import { FC } from "react";
import { Button, Col, Form, Row } from "antd";
import _t from "../../../../../lang/translate";
import { IdType } from "../../../../../types/appTypes";
import AdvCalcUploadBtn from "./advCalcUploadBtn";
import TradePricePreview from "./tradePricePreview";
import AdvCalcAutoPickBestAds from "./advCalcAutoPickBestAds";

interface AdvCalcModalFooterProps {
  caseId: IdType;
  onNewVehicles: (values: any) => void;
  onSubmit: () => void;
  onRefreshTradePrice: () => void;
}

const AdvCalcModalFooter: FC<AdvCalcModalFooterProps> = ({ caseId, onSubmit, onRefreshTradePrice, onNewVehicles }) => {
  return (
    <>
      <div className="ant-modal-footer">
        <Row justify="space-between">
          <Col className="position-static">
            <AdvCalcUploadBtn caseId={caseId} onNewVehicles={onNewVehicles} />
          </Col>
          <Col>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsValue, setFieldsValue }) => (
                <AdvCalcAutoPickBestAds getFieldsValue={getFieldsValue} setFieldsValue={setFieldsValue} />
              )}
            </Form.Item>
            <Form.Item name="tradePrice" noStyle>
              <TradePricePreview onRefresh={onRefreshTradePrice} />
            </Form.Item>
            <Button type="primary" onClick={onSubmit}>
              {_t("apply")}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdvCalcModalFooter;
