import React, { FC, ReactNode } from "react";

interface LoadingProps extends React.HTMLAttributes<HTMLDivElement> {
  loading?: boolean;
  showLogo?: boolean;
  fluid?: boolean;
  message?: ReactNode;
}

const LoadingContainer: FC<LoadingProps> = ({
  loading = true,
  showLogo = false,
  fluid,
  className,
  message,
  children,
  ...rest
}) => {
  return (
    <div
      className={`loading-container ${className ? className : ""}${loading ? " is-loading" : ""}${fluid ? "" : " position-relative"
        }${showLogo ? " show-loading-logo" : ""}`}
      {...rest}
    >
      {children}
      {message && <div className="loading-message">{message}</div>}
    </div>
  );
};

export default LoadingContainer;
