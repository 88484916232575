import { FC } from "react";
import { useParams } from "react-router";
import ActiveUsers from "../../../../components/activeUsers";
import AnimateIn from "../../../../components/animateIn";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import PageBody from "../../../../layout/pageBody";
import PageTitle from "../../../../layout/pageTitle";
import { importCaseUrl } from "../../../../services/importService";
import { useApi } from "../../../../services/useApi";
import { ParamTypes, ScreenProps } from "../../../../types/appTypes";
import { ImportCaseType, initImportCase } from "../../../../types/importTypes";
import ImportCaseHeadExtra from "../importCaseHeadExtra";
import ImportCaseEdit from "./importCaseEdit";

const ImportCaseAdmin: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading }] = useApi<ImportCaseType>(importCaseUrl(id), initImportCase, true);
  const caseRecieved = data.id > 0;

  return (
    <ImportCaseStore.Provider initialState={data}>
      <PageTitle
        title={`${title}/${id}`}
        fluid
        headerMargin={false}
        backBtn
        extra={<ActiveUsers context="import" />}
        footer={caseRecieved && <ImportCaseHeadExtra />}
      />
      <PageBody>
        <AnimateIn start={!isLoading && caseRecieved} loadingBeforeStart>
          <ImportCaseEdit />
        </AnimateIn>
      </PageBody>
    </ImportCaseStore.Provider>
  );
};

export default ImportCaseAdmin;
