import { FC, useEffect, useState } from "react";
import { Result, Spin } from "antd";
import _t from "../../lang/translate";
import { getReviews } from "../../services/reviewService";
import { pageKey, pageSizeKey } from "../../services/urlQueryService";
import { IdType, ModulTypes } from "../../types/appTypes";
import { ReviewType } from "../../types/reviewType";
import LoadMore from "../loadMore";
import ReviewItem from "./reviewItem";
import DividerLine from "../lineSplit";

interface ReviewListProps {
  targetId: IdType;
  targetContext: ModulTypes;
  onNewScore?: (score: number) => void;
}

const ReviewList: FC<ReviewListProps> = ({ targetId, targetContext, onNewScore }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<ReviewType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [editingId, setEditingId] = useState<number | null>(null);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = new URLSearchParams();
        query.set(pageKey, `${currentPage}`);
        query.set(pageSizeKey, `${10}`);
        const { data: newData } = await getReviews(targetId, targetContext, query.toString());
        if (!didCancel) {
          setData([...newData.data]);
          setTotalPages(newData.meta?.last_page || 1);
        }
      } catch ({ response }) {
        if (!didCancel) {
          setError(true);
        }
      } finally {
        if (!didCancel) setLoading(false);
      }
    };
    if (currentPage) fetchData();
    return () => {
      didCancel = true;
    };
  }, [currentPage, setData, setLoading, setError, setCurrentPage, setTotalPages]);

  const handleNewScoreUpdate = (newReview: ReviewType, newScore: number) => {
    const newData = [...data].map((item) => (item.id === newReview.id ? newReview : item));
    setData(newData);
    onNewScore && onNewScore(newScore);
  };

  return (
    <>
      {data.map((item) => (
        <div key={item.id}>
          <ReviewItem
            review={item}
            onNewScore={handleNewScoreUpdate}
            isEditing={item.id === editingId}
            startEditing={() => setEditingId(item.id)}
            stopEditing={() => setEditingId(null)}
          />
          <DividerLine className="mt-05 mb-05" />
        </div>
      ))}
      {error && <Result status="error" title={_t("msg.unknown_error")} />}
      {loading ? (
        <div className="text-center p-2">
          <Spin spinning />
        </div>
      ) : (
        !loading &&
        !error && (
          <LoadMore
            message={_t("no_more_reviews")}
            canLoadMore={totalPages > currentPage}
            onClick={() => setCurrentPage((s) => s + 1)}
          />
        )
      )}
    </>
  );
};

export default ReviewList;
