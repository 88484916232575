import { useState } from "react";
import { Button, Form, Input, message, Space } from "antd";
import _t from "../../lang/translate";
import { storeReview } from "../../services/reviewService";
import { OpenModalProps } from "../../types/modalTypes";
import { ReviewUpsertType } from "../../types/reviewType";
import assertFC from "../../utilities/assertFC";
import RateInput from "../rateInput";
import { renderErrorMessage } from "../messages/errorMessage";

const { TextArea } = Input;

export default function ReviewModal<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [reviewForm] = Form.useForm<ReviewUpsertType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { initialValues, targetId, targetContext } = payload;

  const handleSubmit = async () => {
    const values = reviewForm.getFieldsValue(true);
    if (!targetId || !targetContext) {
      message.error(_t("msg.unknown_error"));
      onCancel(new Error("Missing targetId or targetContext"));
    }
    try {
      setLoading(true);
      const { data } = await storeReview(targetId, targetContext, values);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  const getTitle = () => {
    if (targetContext === "merchant") {
      return _t("give_review", "merchant");
    }
    return _t("give_review");
  };

  return (
    <Form
      form={reviewForm}
      initialValues={initialValues}
      requiredMark={false}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <h2 className="modal-title">{getTitle()}</h2>
      <Form.Item name="score" rules={[{ required: true }]}>
        <RateInput size="large" allowHalf />
      </Form.Item>
      <Form.Item name="note">
        <TextArea autoSize={{ maxRows: 6, minRows: 2 }} placeholder={_t("notes")} />
      </Form.Item>

      <div className="text-right">
        <Space>
          <Button htmlType="submit" type="primary" loading={loading}>
            {_t("send")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ReviewModal);
