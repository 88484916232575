import React, { FC } from "react";
import { Alert, Button, Checkbox, Form, message } from "antd";
import _t from "../../../../lang/translate";
import { getApiBaseUrl } from "../../../../services/apiEndpoint";
import appConfig from "../../../../appConfig";
import { useHistory } from "react-router-dom";
import { isLinkType, LocationStateType } from "../../../../types/appTypes";
import { PurchaseType } from "../../../../types/taxDocTypes";
import PaymentSelect from "../../../../components/paymentSelect";
import { openLink } from "../../../../utilities/readLink";
import format from "../../../../utilities/formatNumbers";
import { purchaseByCard, purchaseBySavedCard, } from "../../../../services/taxService";
import { isAxiosError } from "../../../../utilities/typeGuard";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import { getFreeDocBuysLeftCount } from "../../../../services/authService";

interface TaxDocPurchaseFormProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaxDocPurchaseForm: FC<TaxDocPurchaseFormProps> = ({ setLoading }) => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  let history = useHistory<LocationStateType>();
  const freeDocsLeft = getFreeDocBuysLeftCount();

  const initialValues: PurchaseType = {
    agreedTerms: false,
    cardId: taxCase.client?.defaultPaymentCardId || null,
    useFreePurchase: freeDocsLeft > 0,
  };

  const handlePurchase = async (values: PurchaseType) => {
    const { cardId, agreedTerms, useFreePurchase } = values || {};
    try {
      setLoading(true);
      if (cardId === "0") {
        const { data } = await purchaseByCard(taxCase.id, { agreedTerms, useFreePurchase: !!useFreePurchase });
        const link = data?.data;
        isLinkType(link) && openLink(link, history.push, "_self");
      } else {
        const { data } = await purchaseBySavedCard(taxCase.id, values);
        setTaxCase(data);
      }
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  const priceWithVat = taxCase.documentationSettings?.productPriceWithVat;
  const formatedPriceWithVat = priceWithVat ? format.price(priceWithVat) : "";

  return (
    <Form<PurchaseType>
      wrapperCol={{ span: 24 }}
      labelCol={{ span: 24 }}
      onFinish={handlePurchase}
      initialValues={initialValues}
    >
      <Form.Item name="useFreePurchase" valuePropName="checked" hidden={true}>
        <Checkbox>
          {_t('free')}
        </Checkbox>
      </Form.Item>
      <Form.Item name="cardId" hidden={!!freeDocsLeft}>
        <PaymentSelect />
      </Form.Item>
      {!!freeDocsLeft && (
        <Alert
          className="mb-05"
          message={`${_t('free_docs_left').replace('_freeDocsLeft_', freeDocsLeft)}`}
        />
      )}
      <Form.Item name="agreedTerms" valuePropName="checked">
        <Checkbox>
          {_t("i_read_and_accept")}&nbsp;
          <a
            href={getApiBaseUrl(appConfig("static")?.businessTerms)}
            target="_blank"
          >
            {_t("business_terms").toLowerCase()}
          </a>
        </Checkbox>
      </Form.Item>
      <Form.Item shouldUpdate noStyle className="text-right">
        {({ submit, getFieldValue }) => {
          const agreedTerms = getFieldValue("agreedTerms");
          const cardId = getFieldValue("cardId");
          const freePurchase = getFieldValue("useFreePurchase");
          const disabled = !freePurchase && typeof cardId !== "string";
          return (
            <Button
              size="large"
              disabled={!agreedTerms || disabled}
              type="primary"
              onClick={submit}
              block
            >
              {freePurchase ? _t('request') : `${_t("pay")} ${formatedPriceWithVat}`}
            </Button>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default TaxDocPurchaseForm;
