import { Button, Col, Form, Row } from "antd";
import React, { FC } from "react";
import _t from "../../../../lang/translate";
import NewPriceEquipmentsTotalField from "./newPriceEquipmentsTotalField";

interface NewPriceEquipmentsTotalProps {
  openModal: () => void;
}

const NewPriceEquipmentsTotal: FC<NewPriceEquipmentsTotalProps> = ({ openModal }) => {
  return (
    <Form.Item noStyle shouldUpdate={true}>
      {({ getFieldValue }) => {
        const equipments = getFieldValue(["newPrice", "equipmentCalculator"])?.equipments || [];
        return (
          <Row justify="space-between" align="middle">
            <Col>
              <Form.Item className="mb-0" name={["newPrice", "equipmentsPrice"]} label={_t("extra_equipments")}>
                <NewPriceEquipmentsTotalField equipments={equipments} />
              </Form.Item>
            </Col>
            <Col>
              <Button type="link" className="pl-0 pr-0 m-0" onClick={openModal}>
                {_t("edit")}
              </Button>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default NewPriceEquipmentsTotal;
