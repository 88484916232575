import React, { FC } from "react";
import { Space, Typography } from "antd";
import _t from "../../../../lang/translate";
import ImportDownloadOfferBtn from "./importDownloadOfferBtn";
import ImportDownloadReportBtn from "./importDownloadReportBtn";
import ImportVATCheckBtn from './importVatCheckBtn'

const ImportDownloads: FC = () => {
  return (
    <>
      <Typography.Title level={5}>{_t("download")}</Typography.Title>
      <Space className="mb-05" direction="vertical">
        <ImportVATCheckBtn />
        <ImportDownloadOfferBtn />
        <ImportDownloadReportBtn />
      </Space>
    </>
  );
};

export default ImportDownloads;
