import { Button, Col, Drawer, List, Modal as AntModal, Row, Select, Typography } from "antd";
import React, { FC, useState } from "react";
import _t from "../../lang/translate";
import LineSplit from "../lineSplit";
import { getSubscription } from "../../services/authService";
import format from "../../utilities/formatNumbers";
import { SubscriptionPackageType } from "../../types/subscriptionTypes";
import { useApi } from "../../services/useApi";
import { subscriptionPackageUrl } from "../../services/subcriptionService";
import Icon from "../../assets/icons/icon";
import ReactMarkdown from "react-markdown";
import UserStore from "../../contexts/userStore";
import CancelSubscription from "./cancelSubscription";
import consts from "../../utilities/consts";
import LayoutStore from "../../contexts/layoutStore";
import SubscriptionModal from "./modal";
import ContactFormPackage from "./contactFormPackage";

const { Title } = Typography;

type ChangeSubscriptionProps = {
  disabled?: boolean,
  children?: React.ReactNode;
}

interface InfoDrawerProps {
  infoPackage: SubscriptionPackageType | null;
  onClose: () => void
  setInfoPackage: React.Dispatch<React.SetStateAction<SubscriptionPackageType | null>>
  packages: SubscriptionPackageType[]
}

const InfoDrawer: FC<InfoDrawerProps> = ({ infoPackage, onClose, setInfoPackage, packages }) => {
  const { screen } = LayoutStore.useContainer();

  let priceText;
  if (infoPackage?.level === 0 && (infoPackage?.priceNoVat || 0) < 1) {
    priceText = _t('free');
  } else if ((infoPackage?.priceNoVat || 0) < 1) {
    priceText = _t('contact_us');
  } else {
    priceText = format.pricePrefixed(infoPackage?.priceNoVat);
  }

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      visible={!!infoPackage}
      title={(
        <Row justify="space-between" align="middle">
          <Col className="text-lighter">{_t('subscription_info')}</Col>
          <Col>
            <Select
              value={String(infoPackage?.id)}
              style={{ width: 160 }}
              onChange={(v: string) => setInfoPackage(packages.find((pack, i) => String(pack.id) === v) || null)}
              options={packages.map(pack => ({
                value: String(pack.id),
                label: pack.title
              }))}
            />
          </Col>
        </Row>
      )}
      width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
    >
      <Row justify="space-between">
        <Col>
          <p>
            {_t('price', 'month', ` ${_t('per')} `)}
          </p>
        </Col>

        <Col>
          <p>
            <strong>{priceText}</strong>
          </p>
        </Col>
      </Row>

      <LineSplit className="mt-05" />

      <Title level={4}>
        <span className="muted">{_t('content')}</span>
      </Title>

      <List
        split={false}
        itemLayout="horizontal"
        size="small"
        dataSource={infoPackage?.features || []}
        renderItem={item => (
          <List.Item className="px-0 pb-0">
            <Row gutter={8}>
              <Col>
                <Icon name="checkmark-outline" className="text-blue" />
              </Col>
              <Col>
                <ReactMarkdown components={{ p: React.Fragment }}>{item}</ReactMarkdown>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </Drawer>
  );
}

const ChangeSubscription: FC<ChangeSubscriptionProps> = ({ children, disabled }) => {
  const { user } = UserStore.useContainer();
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);
  const [wantedPackage, setWantedPackage] = useState<SubscriptionPackageType | null>(null);
  const [infoPackage, setInfoPackage] = useState<SubscriptionPackageType | null>(null);
  const [{ data, isLoading }] = useApi<{ data: SubscriptionPackageType[] }>(subscriptionPackageUrl, { data: [] });
  const currentSubscription = getSubscription();

  const packages = data.data.sort((a, b) => a.level > b.level ? 1 : 0);

  const userSubPackageId = user?.subscription?.package?.id;
  const hasCustomPackage = !!userSubPackageId && !data.data.find(subPackage => subPackage.id === userSubPackageId);

  const currentSubTitle = user?.subscription?.package?.title || _t('starter');

  const triggerPackage = (x: SubscriptionPackageType | null) => {
    setShowModal(false);
    setWantedPackage(x);
  }

  return (
    <>
      <Button type="primary" onClick={() => setShowModal(true)} disabled={disabled}>
        {children || _t('change', 'subscription')}
      </Button>

      <InfoDrawer
        infoPackage={infoPackage}
        onClose={() => setInfoPackage(null)}
        setInfoPackage={setInfoPackage}
        packages={packages}
      />

      <AntModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        confirmLoading={loading}
        okButtonProps={{ className: 'd-none' }}
        cancelText={_t('go_back')}
        className="no-footer-border"
      >
        <div>
          <Title level={3} className="text-center mt-2 pb-1">
            <span className="text-bold">{_t('change', 'yourSubscription')}</span>
          </Title>

          <p className="muted mt-2 mb-1">
            {_t('yourCurrentSubscription')}
          </p>

          <Row justify="space-between">
            <Col>
              <Title>
                {currentSubTitle}
              </Title>
            </Col>
            <Col>
              <Title>
                {currentSubscription.package?.priceNoVat ? (<>
                  {format.price(currentSubscription.package.priceNoVat)} /{_t('month')}
                  <span className="muted text-smallest"> + {_t("vat")} </span>
                </>
                ) : '-'}
              </Title>
            </Col>
          </Row>

          <LineSplit fluid />

          <Title className="mb-4 muted" level={4}>
            {_t('change_to', "new_plan")}
          </Title>

          {packages.map((subPackage, i) => {
            const isSelected = Number(user?.subscription?.package?.id || -2) === Number(subPackage.id || -2);

            let priceText;
            let buttonText;

            if (packages.length && i === packages.length - 1) {
              priceText = ' ';
              buttonText = hasCustomPackage ? _t('yourSubscription') : _t('contact_us');
            } else {
              priceText = subPackage.priceNoVat < 1 ? _t('free', 'always') : (
                `${format.pricePrefixed(subPackage.priceNoVat)}/${_t('month')} + ${_t("vat")}`
              );

              buttonText = isSelected ? _t('yourSubscription') : _t('change')
            }

            return (
              <div key={subPackage.id}>
                <Row justify="space-between">
                  <Col>
                    <Title level={2} className="mb-0">
                      <strong>{subPackage.title}</strong>
                    </Title>
                    <div className="muted text-lower">{priceText}</div>
                  </Col>

                  <Col>
                    <Button type="link" className="muted" onClick={() => setInfoPackage(subPackage)}>
                      {_t('info')}
                    </Button>

                    {i === 0 ? (
                      <CancelSubscription disabled={isSelected} type="primary">
                        <div onClick={() => triggerPackage(null)}>
                          {buttonText}
                        </div>
                      </CancelSubscription>
                    ) : (i === packages.length - 1) ? (
                      <ContactFormPackage type="primary" disabled={hasCustomPackage}>
                        <div onClick={() => triggerPackage(null)}>
                          {buttonText}
                        </div>
                      </ContactFormPackage>
                    ) : (
                      <Button disabled={isSelected} type="primary" onClick={() => triggerPackage(subPackage)}>
                        {buttonText}
                      </Button>
                    )}
                  </Col>
                </Row>
                <LineSplit />
              </div>
            )
          })}

          <p className="muted">{_t('change_accept_terms')}</p>
        </div>
      </AntModal>

      <SubscriptionModal wantedPackage={wantedPackage} setWantedPackage={triggerPackage} />
    </>
  )
}

export default ChangeSubscription;
