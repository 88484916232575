import { Button, Form, Input, Result, Space } from "antd";
import React, { useState } from "react";
import _t from "../../lang/translate";
import { saveBillingDestination } from "../../services/dealerService";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import InputCVR from "../form/inputCVR";
import { renderErrorMessage } from "../messages/errorMessage";

export default function ModalBillingDestination<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [billingDesForm] = Form.useForm<T>();
  const [loading, setLoading] = useState<boolean>(false);
  const { parentId, initialValues } = payload;

  const handleSubmit = async (values: T) => {
    setLoading(true);
    try {
      const { data } = await saveBillingDestination<T>(parentId, values);
      onSuccess(data);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={billingDesForm} requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <h2 className="modal-title">{_t("billing_destinations")}</h2>
      {parentId ? (
        <>
          <Form.Item name="id" hidden initialValue={0}>
            <Input hidden />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ setFieldsValue }) => (
              <Form.Item
                name="cvr"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    min: 8,
                    message: " ",
                  },
                ]}
              >
                <InputCVR placeholder={_t("cvr")} onFetched={setFieldsValue} />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            name="title"
            label={_t("name")}
            rules={[{ required: true, whitespace: true }]}
            initialValue={initialValues}
          >
            <Input placeholder={_t("name")} />
          </Form.Item>
          <Form.Item
            name="email"
            label={_t("email")}
            rules={[
              {
                required: true,
                whitespace: true,
                type: "email",
              },
            ]}
          >
            <Input placeholder={_t("email")} />
          </Form.Item>
        </>
      ) : (
        <Result status="error" title={_t("choose", "dealer")} />
      )}
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>

          <Button htmlType="submit" type="primary" disabled={!parentId} loading={loading}>
            {_t("add")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ModalBillingDestination);
