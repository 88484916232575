import { Col, Form, Input, Row } from "antd";
import { FC } from "react";
import InputNum from "../../../../../components/form/inputNum";
import _t from "../../../../../lang/translate";
import { TaxCalcEquipment } from "../../../../../types/taxCalcTableTypes";

interface TaxCalcEquipmentNameProps {
  id: number;
  equipment: TaxCalcEquipment;
}

const TaxCalcEquipmentName: FC<TaxCalcEquipmentNameProps> = ({ id, equipment }) => {
  const disabled = equipment.synced;
  return (
    <Form.Item shouldUpdate={true} noStyle>
      {({ getFieldValue }) => {
        const equipments = (getFieldValue("equipments") || []) as any[];
        const index = equipments.findIndex((e) => e.id === id);
        return (
          <Row align="middle" gutter={8} className="flex-nowrap">
            <Col flex="80px">
              <Form.Item name={["equipments", index, "code"]} noStyle>
                <Input className="pr-0" placeholder={_t("code")} disabled={disabled} />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item name={["equipments", index, "name"]} noStyle>
                <Input placeholder={_t("name")} disabled={disabled} title={equipments[index]?.name || undefined} />
              </Form.Item>
            </Col>
            <Col flex="80px">
              <Form.Item name={["equipments", index, "price"]} noStyle>
                <InputNum className="pr-0" placeholder={_t("price")} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default TaxCalcEquipmentName;
