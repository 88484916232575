import { EnumItemType, IdType } from "./appTypes";
import { DealerProfileType, DepartmentType } from "./dealerTypes";

export interface UserEditiblePropTypes {
  name: string | null;
  phone: string | null;
  initials: string | null;
}

export interface UserCommonType extends UserEditiblePropTypes {
  id: IdType;
  email: string | null;
  departments: DepartmentType[] | null;
}

export interface ProductPricesType {
  tax: number;
  taxRecalculate: number;
  taxYellowToWhite: number;
  taxDoc: number;
  import: number;
  vehicleReport: number;
}

export interface UserType extends UserCommonType {
  dealer: string | null;
  dealerId: IdType | null;
  status?: EnumItemType | null;
  banned: boolean;
  bannedAt?: string | null;
  departmentIds?: IdType[] | null;
  roleId: IdType | null;
  role: EnumItemType | null;
}

export interface ProfileType extends UserCommonType {
  lastLogin: string | null;
  userCount: number | null;
  dealer?: DealerProfileType | null;
}

export const initUser: UserType = {
  id: 0,
  name: null,
  email: null,
  phone: null,
  initials: null,
  dealer: null,
  dealerId: null,
  banned: false,
  role: null,
  roleId: null,
  departments: null,
};

export interface SocketUser {
  id: IdType;
  name: string | null;
  initials: string | null;
  timestamp: number;
}
