import React, { useState } from "react";
import { Button, Form, Space } from "antd";
import _t from "../../lang/translate";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import EnumSelect from "../form/enumSelect";
import { createTaxofImport } from "../../services/importService";
import DividerLine from "../lineSplit";
import { getClipCount, getCreditCount, getProductPrices } from "../../services/authService";
import { renderErrorMessage } from "../messages/errorMessage";

export default function ImportToTaxCase<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<T>();
  const [loading, setLoading] = useState<boolean>(false);
  const getClipPrice = () => getProductPrices()?.tax;
  const totalCredits = getClipCount() + getCreditCount();
  const hasEnoughClips = totalCredits > (getClipPrice() || 0);

  const handleSubmit = async (values: any) => {
    const { id } = payload;
    try {
      setLoading(true);
      const { data } = await createTaxofImport(id, { evaluationTypeId: values.evaluationTypeId });
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <h2 className="modal-title">{_t("create", "taxCase")}</h2>
      <Form.Item name="evaluationTypeId" label={_t("evaluating_type")}>
        <EnumSelect defaultActiveFirstOption nameKey={["taxCase", "evaluationType"]} />
      </Form.Item>
      <DividerLine>
        <small>
          {_t("when_click_calculate_you_use", "clip_from_your_account", ` ${getClipPrice()} `)}
        </small>
      </DividerLine>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" loading={loading} type="primary" disabled={!hasEnoughClips}>
            {_t("create")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ImportToTaxCase);
