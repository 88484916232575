import React, { FC, useState } from "react";
import CollapseCard from "../../../../components/collapseCard";
import DividerLine from "../../../../components/lineSplit";
import _t from "../../../../lang/translate";
import TaxDocDownloadBtns from "./taxDocDownloadBtns";
import TaxDocPrerequisites from "./taxDocPrerequisites";
import TaxDocMotorAgencyStatus from "./taxDocMotorAgencyStatus";
import TaxDocNote from "./taxDocNote";
import TaxDocUploads from "./taxDocUploads";

const TaxDocActions: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <CollapseCard title={_t("actions")} loading={loading}>
      <TaxDocUploads />
      <DividerLine fluid />
      <TaxDocNote />
      <DividerLine fluid />
      <TaxDocPrerequisites setLoading={setLoading} />
      <DividerLine fluid />
      <TaxDocDownloadBtns />
      <DividerLine fluid />
      <TaxDocMotorAgencyStatus />
    </CollapseCard>
  );
};

export default TaxDocActions;
