import React, { FC, useCallback, useEffect, useState } from "react";
import { EnumItemType } from "../types/appTypes";
import { AutoComplete, AutoCompleteProps, Input } from "antd";
import { useApi } from "../services/useApi";
import { PaginationedData } from "../types/apiTypes";
import { getApiQuery, pageSizeKey, searchKey } from "../services/urlQueryService";
import debounce from "lodash/debounce";
import Icon from "../assets/icons/icon";
import { LoadingOutlined } from "@ant-design/icons";

interface ServerAutoFillProps extends AutoCompleteProps {
  apiUrl: string;
}

const ServerAutoFill: FC<ServerAutoFillProps> = ({
  apiUrl,
  value,
  disabled,
  ...rest
}) => {
  const [{ data: response, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<EnumItemType>>("", {
    data: [],
  });
  const [skipLoad, setSkipLoad] = useState<boolean>(false);
  const { data } = response;

  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  const fetchNewOptions = useCallback(() => {
    const url = new URL(apiUrl);
    url.searchParams.set(pageSizeKey, "9999");
    url.searchParams.set(searchKey, value || '');
    delayedSetUrl(apiUrl + getApiQuery(url.searchParams.toString()));
    setSkipLoad(false);
  }, [apiUrl, delayedSetUrl, value]);

  useEffect(() => {
    if (!disabled && !skipLoad) {
      fetchNewOptions();
    }
  }, [disabled, fetchNewOptions, skipLoad]);

  return (
    <AutoComplete
      options={data.map((item) => ({ value: item.title }))}
      value={value}
      onSelect={() => setSkipLoad(true)}
      disabled={disabled}
      {...rest}
    >
      <Input suffix={isLoading ? (<LoadingOutlined />) : <Icon name="chevron-down-outline" />}
        onChange={() => !!skipLoad && setSkipLoad(false)} />
    </AutoComplete>
  );
};

export default ServerAutoFill;
