import React, { FC, useEffect } from "react";
import InputNum, { InputPriceProps } from "../../../../components/form/inputNum";
import { isNum } from "../../../../utilities/typeGuard";

interface RegulationAmountInputProps extends InputPriceProps {
  priceLevel?: number | null;
  otherRegulation?: number | null;
}

const RegulationAmountInput: FC<RegulationAmountInputProps> = ({
  priceLevel,
  otherRegulation,
  value,
  onChange,
  ...rest
}) => {
  useEffect(() => {
    if (isNum(priceLevel) && isNum(otherRegulation)) {
      const nextValue = Math.round(priceLevel * otherRegulation);
      nextValue !== value && onChange && onChange(nextValue);
    } else if (priceLevel !== 0) {
      onChange && onChange("")
    }
  }, [priceLevel, otherRegulation]);

  return <InputNum disabled value={value} onChange={onChange} suffix="kr." {...rest} />;
};

export default RegulationAmountInput;
