//import { convertToAppQuery } from "./urlQueryService";
import getApiEndpoint from "./apiEndpoint";
import { NotificationType } from "../types/notificationsType";
import { getApiQuery, pageSizeKey } from "./urlQueryService";
import http from "./httpService";
import { PaginationedData } from "../types/apiTypes";

const apiEndpoint = `${getApiEndpoint()}/notifications`;

export function notificationUrl(query?: string) {
  if (!query || (query && !query.includes(pageSizeKey))) {
    query += `&${pageSizeKey}=10`;
  }
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function getNotification(query?: string) {
  return http.get<PaginationedData<NotificationType>>(
    `${apiEndpoint}?${getApiQuery(query)}`
  );
}
