import { Button, Modal } from "antd";
import { FC, useState } from "react";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import DealerBonusLog from "./dealerBonusLog";

const DealerAdminBonusOverview: FC = () => {
  const { dealer } = DealerStore.useContainer();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  return (
    <>
      <Button onClick={openModal}>{_t("bonus", "usage")}</Button>
      <Modal
        width={800}
        visible={isVisible}
        onCancel={closeModal}
        destroyOnClose
        title={_t("bonus", "usage")}
        bodyStyle={{ padding: 0 }}
        okButtonProps={{ className: "d-none" }}
      >
        <DealerBonusLog dealerId={dealer.id} />
      </Modal>
    </>
  );
};

export default DealerAdminBonusOverview;
