import http from "./httpService";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isAxiosError } from "../utilities/typeGuard";

type StateType<T> = { data: T; isLoading: boolean; isError: boolean };
type UseApiReturnType<T> = [
  StateType<T>,
  React.Dispatch<React.SetStateAction<string>>,
  React.Dispatch<React.SetStateAction<T>>
];

export function useApi<T>(initUrl: string, initData: T, handleErrors?: boolean): UseApiReturnType<T> {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [data, setData] = useState<T>(initData);
  const [url, setUrl] = useState<string>(initUrl);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const { data } = await http.get<T>(url);
        if (!didCancel) setData(data);
      } catch (error) {
        if (!didCancel) {
          setIsError(true);
          if (isAxiosError(error)) { // Doing all these type checks on the error object to get typescript to stop complaining.
            if (handleErrors && error?.response && error?.response?.status > 400) {
              history?.replace(history.location.pathname, {
                errorStatusCode: error?.response.status,
              });
            }
          }
        }
      } finally {
        if (!didCancel) setIsLoading(false);
      }
    };
    if (url !== "") fetchData();
    return () => {
      didCancel = true;
    };
  }, [setIsLoading, setIsError, setData, url, handleErrors, history]);

  const state: StateType<T> = { data, isLoading, isError };

  return [state, setUrl, setData];
}
