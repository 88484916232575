import { Input } from "antd";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { searchKey } from "../../services/urlQueryService";

interface TableSearchFieldProps {
  onSearch: (v: string) => void;
  onClear: () => void;
}

const TableSearchField: FC<TableSearchFieldProps> = ({ onSearch, onClear }) => {
  let { search } = useLocation();
  const searchValue = new URLSearchParams(search).get(searchKey);
  return (
    <Input
      //ref={searchRef}
      className="pl-0"
      defaultValue={new URLSearchParams(search).get("search") || undefined}
      onChange={(e) => onSearch(e.target?.value)}
      placeholder={_t("search_placeholder")}
      bordered={false}
      value={searchValue || ""}
      prefix={
        !searchValue ? (
          <Icon name="search-outline" size="large" />
        ) : (
          <Icon name="close-circle-outline" onClick={onClear} size="large" />
        )
      }
    />
  );
};

export default TableSearchField;
