import {AutoComplete, Button, Col, Form, Input, Row} from "antd";
import React, {FC, useState} from "react";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";
import NewPriceEquipments from "./newPriceEquipments";
import TechnicalNewPrice from "./technicalNewPrice";
import Icon from "../../../../assets/icons/icon";
import {getNewPriceByVin} from "../../../../services/miscService";
import {renderErrorMessage} from "../../../../components/messages/errorMessage";

const sourceOptions = [
  {value: "Estimatic"},
  {value: "Kilde til basisnypris er Bilinfo - Modelkatalog. Priser på ekstraudstyr er skønnet på baggrund af liste fra DAT.de (importør-anmeldt pris), hvor vi under hensyntagen til moms og afgift har foretaget en omberegning fra Euro til DKK. (Beregning: Europris * 7,5 * 2). Omregnet pris på ekstraudstyret er holdt op imod andre importør-anmeldte priser i Danmark, hvorfor denne omberegning skønnes for værende både retvisende og markedsdannende."},
  {value: "Kilde til nypris er www.motorregister.skat.dk -> Registreringsafgift -> Vis køretøjsvurderinger. Vi har taget udgangspunkt i køretøjets genberegnet, tekniske nypris, som skønnes for retvisende og markedsdannende i forhold til udsalgspriser på aktuelle køretøj. Priser på ekstraudstyr er skønnet på baggrund af liste fra DAT.de (importør-anmeldte priser), hvor vi under hensyntagen til moms og afgift har foretaget en omberegning fra Euro til DKK. (Beregning: Europris * 7,5 * 2). Omregnet pris på ekstraudstyret er holdt op imod andre importør-anmeldte priser i Danmark, hvorfor denne omberegning skønnes for værende både retvisende og markedsdannende."},
]

interface Props {
  vin: string | null | undefined
  firstRegDate: string | null | undefined
}

const NewPriceFormItems: FC<Props> = ({vin, firstRegDate}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const getNewPrice = async (): Promise<number> => {
    let amount = 0;

    try {
      setLoading(true);
      const res = await getNewPriceByVin(vin || '', firstRegDate || '');
      amount = res.data.data;
    } catch (error) {
      renderErrorMessage(error);
    } finally {
      setLoading(false);
    }

    return amount;
  }

  return (
    <>
      <Form.Item
        label={_t("new_price", "without_equipments")}
      >

        <Row>
          <Form.Item shouldUpdate noStyle>
            {({setFieldsValue}) => (
              <>
                <Col hidden={!vin || !firstRegDate}>
                  <Button type="link" icon={<Icon name="open-outline"/>} onClick={async () => {
                    const amount = await getNewPrice();
                    setFieldsValue({"newPrice": {"newPriceWithoutEquipments": Math.round(amount)}})
                  }}/>
                </Col>

                <Col flex="auto">
                  <Form.Item
                    name={["newPrice", "newPriceWithoutEquipments"]}
                    noStyle>
                    <InputNum min={0} suffix="kr." disabled={loading}/>
                  </Form.Item>
                </Col>
              </>
            )}
          </Form.Item>
        </Row>

      </Form.Item>
      <Form.Item name={["newPrice", "newPriceSource"]} label={`${_t("source")} - ${_t("note")}`}
                 className="mb-2"
      >
        <AutoComplete
          options={sourceOptions}
        >
          <Input.TextArea showCount={{formatter: ({count}) => `${count} / 1024`}} maxLength={1024}/>
        </AutoComplete>
      </Form.Item>
      <TechnicalNewPrice/>
      <NewPriceEquipments/>
    </>
  );
};

export default NewPriceFormItems;
