import React, { FC } from "react";
import { Col, Row } from "antd";
import _t, { _lt } from "../../../../lang/translate";
import DividerLine from "../../../../components/lineSplit";
import TaxOfferExpiredBtn from "./taxOfferExpiredBtn";
import { getProductPrices } from "../../../../services/authService";
import { getPath } from "../../../../routes/appRoutes";

const TaxRecalculateInfo: FC = () => {
  const clipCost = getProductPrices()?.taxRecalculate || 0;

  return (
    <>
      <Row className="flex-wrap">
        <Col flex="auto">
          <div className="text-large text-dark">{_t("fee")}</div>
        </Col>
        <Col flex="80px" className="text-right">
          <div className="text-large text-dark">
            {clipCost < 1 ? _t('free') : `${clipCost} ${_t('credit')}`}
          </div>
        </Col>
      </Row>
      <Row className="flex-wrap mb-05">
        <Col flex="auto">
          <small>
            {clipCost < 1 ? (
              _t("promo.hasFreeRecalculation")
            ) : (
              _lt("promo.freeRecalculation", undefined, getPath("subscription"))
            )}
          </small>
        </Col>
      </Row>
      <DividerLine fluid />
      <div className="text-right">
        <TaxOfferExpiredBtn />
      </div>
    </>
  );
};

export default TaxRecalculateInfo;
