import { FC } from "react";

interface ShowFieldValueProps {
  value?: any | null;
}

const ShowFieldValue: FC<ShowFieldValueProps> = ({ value }) => {
  return <span>{value || "-"}</span>;
};

export default ShowFieldValue;
