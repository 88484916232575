import { Col, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FC } from "react";
import ServerSelectBox from "../../../components/serverSelectBox";
import _t from "../../../lang/translate";
import { countriesAutoComplete } from "../../../services/autocompleteService";

const MerchantFormFields: FC = () => {
  return (
    <>
      <Form.Item name="name" rules={[{ required: true }, { whitespace: true }]} label={_t("merchant_name")}>
        <Input />
      </Form.Item>
      <Form.Item name="vatNumber" label={_t("vat_number")} className="mb-3">
        <Input />
      </Form.Item>
      <Form.Item name="address" label={_t("address")}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("zip", "city", " & ")}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="zip" noStyle>
              <Input maxLength={10} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="city" noStyle>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item name="countryId" label={_t("country")} className="mb-3">
        <ServerSelectBox apiUrl={countriesAutoComplete()} />
      </Form.Item>
      <Form.Item name="email" label={_t("email")}>
        <Input />
      </Form.Item>

      <Form.Item name="phone" label={_t("phone")} rules={[{ required: true }]} className="mb-3">
        <Input />
      </Form.Item>

      <Form.Item name="bank" label={_t("bank")} rules={[{ required: false }]} className="mb-3">
        <Input />
      </Form.Item>

      <Form.Item name="iban" label={_t("iban")} rules={[{ required: false }]} className="mb-3">
        <Input />
      </Form.Item>

      <Form.Item name="bic" label={_t("bic")} rules={[{ required: false }]} className="mb-3">
        <Input />
      </Form.Item>

      <Form.Item name="note" label={_t("note")}>
        <TextArea autoSize={{ minRows: 1, maxRows: 3 }} />
      </Form.Item>
    </>
  );
};

export default MerchantFormFields;
