import { Button, Form, Input, Space } from "antd";
import React, { useState } from "react";
import _t from "../../lang/translate";
import { closeImportCase } from "../../services/importService";
import { CloseImportCaseType } from "../../types/importTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { renderErrorMessage } from "../messages/errorMessage";

const { TextArea } = Input;

export default function RejectImportCase<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<CloseImportCaseType>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleReject = async (values: CloseImportCaseType) => {
    const { id } = payload;
    try {
      setLoading(true);
      const { data } = await closeImportCase<T>(id, values);
      onSuccess(data);
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="vertical" onFinish={handleReject}>
      <h2 className="modal-title">{_t("reject", "case")}</h2>
      <Form.Item name="reason" label={_t("reason")} rules={[{ required: true }]}>
        <TextArea autoSize={{ maxRows: 6, minRows: 3 }} />
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" danger type="primary" loading={loading}>
            {_t("reject", "case")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(RejectImportCase);
