import { FC } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { MerchantCasesType } from "../../../types/merchantTypes";
import consts from "../../../utilities/consts";

interface CustomerCasesProps {
  cases?: MerchantCasesType[] | null;
}

const MerchantCases: FC<CustomerCasesProps> = ({ cases }) => {
  const columns: ColumnsType<MerchantCasesType> = [
    {
      key: "id",
      dataIndex: "id",
      title: "#",
      width: consts.idColumnWidth,
      render: (text, record) => record.contexts && <Link to={getPath(record.contexts, text)}>{text}</Link>,
    },
    {
      key: "title",
      dataIndex: "title",
      title: _t("vehicle"),
      ellipsis: true,
    },
    {
      key: "handler",
      dataIndex: "handler",
      title: _t("case_handler"),
    },
    {
      key: "dealer",
      dataIndex: "dealer",
      title: _t("dealer"),
      ellipsis: true,
    },
  ];
  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={cases || []}
      pagination={{ hideOnSinglePage: true, pageSize: 10 }}
    />
  );
};

export default MerchantCases;
