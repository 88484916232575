import { Button, Col, Row, Table, Tag } from "antd";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import CollapseCard from "../../../components/collapseCard";
import StatusTag from "../../../components/statusTag";
import { CustomColumnsType } from "../../../components/table/dataTableTypes";
import LayoutStore from "../../../contexts/layoutStore";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { latestCasesUrl } from "../../../services/miscService";
import { useApi } from "../../../services/useApi";
import { isModulTypes } from "../../../types/appTypes";
import { SimpleCasesType } from "../../../types/miscTypes";
import UserStore from "../../../contexts/userStore";
import CustomMsg from "../../../components/customMsg";

const DashboardCases: FC = () => {
  const [{ data, isLoading }] = useApi(latestCasesUrl, {
    data: [],
  });
  const dataSource = data?.data || [];
  const { screen } = LayoutStore.useContainer();
  const { hasPermission } = UserStore.useContainer();
  const isMobile = screen.isMobile;
  const hasArchive = hasPermission("tax.index");

  const renderLink = (id: any, { type }: SimpleCasesType) => {
    const text = <strong>{id}</strong>;
    const context = type.contexts && isModulTypes(type.contexts) ? type.contexts : null;
    return context ? <Link to={getPath(context, id)}>{text}</Link> : text;
  };

  const columns: CustomColumnsType<SimpleCasesType> = [
    {
      title: _t("case", "number_short"),
      align: "center",
      key: "id",
      dataIndex: "id",
      width: 110,
      fixed: !isMobile && "left",
      render: renderLink,
    },
    {
      title: _t("type"),
      key: "type",
      dataIndex: "type",
      render: (type) => {
        return <Tag color={type.color}>{type.title}</Tag>;
      },
    },
    {
      title: _t("vehicle"),
      key: "vehicle",
      dataIndex: "vehicle",
      ellipsis: true,
    },
    {
      title: _t("customer"),
      key: "clientName",
      dataIndex: "clientName",
      ellipsis: true,
    },
    {
      title: _t("status"),
      key: "status",
      sorter: true,
      width: 200,
      dataIndex: "status",
      render: (status) => <StatusTag status={status} size="small" />,
    },
  ];

  const props = {
    columns,
    dataSource,
    rowKey: "id",
    loading: isLoading,
    pagination: { hideOnSinglePage: true },
  };

  return (
    <CollapseCard className="position-relative collapse-card-tabs" title={_t("latest_cases")}>
      <Table<SimpleCasesType> scroll={{ x: 800 }} size="small" {...props} />
      {!hasArchive && (
        <div className="blur-banner">
          <div className="position-relative" style={{ padding: "30px" }}>
            <CustomMsg
              theme="gray"
              className="p-2 pb-0"
            >
              <span className="text-large">
                <strong>Ingen adgang</strong> til sagsarkiver <br />med nuværende abonnementspakke
              </span>

              <Row gutter={16} align="middle">
                <Col className="mt-2 mb-2">
                  <Button type="primary">
                    <Link to={getPath("subscription")}>
                      {_t('choose', 'subscription_package')}
                    </Link>
                  </Button>
                </Col>

                <Col className="text-large">
                  {_t('or')}
                </Col>

                <Col className="mt-2 mb-2">
                  <Button type="default">
                    <Link to={getPath("tax", "new")}>{_t("create_tax_case")}</Link>
                  </Button>
                </Col>
              </Row>
            </CustomMsg>
          </div>
        </div>
      )}
    </CollapseCard>
  );
};

export default DashboardCases;
