// @ts-nocheck
import goalSeek from "goal-seek";

const initInput = {
  firstRegDate: "",
  isNew: true,
  typeId: 1,
  taxablePrice: 0,
  tradePrice: 0,
  isHighWeight: false,
  newPrice: 0,
  emissionAmount: 0,
  emissionUnitId: 1,
  electricConsumption: 0,
  electricRange: 0,
};

const getCarEnergyType = (id) => {
  let title = "";
  if (id === 1) title = "Høj emissionsbil";
  else if (id === 2) title = "Lav emissionsbil";
  else title = "Nul emissionsbil";

  return { id, title };
};

const calculateDiff = (estimatedTax, input = initInput, isDiff = true) => {
  // Get the input values
  let output = null;
  let diff = 0;

  const {
    firstRegDate,
    typeId: vehicleType,
    isHighWeight,
    isNew,
    taxablePrice,
    tradePrice,
    newPrice,
    emissionUnitId: emissionUnit,
  } = input;

  const emissionValue = input.emissionAmount || 0;
  const electricConsumption = input.electricConsumption || 0;
  const electricRange = input.electricRange || 0;

  let carEnergyType = 1; // default value : Benzin
  const valueloss = isNew ? 1 : tradePrice / newPrice;

  const versionYear = new Date().getFullYear();

  // CO2 tillæg
  const addEmissionLow = versionYear === 2021 ? 250 : 253; // Price pr CO2 g/km between 0 - 125 g/km
  const addEmissionMid = versionYear === 2021 ? 500 : 506; // Price pr CO2 g/km between 125 - 160 g/km
  const addEmisionHigh = versionYear === 2021 ? 950 : 961; // Price pr CO2 g/km between 160 -  g/km

  // Batteri fradrag
  const batteryCapacity = (electricConsumption * electricRange) / 1000;
  const batteryDeductionCharge = versionYear === 2021 ? 1700 : 1300; // Minus 1700 - men max 45 kWh. Kun ved El og Hybridbiler

  let emission = 0;

  if (emissionUnit === 1) {
    emission = emissionValue * 1.21;
  } else if (emissionUnit === 3) {
    emission = emissionValue * 1.11;
  } else {
    emission = emissionValue;
  }
  emission = emission.toFixed(1);

  if (emission >= 50) {
    carEnergyType = 1; // Benzin
  } else if (emission > 0 && emission < 50) {
    carEnergyType = 2; // Hybrid
  } else {
    carEnergyType = 3; // El
  }

  if (vehicleType === 1) {
    // Personbil
    // Keynumbers Car (Personbil)

    // Skalaknæk (Ny)
    let scaleOne = 0; // 0-65000,-
    const scaleOneMax = versionYear === 2021 ? 65000 : 65800;
    let scaleTwo = 0; // 65000-202200,-
    const scaleTwoMax = versionYear === 2021 ? 202200 : 204600;
    let scaleThree = 0; // 202200- ,-

    const scaleOneSurcharge = 0.25;
    const scaleTwoSurcharge = 0.85;
    const scaleThreeSurcharge = 1.5;

    // Afgiftssatser
    const taxSurchargeStandard = 1;
    const taxSurchargeHybrid = versionYear === 2021 ? 0.55 : 0.5;
    const taxSurchargeElectric = 0.6;

    // Bundfradrag
    const bottomDeductionStandard = versionYear === 2021 ? 21700 : 21900;
    const bottomDeductionHybrid = versionYear === 2021 ? 50000 : 48750;
    const bottomDeductionElectric = versionYear === 2021 ? 170000 : 167500;

    //Keynumbers Van (letebil)

    // Beregninger:
    //Tillæg til værdien (Batterifradrag)
    let batteryDeductionAmount = 0;

    if (batteryCapacity > 0 && batteryCapacity > 45) {
      batteryDeductionAmount = carEnergyType == 1 ? 0 : batteryDeductionCharge * 45;
    } else if (batteryCapacity > 0) {
      batteryDeductionAmount = carEnergyType == 1 ? 0 : batteryDeductionCharge * batteryCapacity;
    }
    batteryDeductionAmount = emission < 50 ? batteryDeductionAmount : 0;
    // Afgiftspligtig værdi beregning
    let taxableValueTotal = (tradePrice - ((estimatedTax < 0 ? 0 : estimatedTax) + batteryDeductionAmount)) * valueloss;
    let taxableValue = isNew ? taxablePrice - batteryDeductionAmount : taxableValueTotal / valueloss;

    //Registreringsafgift før tillæg/fradrag
    scaleOne = taxableValue > scaleOneMax * valueloss ? scaleOneMax * valueloss : taxableValue * valueloss;

    if (taxableValue > scaleTwoMax * valueloss) {
      scaleTwo = (scaleTwoMax - scaleOneMax) * valueloss;
    } else if (taxableValue > scaleOneMax * valueloss) {
      scaleTwo = taxableValue - scaleOneMax * valueloss;
    }

    scaleThree = taxableValue > scaleTwoMax * valueloss ? taxableValue - (scaleOne + scaleTwo) : 0;

    let scaleOneAmount = 0;
    let scaleTwoAmount = 0;
    let scaleThreeAmount = 0;
    let bruttoTax = 0;

    //Skalaknæk 1
    scaleOneAmount = scaleOne * scaleOneSurcharge;

    //Skalaknæk 2
    scaleTwoAmount = scaleTwo * scaleTwoSurcharge;

    //Skalaknæk 3
    scaleThreeAmount = scaleThree * scaleThreeSurcharge;

    // Bruttoafgift
    bruttoTax = scaleOneAmount + scaleTwoAmount + scaleThreeAmount;

    // C02 Tillæg (Til bruttoafgift)

    let emissionAddon = 0;
    let emission1 = 0;
    let emission2 = 0;
    let emission3 = 0;
    let emission1Max = versionYear === 2021 ? 125 : 121;
    let emission2Max = versionYear === 2021 ? 160 : 155;

    //Emission 1 (CO2)
    emission1 =
      emission > emission1Max ? emission1Max * addEmissionLow * valueloss : emission * addEmissionLow * valueloss;

    //Emission 2 (CO2)
    if (emission >= emission1Max && emission > emission2Max) {
      emission2 = (emission2Max - emission1Max) * addEmissionMid * valueloss;
    } else if (emission >= emission1Max) {
      emission2 = (emission - emission1Max) * addEmissionMid * valueloss;
    }

    //Emission 3 (CO2)
    emission3 = emission > emission2Max ? (emission - emission2Max) * addEmisionHigh * valueloss : 0;

    //CO2 tillæg i alt
    emissionAddon = carEnergyType == "3" ? 0 : emission1 + emission2 + emission3;

    //RESULTATER
    //Registreringsafgift og første bundfradrag
    let taxTmp = bruttoTax + emissionAddon;

    //Bundfradrag til alle biler
    let bottomDeductionStandardAmount =
      taxTmp > bottomDeductionStandard * valueloss ? bottomDeductionStandard * valueloss : taxTmp;

    let taxTmp2 = taxTmp - bottomDeductionStandardAmount;

    //Indfasning af afgift (Alle biler under 50g CO2)
    let taxDeductionAmount = 0;

    if (emission >= 50) {
      taxDeductionAmount = 0;
    } else if ((emission > 0 && carEnergyType == 2) || carEnergyType == 1) {
      taxDeductionAmount = taxTmp2 * taxSurchargeHybrid;
    } else {
      taxDeductionAmount = taxTmp2 * taxSurchargeElectric;
    }

    let taxTmp3 = taxTmp2 - taxDeductionAmount;

    //Yderligere bundfradrag til el og hybrid biler
    let lowDeductionAmountTmp = 0;

    if (emission >= 50) {
      lowDeductionAmountTmp = 0;
    } else if ((emission > 0 && carEnergyType == 1) || carEnergyType == 2) {
      lowDeductionAmountTmp = bottomDeductionHybrid * valueloss;
    } else {
      lowDeductionAmountTmp = bottomDeductionElectric * valueloss;
    }

    let lowDeductionAmount = taxTmp3 > lowDeductionAmountTmp ? -lowDeductionAmountTmp : -taxTmp3;

    //Registreringsafgift
    let tax = taxTmp3 + lowDeductionAmount;

    //Afgiftspligtig værdiberegning (Goalseek)
    diff = tax - estimatedTax;

    const consts = {
      scaleOneMax,
      scaleTwoMax,
      emission1Max,
      emission2Max,
    };

    output = {
      consts,
      vehicleType,
      energyType: getCarEnergyType(carEnergyType), // 1
      emission, // 2
      batteryCapacity, // 3
      valueloss, // 4
      batteryDeductionAmount: -batteryDeductionAmount, // 5
      scales: [scaleOneAmount, scaleTwoAmount, scaleThreeAmount],

      grossTax: bruttoTax, // 9
      emissions: [emission1, emission2, emission3],

      emissionAddon, // 13

      taxTmp, // 14
      bottomDeductionStandardAmount: -bottomDeductionStandardAmount, // 15
      taxDeductionAmount: -taxDeductionAmount, // 16
      lowDeductionAmount, // 17
      taxablePrice: taxableValue, //18
      registrationFee: tax, // 19
    };
  } else {
    //////////////////////////////////////////////////////////////// VAN
    // Skalaknæk (Ny)
    let scaleOne = 0; // 0-75000,-
    let scaleOneMax = versionYear === 2021 ? 75000 : 75900; // 0-75000,-
    let scaleTwo = 0; // 75000-

    const scaleOneSurcharge = 0;
    const scaleTwoSurcharge = 0.5;

    // Afgiftssatser
    const taxSurchargeStandard = 1;
    const taxSurchargeHybrid = 0.55;
    const taxSurchargeElectric = 0.6;

    // Bundfradrag
    const bottomDeductionStandard = versionYear === 2021 ? 30000 : 30300;
    const bottomDeductionHybrid = 50000;
    const bottomDeductionElectric = 80000;

    // Maks afgift
    const maxTax = 47000;

    // Beregninger:
    //Tillæg til værdien (Batterifradrag)
    let batteryDeductionAmount = 0;

    if (batteryCapacity > 0 && batteryCapacity > 45) {
      batteryDeductionAmount = carEnergyType == 1 ? 0 : batteryDeductionCharge * 45;
    } else if (batteryCapacity > 0) {
      batteryDeductionAmount = carEnergyType == 1 ? 0 : batteryDeductionCharge * batteryCapacity;
    }

    batteryDeductionAmount = emission < 50 ? batteryDeductionAmount : 0;

    //Afgiftspligtig værdi beregning

    let taxableValueTotal = (tradePrice - ((estimatedTax < 0 ? 0 : estimatedTax) + batteryDeductionAmount)) * valueloss;
    let taxableValue = isNew ? taxablePrice - batteryDeductionAmount : taxableValueTotal / valueloss;

    taxableValue = taxableValue < 0 ? 0 : taxableValue;

    //Registreringsafgift før tillæg/fradrag
    scaleOne = 0;

    scaleTwo = taxableValue > scaleOneMax * valueloss ? taxableValue - scaleOneMax * valueloss : 0;

    let scaleOneAmount = 0;
    let scaleTwoAmount = 0;
    let bruttoTax = 0;

    //Skalaknæk 1
    scaleOneAmount = scaleOne * scaleOneSurcharge;

    //Skalaknæk 2
    scaleTwoAmount = scaleTwo * scaleTwoSurcharge;

    // Bruttoafgift
    bruttoTax = scaleOneAmount + scaleTwoAmount;

    //CO2 tillæg i alt
    let emissionAddon = emission * addEmissionLow * valueloss;

    //RESULTATER
    //Registreringsafgift og første bundfradrag
    let taxTmp = bruttoTax;

    //Bruttoafgift inkl. CO2-tillæg
    let taxTmp2 = taxTmp + emissionAddon;

    //Bundfradrag til alle biler
    let bottomDeductionStandardAmount =
      taxTmp2 > bottomDeductionStandard * valueloss ? bottomDeductionStandard * valueloss : taxTmp2;

    let taxTmp3 = taxTmp2 - bottomDeductionStandardAmount;

    //Indfasning af afgift (Alle biler under 50g CO2)
    let taxDeductionAmount = 0;

    if (emission >= 50) {
      taxDeductionAmount = 0;
    } else if ((emission > 0 && carEnergyType == 2) || carEnergyType == 1) {
      taxDeductionAmount = taxTmp3 * taxSurchargeHybrid;
    } else {
      taxDeductionAmount = taxTmp3 * taxSurchargeElectric;
    }

    let taxTmp4 = taxTmp3 - taxDeductionAmount;

    //Yderligere bundfradrag til el og hybrid biler
    let lowDeductionAmountTmp = 0;

    if (emission >= 50) {
      lowDeductionAmountTmp = 0;
    } else if ((emission > 0 && carEnergyType == 1) || carEnergyType == 2) {
      lowDeductionAmountTmp = bottomDeductionHybrid * valueloss;
    } else {
      lowDeductionAmountTmp = bottomDeductionElectric * valueloss;
    }

    let lowDeductionAmount = taxTmp4 > lowDeductionAmountTmp ? lowDeductionAmountTmp : taxTmp4;

    //Registreringsafgift
    //Registreringsafgift
    var taxTmp5 = taxTmp4 - lowDeductionAmount;
    let tax;

    if (isHighWeight == 1 && taxTmp5 >= maxTax) {
      tax = maxTax * valueloss;
    } else {
      tax = taxTmp5;
    }

    //Afgiftspligtig værdiberegning (Goalseek)
    diff = tax - estimatedTax;

    output = {
      vehicleType,
      energyType: getCarEnergyType(carEnergyType), // 1
      emission, // 2
      batteryCapacity, // 3
      valueloss, // 4
      batteryDeduction: -batteryDeductionAmount, // 5
      scales: [scaleOneAmount, scaleTwoAmount],

      grossTax: bruttoTax, // 9

      emissionAddon, // 13

      bottomDeduction: -bottomDeductionStandardAmount, // 15
      taxDeduction: -taxDeductionAmount, // 16
      lowDeductionAmount, // 17
      taxablePrice: taxableValue, //18
      registrationFeeBeforeDeduction: taxTmp, // 14
      registrationFee: tax, // 19
    };
  }

  return isDiff ? Math.round(diff) : output;
};

export default async function calculateRegFee(input) {
  return new Promise((resolve, reject) => {
    let taxableAmount = 0;
    let result = goalSeek({
      fn: calculateDiff,
      fnParams: [0, input, true],
      percentTolerance: 1,
      maxIterations: 10000,
      maxStep: 30000,
      goal: 0,
      independentVariableIdx: 0,
    });
    taxableAmount = result;
    const output = calculateDiff(taxableAmount, input, false);
    output
      ? resolve({ ...output, registrationFee: Math.round(output?.registrationFee || 0) })
      : reject("Beregning mislykkedes");
  });
}

function carAgeCalc(firstRegDate) {
  const now = new Date();

  // Calculate the age of the car in days (86400000 is a magic number) and later in years
  return Math.floor((now - new Date(firstRegDate)) / 86400000) / 365;
}
