import { Tag, TagProps } from "antd";
import React, { FC } from "react";
import { StatisticItemType } from "../types/statisticsType";
import hexToRGB from "../utilities/colorUtil";
import { isNum } from "../utilities/typeGuard";
import Icon from "../assets/icons/icon";

interface StatisticDiffStatusProps extends TagProps {
  totals?: StatisticItemType[] | null;
  valueType?: null | 'percentage' | 'number';
}

const StatisticDiffStatus: FC<StatisticDiffStatusProps> = ({ valueType, totals, ...rest }) => {
  if (!totals || !totals.length) return null;

  const newValue = totals[0].value;
  const oldValue = totals[1].value;
  const newLabel = totals[0].label;
  const oldLabel = totals[1].label;

  const suffix = valueType === 'percentage' ? '%' : '';

  if (!isNum(newValue) || !isNum(oldValue)) return null;

  //const diffAmount = oldValue - newValue;
  const isNegative = newValue - oldValue < 0;
  const getDiffPercent = () => {
    if (newValue === 0 && oldValue === 0) return 0;
    if (newValue === 0) return -100;
    if (oldValue === 0) return 100;

    /*
    const a = Math.round((diffAmount / oldValue) * 100);
     */

    const diff = newValue - oldValue;
    const a = Math.round((diff / oldValue) * 100);

    return a;
  };

  const styles: React.CSSProperties = {
    padding: "2px 4px",
    color: isNegative ? "#E1001A" : "#2bb509",
    backgroundColor: hexToRGB(isNegative ? "#E1001A" : "#2bb509", 0.2),
  };

  let value: any = !isNaN(oldValue) ? oldValue * (valueType === 'percentage' ? 100 : 1) : '-';
  value = value && valueType === 'percentage' ? value.toFixed(2) : value;

  return (
    <>
      <Tag style={styles} {...rest}>
        {`${value}${suffix || ''}`} ({getDiffPercent()}%) <Icon name={isNegative ? "arrow-down-outline" : "arrow-up-outline"} />
      </Tag>
      <small className="d-block">
        {newLabel} vs {oldLabel}
      </small>
    </>
  );
};

export default StatisticDiffStatus;
