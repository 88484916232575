import React, { FC, useEffect, useState } from "react";
import PageTitle from "../layout/pageTitle";
import { useApi } from "../services/useApi";
import { IdType, ScreenProps } from "../types/appTypes";
import { dealerStatisticsUrl } from "../services/dealerService";
import { dealersAutoComplete } from "../services/autocompleteService";
import _t from "../lang/translate";
import { Col, Result, Row, Space } from "antd";
import DateRange from "../components/form/dateRange";
import { StatisticsType } from "../types/statisticsType";
import StatisticsCard from "./forms/statistics/statisticsCard";
import StatisticsStore from "../contexts/statisticsStore";
import StatisticsTotals from "./forms/statistics/statisticsTotals";
import StatisticsCase from "./forms/statistics/statisticsCase";
import StatisticsCVR from "./forms/statistics/statisticsTax";
import PageBody from "../layout/pageBody";
import ServerSelectBox from "../components/serverSelectBox";

type DataType = { data: StatisticsType | null };

const Statistics: FC<ScreenProps> = ({ title }) => {
  const [{ data: dataObj, isLoading }, setUrl] = useApi<DataType>("", {
    data: null,
  });
  const [dealerId, setDealerId] = useState<IdType | null>(null);
  const [date, setDate] = useState<[string, string] | null>(null);

  const data = dataObj.data;

  useEffect(() => {
    if (dealerId) {
      const query = date && `filter[from]=${date[0]}&filter[to]=${date[1]}`;
      setUrl(dealerStatisticsUrl(dealerId, query || ""));
    }
  }, [date, dealerId, setUrl]);

  return (
    <>
      <StatisticsStore.Provider initialState={data || null}>
        <PageTitle
          title={title}
          fluid
          extra={
            <Space className="white-comp">
              <ServerSelectBox
                className="width-240"
                placeholder={_t("choose", "dealer")}
                apiUrl={dealersAutoComplete()}
                value={dealerId}
                onChange={(value) => typeof value === "number" && dealerId !== value && setDealerId(value)}
              />
              <DateRange value={date} onChange={setDate} className="width-240" />
            </Space>
          }
        />
        <PageBody>
          {!dealerId && <Result status="info" title={_t("choose", "dealer")} />}
          {data && (
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={16}>
                <StatisticsCard className="mb-2" title={_t("conversion_rate")} loading={isLoading} isEmpty={!data.rows}>
                  <StatisticsCVR />
                </StatisticsCard>
                {dealerId && <StatisticsCase dealerId={dealerId} />}
              </Col>
              <Col xs={24} lg={8}>
                <StatisticsCard loading={isLoading} title={_t("info")} isEmpty={!data.totals}>
                  <StatisticsTotals />
                </StatisticsCard>
              </Col>
            </Row>
          )}
        </PageBody>
      </StatisticsStore.Provider>
    </>
  );
};

export default Statistics;
