import React, { FC } from "react";
import { Button, Col, Row } from "antd";
import _t from "../../../../lang/translate";
import DividerLine from "../../../../components/lineSplit";
import ImportOfferActions from "./importOfferActions";
import ImportOfferInfoTable from "./importOfferInfoTable";
import ImportCarReportInfo from "../importCarReportInfo";

interface ImportReportInfoProps {
  startEditing: () => void;
}

const ImportReportInfo: FC<ImportReportInfoProps> = ({ startEditing }) => {
  return (
    <>
      <ImportOfferInfoTable />
      <ImportCarReportInfo />
      <Row justify="space-between" align="bottom">
        <Col />
        <Col>
          <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
            {_t("edit")}
          </Button>
        </Col>
      </Row>
      <DividerLine weight={1.5} fluid />
      <ImportOfferActions />
    </>
  );
};

export default ImportReportInfo;
