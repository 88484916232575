import { FC } from "react";
import CollapseCard from "../../../components/collapseCard";
import _t from "../../../lang/translate";
import CustomerStore from "../../../contexts/customerStore";
import CaseList from "../../../components/caseList";

const CustomerCasesCard: FC = () => {
  const { customer, isNew } = CustomerStore.useContainer();

  return (
    <CollapseCard title={_t("cases")} noPadding disable={isNew} defaultOpen={!isNew}>
      <CaseList context="customer" id={customer.id} />
    </CollapseCard>
  );
};

export default CustomerCasesCard;
