import React, { FC } from "react";
import { Alert, Button, Space, Typography } from "antd";
import appConfig from "../../../../appConfig";
import Icon from "../../../../assets/icons/icon";
import DividerLine from "../../../../components/lineSplit";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { openFileLink } from "../../../../services/mediaService";
import EntryCertificateDownloadBtn from "../entryCertificateDownloadBtn";
import PurchaseContractDownloadBtn from "../purchaseContractDownloadBtn";
import ImportOrderedInfo from "./importOrderedInfo";

const ImportReqestedView: FC = () => {
  const importEmail = appConfig("importEmail");
  const { importCase } = ImportCaseStore.useContainer();
  const { paymentAdvice } = importCase.links || {};

  return (
    <>
      <p className="mb-1">{_t("msg.thanks_for_request_import")}</p>
      <Alert type="warning" message={_t("msg.car_not_ordered_before_payment_done")} className="mb-1" closable />
      <div>
        <span className="mb-1 text-large d-block text-dark">
          <strong>1. {_t("sign_and_upload")}</strong> {_t("following", "documents").toLocaleLowerCase()}:
        </span>
        <Space className="mb-1" direction="vertical">
          <EntryCertificateDownloadBtn />
          <PurchaseContractDownloadBtn />
        </Space>
        <small className="d-block">
          {_t("upload_signed_documents_or_send_to")}{" "}
          <Typography.Link href={`mailto:${importEmail}`}>{importEmail}</Typography.Link>
        </small>
      </div>
      <DividerLine />
      <div>
        <span className="mb-1 text-large d-block text-dark">
          <strong>2. {_t("pay_full_price")}</strong>, {_t("by_downloading_invoice").toLocaleLowerCase()}:
        </span>
        <Button
          className="mb-1"
          type="primary"
          disabled={!paymentAdvice}
          ghost
          onClick={() => openFileLink(paymentAdvice)}
        >
          <Icon name="download-outline" size="large" />
          {_t("payment_advice")}
        </Button>
      </div>
      <DividerLine />
      <label>{_t("msg.steps_finished__do_nothing")}</label>
      <br />
      <small className="mb-2 d-block">
        {_t("msg.we_will_validate_documents_payment")}, {_t("msg.car_reserved_when_payment_done")}
      </small>
      <ImportOrderedInfo />
    </>
  );
};

export default ImportReqestedView;
