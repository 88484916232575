import { useHistory } from "react-router-dom";

type UseHashType = [hash: string | undefined, setHash: (hash?: string) => void];

function useHash(): UseHashType {
  const history = useHistory();

  const setHash = (hash?: string) => {
    history.replace({ ...history.location, hash });
  };
  let hash = history.location.hash;
  if (typeof hash === "string") {
    hash = hash.replace("#", "");
    hash = decodeURIComponent(hash);
  }
  return [hash, setHash];
}

export default useHash;
