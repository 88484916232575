import { Button, Form, message, Space } from "antd";
import { FC } from "react";
import DealerAdminStore from "../../../contexts/dealerAdminStore";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import { updateDealerSettings } from "../../../services/dealerService";
import { DealerSettingsType } from "../../../types/dealerTypes";
import consts from "../../../utilities/consts";
import { isAxiosError } from "../../../utilities/typeGuard";
import DealerSettingsFormFields from "./dealerSettingsFormFields";

interface DealerSettingsFormProps {
  stopEditing: () => void;
  setLoading: (x: boolean) => void;
}

const DealerSettingsForm: FC<DealerSettingsFormProps> = ({ stopEditing, setLoading }) => {
  const { dealer } = DealerStore.useContainer();
  const { admin, setAdmin } = DealerAdminStore.useContainer();

  const handleSave = async (values: DealerSettingsType) => {
    try {
      setLoading(true);
      await updateDealerSettings(dealer.id, values);
      setAdmin({ ...admin, settings: { ...admin.settings, ...values } });
      stopEditing();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form<DealerSettingsType>
      {...consts.formItemProps}
      className="m-0"
      initialValues={admin?.settings}
      requiredMark={true}
      onFinish={handleSave}
    >
      <DealerSettingsFormFields />
      <Form.Item noStyle shouldUpdate>
        {({ submit }) => (
          <div className="text-right pt-1">
            <Space>
              <Button tabIndex={-1} onClick={stopEditing}>
                {_t("cancel")}
              </Button>

              <Button type="primary" onClick={submit}>
                {_t("save")}
              </Button>
            </Space>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

export default DealerSettingsForm;
