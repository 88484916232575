import React, { FC } from "react";
import { Col, Row } from "antd";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import DividerLine from "../../../../components/lineSplit";
import { getFreeDocBuysLeftCount } from "../../../../services/authService";

const TaxDocPurchase: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();

  const feeAmount = taxCase.documentationSettings?.productPriceWithVat || 0;
  const feeWithoutTax = feeAmount / 1.25;
  const feeTaxes = feeAmount - feeWithoutTax;
  const defaultFee = ((taxCase.defaultFee || 0) / 2) * 1.25;
  const discount = defaultFee - (feeAmount || defaultFee);
  const freeDocsLeft = getFreeDocBuysLeftCount();

  return freeDocsLeft > 0 ? (
    <>
      <Row className="flex-wrap">
        <Col flex="auto">
          <div className="text-large text-dark">{_t("fee")}</div>
        </Col>
        <Col flex="80px" className="text-right">
          <div className="text-large text-dark">{_t('free')}</div>
        </Col>
      </Row>
      <Row>
        <Col flex="auto">
          <small>
            {_t('free_docs_left').replace('_freeDocsLeft_', freeDocsLeft)}
          </small>
        </Col>
      </Row>
    </>
  ) : (
    <>
      <Row className="flex-wrap mb-05">
        <Col flex="auto">
          <div className="text-large text-dark">
            {_t("fee")}&nbsp;
            {discount > 0 && (
              <span className="text-lighter text-small text-italic">
                ({_t('your', 'offer_discount')} {format.price(discount)})
              </span>
            )}
          </div>
        </Col>
        <Col flex="80px" className="text-right">
          <div className="text-large text-dark">{format.price(feeAmount)}</div>
        </Col>
      </Row>
      <Row className="flex-wrap mb-05">
        <Col flex="auto">
          <div className="text-large text-dark text-italic">{_t("with_moms")}</div>
        </Col>
        <Col flex="80px" className="text-right">
          <div className="text-large text-dark text-italic">{format.price(feeTaxes)}</div>
        </Col>
      </Row>
      <DividerLine fluid />
      <Row className="flex-wrap mb-2">
        <Col flex="auto">
          <h3 className="mb-0">{_t("total_price")}</h3>
        </Col>
        <Col flex="80px" className="text-right">
          <h3 className="mb-0 text-bold">{format.price((feeAmount || 0))}</h3>
        </Col>
      </Row>
    </>
  );
};

export default TaxDocPurchase;
