import { Button } from "antd";
import { FC } from "react";
import StatusTag from "../../../components/statusTag";
import _t from "../../../lang/translate";
import { PostType } from "../../../types/postTypes";
import format from "../../../utilities/formatNumbers";

interface PostViewProps {
  startEditing: () => void;
  post: PostType;
  canEdit: boolean;
}

const PostView: FC<PostViewProps> = ({ startEditing, post, canEdit }) => {
  const { isArchived, isUrgent, date, content } = post;
  return (
    <>
      <div className="mb-2">
        {isUrgent && (
          <StatusTag size="small" color="error">
            {_t("urgent")}
          </StatusTag>
        )}
        {isUrgent && isArchived ? ` • ` : ""}
        {isArchived && (
          <StatusTag color="default" size="small">
            {_t("archived")}
          </StatusTag>
        )}
      </div>
      <span className="muted d-block mb-05">{format.date(date)}</span>

      <p dangerouslySetInnerHTML={{ __html: content || "" }} />
      {canEdit && (
        <div className="text-right">
          <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
            {_t("edit")}
          </Button>
        </div>
      )}
    </>
  );
};

export default PostView;
