import React, { FC } from "react";
import { Tabs } from "antd";
import CollapseCard from "../../../../components/collapseCard";
import _t from "../../../../lang/translate";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import ImportCaseHandler from "./importCaseHandler";
import DividerLine from "../../../../components/lineSplit";
import ImportDownloads from "./importDownloads";
import ImportAfterOrderActions from "./importAfterOrderActions";
import ImportActionsBtns from "./importActionsBtns";
import ImportAutoFetch from "./importAutoFetch";
import ImportFullServiceToggle from "./importFullServiceToggle";

const { TabPane } = Tabs;

const ImportActionsCard: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { requested } = importCase.flags;
  const defaultActiveTab = requested ? "2" : "1";

  return (
    <CollapseCard title={_t("actions")} className="collapse-card-tabs">
      <Tabs defaultActiveKey={defaultActiveTab}>
        <TabPane tab={_t("before", "ordering")} key="1" className="pb-05">
          <ImportFullServiceToggle />
          <DividerLine fluid />
          <ImportCaseHandler />
          <DividerLine fluid />
          <ImportDownloads />
          <DividerLine fluid />
          <ImportAutoFetch />
          <DividerLine fluid className="mb-1" />
          <ImportActionsBtns isBeforeOrder />
        </TabPane>
        <TabPane tab={_t("after", "ordering")} key="2" className="pb-05">
          <ImportCaseHandler />
          <DividerLine fluid />
          <ImportAfterOrderActions />
          <DividerLine fluid className="mb-1" />
          <ImportActionsBtns />
        </TabPane>
      </Tabs>
    </CollapseCard>
  );
};

export default ImportActionsCard;
