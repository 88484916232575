import React, { FC, useState } from "react";
import { Button, message } from "antd";
import Icon from "../../../../assets/icons/icon";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { openFileLink } from "../../../../services/mediaService";

const TaxDownloadReceiptBtn: FC = () => {
  const [loading, setLoading] = useState<boolean>();
  const { taxCase } = TaxCaseStore.useContainer();
  const { flags, legalFiles } = taxCase;
  const downloadIcon = <Icon size="large" name="download-outline" />;
  const disabled = !taxCase.legalFiles?.length && !flags.receipted;

  const downloadReceipt = async () => {
    const link = legalFiles?.length && legalFiles[0]?.response?.url;
    setLoading(true)
    if (link) {
      await openFileLink(link)
    }
    setLoading(false)
  };

  return (
    <Button
      type="primary"
      ghost
      disabled={disabled}
      icon={downloadIcon}
      onClick={downloadReceipt}
      loading={loading}
    >
      {_t("receipt")}
    </Button>
  );
};

export default TaxDownloadReceiptBtn;
