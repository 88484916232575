import { FC } from "react";
import { Rate } from "antd";
import CardBox from "../../../../components/cardBox";
import ReviewHistoryButton from "../../../../components/review/reviewHistoryButton";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import { isNum } from "../../../../utilities/typeGuard";

const ImportMerchantRating: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const score = importCase.merchant?.rating || 0;
  const targetId = importCase.merchant?.id;

  const handleNewRating = (newRatingScore: number) => {
    // Populate the new rating score:
    if (isNum(newRatingScore) && importCase.merchant?.id) {
      const merchant = { ...importCase.merchant, rating: newRatingScore };
      setImportCase({ ...importCase, merchant });
    }
  };

  let color = "danger";
  if (score >= 4) {
    color = "success";
  } else if (score >= 3) {
    color = "yellow"
  }

  return (
    <CardBox className="text-center">
      <Rate
        allowHalf
        disabled
        value={score}
        className={`text-${color}-important`}
      />&nbsp;
      <ReviewHistoryButton
        disabled={!targetId || !isNum(score)}
        targetId={targetId || 0}
        targetContext="merchant"
        rating={score}
        onNewRating={handleNewRating}
      />
    </CardBox>
  );
};

export default ImportMerchantRating;
