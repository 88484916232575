import React, { FC, useEffect, useMemo, useState } from "react";
import { Button, Col, Form, FormInstance, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import _t from "../../../../../lang/translate";
import { TaxCalcRowType } from "../../../../../types/taxCalcTableTypes";
import CheckboxCell from "./checkboxCell";
import ShowFieldValue from "./showFieldValue";
import TaxaverageCell from "./taxaverageCell";
import TaxCalcAdTitle from "./taxCalcAdTitle";
import TaxCalcEquipmentName from "./taxCalcEquipmentName";
import TaxEquipmentsTotalCell from "./taxEquipmentsTotalCell";
import TaxVehicleCell from "./taxVehicleCell";
import TaxCalcEquipmentTotalName from "./taxCalcEquipmentTotalName";
import Icon from "../../../../../assets/icons/icon";
import { VehicleEquipment } from "../../../../../types/appTypes";
import TaxCaseStore from "../../../../../contexts/taxCaseStore";
import { loadPotentialEquipmentsByVin } from "../../../../../services/miscService";

interface TaxAdvancedCalculatorProps {
  onEquipmentAdd: () => void;
  onVehicleRemove: (id: number) => void;
  onEquipmentRemove: (id: number) => void;
  vehicles: any[];
  equipments: any[];
  calcForm: FormInstance;
  calculationDate?: string | null;
}

const TaxAdvancedCalculator: FC<TaxAdvancedCalculatorProps> = ({
  onEquipmentAdd,
  onVehicleRemove,
  calculationDate,
  onEquipmentRemove,
  vehicles,
  equipments,
  calcForm,
}) => {
  const { taxCase } = TaxCaseStore.useContainer()

  const toggleVehicleEquipments = (index: number) => {
    const formVals = calcForm.getFieldsValue(true);
    const { vehicles, equipments, vehicle } = formVals;

    let updatedVehicle;
    if (index >= 0) {
      updatedVehicle = { ...vehicles[index] };
    } else {
      updatedVehicle = { ...vehicle };
    }

    if (updatedVehicle.equipments?.length > 0) {
      updatedVehicle.equipments = [];
    } else {
      updatedVehicle.equipments = equipments?.map((eq: any) => eq.id) || [];
    }

    if (index >= 0) {
      const nextVehicles = [...vehicles];
      nextVehicles[index] = updatedVehicle;
      calcForm.setFieldsValue({ vehicles: nextVehicles });
    } else {
      calcForm.setFieldsValue({ vehicle: updatedVehicle });
    }
  }

  const [potentialEquipments, setPotentialEquipments] = useState<VehicleEquipment[]>([]);

  const fetchPotentialEquipments = async (vin: string) => {
    try {
      const { data } = await loadPotentialEquipmentsByVin(vin);
      const potentialEquipments = data?.data?.equipments;
      setPotentialEquipments(potentialEquipments);
    } catch (error) {
      setPotentialEquipments([]);
    }
  }

  useEffect(() => {
    const { vin } = taxCase?.vehicle || {};

    if (vin) {
      fetchPotentialEquipments(vin);
    }
  }, [taxCase]);

  const toggleEquipmentVehicles = (eqId: number) => {
    const formVals = calcForm.getFieldsValue(true);
    const { vehicles, vehicle } = formVals;

    const vehicleHasEquipment = !!vehicle.equipments?.filter((id: number) => id === eqId).length;
    const vehiclesHaveEquipment = !!vehicles.filter((veh: any) => {
      return !!veh.equipments?.filter((id: number) => id === eqId).length;
    }).length;
    const removeEquipment = vehiclesHaveEquipment || vehicleHasEquipment;

    const nextVehicle = { ...vehicle };
    let nextVehicleEquipments = !!nextVehicle.equipments?.length ? [...nextVehicle.equipments] : [];
    if (removeEquipment) {
      nextVehicleEquipments = nextVehicleEquipments.filter((id: number) => id !== eqId);
    } else {
      nextVehicleEquipments.push(eqId);
    }
    nextVehicle.equipments = nextVehicleEquipments;

    const nextVehicles = [...vehicles].map((nextVeh) => {
      let nextVehEquipments = !!nextVeh.equipments?.length ? [...nextVeh.equipments] : [];
      if (removeEquipment) {
        nextVehEquipments = nextVehEquipments.filter((id: number) => id !== eqId);
      } else {
        nextVehEquipments.push(eqId);
      }
      nextVeh.equipments = nextVehEquipments;
      return nextVeh;
    })

    calcForm.setFieldsValue({ vehicles: nextVehicles });
    calcForm.setFieldsValue({ vehicle: nextVehicle });
  }

  const getEquipments = (equipments?: any): TaxCalcRowType[] => {
    const equipmentList = Array.isArray(equipments) ? equipments : [];

    return equipmentList.map((equipment) => ({
      id: equipment.id,
      editable: true,
      type: "equipments",
      equipment,
    }));
  };

  const getVehicles = (vehicles?: any): ColumnsType<any> => {
    const vehiclesList = Array.isArray(vehicles) ? vehicles : [];

    return vehiclesList.map((veh, index) => ({
      key: "vehicle" + veh.id,
      title: <TaxCalcAdTitle vehicle={veh} index={index} onRemove={onVehicleRemove} />,
      width: 123,
      align: "center",
      dataIndex: "vehicle" + veh.id,
      render: (value, row) => (
        <TaxVehicleCell
          calculationDate={calculationDate}
          row={row}
          vehicle={veh}
          index={index}
          toggleVehicleEquipments={() => toggleVehicleEquipments(index)}
        />
      ),
    }));
  };

  const columns: ColumnsType<any> = useMemo(
    () => [
      {
        key: "name",
        title: _t("name"),
        dataIndex: "name",
        fixed: "left",
        width: 300,
        render: (value, row, index) => {
          const { type, equipment, id } = row || {};

          if (id === "equipment_list_title") {
            return (
              <Row justify="space-between" align="middle">
                <Col>{row.name}</Col>
                <Col>
                  <Button type="link" onClick={onEquipmentAdd} className="p-0">
                    + {_t("add")}
                  </Button>
                </Col>
              </Row>
            );
          }
          if (type === "equipments" && equipment) {
            const euroFactor = calcForm.getFieldValue('euroFactor');
            return (
              <Row align="middle" gutter={8} className="flex-nowrap">
                <Col>
                  <TaxCalcEquipmentName id={id} equipment={equipment} potentialEquipments={potentialEquipments} euroFactor={euroFactor} />
                </Col>
                <Col>
                  <Button size="small" onClick={() => toggleEquipmentVehicles(equipment.id)}>
                    <Icon name="checkbox-outline" />
                  </Button>
                </Col>
              </Row>
            );
          }
          if (type === "equipmentsTotal" || type === "equipmentsTotalUsed") {
            return <TaxCalcEquipmentTotalName equipments={equipments} used={type === "equipmentsTotalUsed"} />;
          }
          if (type === "equipmentsPrice") {
            return (
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const deduction = getFieldValue("total")?.equipmentYearFactorDeduction || 0;
                  return `${value} (${Math.round(deduction * 100)}%)`;
                }}
              </Form.Item>
            );
          }

          return value;
        },
      },
      {
        key: "vehicle",
        dataIndex: "vehicle",
        title: _t("our", "vehicle"),
        width: 123,
        align: "center",
        render: (value, row) => {
          const { type, equipment, id } = row || {};

          if (id === "equipment_list_title") {
            return (
              <Row justify="center" align="middle">
                <Col>
                  <Button size="small" onClick={() => toggleVehicleEquipments(-1)}>
                    <Icon name="checkbox-outline" />
                  </Button>
                </Col>
              </Row>
            );
          }

          if (type === "equipments") {
            return (
              <Form.Item name={["vehicle", type]} noStyle>
                <CheckboxCell equipmentId={equipment.id} />
              </Form.Item>
            );
          }

          if (type === "equipmentsTotal" || type === "equipmentsTotalUsed") {
            return (
              <Form.Item noStyle shouldUpdate={true}>
                {({ getFieldsValue }) => {
                  const values = getFieldsValue(true);
                  const equipments = values.equipments;
                  const vehicleEquipments = values.vehicle?.equipments;

                  return (
                    <Form.Item name={["vehicle", type]} noStyle>
                      <TaxEquipmentsTotalCell
                        used={type === "equipmentsTotalUsed"}
                        equipments={equipments}
                        vehicleEquipments={vehicleEquipments}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            );
          }

          return value;
        },
      },
      ...getVehicles(vehicles),
      {
        key: "average",
        title: _t("average"),
        dataIndex: "average",
        width: 122,
        fixed: "right",
        onCell: () => ({ className: "bg-light" }),
        render: (value, row) => (
          <TaxaverageCell onRemove={onEquipmentRemove} row={row} calculationDate={calculationDate} />
        ),
      },
    ],
    [vehicles, equipments]
  );

  const dataSource: TaxCalcRowType[] = useMemo(
    () => [
      {
        id: "year",
        name: _t("year"),
        type: "year",
        editable: true,
        vehicle: (
          <Form.Item name={["vehicle", "year"]} noStyle>
            <ShowFieldValue />
          </Form.Item>
        ),
      },
      {
        id: "price",
        name: _t("market_price"),
        type: "price",
        editable: true,
      },
      {
        id: "recalculatedPrice",
        name: _t("recalculated", "market_price"),
        type: "recalculatedPrice",
      },
      {
        id: "mileage",
        name: _t("mileage"),
        type: "mileage",
        editable: true,
        vehicle: "",
      },
      {
        id: "recalculatedMileage",
        name: _t("recalculated", "mileage"),
        type: "recalculatedMileage",
      },
      {
        id: "equipment_list_title",
        type: "equipmentListTitle",
        name: _t("equipment_list"),
      },
      ...getEquipments(equipments),
      {
        id: "equipmentsTotal",
        type: "equipmentsTotal",
      },
      {
        id: "equipmentsTotalUsed",
        type: "equipmentsTotalUsed",
      },
      {
        id: "equipmentsDiff",
        type: "equipmentsDiff",
        name: `${_t("diff")} (${_t("new_price")})`,
      },
      {
        id: "equipmentsDiffUsed",
        type: "equipmentsDiffUsed",
        name: `${_t("diff")} (${_t("used")})`,
      },
      {
        id: "equipmentsPrice",
        type: "equipmentsPrice",
        name: _t("rounded_down"),
      },
    ],
    [equipments]
  );

  const getRowClassName = (row: any) => {
    if (row.id === "equipment_list_title") return "flat-row text-dark bg-light text-medium";
    if (row.id === "equipmentsDiff" || row.id === "equipmentsDiffUsed" || row.id === "equipmentsPrice")
      return "flat-row";

    if (!row.editable) return "muted";

    return "";
  };

  return (
    <Table
      size="small"
      scroll={{ x: "max-content" }}
      bordered
      rowKey="id"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      onRow={(row) => ({
        className: getRowClassName(row),
      })}
    />
  );
};

export default TaxAdvancedCalculator;
