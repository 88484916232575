import { Button, Col, Row } from "antd";
import { FC } from "react";
import _t from "../../../../../lang/translate";

interface AdvCalcModalHeaderProps {
  onVehicleAdd: () => void;
  vehicleCount?: number | null;
  stopDragging: () => void;
  startDragging: () => void;
}

const AdvCalcModalHeader: FC<AdvCalcModalHeaderProps> = ({
  onVehicleAdd,
  vehicleCount,
  stopDragging,
  startDragging,
}) => {
  return (
    <div className="ant-modal-header cursor-move" onMouseOverCapture={stopDragging} onMouseOutCapture={startDragging}>
      <Row justify="space-between" align="middle">
        <Col className="ant-modal-title">
          <span>
            {vehicleCount} {_t("ads")}
          </span>
        </Col>
        <Col className="pr-3">
          <Button onClick={onVehicleAdd}>+ {_t("add", "vehicle")}</Button>
        </Col>
      </Row>
    </div>
  );
};

export default AdvCalcModalHeader;
