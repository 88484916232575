import { FC } from "react";
import { ParamTypes, ScreenProps } from "../../../../types/appTypes";
import { useParams } from "react-router-dom";
import { taxCaseUrl } from "../../../../services/taxService";
import { initTaxCase, TaxCase } from "../../../../types/taxTypes";
import { useApi } from "../../../../services/useApi";
import PageTitle from "../../../../layout/pageTitle";
import TaxCaseHeadExtra from "../taxCaseHeadExtra";
import AnimateIn from "../../../../components/animateIn";
import PageBody from "../../../../layout/pageBody";
import TaxCaseView from "./taxCaseView";
import TaxCaseStore from "../../../../contexts/taxCaseStore";

const TaxCaseUser: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading }] = useApi<TaxCase>(taxCaseUrl(id), initTaxCase, true);
  const caseRecieved = data.id > 0;

  return (
    <TaxCaseStore.Provider initialState={data}>
      <PageTitle title={`${title}/${id}`} fluid backBtn extra={caseRecieved && <TaxCaseHeadExtra />} />
      <PageBody>
        <AnimateIn start={!isLoading && caseRecieved} loadingBeforeStart>
          <TaxCaseView />
        </AnimateIn>
      </PageBody>
    </TaxCaseStore.Provider>
  );
};

export default TaxCaseUser;
