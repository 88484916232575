import React, { FC, useState } from "react";
import { Card, Col, Form, message, Row } from "antd";
import { useHistory } from "react-router-dom";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import PageTitle from "../../../layout/pageTitle";
import { getProductPrices } from "../../../services/authService";
import { createTaxDocCase } from "../../../services/taxDocService";
import { ScreenProps } from "../../../types/appTypes";
import { isTaxDataSource, TaxDocCaseCreation, TaxDocDataSourceType } from "../../../types/taxDocTypes";
import { convertToApiFileList } from "../../../utilities/convertTypes";
import TaxNewCaseAdminPanel from "../taxCommon/admin/taxNewCaseAdminPanel";
import TaxDocNewCaseForm from "./taxDocNewCaseForm";
import { getPath } from "../../../routes/appRoutes";
import consts from "../../../utilities/consts";
import LoadingContainer from "../../../components/loadingContainer";
import PageBody from "../../../layout/pageBody";
import DividerLine from "../../../components/lineSplit";
import FancySubmitButton from "../../../components/fancySubmitButton";
import { renderErrorMessage } from "../../../components/messages/errorMessage";

const TaxDocNewCase: FC<ScreenProps> = ({ title }) => {
  const { user, hasPermission, hasEnoughCredits } = UserStore.useContainer();
  const isAdmin = hasPermission("taxDoc.isAdmin");
  const [activeTab, setActiveTab] = useState<TaxDocDataSourceType>("vin");
  const [newTaxCaseForm] = Form.useForm<TaxDocCaseCreation>();

  const [loading, setLoading] = useState<boolean>(false);
  let history = useHistory();

  const initCase = {
    id: 0,
    isQuickCalculation: false,
    vehicle: {
      typeId: 1,
      link: "",
      vin: "",
    },
    note: null,
    files: null,
    paymentMethodId: 1,
    client: {
      userId: user?.id,
      dealerId: user?.dealer?.id,
      typeId: 1,
    },
  };

  const handleTabChange = (key: string) => isTaxDataSource(key) && setActiveTab(key);

  const createNewTaxDocCase = async (values: TaxDocCaseCreation) => {
    try {
      setLoading(true);
      let dataSourceId = activeTab === "vin" ? 2 : 1;
      const newTaxDocCase: TaxDocCaseCreation = {
        ...values,
        dataSourceId,
        files: convertToApiFileList(values?.files || []),
      };
      const { data } = await createTaxDocCase(newTaxDocCase);
      data.id && history.replace(getPath("taxDoc", data.id));
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  const canPurchase = hasEnoughCredits(getProductPrices()?.taxDoc || 0);

  const usageText = _t("when_click_calculate_you_use", "clip_from_your_account", ` ${canPurchase.usage.total} `);

  return (
    <>
      <PageTitle fluid backBtn title={title} />
      <PageBody>

        <Form
          {...consts.formItemProps}
          form={newTaxCaseForm}
          initialValues={initCase}
          requiredMark={false}
          onFinish={createNewTaxDocCase}
        >
          <Row align="top" gutter={24}>
            {isAdmin && (
              <Col {...consts.newCaseAdminColumnProps}>
                <TaxNewCaseAdminPanel loading={loading} />
              </Col>
            )}
            <Col {...consts.newCaseContentColumnProps(isAdmin)} flex="auto" order={2}>
              <Card bordered={false} title={_t("new", "tax-doc")}>
                <LoadingContainer loading={loading}>
                  <TaxDocNewCaseForm isAdmin={isAdmin} onTabChange={handleTabChange} activeTab={activeTab} />
                </LoadingContainer>
                <div className="text-center">
                  {!isAdmin && (
                    <div className="fancy-btn-wp text-center pt-1">
                      <DividerLine>
                        <small>
                          {!isAdmin && canPurchase.ok ? usageText : (
                            <span className="text-danger">
                              {_t('not_enough_credits')}
                            </span>
                          )}
                        </small>
                      </DividerLine>
                      <FancySubmitButton loading={loading} disabled={!canPurchase.ok} />
                    </div>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Form>
      </PageBody>
    </>
  );
};

export default TaxDocNewCase;
