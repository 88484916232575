import { FC } from "react";
import { Col, Divider, Row } from "antd";
import Details from "../../../../components/detailsList";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import ImportMerchantVat from "./importMerchantVat";
import ImportMerchantCompletedCases from "./importMerchantCompletedCases";
import ImportMerchantRating from "./importMerchantRating";
import { ContactPersonType } from "../../../../types/appTypes";

const ImportMerchantInfo: FC = () => {
    const { importCase } = ImportCaseStore.useContainer();
    const { merchantContactPersonId, merchant } = importCase;
    const {
        name,
        email,
        phone,
        address,
        zip,
        country,
        city,
        bank,
        iban,
        bic,
        contactPersons,
        note
    } = merchant || {};
    const contactPerson = contactPersons?.filter((cp) => cp.id === merchantContactPersonId)[0];
    const filteredContacts: ContactPersonType[] | undefined | null =
        merchantContactPersonId && contactPerson ? [contactPerson] : null;

    return (
        <>
            <Details borderLess>
                <Details.Item label={_t("name")} value={name || "-"} />
                <Details.Item label={_t("email")} value={email || "-"} />
                <Details.Item label={_t("phone")} value={phone || "-"} />
                <Details.Item label={_t("address")} value={address || "-"} />
                <Details.Item label={_t("zip")} value={zip || "-"} />
                <Details.Item label={_t("city")} value={city || "-"} />
                <Details.Item label={_t("country")} value={country || "-"} />
                <Details.Item label={_t("note")} value={note || "-"} />
                <Details.Item label={_t("bank")} value={bank || "-"} />
                <Details.Item label={_t("iban")} value={iban || "-"} />
                <Details.Item label={_t("bic")} value={bic || "-"} />
            </Details>
            <ImportMerchantVat className="mb-1" />
            <Row gutter={16}>
                <Col span={12}>
                    <ImportMerchantCompletedCases />
                </Col>
                <Col span={12}>
                    <ImportMerchantRating />
                </Col>
            </Row>

            {!!filteredContacts?.length && (
                <>
                    <Divider orientation="left">
                        <label className="text-normal text-small">{_t("contact_persons")}</label>
                    </Divider>
                    {filteredContacts?.map((cp, i) => (
                        <Details borderLess key={cp.id}>
                            <Details.Item label={_t("name")} value={cp.name || "-"} />
                            <Details.Item label={_t("email")} value={cp.email || "-"} />
                            <Details.Item label={_t("phone")} value={cp.phone || "-"} />
                            {i + 1 < filteredContacts.length && <Details.Divider className="mb-1" />}
                        </Details>
                    ))}
                </>
            )}
        </>
    );
};

export default ImportMerchantInfo;
