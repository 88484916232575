import React, { FC, useCallback, useState } from "react";
import { Button, Col, Form, message, Row, Space } from "antd";
import CardBox from "../../../../components/cardBox";
import LinkBox from "../../../../components/form/linkBox";
import LogText from "../../../../components/logText";
import SavedStatus from "../../../../components/savedStatus";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import _t from "../../../../lang/translate";
import { updateResellerTaxVehicle } from "../../../../services/resellerTaxService";
import { Vehicle } from "../../../../types/appTypes";
import consts from "../../../../utilities/consts";
import { compare } from "../../../../utilities/deepCompare";
import ResellerTaxVehicleInfo from "../resellerTaxVehicleInfo";
import debounce from "lodash/debounce";
import ResellerTaxVehicleSourceModal from "./resellerTaxVehicleSourceModal";
import CollapseCard from "../../../../components/collapseCard";
import ResellerTaxVehicleForm from "./resellerTaxVehicleForm";

const ResellerTaxVehicleEdit: FC = () => {
    const { resellerTaxCase, setResellerTaxCase } = ResellerTaxCaseStore.useContainer();
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [savedStatus, setSavedStatus] = useState<boolean>(true);
    const vehicleLink = resellerTaxCase.vehicle?.link;

    const startEditing = () => setIsEditing(true);
    const stopEditing = () => setIsEditing(false);

    const handleSave = async (values: Vehicle) => {
        try {
            setLoading(true);
            const { data } = await updateResellerTaxVehicle(resellerTaxCase.id, values);
            setResellerTaxCase(data);
            stopEditing();
        } catch ({ response }) {
            message.error(response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const handleFormChange = (changedValues: any, values: Vehicle) => {
        delayedSetMatched(values, resellerTaxCase.vehicle);
    };

    const delayedSetMatched = useCallback(
        debounce((values: Vehicle, savedValues: any) => {
            const isMatched = compare(values, savedValues);
            if (isMatched !== savedStatus) setSavedStatus(isMatched);
        }, 500),
        [setSavedStatus, savedStatus]
    );

    const vehicleInitialValues = resellerTaxCase.vehicle || {};
    vehicleInitialValues.addModelAlias = vehicleInitialValues.meta?.rawModel || null;
    vehicleInitialValues.addVariantAlias = vehicleInitialValues.meta?.rawVariant || null;

    return (
        <>
            <CollapseCard title={_t("vehicle")} loading={loading}>
                <Form
                    {...consts.formItemProps}
                    className="m-0"
                    onValuesChange={handleFormChange}
                    initialValues={vehicleInitialValues}
                    requiredMark={false}
                    onFinish={handleSave}
                >
                    {isEditing ? (
                        <>
                            {vehicleLink && (
                                <Form.Item label={_t("link")} shouldUpdate>
                                    {({ setFieldsValue }) => {
                                        return (
                                            <LinkBox
                                                link={vehicleLink}
                                                onFetch={(data) => {
                                                    const vehicle = { ...data.vehicle };
                                                    vehicle.typeId = resellerTaxCase.vehicle?.typeId;
                                                    setFieldsValue(vehicle);
                                                }}
                                            />
                                        );
                                    }}
                                </Form.Item>
                            )}
                            <ResellerTaxVehicleForm showFetchCarData dataSource={resellerTaxCase.vehicleDataSource} />
                        </>
                    ) : (
                        <>
                            <ResellerTaxVehicleInfo />
                            <CardBox className="mb-1" title={_t("note")}>
                                {resellerTaxCase.note || "-"}
                            </CardBox>
                        </>
                    )}

                    <div className="text-right">
                        {isEditing ? (
                            <Form.Item noStyle shouldUpdate>
                                {({ submit }) => (
                                    <Row justify="space-between">
                                        <Col>
                                            <Button onClick={stopEditing}>{_t("cancel")}</Button>
                                        </Col>
                                        <Col>
                                            <SavedStatus status={savedStatus} />
                                            <Button type="primary" onClick={submit}>
                                                {_t("save")}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </Form.Item>
                        ) : (
                            <Row justify="space-between" align="bottom">
                                <Col>
                                    <Space>
                                        <ResellerTaxVehicleSourceModal />
                                    </Space>
                                </Col>
                                <Col>
                                    <Button
                                        type="link"
                                        className="pl-0 pr-0 mt-1"
                                        onClick={startEditing}
                                    >
                                        {_t("edit")}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </div>
                </Form>
            </CollapseCard>
            {resellerTaxCase.log?.creation && (
                <LogText
                    className="p-0 text-right mt--1  mb-1"
                    small
                    logData={resellerTaxCase.log.creation}
                />
            )}
        </>
    );
};

export default ResellerTaxVehicleEdit;
