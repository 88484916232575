import { FC, useEffect } from "react";
import format from "../../../../../utilities/formatNumbers";
import { isNum } from "../../../../../utilities/typeGuard";
import { sumOfVehicleEquipment } from "../../calc/tradePriceSubCalculations";

interface TaxEquipmentsTotalCellProps {
  equipments?: any[] | null;
  vehicleEquipments?: number[] | null;
  used?: boolean;
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const TaxEquipmentsTotalCell: FC<TaxEquipmentsTotalCellProps> = ({
  vehicleEquipments,
  equipments,
  value,
  used,
  onChange,
}) => {

  useEffect(() => {
    onChange && onChange(sumOfVehicleEquipment(equipments, vehicleEquipments, used));
  }, [equipments, vehicleEquipments]);

  return <span>{isNum(value) ? format.number(value) : 0}</span>;
};

export default TaxEquipmentsTotalCell;
