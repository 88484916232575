import { Popover, Space, Tag } from "antd";
import { LogTextLabel } from "../../components/logText";
import _t from "../../lang/translate";
import { ImportCaseListType } from "../../types/importTypes";
import { importAfterOrderActionsStatus } from "../forms/import/admin/importAfterOrderActions";

const renderImportTableRelations = (_status: unknown, record: ImportCaseListType, index: number) => {
  const tagStyle = { margin: 0, padding: "2px 10px", cursor: "pointer" };
  const log = record.log;
  const processes = importAfterOrderActionsStatus(record.flags, record.expectedDeliveryDate);
  const isBuyerRelationDone = processes.isBuyerRelationDone,
    isMerchantRelationDone = processes.isMerchantRelationDone,
    isTransportDone = processes.isTransportDone;

  return (
    <Space size="small">
      <Popover
        title={_t("buyer_relation")}
        content={
          <Space direction="vertical" size="small">
            <LogTextLabel label={_t("purchase-contract-GR", "signed")} log={log.purchaseContractSigned} />
            <LogTextLabel label={_t("entry-certificate-GR", "signed")} log={log.entryCertificateSigned} />
            <LogTextLabel label={_t("take-home-agreement", "signed")} log={log.takeHomeAgreementSigned} />
            <LogTextLabel label={_t("payment", "received")} log={log.paid} />
          </Space>
        }
      >
        <Tag
          style={tagStyle}
          color={isBuyerRelationDone ? "success" : "default"}
        >
          {_t("buyer")}
        </Tag>
      </Popover>
      <Popover
        title={_t("merchant_relation")}
        content={
          <Space direction="vertical" size="small">
            <LogTextLabel label={_t("purchase-contract-GR", "ordered")} log={log.vehiclePurchaseContractRequested} />
            <LogTextLabel label={_t("purchase-contract-GR", "signed")} log={log.vehiclePurchaseContractSigned} />
            <LogTextLabel label={_t("car", "paid")} log={log.vehiclePaid} />
          </Space>
        }
      >
        <Tag
          style={tagStyle}
          color={isMerchantRelationDone ? "success" : "default"}
        >
          {_t("merchant")}
        </Tag>
      </Popover>
      <Popover
        title={_t("transport")}
        content={
          <Space direction="vertical" size="small">
            <LogTextLabel label={_t("pickup_self")} log={log.pickupSelf} />
            <LogTextLabel label={_t("transport", "ordered")} log={log.transportOrdered} />
          </Space>
        }
      >
        <Tag
          style={tagStyle}
          color={isTransportDone ? "success" : "default"}
        >
          {_t("transport")}
        </Tag>
      </Popover>
    </Space>
  )
}

export default renderImportTableRelations;
