import { Button, Space } from "antd";
import React, { FC, ReactNode, useState } from "react";
import Icon from "../assets/icons/icon";

interface CustomMsgProps {
  title?: string;
  content?: ReactNode;
  children?: ReactNode;
  icon?: ReactNode;
  closable?: boolean;
  className?: string;
  theme?: "light" | "gray" | "dark";
}

const CustomMsg: FC<CustomMsgProps> = ({
  title,
  content,
  children,
  theme = "gray",
  icon,
  className,
  closable,
}) => {
  const [closed, setClosed] = useState<boolean>(false);
  return !closed ? (
    <div className={`custom-message custom-message-${theme} ${className}`}>
      <Space size="large">
        {icon}
        <div>
          {title && <h2>{title}</h2>}
          {content || children}
        </div>
      </Space>
      {closable && (
        <Button
          className="muted close-btn"
          type="text"
          shape="circle"
          icon={<Icon name="close-outline" />}
          onClick={() => setClosed(true)}
        />
      )}
    </div>
  ) : null;
};

export default CustomMsg;
