import { GpsTrackerCalcMethodType, GpsTrackerOutputType } from "./gpsTrackerCalcTypes";

const gpsTrackerCalc: GpsTrackerCalcMethodType = ({ input, gpsTrackerInput }) => {
  const { active } = gpsTrackerInput;
  const leasingPeriod = input.leasingPeriod;

  const gpsUnitVt10 = 896;
  // const gpsSubscriptionPriceSplitleasing = 1468;
  const gpsSubscriptionTrackingPrice = 799;
  const gpsInstalling = 1000;

  // Initial output:
  let output: GpsTrackerOutputType = {
    monthlyPayment: null,
    annualPayment: null,
  };

  if (!leasingPeriod || !active) return output;

  const total = gpsUnitVt10 + gpsSubscriptionTrackingPrice + gpsInstalling;
  output.annualPayment = total;
  output.monthlyPayment = Math.ceil(total / leasingPeriod);

  return output;
};

export default gpsTrackerCalc;
