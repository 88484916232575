import { useMemo, useReducer, useState } from "react";
import { createContainer } from "unstated-next";
import { ChecklistProps } from "../components/checklist/checklist";
import { ChecklistEntryViewType, ChecklistGroupType, ExpandedObjType } from "../types/checklistTypes";

interface ChecklistUserStoreProps extends ChecklistProps {
  groups: ChecklistGroupType[];
  dispatchGroups: React.Dispatch<ActionGroupsType>;
  drawerVisibility: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  expanded: ExpandedObjType | null;
  setExpanded: React.Dispatch<React.SetStateAction<ExpandedObjType | null>>;
  loading: boolean;
  total: number;
  completedTotal: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

type ActionTypes = "populate" | "delete_group" | "add_group" | "add_entry" | "update_entry" | "delete_entry";
type ActionGroupsType = {
  type: ActionTypes;
  payload: ChecklistEntryViewType | ChecklistGroupType | ChecklistGroupType[];
};

function groupsReducer(state: ChecklistGroupType[], action: ActionGroupsType) {
  const { type, payload } = action;
  switch (type) {
    case "populate": {
      return payload as ChecklistGroupType[];
    }
    case "update_entry": {
      const nextState = [...state];
      const item = payload as ChecklistEntryViewType;
      const groupIndex = nextState.findIndex((g) => g.id === item.groupId);
      const itemIndex = nextState[groupIndex]?.entries.findIndex((e) => e.id === item.id);
      nextState[groupIndex].entries[itemIndex] = item;
      return nextState;
    }

    default:
      throw new Error("Unhandled reducer type: " + type);
  }
}

const useChecklistUserStore = (initialState?: ChecklistProps): ChecklistUserStoreProps => {
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [groups, dispatchGroups] = useReducer(groupsReducer, []);
  const [expanded, setExpanded] = useState<ExpandedObjType | null>(null);
  const openDrawer = () => setDrawerVisibility(true);

  const closeDrawer = () => {
    setExpanded(null);
    setDrawerVisibility(false);
  };

  const allEntries = useMemo(
    () =>
      Array.isArray(groups)
        ? groups?.reduce<ChecklistEntryViewType[]>((acc, item) => {
          const entries = item.entries || [];
          return (acc = [...acc, ...entries.filter((e) => !e.ignored)]);
        }, [])
        : [],
    [groups]
  );
  const total = allEntries.length;
  const completedTotal = allEntries?.filter((e) => e.completed).length;

  return {
    ...(initialState as ChecklistProps),
    groups,
    dispatchGroups,
    drawerVisibility,
    openDrawer,
    closeDrawer,
    expanded,
    total,
    completedTotal,
    setExpanded,
    loading,
    setLoading,
  };
};

const ChecklistUserStore = createContainer(useChecklistUserStore);

export default ChecklistUserStore;
