import { Button, Input, message } from "antd";
import { InputProps } from "antd/lib/input";
import React, { FC, useMemo, useState } from "react";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { CVRData } from "../../types/appTypes";
import { LoadingOutlined } from "@ant-design/icons";
import { getCVRData } from "../../services/miscService";
import { isAxiosError } from "../../utilities/typeGuard";

interface InputCVRProps extends InputProps {
  onFetched?: (data: CVRData) => void;
}

const InputCVR = React.forwardRef<HTMLElement, InputCVRProps>((props, ref) => {
  const { value, onChange, onFetched, disabled, className, size } = props;
  let nextProps = { ...props };
  delete nextProps["onFetched"];

  const [loading, setLoading] = useState<boolean>(false);

  const validCVR = useMemo(() => typeof value === "string" && value.length === 8, [value]);

  const fetchCVRData = async () => {
    setLoading(true);
    try {
      const { data } = await getCVRData(value as string);
      onFetched && onFetched(data?.data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9]/g, "");
    event.target.value = value;
    onChange && onChange(event);
  };

  if (onFetched) {
    const isLarge = size === "large";
    nextProps = {
      ...nextProps,
      onChange: handleChange,
      disabled: disabled || loading,
      className: isLarge ? className : "input-group-btn",
      [isLarge ? "suffix" : "addonAfter"]: (
        <Button
          ref={ref}
          className={isLarge ? "" : "p-0 m-0"}
          disabled={!validCVR || loading || disabled}
          onClick={fetchCVRData}
          type={isLarge ? "default" : "link"}
          icon={loading ? <LoadingOutlined /> : <Icon name="refresh-outline" />}
        />
      ),
    };
  }

  return <Input {...nextProps} />;
});

export default InputCVR;
