import React, { FC } from "react";
import { Button, Form, Input } from "antd";
import DateSelect from "../../../../components/form/dateSelect";
import InputNum from "../../../../components/form/inputNum";
import InputVin from "../../../../components/form/inputVin";
import ServerSelectBox from "../../../../components/serverSelectBox";
import _t from "../../../../lang/translate";
import { brandsAutoComplete, modelsAutoComplete } from "../../../../services/autocompleteService";
import ServerAutoFill from "../../../../components/serverAutoFill";

interface ImportVehicleFormProps {
  showFetchCarData?: boolean | undefined;
}

const ImportVehicleFormFields: FC<ImportVehicleFormProps> = ({ showFetchCarData }) => {
  return (
    <>
      <Form.Item rules={[{ required: true }]} name="brandId" label={_t("brand")}>
        <ServerSelectBox apiUrl={brandsAutoComplete()} />
      </Form.Item>
      <Form.Item shouldUpdate={(curr, next) => curr.brandId !== next.brandId} noStyle>
        {({ getFieldsValue }) => {
          const { brandId } = getFieldsValue();

          const url = modelsAutoComplete('', brandId);
          return (
            <Form.Item rules={[{ required: true }]} name="model" label={_t("model")}>
              <ServerAutoFill
                apiUrl={url}
                disabled={!brandId}
              />
            </Form.Item>
          );
        }}

      </Form.Item>

      <Form.Item name="equipmentVariant" label={_t("equipment_variant")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="firstRegDate" label={_t("firstRegDate")} rules={[{ required: true }]}>
        <DateSelect />
      </Form.Item>
      <Form.Item name="vin" label={_t("vin")}>
        <InputVin
          className="input-group-btn"
          maxLength={17}
          addonAfter={
            showFetchCarData ? (
              <Button onClick={() => {
              }} type="ghost">
                {_t("DMR")}
              </Button>
            ) : null
          }
        />
      </Form.Item>
      <Form.Item name="mileage" label={_t("mileage")} rules={[{ required: true }]}>
        <InputNum step={1000} min={0} suffix={<small>{_t("mileage_unit")}</small>} />
      </Form.Item>
    </>
  );
};

export default ImportVehicleFormFields;
