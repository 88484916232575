import { FC, useState } from "react";
import { Button, Empty, Modal } from "antd";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import _t from "../../../../lang/translate";
import ResellerTaxVehicleInfo from "../resellerTaxVehicleInfo";

const ResellerTaxVehicleSourceModal: FC = () => {
  const { resellerTaxCase } = ResellerTaxCaseStore.useContainer();
  const { vehicleDataSource } = resellerTaxCase;
  const [visible, setVisible] = useState<boolean>(false);
  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return (
    <>
      <Button onClick={openModal}>{_t("dealers_data")}</Button>
      <Modal visible={visible} footer={null} onCancel={closeModal} title={_t("dealers_data")}>
        {vehicleDataSource ? <ResellerTaxVehicleInfo vehicle={vehicleDataSource} /> : <Empty />}
      </Modal>
    </>
  );
};

export default ResellerTaxVehicleSourceModal;
