import { Divider, Form, Input, Switch } from "antd";
import React, { FC } from "react";
import DateSelect from "../../../../components/form/dateSelect";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";
import { brandsAutoComplete, modelsAutoComplete } from "../../../../services/autocompleteService";
import InputVin from "../../../../components/form/inputVin";
import EnumRadio from "../../../../components/form/EnumRadio";
import InputPrice from "../../../../components/form/inputPrice";
import BatteryCapacityInput from "./batteryCapacityInput";
import ServerSelectBox from "../../../../components/serverSelectBox";
import ServerAutoFill from "../../../../components/serverAutoFill";
import UnmatchedValueWarning from "../../../../components/unmatchedValueWarning";
import UserStore from "../../../../contexts/userStore";

interface TaxCarFormProps {
  showFetchCarData?: boolean | undefined;
  nested?: string[];
  disabled?: boolean;
  required?: boolean;
}

const TaxVehicleForm: FC<TaxCarFormProps> = ({ showFetchCarData, disabled, required = true, nested }) => {
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("taxDoc.isAdmin");

  return (
    <>
      <Form.Item name="typeId" label={_t("vehicle_type")} rules={[{ required }]}>
        <EnumRadio size="small" nameKey={["vehicle", "type"]} />
      </Form.Item>
      <Form.Item name="isNew" label={_t("new", "vehicle") + "?"} valuePropName="checked">
        <Switch disabled={disabled} size="small" />
      </Form.Item>
      <Form.Item shouldUpdate={(curr, next) => curr.brandId !== next.brandId} noStyle>
        {({ getFieldsValue }) => {
          const { typeId } = getFieldsValue();

          const url = brandsAutoComplete('', '', typeId);
          return (
            <Form.Item rules={[{ required }]} name="brandId" label={_t("brand")}>
              <ServerSelectBox
                apiUrl={url}
                disabled={!typeId || disabled}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item shouldUpdate={(curr, next) => curr.brandId !== next.brandId || curr.addModelAlias !== next.addModelAlias} noStyle>
        {({ getFieldsValue }) => {
          const { brandId, typeId, addModelAlias } = getFieldsValue();

          const url = modelsAutoComplete('', brandId, '', typeId);
          return (
            <Form.Item
              help={addModelAlias && <UnmatchedValueWarning unMatchedValue={addModelAlias} />}
              rules={[{ required: true }]}
              name="model"
              label={_t("model")}>
              <ServerAutoFill
                apiUrl={url}
                disabled={!brandId || !typeId || disabled}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item name="addModelAlias" hidden={true} />

      <Form.Item noStyle shouldUpdate>
        {({ getFieldsValue }) => {
          const { addVariantAlias } = getFieldsValue();

          return (
            <Form.Item
              name="equipmentVariant"
              label={_t("equipment_variant")}
              rules={[{ required }]}
              help={addVariantAlias && <UnmatchedValueWarning unMatchedValue={addVariantAlias} />}
            >
              <Input disabled={disabled} />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item name={nested ? [...nested, "addVariantAlias"] : "addVariantAlias"} hidden={true} />


      <Form.Item name="firstRegDate" label={_t("firstRegDate")} rules={[{ required }]}>
        <DateSelect disabled={disabled} />
      </Form.Item>

      <Form.Item name="vin" label={_t("vin")} >
        <InputVin disabled={disabled} />
      </Form.Item>

      <Form.Item name="mileage" label={_t("mileage")} rules={[{ required }]}>
        <InputNum disabled={disabled} step={1000} min={0} suffix={_t("mileage_unit")} />
      </Form.Item>

      <Divider className="mt-1 mb-1" />

      <Form.Item name="engineSize" label={_t("engine_size")}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item name="horsePower" label={_t("engine_power")}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item name="gearboxId" rules={[{ required }]} label={_t("gearbox")}>
        <EnumRadio size="small" disabled={disabled} nameKey={["vehicle", "gearbox"]} />
      </Form.Item>
      <Form.Item name="bodyId" rules={[{ required }]} label={_t("trim")}>
        <EnumRadio size="small" disabled={disabled} nameKey={["vehicle", "body"]} />
      </Form.Item>

      <Form.Item name="qualityId" label={_t("car_condition")} rules={[{ required }]}>
        <EnumRadio size="small" defaultActiveFirstOption disabled={disabled} nameKey={["vehicle", "quality"]} />
      </Form.Item>
      <Form.Item name="useCaseId" label={_t("special_use")} rules={[{ required }]}>
        <EnumRadio size="small" defaultActiveFirstOption disabled={disabled} nameKey={["vehicle", "useCase"]} />
      </Form.Item>

      <Divider className="mt-1 mb-1" />

      <Form.Item name="fuelId" rules={[{ required }]} label={_t("fuel_type")}>
        <EnumRadio size="small" disabled={disabled} nameKey={["vehicle", "fuel"]} />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) =>
          getFieldValue("typeId") == 1 ? null : (
            <Form.Item name="isHighWeight" label={_t("over_3000_kg")} valuePropName="checked">
              <Switch disabled={disabled} size="small" />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item name="emissionAmount" label={_t("co2_emission")}>
        <InputPrice decimalSize={2} suffix={_t("emission_unit")} maxLength={6} />
      </Form.Item>
      <Form.Item rules={[{ required }]} name="emissionUnitId" label={_t("co2_measurement")}>
        <EnumRadio size="small" nameKey={["vehicle", "emissionUnit"]} />
      </Form.Item>
      <Form.Item name="electricConsumption" label={_t("battery_usage")}>
        <InputNum suffix={_t("battery_usage_unit")} maxLength={5} />
      </Form.Item>
      <Form.Item name="electricRange" label={_t("battery_range")}>
        <InputNum suffix={_t("mileage_unit")} maxLength={5} />
      </Form.Item>
      <Form.Item
        shouldUpdate={(prev, next) =>
          prev?.electricRange !== next?.electricRange || prev?.electricConsumption !== next?.electricConsumption
        }
        noStyle
      >
        {({ getFieldValue }) => {
          const batteryRange = getFieldValue("electricRange") || 0;
          const batteryConsumption = (getFieldValue("electricConsumption") || 0) / 1000;
          const calculatedValue = parseFloat((batteryConsumption * batteryRange).toFixed(1));

          return (
            <Form.Item name="batteryCapacity" label={_t("battery_capacity")}>
              <BatteryCapacityInput suffix={_t("battery_unit")} calculatedValue={calculatedValue} />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Divider />
    </>
  );
};

export default TaxVehicleForm;
