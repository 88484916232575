import React, { FC } from "react";
import { Card } from "antd";
import CardBox from "../../../../components/cardBox";
import LogText from "../../../../components/logText";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import ImportCarReportInfo from "../importCarReportInfo";
import ImportVehicleInfo from "../importVehicleInfo";

const ImportVehicleView: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { offerPublished } = importCase.flags;

  return (
    <>
      <Card bordered={false} title={_t("vehicle")}>
        <ImportVehicleInfo />
        {offerPublished && <ImportCarReportInfo />}
        <CardBox title={_t("note")}>{importCase.note || "-"}</CardBox>
      </Card>
      {importCase.log?.creation && (
        <LogText className="p-0 text-right mt--1  mb-1" small logData={importCase.log.creation} />
      )}
    </>
  );
};

export default ImportVehicleView;
