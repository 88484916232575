import { FC } from "react";
import CollapseCard from "../../../components/collapseCard";
import DividerLine from "../../../components/lineSplit";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import LeasingContracts from "./leasingContracts";
import LeasingDealResults from "./leasingDealResults";
import LeasingDelivery from "./leasingDelivery";
import LeasingOffers from "./leasingOffers";
import LeasingValidation from "./leasingValidation";

interface LeasingActionsCardProps {
  inputSavedStatus: boolean
}

const LeasingActionsCard: FC<LeasingActionsCardProps> = ({ inputSavedStatus }) => {
  const { leasingCase } = LeasingStore.useContainer();

  return (
    <CollapseCard title={_t("actions")}>
      <LeasingDealResults />
      <DividerLine fluid />
      <LeasingOffers inputSavedStatus={inputSavedStatus} />
      <DividerLine fluid />
      <LeasingValidation inputSavedStatus={inputSavedStatus} />
      {!leasingCase.partnerCase && (
        <>
          <DividerLine fluid />
          <LeasingContracts />
          <DividerLine fluid />
          <LeasingDelivery />
        </>
      )}
    </CollapseCard>
  );
};

export default LeasingActionsCard;
