import { FC, useState } from "react";
import { Button, Col, Form, Modal, Row, Tooltip } from "antd";
import Icon from "../../../../assets/icons/icon";
import _t from "../../../../lang/translate";
import TaxRegestrationFeeInput from "./taxRegestrationFeeInput";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import RegFeeOutput from "../../taxCommon/admin/regFeeOutput";
import { RegFeeCalcInputType, RegFeeCalcOutputType } from "./../calc/regFeeCalculatorIndex";
import AutoCalcSwitch from "../../../../components/form/autoCalcSwitch";

const TaxRegestrationFee: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const [visible, setVisible] = useState<boolean>(false);
  const [hasCalculationError, setHasCalculationError] = useState<boolean>(false);

  const {
    isNew,
    firstRegDate,
    typeId,
    isHighWeight,
    emissionAmount,
    emissionUnitId,
    electricConsumption,
    electricRange,
    batteryCapacity,
  } = taxCase.vehicle || {};

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return (
    <Row className="flex-nowrap">
      <Col flex="auto">
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue, setFieldsValue }) => {
            const autoCalculate = getFieldValue(["registrationFee", "autoCalculate"]);
            const input: RegFeeCalcInputType = {
              firstRegDate,
              isNew: !!isNew,
              vehicleTypeId: typeId,
              taxablePrice: getFieldValue("taxablePrice") || 0,
              tradePrice: getFieldValue(["tradePrice", "tradePrice"]) || 0,
              newPrice: getFieldValue(["newPrice", "newPrice"]) || 0,
              isHighWeight: !!isHighWeight,
              emissionAmount,
              emissionUnitId,
              electricConsumption,
              electricRange,
              batteryCapacity
            };
            const handleOutput = (registrationFeeOutput: RegFeeCalcOutputType, projected: RegFeeCalcOutputType) => {
              const projected2025 = {
                calculation: projected,
                estimatedRegistrationFee: projected.registrationFee,
              }
              setHasCalculationError(false)
              const oldRegFee = getFieldValue("registrationFee");
              setFieldsValue({ registrationFee: { ...oldRegFee, registrationFeeOutput }, projected: {...projected2025} });
            };
            return (
              <>
                <Form.Item
                  name={["registrationFee", "registrationFee"]}
                  label={
                    <>
                      {_t("estimated_short", "registration_fee")}
                      <Tooltip title={_t("see_calculation")}>
                        <Button
                          size="small"
                          type="text"
                          shape="circle"
                          tabIndex={-1}
                          className="muted no-bg"
                          onClick={openModal}
                        >
                          <Icon name="information-circle" />
                        </Button>
                      </Tooltip>
                      {hasCalculationError && autoCalculate && (
                        <Tooltip title={_t("calculation_error")}>
                          <Button
                            size="small"
                            type="text"
                            shape="circle"
                            tabIndex={-1}
                            className="muted no-bg"
                            danger={true}
                          >
                            <Icon name="warning-outline" />
                          </Button>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  <TaxRegestrationFeeInput
                    min={0}
                    className="bg-white no-border"
                    suffix="Kr."
                    disabled={autoCalculate}
                    autoCalculate={autoCalculate}
                    onOutput={handleOutput}
                    onError={() => setHasCalculationError(true)}
                    input={input}
                  />
                </Form.Item>
                <Modal
                  visible={visible}
                  onCancel={closeModal}
                  footer={null}
                  width={800}
                  title={_t("registration_fee", "calculation")}
                >
                  <RegFeeOutput
                    output={getFieldValue("registrationFee")?.registrationFeeOutput}
                    regFee={getFieldValue("registrationFee")?.registrationFee}
                  />
                </Modal>
              </>
            );
          }}
        </Form.Item>
      </Col>
      <Col flex="49px" className="text-right">
        <Form.Item noStyle name={["registrationFee", "autoCalculate"]}>
          <AutoCalcSwitch />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default TaxRegestrationFee;
