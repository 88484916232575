import React, { FC, useState } from "react";
import { Col, message, Row } from "antd";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import SwitchInput from "../../../../components/form/switchInput";
import LogText from "../../../../components/logText";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { updatePurchaseContractSigned } from "../../../../services/importService";
import { isAxiosError } from "../../../../utilities/typeGuard";

const PurchaseContractSignedSwitch: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>();
  const { id, log, flags } = importCase;

  const handlePurchaseContractSigned: SwitchChangeEventHandler = async (v) => {
    try {
      setLoading(true);
      const { data } = await updatePurchaseContractSigned(id, { status: v });
      setImportCase(data);
      message.success(_t("saved"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row gutter={[8, 8]} className="mb-1">
      <Col>
        <SwitchInput
          color="green"
          size="small"
          loading={loading}
          id="purchase-contract-signed-switch"
          label={_t("purchase-contract-GR", "signed")}
          checked={flags.purchaseContractSigned}
          onChange={handlePurchaseContractSigned}
        />
      </Col>
      {log?.purchaseContractSigned && (
        <Col>
          <LogText mdash inline small className={"p-0"} logData={log.purchaseContractSigned} />
        </Col>
      )}
    </Row>
  );
};

export default PurchaseContractSignedSwitch;
