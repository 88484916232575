import { DatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import React, { FC } from "react";
import consts from "../../utilities/consts";

const { RangePicker } = DatePicker;

interface DateRangeSelectProps
  extends Omit<RangePickerProps, "onChange" | "value"> {
  value?: [string, string] | null;
  formate?: any[];
  onChange?: (date: [string, string] | null) => void;
}

const DateRange: FC<DateRangeSelectProps> = ({
  value,
  onChange,
  formate,
  ...rest
}) => {
  const handleChange = (values: any) => {
    let nextValue = values;
    if (Array.isArray(values) && values.length) {
      const start = values[0] ? values[0].toISOString() : null;
      const end = values[1] ? values[1].toISOString() : null;
      nextValue = [start, end];
    }

    onChange && onChange(nextValue);
  };

  return (
    <RangePicker
      value={value ? [moment(value[0]), moment(value[1])] : null}
      onChange={handleChange}
      format={formate || consts.dateFormats}
      {...rest}
    />
  );
};

export default DateRange;
