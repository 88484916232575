import React, { FC } from "react";
import { Row, Typography } from "antd";
import Col from "antd/es/grid/col";
import { ChecklistGroupType } from "../../types/checklistTypes";

interface ChecklistGroupHeaderProps {
  group: ChecklistGroupType;
}

const ChecklistGroupUserHeader: FC<ChecklistGroupHeaderProps> = ({ group }) => {
  return (
    <Row justify="space-between">
      <Col>
        <Typography.Title level={5} className="mb-0">
          {group.title}
        </Typography.Title>
      </Col>
    </Row>
  );
};

export default ChecklistGroupUserHeader;
