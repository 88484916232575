import { Column } from "@ant-design/charts";
import moment from "moment";
import React, { FC, useEffect } from "react";
import _t from "../../../lang/translate";
import { caseStatisticsUrl, } from "../../../services/dealerService";
import { useApi } from "../../../services/useApi";
import { IdType } from "../../../types/appTypes";
import StatisticsCard from "./statisticsCard";

interface StatisticsCaseProps {
  dealerId: IdType;
}

type StatisticesDataType = {
  data: Record<string, any>[];
};

const StatisticsCase: FC<StatisticsCaseProps> = ({ dealerId }) => {
  const [{ data, isLoading, isError }, setUrl] = useApi<StatisticesDataType>(
    "",
    {
      data: [],
    }
  );

  useEffect(() => {
    //const query = date && `filter[from]=${date[0]}&filter[to]=${date[1]}`;
    setUrl(caseStatisticsUrl(dealerId));
  }, [dealerId, setUrl]);

  const config = {
    data: data.data,
    isGroup: true,
    height: 400,
    xField: "date",
    yField: "value",
    seriesField: "name",
  };

  return (
    <StatisticsCard
      isEmpty={!data || isError}
      title={_t("preassessments")}
      loading={isLoading}
    >
      <Column
        {...config}
        xAxis={{
          label: {
            formatter: (data) => {
              return moment(data).format("MMM") || data;
            },
          },
        }}
      />
    </StatisticsCard>
  );
};

export default StatisticsCase;
