import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { initMerchant } from "../services/merchantService";
import { MerchantType } from "../types/merchantTypes";

interface MerchantStoreProps {
  merchant: MerchantType;
  isNew: boolean;
  setMerchant: React.Dispatch<React.SetStateAction<MerchantType>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useMerchantStore = (initialState?: MerchantType): MerchantStoreProps => {
  const [merchant, setMerchant] = useState<MerchantType>(initMerchant);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (initialState) {
      setMerchant(initialState);
    }
  }, [initialState]);

  return {
    merchant,
    setMerchant,
    loading,
    setLoading,
    isNew: merchant.id === 0,
  };
};

const MerchantStore = createContainer(useMerchantStore);

export default MerchantStore;
