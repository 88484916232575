import { Alert, Button, Form, Input, Radio, Space, Steps, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { NamePath } from "rc-field-form/lib/interface";
import { FC, useState } from "react";
import Icon from "../../../assets/icons/icon";
import DateSelect from "../../../components/form/dateSelect";
import SwitchInput from "../../../components/form/switchInput";
import DividerLine from "../../../components/lineSplit";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import ServerSelectBox from "../../../components/serverSelectBox";
import _t from "../../../lang/translate";
import { insuranceCompaniesAutoComplete } from "../../../services/autocompleteService";
import { generateContracts } from "../../../services/leasingService";
import { LeasingCaseType, LeasingContractGenerateType, LeasingPartialCaseType } from "../../../types/leasingTypes";
import moment from "moment";

const { Step } = Steps;

interface LeasingGenerateContractsFormProps {
  leasingCase: LeasingCaseType;
  onCancel: () => void;
  onGenerated: (data: LeasingPartialCaseType) => void;
}

const initialAgreementValues: LeasingContractGenerateType['agreements'] = {
  carPurchaseContactPersonEmail: null,
  carPurchaseContactPersonName: null,
  carPurchaseCvr: null,
  carPurchaseName: null,
  carPurchasePreference: "DMF ejer allerede køretøjet",
  carPurchaseRedemptionDate: null,
  expectedStartDate: null,
  extraAgreements: null,
  gpsContactPersonAddress: null,
  gpsContactPersonName: null,
  gpsContactPersonPhone: null,
  gpsContactPersonZipCity: null,
  gpsInstallDate: null,
  gpsInstallationPreference: "Nej",
  numberPlateMarking: "Med EU",
  numberPlateSource: "DMF",
  numberPlateType: "Hvide plader",
  registrationHandler: "DMF",
  registrationNotes: null,
  registrationType: "Nyregistrering"
}

const steps = [
  { stepIndex: 0, title: "Information" },
  { stepIndex: 1, title: "Registrering" },
  { stepIndex: 2, title: "Brugerregistrering" },
  { stepIndex: 3, title: "Tracker" },
  { stepIndex: 4, title: "Køb af bil" },
  { stepIndex: 5, title: "Valg af kontrakter" },
]

const LeasingGenerateContractsForm: FC<LeasingGenerateContractsFormProps> = ({
  leasingCase,
  onGenerated,
  onCancel,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id, customer, contract } = leasingCase;
  const [form] = useForm<LeasingContractGenerateType>()
  const [currentStep, setCurrentStep] = useState<number>(0)
  const nextStep = async () => {
    try {
      let namePath: NamePath[] | undefined = undefined;
      switch (currentStep) {
        case 0: namePath = ["insuranceCompanyId", "pbsRegNumber", "pbsAccountNumber", ["agreements", "expectedStartDate"], ["agreements", "extraAgreements"]]; break;
        case 1: namePath = [["agreements", "registrationType"], ["agreements", "registrationHandler"], ["agreements", "numberPlateSource"], ["agreements", "numberPlateType"], ["agreements", "numberPlateMarking"], ["agreements", "registrationNotes"]]; break;
        case 2: namePath = [
          ["secondaryDrivers", 0, "name"], ["secondaryDrivers", 0, "cpr"], ["secondaryDrivers", 0, "email"], ["secondaryDrivers", 0, "phone"],
          ["secondaryDrivers", 1, "name"], ["secondaryDrivers", 1, "cpr"], ["secondaryDrivers", 1, "email"], ["secondaryDrivers", 1, "phone"],
          ["secondaryDrivers", 2, "name"], ["secondaryDrivers", 2, "cpr"], ["secondaryDrivers", 2, "email"], ["secondaryDrivers", 2, "phone"],
          ["secondaryDrivers", 3, "name"], ["secondaryDrivers", 3, "cpr"], ["secondaryDrivers", 3, "email"], ["secondaryDrivers", 3, "phone"],
          ["secondaryDrivers", 4, "name"], ["secondaryDrivers", 4, "cpr"], ["secondaryDrivers", 4, "email"], ["secondaryDrivers", 4, "phone"],
          ["secondaryDrivers", 5, "name"], ["secondaryDrivers", 5, "cpr"], ["secondaryDrivers", 5, "email"], ["secondaryDrivers", 5, "phone"],
          ["secondaryDrivers", 6, "name"], ["secondaryDrivers", 6, "cpr"], ["secondaryDrivers", 6, "email"], ["secondaryDrivers", 6, "phone"],
          ["secondaryDrivers", 7, "name"], ["secondaryDrivers", 7, "cpr"], ["secondaryDrivers", 7, "email"], ["secondaryDrivers", 7, "phone"],
          ["secondaryDrivers", 8, "name"], ["secondaryDrivers", 8, "cpr"], ["secondaryDrivers", 8, "email"], ["secondaryDrivers", 8, "phone"],
        ]; break;
        case 3: namePath = [["agreements", "gpsInstallationPreference"], ["agreements", "gpsContactPersonName"], ["agreements", "gpsContactPersonPhone"], ["agreements", "gpsContactPersonAddress"], ["agreements", "gpsContactPersonZipCity"], ["agreements", "gpsInstallDate"]]; break;
        case 4: namePath = [["agreements", "carPurchasePreference"], ["agreements", "carPurchaseName"], ["agreements", "carPurchaseCvr"], ["agreements", "carPurchaseContactPersonName"], ["agreements", "carPurchaseContactPersonEmail"], ["agreements", "carPurchaseRedemptionDate"]]; break;
        // case 5: In the last step, step 6 (case 5) we submit the form, which will then perform validation.
      }
      await form.validateFields(namePath)
      setCurrentStep(prev => prev + 1)
    } catch (error) {
      // Do nothing. Ant design handles errors
    }
  }
  const prevStep = () => setCurrentStep(prev => prev - 1)

  const handleSubmit = async (values: LeasingContractGenerateType) => {
    try {
      setLoading(true);
      const { data } = await generateContracts(id, values);
      onGenerated(data);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const initialValues: LeasingContractGenerateType = {
    pbsAccountNumber: customer?.pbsAccountNumber || null,
    pbsRegNumber: customer?.pbsRegNumber || null,
    agreements: contract?.agreements || initialAgreementValues,
    insuranceCompanyId: contract?.insuranceCompany?.id || null,
    secondaryDrivers: contract?.secondaryDrivers || null,
    generateDocuments: null,
  };

  return (
    <>
      <Steps current={currentStep} responsive className="mb-2" labelPlacement="vertical" size="small" progressDot>
        {steps.map(step => (
          <Step key={step.stepIndex} stepIndex={step.stepIndex} title={step.title} />
        ))}
      </Steps>
      <DividerLine fluid style={{ marginBlock: 32 }} />
      <Typography.Title level={4} style={{ marginBottom: 32 }}>
        {steps[currentStep].title}
      </Typography.Title>
      <Form<LeasingContractGenerateType>
        form={form}
        layout="horizontal"
        labelCol={{ xs: 24, lg: 10, xl: 8, className: "text-left label-h-auto", }}
        wrapperCol={{ xs: 24, lg: 14, xl: 16 }}
        style={{minHeight: 435, display: "flex", flexDirection: "column"}}
        initialValues={initialValues}
        onFinish={handleSubmit}
        className="generate-contract-form"
        colon={false}
      >
        <div style={{marginBottom: "auto"}}>
          <div style={{ display: currentStep === 0 ? "block" : "none" }}><Step1 leasingCase={leasingCase} loading={loading} /></div>
          <div style={{ display: currentStep === 1 ? "block" : "none" }}><Step2 leasingCase={leasingCase} loading={loading} /></div>
          <div style={{ display: currentStep === 2 ? "block" : "none" }}><Step3 leasingCase={leasingCase} loading={loading} /></div>
          <div style={{ display: currentStep === 3 ? "block" : "none" }}><Step4 leasingCase={leasingCase} loading={loading} /></div>
          <div style={{ display: currentStep === 4 ? "block" : "none" }}><Step5 leasingCase={leasingCase} loading={loading} /></div>
          <div style={{ display: currentStep === 5 ? "block" : "none" }}><Step6 leasingCase={leasingCase} loading={loading} /></div>
        </div>

        <DividerLine fluid />
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <Button type="primary" danger onClick={onCancel} disabled={loading}>
            {_t("interrupt")}
          </Button>
          <Space>
            {currentStep > 0 && (
              <Button type="ghost" onClick={prevStep} disabled={loading}>
                &lt; {steps[currentStep - 1].title}
              </Button>
            )}
            {currentStep < 5 && (
              <Button type="primary" onClick={nextStep} disabled={loading}>
                {steps[currentStep + 1].title} &gt;
              </Button>
            )}
            {currentStep === 5 && (
              <Button htmlType="submit" loading={loading} type="primary">
                {_t("generate_contracts")}
              </Button>
            )}
          </Space>
        </Space>
      </Form>
    </>
  );
};

export default LeasingGenerateContractsForm

type StepProps = {
  loading: boolean;
  leasingCase: LeasingCaseType
}

function Step1({ leasingCase, loading }: StepProps) {
  const showInsuranceSelect = !leasingCase.deal?.insuranceInput?.active;
  return (
    <div>
      {showInsuranceSelect && (
        <>
          <Form.Item
            name="insuranceCompanyId"
            rules={[{ required: true }]}
            label={_t("insurance_company")}
          >
            <ServerSelectBox disabled={loading} apiUrl={insuranceCompaniesAutoComplete()} />
          </Form.Item>
          <DividerLine style={{ marginBlock: 16 }} />
        </>
      )}

      <Form.Item label={_t("PBS_reg_nr")} className="pbs-item" rules={[{ required: true }]}>
        <Form.Item name="pbsRegNumber" style={{ flex: "0 0 100px", margin: 0 }} rules={[{ required: true }]}>
          <Input disabled={loading} placeholder="reg. nr." />
        </Form.Item>
        <Form.Item name="pbsAccountNumber" style={{ flex: "1 1 0%", margin: 0 }} rules={[{ required: true }]}>
          <Input disabled={loading} placeholder="konto nr." />
        </Form.Item>
      </Form.Item>

      <DividerLine style={{ marginBlock: 16 }} />
      <Form.Item name={["agreements", "expectedStartDate"]} label={_t("expected_start_date")} rules={[{ required: true }]}>
        <DateSelect disabled={loading}
          disabledDate={
            (currentDate) => currentDate && currentDate < moment().subtract(1, 'day').endOf('day')
          }
        />
      </Form.Item>
      <DividerLine style={{ marginBlock: 16 }} />
      <Form.Item name={["agreements", "extraAgreements"]} label={_t("extra_agreements")}>
        <TextArea disabled={loading} />
      </Form.Item>
    </div>
  )
}

function Step2({ leasingCase, loading }: StepProps) {
  return (
    <div>
      <Form.Item label="Registreringsform" rules={[{ required: true }]} name={["agreements", "registrationType"]}>
        <Radio.Group
          optionType="button"
          buttonStyle={"solid"}
          disabled={loading}
        >
          <Radio.Button value={'Nyregistrering'}>
            Nyregistrering
            </Radio.Button>
          <Radio.Button value={'Omregistrering fra anden bruger'}>
            Omregistrering fra anden bruger
            </Radio.Button>
          <Radio.Button value={'Fornyelse af leasingaftale'}>
            Fornyelse af leasingaftale
            </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item label={"Hvem foretager registrering?"} rules={[{ required: true }]} name={["agreements", "registrationHandler"]}>
        <Radio.Group
          optionType="button"
          buttonStyle={"solid"}
          disabled={loading}
        >
          <Radio.Button value={'DMF'}>
            DMF
          </Radio.Button>
          <Radio.Button value={'Forhandler'}>
            Forhandler
          </Radio.Button>
          <Radio.Button value={'Synshal'}>
            Synshal
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item label={"Hvor afhentes der nummerplader?"} rules={[{ required: true }]} name={["agreements", "numberPlateSource"]}>
        <Radio.Group
          optionType="button"
          buttonStyle={"solid"}
          disabled={loading}
        >
          <Radio.Button value={'DMF'}>
            DMF
          </Radio.Button>
          <Radio.Button value={'Forhandler'}>
            Forhandler
          </Radio.Button>
          <Radio.Button value={'Synshal'}>
            Synshal
          </Radio.Button>
        </Radio.Group>
      </Form.Item>


      <Form.Item noStyle shouldUpdate={(prev, next) => prev.agreements.registrationType !== next.agreements.registrationType}>
        {({ getFieldValue }) => {
          const registrationType = getFieldValue(['agreements', 'registrationType'])
          return registrationType === "Nyregistrering" && (
            <>
              <Form.Item label={"Nummerpladetype"} rules={[{ required: true }]} name={["agreements", "numberPlateType"]}>
                <Radio.Group
                  optionType="button"
                  buttonStyle={"solid"}
                  disabled={loading}
                >
                  <Radio.Button value={'Hvide plader'}>
                    Hvide plader
                  </Radio.Button>
                  <Radio.Button value={'Gule plader'}>
                    Gule plader
                  </Radio.Button>
                  <Radio.Button value={'Papegøjeplader'}>
                    Papegøjeplader
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item label={"Nummerplademærkning"} rules={[{ required: true }]} name={["agreements", "numberPlateMarking"]}>
                <Radio.Group
                  optionType="button"
                  buttonStyle={"solid"}
                  disabled={loading}
                >
                  <Radio.Button value={'Med EU'}>
                    Med EU
                  </Radio.Button>
                  <Radio.Button value={'Uden EU'}>
                    Uden EU
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </>
          )
        }}

      </Form.Item>
      <DividerLine style={{ marginBlock: 16 }} />
      <Form.Item name={["agreements", "registrationNotes"]} label={"Evt. note til registrering af køretøjet"} >
        <TextArea disabled={loading} />
      </Form.Item>
    </div>
  )
}

function Step3({ leasingCase, loading }: StepProps) {
  return (
    <div>
      <Typography.Paragraph className="mb-03">
        Ved leasing til erhverv, af personbiler og varebiler på papegøjeplader, er det lovpligtigt at registrere sekundære brugere af køretøjet.
      </Typography.Paragraph>
      <Typography.Paragraph className="mb-1">
        For at overholde lovgivningen og undgå unødvendige problemer anbefales det, at alle relevante oplysninger udfyldes korrekt ved oprettelsen af leasingaftalen
      </Typography.Paragraph>
    <Form.List name="secondaryDrivers">
      {(fields, { add, remove },) => {
        return (
          <>
            {fields.map(field => (
              <div key={field.key}>
                <Space className="mb-1">
                  <Typography.Title className="mb-0" level={5}>{_t("secondary_user")} {field.name + 1}</Typography.Title>
                  <Button type="link" danger icon={<Icon size="large" name="trash-outline" />} onClick={() => { remove(field.name) }} />
                </Space>
                <Form.Item fieldKey={[field.fieldKey, "name"]} name={[field.name, "name"]} label={_t("name")} rules={[{ required: true }]}>
                  <Input disabled={loading} />
                </Form.Item>

                <Form.Item name={[field.name, "cpr"]} fieldKey={[field.fieldKey, "cpr"]} label={_t("cpr", "number")} rules={[{ required: true }]}>
                  <Input disabled={loading} />
                </Form.Item>

                <Form.Item name={[field.name, "phone"]} fieldKey={[field.fieldKey, "phone"]} label={_t("phone")} rules={[{ required: true }]}>
                  <Input type="tel" disabled={loading} />
                </Form.Item>

                <Form.Item name={[field.name, "email"]} fieldKey={[field.fieldKey, "email"]} label={_t("email")} rules={[{ required: true, pattern: /^\w+@\w+\.\w{2,3}$/, message: "En gyldig email er påkrævet!" }]}>
                  <Input type="email" autoComplete="email" disabled={loading} />
                </Form.Item>

                <DividerLine style={{ marginBlock: 16 }} />
              </div>
            ))}
            <Button style={{ marginLeft: "auto", display: "block" }} onClick={() => add()}>+ Tilføj ekstra sekundær bruger</Button>
          </>
        )
      }}
    </Form.List>
    </div>
  )
}

function Step4({ leasingCase, loading }: StepProps) {
  return (
    <div>
      <Typography.Paragraph className="mb-1">
        Ved valg af DMF Flådeforsikring igennem Købsstedernes Forsikring er der krav om montering af tracker i køretøjet, hvis forsikringssummen <Typography.Text strong>overstiger kr. 200.000,-</Typography.Text>. Forsikringssummen er køretøjets indkøbs-/markedspris uden registreringsafgift.
      </Typography.Paragraph>
      <Form.Item label={"Skal der monteres tracker i bilen?"} rules={[{ required: true }]} name={["agreements", "gpsInstallationPreference"]}>
        <Radio.Group
          optionType="button"
          buttonStyle={"solid"}
          disabled={loading}
        >
          <Radio.Button value={"Ja"}>
            Ja
          </Radio.Button>
          <Radio.Button value={"Nej"}>
            Nej
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(curr, next) => curr.agreements.gpsInstallationPreference !== next.agreements.gpsInstallationPreference}>
        {({ getFieldValue }) => {
          const gpsTrackerOn = getFieldValue(["agreements", "gpsInstallationPreference"])
          return gpsTrackerOn === "Ja" &&  (
            <>
              <DividerLine style={{ marginBlock: 16 }} />
              <Form.Item name={["agreements", "gpsContactPersonName"]} label={_t("contact_person") + ", " + _t("name")} rules={[{ required: true }]}>
                <Input disabled={loading} />
              </Form.Item>

              <Form.Item name={["agreements", "gpsContactPersonPhone"]} label={_t("contact_person") + ", " + _t("phone_short")} rules={[{ required: true }]}>
                <Input type="tel" disabled={loading} />
              </Form.Item>

              <Form.Item name={["agreements", "gpsContactPersonAddress"]} label={_t("address")} rules={[{ required: true }]}>
                <Input autoComplete="street-address" disabled={loading} />
              </Form.Item>

              <Form.Item name={["agreements", "gpsContactPersonZipCity"]} label={_t("zip") + " & " + _t("city")} rules={[{ required: true }]}>
                <Input disabled={loading} />
              </Form.Item>

              <Form.Item name={["agreements", "gpsInstallDate"]} label={"Ønske til dato for montering"}>
                <DateSelect disabled={loading}
                  disabledDate={
                    (currentDate) => currentDate && currentDate < moment().subtract(1, 'day').endOf('day')
                  }
                />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>

    </div>
  )
}

function Step5({ leasingCase, loading }: StepProps) {
  return (
    <div>
      <Typography.Paragraph className="mb-1">
        Dansk Motor Finans A/S skal som leasinggiver have ejendomsretten over køretøjet før opstart af leasingaftalen. <br />
        Nedenfor angives information på sælger af køretøjet, så Leasing Backoffice får de bedst mulige forudsætninger for behandling af sagen og rettidigt køb af køretøjet.
      </Typography.Paragraph>
      <Form.Item label={"Anskaffelse af bil"} rules={[{ required: true }]} name={["agreements", "carPurchasePreference"]}>
        <Radio.Group
          optionType="button"
          buttonStyle={"solid"}
          disabled={loading}
        >
          <Radio.Button value={"DMF ejer allerede køretøjet"}>
            DMF ejer allerede køretøjet
          </Radio.Button>
          <Radio.Button value={"DMF skal købe køretøjet"}>
            DMF skal købe køretøjet
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(curr, next) => curr.agreements.carPurchasePreference !== next.agreements.carPurchasePreference}>
        {({ getFieldValue }) => {
          const carPurchase = getFieldValue(["agreements", "carPurchasePreference"])
          return carPurchase === "DMF skal købe køretøjet" &&  (
            <>
              <DividerLine style={{ marginBlock: 16 }} />
              <Form.Item name={["agreements", "carPurchaseName"]} label={_t("name")} rules={[{ required: true }]}>
                <Input disabled={loading} />
              </Form.Item>

              <Form.Item name={["agreements", "carPurchaseCvr"]} label={_t("cvr")} rules={[{ required: true }]}>
                <Input disabled={loading} />
              </Form.Item>

              <Form.Item name={["agreements", "carPurchaseContactPersonName"]} label={_t("contact_person") + ", " + _t("name")} rules={[{ required: true }]}>
                <Input disabled={loading} />
              </Form.Item>

              <Form.Item name={["agreements", "carPurchaseContactPersonEmail"]} label={_t("contact_person") + ", " + _t("email")} rules={[{ required: true }]}>
                <Input type="email" autoComplete="email" disabled={loading} />
              </Form.Item>

              <Form.Item name={["agreements", "carPurchaseRedemptionDate"]} label={"Eventuelt dato for indfrielse"}>
                <DateSelect disabled={loading} disabledDate={
                    (currentDate) => currentDate && currentDate < moment().subtract(1, 'day').endOf('day')
                  }/>
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </div>
  )
}
function Step6({ leasingCase, loading }: StepProps) {
  const generatableDocuments = leasingCase.contract?.generatableDocuments;
  return (
    <div>
      {generatableDocuments && (
        <Form.Item label={_t("contracts")}>
          {generatableDocuments.map((doc) => {
            const { id, label, checked, toggleable } = doc;
            return (
              <Form.Item
                key={id}
                name={["generateDocuments", id]}
                initialValue={checked}
                valuePropName="checked"
                noStyle
              >
                <SwitchInput label={label} disabled={!toggleable || loading} className="mb-05" />
              </Form.Item>
            );
          })}
        </Form.Item>
      )}

      <Alert type="warning" className="mt-2" message={_t("msg.generate_contract_lead")} />

    </div>
  )
}
