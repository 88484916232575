import React, { FC } from "react";
import _t from "../lang/translate";
import { getClipCount, getCreditCount, getSubscription, getUpcomingSubscription } from "../services/authService";
import { Col, Progress, Row, Tooltip, Typography } from "antd";
import { SubscriptionType } from "../types/subscriptionTypes";
import Icon from "../assets/icons/icon";
import { Link } from "react-router-dom";
import { getPath } from "../routes/appRoutes";
import { IdType } from "../types/appTypes";
import { ProgressGradient } from "antd/lib/progress/progress";

const { Title } = Typography;

interface CurrentSubscriptionInfoProps {
  hideDescription?: boolean;
  darkMode?: boolean;
  dealerId?: IdType;
  collapsible?: boolean;
  statusAsDot?: boolean;
}

interface StatusBadgeProps {
  currentSubscription: SubscriptionType;
  upcomingSubscription?: SubscriptionType | null;
  asDot?: boolean;
}

interface UsageBarProps {
  creditsPerPeriod: number;
  creditsLeft: number;
  clipsLeft?: number | null;
  darkMode?: boolean;
}

const UsageBar: FC<UsageBarProps> = ({ clipsLeft, creditsLeft, creditsPerPeriod, darkMode }) => {
  const finalClipsLeft = clipsLeft || 0;
  const barMax = creditsPerPeriod > finalClipsLeft ? creditsPerPeriod : finalClipsLeft;
  const creditsUsed = creditsPerPeriod - creditsLeft;
  const creditsUsedPercent = barMax === 0 ? 0 : Math.round((creditsUsed / barMax) * 100);

  let clipsLeftPercent;
  if (finalClipsLeft === barMax) {
    clipsLeftPercent = 100;
  } else if (finalClipsLeft === 0 || barMax === 0) {
    clipsLeftPercent = 0;
  } else {
    clipsLeftPercent = Math.round((finalClipsLeft / barMax) * 100);
  }

  function getStrokeColor(): string | ProgressGradient {
    if (creditsUsedPercent >= 100) {
      return '#e1001a'
    }
    return { from: "#0299FB", to: "#0A80CC" }
  }
  return (
    <div className="position-relative">
      <Progress
        percent={creditsUsedPercent}
        size="small"
        showInfo={false}
        strokeWidth={6}
        strokeColor={getStrokeColor()}
        trailColor={darkMode ? "#021F37" : "#f1f2f5"}
        className="d-block"
      />
      <Progress
        percent={clipsLeftPercent}
        style={{ mixBlendMode: darkMode ? "overlay" : "normal" }}
        size="small"
        showInfo={false}
        strokeWidth={6}
        strokeColor={darkMode ? "#CCEBFE" : "#CCEBFEAA"}
        trailColor="transparent"
        className="position-absolute top-0 left-0"
      />
    </div>
  )
}

export const SubscriptionStatusBadge: FC<StatusBadgeProps> = ({ currentSubscription, upcomingSubscription, asDot }) => {
  let text = null;
  let color = "text-success";

  if (currentSubscription.isTrial) {
    text = _t('trial');
    color = "text-primary"
  } else if (currentSubscription.isExpired) {
    text = _t("expired");
    color = "text-warning"
  } else if (!!currentSubscription.endsAt && !upcomingSubscription) {
    text = _t('expires');
    color = "text-warning"
  } else if (currentSubscription.isPastDue) {
    text = _t('unpaid');
    color = "text-danger"
  } else {
    text = _t('active');
  }

  if (asDot) {
    return (
      <Tooltip title={text}>
        <span className={`${color} p-03 cursor-pointer`} style={{ fontSize: 32, lineHeight: '10px' }}>&bull;</span>
      </Tooltip>
    )
  }

  return text ? (
    <Row
      className="status-badge-blue-outline status-badge status-badge-rounder d-inline-flex"
      align="middle"
      justify="center"
    >
      <Col flex={"none"} className={`${color} text-huge`}>&bull;</Col>
      <Col className="text-tiny text-secondary ml-05">{text}</Col>
    </Row>
  ) : null;
}

const CurrentSubscriptionInfo: FC<CurrentSubscriptionInfoProps> = ({ hideDescription, darkMode, dealerId, collapsible, statusAsDot }) => {
  const currentSubscription = getSubscription();
  const upcomingSubscription = getUpcomingSubscription();
  const creditAmount = getCreditCount();
  const clipAmount = getClipCount();
  const creditsPerPeriod = currentSubscription?.package?.creditsPerPeriod || 3;
  const creditsUsed = creditsPerPeriod - creditAmount;
  if (currentSubscription && darkMode) {
    return (
      <DarkModeSubscriptionInfoLayout
        creditsPerPeriod={creditsPerPeriod}
        creditsUsed={creditsUsed}
        creditsAmount={creditAmount}
        clipAmount={clipAmount}
        currentSubscription={currentSubscription}
        upcomingSubscription={upcomingSubscription}
        hideDescription={hideDescription}
        dealerId={dealerId}
        collapsible={collapsible}
        statusAsDot={statusAsDot}
      />
    )
  }

  return currentSubscription && (
    <>
      <Row align="middle">
        <Col>
          <Title level={1} className="m-0">{currentSubscription.package?.title}</Title>
        </Col>
        <Col flex={1} className="ml-05">
          <SubscriptionStatusBadge
            currentSubscription={currentSubscription}
            upcomingSubscription={upcomingSubscription}
            asDot={statusAsDot}
          />
        </Col>
      </Row>

      <div className="text-gray mb-1 text-italic">{hideDescription ? ' ' : currentSubscription.package?.description}</div>

      <div className="d-flex justify-content-between text-bold">
        <span className="hide-on-collapse">{_t("clip_use")}</span>
        <span>
          {`${creditsUsed} / ${creditsPerPeriod}`}
          {clipAmount > 0 && (
            <span>&nbsp;(+{ clipAmount})</span>
          )}
        </span>
      </div>
      <UsageBar creditsPerPeriod={creditsPerPeriod} creditsLeft={creditAmount} clipsLeft={clipAmount} />
      <p className="text-secondary text-medium text-smallest mt-03 hide-on-collapse">
        {_t("clipRefillEveryFirstOfMonth")}
      </p>

      <Link className=" text-medium mt-05 hide-on-collapse"
        to={getPath("dealer", dealerId)}
      >
        {_t("administer_subscription")}
      </Link>
    </>
  );
};

export default CurrentSubscriptionInfo;

const DarkModeSubscriptionInfoLayout = ({
  currentSubscription,
  upcomingSubscription,
  hideDescription,
  creditsUsed,
  creditsAmount,
  clipAmount,
  creditsPerPeriod,
  dealerId,
  collapsible,
  statusAsDot
}: {
  currentSubscription: SubscriptionType,
  upcomingSubscription?: SubscriptionType | null,
  hideDescription?: boolean,
  creditsUsed: number,
  creditsAmount: number,
  creditsPerPeriod: number,
  clipAmount: number,
  dealerId?: IdType,
  collapsible?: boolean;
  statusAsDot?: boolean;
}) => {

  let collapseClass = "";
  if (collapsible) {
    collapseClass = "collapsible-subscription-info";
  }

  return (
    <div className={`p-2 w-100 d-flex flex-column ${collapseClass}`}>
      <span className="text-tiny text-medium text-secondary hide-on-collapse">
        {_t("yourSubscriptionPackage")}:
      </span>
      <div className="mt-05 mb-075 d-flex">
        <Icon name="wallet-outline" size="large" className="text-white-important" />
        <Title level={4} style={{maxWidth: 147}} className="ml-05 mb-0 mt-0 text-white-important hide-on-collapse text-ellipsis">{currentSubscription.package?.title}</Title>
        <SubscriptionStatusBadge
          currentSubscription={currentSubscription}
          upcomingSubscription={upcomingSubscription}
          asDot={statusAsDot}
        />
      </div>

      {!hideDescription && (
        <div className="text-secondary text-medium mb-1 text-italic hide-on-collapse">
          {currentSubscription.package?.description}
        </div>
      )}


      <div className="d-flex justify-content-between text-medium text-smallest text-white">
        <span className="hide-on-collapse">{_t("clip_use")}</span>
        <span>
          {`${creditsUsed} / ${creditsPerPeriod}`}
          {clipAmount > 0 && (
            <span>&nbsp;(+{ clipAmount})</span>
          )}
        </span>
      </div>

      <div className="hide-on-collapse">
        <UsageBar creditsPerPeriod={creditsPerPeriod} creditsLeft={creditsAmount} clipsLeft={clipAmount} darkMode />
      </div>

      <span className="text-secondary text-medium text-tiny mt-03 hide-on-collapse">
        {_t("clipRefillEveryFirstOfMonth")}
      </span>

      <Link className="text-smallest text-medium mt-05 hide-on-collapse"
        to={getPath("dealer", dealerId)}
      >
        {_t("administer_subscription")}
      </Link>
    </div>
  )
}
