import React, { FC } from "react";
import { Card, Form, Input } from "antd";
import EnumSelect from "../../../components/form/enumSelect";
import { useEnums } from "../../../components/useEnum";
import _t from "../../../lang/translate";
import { customersAutoComplete, dealersAutoComplete, usersAutoComplete } from "../../../services/autocompleteService";
import EnumRadio from "../../../components/form/EnumRadio";
import UserStore from "../../../contexts/userStore";
import LoadingContainer from "../../../components/loadingContainer";
import FancySubmitButton from "../../../components/fancySubmitButton";
import ServerSelectBox from "../../../components/serverSelectBox";

const { TextArea } = Input;

interface TaxNewCaseAdminPanelProps {
  loading: boolean;
}

const ImportNewCaseAdmin: FC<TaxNewCaseAdminPanelProps> = ({ loading }) => {
  const [clientType] = useEnums(["client", "type"]);
  const { user } = UserStore.useContainer();
  const userId = user?.id;
  const dealerId = user?.dealer?.id;

  return (
    <Card bordered={false} title={_t("case_created_for")}>
      <LoadingContainer loading={loading}>
        <Form.Item name={["client", "typeId"]}>
          <EnumRadio defaultActiveFirstOption nameKey={["client", "type"]} />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.client?.typeId !== curr.client?.typeId}>
          {({ getFieldValue }) => {
            const typeId = getFieldValue(["client", "typeId"]);
            return (
              clientType &&
              typeId === clientType[0]?.id && (
                <Form.Item
                  name={["client", "dealerId"]}
                  initialValue={dealerId}
                  label={_t("dealer")}
                  rules={[{ required: true }]}
                >
                  <ServerSelectBox placeholder={_t("choose", "dealer")} apiUrl={dealersAutoComplete()} />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) =>
            prev.client?.dealerId !== curr.client?.dealerId || prev.client?.typeId !== curr.client?.typeId
          }
        >
          {({ getFieldValue }) => {
            const dealerId = getFieldValue(["client", "dealerId"]);
            const typeId = getFieldValue(["client", "typeId"]);

            return (
              clientType &&
              typeId === clientType[0]?.id && (
                <Form.Item
                  name={["client", "userId"]}
                  initialValue={userId}
                  label={_t("user")}
                  rules={[{ required: true }]}
                >
                  <ServerSelectBox
                    apiUrl={usersAutoComplete()}
                    disabled={!dealerId}
                    placeholder={_t("choose", "user")}
                    filters={dealerId ? `filter[dealerId]=${dealerId}` : undefined}
                  />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.client?.typeId !== curr.client?.typeId}>
          {({ getFieldValue }) => {
            const typeId = getFieldValue(["client", "typeId"]);
            return (
              clientType &&
              typeId === clientType[1]?.id && (
                <Form.Item name={["client", "customerId"]} label={_t("customer")} rules={[{ required: true }]}>
                  <ServerSelectBox apiUrl={customersAutoComplete()} placeholder={_t("choose", "customer")} />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form.Item name="paymentMethodId" label={_t("payment")} rules={[{ required: true }]}>
          <EnumSelect defaultActiveFirstOption nameKey={["paymentMethod"]} />
        </Form.Item>
        <Form.Item name="reason" label={_t("notice")}>
          <TextArea autoSize={{ minRows: 2, maxRows: 4 }} placeholder="Første sag rabat.." />
        </Form.Item>
      </LoadingContainer>
      <div className="fancy-btn-wp text-center">
        <FancySubmitButton loading={loading} />
      </div>
    </Card>
  );
};

export default ImportNewCaseAdmin;
