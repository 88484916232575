import React, { FC } from "react";
import CaseHeadClient from "../../../../components/caseHeadClient";
import openModal from "../../../../components/modal/openModal";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import { TaxDocCase } from "../../../../types/taxDocTypes";

const TaxDocCaseDealer: FC = () => {
  const { taxDocCase, setTaxDocCase } = TaxDocCaseStore.useContainer();
  const { client, id } = taxDocCase;

  const handleClientChange = async () => {
    try {
      const newCase = await openModal<TaxDocCase>("changeClient", { id, client, context: "taxDoc" });
      setTaxDocCase(newCase);
    } catch (ex) {
    }
  };

  return <CaseHeadClient onClientChange={handleClientChange} client={client} />;
};

export default TaxDocCaseDealer;
