import { FC, useState } from "react";
import CollapseCard from "../../../components/collapseCard";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import DealerInfoForm from "./dealerInfoForm";
import DealerInfoView from "./dealerInfoView";

const DealerInfoCard: FC = () => {
  const { dealer, loading, isNew } = DealerStore.useContainer();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <CollapseCard title={isNew ? _t("new", "dealer") : dealer.name || _t("main_info")} loading={loading}>
      {isEditing || isNew ? (
        <DealerInfoForm stopEditing={stopEditing} />
      ) : (
        <DealerInfoView startEditing={startEditing} />
      )}
    </CollapseCard>
  );
};

export default DealerInfoCard;
