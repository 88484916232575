import React, { FC } from "react";
import { Button, Result } from "antd";
import _t from "../lang/translate";
import { useHistory } from "react-router-dom";

interface UnauthorizedProps {
  title: string;
}

const Unauthorized: FC<UnauthorizedProps> = ({ title }) => {
  const history = useHistory();
  return (
    <>
      <Result
        status="403"
        title={title}
        subTitle={_t("msg.unauthorized_description")}
        extra={<Button onClick={() => history.goBack()}>{_t("go_back")}</Button>}
      />
    </>
  );
};

export default Unauthorized;
