import React, { FC } from "react";
import Details from "../../../../components/detailsList";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";
import DividerLine from "../../../../components/lineSplit";

const TaxOfferBaseInfo: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const { isNew } = taxCase.vehicle || {};
  const {
    taxablePrice,
    newPrice,
    tradePrice,
    registrationFee,
  } = taxCase.calculation || {};

  return (
    <>
      <h4 className="text-lighter mb-1">
        {_t('calculated', 'evaluation')}
      </h4>

      <Details borderLess className="mb-2">
        <div className="bg-light fluid-24 pl-1 pr-1 pt-05 pb-05 mb-05">
          {isNew ? (
            <Details.Item
              className="mb-05"
              label={_t("taxable_value")}
              value={format.price(taxablePrice)}
            />
          ) : (
            <>
              <Details.Item
                className="mb-05"
                label={_t("new_price")}
                value={format.price(newPrice?.newPrice)}
              />
              <Details.Item
                label={_t("trade_price")}
                value={format.price(tradePrice?.tradePrice)}
              />
            </>
          )}
          <DividerLine className="mt-05 mb-05" />
          <Details.Item
            bold={true}
            value={format.price(registrationFee?.registrationFee)}
            label={_t("estimated_short", "registration_fee")}
          />
        </div>
      </Details>
    </>
  );
};

export default TaxOfferBaseInfo;
