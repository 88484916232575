import { Space, Typography } from "antd";
import { BaseType } from "antd/lib/typography/Base";
import React, { FC, ReactNode } from "react";
import { LogObj } from "../types/appTypes";
import format from "../utilities/formatNumbers";
import _t from "../lang/translate";
import {millisToMinutes} from "../utilities/date";

interface LogTextProps {
  logData: LogObj | null | undefined;
  inline?: boolean;
  mdash?: boolean;
  noStyle?: boolean;
  small?: boolean;
  type?: BaseType;
  seprator?: ReactNode;
  className?: string;
  align?: "left" | "center" | "right";
  timeToSendOfferInMs?: number | null;
}

const LogText: FC<LogTextProps> = ({
  logData,
  className,
  small,
  mdash,
  noStyle,
  seprator = "•",
  inline,
  align,
  type = "secondary",
  timeToSendOfferInMs
}) => {
  const classes = [];
  if (className) {
    classes.push(className);
  }
  if (small) {
    classes.push("text-smallest");
  }
  if (align) {
    classes.push(`text-${align}`);
  }
  classes.push(inline ? "d-inline" : "pt-05 pb-05");

  const date = format.dateAndTime(logData?.date || null);

  return logData && logData.date ? (
    <div className={classes.join(" ")}>
      {mdash && <>&mdash; </>}
      {logData?.person && (
        <>
          {noStyle ? (
            <>
              {logData.person.fullName || "-"} {seprator} {date}
            </>
          ) : (
            <>
              <Typography.Text type={type} ellipsis className="log-person-name">
                {logData.person.fullName || "-"}
              </Typography.Text>
              {seprator && <Typography.Text type={type}>&nbsp;{seprator}&nbsp;</Typography.Text>}
              <Typography.Text type={type}>{date}</Typography.Text>
            </>
          )}
        </>
      )}
      <br />
      {timeToSendOfferInMs &&
      <Typography.Text type={type}>
        <b>{_t('timeTakenToSendOffer')} </b>&nbsp;{millisToMinutes(timeToSendOfferInMs)} {_t('minutes')}
      </Typography.Text>}
    </div>
  ) : null;
};

export default LogText;


type LogTextLabelProps = {
  label: React.ReactNode;
  log: LogObj | null;
}
export const LogTextLabel = ({ label, log }: LogTextLabelProps) => {

  return (
    <Space size="small">
      <small>
        {label}
      </small>
      {log ? (
        <LogText mdash small className={"p-0"} logData={log} />
      ) : <>&mdash;</>}
    </Space>
  )
}
