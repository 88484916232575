import { Button, Input, message } from "antd";
import { InputProps } from "antd/lib/input";
import React, { FC, useMemo, useState } from "react";
import _t from "../../lang/translate";
import { getVinDetails } from "../../services/vehicleService";
import { VinVehicle } from "../../types/appTypes";
import { isAxiosError } from "../../utilities/typeGuard";
import { isValidVin } from "../../utilities/stringExtraFunctions";
import Icon from "../../assets/icons/icon";
import PopoverList from "../popups/popoverList";

interface InputVinProps extends InputProps {
  onFetched?: ((data: VinVehicle) => void) | null | false;
  searchText?: string | null;
  withAliasCreate?: boolean;
}

const InputVin: FC<InputVinProps> = ({ value, onFetched, searchText, withAliasCreate, disabled, ...rest }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const validVin = useMemo(() => isValidVin(value), [value]);
  const vinLength = typeof value === 'string' ? value.length : 0;
  const fetchVinData = async () => {
    setLoading(true);
    try {
      const { data } = await getVinDetails(value as string);
      onFetched && onFetched(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  let nextProps: InputProps = {
    value: value?.toString().toUpperCase(),
    maxLength: 90,
    disabled,
    ...rest,
  };

  if (onFetched) {
    nextProps = {
      ...nextProps,
      disabled: disabled || loading,
      className: "input-group-btn",
      addonAfter: (
        <Button onClick={fetchVinData} disabled={!validVin || disabled} loading={loading} type="ghost">
          {searchText || _t("DMR")}
        </Button>
      ),
    };
  } else {
    nextProps = {
      ...nextProps,
      suffix: validVin ? (
        <Icon name="checkmark-circle-outline" className="text-blue" size="medium" />
      ) : (
        <PopoverList list={[
          _t("msg.vin_invalid"),
          _t("msg.vin_required_length") + " " + _t("vin") + " " + _t("is").toLowerCase() + " " + vinLength + " " + _t('characters').toLowerCase(),
          _t("msg.vin_required_characters")
        ]}>
          <Icon name="alert-circle-outline" className="text-warning" size="medium" />
        </PopoverList>
      ),
    };
  }


  return (
    <Input {...nextProps} />
  );
};

export default InputVin;
