import React, { FC } from "react";
import { Avatar } from "antd";
import { useParams } from "react-router-dom";
import { useActiveUsers } from "../hooks/useActiveUsers";
import { ParamTypes, ProductType } from "../types/appTypes";
import sortAfterTime from "../utilities/sortAfterTimeStamp";

interface ActiveUsersProps {
  context: ProductType;
}

const ActiveUsers: FC<ActiveUsersProps> = ({ context }) => {
  const { id } = useParams<ParamTypes>();
  const [activeUsers] = useActiveUsers(`${context}.${id}`);

  return (
    <Avatar.Group maxCount={3} className="ml-1 d-inline-block">
      {sortAfterTime(activeUsers).map((user, i) => (
        <Avatar
          size="small"
          key={user.id}
          className={`mr-0 active-user-avatar${i === 0 ? " first-active-user" : ""}`}
          style={{ zIndex: 20 - i }}
        >
          {user.initials}
        </Avatar>
      ))}
    </Avatar.Group>
  );
};

export default ActiveUsers;
