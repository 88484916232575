import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { DealerAdminType, initDealerAdmin } from "../types/dealerTypes";
import UserStore from "./userStore";

interface DealerStoreProps {
  admin: DealerAdminType;
  setAdmin: (newAdmin: DealerAdminType) => void;
  hasStatisticsPermission: boolean;
}

const useDealerAdminStore = (initialState?: DealerAdminType): DealerStoreProps => {
  const [admin, setAdmin] = useState<DealerAdminType>(initDealerAdmin);
  const { hasPermission } = UserStore.useContainer();

  useEffect(() => {
    if (initialState) {
      setAdmin(initialState);
    }
  }, [initialState]);

  return {
    admin,
    setAdmin,
    hasStatisticsPermission: hasPermission("dealer.statistics"),
  };
};

const DealerAdminStore = createContainer(useDealerAdminStore);

export default DealerAdminStore;
