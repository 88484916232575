import { Button, Col, Form, message, Row } from "antd";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import SavedMessage from "../../../components/messages/savedMessage";
import MerchantStore from "../../../contexts/merchantStore";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { saveMerchant } from "../../../services/merchantService";
import { MerchantType } from "../../../types/merchantTypes";
import consts from "../../../utilities/consts";
import MerchantFormFields from "./merchantFormFields";

interface MerchantInfoFormProps {
  stopEditing: () => void;
}

const MerchantInfoForm: FC<MerchantInfoFormProps> = ({ stopEditing }) => {
  let history = useHistory();
  const { merchant, setMerchant, isNew, setLoading } = MerchantStore.useContainer();
  const [merchantForm] = Form.useForm<MerchantType>();

  const handleSave = async (values: MerchantType) => {
    try {
      setLoading(true);
      const { data } = await saveMerchant({ ...merchant, ...values });
      message.success(<SavedMessage message={_t("saved")} />);
      if (isNew) {
        history.replace(getPath("merchant", data.id));
      } else {
        stopEditing();
        setMerchant(data);
        setLoading(false);
      }
    } catch ({ response }) {
      const msg = response?.data?.message;
      const errors = response?.data?.errors;
      errors && merchantForm.setFields(errors);
      message.error(msg || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={merchant}
      form={merchantForm}
      {...consts.formItemProps}
      requiredMark
      onFinish={handleSave}
      className="mb-0"
    >
      <MerchantFormFields />
      <Form.Item noStyle shouldUpdate>
        {({ submit }) =>
          isNew ? (
            <div className="text-right mt-2">
              <Button type="primary" onClick={submit}>
                {_t("create", "merchant")}
              </Button>
            </div>
          ) : (
            <Row justify="space-between" className="mt-2">
              <Col>
                <Button onClick={stopEditing}>{_t("cancel")}</Button>
              </Col>
              <Col>
                <Button type="primary" onClick={submit}>
                  {_t("save")}
                </Button>
              </Col>
            </Row>
          )
        }
      </Form.Item>
    </Form>
  );
};

export default MerchantInfoForm;
