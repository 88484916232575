import notification from "antd/lib/notification";
import { NotifiFuncs } from "../types/notificationsType";

const notifi: NotifiFuncs = {
  success: notification.success,
  error: notification.error,
  info: notification.info,
  warning: notification.warning,
  default: notification.open,
};

export default notifi;
