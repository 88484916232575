import { FC, useState } from "react";
import { Button, Col, Modal, Popover, Row, Space, Tag, Tooltip, Typography } from "antd";
import Icon from "../assets/icons/icon";
import { Link, useHistory } from "react-router-dom";
import _t from "../lang/translate";
import PageTitle from "../layout/pageTitle";
import { ScreenProps } from "../types/appTypes";
import { CustomColumnsType } from "../components/table/dataTableTypes";
import format from "../utilities/formatNumbers";
import { getPath } from "../routes/appRoutes";
import { pageKey, statusKey } from "../services/urlQueryService";
import UserStore from "../contexts/userStore";
import { ImportCaseListType } from "../types/importTypes";
import ImportFilterdCases from "./importFilteredCases";
import StatusTag from "../components/statusTag";
import { getImportStatuses } from "../services/importService";
import useActiveStatus from "../hooks/useActiveStatus";
import StatusTabs from "../components/statusTabs";
import ImportTableFilter from "./import/importTableFilter";
import TableStatistics from "./tableStatistics";
import PromoCard from "../components/promoCard";
import Text from "antd/lib/typography/Text";
import promoBanner from "../assets/images/promo-banner.png";
import appConfig from "../appConfig";
import renderImportTableRelation from "./import/renderImportTableRelations";

const ImportCases: FC<ScreenProps> = ({ title }) => {
  const history = useHistory();
  const { hasPermission } = UserStore.useContainer();

  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const statuses = getImportStatuses();
  const [activeStatusId, setActiveStatusId] = useActiveStatus(Object.values(statuses));
  const isAdmin = hasPermission("import.isAdmin");

  const columns: CustomColumnsType<ImportCaseListType> = [
    {
      title: "#",
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 110,
      render: (text, record) =>
        hasPermission("screen.import") ? (
          <Link to={getPath("import", record.id)}>
            <strong>{text}</strong>
          </Link>
        ) : (
          text
        ),
      fixed: "left",
    },
    {
      title: _t("date"),
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: true,
      width: 190,
      render: format.dateAndTime,
    },
    {
      title: _t("customer"),
      key: "clientName",
      dataIndex: "clientName",
      //sorter: true,
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            {isAdmin && record.dealerIsPriority && (
              <Icon name="star" size="small" color="lightblue" className="mr-05" />
            )}
            {text}
          </>
        );
      },
    },
    {
      title: _t("info"),
      key: "info",
      dataIndex: "info",
      hidden: true,
      render: (text) =>
        text && (
          <Tooltip placement="bottom" title={text}>
            <Icon name="information-circle-outline" />
          </Tooltip>
        ),
    },
    {
      title: _t("vehicle"),
      key: "car",
      ellipsis: true,
      dataIndex: "car",
      sorter: true,
    },
    {
      title: _t("mileage_unit"),
      key: "mileage",
      dataIndex: "mileage",
      sorter: true,
      width: 110,
      render: format.milage,
    },
    {
      title: _t("firstRegDate"),
      key: "firstRegDate",
      dataIndex: "firstRegDate",
      sorter: true,
      render: format.date,
    },
    {
      title: _t("expected_short", "delivery_date"),
      key: "expectedDeliveryDate",
      dataIndex: "expectedDeliveryDate",
      render: format.date,
    },
    {
      title: _t("vin_short"),
      key: "vin",
      dataIndex: "vin",
      sorter: true,
      hidden: true,
      width: 195,
    },
    {
      title: _t("status"),
      key: "status",
      sorter: true,
      width: 220,
      dataIndex: "status",
      render: (status, record) => (
        <Space>
          <StatusTag status={status} size="small" />
          {isAdmin && typeof record.importantNote === "string" && (
            <Popover placement="top" arrowPointAtCenter content={record.importantNote}>
              <Typography.Text type="warning">
                <Icon name="information-outline" size="large" />
              </Typography.Text>
            </Popover>
          )}

          {isAdmin && record.problematic && <Icon name="warning-outline" fill={"red"} />}
        </Space>
      ),
    },
    {
      title: _t("caseHandler"),
      key: "caseHandler",
      dataIndex: "caseHandler",
      ellipsis: true,
      hidden: !isAdmin,
      render: (caseHandler) => caseHandler && caseHandler.fullName
    },
    {
      title: _t("full_service"),
      key: "isFullService",
      dataIndex: "isFullService",
      sorter: true,
      width: 110,
      hidden: !isAdmin,
      render: (isFullService) => (
        <Tag style={{ padding: "2px 10px" }} color={isFullService ? "success" : "default"}>
          {isFullService ? _t("full_service") : _t("no")}
        </Tag>
      )
    },
    {
      title: _t("process", "overview"),
      key: "relation",
      dataIndex: "relation",
      width: 248,
      hidden: !isAdmin,
      render: renderImportTableRelation
    }
  ];

  const handleTabChange = (nextKey?: string) => {
    const query = new URLSearchParams(history.location.search);
    query.delete(pageKey);
    query.delete(statusKey);
    setActiveStatusId(nextKey || "-1");
    history.push({ ...history.location, search: query.toString() });
  };

  const helpWith = [
    _t('merchant_contact'),
    _t('vehicle_status_report'),
    _t('price_negoation'),
    _t('vehicle_transport'),
    _t('vat_levy'),
    _t('vehicle_documentation')
  ];

  return (
    <>
      <PageTitle
        fluid
        title={title}
        extra={
          hasPermission("import.create") && (
            <Button type="primary">
              <Link to={getPath("import", "new")}>{_t("create", "importCase")}</Link>
            </Button>
          )
        }
      >
        {isAdmin && <TableStatistics context="import" />}
        <StatusTabs
          statuses={Object.values(statuses)}
          onTabChange={handleTabChange}
          activeTabId={`${activeStatusId}`}
        />
      </PageTitle>

      <Modal
        width={800}
        visible={showVideoModal}
        onCancel={() => setShowVideoModal(false)}
        destroyOnClose
        footer={null}
        title={_t("see_how_we_can_help_with_import")}
      >
        <video autoPlay={true} controls className="w-100">
          <source type="video/mp4" src={process.env.REACT_APP_IMPORT_VIDEO} />
        </video>
      </Modal>

      <Row gutter={16} className="mb-2 mt-3">
        <Col xs={24} xl={10} flex="auto" className="mb-1">
          <PromoCard>
            <Row justify="space-between" className="height-100">
              <Col xs={24} md={12}>
                <h2 className={"text-lighter"}>{_t('see_how_we_can_help_with_import')}</h2>

                <Button type="link" className="pl-0 pr-0" onClick={() => setShowVideoModal(true)}>
                  <Row gutter={8} align="middle">
                    <Col flex="none" span={8}>
                      <Icon name="caret-forward-outline"
                        className="ant-btn-primary ant-btn-circle p-05 text-white"
                        height={32}
                        width={32}
                      />
                    </Col>
                    <Col span={16}>
                      {_t('play_video')}
                    </Col>
                  </Row>
                </Button>
              </Col>

              <Col md={12} className="ant-col-xs-0 m--4">
                <img src={promoBanner} alt="" style={{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", objectPosition: "top right" }} />
              </Col>
            </Row>
          </PromoCard>
        </Col>
        <Col xs={24} xl={14} flex="auto" className="mb-1">
          <PromoCard bg={true}>
            <Row justify="space-between">
              <Col span={24}>
                <h4 className="text-blue text-upper">{_t("import")}</h4>
              </Col>

              <Col xs={24} xxl={8} className="mb-1">
                <h2 className={"text-lighter"}>{_t('do_you_want_to_import')}</h2>
                <Row gutter={16} align="middle">
                  <Col flex="none">
                    <Button type="primary" style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
                      <Link to={getPath("import", "new")}>
                        {_t("create", "new")}
                      </Link>
                    </Button>
                  </Col>

                  <Col flex="auto">
                    <div>
                      <strong>{_t('price')}: {appConfig("importPromoPrice")} &euro;</strong>
                    </div>
                    <div>
                      {_t('plus_delivery_fee')}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} xxl={14}>
                {_t('we_help_with')}
                <Row gutter={16}>
                  {helpWith.map((val, k) => {
                    return (
                      <Col span={12} key={k} className="pt-03 pb-03">
                        <Icon name="checkmark-outline"
                          className="ant-btn-primary text-white mr-03 border-round p-01"
                          height={12}
                          width={12}
                        />
                        <Text className="text-dark" strong={true}>{val}</Text>
                      </Col>
                    )
                  })}
                </Row>
              </Col>
            </Row>
          </PromoCard>
        </Col>
      </Row>

      <ImportFilterdCases
        filters={activeStatusId === statuses.open.id && <ImportTableFilter />}
        columns={columns}
        statusId={activeStatusId !== -1 ? activeStatusId : undefined}
      />
    </>
  );
};

export default ImportCases;
