import React, { FC } from "react";
import ConfigFieldsGenerator from "../../../../components/form/ConfigFieldsGenerator";
import DividerLine from "../../../../components/lineSplit";
import LeasingStore from "../../../../contexts/leasingStore";
import { ConfigFieldsType } from "../../../../types/systemTypes";
import LeasingDealDmf from "../leasingDealDmf";
import Details from "../../../../components/detailsList";
import _t from "../../../../lang/translate";

const LeasingDMFValuesFormFields: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const config: unknown = leasingCase.deal?.valueConfig;
  const info = leasingCase.deal?.info;
  const { readonly, inputLocked } = leasingCase.flags;
  const disabled = readonly || inputLocked;

  return (
    <>
      <LeasingDealDmf />
      <DividerLine fluid />
      <ConfigFieldsGenerator
        dividerBefore={["insuranceCommission", "interestLoanMarginal"]}
        list={config as ConfigFieldsType}
        nested={["valueConfig"]}
        disabled={disabled}
        rowGutter={[0, 0]}
      />
      <DividerLine fluid />
      <Details borderLess>
        <Details.Title
          className="m-0"
        >
          <h3>{_t("target")}</h3>
        </Details.Title>

        {info?.map(item => (
          <Details.Item label={item.label} value={item.text} color={item.color ?? ''} key={item.label} />
        ))}
      </Details>
    </>
  );
};

export default LeasingDMFValuesFormFields;
