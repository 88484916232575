import React, { FC, useState } from "react";
import { Badge, Collapse } from "antd";
import ImportCaseStore from "../../../contexts/importCaseStore";
import _t from "../../../lang/translate";
import Details from "../../../components/detailsList";
import format from "../../../utilities/formatNumbers";
import CustomCollapse from "../../../components/customCollapse";
import CustomCollapseHeader from "../../../components/customCollapseHeader";
import UserStore from "../../../contexts/userStore";

const { Panel } = Collapse;

const ImportCarReportInfo: FC = () => {
    const { importCase } = ImportCaseStore.useContainer();
    const { hasPermission } = UserStore.useContainer();
    const isAdmin = hasPermission("import.isAdmin");
    const { data, service, condition } = importCase.vehicleOffer || {};
    const [activeKey, setActiveKey] = useState<string[]>([]);
    const { originalDocuments, keyAmount, ownerAmount, smokedInStatus, previousDamageStatus, availableDeliveryDate } = data || {};
    const {
        folderStatus,
        serviceBookStatus,
        serviceHistoryStatus,
        latestServiceDate,
        latestServiceMileage,
        nextServiceDate,
        nextServiceMileage,
        nextServiceContent,
    } = service || {};
    const {
        statusExterior,
        statusInterior,
        statusRims,
        statusTires,
        statusBrakes,
        statusChargingCables,
        tireType,
        extraTiresStatus,
        otherComments,
    } = condition || {};

    const isReportDataCompleted =
        originalDocuments && keyAmount && ownerAmount && smokedInStatus && previousDamageStatus && availableDeliveryDate;

    const isReportServiceCompleted =
        serviceBookStatus &&
        folderStatus &&
        latestServiceDate &&
        latestServiceMileage &&
        nextServiceDate &&
        nextServiceMileage;
    const isReportConditionCompleted =
        statusExterior && statusInterior && statusRims && statusTires && statusBrakes && tireType;

    return (
        <CustomCollapse className="bg-none ml-0 mr-0" setActiveKey={setActiveKey} activeKey={activeKey}>
            <Panel
                header={
                    <CustomCollapseHeader>
                        {isAdmin && <Badge dot color={isReportDataCompleted ? "green" : "orange"} />}
                        {_t("import_car_data_form_title")}
                    </CustomCollapseHeader>
                }
                key="2"
            >
                <Details borderLess className="m-0">
                    <Details.Item label={_t("data_originalDocuments")} value={originalDocuments || "-"} />
                    <Details.Item label={_t("data_keyAmount")} value={keyAmount || "-"} />
                    <Details.Item label={_t("data_ownerAmount")} value={ownerAmount || "-"} />
                    <Details.Item label={_t("data_smokedInStatus")} value={smokedInStatus || "-"} />
                    <Details.Item label={_t("data_previousDamageStatus")} value={previousDamageStatus || "-"} />
                    <Details.Item label={_t("data_availableDeliveryDate")} value={format.date(availableDeliveryDate)} />
                </Details>
            </Panel>
            <Panel
                header={
                    <CustomCollapseHeader>
                        {isAdmin && <Badge dot color={isReportServiceCompleted ? "green" : "orange"} />}
                        {_t("service_en")}
                    </CustomCollapseHeader>
                }
                key="3"
            >
                <Details borderLess className="m-0">
                    <Details.Item label={_t("service_serviceBookStatus")} value={serviceBookStatus || "-"} />
                    <Details.Item label={_t("service_folderStatus")} value={folderStatus || "-"} />
                    <Details.Item label={_t("service_serviceHistoryStatus")} value={serviceHistoryStatus || "-"} />
                    <Details.Item label={_t("service_latestServiceDate")} value={format.date(latestServiceDate)} />
                    <Details.Item label={_t("service_latestServiceMileage")}
                        value={format.milage(latestServiceMileage)} />
                    <Details.Item label={_t("service_nextServiceDate")} value={format.date(nextServiceDate)} />
                    <Details.Item label={_t("service_nextServiceMileage")} value={format.milage(nextServiceMileage)} />
                    <Details.Item label={_t("service_nextServiceContent")} value={nextServiceContent || "-"} />
                </Details>
            </Panel>

            <Panel
                header={
                    <CustomCollapseHeader>
                        {isAdmin && <Badge dot color={isReportConditionCompleted ? "green" : "orange"} />}
                        {_t("car_condition")}
                    </CustomCollapseHeader>
                }
                key="4"
            >
                <Details borderLess className="m-0">
                    <Details.Item label={_t("condition_statusExterior")} value={statusExterior || "-"} />
                    <Details.Item label={_t("condition_statusInterior")} value={statusInterior || "-"} />
                    <Details.Item label={_t("condition_statusRims")} value={statusRims || "-"} />
                    <Details.Item label={_t("condition_statusTires")} value={statusTires || "-"} />
                    <Details.Item label={_t("condition_statusBrakes")} value={statusBrakes || "-"} />
                    <Details.Item label={_t("condition_statusChargingCables")} value={statusChargingCables || "-"} />
                    <Details.Item label={_t("condition_tireType")} value={tireType || "-"} />
                    <Details.Item label={_t("condition_extraTiresStatus")} value={extraTiresStatus || "-"} />
                    <Details.Item label={_t("condition_otherComments")} value={otherComments || ""} />
                </Details>
            </Panel>
        </CustomCollapse>
    );
};

export default ImportCarReportInfo;
