import { Card, Divider, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FC } from "react";
import FancySubmitButton from "../../components/fancySubmitButton";
import EnumRadio from "../../components/form/EnumRadio";
import LoadingContainer from "../../components/loadingContainer";
import ServerSelectBox from "../../components/serverSelectBox";
import { useEnums } from "../../components/useEnum";
import _t from "../../lang/translate";
import { customersAutoComplete, dealersAutoComplete, usersAutoComplete } from "../../services/autocompleteService";
import consts from "../../utilities/consts";

interface VehicleReportAdminProps {
  loading: boolean;
}

const VehicleReportAdmin: FC<VehicleReportAdminProps> = ({ loading }) => {
  const [clientType] = useEnums(["client", "type"]);
  return (
    <Card bordered={false} title={_t("case_created_for")}>
      <LoadingContainer loading={loading}>
        <Form.Item noStyle shouldUpdate={(curr, next) => curr.client.typeId !== next.client.typeId}>
          {({ setFieldsValue }) => {
            return (
              <Form.Item {...consts.formItemLabellessProps} name={["client", "typeId"]}>
                <EnumRadio
                  nameKey={["client", "type"]}
                  onChange={(e) => setFieldsValue({ calculationTypeId: e.target.value })}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.client.typeId !== curr.client.typeId}>
          {({ getFieldValue }) => {
            const typeId = getFieldValue(["client", "typeId"]);
            return (
              clientType &&
              typeId === clientType[0]?.id && (
                <Form.Item
                  {...consts.formItemLabellessProps}
                  name={["client", "dealerId"]}
                  rules={[{ required: true }]}
                >
                  <ServerSelectBox placeholder={_t("choose", "dealer")} apiUrl={dealersAutoComplete()} />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) =>
            prev.client.dealerId !== curr.client.dealerId || prev.client.typeId !== curr.client.typeId
          }
        >
          {({ getFieldValue }) => {
            const dealerId = getFieldValue(["client", "dealerId"]);
            const typeId = getFieldValue(["client", "typeId"]);

            return (
              clientType &&
              typeId === clientType[0]?.id && (
                <Form.Item {...consts.formItemLabellessProps} name={["client", "userId"]} rules={[{ required: true }]}>
                  <ServerSelectBox
                    apiUrl={usersAutoComplete()}
                    disabled={!dealerId}
                    placeholder={_t("choose", "user")}
                    filters={dealerId ? `filter[dealerId]=${dealerId}` : undefined}
                  />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, curr) => prev.client.typeId !== curr.client.typeId}>
          {({ getFieldValue }) => {
            const typeId = getFieldValue(["client", "typeId"]);
            return (
              clientType &&
              typeId === clientType[1]?.id && (
                <Form.Item
                  {...consts.formItemLabellessProps}
                  name={["client", "customerId"]}
                  rules={[{ required: true }]}
                >
                  <ServerSelectBox apiUrl={customersAutoComplete()} placeholder={_t("choose", "customer")} />
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Divider />
        <Form.Item {...consts.formItemLabellessProps} name="paymentMethodId" rules={[{ required: true }]}>
          <EnumRadio nameKey={["paymentMethod"]} />
        </Form.Item>
        <Form.Item {...consts.formItemLabellessProps} name="reason">
          <TextArea autoSize={{ minRows: 2, maxRows: 4 }} placeholder="Første sag rabat.." />
        </Form.Item>
      </LoadingContainer>
      <div className="fancy-btn-wp text-center">
        <FancySubmitButton loading={loading} text={_t('fetch', 'vehicle_report')} />
      </div>
    </Card>
  );
};

export default VehicleReportAdmin;
