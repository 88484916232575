import React from "react";
import { Col, Form, Input, Row } from "antd";
import _t from '../lang/translate';
import InputCVR from "./form/inputCVR";
import EnumRadio from "./form/EnumRadio";
import { extractDataFromObj } from '../utilities/objectUtil';

type CustomerInfoFormItemsProps = {
  namePrefix: string | string[] | null;
}
export default function CustomerInfoFormItems({ namePrefix }: CustomerInfoFormItemsProps) {

  function getNamePath(prefix: null | string | string[], name: string): string | string[] {
    if (prefix === null) return name;
    if (Array.isArray(prefix)) {
      return [...prefix, name];
    }
    if (typeof prefix === 'string') {
      return [prefix, name];
    }
    return name;
  }

  function shouldUpdateTypeId(prev: any, curr: any, path: string | string[]): boolean {
    if (Array.isArray(path)) {
      return extractDataFromObj(prev, path) !== extractDataFromObj(curr, path);
    } else {
      return prev[path] !== curr[path];
    }
  }

  return (
    <>
      <Form.Item name={getNamePath(namePrefix, "typeId")}>
        <EnumRadio
          nameKey={["customer", "type"]}
        />
      </Form.Item>

      <Form.Item shouldUpdate={(prev, cur) => {
        return shouldUpdateTypeId(prev, cur, getNamePath(namePrefix, "typeId"));
      }}
        noStyle
      >
        {({ getFieldValue }) => {
          return getFieldValue(getNamePath(namePrefix, 'typeId')) === 1 ? (
            <>
              <Form.Item name={getNamePath(namePrefix, "nameFirst")} rules={[{ required: true, whitespace: true }]} label={_t("firstname")}>
                <Input />
              </Form.Item>
              <Form.Item name={getNamePath(namePrefix, "nameLast")} label={_t("lastname")}>
                <Input />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item label={_t("cvr")} name={getNamePath(namePrefix, "cvr")}>
                <InputCVR />
              </Form.Item>
              <Form.Item name={getNamePath(namePrefix, "name")}
                label={_t("name")}
                rules={[{ required: true, whitespace: true }]}
              >
                <Input />
              </Form.Item>
            </>
          )
        }}
      </Form.Item>

      <Form.Item label={_t("address")} name={getNamePath(namePrefix, "address")}>
        <Input />
      </Form.Item>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label={_t("zip")} name={getNamePath(namePrefix, "zip")}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item label={_t("city")} name={getNamePath(namePrefix, "city")}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={_t("phone")}
        name={getNamePath(namePrefix, "phone")}
        rules={[{ required: true, whitespace: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={_t("email")}
        name={getNamePath(namePrefix, "email")}
        rules={[{ required: true, whitespace: true, type: "email" }]}
      >
        <Input />
      </Form.Item>
    </>
  )
}
