import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC } from "react";
import { Link } from "react-router-dom";
import Icon from "../../../assets/icons/icon";
import CollapseCard from "../../../components/collapseCard";
import DividerLine from "../../../components/lineSplit";
import openModal from "../../../components/modal/openModal";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { UserType } from "../../../types/userTypes";

const DealerUsersCard: FC = () => {
  const { dealer, setDealer, loading } = DealerStore.useContainer();

  const handleAdd = async () => {
    try {
      const user = await openModal<UserType>("addUser", { parentId: dealer.id });
      const nextUsers = [...(dealer.users || []), user];
      setDealer({ ...dealer, users: nextUsers });
    } catch (error) {
    }
  };

  const usersColumns: ColumnsType<any> = [
    {
      dataIndex: "name",
      key: "name",
      title: _t("name"),
      render: (text, record) => <Link to={getPath("user", record.id)}>{text}</Link>,
    },
    {
      dataIndex: "email",
      key: "email",
      title: _t("email"),
    },
    {
      dataIndex: "phone",
      key: "phone",
      title: _t("phone"),
    },
  ];

  return (
    <CollapseCard title={_t("users")} noPadding loading={loading}>
      <Table
        rowKey="id"
        dataSource={dealer.users || []}
        columns={usersColumns}
        pagination={{ pageSize: 10, hideOnSinglePage: true }}
      />
      <DividerLine className="m-0" />
      <Button onClick={handleAdd} className="m-1">
        <Icon name="add-outline" />
        {_t("add", "user")}
      </Button>
    </CollapseCard>
  );
};

export default DealerUsersCard;
