import React from "react";
import { Popover, PopoverProps } from "antd";

type Props = {
  list: string[];
} & Omit<PopoverProps, "content">;

const PopoverList = ({ list, children, ...rest }: Props) => {
  return (
    <Popover
      content={
        <div className="p-03 d-flex flex-column gap-03">
          {list.map((item, i) => {
            return (
              <div key={i} className="d-flex gap-05 text-left">
                <span className="text-secondary width-1">{i + 1}.</span>
                <span className="text-gray">{item}</span>
              </div>
            );
          })}
        </div>
      }
      {...rest}
    >
      {children}
    </Popover>
  );
};

export default PopoverList;
