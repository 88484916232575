import { FC } from "react";
import { Col, Grid, Row } from "antd";
import _t from "../lang/translate";
import StatisticLiveData from "../components/statistic/statisticLiveData";
import StatisticDiff from "../components/statistic/statisticDiff";
import {
  getActiveCases,
  getActiveDealers,
  getLive,
  getLtvPercentages,
  getRoiPoints,
} from "../services/statisticsService";

const { useBreakpoint } = Grid;

const LeasingTableStatistics: FC = () => {
  const screens = useBreakpoint();
  const colClassName = "d-flex flex-column";
  const cardClassName = "flex-grow";

  return screens.xl ? (
    <Row gutter={[16, 16]} className="mb-1" align="stretch">
      <Col span={12} xxl={{ span: 6 }} className={colClassName}>
        <StatisticLiveData fetchEndpoint={getLive("leasing")} className={cardClassName} />
      </Col>
      <Col span={8} order={3} xxl={{ span: 4, order: 2 }} className={colClassName}>
        <StatisticDiff title={_t("roi_point_case")} fetchEndpoint={getRoiPoints("leasing")} className={cardClassName} />
      </Col>
      <Col span={8} order={4} xxl={{ span: 4, order: 3 }} className={colClassName}>
        <StatisticDiff title={_t("ltv_percentage_case")} valueType="percentage" fetchEndpoint={getLtvPercentages("leasing")}
          className={cardClassName} />
      </Col>
      <Col span={8} order={5} xxl={{ span: 4, order: 4 }} className={colClassName}>
        <StatisticDiff
          title={_t("active", "dealers")}
          fetchEndpoint={getActiveDealers("leasing")}
          className={cardClassName}
        />
      </Col>
      <Col span={12} order={2} xxl={{ span: 6, order: 4 }} className={colClassName}>
        {/*<StatisticTopEntities fetchEndpoint={getTopSellers("leasing")} className={cardClassName}/>*/}
        <StatisticDiff title={_t("active_cases")} fetchEndpoint={getActiveCases("leasing")} className={cardClassName} />
      </Col>
    </Row>
  ) : null;
};

export default LeasingTableStatistics;
