import React, { FC } from "react";
import { Form } from "antd";
import _t from "../../../../lang/translate";
import TradePriceTotalField from "./tradePriceTotalField";

const TradePriceTotal: FC = () => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue }) => {
        return (
          <Form.Item name={["tradePrice", "tradePrice"]} label={<h4 className="mb-0">{_t("total")}</h4>}>
            <TradePriceTotalField input={getFieldsValue(true)?.tradePrice} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default TradePriceTotal;
