import React, { FC, useCallback, useState } from "react";
import { Button, Col, Form, message, Row } from "antd";
import debounce from "lodash/debounce";
import LinkBox from "../../../../components/form/linkBox";
import SavedStatus from "../../../../components/savedStatus";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { updateImportVehicle } from "../../../../services/importService";
import { Vehicle } from "../../../../types/appTypes";
import consts from "../../../../utilities/consts";
import { compare } from "../../../../utilities/deepCompare";
import ImportVehicleFormFields from "./importVehicleFormfields";
import { isAxiosError } from "../../../../utilities/typeGuard";

interface ImportVehicleFormProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  stopEditing: () => void;
}

const ImportVehicleForm: FC<ImportVehicleFormProps> = ({ setLoading, stopEditing }) => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const [savedStatus, setSavedStatus] = useState<boolean>(true);
  const vehicleLink = importCase.vehicle?.link;

  const handleSave = async (values: Vehicle) => {
    try {
      setLoading(true);
      const { data } = await updateImportVehicle(importCase.id, values);
      setImportCase(data);
      stopEditing();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (changedValues: any, values: Vehicle) => {
    delayedSetMatched(values, importCase.vehicle);
  };

  const delayedSetMatched = useCallback(
    debounce((values: Vehicle, savedValues: any) => {
      const isMatched = compare(values, savedValues);
      if (isMatched !== savedStatus) setSavedStatus(isMatched);
    }, 500),
    [setSavedStatus, savedStatus]
  );

  return (
    <Form
      {...consts.formItemProps}
      className="m-0"
      onValuesChange={handleFormChange}
      initialValues={importCase.vehicle || {}}
      requiredMark={false}
      onFinish={handleSave}
    >
      {vehicleLink && (
        <Form.Item label={_t("link")} shouldUpdate>
          {({ setFieldsValue }) => {
            return (
              <LinkBox
                link={vehicleLink}
                onFetch={(data) => {
                  const vehicle = { ...data.vehicle };
                  setFieldsValue(vehicle);
                }}
              />
            );
          }}
        </Form.Item>
      )}
      <ImportVehicleFormFields />
      <Form.Item noStyle shouldUpdate>
        {({ submit }) => (
          <Row justify="space-between">
            <Col>
              <Button onClick={stopEditing}>{_t("cancel")}</Button>
            </Col>
            <Col>
              <SavedStatus status={savedStatus} />
              <Button type="primary" onClick={submit}>
                {_t("save")}
              </Button>
            </Col>
          </Row>
        )}
      </Form.Item>
    </Form>
  );
};

export default ImportVehicleForm;
