import { Button, Checkbox, Divider, Form, message, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { UploadFile } from "antd/lib/upload/interface";
import { useState } from "react";
import _t from "../../lang/translate";
import { sendToPartnerValidation } from "../../services/leasingService";
import { LeasingSendToPartnerValidationType } from "../../types/leasingTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { isAxiosError } from "../../utilities/typeGuard";

export default function LeasingSendToPartner<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<LeasingSendToPartnerValidationType>();
  const [loading, setLoading] = useState<boolean>(false);
  const id = payload.id;
  const initFiles: UploadFile[] = payload.files;

  const handleSubmit = async (values: LeasingSendToPartnerValidationType) => {
    try {
      setLoading(true);
      const { data } = await sendToPartnerValidation(id, values);
      setLoading(false);
      onSuccess(data as T);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  const files = initFiles?.filter((file) => !!file?.response?.id) || [];

  const options = files.map((file) => ({
    label: file.name,
    value: file.response?.id,
  }));

  return (
    <Form form={tmpForm} requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <h2 className="modal-title">{_t("send_to_krone_kapital")}</h2>
      <Form.Item name="files" label={_t("choose_files_to_be_send")}>
        <Checkbox.Group disabled={loading} options={options} />
      </Form.Item>
      <Divider />
      <Form.Item name="note" initialValue={null}>
        <TextArea disabled={loading} placeholder={_t("note")} autoSize={{ maxRows: 2, minRows: 1 }} />
      </Form.Item>
      <Form.Item name="notifyDealer" initialValue={true} valuePropName="checked">
        <Checkbox disabled={loading}>{_t("notify", "dealer")}</Checkbox>
      </Form.Item>
      <Form.Item name="notifyCustomer" initialValue={true} valuePropName="checked">
        <Checkbox disabled={loading}>{_t("notify", "customer")}</Checkbox>
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" loading={loading} type="primary">
            {_t("request")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(LeasingSendToPartner);
