import React, { FC } from "react";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import { Button, Divider, Space, Typography } from "antd";
import Icon from "../../../../assets/icons/icon";
import _t from "../../../../lang/translate";
import { ResellerTaxCase } from "../../../../types/resellerTaxTypes";
import openModal from "../../../../components/modal/openModal";

const { Title } = Typography;

interface CaseHeadCustomerInfoProps {
  resellerTaxCase: ResellerTaxCase | null;
  setResellerTaxCase: (newCase: ResellerTaxCase) => void;
}

const CaseHeadCustomerInfo: FC<CaseHeadCustomerInfoProps> = ({ resellerTaxCase, setResellerTaxCase }) => {
  const { customerInfo, id } = resellerTaxCase || {};
  const handleCustomerInfoEdit = async () => {
    try {
      const data = await openModal<ResellerTaxCase>("updateResellerTaxCustomerInfo", {
        id: id,
        initialValues: customerInfo,
      });
      setResellerTaxCase(data);
    } catch (error) {
    }
  };

  return (
    <>
      <Title level={3} ellipsis className="mb-0">
        {
          <Space split={<Divider type="vertical" className="ml-05 mr-05" />}>
            {customerInfo?.typeId === 1 ? `${customerInfo?.nameFirst} ${customerInfo?.nameLast}` : customerInfo?.name}
            <Typography.Link className="text-dark" onClick={handleCustomerInfoEdit}>
              <Icon name="settings-outline" size="medium" />
            </Typography.Link>
          </Space>
        }
      </Title>
      <Space split={<Divider type="vertical" className="ml-05 mr-05" />}>
        {customerInfo?.email ? (
          <Button className="p-0" type="link">
            <a href={`mailto:${customerInfo.email}`}>{customerInfo.email}</a>
          </Button>
        ) : (
          <Button className="p-0" type="link">
            -
          </Button>
        )}
        {customerInfo?.phone ? (
          <Button className="p-0" type="link">
            <a href={`tel:${customerInfo.phone}`}>
              {_t("phone_short")}
              {customerInfo.phone}
            </a>
          </Button>
        ) : (
          <Button className="p-0" type="link">
            -
          </Button>
        )}
      </Space>
    </>
  );
};

const ResellerTaxCaseCustomerInfo: FC = () => {
  const { resellerTaxCase, setResellerTaxCase } = ResellerTaxCaseStore.useContainer();

  return <CaseHeadCustomerInfo resellerTaxCase={resellerTaxCase} setResellerTaxCase={setResellerTaxCase} />;
};

export default ResellerTaxCaseCustomerInfo;
