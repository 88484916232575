import { Col, Form, Popover, Row } from "antd";
import { FC } from "react";
import CustomCollapseHeader from "../../../../components/customCollapseHeader";
import _t from "../../../../lang/translate";
import { TradePriceCalcTaxType } from "../../../../types/taxTypes";
import TradePriceOutputField from "./tradePriceOutputField";
import TradePriceTotal from "./tradePriceTotal";
import Icon from "../../../../assets/icons/icon";
import format from "../../../../utilities/formatNumbers";
import { DeprecitationOutput } from "../calc/metaCalculators";

type Props = {
  isLargeDeviation: boolean;
  deviationPercentage: number;
  depreciation: DeprecitationOutput;
}


const TradePricePanelHeader: FC<Props> = ({ isLargeDeviation, deviationPercentage, depreciation }) => {
  return (
    <CustomCollapseHeader>
      <Row justify="space-between">
        <Col className="d-flex align-items-center">
          {_t("trade_price")}
          <Form.Item shouldUpdate noStyle>
            {({ getFieldsValue }) => (
              <TradePriceOutputField
                output={
                  getFieldsValue(true)?.tradePrice as TradePriceCalcTaxType
                }
              />
            )}
          </Form.Item>
          {isLargeDeviation && (
            <Popover
              title={_t("deviation_percentage") + ": " + format.percent(deviationPercentage)}
              content={_t("trade_price") + " " + _t("deviates").toLowerCase() + " " + _t("from", "the_robot")}
            >
              <Icon size="huge" className="ml-03 text-warning" name="alert" />
            </Popover>
          )}
        </Col>
        <Col>
          <Popover title={
            <div className="d-flex justify-content-between w-100">
              <span>
                {_t("standard_depreciation")}:
                  &nbsp;
                  {format.percent(depreciation.standard.factor)}
              </span>
              <span>
                {format.price(depreciation.standard.depreciatedNewPrice)}
              </span>
            </div>
          }
            content={
              <span>
                {_t("standard_depreciation", "with")}
                &nbsp;
                {_t("mileage_regulation").toLowerCase()}
                &nbsp;
                <i>({format.price(depreciation.mileageRegulation)}): </i>
                <span className="text-bold ml-1 text-dark">
                  {format.price(depreciation.deprecitationWithMileageRegulation)}
                </span>
              </span>
            }
          >
            <i className="text-smaller text-normal">
              {_t("depreciation") + " " + format.percent(depreciation.caseDeprecitationFactor)}
            </i>
          </Popover>
        </Col>
        <Col>
          <TradePriceTotal />
        </Col>
      </Row>
    </CustomCollapseHeader>
  );
};

export default TradePricePanelHeader;
