import React, { FC } from "react";
import _t from "../lang/translate";
import TaxCaseStore from "../contexts/taxCaseStore";
import SwitchInput from "./form/switchInput";
import { Col, message, Popconfirm, Row } from "antd";
import { acceptTaxOffer, paidTaxOffer, uploadedTaxOffer } from "../services/taxService";
import LogText from "./logText";
import { isAxiosError } from "../utilities/typeGuard";

interface TaxFlagsSwitchesProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaxFlagsSwitches: FC<TaxFlagsSwitchesProps> = ({ setLoading }) => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();

  const handleOfferAccept = async (v: boolean) => {
    try {
      setLoading(true);
      const { data } = await acceptTaxOffer(taxCase.id, { status: v });
      message.success(_t(v ? "accepted" : "not_accepted"));
      setTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentsUploaded = async (v: boolean) => {
    try {
      setLoading(true);
      const { data } = await uploadedTaxOffer(taxCase.id, { status: v });
      message.success(_t(v ? "documents_uploaded" : "documents_not_uploaded"));
      setTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleInvoicePaid = async (v: boolean) => {
    try {
      setLoading(true);
      const { data } = await paidTaxOffer(taxCase.id, { status: v });
      message.success(_t(v ? "invoice_paid" : "invoice_not_paid"));
      setTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row gutter={[8, 8]} className="mb-1">
        <Col>
          <Popconfirm title={_t("confirm_action")} onConfirm={() => handleOfferAccept(!taxCase.flags.accepted)}>
            <SwitchInput
              size="small"
              id="case-accepted-switch"
              label={_t("case", "accepted")}
              checked={taxCase.flags.accepted}
            />
          </Popconfirm>
        </Col>
        {taxCase?.log?.accepted && (
          <Col>
            <LogText mdash inline small className={"p-0"} logData={taxCase.log.accepted} />
          </Col>
        )}
      </Row>
      <Row gutter={[8, 8]} className="mb-1">
        <Col>
          <SwitchInput
            checked={taxCase.flags.uploaded}
            onChange={handleDocumentsUploaded}
            id="documents-received-switch"
            size="small"
            label={_t("documents", "received")}
          />
        </Col>
        {taxCase?.log?.uploaded && (
          <Col>
            <LogText mdash inline small className={"p-0"} logData={taxCase.log.uploaded} />
          </Col>
        )}
      </Row>
      <Row gutter={[8, 8]} className="mb-1">
        <Col>
          <SwitchInput
            checked={taxCase.flags.paid}
            onChange={handleInvoicePaid}
            id="payment-received-switch"
            size="small"
            label={_t("payment", "received")}
          />
        </Col>
        {taxCase?.log?.paid && (
          <Col>
            <LogText mdash inline small className={"p-0"} logData={taxCase.log.paid} />
          </Col>
        )}
      </Row>
    </>
  );
};

export default TaxFlagsSwitches;
