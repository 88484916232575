import { DropResult } from "react-smooth-dnd";

const sortArrayAfterDrop = <T extends object>(
  arr: T[],
  dragResult: DropResult
) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export default sortArrayAfterDrop;
