import React, { FC } from "react";
import CardBox from "../../../../components/cardBox";
import Details from "../../../../components/detailsList";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";

const TaxDocEstimation: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  const { newPrice, tradePrice } = taxDocCase.evaluation || {};
  return (
    <CardBox className="mb-1">
      <Details borderLess className="m-0">
        <Details.Item label={_t("new_price")} value={format.price(newPrice)} />
        <Details.Item label={_t("trade_price")} value={format.price(tradePrice)} />
      </Details>
    </CardBox>
  );
};

export default TaxDocEstimation;
