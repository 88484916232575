import { Button, Form, Space } from "antd";
import { useState } from "react";
import _t from "../../lang/translate";
import { creditPortalLinkTemplates } from "../../services/autocompleteService";
import { sendCreditPortalLink } from "../../services/leasingService";
import { CreditValidationLinkType } from "../../types/leasingTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import ServerSelectBox from "../serverSelectBox";
import { renderErrorMessage } from "../messages/errorMessage";

export default function SendCreditPortalLink<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [loading, setLoading] = useState<boolean>();

  const handleSubmit = async (values: CreditValidationLinkType) => {
    setLoading(true);
    try {
      await sendCreditPortalLink(payload.id, values);
      onSuccess({ data: true } as T);
    } catch (error) {
      renderErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form<CreditValidationLinkType> requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <h2 className="modal-title">{_t("send_credit_portal_link")}</h2>
      <Form.Item name="templateId" label={_t("template")} rules={[{ required: true }]}>
        <ServerSelectBox apiUrl={creditPortalLinkTemplates()} />
      </Form.Item>

      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>

          <Button htmlType="submit" type="primary" loading={loading}>
            {_t("send")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(SendCreditPortalLink);
