import React, { FC, useState } from "react";
import { Divider, message } from "antd";
import { ChecklistEntryType, initChecklistEntry as initData } from "../../types/checklistTypes";
import AddDivider from "../addDivider";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import _t from "../../lang/translate";
import { createChecklistEntry } from "../../services/checklistService";
import ChecklistForm from "./checklistForm";
import { isAxiosError } from "../../utilities/typeGuard";

interface ChecklistAddEntryProps {
  position?: number;
  groupId: string;
  hideButton?: boolean;
}

const ChecklistAddEntry: FC<ChecklistAddEntryProps> = ({ position = 1, groupId, hideButton }) => {
  const { setEditing, editing, sorting, dispatchGroups } = ChecklistAdminStore.useContainer();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleShowForm = () => {
    setShowForm(true);
    setEditing({ id: "0", groupId });
  };

  const onCancel = () => {
    setShowForm(false);
    setEditing(null);
  };

  const onSubmit = async (value: ChecklistEntryType) => {
    const newEntry = { ...value, groupId, position, id: "0" };
    try {
      setLoading(true);
      const { data: payload } = await createChecklistEntry(newEntry);
      setLoading(false);
      setShowForm(false);
      setEditing(null);
      dispatchGroups({ type: "add_entry", payload });
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return hideButton ? (
    <AddDivider disabled />
  ) : (
    <>
      <AddDivider disabled={showForm || !!editing || sorting} onClick={handleShowForm} />
      <div className="transition-05 overflow-hidden" style={{ maxHeight: showForm ? 800 : 0 }}>
        {showForm && (
          <>
            <ChecklistForm {...{ loading, initData, onSubmit, onCancel }} />
            <Divider />
          </>
        )}
      </div>
    </>
  );
};

export default ChecklistAddEntry;
