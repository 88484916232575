import { FC } from "react";
import { useParams } from "react-router-dom";
import { ParamTypes, ScreenProps } from "../../../types/appTypes";
import { isNew } from "../../../routes/appRoutes";
import UserStore from "../../../contexts/userStore";
import TaxDocNewCase from "./taxDocNewCase";
import TaxDocCaseAdmin from "./admin/taxDocCaseAdmin";
import TaxDocCaseUser from "./user/taxDocCaseUser";

const TaxDocCase: FC<ScreenProps> = (props) => {
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("taxDoc.isAdmin");
  const { id } = useParams<ParamTypes>();

  if (isNew(id)) {
    return <TaxDocNewCase {...props} />;
  }
  if (isAdmin) return <TaxDocCaseAdmin {...props} />;

  return <TaxDocCaseUser {...props} />;
};

export default TaxDocCase;
