import { FC, useState } from "react";
import _t from "../../../../lang/translate";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import {
  adsDocumentationFileEndpoint,
  equipmentsDocumentationFileEndpoint,
  fetchEquipmentsDocumentationFile,
  newPriceDocumentationFileEndpoint,
  vehicleListDocumentationFileEndpoint,
} from "../../../../services/taxDocService";
import TaxDocUploadFile from "./taxDocUploadFile";
import { message } from "antd";

const TaxDocUploads: FC = () => {
  const { taxDocCase, setTaxDocCase } = TaxDocCaseStore.useContainer();
  const [loadingDat, setLoadingDat] = useState<boolean>(false);

  const handleAutoFetchDat = async () => {
    try {
      setLoadingDat(true);
      const { data } = await fetchEquipmentsDocumentationFile(taxDocCase.id);
      setTaxDocCase(data);
    } catch ({ response }) {
      message.error(response?.data?.message || _t("msg.file_not_removed"));
    } finally {
      setLoadingDat(false);
    }
  };

  return (
    <>
      <TaxDocUploadFile label={_t("ads")} fileKey="ads" action={adsDocumentationFileEndpoint(taxDocCase.id)} />
      <TaxDocUploadFile
        label={_t("vehicle_list_from_bilinfo")}
        fileKey="vehicleList"
        action={vehicleListDocumentationFileEndpoint(taxDocCase.id)}
      />
      <TaxDocUploadFile
        label={_t("newprice_documentation")}
        fileKey="newPrice"
        action={newPriceDocumentationFileEndpoint(taxDocCase.id)}
      />
      <TaxDocUploadFile
        label={_t("equipments_documentation")}
        fileKey="equipments"
        action={equipmentsDocumentationFileEndpoint(taxDocCase.id)}
        onAutoFetch={handleAutoFetchDat}
        loading={loadingDat}
      />
    </>
  );
};

export default TaxDocUploads;
