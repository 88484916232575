import { Button, Tag } from "antd";
import { FC } from "react";
import Details from "../../../components/detailsList";
import UserPageStore from "../../../contexts/userPageStore";
import _t from "../../../lang/translate";

interface UserPageInfoViewProps {
  startEditing: () => void;
}

const UserPageInfoView: FC<UserPageInfoViewProps> = ({ startEditing }) => {
  const { user } = UserPageStore.useContainer();
  const { name, email, initials, phone, role, departments, dealer } = user;

  const userDepartments = departments?.map((dep) => (
    <Tag className="tag-small mr-0 ml-05 mb-05" key={dep.id}>
      {dep.title}
    </Tag>
  ));

  return (
    <>
      <Details borderLess>
        <Details.Item label={_t("name")} value={name || "-"} />
        <Details.Item label={_t("initials")} value={initials || "-"} />
        <Details.Item label={_t("role")} value={role?.title || "-"} className="mb-1" />
        <Details.Item label={_t("email")} value={email || "-"} />
        <Details.Item label={_t("phone")} value={phone || "-"} className="mb-1" />
        <Details.Item label={_t("dealer")} value={dealer || "-"} />
        <Details.Item label={_t("departments")} value={userDepartments || "-"} />
      </Details>
      <div className="text-right">
        <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
          {_t("edit")}
        </Button>
      </div>
    </>
  );
};

export default UserPageInfoView;
