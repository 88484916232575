import { Button, Col, Dropdown, Form, Menu, Row, Switch } from "antd";
import { FC } from "react";
import Icon from "../../../../../assets/icons/icon";
import _t from "../../../../../lang/translate";
import TaxaveragePriceCell from "./taxaveragePriceCell";
import TaxEquipmentsDiffCell from "./taxEquipmentsDiffCell";
import TaxEquipmentsPriceCell from "./taxEquipmentsPriceCell";
import { dateDiff } from "../../../../../utilities/date";

interface TaxaverageCellProps {
  row: any;
  documentationDate: string | null;
  onRemove: (id: number) => void;
}

const TaxaverageCell: FC<TaxaverageCellProps> = ({ row, onRemove, documentationDate }) => {
  const { type, id } = row || {};

  const menu = (
    <Menu>
      <Menu.Item key="delete" onClick={() => onRemove(id)} danger icon={<Icon name="trash-bin-outline" size="small" />}>
        {_t("delete")}
      </Menu.Item>
    </Menu>
  );

  if (
    type === "recalculatedPrice" ||
    type === "recalculatedMileage" ||
    type === "equipmentsTotal" ||
    type === "equipmentsTotalUsed"
  ) {
    return (
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldsValue }) => {
          const vehicles = getFieldsValue(true)?.vehicles;

          return (
            <Form.Item name={["total", type]} noStyle>
              <TaxaveragePriceCell vehicles={vehicles} type={type} bold={!type.includes("equipmentsTotal")} />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  if (type === "equipmentsDiff" || type === "equipmentsDiffUsed") {
    return (
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldsValue }) => {
          const { vehicle, total } = getFieldsValue(true) || {};
          const used = type === "equipmentsDiffUsed";

          return (
            <Form.Item name={["total", type]} noStyle>
              <TaxEquipmentsDiffCell
                vehiclesEquipmentsPrice={used ? total?.equipmentsTotalUsed : total?.equipmentsTotal}
                vehicleEquipmentsPrice={used ? vehicle?.equipmentsTotalUsed : vehicle?.equipmentsTotal}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  if (type === "equipmentListTitle") {
    return <label>{_t("deduction")}</label>;
  }
  if (type === "equipmentsPrice") {
    return (
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldsValue, setFieldsValue }) => {
          const allValues = getFieldsValue(true) || {};
          const { vehicle, total } = allValues;
          const actualValuePers = [1, 0.7, 0.57, 0.45, 0.33, 0.2, 0.15, 0.1];
          // 10 is a number to avoid deduction in case first reg date is null.
          const vehicleAgeInMonth = vehicle?.firstRegDate
            ? dateDiff({ start: vehicle?.firstRegDate, end: documentationDate })
            : 0;
          const vehicleAgeInYears = Math.floor(vehicleAgeInMonth / 12);
          let equipmentYearFactorDeduction = actualValuePers[vehicleAgeInYears] || 0;
          if (vehicleAgeInYears < 6 && vehicleAgeInYears > 0) {
            equipmentYearFactorDeduction -= (vehicleAgeInMonth - vehicleAgeInYears * 12) / 100;
          }
          if (equipmentYearFactorDeduction !== total?.equipmentYearFactorDeduction) {
            setFieldsValue({
              ...allValues,
              total: { ...total, equipmentYearFactorDeduction },
            });
          }

          return (
            <Form.Item name={["total", type]} noStyle>
              <TaxEquipmentsPriceCell
                vehicleYear={vehicleAgeInYears}
                diff={total?.equipmentsDiffUsed}
                equipmentYearFactorDeduction={equipmentYearFactorDeduction}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  if (type === "equipments") {
    return (
      <Row justify="space-between">
        <Col>
          <Form.Item shouldUpdate={true} noStyle>
            {({ getFieldValue }) => {
              const equipments = (getFieldValue("equipments") || []) as any[];
              const index = equipments.findIndex((e) => e.id === id);
              return (
                <Form.Item noStyle name={["equipments", index, "deduction"]} valuePropName="checked">
                  <Switch size="small" />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        {!row?.equipment?.synced && (
          <Col>
            <Dropdown placement="bottomLeft" overlay={menu}>
              <Button shape="circle" type="text">
                <Icon name="ellipsis-vertical-sharp" />
              </Button>
            </Dropdown>
          </Col>
        )}
      </Row>
    );
  }

  return null;
};

export default TaxaverageCell;
