import React, { FC } from "react";
import { Col, Row } from "antd";
import Icon from "../../../../assets/icons/icon";
import _t from "../../../../lang/translate";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import format from "../../../../utilities/formatNumbers";

const TaxDocPurchasedMessage: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();

  return (
    <Row className="flex-wrap mb-1">
      <Col flex="auto">
        <h3 className="">
          <Icon name="shield-checkmark-outline" size="huge" className="text-primary" />
          <span className="pl-05">{_t("documentation_purchased")}</span>
        </h3>
        <label className="d-block">{_t("documentation_ready_to_download")}</label>
        <label className="d-block">{_t("garanti_expiration__see_more")}</label>
      </Col>
      <Col flex="100px" className="text-right">
        <h3 className="mb-0">{format.price(taxDocCase.settings?.productPrice)}</h3>
        <small className="d-block text-right">
          {format.price(taxDocCase.evaluation?.productPriceWithVat)}
          <br /> {_t("include_short", "vat").toLowerCase()}
        </small>
      </Col>
    </Row>
  );
};

export default TaxDocPurchasedMessage;
