import React, { FC, useState } from "react";
import { getUserInfo, saveUserInfo } from "../../../services/authService";
import { Button, message } from "antd";
import _t from "../../../lang/translate";
import { isAxiosError } from "../../../utilities/typeGuard";
import { toggleEmailSubscription } from "../../../services/miscService";

const DashboardNewsletterToggle: FC = () => {
  const { newsletterSubscription } = getUserInfo();
  const [isSubbed, setIsSubbed] = useState<boolean>(!!newsletterSubscription);
  const [loading, setLoading] = useState<boolean>(false);

  const updateSubStatus = (status: boolean) => {
    setIsSubbed(status);
    const userInfo = { ...getUserInfo() };
    userInfo.newsletterSubscription = status;
    saveUserInfo(userInfo);
  }

  const handleSubToggle = async () => {
    try {
      setLoading(true);
      await toggleEmailSubscription(!isSubbed);
      message.success(_t("updated"));
      updateSubStatus(!isSubbed);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button type={isSubbed ? 'default' : 'primary'} onClick={handleSubToggle} disabled={loading}>
      {isSubbed ? _t('unsubscribe_newsletter') : _t('subscribe_newsletter')}
    </Button>
  )
};

export default DashboardNewsletterToggle;
