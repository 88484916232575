import { FC } from "react";
import { useParams } from "react-router-dom";
import { ParamTypes, ScreenProps } from "../../../types/appTypes";
import TaxNewCase from "./taxNewCase";
import TaxCaseUser from "./user/taxCaseUser";
import { isNew } from "../../../routes/appRoutes";
import UserStore from "../../../contexts/userStore";
import TaxCaseAdmin from "./admin/taxCaseAdmin";

const TaxCase: FC<ScreenProps> = (props) => {
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("tax.isAdmin");
  const { id } = useParams<ParamTypes>();
  if (isNew(id)) {
    return <TaxNewCase {...props} />;
  }
  if (isAdmin) return <TaxCaseAdmin {...props} />;

  return <TaxCaseUser {...props} />;
};

export default TaxCase;
