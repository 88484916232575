import { Button, Col, Divider, Form, FormInstance, Input, message, Row, Space, } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { FC, useEffect, useState } from "react";
import { Link, Prompt } from "react-router-dom";
import _t from "../../../../lang/translate";
import consts from "../../../../utilities/consts";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import InputNum from "../../../../components/form/inputNum";
import { FieldData, NamePath, Rule } from "rc-field-form/lib/interface";
import TaxCalculationTotalField from "../../tax/admin/taxCalculationTotalField";
import { OfferResellerTaxType, ResellerTaxCase } from "../../../../types/resellerTaxTypes";
import SavedStatus from "../../../../components/savedStatus";
import ResellerTaxOfferArchived from "./resellerTaxOfferArchived";
import { saveOfferOptimization } from "../../../../services/resellerTaxService";
import { compare } from "../../../../utilities/simpleCompare";
import debounce from "lodash/debounce";
import { getPath } from "../../../../routes/appRoutes";
import ResellerTaxOfferActions from "./resellerTaxOfferActions";
import ResellerTaxCollateralAmount from "./resellerTaxCollateralAmount";
import InputPercentage from "../../../../components/form/inputPercentage";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";

interface ResellerTaxCaseOfferEditProps {
  optMode?: boolean;
}

interface InputFieldProps {
  label: string;
  name: NamePath;
  rules?: Rule[];
}

interface OfferTotalProps {
  resellerTaxCase: ResellerTaxCase;
  optMode?: boolean;
}

type ResellerOfferForm = OfferResellerTaxType & { collateralFee_percentage: number };

const keysToCompare: (keyof ResellerOfferForm)[] = [
  "newPrice",
  "tradePrice",
  "registrationFee",
  "licensePlateFee",
  "caseFee",
  "collateralFee",
  "collateralFee_percentage"
];

function updateValuesCalculation(
  changedValues: Partial<ResellerOfferForm>,
  offer: ResellerOfferForm
): OfferResellerTaxType {
  const tmp = { ...offer };

  if (changedValues.registrationFee) {
    tmp.collateralFee_percentage =
      calculateCollateralFeePercentage(changedValues.registrationFee, tmp.collateralFee);
  }
  if (changedValues.collateralFee) {
    tmp.collateralFee_percentage =
      calculateCollateralFeePercentage(tmp.registrationFee, changedValues.collateralFee);
  }
  if (changedValues.collateralFee_percentage) {
    tmp.collateralFee =
      calculateCollateralFee(tmp.registrationFee, changedValues.collateralFee_percentage);
  }

  return tmp;
}

function calculateCollateralFee(
  registrationFee: ResellerOfferForm['registrationFee'] | undefined,
  percentage: ResellerOfferForm['collateralFee_percentage'] | undefined
) {
  return (percentage || 0) * (registrationFee || 0);
}

function calculateCollateralFeePercentage(
  registrationFee: ResellerOfferForm['registrationFee'] | undefined,
  collateralFee: ResellerOfferForm['collateralFee'] | undefined
) {
  if (valueCanDivide(registrationFee) && valueCanDivide(collateralFee)) {
    return collateralFee / registrationFee;
  }
  return 0;
}

function valueCanDivide(value: any): value is number {
  return (
    typeof value === "number" &&
    value !== 0 &&
    !isNaN(value)
  )
}

const OfferTotal: FC<OfferTotalProps> = ({ resellerTaxCase, optMode }) => {
  const shouldCalcTotal = (prev: OfferResellerTaxType, next: OfferResellerTaxType) => {
    return (
      prev.registrationFee !== next.registrationFee ||
      prev.caseFee !== next.caseFee ||
      prev?.collateralFee !== next?.collateralFee ||
      prev.licensePlateFee !== next.licensePlateFee
    );
  };

  return (
    <Form.Item shouldUpdate={shouldCalcTotal} noStyle>
      {({ getFieldValue }) => {
        const regFee = getFieldValue(["registrationFee"]) || 0;
        const fee = getFieldValue(["caseFee"]) || 0;
        const collateral = getFieldValue("collateralFee") || 0;
        const licensePlateFee = getFieldValue("licensePlateFee") || 0;

        return (
          <Form.Item label={_t("total_price")} name="total">
            <TaxCalculationTotalField input={[regFee, fee, licensePlateFee, collateral]} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

const InputField: FC<InputFieldProps> = ({ children, rules, label, name, }) => {
  return (
    <Row className="flex-nowrap">
      <Col flex="auto">
        <Form.Item label={label} name={name} rules={rules}>
          {children}
        </Form.Item>
      </Col>
    </Row>
  )
}

const PercentageRegulatedInputField: FC<InputFieldProps> = ({ rules, label, name }) => {
  return (
    <Row className="flex-nowrap">
      <Col flex="auto">
        <Form.Item label={label}>
          <Row className="flex-nowrap" gutter={8}>
            <Col flex="auto">
              <Form.Item shouldUpdate rules={rules} noStyle name={name}>
                <InputNum
                  min={0}
                  suffix={"kr."}
                />
              </Form.Item>
            </Col>
            <Col flex="100px">
              <Form.Item shouldUpdate noStyle name={name + '_percentage'}>
                <InputPercentage />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
      </Col>
    </Row>
  )
}


const ResellerTaxOfferEdit: FC<ResellerTaxCaseOfferEditProps> = ({ optMode }) => {
  const { resellerTaxCase, setResellerTaxCase, setCanSendInvoice } =
    ResellerTaxCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const [savedStatus, setSavedStatus] = useState<boolean>(true);
  const [offerForm] = useForm<ResellerOfferForm>();

  const key = optMode ? "optimization" : "offer";

  const [initialValues, setInitialValues] = useState({
    ...resellerTaxCase[key],
    optimization: optMode,
    collateralFee_percentage: calculateCollateralFeePercentage(
      resellerTaxCase[key]?.registrationFee,
      resellerTaxCase[key]?.collateralFee
    )
  } || undefined);

  useEffect(() => {
    setInitialValues({
      ...resellerTaxCase[key],
      optimization: optMode,
      collateralFee_percentage: calculateCollateralFeePercentage(
        resellerTaxCase[key]?.registrationFee,
        resellerTaxCase[key]?.collateralFee
      )
    } || undefined);
    setTimeout(offerForm.resetFields, 10);
  }, [resellerTaxCase, key, optMode, offerForm]);

  const checkForChange = debounce((changedFields: FieldData[], allFields: FieldData[]) => {
    const toCompare = Object.fromEntries(allFields.flatMap(field => {
      const key = Array.isArray(field.name) ? field.name[0] : String(field.name);
      return [[key, field.value]];
    }));
    const isSame = compare((resellerTaxCase[key] || {}), toCompare, keysToCompare);
    setSavedStatus(isSame);
  }, 200);


  const handleSave = async (values: ResellerOfferForm) => {
    setLoading(true);
    try {
      const { data } = await saveOfferOptimization(resellerTaxCase.id, values, optMode);
      message.success(_t("updated"));
      setResellerTaxCase(data);
      setSavedStatus(true);
      optMode && setCanSendInvoice(true);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (changedValues: Partial<ResellerOfferForm>, values: ResellerOfferForm) => {
    const offer = updateValuesCalculation(changedValues, values);
    offerForm.setFieldsValue(offer);
  };

  return (
    <>
      <Prompt when={!savedStatus} message={_t("msg.confirm_leave")} />
      <Form
        {...consts.formItemProps}
        form={offerForm}
        onFinish={handleSave}
        initialValues={initialValues}
        onFieldsChange={checkForChange}
        onValuesChange={handleFormChange}
      >
        <Form.Item noStyle shouldUpdate>
          <InputField label={_t('tax_case', 'number_short')} name={"taxCaseNumber"}>
            {resellerTaxCase.taxCaseId ? <Link to={getPath("tax", resellerTaxCase.taxCaseId)}
              target="_blank">{resellerTaxCase.taxCaseId}</Link> : '-'}
          </InputField>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          <InputField label={_t('new_price')} name={"newPrice"}>
            <InputNum min={0} suffix={"kr."} disabled={optMode} />
          </InputField>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          <InputField label={_t('trade_price')} name={"tradePrice"}>
            <InputNum min={0} suffix={"kr."} disabled={optMode} />
          </InputField>
        </Form.Item>

        <Divider className="mt-05 mb-1" />

        <Form.Item noStyle shouldUpdate>
          <InputField label={optMode ? _t('registration_fee') : _t("estimated_short", "registration_fee")}
            name={"registrationFee"}>
            <InputNum min={0} suffix={"kr."} disabled={optMode} />
          </InputField>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          <InputField label={_t("license_plate_fee")} name={"licensePlateFee"}>
            <InputNum min={0} suffix={"kr."} disabled={optMode} />
          </InputField>
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          <InputField label={_t("offer_processingFee")} name={"caseFee"}>
            <InputNum min={0} suffix={"kr."} disabled={optMode} />
          </InputField>
        </Form.Item>

        {optMode ? (
          <ResellerTaxCollateralAmount />
        ) : (
          <PercentageRegulatedInputField
            label={_t("collateralFee")}
            name={"collateralFee"}
          />
        )}

        <Divider className="mt-05 mb-1" />
        <OfferTotal resellerTaxCase={resellerTaxCase} />

        <Row className="pt-1" justify="space-between">
          <Col>
            <ResellerTaxOfferArchived optMode={optMode} />
          </Col>
          <Col>
            <SavedStatus status={savedStatus} />
            <Form.Item shouldUpdate noStyle>
              {({ getFieldsValue }) => (
                <Button
                  loading={loading}
                  onClick={() => handleSave(getFieldsValue(true))}
                  type="primary"
                >
                  {_t("save")}
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
        {!optMode && (
          <ResellerTaxOfferActions
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </Form>
    </>
  );
};

export default ResellerTaxOfferEdit;
