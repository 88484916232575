import React, { useState } from "react";
import { Affix, Button, Col, Dropdown, Layout, Menu, Modal, Row } from "antd";
import appConfig from "../appConfig";
import _t from "../lang/translate";
import ContactMenuDropdown from "../components/contactMenu";
import LayoutStore from "../contexts/layoutStore";
import { getApiBaseUrl } from "../services/apiEndpoint";
import UserStore from "../contexts/userStore";
import ContactButton from "../components/contactButton";
import Tools from "../testing/tools";
import { getIsFirstLogin, saveIsFirstLogin } from "../services/authService";

const { Footer } = Layout;

const AppFooter: React.FC = () => {
  const { screen } = LayoutStore.useContainer();
  const { showCookiePolicy } = UserStore.useContainer();
  const showCookies = !!process.env.REACT_APP_COOKIEBOT_ID;
  const useDebugTools = !(process.env.REACT_APP_GIT_BRANCH === "master" && process.env.NODE_ENV === "production");
  const [showIntroModal, setShowIntroModal] = useState<boolean>(getIsFirstLogin);
  const closeIntroModal = () => {
    setShowIntroModal(false);
    saveIsFirstLogin(false);
  }

  const bankInfo = () => {
    const { name, registrationNumber, accountNumber } = appConfig("bankInfo");
    return (
      <Menu className="p-1">
        <strong>{name}</strong>
        <br />
        {_t("registration_number_short")} {registrationNumber}
        <br />
        {_t("account_number")} {accountNumber}
      </Menu>
    );
  };

  return (
    <>
      <Footer>
        <Row gutter={[16, 8]} align="middle">
          <Col lg={8} xs={24} md={{ span: 12 }} order={screen.isTablet ? 2 : 1}>
            {appConfig("companyName")} © {new Date().getFullYear()}
          </Col>
          <Col lg={8} xs={24} md={{ span: 12 }} order={screen.isTablet ? 1 : 2} className={"text-center"}>
            <ContactMenuDropdown />
            •
            <Dropdown overlay={bankInfo()} placement="topCenter" arrow>
              <Button className="muted pl-1 pr-1" type="link">
                Bankinfo
              </Button>
            </Dropdown>
            •
            <Button className="muted pl-1 pr-1" type="link">
              <a href={getApiBaseUrl(appConfig("static")?.subscriptionTerms)} target="_blank">
                {_t("subscription_terms")}
              </a>
            </Button>
            {showCookies && (
              <>
                •
                <Button className="muted pl-1 pr-1" type="link" onClick={showCookiePolicy}>
                  {_t('cookie_policy')}
                </Button>
              </>
            )}
            {useDebugTools && <Tools />}
          </Col>
          {<Col lg={8} xs={24} order={3} className="text-right">
            <Affix offsetBottom={10} style={{ zIndex: 99, position: "relative" }}>
              <ContactButton />
            </Affix>
          </Col>}
        </Row>
      </Footer>

      <Modal
        width={800}
        visible={showIntroModal}
        onCancel={closeIntroModal}
        destroyOnClose
        footer={null}
        title={_t("hey_welcome")}
      >
        <video autoPlay={true} controls className="w-100">
          <source type="video/mp4" src={process.env.REACT_APP_INTRO_VIDEO} />
        </video>
      </Modal>
    </>
  );
};

export default AppFooter;
