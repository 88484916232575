import {
    calculateNewPrice,
    calculateRegistrationFee,
    calculateTradePrice,
    Advert,
    AmendedPrivateInput,
    TradePriceInput,
    regulateSingleVehicleEquipment
} from '@dmf/calculator-library'
import _t from '../../../../../lang/translate';
import { TaxCase } from '../../../../../types/taxTypes';
import { mapEmissionUnit } from '../../calc/registrationFeeDecorator';
import type {
    TaxEstimationRobotData, SimilarVehicle, TaxEstimationOutput, EquipmentTaxEstimationRobot
} from "../../../../../types/taxEstimationTypes";
import { getRandomString } from '../../../../../utilities/stringExtraFunctions';
import { dateDiff } from '../../../../../utilities/date'



const calculate = (
    vehicle: TaxCase,
    taxEstimationRobotData: TaxEstimationRobotData
): TaxEstimationOutput => {
    try {
        const newPrice = calculateNewPrice({
            equipmentPrice: taxEstimationRobotData.resolvedValues?.equipmentPrice ?? 0,
            newPrice: taxEstimationRobotData.resolvedValues.newPrice ?? 0,
            technicalNewPrice: 0
        });

        const tradePrice = getTradePrice(vehicle, taxEstimationRobotData)

        const registrationFee = calculateRegistrationFee(getRegistrationFeeInput(
            vehicle,
            newPrice.totalSalesPrice,
            tradePrice.result?.tradePrice || 0
        ))

        const confidenceInterval = calculateConfidenceInterval(
            registrationFee.result.taxAfterAllDeductions,
            taxEstimationRobotData.robotDetails.confidenceLevel
        )
        if (tradePrice.result && newPrice && registrationFee) {
            return {
                newPrice,
                tradePrice,
                registrationFee,
                confidenceInterval
            };
        } else {
            throw new Error(_t('msg.unknown_error'))
        }

    } catch (e: unknown) {
        throw e
    }
}

export default calculate;


const calculateConfidenceInterval = (
    registrationFee: number,
    confidenceLevel: number
): TaxEstimationOutput['confidenceInterval'] => {
    let rate = confidenceLevel > 1
        ? confidenceLevel / 100
        : confidenceLevel;
    return {
        low: registrationFee * rate,
        high: registrationFee * (1 + (1 - rate)),
    }
}



const getRegistrationFeeInput = (vehicle: TaxCase, newPrice: number, tradePrice: number): AmendedPrivateInput => {
    return {
        electricConsumption: vehicle.vehicle?.electricConsumption ?? 0,
        electricRange: vehicle.vehicle?.electricRange ?? 0,
        batteryCapacity: vehicle.vehicle?.batteryCapacity ?? 0,
        emissionUnit: mapEmissionUnit(vehicle.vehicle?.emissionUnitId),
        emissionValue: vehicle.vehicle?.emissionAmount ?? 0,
        isNew: false,
        newPrice: newPrice,
        taxableValue: null,
        tradePrice: tradePrice,
        vehicleType: 'PRIVATE_VEHICLE',
        versionYear: new Date().getFullYear() as 2023
    }
}

const getTradePrice = (
    vehicle: TaxCase,
    taxEstimationRobotData: TaxEstimationRobotData
) => {

    const equipmentPrice = getEquipmentPrice(vehicle, taxEstimationRobotData);
    const tradePriceInput = getTradePriceInput(
        vehicle, equipmentPrice, taxEstimationRobotData
    );
    const tradePrice = calculateTradePrice(tradePriceInput);
    return tradePrice;
}

const getTradePriceInput = (
    vehicle: TaxCase,
    filteredVehicleEquipmentPrice: number,
    taxEstimationRobotData: TaxEstimationRobotData
): TradePriceInput => {
    return {
        equipmentPrice: filteredVehicleEquipmentPrice,
        firstRegDate: vehicle.vehicle?.firstRegDate ?? new Date().toISOString(),
        mileage: vehicle.vehicle?.mileage ?? 0,
        quality: 'AVERAGE',
        specialUse: false,
        calcDate: vehicle.log?.creation?.date
            ? new Date(vehicle.log.creation.date).toISOString()
            : new Date().toISOString(),
        adverts: mapAdverts(taxEstimationRobotData.similarVehicles)
    }
}

const mapAdverts = (adverts: SimilarVehicle[]): Advert[] => {
    return adverts.map((ad: SimilarVehicle): Advert => {
        return {
            equipmentPrice: getAdvertEquipmentPrice(ad),
            firstRegDateYear: ad.vehicle?.firstRegDate
                ? new Date(ad.vehicle?.firstRegDate).getFullYear()
                : new Date().getFullYear(),
            isSold: true,
            mileage: ad.vehicle?.mileage ?? 0,
            salesPrice: ad.vehicle?.salesPrice ?? 0,
        }
    })
}

function getAdvertEquipmentPrice(advert: SimilarVehicle) {
    let equipmentPrice = advert.inferredEquipmentPrice
        ?? advert.vehicle.equipmentPrice
        ?? 0;
    return equipmentPrice >= 0 ? equipmentPrice : 0;
}

const getEquipmentPrice = (
    vehicle: TaxCase,
    taxEstimationRobotData: TaxEstimationRobotData
): number => {
    const unfilteredEquipmentPrice = taxEstimationRobotData.resolvedValues.equipmentPrice;
    let equipmentPrice = unfilteredEquipmentPrice;

    const equipment = getCalculatorConformantEquipment(
        taxEstimationRobotData.resolvedValues.equipments
    );
    if (equipment.length > 0) { // TODO write function to check shape of object?
        const vehicleEquipmentKeys = getEquipmentKeys(equipment)
        const ageInYearsToDate = getAgeInYearsToDate(vehicle)

        const regulatedEquipment = regulateSingleVehicleEquipment(
            vehicleEquipmentKeys, equipment, ageInYearsToDate
        );

        equipmentPrice = regulatedEquipment.regulatedSum
    }
    return equipmentPrice;
}

const getCalculatorConformantEquipment = (equipmentList: EquipmentTaxEstimationRobot[]) => {
    return equipmentList.map(singleEquipment => {
        return equipmentFactory(singleEquipment);
    })
}

const equipmentFactory = (singleEquipment: EquipmentTaxEstimationRobot): CalculatorEquipment => {
    return {
        category: 'NONE_DEDUCTABLE',
        code: singleEquipment.code,
        key: singleEquipment.id ?? getRandomString(5),
        name: singleEquipment.name,
        price: singleEquipment.price ?? 0,
    }
}

const getEquipmentKeys = (equipmentList: CalculatorEquipment[]): CalculatorEquipmentKeys => {
    return equipmentList.map(equipment => {
        return equipment.key
    })
}

const getAgeInYearsToDate = (taxCase: TaxCase): AgeInYearsToDate => {
    const firstRegDate = taxCase.vehicle?.firstRegDate;

    // TODO -->  consider how to handle calcDate
    const calcDate = taxCase.log?.creation?.date;

    const ageInYearsToDate = dateDiff({
        start: calcDate || new Date().toISOString(),
        end: firstRegDate,
        unitOfTime: 'years'
    })
    return ageInYearsToDate;
}


type CalculatorEquipmentKeys = Parameters<typeof regulateSingleVehicleEquipment>[0];
type CalculatorEquipment = Parameters<typeof regulateSingleVehicleEquipment>[1][number];
type AgeInYearsToDate = Parameters<typeof regulateSingleVehicleEquipment>[2];
