import { VehicleReportCreateType } from "../types/vehicleReportTypes";
import getApiEndpoint from "./apiEndpoint";
import http from "./httpService";
import { getApiQuery } from "./urlQueryService";

const apiEndpoint = `${getApiEndpoint()}/vehicleReport`;

export function vehicleReportsUrl(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function vehicleReportDownload(id: number) {
  return http.get<{ url: string }>(`${apiEndpoint}/${id}/downloadReport`);
}

export function vehicleReportCreate(data: VehicleReportCreateType) {
  return http.post<{ url: string }>(apiEndpoint, data);
}
