import { Button, ButtonProps } from "antd";
import React, { FC } from "react";

export interface CheckableBoxProps extends ButtonProps {
  checked?: boolean;
}

const CheckableBox: FC<CheckableBoxProps> = ({ checked = false, children, className, block = true, ...rest }) => {
  return (
    <Button
      type="text"
      className={`${className ? className : ""
        } bg-muted border-light-2 hover-border-primary hover-bg-muted p-05 height-auto ${checked ? "border-primary" : ""
        }`}
      block={block}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default CheckableBox;
