import { Result, Table } from "antd";
import { FC } from "react";
import _t from "../../../lang/translate";
import { statisticsUrl } from "../../../services/dealerService";
import { useApi } from "../../../services/useApi";

const DealerStatistics: FC<{ dealerId: number }> = ({ dealerId }) => {
  const [{ data, isLoading, isError }] = useApi(statisticsUrl(dealerId), {
    data: {
      columns: [],
      rows: [],
    },
  });

  if (isError) return <Result status="error" title={_t("msg.unknown_error")} />;

  const columns = data.data.columns;

  return (
    <Table
      rowKey="label"
      dataSource={data.data.rows}
      loading={isLoading}
      scroll={{ x: "auto" }}
      columns={columns}
      pagination={false}
    />
  );
};

export default DealerStatistics;
