import { UploadFile } from "antd/lib/upload/interface";
import {
  Client,
  EnumItemType,
  IdType,
  InitialClient,
  LogObj,
  Person,
  RelatedCasesType,
  StatusLogObj,
  Vehicle,
} from "./appTypes";
import { CustomerType } from "./customerTypes";

export interface ResellerTaxListItem {
  id: IdType;
  status: EnumItemType;
  createdAt: string;
  customerName: string;
  importantNote: string | null;
  dealerName: string;
  car: string;
  horsePower: string;
  registrationFee: number;
  totalPrice: number;
  vin: number;
  mileage: number;
  uploaded: boolean;
  paid: boolean;
  invoiceNumber: number;
}

const resellerTaxDataSources = ["vin", "link", "manual"] as const;

export type ResellerTaxDataSourceType = typeof resellerTaxDataSources[number];

export function isTaxDataSource(key: string): key is ResellerTaxDataSourceType {
  return resellerTaxDataSources.includes(key as ResellerTaxDataSourceType);
}

// api/v1/resellerTaxCase
export interface ResellerTaxCaseCreation {
  id: IdType;
  vehicle: Vehicle | null;
  note: string | null;
  dataSourceId?: IdType;
  evaluationTypeId: IdType;
  vehicleDataSource: Vehicle | null;
  files: UploadFile[] | null;
  client: InitialClient | null;
  flags: ResellerTaxFlagsType;
  paymentMethodId: IdType;
  reason?: string | null;
}

export type ResellerTaxCustomerType = Pick<CustomerType,
  "prospectId" |
  "typeId" |
  "name" |
  "cvr" |
  "nameFirst" |
  "nameLast" |
  "address" |
  "zip" |
  "city" |
  "phone" |
  "email"
>

export interface ResellerTaxCase extends ResellerTaxCaseCreation {
  status: EnumItemType | null;
  taxCaseId: null | IdType;
  invoiceStartedAt: string | null;
  client: Client | null;
  customerInfo: ResellerTaxCustomerType | null;
  flags: ResellerTaxFlagsType;
  log: LogsResellerTaxCase | null;
  importantNote: string | null;
  links: LinksResellerTax | null;
  internalInvoice: UploadFile[] | null;
  externalReceipt: UploadFile[] | null;
  offer: OfferResellerTaxType | null;
  optimization: OptimizationResellerTaxType | null;
  internalChecklist: {
    optimizationRequested: StatusLogObj | null;
    optimizedTaxCase: StatusLogObj | null;
    invoiceSentToPayment: StatusLogObj | null;
    invoicePaidInternally: StatusLogObj | null;
  }
}

export interface LinksResellerTax {
  invoiceLink: string | null;
  offerPdfLink: string | null;
}

export interface OptimizationResellerTaxType {
  newPrice: number | null;
  tradePrice: number | null;
  registrationFee: number | null;
  licensePlateFee: number | null;
  caseFee: number | null;
  collateralFee: number | null;
  total: number | null;
}

export interface ResellerTaxFlagsType {
  forcedStatus: boolean;
  accepted: boolean;
  uploaded: boolean;
  paid: boolean;
  expired: boolean;
  allowRecalculateRequest: boolean;
  receipted: boolean;
  sent: boolean;
}

export type AcceptOfferFormType = {
  status: boolean;
};

export interface VehicleDataSource {
  typeId: IdType;
  link?: string;
  vin?: string;
  vehicle?: Vehicle;
  isInspected?: boolean;
  mileage?: number;
  useCaseId?: IdType;
}

export interface LogsResellerTaxCase {
  creation: LogObj | null;
  assessed: LogObj | null;
  offerSent: LogObj | null;
  accepted: LogObj | null;
  paid: LogObj | null;
  uploaded: LogObj | null;
  receipted: LogObj | null;
}

// api/v1/taxCase/:id/updateStatus
export interface StatusUpdateTax {
  id: IdType;
  forceStatus: boolean;
  autoStatus?: boolean;
}

export interface TradePriceCalcResellerTaxType {
  adVehicles?: any;
  averagePrice: number | null;
  deliveryFee: number | null;
  tradePriceOutput?: any;
  estimatedTradePrice: number | null;
  deductionAverageDiscount: number | null;
  priceLevel: number | null;
  qualityAmount: number | null;
  specialUseAmount: number | null;
  mileageRegulation: number | null;
  equipmentRegulation: number | null;
  otherRegulation: number | null;
  otherRegulationAmount: number | null;
  optimizedRegulation: number | null;
  optimizedRegulationAmount: number | null;
  tradePrice: number | null;
}

export interface OfferResellerTaxType {
  newPrice: number | null;
  tradePrice: number | null;
  registrationFee: number | null;
  licensePlateFee: number | null;
  caseFee: number | null;
  collateralFee: number | null;
  total: number | null;
}

export interface ResellerTaxSettingsType {
  documentationExpirationDate?: string | null;
  guaranteeLengthInMonths?: number | null;
  productPrice: number | null;
}

export type SendOfferFormType = {
  message?: string | null;
};

export interface OptimizationArchiveType extends OptimizationResellerTaxType {
  id: IdType;
  createdAt: string | null;
  person: Person | null;
}

export interface OfferArchiveType extends OfferResellerTaxType {
  id: IdType;
  createdAt: string | null;
  person: Person | null;
}

export interface RejectCaseType {
  reason: string | null;
}

export const initResellerTaxCase: ResellerTaxCase = {
  id: 0,
  importantNote: null,
  taxCaseId: null,
  evaluationTypeId: 1,
  links: null,
  invoiceStartedAt: null,
  vehicle: null,
  note: null,
  customerInfo: null,
  internalInvoice: null,
  externalReceipt: null,
  vehicleDataSource: null,
  files: null,
  client: null,
  flags: {
    forcedStatus: false,
    accepted: false,
    uploaded: false,
    paid: false,
    expired: false,
    allowRecalculateRequest: false,
    receipted: false,
    sent: false,
  },
  paymentMethodId: 1,
  status: null,
  log: null,
  offer: null,
  optimization: null,
  internalChecklist: {
    optimizationRequested: null,
    optimizedTaxCase: null,
    invoiceSentToPayment: null,
    invoicePaidInternally: null,
  }
};
