import { Button, Form, message, Modal as AntModal, Typography } from "antd";
import React, { FC, useState } from "react";
import _t from "../../lang/translate";
import { isAxiosError } from "../../utilities/typeGuard";
import type { ButtonType } from "antd/es/button";
import TextArea from "antd/lib/input/TextArea";
import { contactSupport } from "../../services/miscService";
import { ContactFormType } from "../../types/appTypes";

const { Title } = Typography;

type ContactForPackageProps = {
  disabled?: boolean,
  children?: React.ReactNode;
  type?: ButtonType
}

const ContactFormPackage: FC<ContactForPackageProps> = ({ children, disabled, type }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<ContactFormType>();

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      await contactSupport({ message: values.note, type: 'general' });
      message.success(_t("message", "sent"));
      setShowModal(false);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Button
        type={type || "link"} onClick={() => setShowModal(true)}
        className={type ? '' : "p-0 muted"}
        disabled={disabled}
      >
        {children || _t('contact_us_for_enterprise')}
      </Button>

      <AntModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        confirmLoading={loading}
        onOk={form.submit}
        okText={_t('contact_us')}
        cancelText={_t('go_back')}
        className="no-footer-border"
        bodyStyle={{ paddingBottom: 0 }}
      >
        <Title level={3} className="text-center mt-2 pb-1">
          <span className="text-bold">{_t('contact_us_for_enterprise')}</span>
        </Title>

        <p className="mt-2 mb-2">
          {_t('enterprise_contact_text')}
        </p>

        <Form form={form} requiredMark={false} layout="vertical" onFinish={handleSubmit}>
          <Form.Item rules={[{ required: true, message: "Dette felt er påkrævet!" }]} name="note">
            <TextArea placeholder={_t("message")} rows={6} />
          </Form.Item>
        </Form>
      </AntModal>
    </>
  )
}

export default ContactFormPackage;
