import { FC, ReactNode } from "react";
import { PageHeader } from "antd";
import Icon from "../assets/icons/icon";
import { useHistory } from "react-router-dom";
import { PageHeaderProps } from "antd/lib/page-header";

interface PageTitleProps extends PageHeaderProps {
  title: ReactNode;
  fluid?: boolean;
  headerMargin?: boolean;
  backBtn?: boolean;
}

const PageTitle: FC<PageTitleProps> = ({
  title,
  backBtn = false,
  fluid,
  headerMargin = true,
  className = "",
  ...rest
}) => {
  const history = useHistory();
  //const { url } = useRouteMatch();
  //const backUrl: string = url.substring(0, url.lastIndexOf("/"));
  const handleBackBtn: () => void = () => history.goBack();
  //backUrl ? history.push(backUrl) : history.goBack();

  if (fluid) {
    className += " fluid-page-title";
  }
  if (headerMargin) {
    className += " header-heading-margin";
  }

  return (
    <PageHeader
      className={className}
      title={
        backBtn ? (
          <div className="back-btn" onClick={handleBackBtn}>
            <Icon name="chevron-back-outline" />
            {title}
          </div>
        ) : (
          title
        )
      }
      {...rest}
    />
  );
};

export default PageTitle;
