import React, { FC } from "react";
import { Alert, Card, Col, Row, Tabs, Typography } from "antd";
import TaxAttachments from "../taxAttachments";
import TaxOfferView from "./taxOfferView";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import TaxDocDownloads from "./taxDocDownloads";
import TaxDownloads from "./taxDownloads";
import TaxDocProcessSteps from "./taxDocProcessSteps";
import TaxProcessSteps from "./taxProcessSteps";
import Icon from "../../../../assets/icons/icon";
import TaxPaymentFlow from "./paymentFlow/taxPaymentFlow";
import CardBox from "../../../../components/cardBox";
import FoldableVehicleInfo from "../../../../components/vehicleInfoViews/foldableVehicleInfo";

const { TabPane } = Tabs;

const TaxCaseView: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const { documentationBought, receipted } = taxCase.flags;
  return (
    <Row align="top" gutter={[24, 24]}>
      <Col span={24}>
        <div className="overflow-x-auto mx-width-100">
          {documentationBought ? <TaxDocProcessSteps /> : <TaxProcessSteps />}
        </div>
      </Col>
      {receipted && (
        <Col span={24}>
          <Row justify="space-between" wrap={false} align="middle" className="case-completed-banner">
            <Col>
              <Typography.Title className="mb-0 text-white-important text-bold">
                {_t("vehicle_is_now_evaluated")}
              </Typography.Title>
            </Col>
            <Col><Icon color="#074B7D" width={48} height={48} name="handshake-duotone" /></Col>
          </Row>
        </Col>
      )}
      <Col xxl={16} xl={12} xs={24}>
        <Row gutter={24}>
          <Col
            xxl={{ span: 12, order: 1 }}
            xl={{ span: 24, order: 2 }}
            xs={{ span: 24, order: 2 }}
          >
            <Card bordered={false} title={_t("vehicle")}>
              <FoldableVehicleInfo vehicle={taxCase.vehicle} />
              <CardBox title={_t("note")}>{taxCase.note || "-"}</CardBox>
            </Card>
          </Col>
          <Col
            xxl={{ span: 12, order: 2 }}
            xl={{ span: 24, order: 1 }}
            xs={{ span: 24, order: 1 }}
          >
            <TaxOfferView />
          </Col>
        </Row>
      </Col>
      <Col xxl={8} xl={12} xs={24}>
        {taxCase.importantNote && (
          <Alert
            className="mb-2"
            message={taxCase.importantNote}
            type="warning"
            closable
          />
        )}

        <Card bordered={false} title={_t("payment")}>
          <Tabs defaultActiveKey={"1"}>
            <TabPane tab={_t("payment")} key="1" className="pb-05">
              <TaxPaymentFlow />
            </TabPane>
            <TabPane tab={_t("receipt")} key="2" className="pb-05">
              {documentationBought ? <TaxDocDownloads /> : <TaxDownloads />}
            </TabPane>
          </Tabs>
        </Card>
        <TaxAttachments />
      </Col>
    </Row>
  );
};

export default TaxCaseView;
