import { message } from "antd";
import { AxiosRequestConfig } from "axios";
import appConfig from "../appConfig";
import _t from "../lang/translate";
import getApiEndpoint from "./apiEndpoint";
import http from "./httpService";

export const mediaApiEndpoint = `${getApiEndpoint()}/${appConfig("uploadApi")}`;

export function deleteFile(baseUrl: string, id: string | number) {
  return http.delete(baseUrl + "/" + id);
}

export function updateFile(baseUrl: string, id: string | number, data: any) {
  return http.put(baseUrl + "/" + id, data);
}

export function saveFile(
  baseUrl: string,
  file: any,
  config?: AxiosRequestConfig
) {
  return http.post(baseUrl, file, config);
}

export function getFileLink(url: string, method?: string | null, body?: object | null) {
  let res;

  switch (method) {
    case "post":
      res = http.post(url, body);
      break;
    case "put":
      res = http.put(url, body);
      break;
    case "patch":
      res = http.patch(url, body);
      break;
    case "delete":
      res = http.delete(url);
      break;
    default:
      res = http.get(url);
  }

  return res;
}

export async function openFileLink(url?: string | null, method?: string | null, body?: object | null) {
  if (url)
    try {
      const { data } = await getFileLink(url, method, body);
      if (data.url) window.open(data.url, "_blank");
    } catch (ex) {
      message.error(_t("msg.link_not_generated"));
    }
}
