import { Col, Form, Row } from "antd";
import { FC } from "react";
import InputPercentage from "../../../../components/form/inputPercentage";
import { OfferTax } from "../../../../types/taxTypes";
import RegulationAmountInput from "./regulationAmountInput";

interface RegulationFieldsProps {
  nameKey: "otherRegulation" | "optimizedRegulation";
  label: string;
}

const RegulationFields: FC<RegulationFieldsProps> = ({ nameKey, label }) => {
  const shouldUpdate = (curr: OfferTax, next: OfferTax) => {
    if (curr?.tradePrice && next?.tradePrice) {
      return (
        curr?.tradePrice[nameKey] !== next?.tradePrice[nameKey] ||
        curr?.tradePrice?.tradePriceOutput?.tradingPriceTotal !==
        next?.tradePrice?.tradePriceOutput?.tradingPriceTotal
      );
    }
    return false;
  };

  return (
    <Form.Item label={label}>
      <Row className="flex-wrap" gutter={8}>
        <Col flex="100px">
          <Form.Item name={["tradePrice", nameKey]} noStyle>
            <InputPercentage />
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Form.Item shouldUpdate={shouldUpdate} noStyle>
            {({ getFieldValue }) => {
              const priceLevel =
                getFieldValue([
                  "tradePrice",
                  "tradePriceOutput",
                  "tradingPriceTotal",
                ]) || 0;
              return (
                <Form.Item name={["tradePrice", `${nameKey}Amount`]} noStyle>
                  <RegulationAmountInput
                    priceLevel={priceLevel}
                    regulationRate={getFieldValue(["tradePrice", nameKey])}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default RegulationFields;
