import { Divider, Tag } from "antd";
import React, { FC } from "react";
import _t from "../../../../lang/translate";
import { Client } from "../../../../types/appTypes";
import format from "../../../../utilities/formatNumbers";

interface ClientInfoProps {
  client: Client;
}

const ClientInfo: FC<ClientInfoProps> = ({ client }) => {
  const { dealerLevel, dealerNote, dealerCTR } = client;
  return (
    <div className="pt-1 pb-1 min-width-240 mx-width-340">
      {dealerLevel && <Tag color="processing">{dealerLevel}</Tag>}
      {dealerCTR && (
        <Tag color="success">
          {format.number(dealerCTR, {
            style: "percent",
          })}
        </Tag>
      )}
      <Divider />
      <p className="mb-0">
        <label className="d-block">{_t("note")}</label>
        {dealerNote || "-"}
      </p>
    </div>
  );
};

export default ClientInfo;
