import { useEffect, useRef } from "react"
import { useLocation } from "react-router"


export default function usePrevLocation(location: ReturnType<typeof useLocation>) {
  const prevLocRef = useRef(location)
  useEffect(() => {
    prevLocRef.current = location
  }, [location])
  return prevLocRef.current
}
