import { FC, useState } from "react";
import CollapseCard from "../../../components/collapseCard";
import CustomerStore from "../../../contexts/customerStore";
import _t from "../../../lang/translate";
import { getCustomerName } from "../../../services/customerService";
import CustomerInfoForm from "./customerInfoForm";
import CustomerInfoView from "./customerInfoView";

const CustomerInfoCard: FC = () => {
  const { customer, isNew } = CustomerStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <CollapseCard title={getCustomerName(customer) || _t("main_info")} loading={loading}>
      {isEditing || isNew ? (
        <CustomerInfoForm setLoading={setLoading} stopEditing={stopEditing} />
      ) : (
        <CustomerInfoView startEditing={startEditing} />
      )}
    </CollapseCard>
  );
};

export default CustomerInfoCard;
