import React, { FC, useState } from "react";
import { Button, message } from "antd";
import Icon from "../../../../assets/icons/icon";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { getFileLink } from "../../../../services/mediaService";

const TaxDownloadOfferBtn: FC = () => {
  const [loading, setLoading] = useState<boolean>();
  const { taxCase } = TaxCaseStore.useContainer();
  const { flags, links } = taxCase;
  const link = links?.offerPdfLink;
  const downloadIcon = <Icon size="large" name="download-outline" />;

  const downloadOffer = async () => {
    if (!link) return;
    try {
      setLoading(true);
      const { data } = await getFileLink(link);
      if (data.url) window.open(data.url, "_blank");
    } catch (ex) {
      message.error(_t("msg.link_not_generated"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      type="primary"
      ghost
      disabled={!flags.published}
      icon={downloadIcon}
      onClick={downloadOffer}
      loading={loading}
    >
      {_t("pdf")}
    </Button>
  );
};

export default TaxDownloadOfferBtn;
