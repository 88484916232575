import { Space, SpaceProps } from "antd";
import { FC } from "react";
import Icon from "../assets/icons/icon";
import _t from "../lang/translate";
import { getCustomerName } from "../services/customerService";
import { CustomerType } from "../types/customerTypes";

interface CustomerCardProps extends SpaceProps {
  customer: CustomerType | null;
}

const CustomerCard: FC<CustomerCardProps> = ({ customer, ...rest }) => {
  const { typeId, email, phone, cvr, address, zip, city } = customer || {};
  const nameDisplay = customer ? getCustomerName(customer) : "-";

  return (
    <Space direction="vertical" {...rest}>
      <h3 className="mb-0">{nameDisplay}</h3>
      <Space align="start">
        <Icon name="location-outline" size="large" />
        <span>
          {address || "-"}
          <br />
          {zip || "-"}&nbsp;{city || "-"}
        </span>
      </Space>

      {typeId !== 1 && (
        <span>
          {_t("cvr")}: {cvr || ""}
        </span>
      )}

      <Space align="start">
        <Icon name="mail-outline" size="large" />
        {email || "-"}
      </Space>

      <Space align="start">
        <Icon name="call-outline" size="large" />
        {phone || "-"}
      </Space>
    </Space>
  );
};

export default CustomerCard;
