import React, { FC } from "react";
import CaseStatus from "../../../../components/caseStatus";
import CollapseCard from "../../../../components/collapseCard";
import DividerLine from "../../../../components/lineSplit";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import TaxDocEstimation from "./taxDocEstimation";
import TaxDocPurchase from "./taxDocPurchase";
import TaxDocPurchasedMessage from "./taxDocPurchasedMessage";
import TaxDocRegPrice from "./taxDocRegPrice";
import TaxDocRejectedMessage from "./taxDocRejectedMessage";
import TaxDocTotalPrice from "./taxDocTotalPrice";

const TaxDocEvaluation: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  const { rejected, documentationPublished, documentationBought } = taxDocCase.flags;

  const getContent = () => {
    if (rejected) {
      return <TaxDocRejectedMessage />;
    }
    if (documentationPublished || documentationBought) {
      return (
        <>
          <TaxDocEstimation />
          <DividerLine fluid />
          {documentationBought ? <TaxDocPurchasedMessage /> : <TaxDocPurchase />}
          <DividerLine fluid />
          <TaxDocRegPrice />
          <DividerLine fluid />
          <TaxDocTotalPrice />
        </>
      );
    }
    return <CaseStatus status="calculating" title={_t("msg.working_on_the_case")} />;
  };

  return (
    <CollapseCard bordered={false} title={_t("evaluation")}>
      {getContent()}
    </CollapseCard>
  );
};

export default TaxDocEvaluation;
