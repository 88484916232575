import { Badge, Button, Col, List, message, Row, Typography } from "antd";
import React, { FC, useState } from "react";
import PageTitle from "../layout/pageTitle";
import { ScreenProps } from "../types/appTypes";
import { useApi } from "../services/useApi";
import UserStore from "../contexts/userStore";
import PageBody from "../layout/pageBody";
import { SubscriptionPackageType, SubscriptionType } from "../types/subscriptionTypes";
import { subscriptionPackageUrl } from "../services/subcriptionService";
import SubscriptionModal from "../components/subscription/modal";
import _t from "../lang/translate";
import ReactMarkdown from "react-markdown";
import CancelSubscription from "../components/subscription/cancelSubscription";
import Loading from "../components/loading";
import Icon from "../assets/icons/icon";
import format from "../utilities/formatNumbers";
import ContactFormPackage from "../components/subscription/contactFormPackage";
import { capitalize } from "../utilities/stringExtraFunctions";
import { cancelSubscriptionPackage } from "../services/subcriptionService";
import { renderErrorMessage } from "../components/messages/errorMessage";
import moment from "moment";

const { Title } = Typography;

const Subscription: FC<ScreenProps> = ({ title }) => {
  const { user, fetchUserData } = UserStore.useContainer();
  const [wantedPackage, setWantedPackage] = useState<SubscriptionPackageType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [{ data, isLoading }] = useApi<{ data: SubscriptionPackageType[] }>(subscriptionPackageUrl, { data: [] });

  const packages = data.data
  packages.sort((a, b) => Number(a.level) > Number(b.level) ? 1 : -1);

  const userSubPackageId = user?.subscription?.package?.id;
  const hasSelectedSubscription = !!user?.subscriptionSelected;
  const hasCustomPackage = !!userSubPackageId && !data.data.find(subPackage => subPackage.id === userSubPackageId);

  const currentSubTitle = user?.subscription?.package?.title || _t('starter');

  const continueWithFreePackage = async () => {
    try {
      setLoading(true);
      await cancelSubscriptionPackage();
      message.success(_t("subscription", "continued"));
      fetchUserData();
    } catch (error) {
      renderErrorMessage(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <PageTitle
        fluid
        title={_t('choose', 'yourSubscription')}
      />
      <PageBody>
        {isLoading ? <Loading loading={true} /> : (
          <>
            <Title level={2} className="mt-2 mb-1">
              <span className="text-normal">{_t('yourCurrentSubscription')}:</span>&nbsp;
                <strong>{hasSelectedSubscription ? currentSubTitle : _t("undecided")}</strong>
            </Title>
            {user?.upcomingSubscription && (
              <UpcomingSubscription
                currentSubscription={user?.subscription}
                upcomingSubscription={user?.upcomingSubscription}
              />
            )}

            <Row justify="space-between" className="subscription-items-row" gutter={[16, 16]}>
              {packages.map((subPackage, i) => {
                const isSelected = (user?.subscription?.package?.id || null) === subPackage.id;
                const isRecommended = subPackage.recommended;

                const packageClasses = [
                  isRecommended ? 'bg-dark-blue2' : 'bg-white',
                  isRecommended ? 'text-white' : null,
                  'p-3 border-radius height-100 position-relative'
                ].filter(Boolean).join(' ');

                const titleColor = isRecommended ? 'text-white' : 'text-dark-blue';

                const descColor = isRecommended ? 'text-light-blue' : 'muted';
                const listColor = isRecommended ? 'text-white' : '';


                let priceText;
                let monthText;
                let buttonText;

                if (packages.length && i === packages.length - 1) {
                  priceText = _t('contact_us');
                  monthText = ' ';
                  buttonText = hasCustomPackage ? _t('yourSubscription') : _t('contact_us');
                } else {
                  priceText = subPackage.priceNoVat < 1 ? _t('free') : (
                    <span className="text-capitalize">{format.pricePrefixed(subPackage.priceNoVat)}</span>
                  );
                  monthText = subPackage.priceNoVat < 1 ? _t('always') : `/${_t('month')} + ${_t("vat")}`;
                  buttonText = (isSelected && hasSelectedSubscription) ? _t('yourSubscription') : (
                    <>
                      {_t('change')}
                      <span className="package-element-visible-xxl">
                        {` ${_t('to')} ${subPackage.title}`}
                      </span>
                    </>
                  )
                }

                return (
                  <Col key={subPackage.id}>
                    <div className={packageClasses}>
                      <Badge
                        count={isRecommended ? _t('recommended_package') : 0}
                        className="recommended-package-badge"
                      />

                      <Title level={3} className="mb-0">
                        <span className={`${titleColor} text-bold`}>{capitalize(subPackage.title || '')}</span>
                      </Title>
                      <div className={descColor} style={{ minHeight: "3rem" }}>{subPackage.description}</div>

                      <div className="mt-3">
                        <div className="text-40">
                          <strong className={`${titleColor}`}>{priceText}</strong>
                        </div>
                        <div className={`text-lower ${descColor}`}>{monthText}&nbsp;</div>
                      </div>

                      <div className="mt-3">
                        {i === 0 ? (
                          !hasSelectedSubscription ? (
                            <Button type="primary" onClick={continueWithFreePackage} loading={loading}>
                              {buttonText}
                            </Button>
                          ) : (
                            <CancelSubscription disabled={isSelected && hasSelectedSubscription} type="ghost">
                              {buttonText}
                            </CancelSubscription>
                          )
                        ) : (i === packages.length - 1) ? (
                          <ContactFormPackage type="primary" disabled={hasCustomPackage}>
                            {buttonText}
                          </ContactFormPackage>
                        ) : (
                          <Button type="primary" onClick={e => setWantedPackage(subPackage)}
                            disabled={isSelected}>
                            {buttonText}
                          </Button>
                        )}
                      </div>

                      <List
                        className="mt-3 flex-grow"
                        split={false}
                        itemLayout="horizontal"
                        size="small"
                        dataSource={subPackage.features || []}
                        renderItem={item => (
                          <List.Item className="px-0 pb-0">
                            <Row gutter={8} wrap={false}>
                              <Col className="package-element-visible-xxl">
                                <Icon name="checkmark-outline" className={isRecommended ? "text-blue" : "text-green"} />
                              </Col>
                              <Col className={listColor}>
                                <ReactMarkdown components={{ p: React.Fragment }}>{item}</ReactMarkdown>
                              </Col>
                            </Row>
                          </List.Item>
                        )}
                      />
                    </div>
                  </Col>
                )
              })}
            </Row>
          </>
        )}
      </PageBody>

      <SubscriptionModal wantedPackage={wantedPackage} setWantedPackage={setWantedPackage} />
    </>
  )
    ;
}
  ;

export default Subscription;

type UpcomingSubscriptionProps = {
  upcomingSubscription: SubscriptionType | null | undefined;
  currentSubscription: SubscriptionType | null | undefined;
}

const UpcomingSubscription = ({ currentSubscription, upcomingSubscription }: UpcomingSubscriptionProps) => {
  let upcomingSubTitle;
  const upcomingSubcriptionStartDate = format.date(moment().add(1, 'month').startOf('month').toISOString())
  if (currentSubscription?.endsAt && !upcomingSubscription) {
    // cancelled
    upcomingSubTitle = _t("starter")
  } else if (currentSubscription?.endsAt && upcomingSubscription) {
    // Changed plan
    upcomingSubTitle = upcomingSubscription.package?.title;
  }

  return (
    <Typography.Paragraph className="mb-2 text-dark-blue">
      {_t("yourSubscriptionChangesTo")}
      <Typography.Text italic strong className="mr-05 ml-05 text-dark-blue">{upcomingSubTitle}</Typography.Text>
      den
      <Typography.Text italic strong className="ml-05 text-dark-blue">{upcomingSubcriptionStartDate}</Typography.Text>
    </Typography.Paragraph>
  )
}
