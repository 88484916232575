import { FC } from "react";
import { Form } from "antd";
import TradePriceTotalField from "./tradePriceTotalField";
import { TradePriceCalcTaxType } from "../../../../types/taxTypes";

const TradePriceTotal: FC = () => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue }) => {
        const tradePrice = getFieldsValue(true)
          ?.tradePrice as TradePriceCalcTaxType;

        return (
          <Form.Item name={["tradePrice", "tradePrice"]} noStyle>
            <TradePriceTotalField
              otherRegulationAmount={tradePrice?.otherRegulationAmount}
              optimizedRegulationAmount={tradePrice?.optimizedRegulationAmount}
              tradePrice={tradePrice?.tradePriceOutput?.finalTradePriceTotal}
              useEstimatedTradePrice={tradePrice?.useEstimatedTradePrice}
              estimatedTradePrice={tradePrice?.estimatedTradePrice}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default TradePriceTotal;
