import { Button, Dropdown, Menu } from "antd";
import Icon from "../assets/icons/icon";
import appConfig from "../appConfig";
import _t from "../lang/translate";

export const contactMenu = (
  <Menu>
    <Menu.Item
      icon={<Icon name="call-outline" />}
      onClick={() => window.open(`tel:${appConfig("companyPhone")}`, "_self")}
    >
      {appConfig("companyPhone")}
    </Menu.Item>
    <Menu.Item
      icon={<Icon name="mail-outline" />}
      onClick={() => window.open(`mailto:${appConfig("companyEmail")}`, "_self")}
    >
      {appConfig("companyEmail")}
    </Menu.Item>
  </Menu>
);


const ContactMenuDropdown = () => {
  return (
    <Dropdown overlay={contactMenu} placement="topRight" arrow>
      <Button className="underline-style p-0" type="link">
        {_t("contact_us")}
      </Button>
    </Dropdown>
  )
}
export default ContactMenuDropdown;
