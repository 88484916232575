import { FC } from "react";
import { Button, Dropdown, Menu } from "antd";
import { DateSegmentType } from "../../types/statisticsType";
import Icon from "../../assets/icons/icon";
import { dateSegments, getDateSegmentByKey } from "../../services/statisticsService";

interface StatisticSegmentSelectProps {
  onChange: (value: DateSegmentType) => void;
  segment: DateSegmentType;
}

const StatisticSegmentSelect: FC<StatisticSegmentSelectProps> = ({ onChange, segment }) => {
  const menu = (
    <Menu selectedKeys={[segment]}>
      {dateSegments.map((ds) => (
        <Menu.Item key={ds.key} onClick={() => onChange(ds.key)}>
          {ds.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const selectedSegment = getDateSegmentByKey(segment);

  return (
    <Dropdown overlay={menu} placement="bottomRight" overlayClassName="width-125">
      <Button size="small" type="text" className="bg-none p-0 text-smaller">
        {selectedSegment.label}&nbsp;
        <span className="v-align-middle">
          <Icon size="small" name="chevron-down-outline" />
        </span>
      </Button>
    </Dropdown>
  );
};

export default StatisticSegmentSelect;
