import { Button, ButtonProps, message } from "antd";
import { FC, useState } from "react";
import ErrorMessage from "../../../../components/messages/errorMessage";
import SavedMessage from "../../../../components/messages/savedMessage";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { toggleInputLock } from "../../../../services/leasingService";

const LockCaseButton: FC<ButtonProps> = (props) => {
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const { id, flags } = leasingCase;

  const { inputLocked } = flags;

  const handleLockClick = async () => {
    try {
      setLoading(true);
      await toggleInputLock(id, !inputLocked);
      message.success(<SavedMessage />);
      setLeasingCase({ ...leasingCase, flags: { ...leasingCase.flags, inputLocked: !inputLocked } });
    } catch ({ response }) {
      message.error(<ErrorMessage message={response?.data?.message} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button {...props} loading={loading} onClick={handleLockClick}>
      {_t(inputLocked ? "unlock" : "lock")}
    </Button>
  );
};

export default LockCaseButton;
