import { Typography } from "antd";
import { RcFile } from "antd/lib/upload";
import moment from "moment";
import React, { FC, useState } from "react";
import Icon from "../../../../../assets/icons/icon";
import DragUpload from "../../../../../components/dragUpload";
import UploadButton from "../../../../../components/uploadButton";
import useIsDraggingFiles from "../../../../../hooks/useIsDraggingFiles";
import _t from "../../../../../lang/translate";
import { adsDataDocumentationFileEndpoint } from "../../../../../services/taxService";
import { IdType } from "../../../../../types/appTypes";

interface AdvCalcUploadBtnProps {
  caseId: IdType;
  onNewVehicles: (values: any) => void;
}

const AdvCalcUploadBtn: FC<AdvCalcUploadBtnProps> = ({ caseId, onNewVehicles }) => {
  const [isDragging] = useIsDraggingFiles({ isModal: true });
  const [loading, setLoading] = useState<boolean>(false);
  const mapCSVDataToTable = (data: { [x: string]: any }[]) => {
    const vehicles = data
      .filter((ad) => !!ad?.mileage || !!ad?.firstRegDate)
      .map((ad, index) => ({
        id: index + 1,
        enabled: true,
        mileage: parseInt(ad?.mileage.replace(/^\D+/g, ""), 10),
        price: parseInt(ad?.cashPrice.replace(/^\D+/g, ""), 10) || 0,
        year: moment(ad?.firstRegDate, "DD-MM-YYYY").get("year"),
        equipments: [],
      }));
    onNewVehicles(vehicles);
  };

  const handleCSVUpload = (file: RcFile) => {
    try {
      const reader = new FileReader();
      reader.onload = function (e) {
        const bufferString = e?.target?.result;
        if (typeof bufferString !== "string") return false;

        let arr = bufferString.split("\n");

        let jsonObj = [];
        let headers = arr[0].replace("\r", "").split(";");

        for (let i = 1; i < arr.length; i++) {
          let data = arr[i].split(";");
          let obj: { [x: string]: any } = {};
          for (let j = 0; j < data.length; j++) {
            obj[headers[j]?.trim()] = data[j]?.trim();
          }
          jsonObj.push(obj);
        }
        !!jsonObj.length && mapCSVDataToTable(jsonObj);
      };
      reader.readAsText(file);
      return true;
    } catch (error) {
      // Prevent upload
      return false;
    }
  };

  const handleUploadChange = ({ file, fileList }: any) => {
    if ((fileList[0].status === "done" || fileList[0].status === "error") && loading) {
      setLoading(false);
    } else if (fileList[0].status === "uploading" && !loading) {
      setLoading(true);
    }
  };

  const uploadProps = {
    accept: ".csv",
    showUploadList: false,
    beforeUpload: handleCSVUpload,
    action: adsDataDocumentationFileEndpoint(caseId),
    onChange: handleUploadChange,
  };

  return (
    <>
      <DragUpload className={`${isDragging ? "is-dragging" : ""} file-drop-zone`} {...uploadProps}>
        <Typography.Title level={3} className="mb-0">
          {_t("msg.drop_csv_calculate")}
        </Typography.Title>
      </DragUpload>
      <UploadButton
        {...uploadProps}
        loading={loading}
        buttonText={`${_t("upload")} csv`}
        uploadIcon={<Icon name="cloud-upload-outline" size="medium" />}
      />
    </>
  );
};

export default AdvCalcUploadBtn;
