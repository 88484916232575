import React, { FC } from "react";
import { Card } from "antd";
import CardBox from "../../../../components/cardBox";
import _t from "../../../../lang/translate";
import TaxDocVehicleInfo from "../taxDocVehicleInfo";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";

const TaxDocVehicleView: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  return (
    <Card bordered={false} title={_t("vehicle")}>
      <TaxDocVehicleInfo />
      {taxDocCase.note && <CardBox title={_t("note")}>{taxDocCase.note}</CardBox>}
    </Card>
  );
};

export default TaxDocVehicleView;
