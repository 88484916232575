import {FC, useCallback, useRef, useState} from "react";
import {Button, Col, Form, Input, message, Row} from "antd";
import _t from "../../../../lang/translate";
import Icon from "../../../../assets/icons/icon";
import {Equipment, initEquipment} from "../../../../types/appTypes";
import InputNum from "../../../../components/form/inputNum";
import debounce from "lodash/debounce";
import {isAxiosError, isNum} from "../../../../utilities/typeGuard";
import EquipmentCalculator from "./equipmentCalculator";
import {loadEquipmentsByVin} from "../../../../services/miscService";
import LoadingContainer from "../../../../components/loadingContainer";
import EquipmentCalculatorFooter from "./equipmentCalculatorFooter";
import {EquipmentCalculatorType} from "../../../../types/taxDocTypes";
import {calcEquipmentsPrice, cleanEquipments} from "../calc/equipmentsCalculator";
import EquipmentPriceEuro from "./equipmentPriceEuro";

interface NewPriceEquipmentsFormProps {
  onApply: (values: { equipmentCalculator: EquipmentCalculatorType }) => void;
  vin?: string | null;
  firstRegDate?: string | null;
  initialValues?: EquipmentCalculatorType | null;
}

const NewPriceEquipmentsForm: FC<NewPriceEquipmentsFormProps> = ({onApply, vin, firstRegDate, initialValues}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [equipmentsForm] = Form.useForm<EquipmentCalculatorType>();

  const listEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = useCallback(
    debounce(() => {
      listEndRef.current?.scrollIntoView({behavior: "smooth"});
    }, 150),
    []
  );

  const handleSubmit = () => {
    const {getFieldsValue, setFieldsValue} = equipmentsForm;
    const values = getFieldsValue();
    const nextEquipments = cleanEquipments(values?.equipments);
    const hasEuroFactor = isNum(values?.euroFactor);
    if (!hasEuroFactor) {
      const hasEuroPrices = !!nextEquipments?.filter((e) => isNum(e.priceEuro) && e.priceEuro > 0).length;
      if (hasEuroPrices) {
        equipmentsForm.setFields([{name: "euroFactor", errors: [""]}]);
        return;
      }
    }
    const equipmentCalculator = {
      ...values,
      //equipments: calcEquipmentsPrice(values?.euroFactor, nextEquipments),
      equipments: nextEquipments,
    };
    setFieldsValue({...equipmentCalculator});
    onApply({equipmentCalculator});
  };

  const handleNewEuroFactor = () => {
    const euroFactor = equipmentsForm.getFieldValue("euroFactor");
    const currEquipments = equipmentsForm.getFieldValue("equipments");
    const equipments = calcEquipmentsPrice(euroFactor, currEquipments);
    equipments && equipmentsForm.setFieldsValue({equipments});
  };

  const handleLoadEquipments = async () => {
    if (!vin) {
      message.warning(_t("msg.vin_field_required"));
      return;
    }
    try {
      setLoading(true);
      const {data} = await loadEquipmentsByVin(vin, firstRegDate);
      const newEquipments = data?.data?.equipments;
      if (Array.isArray(newEquipments)) {
        const euroFactor = equipmentsForm.getFieldValue("euroFactor");
        const nextEquipments = calcEquipmentsPrice(euroFactor, newEquipments);
        const currEquipments: Equipment[] = cleanEquipments(equipmentsForm.getFieldValue("equipments"));
        var nextIds = nextEquipments.map((e) => e.id);
        const equipments = [...nextEquipments, ...currEquipments.filter((e) => !nextIds.includes(e.id))];
        equipments && equipmentsForm.setFieldsValue({equipments});
      }
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    const {getFieldValue, setFieldsValue} = equipmentsForm;
    const currEquipments = getFieldValue("equipments") || [];
    setFieldsValue({equipments: [...currEquipments, initEquipment]});
  };

  const shouldUpdateCount = (curr: any, prev: any) =>
    prev?.equipmentCalculator?.equipments?.length !== curr?.equipmentCalculator?.equipments?.length;

  return (
    <LoadingContainer loading={loading}>
      <Form<EquipmentCalculatorType>
        name="equipments"
        form={equipmentsForm}
        initialValues={{...initialValues, equipments: initialValues?.equipments || [initEquipment]}}
      >
        <Form.Item noStyle shouldUpdate={shouldUpdateCount}>
          {({getFieldValue}) => {
            const equipmentsCount = getFieldValue("equipments")?.length;
            return <EquipmentCalculator onLoadEquipments={handleLoadEquipments} equipmentsCount={equipmentsCount}/>;
          }}
        </Form.Item>
        <Form.List name="equipments">
          {(fields, {add, remove}) => {
            return (
              <>
                <div className="ant-modal-body modal-scroll pb-2">
                  {fields.map((field) => (
                    <Row justify="space-between" key={field.key} gutter={8} className="mb-05">
                      <Col flex="80px">
                        <Form.Item {...field} name={[field.name, "code"]} fieldKey={[field.fieldKey, "code"]} noStyle>
                          <Input placeholder={_t("code")} maxLength={20}/>
                        </Form.Item>
                      </Col>
                      <Col flex="auto">
                        <Form.Item {...field} noStyle name={[field.name, "name"]} fieldKey={[field.fieldKey, "name"]}>
                          <Input placeholder={_t("name")}/>
                        </Form.Item>
                      </Col>
                      <Col flex="300px">
                        <Row gutter={8}>
                          <Col span={8}>
                            <Form.Item
                              {...field}
                              noStyle
                              name={[field.name, "priceEuro"]}
                              fieldKey={[field.fieldKey, "priceEuro"]}
                            >
                              <EquipmentPriceEuro/>
                            </Form.Item>
                          </Col>
                          <Col span={16}>
                            <Form.Item
                              {...field}
                              noStyle
                              name={[field.name, "price"]}
                              fieldKey={[field.fieldKey, "price"]}
                            >
                              <InputNum className="w-100" placeholder={_t("price")} suffix="kr."/>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      <Col flex="46px" className="text-right">
                        <Button
                          type="text"
                          className="no-bg delete-btn pl-05 pr-05"
                          tabIndex={-1}
                          onClick={() => remove(field.name)}
                        >
                          <Icon name="trash-outline" size="large"/>
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <span ref={listEndRef}/>
                </div>
              </>
            );
          }}
        </Form.List>
        <EquipmentCalculatorFooter
          onSubmit={handleSubmit}
          onNewEuroFactor={handleNewEuroFactor}
          onAdd={
            () => {
              handleAdd();
              scrollToBottom();
            } /* To avoid event presist issues, keep add() inside function  */
          }
        />
      </Form>
    </LoadingContainer>
  );
};

export default NewPriceEquipmentsForm;
