import { FC } from "react";
import { Button, ButtonProps } from "antd";
import openModal from "../../../../components/modal/openModal";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";

const StartDigitalSigningButton: FC<ButtonProps> = (props) => {
  const { leasingCase } = LeasingStore.useContainer();
  const { contract, id, flags } = leasingCase;
  const contractFiles = contract?.documents;
  const { contractGenerated, readonly } = flags;
  const disabled = !contractGenerated || readonly;

  const handleStartDigitalSigning = async () => {
    try {
      await openModal<{ data: string }>("startDigitalSigning", { contractFiles, id });
    } catch (error) {
    }
  };

  return (
    <Button {...props} onClick={handleStartDigitalSigning} disabled={disabled}>
      {_t("start_document_signing")}
    </Button>
  );
};

export default StartDigitalSigningButton;
