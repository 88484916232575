import React, { FC, useState } from "react";
import { Button, Col, Input, message, Row } from "antd";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { LoadingOutlined } from "@ant-design/icons";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import { createChecklistGroup } from "../../services/checklistService";
import { initChecklistGroup } from "../../types/checklistTypes";
import { isAxiosError } from "../../utilities/typeGuard";

const { TextArea } = Input;

interface ChecklistAddGroupProps {
}

const ChecklistAddGroup: FC<ChecklistAddGroupProps> = () => {
  const { id, context, groups, dispatchGroups } = ChecklistAdminStore.useContainer();
  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => setValue(e.target.value);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { data: payload } = await createChecklistGroup(context, id, {
        ...initChecklistGroup,
        position: groups.length + 1,
        title: value,
      });
      setLoading(false);
      setValue("");
      dispatchGroups({ type: "add_group", payload });
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <Row className="flex-wrap" gutter={12}>
      <Col flex="auto">
        <TextArea
          onPressEnter={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          disabled={loading}
          value={value}
          onChange={handleChange}
          autoSize={{ minRows: 1 }}
          placeholder={_t("new", "group")}
        />
      </Col>
      <Col flex="46px">
        <Button type="text" disabled={loading} shape="circle" onClick={handleSubmit}>
          {loading ? <LoadingOutlined /> : <Icon name="add-outline" size="huge" />}
        </Button>
      </Col>
    </Row>
  );
};

export default ChecklistAddGroup;
