import { Alert, Col, Row, Space } from "antd";
import { FC } from "react";
import DividerLine from "../../../../components/lineSplit";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import LeasingValidationAdminInfo from "./leasingValidationAdminInfo";
import DownloadPartnerCase from "./downloadPartnerCase";
import LeasingUpdateCustomer from "./leasingUpdateCustomer";
import LeasingValidationActions from "./leasingValidationActions";
import LockCaseButton from "./lockCaseButton";
import SendCreditPortalLink from "./sendCreditPortalLink";
import SendToPartnerValidation from "./sendToPartnerValidation";
import LeasingEquipmentReport from "./leasingEquipmentReport";

const LeasingAdminValidation: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { unMergedCustomer } = leasingCase.flags;

  return (
    <>
      <LeasingValidationAdminInfo />
      {unMergedCustomer && <Alert type="warning" message={_t("msg.add_customer_to_system")} className="mb-1" />}
      <LeasingUpdateCustomer />
      <DividerLine />
      <Row justify="space-between">
        <Col>
          <LeasingEquipmentReport />
        </Col>
        <Col>
          {leasingCase.partnerCase ? (
            <Space>
              <DownloadPartnerCase />
              <SendToPartnerValidation />
            </Space>
          ) : (
            <SendCreditPortalLink />
          )}
        </Col>
      </Row>
      <DividerLine fluid className="mb-1" />
      <Row justify="space-between">
        <Col>
          <LockCaseButton />
        </Col>
        <Col>
          <LeasingValidationActions />
        </Col>
      </Row>
    </>
  );
};

export default LeasingAdminValidation;
