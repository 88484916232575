import React, { FC, useEffect } from "react";
import InputNum, { InputPriceProps } from "../../../../components/form/inputNum";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import { initDynamicFee } from "../../../../types/taxTypes";

interface TaxFeeCalcInputProps extends InputPriceProps {
  regFee?: number | null;
  newPrice: number | null,
  tradePrice: number | null;
  autoCalculate?: boolean;
}

const TaxDynamicFeeInput: FC<TaxFeeCalcInputProps> = ({ regFee, newPrice, tradePrice, value, autoCalculate, onChange, ...rest }) => {
  const { taxCase } = TaxCaseStore.useContainer();
  let { min, max, ratio, when0RegFeeRule } = taxCase.dynamicFee || initDynamicFee;

  if (typeof regFee === 'number' && regFee < 1 && typeof when0RegFeeRule === 'number') {
    min = when0RegFeeRule;
    max = when0RegFeeRule;
  }

  useEffect(() => {

    if (!autoCalculate) return;
    const calculateDynamicFee = () => {
      let fee = Math.round((regFee || 0) * ratio);
      if (fee <= min) {
        fee = min;
      } else if (fee >= max) {
        fee = max;
      }
      fee = Math.round(fee * 1.25);

      fee !== value && onChange && onChange(fee);
    };
    calculateDynamicFee();
  }, [regFee, autoCalculate, newPrice, tradePrice]);

  return <InputNum value={value} onChange={onChange} {...rest} />;
};

export default TaxDynamicFeeInput;
