import { Equipment } from "../../../../types/appTypes";
import { TaxCalcValueTypes } from "../../../../types/taxCalcTableTypes";
import calcAverage from "../../../../utilities/calcAverage";
import { roundNum } from "../../../../utilities/number";
import { isNum } from "../../../../utilities/typeGuard";

export function regulateAdvertMileage(
    year?: number | null,
    mileage?: number | null,
    vehicleYear?: number | null,
    calculationDate?: string | null,
) {
    let regulatedMileage = 0;
    if (isNum(vehicleYear) && isNum(mileage) && isNum(year) && year > 999) {
        const currentYear = calculationDate ? new Date(calculationDate).getFullYear() : new Date().getFullYear();
        const diff = currentYear - year || 1;
        const vehicleDiff = currentYear - vehicleYear || 1;
        regulatedMileage = roundNum((mileage / diff) * vehicleDiff || 0, 0);
    }
    return regulatedMileage;
}

export function regulateAdvertSalesPrice(
    year: number | null | undefined,
    price: number | null | undefined,
    vehicleYear: number | null | undefined,
) {
    let regulatedSalesPrice = 0;
    if (isNum(vehicleYear) && isNum(price) && isNum(year) && year > 999) {
        let diff = year - vehicleYear;
        if (diff > 4) diff = 4;
        if (diff < -4) diff = -4;
        diff = diff / 10 || 0;
        regulatedSalesPrice = roundNum(price - price * diff, 0);
    }
    return regulatedSalesPrice;
}


export function sumOfVehicleEquipment(
    equipments?: any[] | null,
    vehicleEquipments?: number[] | null,
    used?: boolean,
) {
    if (!vehicleEquipments || !equipments) return 0;
    return vehicleEquipments.reduce((acc, id) => {
        const equipment: Equipment = equipments.find((e) => e.id === id);
        const deduction: boolean = equipment?.deduction && !!used;
        let equipmentPrice: number = equipment?.price || 0;
        equipmentPrice = deduction ? equipmentPrice - 30000 : equipmentPrice;
        equipmentPrice = equipmentPrice < 0 ? 0 : equipmentPrice;
        return acc + equipmentPrice;
    }, 0);
}

/**
 *
 * @param vehicleYear  The age of the vehicle (NOT ADVERT) in full years.
 * @param diff The difference between the vehicle' equipment price and the average advert' equipment price
 * @param equipmentYearFactorDeduction - The depreciation factor
 * @returns The depreciated value of the price difference
 */
export function getEquipmentDepreciation(
    vehicleYear?: number | null,
    diff?: number | null,
    equipmentYearFactorDeduction?: number | null,
) {
    let regulatedEquipmentDifference = 0;
    if (isNum(diff)
        && isNum(vehicleYear)
        && isNum(equipmentYearFactorDeduction)
        && vehicleYear < 8
    ) {
        regulatedEquipmentDifference = diff * equipmentYearFactorDeduction;
    }
    return roundNum(regulatedEquipmentDifference || 0, 0);
}

export function getDifferenceBetween(
    vehicleEquipmentPrice?: number | null,
    advertEquipmentPrice?: number | null,
) {
    let equipmentDifference = 0;
    if (isNum(advertEquipmentPrice) && isNum(vehicleEquipmentPrice)) {
        equipmentDifference = vehicleEquipmentPrice - advertEquipmentPrice;
    }
    return roundNum(equipmentDifference || 0, 0);
}


export function getRegulatedTradePrice(
    otherRegulationAmount?: number | null,
    optimizedRegulationAmount?: number | null,
    tradePrice?: number | null,
    estimatedTradePrice?: number | null,
    useEstimatedTradePrice?: boolean,
) {
    if (useEstimatedTradePrice) {
        return estimatedTradePrice || 0;
    }
    const result = Math.round(
        (tradePrice || 0) +
        (otherRegulationAmount || 0) +
        (optimizedRegulationAmount || 0)
    );
    return result;
}

export function getTradePriceRegulation(priceLevel: number, regulationRate: number) {
    return Math.round(priceLevel * regulationRate);
}

export function getAverageOfEnabledVehicles(
    vehicles: any[] | null,
    type: TaxCalcValueTypes,
): number {
    const enabledVehicles = vehicles?.filter((e) => e.enabled);
    return calcAverage(enabledVehicles || [], type);
}
