import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { Button, Checkbox, message, Popconfirm, Result } from "antd";
import Icon from "../assets/icons/icon";
import { useLocation } from "react-router-dom";
import _t from "../lang/translate";
import { IdType, LocationStateType } from "../types/appTypes";
import { PaginationedData } from "../types/apiTypes";
import DataTable from "../components/table/dataTable";
import { CustomColumnsType, RenderFunc } from "../components/table/dataTableTypes";
import { useApi } from "../services/useApi";
import { pageSizeKey, searchKey } from "../services/urlQueryService";
import UserStore from "../contexts/userStore";
import { ImportCaseListType } from "../types/importTypes";
import { deleteImportCase, importCasesUrl } from "../services/importService";
import debounce from "lodash/debounce";

interface ImportFilteredCasesProps {
  columns: CustomColumnsType<ImportCaseListType>;
  filters?: ReactNode;
  statusId?: number;
}

const ImportFilterdCases: FC<ImportFilteredCasesProps> = ({ columns, filters, statusId }) => {
  const location = useLocation<LocationStateType>();
  const { hasPermission } = UserStore.useContainer();
  const [refreshId, setRefreshId] = useState<string | undefined>();
  const [doRefund, setDoRefund] = useState<boolean>(false);
  const [{ data, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<ImportCaseListType>>(
    "",
    {
      data: [],
    },
    true
  );

  const handleRefresh = () => setRefreshId(new Date().getSeconds().toString());

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (statusId) {
      query.set("status", statusId.toString());
    }
    if (typeof refreshId === "string") {
      query.set("refreshId", refreshId);
    }
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    const url = importCasesUrl(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [setUrl, location.search, refreshId, statusId, delayedSetUrl]);

  const handleDelete = async (id: IdType) => {
    const originalData = { ...data };
    try {
      setData((state) => ({
        ...state,
        data: originalData.data.filter((item) => item.id !== id),
      }));
      await deleteImportCase(id, doRefund);
      message.success(_t("deleted"));
    } catch (ex) {
      setData(originalData);
      message.error(ex.response?.data?.message || _t("msg.not_deleted"));
    }
  };

  const tableActions: RenderFunc<ImportCaseListType> = (_, record) => {
    return hasPermission("import.delete") ? (
      <Popconfirm
        placement="topLeft"
        onVisibleChange={() => setDoRefund(false)}
        onConfirm={() => handleDelete(record.id)}
        icon={<Icon fill="red" name="information-circle-outline" />}
        title={
          <div>
            {_t("msg.confirm_delete")}&nbsp;
            <strong>
              {record.id} : {record.car}
            </strong>

            <div>
              <Checkbox
                checked={doRefund}
                onChange={e => setDoRefund(e.target.checked)}
              >{_t('refund', 'credits')}</Checkbox>
            </div>
          </div>
        }
      >
        <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
      </Popconfirm>
    ) : null;
  };

  return isError ? (
    <Result status="error" title={_t("msg.unknown_error")} />
  ) : (
    <DataTable<ImportCaseListType>
      onRefresh={handleRefresh}
      filter={filters}
      loading={isLoading}
      renderActions={tableActions}
      columnStorageKey="IMPORT"
      columns={columns}
      dataSource={data.data}
      meta={data.meta}
    />
  );
};

export default ImportFilterdCases;
