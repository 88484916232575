import { Button, Checkbox, Form, Input, Space } from "antd";
import { FC } from "react";
import DateSelect from "../../../components/form/dateSelect";
import Editor from "../../../components/form/editor";
import _t from "../../../lang/translate";
import { PostType } from "../../../types/postTypes";
import consts from "../../../utilities/consts";

interface PostEditProps {
  onSave: (newPost: PostType) => void;
  onCancel: () => void;
  post: PostType;
}

const PostEdit: FC<PostEditProps> = ({ onSave, onCancel, post }) => {
  return (
    <Form {...consts.formItemProps} initialValues={post} onFinish={onSave} className="mb-0">
      <Form.Item label={_t("title")} name="title" rules={[{ required: true }, { whitespace: true }]}>
        <Input />
      </Form.Item>

      <Form.Item label={_t("publish_date")} name="date">
        <DateSelect />
      </Form.Item>
      <Form.Item name="isUrgent" valuePropName="checked" {...consts.formItemNoLabelProps}>
        <Checkbox>{_t("urgent")}</Checkbox>
      </Form.Item>
      <Form.Item name="isArchived" valuePropName="checked" {...consts.formItemNoLabelProps}>
        <Checkbox>{_t("archived")}</Checkbox>
      </Form.Item>

      <Form.Item name="content" label={_t("content")} rules={[{ required: true }, { whitespace: true }]}>
        <Editor />
      </Form.Item>
      <Form.Item name="notify" valuePropName="checked" {...consts.formItemNoLabelProps}>
        <Checkbox>{_t("send_notification")}</Checkbox>
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ submit }) => (
          <div className="text-right">
            <Space>
              <Button onClick={onCancel}>{_t("cancel")}</Button>
              <Button onClick={submit} type="primary">
                {_t("save")}
              </Button>
            </Space>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

export default PostEdit;
