import { Col, Form, Row } from "antd";
import React, { FC, useEffect } from "react";
import AutoCalcSwitch from "../../../../components/form/autoCalcSwitch";
import _t from "../../../../lang/translate";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import { initDynamicFee } from "../../../../types/taxTypes";
import InputNum, { InputPriceProps } from "../../../../components/form/inputNum";

interface DynamicInputProps extends InputPriceProps {
  source: number | null;
  ratio: number;
  autoCalculate?: boolean;
}
const DynamicInput: FC<DynamicInputProps> = ({ source, ratio, value, autoCalculate, onChange, ...rest }) => {
  useEffect(() => {
    if (!autoCalculate) return;
    const calculateDynamicValue = () => {
      let resultVal = Math.round((source || 0) * ratio);
      // If it is NOT a zero reg fee car, and the estimation is less than 500, then set it to 500
      resultVal = !!source && resultVal < 500 ? 500 : resultVal
      resultVal = Math.round(resultVal * 1.25); // Taxes


      resultVal !== value && onChange && onChange(resultVal);
    };
    calculateDynamicValue();
  }, [source, autoCalculate, ratio, value, onChange]);

  return <InputNum value={value} onChange={onChange} {...rest} />;
};

const TaxEstimatedCollateralAmount: FC = () => {
  const shouldUpdate = (curr: any, next: any) =>
    curr.registrationFee?.registrationFee !== next.registrationFee?.registrationFee ||
    curr.estimatedCollateral?.autoCalculate !== next.estimatedCollateral?.autoCalculate;
  return (
    <Row className="flex-nowrap">
      <Col flex="auto">
        <Form.Item shouldUpdate={shouldUpdate} noStyle>
          {({ getFieldValue }) => {
            const autoCalculate = getFieldValue(["estimatedCollateral", "autoCalculate"]);
            return (
              <Form.Item name={["estimatedCollateral", "estimatedCollateral"]} label={_t("estimated", "collateral")}>
                <DynamicInput
                  ratio={0.017}
                  suffix={<small>{_t("include_short", "vat")}</small>}
                  className="bg-white no-border"
                  source={getFieldValue(["registrationFee", "registrationFee"])}
                  autoCalculate={autoCalculate}
                  disabled={autoCalculate}
                  min={0}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col flex="49px" className="text-right">
        <Form.Item noStyle name={["estimatedCollateral", "autoCalculate"]}>
          <AutoCalcSwitch />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default TaxEstimatedCollateralAmount;
