import { Button, Col, Row } from "antd";
import React, { FC } from "react";
import Icon from "../../../../assets/icons/icon";
import _t from "../../../../lang/translate";

interface EquipmentCalculatorProps {
  onLoadEquipments: () => void;
  equipmentsCount: number;
}

const EquipmentCalculator: FC<EquipmentCalculatorProps> = ({ onLoadEquipments, equipmentsCount }) => {
  return (
    <div className="ant-modal-header">
      <Row justify="space-between" align="middle">
        <Col className="ant-modal-title">
          <span>
            {equipmentsCount} {_t("equipments")}
          </span>
        </Col>
        <Col className="pr-3">
          <Button onClick={onLoadEquipments} icon={<Icon name="download-outline" size="large" />}>
            {_t("download_equipments_from_source")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default EquipmentCalculator;
