import http from "./httpService";
import { LinkType } from "../types/appTypes";
import getApiEndpoint from "./apiEndpoint";
import { PaymentCardType } from "../types/paymentCardType";

export const paymentCardUrl = `${getApiEndpoint()}/paymentCard`;

export function getPaymentCard() {
  return http.get<{ data: PaymentCardType[] }>(paymentCardUrl);
}

export function addPaymentCard(returnPath: string) {
  return http.post<{ data: LinkType }>(paymentCardUrl, { returnPath });
}

export function deletePaymentCard(cardId: string) {
  return http.delete<{ data: boolean }>(`${paymentCardUrl}/${cardId}`);
}
