import React, { FC } from "react";
import { useParams } from "react-router";
import AnimateIn from "../../../../components/animateIn";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import PageBody from "../../../../layout/pageBody";
import PageTitle from "../../../../layout/pageTitle";
import { importCaseUrl } from "../../../../services/importService";
import { useApi } from "../../../../services/useApi";
import { ParamTypes, ScreenProps } from "../../../../types/appTypes";
import { ImportCaseType, initImportCase } from "../../../../types/importTypes";
import ImportCaseHeadExtra from "../importCaseHeadExtra";
import ImportCaseView from "./importCaseView";

const ImportCaseUser: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading }] = useApi<ImportCaseType>(importCaseUrl(id), initImportCase, true);
  const caseRecieved = data.id > 0;

  return (
    <ImportCaseStore.Provider initialState={data}>
      <PageTitle title={`${title}/${id}`} fluid backBtn extra={caseRecieved && <ImportCaseHeadExtra />} />
      <PageBody>
        <AnimateIn start={!isLoading && caseRecieved} loadingBeforeStart>
          <ImportCaseView />
        </AnimateIn>
      </PageBody>
    </ImportCaseStore.Provider>
  );
};

export default ImportCaseUser;
