import { Button, message, Modal as AntModal, Typography } from "antd";
import React, { FC, useState } from "react";
import _t from "../../lang/translate";
import { isAxiosError } from "../../utilities/typeGuard";
import { cancelPaymentMethod } from "../../services/subcriptionService";
import UserStore from "../../contexts/userStore";

const { Title } = Typography;

type RemovePaymentMethodProps = {
  text?: string
}

const RemovePaymentMethod: FC<RemovePaymentMethodProps> = ({ text }) => {
  const { fetchUserData } = UserStore.useContainer();
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await cancelPaymentMethod();
      message.success(_t("payment_method", "deleted"));
      setShowModal(false);
      fetchUserData();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Button type="link" onClick={() => setShowModal(true)} className="p-0 muted">
        {text || _t('delete', 'payment_method')}
      </Button>

      <AntModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        confirmLoading={loading}
        onOk={handleSubmit}
        okText={_t('confirm')}
        className="no-footer-border"
      >
        <Title level={3} className="text-center mt-2 pb-1">
          <span className="text-bold">{_t('delete', 'payment_method')}</span>
        </Title>
        <p className="muted mt-2">
          {_t('delete_payment_method')}
        </p>
      </AntModal>
    </>
  )
}

export default RemovePaymentMethod;
