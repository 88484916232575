import { Modal as AntModal, Skeleton, Space, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import _t from "../../lang/translate";
import SubForm from "./form";
import { SubscriptionPackageType, SubscriptionSelectFormType } from "../../types/subscriptionTypes";
import { useForm } from "antd/es/form/Form";
import UserStore from "../../contexts/userStore";
import LineSplit from "../lineSplit";
import moment, { Moment } from "moment";
import appConfig from "../../appConfig";
import DetailsList from "../detailsList";
import format from "../../utilities/formatNumbers";
import { getSubscription } from "../../services/authService";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { getApiBaseUrl } from "../../services/apiEndpoint";

const { Title, Link } = Typography;

type ModalProps = {
  wantedPackage: null | SubscriptionPackageType,
  setWantedPackage: CallableFunction
}

const Modal: FC<ModalProps> = ({ wantedPackage, setWantedPackage }) => {
  const { user } = UserStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionTermsAccepted, setSubscriptionTermsAccepted] = useState<boolean>(false);
  const [taxBusinessTermsAccepted, setTaxBusinessTermsAccepted] = useState<boolean>(false);
  const [subscriptionForm] = useForm<SubscriptionSelectFormType>();
  const [subChangesAt, setSubChangesAt] = useState<null | Moment>(null)
  const isCurrentlyOnTrial = getSubscription().id < 0;

  const closeModal = () => {
    setWantedPackage(null);
    setSubChangesAt(null);
  }

  useEffect(() => {
    // Reset accepted terms when the package changes
    setSubscriptionTermsAccepted(false)
    setTaxBusinessTermsAccepted(false)
  }, [wantedPackage])

  return (
    <AntModal
      width={600}
      destroyOnClose={true}
      visible={!!wantedPackage}
      onCancel={closeModal}
      confirmLoading={loading}
      onOk={subscriptionForm.submit}
      okButtonProps={{ disabled: !subscriptionTermsAccepted || !taxBusinessTermsAccepted }}
      okText={_t('confirm')}
      className="no-footer-border"
    >
      <Skeleton loading={!subChangesAt}>
        <Title level={3} className="text-center mt-2 pb-1">
          <span className="text-bold">{_t('confirm', 'yourNewSubscription')}</span>
        </Title>
        <p className="muted mt-2">
          {_t((isCurrentlyOnTrial ? 'new2' : 'change'), 'subscription')}
        </p>

        <LineSplit />

        <Title>
          {wantedPackage?.title}
        </Title>
        <DetailsList>
          <DetailsList.Item label={(
            <>
              {_t('subscription_changes_at')}&nbsp;
              <strong>
                {subChangesAt && subChangesAt.format(appConfig("dateFormat")) === moment().format(appConfig("dateFormat")) ? (
                  _t('today')
                ) : `${subChangesAt ? _t('date_at') : ''} ${subChangesAt?.format(appConfig("dateFormat"))}`}
              </strong>
            </>
          )} value={wantedPackage?.priceNoVat ? format.pricePrefixed(wantedPackage.priceNoVat) : '-'} />
        </DetailsList>

        <LineSplit />
      </Skeleton>

      {wantedPackage && (
        <SubForm
          formInstance={subscriptionForm}
          newSub={wantedPackage}
          requiresPaymentMethodInput={!user?.paymentMethod.lastFour}
          onSubmit={closeModal}
          loading={loading}
          setLoading={setLoading}
          setSubChangesAt={setSubChangesAt}
        />
      )}

      <LineSplit />

      <Skeleton loading={!subChangesAt} className="mt-1 muted text-large">
        <Checkbox
          onChange={(e: CheckboxChangeEvent) => setSubscriptionTermsAccepted(e.target.checked)}
          checked={subscriptionTermsAccepted}
        >
          {_t('i_read_and_accept')}
          &nbsp;
          <Link href={getApiBaseUrl(appConfig("static")?.subscriptionTerms)}
            target="_blank"
          >
            {_t("subscription_terms")}
          </Link>
        </Checkbox>
        <Checkbox className="mt-1 ml-0"
          onChange={(e: CheckboxChangeEvent) => setTaxBusinessTermsAccepted(e.target.checked)}
          checked={taxBusinessTermsAccepted}
        >
          {_t("i_read_and_accept")}
          &nbsp;
          <Link href={getApiBaseUrl(appConfig("static")?.businessTerms)}
            target="_blank"
          >
            {_t("business_terms")}
          </Link>
        </Checkbox>
      </Skeleton>
    </AntModal>
  )
}

export default Modal;
