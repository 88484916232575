import React, { useState } from "react";
import { Button, Form, Space } from "antd";
import _t from "../../lang/translate";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import EnumSelect from "../form/enumSelect";
import { toTaxCase } from "../../services/resellerTaxService";
import { renderErrorMessage } from "../messages/errorMessage";

export default function ResellerTaxToTaxCase<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<T>();
  const [loading, setLoading] = useState<boolean>(false);


  const handleSubmit = async (values: any) => {
    const { id } = payload;
    try {
      setLoading(true);
      const { data } = await toTaxCase(id, { evaluationTypeId: values.evaluationTypeId });
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <h2 className="modal-title">{_t("create", "taxCase")}</h2>
      <Form.Item name="evaluationTypeId" label={_t("evaluating_type")}>
        <EnumSelect defaultActiveFirstOption nameKey={["taxCase", "evaluationType"]} />
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" loading={loading} type="primary">
            {_t("create")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ResellerTaxToTaxCase);
