import React, { FC } from "react";
import { Col, Form, Row } from "antd";
import InputNum, { InputPriceProps } from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";

const TaxFee: FC<InputPriceProps> = ({ value, onChange, ...rest }) => {
  return (
    <Row className="flex-nowrap">
      <Col flex="auto">
        <Form.Item name={["fee", "fee"]} label={_t("fee")}>
          <InputNum
            className="bg-white no-border"
            suffix={<small>{_t("include_short", "vat")}</small>}
            min={0}
            value={value}
            onChange={onChange}
            {...rest}
          />
        </Form.Item>
      </Col>
      <Col flex="49px"></Col>
    </Row>
  );
};

export default TaxFee;
