import React, { useState } from "react";
import { Button, Checkbox, Form, Modal, Space, Typography, message } from "antd";
import _t from "../../lang/translate";
import { AcceptOfferFormType } from "../../types/taxTypes";
import DividerLine from '../../components/lineSplit'
import { renderErrorMessage } from "../messages/errorMessage";
import { termsAccepted } from "../../services/userService";
import { UserSystemType } from "../../services/authService";
import appConfig from "../../appConfig";
import moment from "moment";

const { Link, Paragraph } = Typography;
const { confirm } = Modal;

export default async function acceptTerms(user: UserSystemType | null): Promise<void> {
    const cancelBtnId = `${new Date().getTime().toString(36)}_modal_cancel_btn`;
    const closeModal = () => document.getElementById(cancelBtnId)?.click();

    return new Promise((resolve, reject) => {
        const onSuccess = () => {
            resolve();
            closeModal();
        }

        if (!user) {
            closeModal();
            return reject(_t("msg.no_user_data"))
        };

        const termsAcceptedAt = user.terms?.tax?.acceptedAt;
        // If terms are already accepted, proceed with callback immediately.
        if (termsAcceptedAt && !isNaN(new Date(termsAcceptedAt).getTime())) {
            return onSuccess()
        }

        confirm({
            title: null,
            content: (
                <AcceptTerms
                    onCancel={closeModal}
                    onSuccess={onSuccess}
                    user={user}
                />
            ),
            width: 500,
            icon: null,
            className: "open-modal-wp",
            cancelButtonProps: {
                id: cancelBtnId,
                hidden: true,
            },
            okButtonProps: {
                hidden: true,
            },
            onCancel() {
                reject(_t("must_agree_terms"));
            },
        });
    });
}

type AcceptTermsProps = {
    onSuccess: () => void;
    onCancel: () => void;
    user: UserSystemType;
}

function AcceptTerms({ onSuccess, onCancel, user }: AcceptTermsProps) {
    const [tmpForm] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const termsUri = user.terms?.tax?.uri ?? "#";

    const firstName = user.name?.split(' ')[0];
    const termsUpdatedAtDate = moment(appConfig("terms.tax.updatedAt")).format("DD-MM/YYYY");
    const termsUpdatedAtText = moment(appConfig("terms.tax.updatedAt")).format("Do MMMM YYYY");

    const handleAccept = async (values: AcceptOfferFormType) => {
        try {
            setLoading(true);
            await termsAccepted(user.id, ["tax"]);
            message.success(_t("preassessment_terms_accepted"))
            onSuccess();
        } catch (error) {
            renderErrorMessage(error)
            setLoading(false);
        }
    };

    return (
        <Form
            form={tmpForm}
            initialValues={{ billingDestinationId: -1 }}
            requiredMark={false}
            layout="vertical"
            onFinish={handleAccept}
        >
            <h2 className="modal-title">Nye forretningsbetingelser per {termsUpdatedAtDate}</h2>
            <Paragraph style={{ color: "#5A6870" }}>
                Hej {firstName}
            </Paragraph>
            <Paragraph style={{ color: "#5A6870" }} className="mt-2 mb-05">
                Tusind tak for din anvendelse af vores TAX-produkt.
            </Paragraph>
            <Paragraph style={{ color: "#5A6870" }} className="mb-05">
                Vi gør opmærksom på, at vi per {termsUpdatedAtText} har lavet ændringer i vores forretningsbetingelser, som du kan gennemgå og accepterer nedenfor.
            </Paragraph>
            <Paragraph style={{ color: "#5A6870" }} className="mb-05">
                Har du spørgsmål til betingelserne er du mere end velkommen til at kontakte os.
            </Paragraph>

            <Link className="mt-1 mb-1 d-block" href={termsUri} rel="noreferrer" target="_blank" >
                {_t("view_new_business_terms_here")}
            </Link>

            <Form.Item name="status" required valuePropName="checked">
                <Checkbox disabled={loading} style={{ lineHeight: 1.4 }}>
                    {_t("i_read_and_accept_preassessment_terms")}
                </Checkbox>
            </Form.Item>

            <DividerLine fluid largeFluid />
            <Space style={{ justifyContent: "space-between" }} className="w-100">
                <Button type="ghost" onClick={onCancel} disabled={loading}>
                    {_t("cancel")}
                </Button>
                <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                        const isValidForm = () => {
                            const termsStatus = getFieldValue("status");
                            return termsStatus;
                        };
                        const isValid = isValidForm();
                        return (
                            <Button
                                htmlType="submit"
                                loading={loading}
                                type="primary"
                                disabled={!isValid}
                            >
                                {_t("accept")}
                            </Button>
                        )
                    }}
                </Form.Item>
            </Space>
        </Form>
    );
}
