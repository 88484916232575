import { Button, Checkbox, Form, message, Space } from "antd";
import { FC, useState } from "react";
import DateSelect from "../../../components/form/dateSelect";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { updateDelivery } from "../../../services/leasingService";
import { LeasingDeliveryType } from "../../../types/leasingTypes";
import { isAxiosError } from "../../../utilities/typeGuard";

interface LeasingDeliveryFormProps {
  stopEditing: () => void;
}

const LeasingDeliveryForm: FC<LeasingDeliveryFormProps> = ({ stopEditing }) => {
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = async (values: LeasingDeliveryType) => {
    try {
      setLoading(true);
      await updateDelivery(leasingCase.id, values);
      setLeasingCase({ ...leasingCase, delivery: values });
      stopEditing();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form<LeasingDeliveryType>
      className="m-0"
      labelCol={{ hidden: true }}
      initialValues={leasingCase.delivery}
      requiredMark={true}
      onFinish={handleSave}
    >
      <Form.Item name="date">
        <DateSelect placeholder={_t("delivery_date")} />
      </Form.Item>
      <Form.Item name="isReady" valuePropName="checked">
        <Checkbox>{_t("ready_for_delivery")}</Checkbox>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ submit }) => (
          <div className="text-right">
            <Space>
              <Button tabIndex={-1} onClick={stopEditing} disabled={loading}>
                {_t("cancel")}
              </Button>
              <Button type="primary" onClick={submit} loading={loading}>
                {_t("save")}
              </Button>
            </Space>
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

export default LeasingDeliveryForm;
