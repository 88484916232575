import React, { FC, useCallback, useEffect, useState } from "react";
import DataTable from "../../components/table/dataTable";
import { CustomColumnsType, RenderFunc } from "../../components/table/dataTableTypes";
import _t from "../../lang/translate";
import Icon from "../../assets/icons/icon";
import { useLocation } from "react-router-dom";
import { VehicleTypeItem, VehicleTypeUpsertType } from "../../types/vehicleCatalogTypes";
import { Button, Form, Input, message, Modal, Popconfirm, Result, Select } from "antd";
import { IdType, LocationStateType } from "../../types/appTypes";
import { useApi } from "../../services/useApi";
import { PaginationedData } from "../../types/apiTypes";
import debounce from "lodash/debounce";
import { pageSizeKey, searchKey } from "../../services/urlQueryService";
import {
  deleteVehicleType,
  saveVehicleType,
  updateVehicleType,
  vehicleTypesUrl
} from "../../services/vehicleCatalogService";
import { isAxiosError } from "../../utilities/typeGuard";

interface TypeProps {
  showModal: boolean;
  hideModal: () => void;
  withActions: boolean;
}

const Type: FC<TypeProps> = ({ hideModal, showModal, withActions }) => {
  const location = useLocation<LocationStateType>();
  const [{ data, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<VehicleTypeItem>>("", { data: [] }, true);
  const [targetId, setTargetId] = useState<IdType>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [itemForm] = Form.useForm<VehicleTypeUpsertType>();
  const doShowModal = showModal || !!targetId;

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    const url = vehicleTypesUrl(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [setUrl, location.search, delayedSetUrl]);

  const columns: CustomColumnsType<VehicleTypeItem> = [
    {
      title: _t("id"),
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 110,
      hidden: false,
    },
    {
      title: _t("name"),
      key: "title",
      sorter: true,
      dataIndex: "title",
      hidden: false,
    },
    {
      title: _t("aliases"),
      key: "aliases",
      sorter: false,
      dataIndex: "aliases",
      hidden: false,
      render: value => value?.map((val: string) => val).join(', ')
    },
  ];

  const startEdit = (row: VehicleTypeItem) => {
    itemForm.setFieldsValue(row);
    setTargetId(row.id);
  }

  const doHideModal = () => {
    hideModal();
    setTargetId(0);
    itemForm.setFieldsValue({ title: "", aliases: null });
  }

  const handleSave = async () => {
    try {
      setLoading(true);
      const formData = itemForm.getFieldsValue();

      if (targetId) {
        await updateVehicleType(targetId, formData);
      } else {
        await saveVehicleType(formData);
      }

      message.success(_t("saved"));

      doHideModal();
      handleRefresh();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  const handleRefresh = () => {
    const query = new URLSearchParams(location.search);
    query.set("refreshId", new Date().getSeconds().toString());
    const url = vehicleTypesUrl(query.toString());
    setUrl(url);
  };

  const handleDelete = async (id: IdType) => {
    const originalData = { ...data };
    try {
      setData((state) => ({
        ...state,
        data: originalData.data.filter((item) => item.id !== id),
      }));
      await deleteVehicleType(id);
      message.success(_t("deleted"));
    } catch (ex) {
      setData(originalData);
      message.error(ex.response?.data?.message || _t("msg.not_deleted"));
    }
  };

  const tableActions: RenderFunc<VehicleTypeItem> = (_, row) => {
    const { id, title } = row;

    return (
      <>
        <Button className="muted" type="text" shape="circle" icon={<Icon name="pencil-outline" />}
          onClick={() => startEdit(row)} />

        <Popconfirm
          placement="topLeft"
          onConfirm={() => handleDelete(id)}
          icon={<Icon fill="red" name="information-circle-outline" />}
          title={
            <div>
              {_t("msg.confirm_delete")}&nbsp;
              <strong>{title}</strong>
            </div>
          }
        >
          <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
        </Popconfirm>
      </>
    );
  };

  return (
    <>
      {isError ? (
        <Result status="error" title={_t("msg.unknown_error")} />
      ) : (
        <DataTable
          columnStorageKey="VEHICLE_CATALOG_TYPE"
          columns={columns}
          dataSource={data.data}
          meta={data.meta}
          renderActions={withActions ? tableActions : undefined}
          loading={isLoading}
          onRefresh={handleRefresh}
        />
      )}

      <Modal visible={doShowModal} onCancel={doHideModal} destroyOnClose={true} onOk={itemForm.submit}
        okText={!!targetId ? _t('update') : _t('add')}
        confirmLoading={loading}
      >
        <h2 className="modal-title">
          {!!targetId ? _t('update', 'type') : _t('add', 'type')}
        </h2>

        <Form
          form={itemForm}
          requiredMark={true}
          layout="horizontal"
          onFinish={handleSave}
        >
          <Form.Item name="title" label={_t('name')} rules={[{ required: true }]}>
            <Input disabled={loading} />
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const values = getFieldsValue();
              const aliases: string[] = values.aliases || [];

              return (
                <Form.Item name="aliases" label={_t('aliases')}>
                  <Select mode="tags" placeholder={_t('choose')} disabled={loading}>
                    {aliases?.map((alias, i) => (
                      <Select.Option value={alias} key={i}>{alias}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
};

export default Type;
