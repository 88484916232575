import React, { useState } from "react";
import { Button, Divider, Form, Space } from "antd";
import _t from "../../lang/translate";
import { createLeasingofImport } from "../../services/importService";
import { createLeasingofTaxCase } from "../../services/taxService";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import EnumRadio from "../form/EnumRadio";
import { renderErrorMessage } from "../messages/errorMessage";

export default function TaxToLeasingCase<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<T>();
  const [loading, setLoading] = useState<boolean>(false);
  const handleSubmit = async (values: any) => {
    const { id, from, partnerCase } = payload;
    try {
      setLoading(true);
      const data =
        from === "tax"
          ? await createLeasingofTaxCase(id, { ...values, partnerCase })
          : await createLeasingofImport(id, values);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <h2 className="modal-title">{_t("create", "leasingCase")}</h2>
      <Form.Item label={_t("customer", "type")} name="customerTypeId">
        <EnumRadio defaultActiveFirstOption nameKey={["customer", "type"]} />
      </Form.Item>
      <Form.Item name="vehicleTypeId" label={_t("vehicle_type")} rules={[{ required: true }]}>
        <EnumRadio defaultActiveFirstOption nameKey={["vehicle", "type"]} />
      </Form.Item>
      <Divider />
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" loading={loading} type="primary">
            {_t("create")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(TaxToLeasingCase);
