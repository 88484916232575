import { FC, useState } from "react";
import { Button, message } from "antd";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { openFileLink } from "../../../services/mediaService";
import { downloadChecklistUrl } from "../../../services/leasingService";
import ErrorMessage from "../../../components/messages/errorMessage";
import Icon from "../../../assets/icons/icon";

const LeasingGenerateChecklist: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const handleChecklistDownload = async () => {
    try {
      setLoading(true);
      await openFileLink(downloadChecklistUrl(leasingCase.id));
    } catch ({ response }) {
      message.error(<ErrorMessage message={response?.data?.message} />);
    } finally {
      setLoading(false);
    }
  };

  const disabled = !leasingCase.flags.validationStarted;

  return (
    <Button loading={loading} disabled={disabled} onClick={handleChecklistDownload}>
      <Icon name="download-outline" size="large" /> {_t("checklist")}
    </Button>
  );
};

export default LeasingGenerateChecklist;
