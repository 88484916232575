import { Button, Col, Divider, Drawer, Result, Row, Spin, Typography } from "antd";
import React, { FC, useEffect, useState } from "react";
import Icon from "../../../../assets/icons/icon";
import Details from "../../../../components/detailsList";
import LayoutStore from "../../../../contexts/layoutStore";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import { calculationArchiveEndpoint } from "../../../../services/taxDocService";
import { useApi } from "../../../../services/useApi";
import { ApiResponseObject } from "../../../../types/apiTypes";
import { CalculationArchiveType } from "../../../../types/taxDocTypes";
import consts from "../../../../utilities/consts";
import format from "../../../../utilities/formatNumbers";

const TaxDocArchive: FC = () => {
  const { screen } = LayoutStore.useContainer();
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  const [visible, setVisible] = useState<boolean>(false);
  const [{ data, isError, isLoading }, setUrl] = useApi<ApiResponseObject<CalculationArchiveType[]>>("", { data: [] });
  const id = taxDocCase.id;

  useEffect(() => {
    if (visible) setUrl(calculationArchiveEndpoint(id));
  }, [visible, setUrl, id]);

  const openDrawer = () => setVisible(true);
  const closeDrawer = () => setVisible(false);

  return (
    <>
      <Button tabIndex={-1} onClick={openDrawer}>
        <Icon name="file-tray-full-outline" size="medium" /> {_t("archive")}
      </Button>
      <Drawer
        footer={null}
        title={_t("archived")}
        width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
        visible={visible}
        onClose={closeDrawer}
        className="calculation-table"
      >
        {isError && <Result status="error" />}
        {isLoading && <Spin size="large" />}
        {data.data?.map((item) => (
          <div key={item.id}>
            <Details borderLess>
              <Details.Item label={_t("new_price")} value={item.newPrice?.newPrice} />
              <Details.Item label={_t("trade_price")} value={item.tradePrice?.tradePrice} />
              <Details.Item label={_t("registration_fee")} value={item.registrationFee?.registrationFee} />
            </Details>
            <Row justify="space-between">
              <Col>
                <small>{item.createdAt ? format.dateAndTime(item.createdAt) : "-"}</small>
              </Col>
              <Col>
                <Typography.Text type="secondary" className="text-smallest" ellipsis>
                  {item.person?.fullName || "-"}
                </Typography.Text>
              </Col>
            </Row>
            <Divider />
          </div>
        ))}
      </Drawer>
    </>
  );
};

export default TaxDocArchive;
