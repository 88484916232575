import React, { FC } from "react";
import { Tooltip } from "antd";
import _t from "../lang/translate";

const UnmatchedValueWarning: FC<{ unMatchedValue: string }> = ({ unMatchedValue }) => {
  return (
    <Tooltip title={_t('msg.system_no_value_match')}>
      <small className="text-danger">{unMatchedValue}</small>
    </Tooltip>
  );
}

export default UnmatchedValueWarning;
