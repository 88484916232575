export const compare = (data1: { [key: string]: any }, data2: { [key: string]: any }, keys: string[]) => {
  for (const key of keys) {
    const a = JSON.stringify(data1[key]);
    const b = JSON.stringify(data2[key]);

    if (a !== b) {
      return false;
    }
  }

  return true;
};
