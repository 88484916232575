import { Col, Row } from "antd";
import { FC } from "react";
import UserInfoCard from "./userInfoCard";
import UserPageActions from "./userPageActions";

const UserContent: FC = () => {
  return (
    <Row align="top" gutter={24}>
      <Col xxl={{ span: 10, offset: 3 }} xl={{ span: 14 }} xs={{ span: 24 }}>
        <UserInfoCard />
      </Col>
      <Col xxl={{ span: 8 }} xl={{ span: 10 }} xs={{ span: 24 }}>
        <UserPageActions />
      </Col>
    </Row>
  );
};

export default UserContent;
