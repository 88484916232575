import React, { FC } from "react";
import { Typography, Collapse, Empty } from "antd";
import Details from "../../components/detailsList";
import _t from "../../lang/translate";
import format from "../../utilities/formatNumbers";
import { BaseVehicle, Vehicle } from "../../types/appTypes";
import CopyableContent from "../../components/copyableContent";
import { isString, isValidNum } from "../../utilities/typeGuard";

const { Link } = Typography;
const { Panel } = Collapse;

type Props = {
    vehicle: Vehicle | BaseVehicle | null;
    showAdditionalData?: boolean;
};
function isVehicle(vehicle: Vehicle | BaseVehicle): vehicle is Vehicle {
    return 'remoteData' in vehicle && !!vehicle.remoteData;
}

const FoldableVehicleInfo: FC<Props> = ({ vehicle, showAdditionalData }) => {
    if (!vehicle) {
        return <Empty />;
    }
    const {
        brand,
        model,
        type,
        modelDesignation,
        modelPeriod,
        equipmentVariant,
        firstRegDate,
        vin,
        mileage,
        quality,
        useCase,
        fuel,
        engineSize,
        horsePower,
        gearbox,
        body,
        emissionAmount,
        batteryCapacity,
        electricConsumption,
        electricRange,
        emissionUnit,
        isHighWeight,
        isNew,
        link,
    } = vehicle;

    const formatValue = (v?: unknown | null) => {
        if (isString(v)) {
            return v.trim()
        } else if (isValidNum(v) || typeof v === 'boolean') {
            return v + "";
        } else {
            return "-";
        }
    };

    return (
        <Details borderLess>
            {link && (
                <Details.Item
                    value={
                        <Link ellipsis className="max-width-240" href={link} target="_blank">
                            {link}
                        </Link>
                    }
                    className="mb-1"
                />
            )}

            <Details.Item
                label={_t("new", "vehicle") + "?"}
                value={format.yesOrNo(isNew)}
                className="mb-1"
            />

            <Details.Item label={_t("brand")} value={formatValue(brand)} />
            <Details.Item label={_t("model")} value={formatValue(model)} />
            <Details.Item
                label={_t("equipment_variant")}
                value={formatValue(equipmentVariant)}
            />

            <Details.Item
                label={_t("firstRegDate")}
                value={
                    <CopyableContent contentToCopy={format.date(firstRegDate)}>
                        {format.date(firstRegDate)}
                    </CopyableContent>
                }
            />
            <Details.Item
                label={_t("vin")}
                value={
                    <CopyableContent contentToCopy={formatValue(vin)}>
                        {formatValue(vin)}
                    </CopyableContent>
                }
            />
            <Details.Item
                label={_t("mileage")}
                value={format.milage(mileage)}
                className="mb-1"
            />
            <Details.Item
                label={_t("co2_emission")}
                value={format.emission(emissionAmount)}
            />
            <Details.Item
                label={_t("co2_measurement")}
                value={formatValue(emissionUnit)}
            />
            <Details.Item
                label={_t("battery_capacity")}
                value={format.batteryCapacity(batteryCapacity)}
                className="mb-1"
            />

            <UseUnfold useUnfold={true}>
                <Details borderLess className="mb-0-important">
                    <Details.Item
                        label={_t("model_designation")}
                        value={formatValue(modelDesignation)}
                    />
                    <Details.Item
                        label={_t("model_period")}
                        value={formatValue(modelPeriod)}
                    />
                    <Details.Item label={_t("type")} value={formatValue(type)} />
                    <Details.Item label={_t("engine_size")} value={formatValue(engineSize)} />
                    <Details.Item
                        label={_t("engine_power")}
                        value={formatValue(horsePower)}
                    />
                    <Details.Item label={_t("gearbox")} value={formatValue(gearbox)} />
                    <Details.Item label={_t("trim")} value={formatValue(body)} />
                    <Details.Item label={_t("car_condition")} value={formatValue(quality)} />
                    <Details.Item
                        label={_t("special_use")}
                        value={formatValue(useCase)}
                        className="mb-1"
                    />
                    <Details.Item label={_t("engine_size")} value={formatValue(engineSize)} />
                    <Details.Item
                        label={_t("engine_power")}
                        value={formatValue(horsePower)}
                    />
                    <Details.Item label={_t("gearbox")} value={formatValue(gearbox)} />
                    <Details.Item label={_t("trim")} value={formatValue(body)} />
                    <Details.Item label={_t("car_condition")} value={formatValue(quality)} />
                    <Details.Item
                        label={_t("special_use")}
                        value={formatValue(useCase)}
                        className="mb-1"
                    />

                    <Details.Item label={_t("fuel_type")} value={formatValue(fuel)} />
                    <Details.Item
                        label={_t("over_3000_kg")}
                        value={format.yesOrNo(isHighWeight)}
                    />
                    <Details.Item
                        label={_t("battery_usage")}
                        value={format.batteryUsage(electricConsumption)}
                    />
                    <Details.Item
                        label={_t("battery_range")}
                        value={format.milage(electricRange)}
                    />
                    {isVehicle(vehicle) && showAdditionalData && (<>
                        <Details.Item
                            label={_t("dat_net_price")}
                            value={format.price(vehicle.remoteData?.dat?.raw?.originalNetPrice, 0, "EUR")}
                        />
                        <Details.Item
                            label={_t("number_of_doors")}
                            value={format.number(vehicle.remoteData?.dat?.raw?.doorCount)}
                        />
                        <Details.Item
                            label={_t("number_of_seats")}
                            value={format.number(vehicle.remoteData?.dat?.raw?.seatsCount)}
                        />
                        <Details.Item
                            label={_t("number_of_gears")}
                            value={format.number(vehicle.remoteData?.dat?.raw?.gearCount)}
                        />
                        <Details.Item
                            label={_t("drive_wheel")}
                            value={vehicle.remoteData?.dat?.raw?.driveWheel}
                        />
                        <Details.Item
                            label={_t("cylinders")}
                            value={format.number(vehicle.remoteData?.dat?.raw?.cylinders)}
                        />
                    </>)}
                </Details>
            </UseUnfold>
        </Details>
    );
};

export default FoldableVehicleInfo;

type UseUnfoldProps = {
    useUnfold: boolean;
    children: React.ReactNode
}
const UseUnfold = ({ useUnfold, children }: UseUnfoldProps) => {
    return useUnfold ? (
        <Collapse className="collapse-vehicle-unfold" bordered>
            <Panel
                header={<span>{_t("see", "more")}</span>}
                key="2"
            >
                {children}
            </Panel>
        </Collapse>
    ) : (
        <>{children}</>
    )
}
