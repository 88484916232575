import React, { FC, ReactNode } from "react";
import _t from "../../lang/translate";

interface SavedMessageProps {
  escHint?: boolean;
  message?: ReactNode;
}

const SavedMessage: FC<SavedMessageProps> = ({
  escHint = true,
  message = _t("saved"),
}) => {
  return (
    <span>
      {message}
      {escHint && (
        <span className="message-subtext">{_t("press_esc_to_go_back")}</span>
      )}
    </span>
  );
};

export default SavedMessage;
