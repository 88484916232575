import { Alert } from "antd";
import React, { FC } from "react";
import _t from "../lang/translate";
import { LinkType, RelatedCasesType } from "../types/appTypes";
import { isValidNum } from "../utilities/typeGuard";
import ServerLink from "./serverLink";

interface ConflictAlertProps {
  conflictedCases: RelatedCasesType;
}

const ConflictAlert: FC<ConflictAlertProps> = ({ conflictedCases }) => {
  const hasConflictedCasesVin = !!conflictedCases.vin;
  const hasConflictedCasesInfo = !!conflictedCases.info;
  const hasConflictedCasesLink = !!conflictedCases.link;

  const descriptionSameVin = hasConflictedCasesVin ? (
    <p>
      {_t("conflicted_cases_vin")}&nbsp;
      {conflictedCases.vin?.sort(sortLinkType).map((link, index) => (
        <span key={link.id}>
          <ServerLink link={link} target="_blank" />
          {conflictedCases.vin?.length !== index + 1 ? ", " : ""}
        </span>
      ))
      }
    </p>
  ) : null;

  const descriptionSameInfo = hasConflictedCasesInfo ? (
    <p>
      {_t("conflicted_cases_info")}&nbsp;
      {conflictedCases.info?.sort(sortLinkType).map((link, index) => (
        <span key={link.id}>
          <ServerLink link={link} target="_blank" />
          {conflictedCases.info?.length !== index + 1 ? ", " : ""}
        </span>
      ))}
    </p>
  ) : null;

  const descriptionSameLink = hasConflictedCasesLink ? (
    <p>
      {_t("conflicted_cases_link")}&nbsp;
      {conflictedCases.link?.sort(sortLinkType).map((link, index) => (
        <span key={link.id}>
          <ServerLink link={link} target="_blank" />
          {conflictedCases.link?.length !== index + 1 ? ", " : ""}
        </span>
      ))}
    </p>
  ) : null;

  const description = (
    <>
      {descriptionSameVin}
      {descriptionSameInfo}
      {descriptionSameLink}
    </>
  );

  if (
    hasConflictedCasesVin ||
    hasConflictedCasesInfo ||
    hasConflictedCasesLink
  ) {
    return (
      <Alert
        message={_t("be_aware")}
        showIcon
        description={description}
        type="error"
        className="mb-2"
        closable
      />
    );
  }

  return null;
};

export default ConflictAlert;


function sortLinkType(a: LinkType, b: LinkType) {
  if (typeof a.params === 'undefined' ||
    typeof b.params === 'undefined' ||
    !a.params?.[0] ||
    !b.params?.[0]
  ) {
    return 1;
  }

  if (typeof a.params[0] === 'string' && typeof b.params[0] === 'string') {
    return a.params[0] < b.params[0]
      ? -1
      : 1;
  }

  if (isValidNum(a.params?.[0]) && isValidNum(b.params?.[0])) {
    return a.params[0] - b.params[0]
  }

  return 0;

}
