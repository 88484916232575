import React, { FC, useCallback, useEffect, useState } from "react";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  initPaymentMethodIntent,
  subscriptionPackagePrice,
  updatePaymentMethod,
  updateSubscriptionPackage
} from "../../services/subcriptionService";
import { isAxiosError } from "../../utilities/typeGuard";
import _t from "../../lang/translate";
import { loadStripe } from "@stripe/stripe-js";
import { useForm } from "antd/es/form/Form";
import {
  SubscriptionPackageType,
  SubscriptionPriceChangeType,
  SubscriptionSelectFormType
} from "../../types/subscriptionTypes";
import { Button, Col, Form as AntForm, FormInstance, Input, List, message, Row, Skeleton } from "antd";
import UserStore from "../../contexts/userStore";
import format from "../../utilities/formatNumbers";
import appConfig from "../../appConfig";
import Icon from "../../assets/icons/icon";
import LineSplit from "../lineSplit";
import moment from "moment";
import DetailsList from "../detailsList";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey || '');

type FormType = {
  newSub?: SubscriptionPackageType | null,
  requiresPaymentMethodInput: boolean
  onSubmit?: () => void
  formInstance: null | FormInstance<SubscriptionSelectFormType>
  loading?: boolean
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
  setSubChangesAt?: React.Dispatch<React.SetStateAction<moment.Moment | null>>
}

const FormContent: FC<FormType> = ({
  newSub,
  requiresPaymentMethodInput,
  onSubmit,
  formInstance,
  loading,
  setLoading,
  setSubChangesAt,
}) => {
  const { user, fetchUserData } = UserStore.useContainer();
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [internalFormInstance] = useForm<SubscriptionSelectFormType>();
  const [spawnPaymentMethodFields, setSpawnPaymentMethodFields] = useState<boolean>(requiresPaymentMethodInput);
  const [wantedCoupon, setWantedCoupon] = useState<string | null>(null)
  const [internalLoading, setInternalLoading] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();
  const subscriptionForm = formInstance || internalFormInstance;
  const [newSubPrice, setNewSubPrice] = useState<null | SubscriptionPriceChangeType>(null);
  const toggleShowDetails = () => setShowDetails(!showDetails);
  const setSubFormItem = subscriptionForm.setFieldsValue;

  const updateLoading = useCallback((newState: boolean) => setLoading ? setLoading(newState) : setInternalLoading(newState), [setLoading])

  useEffect(() => {
    subscriptionForm.setFieldsValue({ subscriptionPackageId: newSub?.id });
  }, [newSub]);

  const handlePaymentCodeResolve = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements: elements,
      redirect: "if_required",
      confirmParams: {
        return_url: window.location.href
      },
    });

    if (result.error) {
      throw new Error(result.error.message);
    } else {
      return result;
    }
  };

  const handleRequest = async (values: SubscriptionSelectFormType) => {
    if (requiresPaymentMethodInput && values.subscriptionPackageId) {
      return {
        res: await updatePaymentMethod(String(values.paymentMethodId), String(values.subscriptionPackageId)),
        successMsg: _t("subscription_change_process_started"),
      };
    } else if (requiresPaymentMethodInput) {
      return {
        res: await updatePaymentMethod(String(values.paymentMethodId)),
        successMsg: _t('payment_method', 'updated')
      };
    } else if (values.subscriptionPackageId) {
      return {
        res: await updateSubscriptionPackage(String(values.subscriptionPackageId), values.coupon),
        successMsg: _t("subscription_change_process_started"),
      };
    } else {
      throw new Error(_t("msg.unknown_error"));
    }
  }

  const fetchPrice = useCallback(async () => {
    if (newSub) {
      updateLoading(true);
      try {
        const { data } = await subscriptionPackagePrice(newSub.id, wantedCoupon);
        setNewSubPrice(data);
        if (wantedCoupon && !data.validCoupon) {
          setSubFormItem({ 'coupon': null });
          message.error(_t('msg.invalid_coupon'));
        } else {
          setSubFormItem({ 'coupon': wantedCoupon })
        }
      } catch ({ response }) {
        const { message: msg, errors } = response?.data || {};
        message.error(msg);
      } finally {
        updateLoading(false);
      }
    }
  }, [newSub, updateLoading, wantedCoupon, setSubFormItem])

  useEffect(() => {
    fetchPrice()
  }, [fetchPrice, wantedCoupon]);

  const handleSubmit = async () => {
    try {
      updateLoading(true);
      if (spawnPaymentMethodFields) {
        const { setupIntent } = await handlePaymentCodeResolve() || {};
        subscriptionForm.setFieldsValue({ paymentMethodId: String(setupIntent?.payment_method) });
      }
      const values = subscriptionForm.getFieldsValue(true) as SubscriptionSelectFormType;
      const req = await handleRequest(values);
      message.success(req.successMsg, 8);
      fetchUserData();
      onSubmit && onSubmit()
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : error.message;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      updateLoading(false);
    }
  };

  return (
    <Skeleton loading={internalLoading}>
      {newSub && (<PaymentDetails
        newSubPrice={newSubPrice}
        setSubChangesAt={setSubChangesAt}
      />)}

      <AntForm
        form={subscriptionForm}
        onFinish={handleSubmit}
        initialValues={{
          subscriptionPackageId: newSub?.id,
          paymentMethodId: undefined,
          coupon: null,
        }}
      >
        {spawnPaymentMethodFields ? (
          <div className="mt-2 mb-05">
            <PaymentElement />
            <div className="text-right text-italic muted text-smaller">
              {!!user?.paymentMethod.type && !requiresPaymentMethodInput && (
                <div className="cursor-pointer" onClick={() => setSpawnPaymentMethodFields(false)}>
                  {_t('cancel')}
                </div>
              )}
            </div>
          </div>
        ) : (
          <Row className="w-100 text-italic muted text-smaller">
            <Col flex="auto">
              <div className="cursor-pointer" onClick={(toggleShowDetails)}>
                {showDetails ? _t('hide', 'details') : _t('show', 'details')}
              </div>
            </Col>

            <Col flex="100px" className="text-right">
              {showDetails && (
                <>
                  <span className="text-capitalize">
                    {`${user?.paymentMethod.type} **** ${user?.paymentMethod.lastFour}`}
                  </span>
                  <div className="cursor-pointer" onClick={() => setSpawnPaymentMethodFields(true)}>
                    {_t('change', 'payment')}
                  </div>
                </>
              )}
            </Col>
          </Row>
        )}
      </AntForm>

      {newSub && (
        <>
          <LineSplit />

          <CouponForm
            setWantedCoupon={setWantedCoupon}
          />
        </>
      )}
    </Skeleton>
  )
}

interface CouponFormType {
  setWantedCoupon: React.Dispatch<React.SetStateAction<string | null>>
}

const CouponForm: FC<CouponFormType> = ({ setWantedCoupon }) => {
  const [showCoupon, setShowCoupon] = useState<boolean>(false);
  const [couponForm] = useForm<{ coupon: string | null }>();
  const toggleShowCoupon = () => setShowCoupon(!showCoupon)

  return (
    <>
      {!showCoupon && (
        <Button type="link" onClick={toggleShowCoupon} className="p-0">
          {_t('add', 'couponCode')}
        </Button>
      )}

      {showCoupon && (
        <AntForm onFinish={values => setWantedCoupon(values.coupon)} form={couponForm}>
          <AntForm.Item name="coupon">
            <Input
              placeholder={_t('couponCode')}
              suffix={<Icon name="save-outline" onClick={couponForm.submit} />}
            />
          </AntForm.Item>
        </AntForm>
      )}
    </>
  )
}

interface PaymentDetailsType {
  newSubPrice: SubscriptionPriceChangeType | null,
  setSubChangesAt?: React.Dispatch<React.SetStateAction<moment.Moment | null>>
}

export const PaymentDetails: FC<PaymentDetailsType> = (
  { newSubPrice, setSubChangesAt }
) => {
  const { dateToChange, paymentDate } = newSubPrice || {};
  const subChangesAt = dateToChange ? moment(dateToChange) : null;
  const paymentAt = paymentDate ? moment(paymentDate) : null;
  const lines = newSubPrice?.lines ? [...newSubPrice.lines] : [];

  useEffect(() => {
    setSubChangesAt && setSubChangesAt(dateToChange ? moment(dateToChange) : null);
  }, [setSubChangesAt, dateToChange]);

  if (newSubPrice?.discountAmount) {
    lines.push({
      description: _t('offer_discount'),
      amountExcludingTax: newSubPrice?.discountAmount || 0,
      taxes: 0,
      amountWithTax: newSubPrice?.discountAmount || 0,
    })
  }

  lines.push({
    description: _t('inTotal'),
    amountExcludingTax: newSubPrice?.totalExcludingTax || 0,
    taxes: newSubPrice?.taxes || 0,
    amountWithTax: newSubPrice?.totalWithTax || 0,
  })

  return newSubPrice && (
    <>
      <List
        size="small"
        split={false}
        className="p-2 bg-muted"
        dataSource={lines}
        renderItem={item => (
          <List.Item className="p-0">
            <Row className={"w-100 " + (item.description === _t('inTotal') ? 'text-bold mt-1' : '')}>
              <Col flex="auto">
                {item.description}
              </Col>

              <Col flex="100px" className="text-right">
                {format.pricePrefixed(item.amountExcludingTax)}
              </Col>
            </Row>
          </List.Item>
        )}
      />

      {subChangesAt && paymentAt && (
        <DetailsList className="mt-1">
          <DetailsList.Item label={(
            <>
              {_t('toBePaid')}&nbsp;
              <strong>
                {paymentAt && paymentAt.format(appConfig("dateFormat")) === moment().format(appConfig("dateFormat"))
                  ? _t('today')
                  : `${paymentAt ? _t('date_at') : ''} ${paymentAt?.format(appConfig("dateFormat"))}`}
                    &nbsp;(+ {_t('vat')})
              </strong>
            </>
          )} value={format.pricePrefixed(newSubPrice.totalExcludingTax)} />
        </DetailsList>
      )}
    </>
  )
}

const Form: FC<FormType> = (props) => {
  const [intentCode, setIntentCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCode = async () => {
    try {
      setLoading(true);
      const { data } = await initPaymentMethodIntent();
      setIntentCode(data.code);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!intentCode) {
      fetchCode();
    }
  }, [intentCode])

  return (
    <Skeleton loading={intentCode === ''}>
      {stripePromise && intentCode ? (
        <Elements stripe={stripePromise} options={{ clientSecret: intentCode, locale: "da" }}>
          <FormContent {...props} />
        </Elements>
      ) : null}
    </Skeleton>
  )
}

export default Form;
