import { Col, Row } from "antd";
import React, { FC } from "react";
import { ScreenProps } from "../types/appTypes";
import DashboardCases from "./forms/dashboard/dashboardCases";
import DashboardNews from "./forms/dashboard/dashboardNews";
import DashboardCardCarousel from "./forms/dashboard/dashboardCardCarousel";

const Dashboard: FC<ScreenProps> = ({ title }) => {

  return (

    <Row gutter={[16, 16]} justify="center" className="dashboard-ant-row">
      <Col xs={24} xxl={21}>
        <DashboardCardCarousel />
      </Col>
      <Col xs={24} xxl={21}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={8}>
            <DashboardNews />
          </Col>
          <Col xs={24} xl={16}>
            <DashboardCases />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Dashboard;
