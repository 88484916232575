export const getApiHost = () => process.env.REACT_APP_API_HOST;
export const getSocketHost = () => process.env.REACT_APP_SOCKET_HOST;
export const getSocketHostPort = () => process.env.REACT_APP_SOCKET_HOST_PORT;

const getApiEndpoint = () => {
  const protocol = process.env.REACT_APP_API_PROTOCOL;
  const host = process.env.REACT_APP_API_HOST;
  const version = process.env.REACT_APP_API_VERSION;
  const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

  if (!protocol || !host || !version) {
    throw Error("Please check api endpoint variables in .env file.");
  }
  return `${protocol}://${host}${apiEndpoint}/${version}`;
};

export const getSocketAuthEndpoint = () => {
  const protocol = process.env.REACT_APP_API_PROTOCOL;
  const host = process.env.REACT_APP_API_HOST;
  const socketAuthEndpoint = process.env.REACT_APP_SOCKET_ENDPOINT;

  return `${protocol}://${host}${socketAuthEndpoint}`;
};

export type getApiBaseUrlType = (path?: string) => string;

export const getApiBaseUrl: getApiBaseUrlType = (path = "") => {
  const protocol = process.env.REACT_APP_API_PROTOCOL;
  const host = process.env.REACT_APP_API_HOST;
  if (!protocol || !host) {
    return path;
  }
  return `${protocol}://${host}${path}`;
};

export default getApiEndpoint;

