import { FC } from "react";
import TradePriceCalculator from "./tradePriceCalculator";
import TradePriceEditFields from "./tradePriceEditFields";

const TradePriceFormItems: FC = () => {
  return (
    <>
      <TradePriceCalculator />
      <TradePriceEditFields />
    </>
  );
};

export default TradePriceFormItems;
