import { FC, ReactText } from "react";
import { SelectValue } from "antd/lib/select";
import { useHistory } from "react-router-dom";
import StatusSelect from "../../components/form/statusSelect";
import _t from "../../lang/translate";
import { statusKey } from "../../services/urlQueryService";

const TaxDocTableFilter: FC = () => {
  const history = useHistory();
  const location = history.location;
  const initStatusFilter = new URLSearchParams(location.search).get(statusKey) as ReactText;

  const onStatusFilterChange = (id: SelectValue) => {
    const query = new URLSearchParams(location.search);
    if (!id) query.delete(statusKey);
    else query.set(statusKey, id.toString());

    history.push({
      ...history.location,
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  return (
    <StatusSelect
      defaultValue={initStatusFilter !== null ? Number(initStatusFilter) : undefined}
      showSearch={false}
      className="width-180"
      placeholder={_t("status", "filter")}
      allowClear
      onChange={(value) => onStatusFilterChange(value)}
      contexts={"taxDoc"}
    />
  );
};

export default TaxDocTableFilter;
