import { Result } from "antd";
import React from "react";
import _t from "../lang/translate";
import log from "../services/logService";

interface Props {
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any): State {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: React.ErrorInfo): void {
    log({ error, componentStack: errorInfo.componentStack });
    console.log("componentStack: " + errorInfo.componentStack);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <Result status="error" title={_t("msg.unknown_error")} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
