import { FC } from "react";
import { Form } from "antd";
import _t from "../../../../lang/translate";
import { OfferTax } from "../../../../types/taxTypes";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import TaxCalculationTotalField from "./taxCalculationTotalField";

interface Props {
  optMode: boolean;
}
const TaxOfferTotal: FC<Props> = ({ optMode }) => {
  const { taxCase } = TaxCaseStore.useContainer();
  const ignoreCollateral = taxCase.calculationTypeId !== 1;
  const shouldCalcTotal = (prev: OfferTax, next: OfferTax) => {
    return (
      prev.registrationFee?.registrationFee !== next.registrationFee?.registrationFee ||
      prev.estimatedCollateral?.estimatedCollateral !== next.estimatedCollateral?.estimatedCollateral ||
      prev.fee !== next.fee ||
      prev?.collateral !== next?.collateral ||
      prev.licensePlateFee !== next.licensePlateFee
    );
  };

  return (
    <Form.Item shouldUpdate={shouldCalcTotal} noStyle>
      {({ getFieldValue }) => {
        const regFee = getFieldValue(["registrationFee", "registrationFee"]) || 0;
        const fee = getFieldValue(["fee", "fee"]) || 0;
        const estimatedCollateral = ignoreCollateral || optMode ? 0 : getFieldValue(["estimatedCollateral", "estimatedCollateral"]) || 0;
        const collateral = ignoreCollateral ? 0 : getFieldValue("collateral") || 0;
        const licensePlateFee = getFieldValue("licensePlateFee") || 0;

        return (
          <Form.Item label={_t("total_price")} name="total">
            <TaxCalculationTotalField input={[regFee, fee, licensePlateFee, collateral, estimatedCollateral]} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default TaxOfferTotal;
