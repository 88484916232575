import { FC } from "react";
import { Form, Input } from "antd";
import _t from "../../../lang/translate";
import { ContactPersonsKeyType } from "../../../types/leasingTypes";
import ContactPersonSelect from "./contactPersonSelect";
import DividerLine from "../../../components/lineSplit";

interface ContactPersonFormFieldsProps {
  formKey?: ContactPersonsKeyType;
  hideSelect?: boolean;
}

const ContactPersonFormFields: FC<ContactPersonFormFieldsProps> = ({ formKey, hideSelect = false }) => {
  return (
    <>
      <DividerLine>{_t(formKey === "legalOwner" ? "legal_owner" : "contact_person")}</DividerLine>
      {!hideSelect && !!formKey && <ContactPersonSelect formKey={formKey} />}
      <Form.Item name={formKey ? [formKey, "name"] : "name"} label={_t("name")}>
        <Input />
      </Form.Item>
      <Form.Item name={formKey ? [formKey, "email"] : "email"} label={_t("email")}>
        <Input />
      </Form.Item>
      <Form.Item name={formKey ? [formKey, "phone"] : "phone"} label={_t("phone")}>
        <Input />
      </Form.Item>
    </>
  );
};

export default ContactPersonFormFields;
