// @ts-nocheck
import { FC, ReactNode } from "react";
import Icon from "./../assets/icons/icon";

interface CaseStatusProps {
  status: "sync_car_info" | "waiting_merchant" | "customer_not_valid" | "calculating" | "validating_customer";
  title?: string | null;
  message?: string | null;
  extra?: ReactNode;
}

const CaseStatus: FC<CaseStatusProps> = ({ status, title, message, extra }) => {
  const getIcon = () => {
    if (status === "calculating") {
      return <Icon name="calculator" className="mb-1" width={84} height={90} />;
    }
    if (status === "sync_car_info") {
      return <Icon name="sync-car-info" className="mb-1" width={84} height={90} />;
    }
    if (status === "waiting_merchant") {
      return <Icon name="person-unavilable" className="mb-1" width={84} height={90} />;
    }
    if (status === "customer_not_valid") {
      return <Icon name="customer-not-valid" className="mb-1" width={163.3} height={100} />;
    }
    if (status === "validating_customer") {
      return <Icon name="validating-customer" className="mb-1" width={163.3} height={100} />;
    }
  };
  return (
    <div className="custom-tab-msg">
      {getIcon()}
      {title && <h2 className="content-title">{title}</h2>}
      {message && <p className="text-center">{message}</p>}
      {extra}
    </div>
  );
};

export default CaseStatus;
