import { Form } from "antd";
import { FC } from "react";
import SwitchInput from "../../../components/form/switchInput";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";
import { isNum } from "../../../utilities/typeGuard";
import { InsuranceOutputType } from "./calc/addons/insuranceCalcTypes";

const LeasingInsuranceRoadAssistance: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const shouldUpdate = (curr: any, next: any) =>
    curr?.insuranceOutput?.roadAssistance !== next?.insuranceOutput?.roadAssistance;

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate}>
      {({ getFieldsValue }) => {
        const insuranceOutput: InsuranceOutputType = getFieldsValue(true)?.insuranceOutput;
        const { roadAssistanceMonthlyPayment } = insuranceOutput || {};
        //TODO add monthly amount on insurance calc
        const roadAssistanceRender =
          isNum(roadAssistanceMonthlyPayment) && roadAssistanceMonthlyPayment > 0 ? (
            <>
              <label>
                {format.number(roadAssistanceMonthlyPayment)} {_t("krone_per_month")}
              </label>
            </>
          ) : (
            ""
          );

        return (
          <Form.Item label={_t("road_help")} valuePropName="checked" name={["insuranceInput", "roadAssistance"]}
            className="mb-05">
            <SwitchInput disabled={disabled} size="small" label={roadAssistanceRender} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default LeasingInsuranceRoadAssistance;
