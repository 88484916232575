import React, { FC, useState } from "react";
import { Button, Col, Modal, Row, Spin } from "antd";
import useHash from "../../../../hooks/useHash";
import _t from "../../../../lang/translate";
import TaxDocPurchaseForm from "./taxDocPurchaseForm";
import TaxOfferExpiredBtn from "./taxOfferExpiredBtn";

interface Props {
  expired: boolean;
}

const TaxDocPurchaseButton: FC<Props> = ({ expired }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hash, setHash] = useHash();
  const hashKey = _t("buy", "documentation").replaceAll(" ", "_").toLowerCase();
  const isVisible = hash === hashKey;
  const openModal = () => setHash(hashKey);
  const closeModal = () => setHash();

  const purchaseButton = (
    <>
      <Button onClick={openModal} type="primary" className="pl-1 pr-1" disabled={expired}>
        {_t("request", "documentation")}
      </Button>
      <Modal
        visible={isVisible}
        onCancel={closeModal}
        title={_t("request", "documentation")}
        footer={null}
      >
        <Spin spinning={loading} size="large">
          <TaxDocPurchaseForm setLoading={setLoading} />
        </Spin>
      </Modal>
    </>
  );

  return (
    <Row justify="end" gutter={8}>
      {expired ? (
        <Col><TaxOfferExpiredBtn /></Col>
      ) : null}
      <Col>{purchaseButton}</Col>
    </Row>
  );
};

export default TaxDocPurchaseButton;
