import React, { FC, useState } from "react";
import { Modal, ModalProps } from "antd";
import Draggable from "react-draggable";

interface DraggableModalProps extends ModalProps {
}

const DraggableModal: FC<DraggableModalProps> = ({ children, title, ...rest }) => {
  const [dragging, setDragging] = useState<boolean>(true);
  return (
    <Modal
      title={
        <div
          style={{ width: "100%", cursor: "move" }}
          onMouseOverCapture={() => {
            if (dragging) setDragging(false);
          }}
          onMouseOutCapture={() => {
            if (!dragging) setDragging(true);
          }}
        >
          {title}
        </div>
      }
      modalRender={(modal) => <Draggable disabled={dragging}>{modal}</Draggable>}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default DraggableModal;
