import { Button, Checkbox, Form, Input, Tabs, Tooltip } from "antd";
import React, { FC } from "react";
import Icon from "../../../assets/icons/icon";
import _t from "../../../lang/translate";
import EnumSelect from "../../../components/form/enumSelect";
import DocumentsUpload from "../../../components/documentsUpload";
import { taxFileUrl } from "../../../services/taxService";
import InputNum from "../../../components/form/inputNum";
import UserStore from "../../../contexts/userStore";
import InputVin from "../../../components/form/inputVin";
import { TaxCase } from "../../../types/taxTypes";
import EnumRadio from "../../../components/form/EnumRadio";
import TaxVehicleForm from "./admin/taxVehicleForm";
import DateSelect from "../../../components/form/dateSelect";
import moment from "moment";

const { TabPane } = Tabs;
const { TextArea } = Input;

interface TaxFetchCarFCProps {
  uploadRef?: React.RefObject<HTMLDivElement>;
  onTabChange: (key: string) => void;
  activeTab: string;
  taxCase?: TaxCase;
  isAdmin: boolean;
  enforceIsInspected: boolean;
}

const TaxFetchCarFC: FC<TaxFetchCarFCProps> = ({
  uploadRef, taxCase, onTabChange, isAdmin, activeTab, enforceIsInspected
}) => {
  const { hasPermission } = UserStore.useContainer();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const evalTypeElement = (
    <Form.Item
      name={["evaluationTypeId"]}
      label={_t("evaluating_type")}
      rules={[{ required: activeTab === "vin" }]}
    >
      <EnumRadio nameKey={["taxCase", "evaluationType"]} />
    </Form.Item>
  );

  return (
    <div>
      <Tabs activeKey={activeTab} destroyInactiveTabPane onTabClick={onTabChange}>
        <TabPane tab={_t("vin")} key="vin">
          <Form.Item
            wrapperCol={{ span: 24 }}
            name={["vehicle", "vin"]}
            className="mb-1"
            rules={[{ required: activeTab === "vin" }]}
          >
            <InputVin size="large" placeholder={_t("insert_vin_here")} prefix={<Icon name="barcode-outline" />} />
          </Form.Item>

          <div className="fit-content-height position-relative">
            <Form.Item label=" "
              name={["vehicle", "isInspected"]}
              valuePropName="checked"
            >
              <Checkbox disabled={enforceIsInspected}>
                {_t("input_registration_date_manually")}
              </Checkbox>
            </Form.Item>
            <Tooltip title={_t("vin_not_in_dmr")} trigger={['hover', 'focus']} className="absolute-right md-min-top-2px md-max-top-33px">
              <Button
                size="small" type="text"
                shape="circle"
                className="muted no-bg"
              >
                <Icon name="information-circle" />
              </Button>
            </Tooltip>
          </div>

          <Form.Item label=" " name={["flags", "isPriority"]} valuePropName="checked">
            <Checkbox>{_t("high_priority_with_example")}</Checkbox>
          </Form.Item>
          {evalTypeElement}

          <Form.Item shouldUpdate={(prev, next) =>
            prev.vehicle?.vin !== next.vehicle.vin ||
            prev.vehicle?.isInspected !== next.vehicle?.isInspected
          }
            noStyle
          >
            {({ getFieldValue }) => {
              const inputRegDateManually = getFieldValue(['vehicle', 'isInspected']);
              return inputRegDateManually &&
                <Form.Item
                  name={["vehicle", "firstRegDate"]}
                  label={_t("firstRegDate")}
                  className="highlight-input"
                  rules={[{
                    required: activeTab === 'vin' && inputRegDateManually,
                  }]}
                >
                  <DateSelect disabledDate={
                    (currentDate) => currentDate && currentDate > moment().endOf('day')
                  }
                  />
                </Form.Item>
            }}
          </Form.Item>

          <Form.Item name={["vehicle", "mileage"]} label={_t("mileage")} rules={[{ required: activeTab === "vin" }]}>
            <InputNum step={1000} min={0} suffix={_t("mileage_unit")} />
          </Form.Item>

          <Form.Item
            className="mb-05"
            name={["vehicle", "useCaseId"]}
            label={_t("special_use")}
            rules={[{ required: activeTab === "vin" }]}
          >
            <EnumSelect nameKey={["vehicle", "useCase"]} defaultActiveFirstOption />
          </Form.Item>
        </TabPane>
        <TabPane tab={_t("link")} key="link">
          <Form.Item
            wrapperCol={{ span: 24 }}
            className="mb-1"
            name={["vehicle", "link"]}
            rules={[{ required: activeTab === "link" }]}
          >
            <Input size="large" placeholder={_t("insert_link")} prefix={<Icon name="link-outline" />} />
          </Form.Item>
          <Form.Item
            wrapperCol={{ span: 24 }}
            name={["vehicle", "vin"]}
            className="mb-1"
            rules={[{ required: activeTab === "vin" }]}
          >
            <InputVin size="large" placeholder={_t("insert_vin_here")} prefix={<Icon name="barcode-outline" />} />
          </Form.Item>
          {evalTypeElement}
        </TabPane>
        <TabPane tab={_t("manual")} key="manual">
          {evalTypeElement}
          <TaxVehicleForm required={activeTab === "manual"} nested={["vehicle"]} hideVehicleType={true} />
        </TabPane>
      </Tabs>
      <div className="ml-2 mr-2">
        <Form.Item name="note" label={_t("note")}>
          <TextArea placeholder={_t("note_placeholder")} autoSize={{ minRows: 1, maxRows: 3 }} />
        </Form.Item>

        <div className="uploadRef" ref={uploadRef}>
          <Form.Item name="files" label={_t("documents")} valuePropName="fileList" getValueFromEvent={normFile}>
            <DocumentsUpload
              canLockFiles={hasPermission("tax.isAdmin")}
              action={taxFileUrl(taxCase?.id)}
              canDelete={!isAdmin}
              multiple={true}
            />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default TaxFetchCarFC;
