import { FC, useMemo, useState } from "react";
import { Drawer, Layout, Menu } from "antd";
import Logo from "./logo";
import Icon from "../assets/icons/icon";
import { Link, useLocation } from "react-router-dom";
import { menuItems } from "../routes/appRoutes";
import LayoutStore from "../contexts/layoutStore";
import consts from "../utilities/consts";
import UserStore from "../contexts/userStore";
import CurrentSubscriptionInfo from "../components/CurrentSubscriptionInfo";
import DividerLine from "../components/lineSplit";

const { Sider } = Layout;

const AppSider: FC = () => {
  let location = useLocation();
  const { user, hasPermission, permissions } = UserStore.useContainer();
  const { screen } = LayoutStore.useContainer();
  const { sideMenuVisiblity, setSideMenuVisiblity } = LayoutStore.useContainer();

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = (collapsed: boolean) => setCollapsed(collapsed);
  const menuContent = useMemo(() => {
    const permittedMenuItems = menuItems.filter((item) => typeof item === "string" || hasPermission(item.permission));
    // Remove double dividers:
    const filterdMenuItems = permittedMenuItems.filter(
      (item, index) => typeof item !== "string" || typeof permittedMenuItems[index - 1] !== "string"
    );
    return (
      <div className="sider-menu d-flex flex-column">
        <Logo />
        <Menu
          theme="dark"
          defaultSelectedKeys={["/"]}
          selectedKeys={[location.pathname]}
          mode="inline"
          style={{ marginBottom: 'auto' }}
        >
          {filterdMenuItems.map((item) => {
            if (typeof item === "string") {
              return (
                <Menu.Item key={item} className="height-auto cursor-default" disabled>
                  <hr className="m-0" />
                </Menu.Item>
              );
            }
            const { path, title, iconName } = item;

            const titleNode = item.badge ? (
              <>
                <span className="mx-width-125 ant-typography-ellipsis ant-typography-ellipsis-single-line">
                  {title}
                </span>
                <item.badge />
              </>
            ) : (
              title
            );

            return (
              <Menu.Item key={path} className={title.toLowerCase()} icon={iconName ? <Icon name={iconName} /> : null}>
                <Link to={path} onClick={screen.isTablet ? () => setSideMenuVisiblity(false) : undefined}>
                  {titleNode}
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>

        <DividerLine />
        <CurrentSubscriptionInfo darkMode statusAsDot dealerId={user?.dealer.id} collapsible hideDescription />
      </div>
    );
  }, [menuItems, location, permissions, collapsed]);

  return screen.isTablet ? (
    <Drawer
      className="side-menu-mobile"
      visible={sideMenuVisiblity}
      onClose={() => setSideMenuVisiblity(false)}
      closable={false}
      placement="left"
      width={consts.sideMenuWidth}
    >
      {menuContent}
    </Drawer>
  ) : (
    <Sider
      collapsible={true}
      collapsed={collapsed}
      onCollapse={onCollapse}
      trigger={<span />}
      width={consts.sideMenuWidthSm}
      breakpoint="lg"
    >
      {menuContent}
    </Sider>
  );
};

export default AppSider;
