import moment from "moment";

var consts = {
  format: "hh:mm:ss",
  startWorkTime: "08:00:00",
  endWorkTime: "16:00:00",
  handleDurationMs: 1800000,
};

function calculateDiff(startDateISO: string, handleDurationMinutes: number): number {
  const handleDurationMs = handleDurationMinutes * 60000;
  const nowISO = moment().toISOString();
  const now = getCorrectTime(nowISO);
  const startDate = getCorrectTime(startDateISO);
  const expectedEndDate = startDate.add(handleDurationMs, "milliseconds");

  let isNeg = false;
  let diff = moment.duration(expectedEndDate.diff(now, "milliseconds"));

  // if the diff is more than 30 min:
  if (now.isSameOrAfter(expectedEndDate)) {
    isNeg = true;
    diff = getDiff(expectedEndDate.toISOString(), nowISO);
  }

  const durationMs = diff.asMilliseconds();

  return isNeg ? -durationMs : durationMs;
  //return `${isNeg ? "-" : ""}${moment.utc(diff.asMilliseconds()).format("DD:HH:mm:ss")}`;
}

function getDiff(startDateISO: string, endDateISO: string) {
  const startDate = moment(startDateISO);
  const endDate = moment(endDateISO);
  let diff = moment.duration(endDate.diff(startDate, "milliseconds"));

  if (startDate.isSame(endDate, "day")) {
    return diff;
  }

  var daysCount = endDate.diff(startDate, "days");

  for (var i = 0; i < daysCount; i++) {
    if (startDate.add(i, "day").day() === 6 || startDate.add(i, "day").day() === 7) {
      diff.subtract(24, "hours");
    } else if (i === 0 || i + 1 === daysCount) {
      diff.subtract(8, "hours");
    } else {
      diff.subtract(16, "hours");
    }
  }

  return diff;
}

function getCorrectTime(isoDate: string): moment.Moment {
  const { startWorkTime, endWorkTime, format } = consts;
  const startWork = moment(startWorkTime, format);
  const endWork = moment(endWorkTime, format);
  var value = moment(isoDate);
  if (!value.isValid()) return moment();
  const timeObj = {
    hour: startWork.get("hour"),
    minute: startWork.get("minute"),
    second: startWork.get("second"),
  };
  // check if weekend and add days:
  const dayNumber = value.isoWeekday();
  if (dayNumber > 5) {
    const count = dayNumber === 7 ? 1 : 2;
    return value.add(count, "day").set(timeObj);
  }
  if (value.hour() > endWork.get("hour")) {
    return value.add(1, "day").set(timeObj);
  }
  if (value.hour() < startWork.get("hour")) {
    return value.set(timeObj);
  }

  return value;
}

export default calculateDiff;
