import { Alert, Button } from "antd";
import { FC } from "react";
import AnimateIn from "../../components/animateIn";
import Checklist from "../../components/checklist/checklist";
import LeasingStore from "../../contexts/leasingStore";
import UserStore from "../../contexts/userStore";
import _t from "../../lang/translate";
import PageBody from "../../layout/pageBody";
import LeasingCaseContent from "./leasing/leasingCaseContent";

const LeasingCaseBody: FC = () => {
  const { leasingCase, setLeasingCase, versionStatus, setVersionStatus, isAdmin } = LeasingStore.useContainer();
  const { partnerCase, id } = leasingCase;
  const { hasPermission } = UserStore.useContainer();
  const canSeeChecklist = hasPermission(partnerCase ? "partner_leasing.checklist" : "leasing.checklist");

  const handleUseNewCalculator = () => {
    const nextLeasingCase = { ...leasingCase };
    nextLeasingCase.flags.readonly = false;
    setLeasingCase(nextLeasingCase);
    setVersionStatus("latest");
  };

  return (
    <AnimateIn start={!!leasingCase.status} loadingBeforeStart>
      <div className="d-flex">
        <PageBody>
          {versionStatus !== "latest" && (
            <Alert
              banner
              type={versionStatus === "expired" ? "error" : "info"}
              message={
                <>
                  {_t(versionStatus === "expired" ? "msg.cannot_calculate" : "calc_updated")}
                  <Button type="link" className="pt-0 pb-0" size="small" onClick={handleUseNewCalculator}>
                    {_t("use_new_calculator")}
                  </Button>
                </>
              }
              closable={versionStatus !== "expired"}
              className="mb-2"
            />
          )}
          <LeasingCaseContent />
        </PageBody>
        {canSeeChecklist && isAdmin && <Checklist {...{ isAdmin, id, context: "leasing", btnText: _t("checklist"), initialOpen: true }} />}
      </div>
    </AnimateIn>
  );
};

export default LeasingCaseBody;
