import { FC, useState } from "react";
import { Button, Modal } from "antd";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { LeasingPartialCaseType } from "../../../types/leasingTypes";
import LeasingGenerateContractsForm from "./leasingGenerateContractsForm";

const LeasingGenerateContracts: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();
  const { readonly, contractGenerated, validationAccepted, unMergedCustomer } = leasingCase.flags;
  const disabled = contractGenerated || readonly || !validationAccepted || unMergedCustomer;

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  const handleGeneratedContracts = (data: LeasingPartialCaseType) => {
    setLeasingCase({ ...leasingCase, ...data });
    closeModal();
  };

  return (
    <>
      <Button type="primary" disabled={disabled} onClick={openModal}>
        {_t("generate_contracts")}
      </Button>
      <Modal width={930} visible={isVisible} onCancel={closeModal} title={_t("generate_contracts")} footer={null}>
        <LeasingGenerateContractsForm
          onCancel={closeModal}
          leasingCase={leasingCase}
          onGenerated={handleGeneratedContracts}
        />
      </Modal>
    </>
  );
};

export default LeasingGenerateContracts;
