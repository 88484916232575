import React, { FC } from "react";
import { Col, Form, Progress, Row, Typography } from "antd";
import ChecklistUserStore from "../../contexts/checklistUserStore";

const ChecklistUserHeader: FC = () => {
  const { total, completedTotal } = ChecklistUserStore.useContainer();

  const percent = (completedTotal * 100) / total;
  return (
    <>
      <Form className="pt-2 p-1 pl-2 pr-2">
        <Row gutter={20} justify="space-between">
          <Col>
            <Typography.Title level={4} type={percent === 100 ? "success" : "secondary"} className="mb-0">
              {completedTotal}
              <small className="text-inherit"> / {total}</small>
            </Typography.Title>
          </Col>
        </Row>
      </Form>
      <Progress percent={percent} size="small" showInfo={false} strokeWidth={3} status="success" />
    </>
  );
};

export default ChecklistUserHeader;
