import { useEffect, useState } from "react";
import { EnumItemType } from "../types/appTypes";
import useHash from "./useHash";

type UseActiveStatusType = [activeStatusId: number, setActiveStatusId: (id: string) => void];

function useActiveStatus(statuses: EnumItemType[]): UseActiveStatusType {
  const [hash, setHash] = useHash();
  const [activeStatusId, setActiveStatusId] = useState<number>(statuses[0]?.id || -1);

  useEffect(() => {
    const activeStatus = statuses.filter((st) => st.title === hash)[0] || statuses[0];

    activeStatus?.id !== activeStatusId && setActiveStatusId(activeStatus?.id);
  }, [hash, statuses]);

  const handleNewActiveStatusId = (id: string) => {
    const nextStatus = statuses.filter((st) => st.id.toString() === id)[0] || statuses[0];
    setHash(statuses[0].id === nextStatus?.id ? "" : nextStatus?.title);
  };

  return [activeStatusId, handleNewActiveStatusId];
}

export default useActiveStatus;
