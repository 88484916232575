import { Col, Form, Row } from "antd";
import { FC } from "react";
import _t from "../../../../lang/translate";
import NewPriceEquipmentsList from "./newPriceEquipmentsList";
import NewPriceEquipmentsTotalField from "./newPriceEquipmentsTotalField";

const NewPriceEquipments: FC = () => {
  return (
    <Row justify="space-between" align="middle">
      <Col>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <Form.Item className="mb-0" name={["newPrice", "equipmentsPrice"]} label={_t("extra_equipments")}>
              <NewPriceEquipmentsTotalField
                equipments={getFieldValue(["newPrice", "equipmentCalculator"])?.equipments || []}
              />
            </Form.Item>
          )}
        </Form.Item>
      </Col>
      <Col>
        <Form.Item name={["newPrice", "equipmentCalculator"]} noStyle>
          <NewPriceEquipmentsList />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default NewPriceEquipments;
