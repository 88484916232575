import React, { FC, useState } from "react";
import CollapseCard from "../../../../components/collapseCard";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import ImportRequestForm from "./importOrderForm";
import ImportOrderInfoEditable from "./importOrderInfoEditable";

const ImportOrderEditableCard: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { requested } = importCase.flags;
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <CollapseCard title={_t("order")} loading={loading}>
      {requested ? <ImportOrderInfoEditable /> : <ImportRequestForm setLoading={setLoading} loading={loading} />}
    </CollapseCard>
  );
};

export default ImportOrderEditableCard;
