import React, { FC } from "react";
import { Button, Col, Result, Row } from "antd";
import UserStore from "../contexts/userStore";
import PageTitle from "../layout/pageTitle";
import _t from "../lang/translate";
import { useApi } from "../services/useApi";
import { useHistory } from "react-router-dom";
import { profileUrl } from "../services/profileService";
import { ProfileType } from "../types/userTypes";
import UserProfile from "./forms/myAccount/userProfile";
import DealerProfile from "./forms/myAccount/dealerProfile";

interface MyAccountProps {
  title: string;
}

export type ProfileUpdateFunc = (profile: ProfileType) => void;

const MyAccount: FC<MyAccountProps> = ({ title }) => {
  let history = useHistory();
  const { onProfileUpdated } = UserStore.useContainer();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ data, isLoading, isError }, setUrl, setData] = useApi<ProfileType | null>(profileUrl(), null);

  const handleProfileUpdate: ProfileUpdateFunc = (profile) => {
    setData(profile);
    onProfileUpdated({
      name: profile.name,
      phone: profile.phone,
      initials: profile.initials,
    });
  };

  return (
    <>
      <PageTitle title={title} />
      {isError && (
        <Result
          status="error"
          subTitle={_t("msg.unknown_error")}
          extra={<Button onClick={() => history.goBack()}>{_t("go_back")}</Button>}
        />
      )}
      <div className={`content-bg card${isLoading ? " loading" : ""}`}>
        <Row className="card-body">
          <Col xs={24} lg={12}>
            {data && <UserProfile user={data} onProfileUpdate={handleProfileUpdate} />}
          </Col>
          <Col xs={24} lg={12} className="card-sider p-0">
            <div className="p-1">{data?.dealer && <DealerProfile dealer={data.dealer} />}</div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyAccount;
