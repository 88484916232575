import { FC } from "react";
import { Form } from "antd";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import _t from "../../../../lang/translate";
import ResellerTaxCollateralAmountInput from "./ResellerTaxCollateralAmountInput";

const ResellerTaxCollateralAmount: FC = () => {
  const { resellerTaxCase } = ResellerTaxCaseStore.useContainer();
  const offerRegFee = resellerTaxCase.offer?.registrationFee;
  const estimatedCollateral = resellerTaxCase.offer?.collateralFee;
  const finalPreRegFee = (offerRegFee || 0) + (estimatedCollateral || 0);

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => (
        <Form.Item label={_t("collateralFee")} name="collateralFee">
          <ResellerTaxCollateralAmountInput
            optRegFee={getFieldValue(["registrationFee"])}
            offerRegFee={finalPreRegFee}
          />
        </Form.Item>
      )}
    </Form.Item>
  );
};

export default ResellerTaxCollateralAmount;
