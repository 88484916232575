import { Badge, Collapse, Form } from "antd";
import { FC, useState } from "react";
import CustomCollapse from "../../../components/customCollapse";
import CustomCollapseHeader from "../../../components/customCollapseHeader";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import LeasingDealAdvanced from "./leasingDealAdvanced";
import LeasingLimitsFields from "./leasingLimitsFields";
import TaxationField from "./taxationField";

const { Panel } = Collapse;

const LeasingDealExtraFields: FC = () => {
  const { isPrivate } = LeasingStore.useContainer();
  const [inLimitStatus, setInLimitStatus] = useState<boolean>(true);

  return (
    <CustomCollapse>
      <Panel
        forceRender
        header={
          <CustomCollapseHeader>
            {!inLimitStatus && <Badge dot color={"orange"} />}
            {_t("customization")}
          </CustomCollapseHeader>
        }
        key="1"
      >
        <LeasingLimitsFields inLimitStatus={inLimitStatus} onInLimitStatusChange={setInLimitStatus} />
        {!isPrivate && (
          <Form.Item
            shouldUpdate={(prev, next) => prev?.input?.vehicleAgeInMonths !== next?.input?.vehicleAgeInMonths}
            noStyle
          >
            {({ getFieldValue }) => (
              <Form.Item name={["input", "newTaxation"]} label={_t("new_car_taxation")}>
                <TaxationField vehicleAge={getFieldValue(["input", "vehicleAgeInMonths"])} />
              </Form.Item>
            )}
          </Form.Item>
        )}
      </Panel>
      <Panel header={<CustomCollapseHeader>{_t("settings")}</CustomCollapseHeader>} key="2">
        <LeasingDealAdvanced />
      </Panel>
    </CustomCollapse>
  );
};

export default LeasingDealExtraFields;
