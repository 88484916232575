import React, { FC } from "react";
import { Result } from "antd";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";
import ImportOrderedInfo from "./importOrderedInfo";
import Details from "../../../../components/detailsList";

const ImportOrderedView: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const pickupDate = format.date(importCase.vehicleOffer?.data.availableDeliveryDate);
  const expectedDeliveryDate = importCase.expectedDeliveryDate ? (
    format.date(importCase.expectedDeliveryDate)
  ) : (
    <label className="text-italic">{_t("pending")}</label>
  );
  return (
    <>
      <Result status="success" title={_t("msg.you_have_ordered_car")} />
      <Details borderLess className="mb-2">
        <Details.Item label={_t("vehicle_pickup_at")} value={pickupDate} />
        <Details.Item label={_t("expected", "delivery_date")} value={expectedDeliveryDate} />
      </Details>
      <ImportOrderedInfo />
    </>
  );
};

export default ImportOrderedView;
