import React, { FC } from "react";
import Icon from "../../../../assets/icons/icon";
import _t from "../../../../lang/translate";

const TaxDocRejectedMessage: FC = () => {
  return (
    <>
      <h3 className="text-warning">
        <Icon name="alert-circle-outline" size="huge" />
        <span className="pl-05">{_t("cannot_purchase_documentation")}</span>
      </h3>
      <label>{_t("msg.contact_dmf_for_info")}</label>
    </>
  );
};

export default TaxDocRejectedMessage;
