import React, { FC } from "react";
import { Alert, Col, Popover, Row } from "antd";
import Icon from "../../../../assets/icons/icon";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";

const TaxDocRegPrice: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  const tooltipContent = (
    <>
      <p>{_t("documentation_accepted_if_registered_within_5_days")}</p>
      {_t("garanti_rejected_if_not_registered_within_5_days")}
    </>
  );
  return (
    <>
      <Row className="flex-wrap mb-1">
        <Col flex="auto">
          <h3>
            <Icon name="dmr-logo" size="huge" className="text-primary" />
            <span className="pl-05">
              {_t("registration_fee")} ({_t("register_via_dmr")})
            </span>
          </h3>
          <label className="d-block">{_t("pay_reg_fee_on_dmr")}</label>
        </Col>
        <Col flex="80px" className="text-right">
          <h3 className="mb-0">{format.price(taxDocCase.evaluation?.registrationFee)}</h3>
        </Col>
      </Row>
      {!taxDocCase.flags.registered && (
        <Alert
          type="error"
          message={
            <>
              {_t("register_vehicle_in_five_days")} &nbsp;
              <Popover content={tooltipContent} overlayClassName="mx-width-340">
                <span className="text-primary cursor-default"> {_t("read", "more")}</span>
              </Popover>
            </>
          }
        />
      )}
    </>
  );
};

export default TaxDocRegPrice;
