import { Button, Space } from "antd";
import { FC } from "react";
import LogText from "../../../../components/logText";
import openModal from "../../../../components/modal/openModal";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { LeasingPartialCaseType } from "../../../../types/leasingTypes";

const LeasingValidationActions: FC = () => {
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();
  const { id, flags, log } = leasingCase;
  const { contractGenerated, validationStarted, unMergedCustomer, validationAccepted } = flags;

  const handleReject = async () => {
    try {
      const payload = await openModal<LeasingPartialCaseType>("leasingRejectCase", { id });
      setLeasingCase({ ...leasingCase, ...payload });
    } catch (error) {
    }
  };

  const handleAccept = async () => {
    try {
      const payload = await openModal<LeasingPartialCaseType>("leasingAcceptCase", { id });
      setLeasingCase({ ...leasingCase, ...payload });
    } catch (error) {
    }
  };

  const disabled = !validationStarted || unMergedCustomer;

  return (
    <Space>
      {validationAccepted && <LogText logData={log?.validationAccepted} className="text-success" />}
      <Button disabled={contractGenerated || !validationStarted} danger type="primary" onClick={handleReject}>
        {_t("reject", "case")}
      </Button>
      <Button type="primary" color="green" disabled={validationAccepted || disabled} onClick={handleAccept}>
        {_t("accept", "case")}
      </Button>
    </Space>
  );
};

export default LeasingValidationActions;
