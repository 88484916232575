import { EnumItemType, IdType, Person } from "./appTypes";

export interface Activity {
  id: string;
  date: string;
  person: Person;
  isPublic: boolean;
  send?: boolean;
  isImportant: boolean;
  type: EnumItemType;
  text: string | null;
  note: string | null;
}

export interface ActivityEntryType {
  id: string;
  send?: boolean;
  isPublic: boolean;
  isImportant: boolean;
  text?: string | null;
  note?: string | null;
  typeId?: IdType;
}

export const initActivityEntry: ActivityEntryType = {
  id: "0",
  send: false,
  isPublic: false,
  isImportant: false,
  text: null,
  note: null,
};

export const initActivites = { data: { entries: [], filters: [] } };

export interface ActivityEntries {
  entries: Activity[];
  filters: EnumItemType[];
}

export interface SuggestionsType {
  label: string;
  text: string;
}
