import { Button, ButtonProps, message } from "antd";
import { FC, useState } from "react";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { checkMotorAgencyStatus } from "../../../../services/leasingService";

const MotorAgencyReportCheck: FC<ButtonProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { leasingCase, adminValues, setAdminValues } = LeasingStore.useContainer();
  const vin = leasingCase.vehicle?.vin;

  const handleCheck = async () => {
    if (!vin) return;
    try {
      setLoading(true);
      const { data } = await checkMotorAgencyStatus(leasingCase.id);
      adminValues && setAdminValues({ ...adminValues, motorAgency: data });
    } catch ({ response }) {
      const { message: msg } = response?.data || {};
      message.error(msg || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button {...props} loading={loading} onClick={handleCheck}>
      {_t("check", "DMR")}
    </Button>
  );
};

export default MotorAgencyReportCheck;
