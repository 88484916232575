import { FC, useState } from "react";
import { Button, ButtonProps, Drawer } from "antd";
import { IdType, ModulTypes } from "../../types/appTypes";
import ReviewList from "./reviewList";
import LayoutStore from "../../contexts/layoutStore";
import _t from "../../lang/translate";
import consts from "../../utilities/consts";

interface ReviewHistoryButtonProps extends ButtonProps {
  rating: number | null;
  targetId: IdType;
  targetContext: ModulTypes;
  onNewRating?: (score: number) => void;
}

const ReviewHistoryButton: FC<ReviewHistoryButtonProps> = ({
  rating,
  targetId,
  targetContext,
  onNewRating,
  ...rest
}) => {
  const { screen } = LayoutStore.useContainer();
  const [visible, setVisible] = useState<boolean>(false);
  const openDrawer = () => setVisible(true);
  const closeDrawer = () => setVisible(false);

  return (
    <>
      <Button type="link" onClick={openDrawer} {...rest}>
        {rating}
      </Button>
      <Drawer
        destroyOnClose
        visible={visible}
        title={_t("reviews")}
        onClose={closeDrawer}
        width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
      >
        <ReviewList targetId={targetId} targetContext={targetContext} onNewScore={onNewRating} />
      </Drawer>
    </>
  );
};

export default ReviewHistoryButton;
