import { message } from "antd";
import { FC, useState } from "react";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import {
  adsDocumentationFileEndpoint,
  equipmentsDocumentationFileEndpoint,
  fetchEquipmentsDocumentationFile,
  newPriceDocumentationFileEndpoint,
  vehicleListDocumentationFileEndpoint,
} from "../../../../services/taxService";
import TaxDocUploadFile from "./taxDocUploadFile";

const TaxDocUploads: FC = () => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const [loadingDat, setLoadingDat] = useState<boolean>(false);

  const handleAutoFetchDat = async () => {
    try {
      setLoadingDat(true);
      const { data } = await fetchEquipmentsDocumentationFile(taxCase.id);
      setTaxCase(data);
    } catch ({ response }) {
      message.error(response?.data?.message || _t("msg.file_not_removed"));
    } finally {
      setLoadingDat(false);
    }
  };

  return (
    <>
      <TaxDocUploadFile
        label={_t("ads")}
        fileKey="ads"
        action={adsDocumentationFileEndpoint(taxCase.id)}
      />
      <TaxDocUploadFile
        label={_t("vehicle_list_from_bilinfo")}
        fileKey="vehicleList"
        action={vehicleListDocumentationFileEndpoint(taxCase.id)}
      />
      <TaxDocUploadFile
        label={_t("newprice_documentation")}
        fileKey="newPrice"
        action={newPriceDocumentationFileEndpoint(taxCase.id)}
      />
      <TaxDocUploadFile
        label={_t("equipments_documentation")}
        fileKey="equipments"
        action={equipmentsDocumentationFileEndpoint(taxCase.id)}
        onAutoFetch={handleAutoFetchDat}
        loading={loadingDat}
      />
    </>
  );
};

export default TaxDocUploads;
