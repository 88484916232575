import { FC } from "react";
import { Col, Grid, Row } from "antd";
import _t from "../lang/translate";
import StatisticLiveData from "../components/statistic/statisticLiveData";
import StatisticDiff from "../components/statistic/statisticDiff";
import {
  getActiveDealers,
  getLive,
  getTopDealers,
  getTotalAccepted,
  getTotalCreated,
} from "../services/statisticsService";
import StatisticTopEntities from "../components/statistic/statisticTopEntities";
import { ProductType } from "../types/appTypes";

const { useBreakpoint } = Grid;

interface TableStatisticsProps {
  context: ProductType;
}

const TableStatistics: FC<TableStatisticsProps> = ({ context }) => {
  const screens = useBreakpoint();
  const colClassName = "d-flex flex-column";
  const cardClassName = "flex-grow";

  return screens.xl ? (
    <Row gutter={[16, 16]} className="mb-1" align="stretch">
      <Col span={12} xxl={{ span: 6 }} className={colClassName}>
        <StatisticLiveData fetchEndpoint={getLive(context)} className={cardClassName} />
      </Col>
      <Col span={8} order={3} xxl={{ span: 4, order: 2 }} className={colClassName}>
        <StatisticDiff title={_t("total")} fetchEndpoint={getTotalCreated(context)} className={cardClassName} />
      </Col>
      <Col span={8} order={4} xxl={{ span: 4, order: 3 }} className={colClassName}>
        <StatisticDiff title={_t("accepted")} fetchEndpoint={getTotalAccepted(context)} className={cardClassName} />
      </Col>
      <Col span={8} order={5} xxl={{ span: 4, order: 4 }} className={colClassName}>
        <StatisticDiff
          title={_t("active", "dealers")}
          fetchEndpoint={getActiveDealers(context)}
          className={cardClassName}
        />
      </Col>
      <Col span={12} order={2} xxl={{ span: 6, order: 4 }} className={colClassName}>
        <StatisticTopEntities fetchEndpoint={getTopDealers(context)} className={cardClassName} />
      </Col>
    </Row>
  ) : null;
};

export default TableStatistics;
