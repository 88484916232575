import { FC } from "react";
import { Alert, Col, Row } from "antd";
import ImportVehicleEditableCard from "./importVehicleEditableCard";
import ImportMerchantEditableCard from "./importMerchantEditableCard";
import ImportReportEditableCard from "./importReportEditableCard";
import ImportOrderEditableCard from "./importOrderEditableCard";
import ImportActionsCard from "./importActionsCard";
import ImportAttachmentsCard from "./importAttachmentsCard";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import ConflictAlert from "../../../../components/conflictAlert";

const ImportCaseEdit: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  return (
    <Row align="top" gutter={24}>
      <Col xxl={{ span: 8, order: 1 }} xl={{ span: 12, order: 3 }} xs={{ span: 24, order: 3 }}>
        <ImportVehicleEditableCard />
        <ImportMerchantEditableCard />
      </Col>
      <Col xxl={{ span: 8, order: 2 }} xl={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }}>
        <ImportReportEditableCard />
        <ImportOrderEditableCard />
      </Col>
      <Col xxl={{ span: 8, order: 3 }} xl={{ span: 12, order: 2 }} xs={{ span: 24, order: 2 }}>
        {importCase.importantNote && (
          <Alert className="mb-2" message={importCase.importantNote} type="warning" closable />
        )}
        {importCase.relatedCases && <ConflictAlert conflictedCases={importCase.relatedCases} />}
        <ImportActionsCard />
        <ImportAttachmentsCard />
      </Col>
    </Row>
  );
};

export default ImportCaseEdit;
