import React, { FC } from "react";
import { Link } from "react-router-dom";
import { getPath, getTitleByRoute } from "../routes/appRoutes";
import { LinkType } from "../types/appTypes";
import { Location } from "history";

interface ServerLinkProps {
  link: LinkType;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  target?: "_blank" | "_self" | "_parent" | "_top";
  children?: React.ReactNode;
}

const ServerLink: FC<ServerLinkProps> = ({ children, link, onClick, target }) => {
  const { external, internalModule, text } = link;
  const finalText = children ? children : text;

  if (external)
    return (
      <a href={external} target={target || "_blank"}>
        {finalText || getExtrnalText(external)}
      </a>
    );
  if (internalModule) {
    const internalLocation = getInternalLink(link);
    if (!internalLocation) return null;
    return (
      <Link target={target} to={internalLocation} onClick={onClick}>
        {finalText || getTitleByRoute(internalModule)}
      </Link>
    );
  }

  return null;
};

export default ServerLink;

const getExtrnalText = (link: string) => {
  let title = link;
  if (title.startsWith("tel:")) title = title.replace("tel:", "");
  if (title.startsWith("mailto:")) title = title.replace("mailto:", "");

  return title;
};

export const getInternalLink = (link: LinkType): Location | null => {
  const { internalModule, params, search, state, hash } = link;
  const context = internalModule && getPath(internalModule);
  const mappedParams = internalModule && Array.isArray(params) && params.length && params.join("/");
  if (!context) return null;
  return {
    pathname: context + "/" + mappedParams,
    search: search || "",
    hash: hash || "",
    state: state,
  };
};
