import React, { FC } from "react";
import { Card } from "antd";
import _t from "../../../../lang/translate";
import ImportOfferInfo from "./importOfferInfo";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import CaseStatus from "../../../../components/caseStatus";

const ImportOfferView: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { offerPublished } = importCase.flags;

  return (
    <Card bordered={false} title={_t("offer")}>
      <div className="text-center">
        {offerPublished ? <ImportOfferInfo /> : <CaseStatus status="calculating" title={_t("msg.working_on_the_case")} />}
      </div>
    </Card>
  );
};

export default ImportOfferView;
