import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";
import {
  PaymentMethodIntentType,
  SubscriptionPackageType,
  SubscriptionPriceChangeType
} from "../types/subscriptionTypes";
import { getApiQuery } from "./urlQueryService";

export const subscriptionPackageUrl = `${getApiEndpoint()}/subscriptionPackage`;
export const subscriptionPaymentMethodUrl = `${getApiEndpoint()}/subscriptionPaymentMethod`;
export const signupPaymentMethod = `${getApiEndpoint()}/setupPaymentIntent`;
export function subscriptionPaymentUrl(query?: string) {
  return `${getApiEndpoint()}/subscriptionPayment?${getApiQuery(query)}`;
}

export function initPaymentMethodIntent(subscriptionPackageId?: string) {
  return http.post<PaymentMethodIntentType>(subscriptionPaymentMethodUrl, {
    subscriptionPackageId: subscriptionPackageId || null,
  });
}

export function initSignupPaymentMethodIntent(subscriptionPackageId?: string) {
  return http.post<{ data: string }>(signupPaymentMethod, {
    subscriptionPackageId: subscriptionPackageId || null,
  });
}

export function updatePaymentMethod(paymentMethodId: string, subscriptionPackageId?: string) {
  return http.put<{ data: { data: boolean } }>(subscriptionPaymentMethodUrl, {
    paymentMethodId,
    subscriptionPackageId: subscriptionPackageId || null,
  });
}

export function cancelPaymentMethod() {
  return http.delete<{ data: boolean }>(subscriptionPaymentMethodUrl);
}

export function subscriptionPackages() {
  return http.get<{ data: SubscriptionPackageType[] }>(subscriptionPackageUrl);
}

export function subscriptionPackagePrice(subscriptionPackageId?: string | number, coupon: string | null | undefined = '') {
  const url = `${subscriptionPackageUrl}/${subscriptionPackageId}?coupon=${coupon}`;
  return http.get<SubscriptionPriceChangeType>(url);
}

export function updateSubscriptionPackage(subscriptionPackageId: string, coupon?: string | null) {
  return http.put<{ data: { data: boolean } }>(subscriptionPackageUrl, {
    subscriptionPackageId,
    coupon: coupon || null,
  });
}

export function cancelSubscriptionPackage() {
  return http.delete<{ data: { data: boolean } }>(subscriptionPackageUrl);
}

export function renewSubscriptionPackage() {
  return http.patch<{ data: { data: boolean } }>(`${subscriptionPackageUrl}/renew`);
}
