import { Button, Col, Row, Space } from "antd";
import React, { FC } from "react";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import _t from "../../lang/translate";
import { ChecklistEntryViewType } from "../../types/checklistTypes";

interface ChecklistActionsProps {
  entry: ChecklistEntryViewType;
  onDelete: () => void;
  onHiddenChange: (entry: ChecklistEntryViewType) => void;
}

const ChecklistActions: FC<ChecklistActionsProps> = ({ entry, onDelete, onHiddenChange }) => {
  const { setEditing } = ChecklistAdminStore.useContainer();
  const { id, groupId, ignored } = entry;

  const handleUpdate = () => {
    setEditing({ id, groupId });
  };

  const handleIgnore = () => {
    onHiddenChange({ ...entry, ignored: !ignored });
  };

  return (
    <Row justify="space-between">
      <Col>
        <Space>
          <Button className="muted delete-btn no-bg p-05" onClick={onDelete} type="text">
            {_t("delete")}
          </Button>
          <Button className="muted no-bg p-05" type="text" onClick={handleUpdate}>
            {_t("edit")}
          </Button>
        </Space>
      </Col>
      <Col>
        <Button
          className={`no-bg p-05 ${ignored ? "text-green hover-text-green-dark" : "muted"}`}
          onClick={handleIgnore}
          type="text"
        >
          {_t(ignored ? "activate" : "hide")}
        </Button>
      </Col>
    </Row>
  );
};

export default ChecklistActions;
