import { Col, Grid, Row } from "antd";
import { FC } from "react";
import DealerStore from "../../../contexts/dealerStore";
import DealerAdmin from "./dealerAdmin";
import DealerBillingAddressCard from "./dealerBillingAddressCard";
import DealerDepartmentCard from "./dealerDepartmentCard";
import DealerInfoCard from "./dealerInfoCard";
import DealerPaymentCard from "./dealerPaymentCard";
import DealerUsersCard from "./dealerUsersCard";
import DealerSubscription from "./dealerSubscription";

const { useBreakpoint } = Grid;

const DealerContent: FC = () => {
  const { isNew, isAdmin } = DealerStore.useContainer();
  const screens = useBreakpoint();

  const isXl = screens.xl;

  if (isNew) {
    return (
      <Row gutter={24}>
        <Col xxl={{ span: 10, offset: 7 }} xl={{ span: 14, offset: 5 }} xs={{ span: 24 }}>
          <DealerInfoCard />
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={24}>
      {!isNew && isAdmin && !isXl && (
        <Col span={24}>
          <DealerAdmin />
        </Col>
      )}
      <Col xxl={{ span: 10, offset: 3 }} xl={{ span: 14 }} xs={{ span: 24 }}>
        <DealerInfoCard />
        <DealerUsersCard />
      </Col>
      <Col xxl={{ span: 8 }} xl={{ span: 10 }} xs={{ span: 24 }}>
        {!isNew && isAdmin && isXl && <DealerAdmin />}
        <DealerSubscription />
        <DealerDepartmentCard />
        <DealerBillingAddressCard />
        {/*<DealerPaymentCard/>*/}
      </Col>
    </Row>
  );
};

export default DealerContent;
