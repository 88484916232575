import { FC } from "react";
import { Button } from "antd";
import _t from "../../../lang/translate";
import LeasingStore from "../../../contexts/leasingStore";
import CustomerDetails from "../customer/customerDetails";
import { isPrivateCustomer } from "../../../services/customerService";
import LeasingContactPersonsInfo from "./leasingContactPersonsInfo";

interface LeasingCustomerInfoProps {
  startEditing: () => void;
}

const LeasingCustomerInfo: FC<LeasingCustomerInfoProps> = ({ startEditing }) => {
  const { leasingCase, isAdmin } = LeasingStore.useContainer();
  const { flags } = leasingCase;
  const { validationStarted, readonly, inputLocked } = flags;
  const disabled = readonly || inputLocked;

  const isPrivate = isPrivateCustomer(leasingCase?.customer?.typeId);

  return (
    <>
      <CustomerDetails customer={leasingCase.customer} hideContactPersons hideCpr={!isAdmin} hidePBS={!isAdmin} />
      {!isPrivate && (
        <>
          <LeasingContactPersonsInfo contactPersonKey="legalOwner" />
          <LeasingContactPersonsInfo contactPersonKey="contactPerson" />
        </>
      )}

      {!validationStarted && (
        <div className="text-right">
          <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing} disabled={disabled}>
            {_t("edit")}
          </Button>
        </div>
      )}
    </>
  );
};

export default LeasingCustomerInfo;
