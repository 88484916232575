import { FC, useState } from "react";
import CollapseCard from "../../../components/collapseCard";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import LeasingCustomerForm from "./leasingCustomerForm";
import LeasingCustomerInfo from "./leasingCustomerInfo";

const LeasingCustomerCard: FC = () => {
  const { customerLoading } = LeasingStore.useContainer();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <CollapseCard title={_t("customer")} loading={customerLoading}>
      {isEditing ? (
        <LeasingCustomerForm stopEditing={stopEditing} />
      ) : (
        <LeasingCustomerInfo startEditing={startEditing} />
      )}
    </CollapseCard>
  );
};

export default LeasingCustomerCard;
