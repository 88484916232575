import { Button, Form } from "antd";
import { FC } from "react";
import Icon from "../../../../assets/icons/icon";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { OfferTax } from "../../../../types/taxTypes";
import calculateTradePrice from "../calc/tradePriceCalculatorIndex";
import { TradePriceCalcInputType, } from "../calc/tradePriceCalculatorTypes";
import { TaxAdVehicleType } from "../../../../types/taxAdvancedCalcTypes";

const TaxOfferCopy: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const ignoreCollateral = taxCase.calculationTypeId !== 1;
  const { calculation, vehicle } = taxCase;
  const { mileage, qualityId, useCaseId, firstRegDate, isNew } = vehicle || {};

  return (
    <Form.Item shouldUpdate noStyle>
      {({ setFieldsValue, getFieldsValue }) => {
        const internalCalculation = getFieldsValue() as OfferTax;
        const handleOfferCopy = () => {
          try {
            let newInternalCalculation = { ...calculation } as OfferTax;
            newInternalCalculation.optimization = true;

            // Keep Regfee value as is:
            newInternalCalculation.registrationFee =
              internalCalculation?.registrationFee;

            //Calculate collateral:
            newInternalCalculation.collateral = ignoreCollateral
              ? 0
              : ((calculation?.registrationFee?.registrationFee || 0) + (calculation?.estimatedCollateral?.estimatedCollateral || 0)) -
              (newInternalCalculation.registrationFee?.registrationFee || 0);

            // Recalculate tradeprice:
            if (!isNew && !!newInternalCalculation.tradePrice) {
              const tradePriceInput: TradePriceCalcInputType = {
                mileage,
                qualityId,
                specialUseId: useCaseId,
                firstRegDate,
                optimization: true,
                vehicleAdCount: calculation?.tradePrice?.adVehicles?.vehicles?.filter((vehicle: TaxAdVehicleType) => vehicle.enabled).length || 0,
                otherRegulation: calculation?.tradePrice?.otherRegulation || 0,
                optimizedRegulation: 0,
                equipmentRegulation: calculation?.tradePrice?.tradePriceOutput?.equipmentRegulation,
                averagePrice: calculation?.tradePrice?.adVehicles?.total?.recalculatedPrice,
                averageMileage: calculation?.tradePrice?.adVehicles?.total?.recalculatedMileage,
              };

              const tradePriceOutput = calculateTradePrice(tradePriceInput);
              newInternalCalculation.tradePrice.tradePrice = tradePriceOutput.finalTradePriceTotal;
              newInternalCalculation.tradePrice = {
                ...{ ...newInternalCalculation.tradePrice },
                tradePriceOutput: tradePriceOutput,
                optimizedRegulation: 0,
                optimizedRegulationAmount: 0,
                otherRegulation: calculation?.tradePrice?.otherRegulation || 0,
                otherRegulationAmount: tradePriceOutput.otherRegulationAmount,
              };
            }
            setFieldsValue(newInternalCalculation);
          } catch (error) {
          }
        };
        return (
          <Button onClick={handleOfferCopy}>
            <Icon name="copy-outline" size="medium" /> {_t("copy")}
          </Button>
        );
      }}
    </Form.Item>
  );
};

export default TaxOfferCopy;
