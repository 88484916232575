import React, { FC, useState } from "react";
import { Badge, Collapse } from "antd";
import appConfig from "../../../../appConfig";
import CustomCollapse from "../../../../components/customCollapse";
import CustomCollapseHeader from "../../../../components/customCollapseHeader";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import ImportBuyerRelation from "./importBuyerRelation";
import ImportMerchantRelation from "./importMerchantRelation";
import ImportTransportActions from "./importTransportActions";
import ImportOtherActions from "./importOtherActions";
import { ImportCaseType } from "../../../../types/importTypes";

const { Panel } = Collapse;

const ImportAfterOrderActions: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const [collapseKeys, setCollapseKeys] = useState<string[]>(["1"]);
  const { pickupSelf } = importCase.flags

  const processes = importAfterOrderActionsStatus(importCase.flags, importCase.expectedDeliveryDate);
  const isBuyerRelationDone = processes.isBuyerRelationDone,
    isMerchantRelationDone = processes.isMerchantRelationDone,
    isTransportDone = processes.isTransportDone;

  return (
    <CustomCollapse activeKey={collapseKeys} setActiveKey={setCollapseKeys}>
      <Panel
        key="1"
        header={
          <CustomCollapseHeader extra={`(DMF ${_t("to")} ${importCase.client?.name || "-"})`}>
            <Badge dot color={isBuyerRelationDone ? "green" : "orange"} />
            {_t("buyer_relation")}
          </CustomCollapseHeader>
        }
      >
        <ImportBuyerRelation />
      </Panel>
      <Panel
        key="2"
        header={
          <CustomCollapseHeader extra={`(${importCase.merchant?.name || "-"} ${_t("to")} DMF)`}>
            <Badge dot color={isMerchantRelationDone ? "green" : "orange"} />
            {_t("merchant_relation")}
          </CustomCollapseHeader>
        }
      >
        <ImportMerchantRelation />
      </Panel>
      <Panel
        key="3"
        header={
          <CustomCollapseHeader extra={`(${pickupSelf ? _t("pickup_self") : appConfig("importAgency")})`}>
            <Badge dot color={isTransportDone || pickupSelf ? "green" : "orange"} /> {_t("transport")}
          </CustomCollapseHeader>
        }
      >
        <ImportTransportActions />
      </Panel>
      <Panel
        key="4"
        header={
          <CustomCollapseHeader>
            {_t('other_information')}
          </CustomCollapseHeader>
        }>
        <ImportOtherActions />
      </Panel>
    </CustomCollapse>
  );
};

export default ImportAfterOrderActions;

export const importAfterOrderActionsStatus = (flags: ImportCaseType['flags'], expectedDeliveryDate: ImportCaseType['expectedDeliveryDate']) => {
  const {
    purchaseContractSigned,
    entryCertificateSigned,
    takeHomeAgreementSigned,
    paid,
    transportOrdered,
    vehiclePaid,
    vehiclePurchaseContractRequested,
    vehiclePurchaseContractSigned,
  } = flags;


  const isBuyerRelationDone = purchaseContractSigned && entryCertificateSigned && paid && takeHomeAgreementSigned;
  const isMerchantRelationDone = vehiclePaid && vehiclePurchaseContractRequested && vehiclePurchaseContractSigned;
  const isTransportDone = transportOrdered && !!expectedDeliveryDate;
  return {
    isBuyerRelationDone,
    isMerchantRelationDone,
    isTransportDone
  }
}
