import { FC } from "react";
import { Menu } from "antd";
import { EnumItemType } from "../types/appTypes";

interface StatusTabsProps {
  statuses: EnumItemType[];
  activeTabId: string;
  onTabChange: (key: string) => void;
}

const StatusTabs: FC<StatusTabsProps> = ({ statuses, activeTabId, onTabChange }) => {
  return (
    <Menu
      onClick={({ key }) => onTabChange(key)}
      selectedKeys={[`${activeTabId}`]}
      mode="horizontal"
      className="custom-menu-horizontal border-bottom-0 text-small"
    >
      {Object.values(statuses).map((st) => (
        <Menu.Item key={`${st.id}`}>{st.title}</Menu.Item>
      ))}
    </Menu>
  );
};

export default StatusTabs;
