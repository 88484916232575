import { useEffect, useState } from "react";
import { Checkbox, Form, Modal, Typography } from "antd";
import { AcceptOfferFormType, TaxCase } from "../../../../../types/taxTypes";
import { UploadFile } from "antd/lib/upload/interface";
import { renderErrorMessage } from "../../../../../components/messages/errorMessage";
import { acceptTaxOffer, payWithQuickpay, payWithStripe, taxFileUrl } from "../../../../../services/taxService";
import { isValidLink } from "../../../../../utilities/readLink";
import DividerLine from "../../../../../components/lineSplit";
import format from "../../../../../utilities/formatNumbers";
import _t from "../../../../../lang/translate";
import PaymentMethod from "../../../../../components/paymentDetails/paymentMethod";
import ChangePaymentMethod from "../../../../../components/subscription/changePaymentMethod";
import DocumentsUpload from "../../../../../components/documentsUpload";
import ServerSelectBox from "../../../../../components/serverSelectBox";
import { billingDestinationsAutoComplete } from "../../../../../services/autocompleteService";
import TaxCaseStore from "../../../../../contexts/taxCaseStore";
import UserStore from "../../../../../contexts/userStore";
import eventTracker from "../../../../../services/eventsTrackerService";
import { CalculatePaymentOutput } from "../../calc/metaCalculators";
import { PaymentType } from "../../../../../types/paymentCardType";



const { Text, Paragraph } = Typography;
type TaxAcceptOfferModalProps = {
  paymentType: PaymentType;
  paymentCalculation: CalculatePaymentOutput;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: () => void;
  onSuccess: () => void;
}
type FormType = AcceptOfferFormType & {
  status2: boolean;
}

export default function TaxAcceptOfferModal({ paymentCalculation, paymentType, visible, onCancel, onSuccess }: TaxAcceptOfferModalProps) {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const { user, hasPermission } = UserStore.useContainer()
  const [tmpForm] = Form.useForm<FormType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile<any>[]>(taxCase.files || []);
  const [isValidForm, setIsValidForm] = useState(false);

  const canUpdateBillingDestination = hasPermission("dealer.update_billing_destination");
  const dealerId = taxCase.client?.dealerId;
  const userId = taxCase.client?.userId;
  const termsLink = taxCase.links?.terms;
  const totalPrice = paymentCalculation.totalWithCardFees;

  const submitForm = () => tmpForm.submit();
  const handleAccept = async (values: FormType) => {
    try {
      setLoading(true);
      let data: TaxCase | undefined;
      // switch (paymentType) {
      //   case "invoice": data = await payInvoice(values); break;
      //   case "quickpay": await payQuickpay(); break;
      //   case "stripe": data = await payStripe(); break;
      // }
      if (paymentType !== 'invoice') {
        throw new Error("Der er sket en fejl. Der skal betales med faktura.")
      }
      data = await payInvoice(values)
      if (data) {
        setTaxCase(data)
      }
      onSuccess()
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  const payInvoice = async (values: FormType) => {
    const options: AcceptOfferFormType = {
      status: values.status,
      billingDestinationId: values.billingDestinationId
    }
    const { data } = await acceptTaxOffer(taxCase.id, options);
    eventTracker.register("tax_case_accepted");
    return data;
  }

  // const payQuickpay = async (): Promise<void> => {
  //   const { data } = await payWithQuickpay(taxCase.id);
  //   const quickpayLink = data.data.external;
  //   if (isValidLink(quickpayLink)) {
  //     eventTracker.register("tax_case_accepted");
  //     window.location.replace(quickpayLink)
  //   } else {
  //     throw new Error("Invalid quickpay link")
  //   }
  // };

  // const payStripe = async () => {
  //   const { data } = await payWithStripe(taxCase.id, { userId: userId });
  //   eventTracker.register("tax_case_accepted");
  //   return data;
  // };

  useEffect(() => {
    setTaxCase({ ...taxCase, files: uploadedFiles });
  }, [uploadedFiles])

  const isFormValid = (values: FormType) => {
    const termsStatus = values.status;
    const termsStatus2 = values.status2;
    setIsValidForm(termsStatus && termsStatus2);
  }
  function handleFormValuesChange(_changedValues: Partial<FormType>, values: FormType) {
    isFormValid(values)
  }

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={submitForm}
      okText={getSubmitButtonText(paymentType)}
      okButtonProps={{
        type: "primary",
        disabled: !isValidForm
      }}
      title={getModalTitle(paymentType)}
      width={600}
    >
      <Form
        form={tmpForm}
        initialValues={{ billingDestinationId: -1 }}
        requiredMark={false}
        layout="vertical"
        onFinish={handleAccept}
        onValuesChange={handleFormValuesChange}
      >
        <div className="d-flex justify-content-between">
          <strong className="text-dark">{_t("amount_to_pay")}</strong>
          <strong className="text-dark">{format.price(totalPrice)}</strong>
        </div>
        <DividerLine fluid />

        {paymentType === 'stripe' && (<>
          <div className="bg-light border-radius p-1 d-flex justify-content-between align-items-center">
            <PaymentMethod paymentMethod={user?.paymentMethod} />
          </div>
          <div className="mt-1">
            <ChangePaymentMethod type="default">{_t("update", "payment_method")}</ChangePaymentMethod>
          </div>
          <DividerLine fluid />
        </>)}

        <Paragraph className="mt-2" strong>
          {_t("documents_to_upload")}:
        </Paragraph>
        <ul className="pl-2">
          <li><Text>{_t("registration_certificate_part_one_and_two")}</Text></li>
          <li><Text>{_t("sight_report")}</Text></li>
          <li><Text>{_t("mileage_documentation")}</Text></li>
          <li><Text>{_t("special_use_documentation")}</Text></li>
        </ul>
        <Paragraph className="mb-05 mt-2" type="secondary">
          {_t('upload', 'documents')}
        </Paragraph>
        <div className="mb-1 accept-case-upload-wrapper">
          <DocumentsUpload
            fileList={uploadedFiles}
            canLockFiles={false}
            onChange={({ fileList }) => setUploadedFiles(fileList)}
            action={taxFileUrl(taxCase?.id)}
          />
        </div>
        {paymentType === 'invoice' && (<>
          <DividerLine fluid />
          <Form.Item shouldUpdate noStyle>
            {({ setFieldsValue }) => (
              <Form.Item name="billingDestinationId" label={_t("billing_destination")} rules={[{ required: true }]}>
                <ServerSelectBox
                  disabled={loading}
                  addModalType={canUpdateBillingDestination ? "addBillingDestination" : null}
                  onItemAdded={(_, id) => typeof id === "number" && setFieldsValue({ billingDestinationId: id })}
                  addModalExtraValues={{
                    parentId: dealerId,
                  }}
                  placeholder={_t("billing_destination")}
                  filters={`filter[dealerId]=${dealerId}`}
                  apiUrl={billingDestinationsAutoComplete()}
                />
              </Form.Item>
            )}
          </Form.Item>
        </>
        )}

        <DividerLine fluid />

        <Form.Item name="status" valuePropName="checked">
          <Checkbox disabled={loading}>
            Jeg bekræfter at have læst og accepteret de &nbsp;
            <a href={termsLink || ""} rel="noreferrer" target="_blank">
              “Generelle forretningsbetingelser for værdifastsættelse af køretøjer ved Dansk Motor Finans A/S”
            </a>
          </Checkbox>
        </Form.Item>
        <Form.Item name="status2" valuePropName="checked">
          <Checkbox disabled={loading}>
            {_t("i_give_dmf_power_of_attorney_for_valuation")}
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}


const getSubmitButtonText = (paymentType: PaymentType) => {
  switch (paymentType) {
    case "invoice": return _t("accept", "and") + " " + _t("request_invoice").toLowerCase();
    case "quickpay": return _t("go", "to") + " " + _t("payment").toLowerCase();
    default: return _t("pay", "registration_fee");
  }
}

const getModalTitle = (paymentType: PaymentType) => {
  switch (paymentType) {
    case "invoice": return _t("request_invoice_payment_of_registration_fee");
    default: return _t("pay", "registration_fee");
  }
}
