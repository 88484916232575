import { Button, Modal } from "antd";
import { FC, useState } from "react";
import Icon from "../../../../assets/icons/icon";
import Details from "../../../../components/detailsList";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { openFileLink } from "../../../../services/mediaService";
import { date } from "../../../../utilities/date";

const LeasingAdminContractsInfo: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { customer } = leasingCase
  const { agreements, insuranceCompany, secondaryDrivers } = leasingCase?.contract || {};
  const { expectedStartDate, extraAgreements } = agreements || {};
  const disabled = !leasingCase.flags.contractGenerated;
  const generatedDocuments = leasingCase.contract?.documents

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  function copyTrackerInformation() {
    let string =  _t("contact_person") + ", " + _t("name") + " -\t\t"+agreements?.gpsContactPersonName + "\r\n";
    string+=      _t("contact_person") + ", " + _t("phone_short") + " -\t\t"+agreements?.gpsContactPersonPhone + "\r\n";
    string+=      _t("address") + " -\t\t\t"+agreements?.gpsContactPersonAddress + "\r\n";
    string+=      _t("zip") + " & " + _t("city") + " -\t\t\t"+agreements?.gpsContactPersonZipCity + "\r\n";
    string+=      "Ønske til dato for montering -\t"+date(agreements?.gpsInstallDate || "");
    window.navigator.clipboard.writeText(string)
  }

  return (
    <>
      <Button icon={<Icon size="large" name="document-text-outline" />} style={{ display: "block" }} disabled={disabled} onClick={openModal}>
        {_t("contract", "details")}
      </Button>
      <Modal width={600} bodyStyle={{ maxHeight: "80vh", overflow: "auto" }} visible={isVisible} onCancel={closeModal} title={_t("contract", "details")} footer={null}>
        <Details borderLess className="m-0">
          <Details.Title bold className="mt-0 text-dark">Information</Details.Title>
          <Details.Item label={_t("insurance_company")} value={insuranceCompany?.name} />
          <Details.Item label={_t("PBS_reg_nr")} value={customer?.pbsRegNumber} />
          <Details.Item label={_t("PBS_account_nr")} value={customer?.pbsAccountNumber} />
          <Details.Item label={_t("expected_start_date")} value={date(expectedStartDate || "")} />
          <Details.Item label={<span style={{textDecoration:"underline", textDecorationStyle: "dashed"}} title={_t("extra_agreements")}>Er der aftalt yderligere med kunden...</span>} value={extraAgreements} />

          <Details.Divider />
          <Details.Title bold className="mt-0 text-dark">Registrering</Details.Title>
          <Details.Item label={"Registreringsform"} value={agreements?.registrationType} />
          <Details.Item label={"Hvem foretager registrering?"} value={agreements?.registrationHandler} />
          <Details.Item label={"Hvor afhentes der nummerplader?"} value={agreements?.numberPlateSource} />
          {agreements?.registrationType === "Nyregistrering" && (
            <>
              <Details.Item label={"Nummerpladetype"} value={agreements?.numberPlateType} />
              <Details.Item label={"Nummerplademærkning"} value={agreements?.numberPlateMarking} />
            </>
          )}
          <Details.Item label={"Evt. note til registrering af køretøjet"} value={agreements?.registrationNotes} />

          <Details.Divider />
          <Details.Title bold className="mt-0 text-dark">Brugerregistrering</Details.Title>
          {secondaryDrivers?.map((driver, i) => (
            <div key={i}>
              <p className="mb-03">{_t("secondary_user")} {i + 1}</p>
              <Details.Item label={_t("name")} value={driver.name} />
              <Details.Item label={_t("cpr", "number")} value={driver.cpr} />
              <Details.Item label={_t("phone")} value={driver.phone} />
              <Details.Item label={_t("email")} value={driver.email} />
            </div>
          ))}

          <Details.Divider />
          <Details.Title bold className="mt-0 text-dark">Tracker</Details.Title>
          <Details.Item label={"Skal der monteres tracker i bilen?"} value={agreements?.gpsInstallationPreference} />
          {agreements?.gpsInstallationPreference === "Ja" && (
            <>
              <Details.Item label={_t("contact_person") + ", " + _t("name")} value={agreements?.gpsContactPersonName} />
              <Details.Item label={_t("contact_person") + ", " + _t("phone_short")} value={agreements?.gpsContactPersonPhone} />
              <Details.Item label={_t("address")} value={agreements?.gpsContactPersonAddress} />
              <Details.Item label={_t("zip") + " & " + _t("city")} value={agreements?.gpsContactPersonZipCity} />
              <Details.Item label={"Ønske til dato for montering"} value={date(agreements?.gpsInstallDate || "")} />
              <div style={{display:"flex", justifyContent:"flex-end"}}>
                <Button style={{textAlign:"right"}} onClick={copyTrackerInformation}>{_t("copy")}</Button>
              </div>
            </>
          )}

          <Details.Divider />
          <Details.Title bold className="mt-0 text-dark">Køb af bil</Details.Title>
          <Details.Item label={"Anskaffelse af bil"} value={agreements?.carPurchasePreference} />
          {agreements?.carPurchasePreference === "DMF skal købe køretøjet" && (
            <>
              <Details.Item label={_t("name")} value={agreements?.carPurchaseName} />
              <Details.Item label={_t("cvr")} value={agreements?.carPurchaseCvr} />
              <Details.Item label={_t("contact_person") + ", " + _t("name")} value={agreements?.carPurchaseContactPersonName} />
              <Details.Item label={_t("contact_person") + ", " + _t("email")} value={agreements?.carPurchaseContactPersonEmail} />
              <Details.Item label={"Eventuelt dato for indfrielse"} value={date(agreements?.carPurchaseRedemptionDate || "")} />
            </>
          )}

          <Details.Divider />
          <Details.Title bold className="mt-0 text-dark">Valg af kontrakter</Details.Title>
          <div>
            {generatedDocuments?.map((doc) => (
              <Button
                key={doc.id}
                type="link"
                className="pl-0 pr-0 text-left"
                block
                icon={<Icon name="download-outline" />}
                onClick={() => openFileLink(doc.url)}
              >
                {doc.name}
              </Button>
            ))}

          </div>

        </Details>
      </Modal>
    </>

  );
};

export default LeasingAdminContractsInfo;
