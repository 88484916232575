import { message } from "antd";
import { FC } from "react";
import SwitchInput from "../../../components/form/switchInput";
import UserPageStore from "../../../contexts/userPageStore";
import _t from "../../../lang/translate";
import { banUser } from "../../../services/userService";
import { isAxiosError } from "../../../utilities/typeGuard";

const UserAdminTools: FC = () => {
  const { user, setUser, loading, setLoading } = UserPageStore.useContainer();

  const handleBanChange = async (banned: boolean) => {
    try {
      setLoading(true);
      await banUser(user.id, banned);
      setUser({ ...user, banned });
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <SwitchInput
      color="red"
      label={_t("ban_user")}
      loading={loading}
      checked={user.banned}
      onChange={handleBanChange}
    />
  );
};

export default UserAdminTools;
