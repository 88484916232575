import { FC, useState } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { openFileLink } from "../../../services/mediaService";
import { updateAndDownloadBailDocumentUrl } from "../../../services/leasingService";
import ErrorMessage from "../../../components/messages/errorMessage";
import Icon from "../../../assets/icons/icon";
import consts from "../../../utilities/consts";
import { LeasingCaseBaileeType } from "../../../types/leasingTypes";

const LeasingBaileeForm: FC = () => {
  return (
    <>
      <Form.Item
        name={"name"}
        rules={[{ required: true }]}
        label={_t('name')}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={_t("cvr_or_cpr")}
        name={"gid"}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"address"}
        rules={[{ required: true }]}
        label={_t('address')}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"zip"}
        rules={[{ required: true }]}
        label={_t('zip')}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"city"}
        rules={[{ required: true }]}
        label={_t('city')}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"phone"}
        rules={[{ required: true }]}
        label={_t('phone')}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={"email"}
        rules={[{ required: true }]}
        label={_t('email')}
      >
        <Input />
      </Form.Item>
    </>
  );
};

const LeasingGenerateBailDocument: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [updateBaileeForm] = Form.useForm<LeasingCaseBaileeType>();
  const { bailee } = leasingCase;

  const handleBailDocument = async () => {
    const values: LeasingCaseBaileeType = updateBaileeForm.getFieldsValue(true);

    try {
      setLoading(true);
      await openFileLink(updateAndDownloadBailDocumentUrl(leasingCase.id), 'post', values);
    } catch ({ response }) {
      message.error(<ErrorMessage message={response?.data?.message} />);
    } finally {
      setLoading(false);
    }
  };

  const disabled = !leasingCase.flags.validationStarted;

  return (
    <>
      <Button loading={loading} disabled={disabled} onClick={() => setShowModal(true)}>
        <Icon name="download-outline" size="large" /> {_t("bailDocument")}
      </Button>

      <Modal
        confirmLoading={loading}
        width={800}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        title={_t("bailDocument")}
        okText={_t("save_and_download")}
        onOk={updateBaileeForm.submit}
      >
        <Form
          {...consts.formItemProps}
          form={updateBaileeForm}
          requiredMark={false}
          onFinish={handleBailDocument}
          initialValues={{ ...bailee }}
        >
          <LeasingBaileeForm />
        </Form>
      </Modal>
    </>
  );
};

export default LeasingGenerateBailDocument;
