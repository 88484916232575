import { Result } from "antd";
import TaxCaseStore from "../../../../../contexts/taxCaseStore";
import PaymentMethod from "../../../../../components/paymentDetails/paymentMethod";
import { TaxCase } from "../../../../../types/taxTypes";
import PaymentCardDetails from "../../../../../components/paymentDetails/PaymentCardDetails";
import TaxDownloadInvoiceBtn from "../taxDownloadInvoiceBtn";
import _t from "../../../../../lang/translate";

export default function TaxCardPaymentFlow() {
  const { taxCase } = TaxCaseStore.useContainer();

  if (taxCase.flags.paid) {
    return <CasePaid taxCase={taxCase} />
  }

  if (taxCase.flags.accepted && !taxCase.flags.paid) {
    return (
      <Result
        status="warning"
        title="Sagen er accepteret men ikke betalt"
      />
    )
  }

  return (
    <>
      <Result
        title="Noget er gået galt"
        subTitle="Kontakt venligts en administrator."
        status={"warning"}
      />
    </>
  )
}

function CasePaid({ taxCase }: { taxCase: TaxCase }) {
  const { paymentDetails, log } = taxCase;
  const paymentMethod = {
    lastFour: paymentDetails?.metadata?.lastFour || null,
    type: paymentDetails?.metadata?.cardType || null
  }

  return (<>
    <Result
      className="bg-light border-light border-radius"
      title="Betalt!"
      subTitle="Vi sørger nu for værdifastsættelse af køretøjet i DMR, og du vil hurtigst muligt modtage en kvittering herpå."
      status={"success"}
    />
    <div className="mt-1">
      <h4>Du har betalt med følgende kort:</h4>
      <div className="p-1 bg-light">
        <PaymentMethod paymentMethod={paymentMethod} />
      </div>
      <PaymentCardDetails
        paymentDate={log?.paid?.date}
        paymentId={paymentDetails?.paymentId}
        paymentPerson={log?.paid?.person.fullName}
        footer={
          <div className="d-flex justify-content-end mt-1">
            <TaxDownloadInvoiceBtn>
              {_t("receipt")}
            </TaxDownloadInvoiceBtn>
          </div>
        }
      />
    </div>
  </>)
}
