import React, {FC, useEffect, useState} from "react";
import {Button, Result} from "antd";
import _t from "../lang/translate";
import {Redirect, useHistory} from "react-router-dom";
import UserStore from "../contexts/userStore";

interface NotFoundProps {
  title?: string;
  location?: { state: any }
}

const NotFound: FC<NotFoundProps> = ({title = _t("msg.not_found"), location}) => {
  const {hasPermission, permissions} = UserStore.useContainer();
  const [redirectBack, setRedirectBack] = useState<boolean>(false)
  const history = useHistory();


  const expectedPermission = location?.state?.from?.permission;
  const wantedPathTo = location?.state?.from?.pathname;

  useEffect(() => {
    if (expectedPermission && wantedPathTo) {
      // Check user has the permission
      if (hasPermission(expectedPermission)) {
        setRedirectBack(true)
      }
    }
  }, [expectedPermission, hasPermission, wantedPathTo, permissions]);

  return redirectBack ? (
    <Redirect to={wantedPathTo}/>
  ) : (
    <Result
      status="404"
      title={title}
      subTitle={_t("msg.not_found_description")}
      extra={<Button onClick={() => history.goBack()}>{_t("go_back")}</Button>}
    />
  );
};

export default NotFound;
