import { FC } from "react";
import CustomerStore from "../../../contexts/customerStore";
import _t from "../../../lang/translate";

const CustomerProspectId: FC = () => {
  const { customer } = CustomerStore.useContainer();
  const { prospectId, leasingProspectId } = customer;
  return prospectId || leasingProspectId ? (
    <label className="pt-05 d-block">
      {(prospectId || "") +
        (prospectId && leasingProspectId ? " - " : "") +
        (leasingProspectId ? `${_t("leasing")}: ${leasingProspectId}` : "")}
    </label>
  ) : null;
};

export default CustomerProspectId;
