import moment from "moment";
import { IdType } from "./appTypes";

export interface PostsType {
  id: IdType;
  title: string | null;
  content: string | null;
  isArchived: boolean;
  isUrgent: boolean;
  date: string | null;
  createdAt: string | null;
}

export const initPost: PostsType = {
  id: 0,
  title: null,
  content: null,
  isArchived: false,
  isUrgent: false,
  date: moment().toISOString(),
  createdAt: null,
};

export interface PostType {
  id: IdType;
  title: string | null;
  content: string | null;
  isArchived: boolean;
  isUrgent: boolean;
  date: string | null;
  createdAt: string | null;
}
