import React, { FC, useEffect } from "react";
import format from "../../../../utilities/formatNumbers";

interface TaxCollateralAmountInputProps {
  value?: number | null;
  onChange?: (v: number | null) => void;
  optRegFee?: number | null;
  preRegFee?: number | null;
}

const TaxCollateralAmountInput: FC<TaxCollateralAmountInputProps> = ({ value, onChange, optRegFee, preRegFee }) => {
  useEffect(() => {
    if (typeof optRegFee === "number") {
      const newCollateral = typeof preRegFee === "number" ? preRegFee - optRegFee : 0;
      onChange && onChange(newCollateral);
    }
  }, [optRegFee, preRegFee]);

  return <span>{format.price(value || 0)}</span>;
};

export default TaxCollateralAmountInput;
