import { FC } from "react";
import { Button } from "antd";
import Icon from "../../../../assets/icons/icon";
import openModal from "../../../../components/modal/openModal";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";

const ImportCollectionAuthorizationBtn: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();

  const handleDownload = async () => {
    try {
      const { id, collectionAuthorizationCode, vehicle } = importCase;
      const vin = vehicle?.vin;
      const defaultCollectionAuthorizationCode = collectionAuthorizationCode || `${vin ? vin + " / " : ""}${id}`;
      const data = await openModal<any>("ImportCollectionAuthorization", {
        id,
        collectionAuthorizationCode: defaultCollectionAuthorizationCode,
        pickupSelf: importCase.flags.pickupSelf,
        pickupInfo: importCase.pickupInfo,
      });
      if (data.collectionAuthorizationCode)
        setImportCase({ ...importCase, collectionAuthorizationCode: data.collectionAuthorizationCode });
      if (data.url) window.open(data.url, "_blank");
    } catch (error) {
    }
  };

  const downloadIcon = <Icon size="large" name="download-outline" />;

  return (
    <Button type="primary" icon={downloadIcon} ghost onClick={handleDownload}>
      {_t("collection_authorization")}
    </Button>
  );
};

export default ImportCollectionAuthorizationBtn;
