import { Popover, Space, Typography } from "antd";
import { FC } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Icon from "../assets/icons/icon";
import StatusTabs from "../components/statusTabs";
import StatusTag from "../components/statusTag";
import { CustomColumn, CustomColumnsType } from "../components/table/dataTableTypes";
import UserStore from "../contexts/userStore";
import useActiveStatus from "../hooks/useActiveStatus";
import _t from "../lang/translate";
import PageTitle from "../layout/pageTitle";
import { getPath } from "../routes/appRoutes";
import { getLeasingStatuses } from "../services/leasingService";
import { pageKey, statusKey } from "../services/urlQueryService";
import { ScreenProps } from "../types/appTypes";
import { LeasingCaseListType } from "../types/leasingTypes";
import format from "../utilities/formatNumbers";
import { constructURL } from "../utilities/urlFunctions";
import CreateLeasingActions from "./leasing/createLeasingActions";
import LeasingCases from "./leasing/leasingCases";
import LeasingTableFilter from "./leasing/leasingTableFilter";
import LeasingTableStatistics from "./leasingTableStatistics";

const Leasing: FC<ScreenProps> = ({ title }) => {
  const history = useHistory();
  const location = useLocation();
  const partnerCase = !history.location.pathname.includes(getPath("leasing"));
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission(partnerCase ? "partner_leasing.isAdmin" : "leasing.isAdmin");

  const statuses = getLeasingStatuses(isAdmin);
  const [activeStatusId, setActiveStatusId] = useActiveStatus(Object.values(statuses));

  const canAccessCase = hasPermission(partnerCase ? "screen.partner_leasing" : "screen.leasing");
  const canCreate = hasPermission(partnerCase ? "partner_leasing.create" : "leasing.create");

  const columns: CustomColumnsType<LeasingCaseListType> = [
    {
      title: "#",
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 110,
      render: (text, record) => {
        const isActiveCase = record.status.id === statuses?.active.id;
        const showLink = isAdmin || !isActiveCase;
        const url = constructURL(history.location.pathname, record.id.toString());
        return showLink && canAccessCase ? (
          <Link to={url}>
            <strong>{text}</strong>
          </Link>
        ) : (
          text
        );
      },
      fixed: "left",
    },
    {
      title: _t("date"),
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: true,
      width: 190,
      render: format.dateAndTime,
    },
    {
      title: _t("dealer"),
      key: "dealerName",
      dataIndex: "dealerName",
      //sorter: true,
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            {isAdmin && record.dealerIsPriority && (
              <Icon name="star" size="small" color="lightblue" className="mr-05" />
            )}
            {text}
          </>
        );
      },
    },
    {
      title: _t("customer"),
      key: "clientName",
      dataIndex: "clientName",
      //sorter: true,
      render: (text) => (
        <Typography.Paragraph ellipsis={{ rows: 1 }} className="m-0">
          {text}
        </Typography.Paragraph>
      ),
    },
    {
      title: _t("vehicle"),
      key: "car",
      dataIndex: "car",
      ellipsis: true,
      sorter: true,
    },
    {
      title: _t("mileage_unit"),
      key: "mileage",
      dataIndex: "mileage",
      sorter: true,
      width: 110,
      render: format.milage,
    },
    {
      title: _t("firstRegDate"),
      key: "firstRegDate",
      dataIndex: "firstRegDate",
      hidden: true,
      render: format.date,
    },
    {
      title: _t("delivery_date"),
      key: "deliveryDate",
      dataIndex: "deliveryDate",
      hidden: true,
      sorter: true,
      render: format.date,
    },
    {
      title: _t("vin_short"),
      key: "vin",
      dataIndex: "vin",
      sorter: true,
      width: 185,
    },
    {
      title: _t("status"),
      key: "status",
      sorter: true,
      width: 200,
      dataIndex: "status",
      render: (status, record) => (
        <Space>
          <StatusTag status={status} size="small" />
          {typeof record.importantNote === "string" && (
            <Popover placement="top" arrowPointAtCenter content={record.importantNote}>
              <Typography.Text type="warning">
                <Icon name="information-outline" size="large" />
              </Typography.Text>
            </Popover>
          )}
        </Space>
      ),
    },
    {
      ...isAdmin && {
        title: _t("caseHandler"),
        key: "caseHandler",
        dataIndex: "caseHandler",
        ellipsis: true,
        render: (caseHandler) => caseHandler && caseHandler.fullName
      }
    },
  ];

  const getFilteredColumns = () => {
    if (activeStatusId === statuses.open.id) return columns;

    const nextColumns = [...columns].map((col) => {
      if (col.key === "createdAt" || col.key === "dealer" || col.key === "firstRegDate" || col.key === "mileage")
        col.hidden = true;

      return col;
    });

    const startDateColumn: CustomColumn<LeasingCaseListType> = {
      title: _t("start_date"),
      key: "startDate",
      dataIndex: "startDate",
      sorter: true,
      render: (date) => (date ? format.date(date) : "-"),
    };

    nextColumns.splice(1, 0, startDateColumn);

    const endDateColumn: CustomColumn<LeasingCaseListType> = {
      title: _t("end_date"),
      key: "endDate",
      dataIndex: "endDate",
      sorter: true,
      render: (date) => (date ? format.date(date) : "-"),
    };

    nextColumns.splice(nextColumns.length - 1, 0, endDateColumn);

    return nextColumns;
  };

  const handleTabChange = (nextKey?: string) => {
    const query = new URLSearchParams(location.search);
    query.delete(pageKey);
    query.delete(statusKey);
    setActiveStatusId(nextKey || "-1");
    history.push({ ...history.location, search: query.toString() });
  };

  return (
    <>
      <PageTitle
        fluid
        title={title}
        extra={
          <>
            {canCreate && <CreateLeasingActions />}
          </>
        }
      >
        {isAdmin && <LeasingTableStatistics />}
        <StatusTabs
          statuses={Object.values(statuses)}
          onTabChange={handleTabChange}
          activeTabId={`${activeStatusId}`}
        />
      </PageTitle>
      <LeasingCases
        filters={activeStatusId === statuses.open.id && <LeasingTableFilter />}
        columns={getFilteredColumns()}
        statusId={activeStatusId !== -1 ? activeStatusId : undefined}
        partnerCase={partnerCase}
      />
    </>
  );
};

export default Leasing;
