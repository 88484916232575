import { Button, Modal as AntModal, Typography } from "antd";
import React, { FC, useState } from "react";
import _t from "../../lang/translate";
import SubForm from "./form";
import { useForm } from "antd/es/form/Form";
import { SubscriptionSelectFormType } from "../../types/subscriptionTypes";
import type { ButtonType } from "antd/es/button";

const { Title } = Typography;

type ChangePaymentMethodProps = {
  children?: React.ReactNode;
  isNew?: boolean;
  type?: ButtonType
  disabled?: boolean
}

type ChangePaymentMethodModalProps = {
  children?: React.ReactNode;
  showModal: boolean;
  hideModal: () => void;
  isNew: boolean;
}

const ChangePaymentMethod: FC<ChangePaymentMethodProps> = ({ disabled, children, type, isNew }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const hideModal = () => setShowModal(false);

  return (
    <>
      <Button
        type={type || "link"}
        onClick={() => setShowModal(true)} className={`${type ? '' : 'muted p-0'}`}
        disabled={disabled}
      >
        {children || _t('change', 'payment_method')}
      </Button>

      <ChangePaymentMethodModal showModal={showModal} isNew={!!isNew} hideModal={hideModal} />
    </>
  )
}

export const ChangePaymentMethodModal: FC<ChangePaymentMethodModalProps> = ({ showModal, hideModal, isNew }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionForm] = useForm<SubscriptionSelectFormType>();

  return (
    <AntModal
      visible={showModal}
      onCancel={hideModal}
      confirmLoading={loading}
      destroyOnClose={true}
      onOk={subscriptionForm.submit}
      okText={_t((isNew ? 'add' : 'change'), 'payment_method')}
      cancelText={_t('go_back')}
      className="no-footer-border"
    >
      <Title level={3} className="text-center mt-2 pb-1">
        <span className="text-bold">{_t((isNew ? 'add' : 'change'), 'payment_method')}</span>
      </Title>

      <SubForm
        formInstance={subscriptionForm}
        newSub={null}
        requiresPaymentMethodInput={true}
        onSubmit={hideModal}
        loading={loading}
        setLoading={setLoading}
      />
    </AntModal>
  )
}

export default ChangePaymentMethod;
