import { Popover } from "antd";
import type { AbstractTooltipProps } from "antd/lib/tooltip";
import type { FC } from "react";
import * as React from "react";
import type { RenderFunction } from "antd/lib/_util/getRenderPropValue";

interface PopMaybeOverProps extends AbstractTooltipProps {
  title?: React.ReactNode | RenderFunction;
  content?: React.ReactNode | RenderFunction;
}

const PopMaybeOver: FC<PopMaybeOverProps> = ({ children, title, content }) => {
  return title || content ? (
    <Popover title={title} content={content}>{children}</Popover>
  ) : <>{children}</>;
}

export default PopMaybeOver;
