import React, { FC, ReactNode } from "react";
import { Col, Row, Space } from "antd";
import LogText from "../../../../components/logText";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import ImportAcceptCaseBtn from "./importAcceptCaseBtn";
import ImportRejectCaseBtn from "./importRejectCaseBtn";

interface ImportActionsBtnsProps {
  isBeforeOrder?: boolean;
}

const ImportActionsBtns: FC<ImportActionsBtnsProps> = ({ isBeforeOrder = false }) => {
  const { importCase } = ImportCaseStore.useContainer();
  const { rejected, completed } = importCase.flags;
  let children: ReactNode;
  if (!rejected && !completed) {
    children = (
      <Space>
        <ImportRejectCaseBtn /> {!isBeforeOrder && <ImportAcceptCaseBtn />}
      </Space>
    );
  } else if (rejected) {
    children = <LogText type="danger" logData={importCase.log.rejected} />;
  } else if (completed) {
    children = <LogText type="success" logData={importCase.log.completed} />;
  }
  return (
    <Row justify="space-between">
      <Col></Col>
      <Col>{children}</Col>
    </Row>
  );
};

export default ImportActionsBtns;
