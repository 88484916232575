import { FC } from "react";
import { valueType } from "antd/lib/statistic/utils";
import { isNum } from "../../utilities/typeGuard";
import { InputPriceProps } from "./inputNum";
import InputPrice from "./inputPrice";

const InputPercentage: FC<InputPriceProps> = ({ value, precision = 2, onChange, ...rest }) => {
  const formatedValue = isNum(value) ? (value * 100).toFixed(precision) : value;

  const handleChange = (nextValue: valueType) => {
    if (!onChange) return;
    onChange(isNum(nextValue) ? parseFloat((nextValue / 100).toFixed(2 + precision)) : nextValue);
  };

  return <InputPrice value={formatedValue} onChange={handleChange} decimalSize={2} suffix="%" {...rest} />;
};

export default InputPercentage;
