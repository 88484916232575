import { FC, useState } from "react";
import { Alert, Col, Row } from "antd";
import LeasingStore from "../../../contexts/leasingStore";
import LeasingVehicleCard from "./leasingVehicleCard";
import LeasingCustomerCard from "./leasingCustomerCard";
import LeasingAdminCard from "./admin/leasingAdminCard";
import LeasingEntriesCard from "./leasingEntriesCard";
import LeasingActionsCard from "./leasingActionsCard";
import LeasingAfterRegisterCard from "./partner/leasingAfterRegisterCard";
import LeasingAttachmentsCard from "./leasingAttachmentsCard";
import LeasingProcess from "./leasingProcess";

const LeasingCaseContent: FC = () => {
  const { leasingCase, isAdmin } = LeasingStore.useContainer();
  const { importantNote, partnerCase } = leasingCase;
  const [savedStatus, setSavedStatus] = useState<boolean>(true);

  return (
    <Row align="top" gutter={24} wrap>
      <Col span={24}>
        <LeasingProcess />
      </Col>
      <Col flex="1 1 25%" xxl={{ order: 1 }} xl={{ order: 3 }} xs={{ order: 3 }}>
        <LeasingVehicleCard />
        <LeasingCustomerCard />
      </Col>
      <Col flex="1 1 45%" xxl={{order: 2 }} xl={{ order: 1 }} xs={{ order: 1 }}>
        <LeasingEntriesCard savedStatus={savedStatus} setSavedStatus={setSavedStatus} />
        {isAdmin && leasingCase.id > 0 && <LeasingAdminCard />}
      </Col>
      <Col flex="1 1 25%" xxl={{ order: 3 }} xl={{ order: 2 }} xs={{ order: 2 }}>
        {importantNote && <Alert className="mb-2" message={importantNote} type="warning" closable />}
        <LeasingActionsCard inputSavedStatus={savedStatus} />
        {partnerCase && <LeasingAfterRegisterCard />}
        <LeasingAttachmentsCard />
      </Col>
    </Row>
  );
};

export default LeasingCaseContent;
