import { FC, ReactText } from "react";
import { SelectValue } from "antd/lib/select";
import { useHistory } from "react-router-dom";
import EnumSelect from "../../components/form/enumSelect";
import _t from "../../lang/translate";
import { getImportStatuses } from "../../services/importService";
import { pageKey, statusKey } from "../../services/urlQueryService";
import { EnumItemType } from "../../types/appTypes";

const ImportTableFilter: FC = () => {
  const history = useHistory();
  const statuses = getImportStatuses();
  const initStatusFilter = new URLSearchParams(history.location.search).get(
    statusKey
  ) as ReactText;

  const filterByContext = (data: EnumItemType[]) =>
    data?.filter(
      (item) =>
        item?.contexts?.includes("import") &&
        item?.id !== statuses?.completed?.id &&
        item?.id !== statuses?.rejected?.id
    );

  const onStatusFilterChange = (id?: SelectValue) => {
    const query = new URLSearchParams(history.location.search);
    query.set(pageKey, "1");
    if (!id) query.delete(statusKey);
    else query.set(statusKey, id.toString());

    history.push({
      ...history.location,
      pathname: history.location.pathname,
      search: query.toString(),
    });
  };

  return (
    <EnumSelect
      dataFilter={filterByContext}
      nameKey={["status"]}
      value={initStatusFilter !== null ? Number(initStatusFilter) : undefined}
      className="width-180"
      showSearch={false}
      placeholder={_t("status", "filter")}
      allowClear
      onChange={onStatusFilterChange}
    />
  );
};

export default ImportTableFilter;
