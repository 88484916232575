import React, { useState } from "react";
import { Button, Form, Space } from "antd";
import _t from "../../lang/translate";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { ResellerTaxCustomerType } from "../../types/resellerTaxTypes";
import { updateResellerTaxCaseCustomerInfo } from "../../services/resellerTaxService";
import LoadingContainer from "../loadingContainer";
import CustomerInfoFormItems from "../customerInfoFormItems";
import { renderErrorMessage } from "../messages/errorMessage";

export default function UpdateResellerTaxCustomerInfo<T extends object>(
  {
    onSuccess,
    onCancel,
    payload
  }: OpenModalProps<T>) {
  const [tempForm] = Form.useForm<ResellerTaxCustomerType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id, initialValues } = payload;
  const handleSubmit = async (values: ResellerTaxCustomerType) => {
    setLoading(true);
    try {
      const { data } = await updateResellerTaxCaseCustomerInfo(id, values);
      onSuccess(data);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={tempForm} initialValues={initialValues} requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <h2 className="modal-title">{_t("customer_info")}</h2>
      <LoadingContainer loading={loading}>

        <CustomerInfoFormItems namePrefix={null} />

        <div className="text-right">
          <Space>
            <Button type="ghost" onClick={onCancel} disabled={loading}>
              {_t("cancel")}
            </Button>

            <Button htmlType="submit" type="primary" loading={loading}>
              {_t("save")}
            </Button>
          </Space>
        </div>
      </LoadingContainer>
    </Form>
  );
}

assertFC(UpdateResellerTaxCustomerInfo);
