import { FC } from "react";
import _t from "../../../lang/translate";
import { LeasingInsuranceCompanyType } from "../../../types/leasingTypes";

interface LeasingInsuranceCompanyViewProps extends React.HTMLAttributes<HTMLDivElement> {
  insuranceCompany?: LeasingInsuranceCompanyType | null;
}

const LeasingInsuranceCompanyView: FC<LeasingInsuranceCompanyViewProps> = ({ insuranceCompany, ...rest }) => {
  return (
    <div {...rest}>
      <small>{_t("chose_insurance_company")}</small>
      <br />
      <span className="text-dark pr-05">{insuranceCompany?.name || "-"}</span>
      <label>
        ({_t("cvr")} {insuranceCompany?.cvr || "-"})
      </label>
    </div>
  );
};

export default LeasingInsuranceCompanyView;
