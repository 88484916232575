import { Button, Form, Space, Switch } from "antd";
import _t from "../../lang/translate";
import { exportDealers } from "../../services/systemService";
import { ExportDealersType } from "../../types/dealerTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { renderErrorMessage } from "../messages/errorMessage";

export default function ExportDealers<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<ExportDealersType>();

  const handleAccept = async (values: ExportDealersType) => {
    try {
      const { data } = await exportDealers(values);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="horizontal" initialValues={payload} onFinish={handleAccept}>
      <h2 className="modal-title">{_t("clone_case")}</h2>
      <div className="mb-1">
        <Form.Item noStyle name="active" valuePropName="checked">
          <Switch />
        </Form.Item>
        &nbsp; {_t("active")}
      </div>
      <div className="mb-1">
        <Form.Item name="inactive" valuePropName="checked" noStyle>
          <Switch />
        </Form.Item>{" "}
        &nbsp;{_t("inactive")}
      </div>
      <div className="mb-1">
        <Form.Item name="disabled" valuePropName="checked" noStyle>
          <Switch />
        </Form.Item>{" "}
        &nbsp;{_t("disactive")}
      </div>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" type="primary">
            {_t("accept")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ExportDealers);
