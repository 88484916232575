import { Spin } from "antd";
import { FC, useState } from "react";
import DealerSettingsForm from "./dealerSettingsForm";
import DealerSettingsInfo from "./dealerSettingsInfo";

const DealerAdminSettings: FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <Spin spinning={loading}>
      {isEditing ? (
        <DealerSettingsForm stopEditing={stopEditing} setLoading={setLoading} />
      ) : (
        <DealerSettingsInfo startEditing={startEditing} />
      )}
    </Spin>
  );
};

export default DealerAdminSettings;
