import { Button, Empty, message, Popconfirm, Result } from "antd";
import { FC, useState } from "react";
import Icon from "../../../assets/icons/icon";
import CollapseCard from "../../../components/collapseCard";
import DividerLine from "../../../components/lineSplit";
import openModal from "../../../components/modal/openModal";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import { deleteDepartment, departmentUrl } from "../../../services/dealerService";
import { useApi } from "../../../services/useApi";
import { DepartmentType } from "../../../types/dealerTypes";
import { isAxiosError } from "../../../utilities/typeGuard";

const DealerDepartmentCard: FC = () => {
  const { dealer, loading } = DealerStore.useContainer();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [{ data, isLoading, isError }, _, setData] = useApi<{ data: DepartmentType[] } | null>(
    departmentUrl(dealer.id),
    null
  );

  const handleAdd = async () => {
    try {
      const newDep = await openModal<DepartmentType>("addDepartments", { parentId: dealer.id });
      const oldDeps = data?.data || [];
      setData({ data: [...oldDeps, newDep] });
    } catch (e) {
    }
  };

  const handleDelete = async (id: number) => {
    try {
      setDeleteLoading(true);
      await deleteDepartment(dealer.id, id);
      const oldDeps = data?.data || [];
      const newDeps = oldDeps?.filter((cp) => cp.id !== id);
      setData({ data: newDeps });
      message.success(_t("deleted"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setDeleteLoading(false);
    }
  };

  const allLoading = isLoading || loading || deleteLoading;

  return (
    <CollapseCard title={_t("departments")} loading={allLoading} defaultOpen={false}>
      {isError && <Result status="error" title={_t("msg.unknown_error")} className="result-small" />}
      {data?.data?.map(({ id, title, invoiceEmail }) => {
        return (
          <div className="pt-05 pb-05 mb-05 drawer-item position-relative" key={id}>
            <span className="d-block text-dark text-bold ">{title}</span>
            <small>
              {_t("email")} {invoiceEmail || "-"}
            </small>
            <div className="drawer-item-actions position-absolute top-0 right-0 p-05">
              <Popconfirm
                placement="topLeft"
                onConfirm={() => handleDelete(id)}
                icon={<Icon fill="red" name="information-circle-outline" />}
                title={
                  <div>
                    {_t("msg.confirm_delete")}&nbsp;
                    <strong>{id}</strong>
                  </div>
                }
              >
                <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
              </Popconfirm>
            </div>
          </div>
        );
      })}
      {data !== null && !data?.data?.length && <Empty className="mb-1" description={_t("no_departments")} />}
      <DividerLine className="mt-05 mb-1" />
      <Button onClick={handleAdd}>
        <Icon name="add-outline" />
        {_t("add", "department")}
      </Button>
    </CollapseCard>
  );
};

export default DealerDepartmentCard;
