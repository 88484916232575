import { FC } from "react";
import { Space } from "antd";
import _t from "../../../lang/translate";
import LeasingContractsInfo from "./leasingContractsInfo";
import LeasingDownloadContractsButton from "./leasingDownloadContractsButton";
import LeasingGenerateContracts from "./leasingGenerateContracts";

const LeasingContracts: FC = () => {
  return (
    <>
      <span className="text-dark d-block text-large mb-1">{_t("contracts")}</span>
      <LeasingContractsInfo />
      <div className="text-right">
        <Space>
          <LeasingDownloadContractsButton />
          <LeasingGenerateContracts />
        </Space>
      </div>
    </>
  );
};

export default LeasingContracts;
