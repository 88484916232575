import { Space } from "antd";
import { FC } from "react";
import CardBox from "../../../../components/cardBox";
import _t from "../../../../lang/translate";
import TaxDocDocumentationBtn from "../taxDocDocumentationBtn";
import TaxDocGuaranteeBtn from "../taxDocGuaranteeBtn";

const TaxDocDownloads: FC = () => {
  return (
    <CardBox title={_t("download")} className="pb-1">
      <Space direction="vertical">
        <TaxDocDocumentationBtn />
        <TaxDocGuaranteeBtn />
      </Space>
    </CardBox>
  );
};

export default TaxDocDownloads;
