import calculateRegFee from "./regFeeCalculator";

export interface RegFeeCalcInputType {
  firstRegDate?: string | null;
  isNew: boolean;
  typeId?: number | null;
  taxablePrice?: number | null;
  tradePrice?: number | null;
  newPrice?: number | null;
  isHighWeight?: boolean;
  emissionAmount?: number | null;
  emissionUnitId?: number | null;
  electricConsumption?: number | null;
  electricRange?: number | null;
}

export interface RegFeeCalcOutputType {
  typeId: number | null;
  energyType: { id: any; title: string };
  emission: number | null;
  batteryCapacity: number | null;
  valueLoss: number | null;
  batteryDeductionAmount: number | null;
  scaleOneAmount: number | null;
  scaleTwoAmount: number | null;
  grossTax: number | null;
  emissionAddon: number | null;
  bottomDeductionStandardAmount: number | null;
  taxDeductionAmount: number | null;
  lowDeductionAmount: number | null;
  taxablePrice: number | null;
  registrationFeeBeforeDeduction: number | null;
  registrationFee: number | null;
  scales: (number | null)[];

  //// Extra properties in person bil
  consts?: {
    scaleOneMax: number;
    scaleTwoMax: number;
    emission1Max: number;
    emission2Max: number;
  };
  scaleThreeAmount?: number | null;
  emissions?: (number | null)[];
}

export type RegFeeCalcFuncType = (input: RegFeeCalcInputType) => Promise<RegFeeCalcOutputType>;

export default calculateRegFee as RegFeeCalcFuncType;
