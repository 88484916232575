import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { DocumentationFilesType, initTaxDocCase, TaxDocCase } from "../types/taxDocTypes";
import { convertToUploadFile, convertToUploadFileList, isApiFile, isApiFiles } from "../utilities/convertTypes";
import UserStore from "./userStore";

interface TaxDocCaseStoreProps {
  isAdmin: boolean;
  taxDocCase: TaxDocCase;
  setTaxDocCase: (newCase: TaxDocCase) => void;
}

const useTaxDocCaseStore = (initialState?: TaxDocCase): TaxDocCaseStoreProps => {
  const [taxDocCase, setTaxDocCase] = useState<TaxDocCase>(initTaxDocCase);

  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("tax.isAdmin");

  const handleSetTaxDocCase = useCallback(
    (newCase: TaxDocCase) => {
      const { files: currFiles, documentationFiles: docFiles } = newCase;
      const { ads, vehicleList, newPrice, equipments } = docFiles || {};
      const files = currFiles && isApiFiles(currFiles) ? convertToUploadFileList(currFiles) : currFiles;
      const documentationFiles: DocumentationFilesType = {
        ads: ads && isApiFile(ads) ? convertToUploadFile(ads) : ads,
        vehicleList: vehicleList && isApiFile(vehicleList) ? convertToUploadFile(vehicleList) : vehicleList,
        newPrice: newPrice && isApiFile(newPrice) ? convertToUploadFile(newPrice) : newPrice,
        equipments: equipments && isApiFile(equipments) ? convertToUploadFile(equipments) : equipments,
      };

      setTaxDocCase({ ...newCase, files, documentationFiles });
    },
    [setTaxDocCase]
  );

  useEffect(() => {
    if (initialState) {
      handleSetTaxDocCase(initialState);
    }
  }, [initialState, handleSetTaxDocCase]);

  return {
    isAdmin,
    taxDocCase,
    setTaxDocCase: handleSetTaxDocCase,
  };
};

const TaxDocCaseStore = createContainer(useTaxDocCaseStore);

export default TaxDocCaseStore;
