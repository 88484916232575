import { FC, useEffect } from "react";
import { useHistory } from "react-router";
import _t from "../lang/translate";
import { getPath } from "../routes/appRoutes";
import { getCurrentUser } from "../services/authService";

const UnauthorizedApp: FC = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    /// Return to home page if the user is authorized:
    const user = getCurrentUser();
    if (user !== null) history.push(getPath("home"));
  }, [history]);

  return (
    <>
      {children}
    </>
  );
};

export default UnauthorizedApp;
