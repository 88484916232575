import { FC } from "react";
import { Col, Row, Typography } from "antd";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";
import DividerLine from "../../../../components/lineSplit";
import TaxDownloadOfferBtn from "./taxDownloadOfferBtn";
import moment from "moment";

const TaxOfferInfo: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const {
    registrationFee,
    licensePlateFee,
    estimatedCollateral,
    fee,
    total,
  } = taxCase.calculation || {};

  const feeAmount = fee?.fee || 0;
  const feeWithoutTax = feeAmount / 1.25;
  const feeTaxes = feeAmount - feeWithoutTax;
  const defaultFee = (taxCase.defaultFee || 0) * 1.25;
  const discount = defaultFee - (feeAmount || defaultFee);
  const expirationDate = getExpirationDateText(taxCase.log?.accepted?.date);

  return (
    <>
      <Row className="flex-wrap mb-05">
        <Col flex="auto">
          <div className="text-large text-dark">{_t("estimated", "registration_fee")}</div>
        </Col>
        <Col flex="80px" className="text-right">
          <div className="text-large text-dark">
            {format.price(registrationFee?.registrationFee)}
          </div>
        </Col>
      </Row>
      {!!estimatedCollateral?.estimatedCollateral && (
        <Row className="flex-wrap mb-05">
          <Col flex="auto">
            <h3>{_t("estimated", "collateral")}</h3>
          </Col>
          <Col flex="80px" className="text-right">
            <h3 className="mb-0">
              {format.price(estimatedCollateral?.estimatedCollateral)}
            </h3>
          </Col>
        </Row>
      )}
      <Row className="flex-wrap mb-05">
        <Col flex="auto">
          <div className="text-large text-dark">
            {_t("fee")}&nbsp;
            {discount > 0 && (
              <span className="text-lighter text-small text-italic">
                ({_t('your', 'offer_discount')} {format.price(discount)})
              </span>
            )}
          </div>
        </Col>
        <Col flex="80px" className="text-right">
          <div className="text-large text-dark">{format.price(feeAmount)}</div>
        </Col>
      </Row>
      <Row className="flex-wrap mb-05">
        <Col flex="auto">
          <div className="text-large text-dark">{_t("plade_nr_fee")}</div>
        </Col>
        <Col flex="80px" className="text-right">
          <div className="text-large text-dark">{format.price(licensePlateFee)}</div>
        </Col>
      </Row>
      <Row className="flex-wrap mb-05">
        <Col flex="auto">
          <div className="text-large text-dark text-italic">{_t("with_moms")}</div>
        </Col>
        <Col flex="80px" className="text-right">
          <div className="text-large text-dark text-italic">{format.price(feeTaxes)}</div>
        </Col>
      </Row>
      <DividerLine fluid />
      <Row className="flex-wrap mb-2">
        <Col flex="auto">
          <h3 className="mb-0">{_t("total_price")}</h3>
        </Col>
        <Col flex="80px" className="text-right">
          <h3 className="mb-0 text-bold">{format.price(total)}</h3>
        </Col>
      </Row>
      <DividerLine fluid />
      <div className="text-right">
        <Typography.Text type="secondary" className="mr-1">
          {expirationDate}
        </Typography.Text>
        <TaxDownloadOfferBtn />
      </div>
    </>
  );
};

export default TaxOfferInfo;

function getExpirationDateText(dateString: string | undefined): string {
  const expirationDate = moment(dateString).add(30, 'days');
  const daysLeft = expirationDate.diff(moment(), 'days');
  // As soon as the count down starts
  // The day difference is going to round down from 30 days, to ~~29.831 days, rounded to 29 days.
  // This will result in "0 days left" in the count down text.
  return daysLeft >= 0
    ? "Gældende i " + daysLeft + " dage"
    : "Offer udløbet";
}
