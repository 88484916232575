import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { ImportCaseType, initImportCase } from "../types/importTypes";
import { convertToUploadFileList, isApiFiles } from "../utilities/convertTypes";
import UserStore from "./userStore";

interface ImportStoreProps {
  isAdmin: boolean;
  importCase: ImportCaseType;
  setImportCase: (newCase: ImportCaseType) => void;
}

const useImportCaseStore = (initialState?: ImportCaseType): ImportStoreProps => {
  const [importCase, setImportCase] = useState<ImportCaseType>(initImportCase);
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("user.isAdmin");

  const handleSetImportCase = useCallback(
    (newCase: ImportCaseType) => {
      const files = newCase.files && isApiFiles(newCase.files) ? convertToUploadFileList(newCase.files) : newCase.files;
      setImportCase({ ...newCase, files });
    },
    [setImportCase]
  );

  useEffect(() => {
    if (initialState) {
      handleSetImportCase(initialState);
    }
  }, [initialState, handleSetImportCase]);

  return {
    isAdmin,
    importCase,
    setImportCase: handleSetImportCase,
  };
};

const ImportCaseStore = createContainer(useImportCaseStore);

export default ImportCaseStore;
