import { FC } from "react";
import CollapseCard from "../../../components/collapseCard";
import DocumentsUpload from "../../../components/documentsUpload";
import CustomerStore from "../../../contexts/customerStore";
import _t from "../../../lang/translate";
import { customerFileUrl } from "../../../services/customerService";

const CustomerAttachments: FC = () => {
  const { customer, isNew, setCustomer } = CustomerStore.useContainer();

  return (
    <CollapseCard title={_t("attachments")} disable={isNew} defaultOpen={!isNew}>
      <DocumentsUpload
        fileList={customer.files || []}
        onChange={({ fileList }) => setCustomer({ ...customer, files: fileList })}
        action={customerFileUrl(customer.id)}
      />
    </CollapseCard>
  );
};

export default CustomerAttachments;
