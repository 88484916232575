import { FC } from "react";
import { Col, Form, Row } from "antd";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";

const TaxRegestrationFeeOpt: FC = ({ ...rest }) => {
  return (
    <Row className="flex-nowrap">
      <Col flex="auto">
        <Form.Item name={["registrationFee", "registrationFee"]} label={_t("registration_fee")}>
          <InputNum min={0} className="bg-white no-border" suffix="Kr." {...rest} />
        </Form.Item>
      </Col>
      <Col flex="49px"></Col>
    </Row>
  );
};

export default TaxRegestrationFeeOpt;
