import React, { FC, useEffect } from "react";
import { InputPriceProps } from "../../../../components/form/inputNum";
import InputPrice from "../../../../components/form/inputPrice";

interface BatteryCapacityInputProps extends InputPriceProps {
  calculatedValue?: number | null;
}

const BatteryCapacityInput: FC<BatteryCapacityInputProps> = ({ calculatedValue, onChange, ...rest }) => {
  useEffect(() => {
    onChange && onChange(calculatedValue || 0);
  }, [calculatedValue]);
  return <InputPrice disabled {...rest} />;
};

export default BatteryCapacityInput;
