import { FC } from "react";
import { Alert, Form, Switch } from "antd";
import _t from "../../../lang/translate";
import LeasingInsuranceActiveSwitch from "./leasingInsuranceActiveSwitch";
import InputPrice from "../../../components/form/inputPrice";
import LeasingInsuranceRoadAssistance from "./leasingInsuranceRoadAssistance";
import LeasingInsuranceGlassDamage from "./leasingInsuranceGlassDamage";
import InsurancePriceField from "./insurancePriceField";
import LeasingStore from "../../../contexts/leasingStore";
import { LeasingDealType } from "./calc/leasingCalcTypes";

const LeasingInsurance: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { inputLocked, readonly } = leasingCase.flags;
  const disabled = inputLocked || readonly;

  const shouldUpdate = (curr?: LeasingDealType, next?: LeasingDealType) =>
    curr?.insuranceInput?.active !== next?.insuranceInput?.active ||
    curr?.insuranceOutput?.insurable !== next?.insuranceOutput?.insurable;

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate}>
      {({ getFieldsValue }) => {
        const deal: LeasingDealType = getFieldsValue(true);
        const isActive = deal?.insuranceInput?.active;
        const vehiclePrice = deal.input.vehiclePrice;
        const insurable = deal?.insuranceOutput?.insurable;

        return (
          <>
            <LeasingInsuranceActiveSwitch disabled={disabled} />
            {isActive && (
              <>
                <Form.Item label={_t("insurance_sum")} name={["insuranceInput", "vehiclePrice"]} className="mb-05">
                  <InsurancePriceField isActive={isActive} vehiclePrice={vehiclePrice} disabled={disabled} />
                </Form.Item>
                <Form.Item label={_t("own_risk")} name={["insuranceOutput", "ownRisk"]} className="mb-05">
                  <InputPrice suffix={_t("dkk_price_short")} disabled />
                </Form.Item>
                <Form.Item
                  label={_t("is_over_26")}
                  name={["insuranceInput", "isUserOver26"]}
                  className="mb-05"
                  valuePropName="checked"
                >
                  <Switch size="small" disabled={disabled} />
                </Form.Item>
                <LeasingInsuranceRoadAssistance />
                <LeasingInsuranceGlassDamage />
                {!insurable && <Alert type="warning" banner message={_t("msg.not_insurable")} className="mb-1" />}
              </>
            )}
          </>
        );
      }}
    </Form.Item>
  );
};

export default LeasingInsurance;
