import moment from "moment";
import { TaxCalcValueTypes } from "../../../../types/taxCalcTableTypes";
import { isNum } from "../../../../utilities/typeGuard";
import { TradePriceCalcInputType } from "./tradePriceCalculatorTypes";
import calculateTradePrice from "./tradePriceCalculatorIndex";

const getBestCombination = (
  data: any,
  combinations: Array<number[]>,
  initCalcInput: Partial<TradePriceCalcInputType>
) => {
  let bestCombination: any[] = [];
  let bestTradePrice: number | null = null;

  let i = 0;
  while (i < combinations.length) {
    const tradePrice = getTradePriceInput(data, combinations[i], initCalcInput);
    if (
      (!isNum(bestTradePrice) && isNum(tradePrice)) ||
      (isNum(tradePrice) && isNum(bestTradePrice) && tradePrice < bestTradePrice)
    ) {
      bestTradePrice = tradePrice;
      bestCombination = combinations[i];
    }
    i++;
  }

  return { bestTradePrice, bestCombination };
};

const getTradePriceInput = (data: any, combination: number[], initCalcInput: Partial<TradePriceCalcInputType>) => {
  const vehicle: any = data?.vehicle || {};
  const ads: any[] = data?.vehicles || [];
  const filteredAds = ads.filter((e, i) => combination.includes(i));
  const input: TradePriceCalcInputType = {
    ...initCalcInput,
    mileage: data?.vehicle?.mileage,
    qualityId: data?.vehicle?.qualityId,
    specialUseId: data?.vehicle?.useCaseId,
    firstRegDate: data?.vehicle?.firstRegDate,
    vehicleAdCount: combination?.length,
    averagePrice: calculateAvarage(filteredAds, "recalculatedPrice"),
    averageMileage: calculateAvarage(filteredAds, "recalculatedMileage"),
    equipmentRegulation: calculateEquipmentRegulation(vehicle, filteredAds, data?.total || {}),
  };
  const output = calculateTradePrice(input);
  return output.finalTradePriceTotal;
};

const calculateAvarage = (ads: any[], type: TaxCalcValueTypes) => {
  if (!ads?.length) return 0;
  const result = ads.reduce((acc, item) => {
    const value = item[type];
    const length = ads.length;
    return acc + value / length;
  }, 0);
  return Math.round(result || 0);
};

const calculateEquipmentRegulation = (vehicle: any, ads: any[], total: any) => {
  if (!ads?.length) return 0;
  const equipmentYearFactorDeduction = total?.equipmentYearFactorDeduction || 0;
  const vehicleAge = moment().get("year") - (vehicle?.year || 0);
  const vehicleEquipmentsTotalUsed = vehicle?.equipmentsTotalUsed || 0;
  const vehiclesEquipmentsTotalUsed = calculateAvarage(ads, "equipmentsTotalUsed");
  const equipmentsDiffUsed = vehicleEquipmentsTotalUsed - vehiclesEquipmentsTotalUsed || 0;
  if (isNum(vehicleAge) && vehicleAge < 8) {
    return Math.round(equipmentsDiffUsed * equipmentYearFactorDeduction);
  }
  return 0;
};

export default getBestCombination;
