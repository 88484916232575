import { FC, useEffect } from "react";
import { TaxCalcValueTypes } from "../../../../../types/taxCalcTableTypes";
import format from "../../../../../utilities/formatNumbers";
import { isNum } from "../../../../../utilities/typeGuard";

interface TaxaveragePriceCellProps {
  vehicles?: any[] | null;
  type: TaxCalcValueTypes;
  bold?: boolean;
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const TaxaveragePriceCell: FC<TaxaveragePriceCellProps> = ({ vehicles, type, bold, value, onChange }) => {
  useEffect(() => {
    let nextValue = 0;
    if (Array.isArray(vehicles)) {
      nextValue = vehicles.reduce((acc, item) => {
        const value = item.enabled ? item[type] : 0;
        const length = vehicles.filter((e) => e.enabled).length;
        return acc + value / length;
      }, 0);
      nextValue = Math.round(nextValue || 0);
    }
    onChange && onChange(nextValue);
  }, [vehicles, type]);

  return (
    <>{isNum(value) ? <span className={`text-dark${bold ? " text-bold" : ""}`}>{format.number(value)}</span> : "-"}</>
  );
};

export default TaxaveragePriceCell;
