import React, { FC } from "react";
import { Typography, Collapse, Empty, Popover } from "antd";
import Details from "../../components/detailsList";
import _t from "../../lang/translate";
import format from "../../utilities/formatNumbers";
import { BaseVehicle, Vehicle } from "../../types/appTypes";
import CopyableContent from "../../components/copyableContent";
import CustomCollapse from "../../components/customCollapse";
import CustomCollapseHeader from "../../components/customCollapseHeader";
import UserStore from "../../contexts/userStore";
import { isString, isValidNum } from "../../utilities/typeGuard";
import Icon from "../../assets/icons/icon";
import { isDMRBatteryCapacityEqual, isDMRElectricConsumptionEqual, isDMRElectricRangeEqual, isDMREmissionEqual, isDMREmssionUnitEqual } from "../../utilities/mapDataFromDMR";

const { Link } = Typography;
const { Panel } = Collapse;

type Props = {
  vehicle: Vehicle | BaseVehicle | null;
  showAdditionalData?: boolean;
  showDMRAlerts?: boolean
};
function isVehicle(vehicle: Vehicle | BaseVehicle): vehicle is Vehicle {
  return 'remoteData' in vehicle && !!vehicle.remoteData;
}

const VehicleInfo: FC<Props> = ({ vehicle, showAdditionalData, showDMRAlerts }) => {
  const { hasPermission } = UserStore.useContainer();
  if (!vehicle) {
    return <Empty />;
  }
  const {
    brand,
    model,
    type,
    modelDesignation,
    modelPeriod,
    equipmentVariant,
    firstRegDate,
    vin,
    mileage,
    quality,
    useCase,
    fuel,
    engineSize,
    horsePower,
    gearbox,
    body,
    emissionAmount,
    batteryCapacity,
    electricConsumption,
    electricRange,
    emissionUnit,
    isHighWeight,
    isNew,
    link,
  } = vehicle;

  const formatValue = (v?: unknown | null) => {
    if (isString(v)) {
      return v.trim()
    } else if (isValidNum(v) || typeof v === 'boolean') {
      return v + "";
    } else {
      return "-";
    }
  };

  return (
    <Details borderLess>
      {link && (
        <Details.Item
          value={
            <Link ellipsis className="max-width-240" href={link} target="_blank">
              {link}
            </Link>
          }
          className="mb-1"
        />
      )}
      <Details.Item label={_t("type")} value={formatValue(type)} />
      <Details.Item
        label={_t("new", "vehicle") + "?"}
        value={format.yesOrNo(isNew)}
        className="mb-1"
      />

      <Details.Item label={_t("brand")} value={formatValue(brand)} />
      <Details.Item label={_t("model")} value={formatValue(model)} />
      <Details.Item
        label={_t("equipment_variant")}
        value={formatValue(equipmentVariant)}
      />

      <Details.Item
        label={_t("model_designation")}
        value={formatValue(modelDesignation)}
      />
      <Details.Item
        label={_t("model_period")}
        value={formatValue(modelPeriod)}
      />

      <Details.Item
        label={_t("firstRegDate")}
        value={
          <CopyableContent contentToCopy={format.date(firstRegDate)}>
            {format.date(firstRegDate)}
          </CopyableContent>
        }
      />
      <Details.Item
        label={_t("vin")}
        value={
          <CopyableContent contentToCopy={formatValue(vin)}>
            {formatValue(vin)}
          </CopyableContent>
        }
      />
      <Details.Item
        label={_t("mileage")}
        value={
          <CopyableContent contentToCopy={String(mileage || 0)}>
            {format.milage(mileage)}
          </CopyableContent>
        }
        className="mb-1"
      />

      <Details.Item label={_t("engine_size")} value={formatValue(engineSize)} />
      <Details.Item
        label={_t("engine_power")}
        value={formatValue(horsePower)}
      />
      <Details.Item label={_t("gearbox")} value={formatValue(gearbox)} />
      <Details.Item label={_t("trim")} value={formatValue(body)} />
      <Details.Item label={_t("car_condition")} value={formatValue(quality)} />
      <Details.Item
        label={_t("special_use")}
        value={formatValue(useCase)}
        className="mb-1"
      />

      <Details.Item label={_t("fuel_type")} value={formatValue(fuel)} />
      <Details.Item
        label={_t("over_3000_kg")}
        value={format.yesOrNo(isHighWeight)}
      />
      <Details.Item
        label={
          <span>
            {_t("co2_emission")}
            {showDMRAlerts && !isDMREmissionEqual(vehicle) && (
              <AlertDMRData field={_t("co2_emission")} />
            )}
          </span>
        }
        value={format.emission(emissionAmount)}
      />
      <Details.Item
        label={
          <span>
            {_t("co2_measurement")}
            {showDMRAlerts && (!isDMREmssionUnitEqual(vehicle).isEqual || isDMREmssionUnitEqual(vehicle).isDerived) && (
              <AlertDMRData field={_t("co2_measurement")}
                message={isDMREmssionUnitEqual(vehicle).isDerived ? _t("dmr_data_unclear_on_emission_unit") : undefined}
              />
            )}
          </span>
        }
        value={formatValue(emissionUnit)}
      />
      <Details.Item
        label={
          <span>
            {_t("battery_capacity")}
            {showDMRAlerts && !isDMRBatteryCapacityEqual(vehicle) && (
              <AlertDMRData field={_t("battery_capacity")} />
            )}
          </span>
        }
        value={format.batteryCapacity(batteryCapacity)}
      />
      <Details.Item
        label={
          <span>
            {_t("battery_usage")}
            {showDMRAlerts && !isDMRElectricConsumptionEqual(vehicle) && (
              <AlertDMRData field={_t("battery_usage")} />
            )}
          </span>
        }
        value={format.batteryUsage(electricConsumption)}
      />
      <Details.Item
        label={
          <span>
            {_t("battery_range")}
            {showDMRAlerts && !isDMRElectricRangeEqual(vehicle) && (
              <AlertDMRData field={_t("battery_range")} />
            )}
          </span>
        }
        value={format.milage(electricRange)}
      />

      {isVehicle(vehicle) && showAdditionalData && hasPermission("tax.isAdmin") && (
        <CustomCollapse className="mt-03">
          <Panel
            header={
              <CustomCollapseHeader>
                {_t("other_information")}
              </CustomCollapseHeader>
            }
            key="1"
          >
            <Details borderLess className="mb-0-important">
              <Details.Item
                label={_t("dat_net_price")}
                value={format.price(vehicle.remoteData?.dat?.raw?.originalNetPrice, 0, "EUR")}
              />
              <Details.Item
                label={_t("number_of_doors")}
                value={format.number(vehicle.remoteData?.dat?.raw?.doorCount)}
              />
              <Details.Item
                label={_t("number_of_seats")}
                value={format.number(vehicle.remoteData?.dat?.raw?.seatsCount)}
              />
              <Details.Item
                label={_t("number_of_gears")}
                value={format.number(vehicle.remoteData?.dat?.raw?.gearCount)}
              />
              <Details.Item
                label={_t("drive_wheel")}
                value={vehicle.remoteData?.dat?.raw?.driveWheel}
              />
              <Details.Item
                label={_t("cylinders")}
                value={format.number(vehicle.remoteData?.dat?.raw?.cylinders)}
              />
            </Details>
          </Panel>
        </CustomCollapse>
      )}
    </Details>
  );
};

export default VehicleInfo;

type AlertDMRDataProps = {
  field: string;
  message?: string;
}
const AlertDMRData = ({ field, message }: AlertDMRDataProps) => {
  return (
    <Popover content={
      <>
        <span className="d-block">
          - {_t("dmr_has_differing_data_for")}: {field}
        </span>
        {message && (
          <span className="d-block">- {message}</span>
        )}
      </>
    }>
      <Icon size="large" className="ml-05 no-bg text-warning cursor-pointer" name="alert" />
    </Popover>
  )
}
