import { FC } from "react";
import { Button, Col, Form, message, Row } from "antd";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import consts from "../../../utilities/consts";
import CustomerFormFields from "../customer/customerFormFields";
import { updateCustomer } from "../../../services/leasingService";
import EnumRadio from "../../../components/form/EnumRadio";
import { getInitCustomer, isPrivateCustomer } from "../../../services/customerService";
import LeasingCustomerAutoFill from "./admin/leasingCustomerAutoFill";
import ContactPersonFormFields from "./contactPersonFormFields";
import { LeasingCustomerUpdateType } from "../../../types/leasingTypes";
import { CustomerType } from "../../../types/customerTypes";
import { isAxiosError } from "../../../utilities/typeGuard";

interface LeasingCustomerFormProps {
  stopEditing: () => void;
}

const LeasingCustomerForm: FC<LeasingCustomerFormProps> = ({ stopEditing }) => {
  const { leasingCase, isAdmin, setLeasingCase, setCustomerLoading } = LeasingStore.useContainer();

  const handleSave = async (values: LeasingCustomerUpdateType) => {
    try {
      setCustomerLoading(true);
      if (!!leasingCase.id) {
        const { data } = await updateCustomer(leasingCase.id, values);
        setLeasingCase({ ...leasingCase, ...data });
      } else {
        setLeasingCase({ ...leasingCase, ...values });
      }
      stopEditing();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setCustomerLoading(false);
    }
  };

  const initValues: LeasingCustomerUpdateType = {
    customer: leasingCase.customer || getInitCustomer(),
    legalOwner: leasingCase.legalOwner,
    contactPerson: leasingCase.contactPerson,
  };

  const isRequiredForValidation = !!leasingCase.id && !leasingCase.flags.validationStarted;

  return (
    <Form<LeasingCustomerUpdateType>
      {...consts.formItemProps}
      className="m-0"
      initialValues={initValues}
      requiredMark={true}
      onFinish={handleSave}
    >
      <Form.Item name={["customer", "typeId"]} label={_t("type")}>
        <EnumRadio nameKey={["customer", "type"]} />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, setFieldsValue }) => {
          const typeId = getFieldValue(["customer", "typeId"]) || 1;
          const isPrivate = isPrivateCustomer(typeId);
          const handleFetched = (customer: CustomerType) => {
            setFieldsValue({ customer });
          };
          return (
            <>
              {isAdmin && <LeasingCustomerAutoFill typeId={typeId} onFetched={handleFetched} />}
              <CustomerFormFields
                requiredFields={isRequiredForValidation ? ["name", "email", "phone"] : ["name"]}
                formKey="customer"
                isPrivate={isPrivate}
                hideCPR={!isAdmin}
                hidePBS={!isAdmin}
              />
              {!isPrivate && (
                <>
                  <ContactPersonFormFields formKey="legalOwner" />
                  <ContactPersonFormFields formKey="contactPerson" />
                </>
              )}
            </>
          );
        }}
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ submit }) => (
          <Row justify="space-between" className="mt-2">
            <Col>
              <Button tabIndex={-1} onClick={stopEditing}>
                {_t("cancel")}
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={submit}>
                {_t("save")}
              </Button>
            </Col>
          </Row>
        )}
      </Form.Item>
    </Form>
  );
};

export default LeasingCustomerForm;
