import { Result } from "antd";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import AnimateIn from "../../components/animateIn";
import UserPageStore from "../../contexts/userPageStore";
import _t from "../../lang/translate";
import PageBody from "../../layout/pageBody";
import PageTitle from "../../layout/pageTitle";
import { isNew } from "../../routes/appRoutes";
import { useApi } from "../../services/useApi";
import { userUrl } from "../../services/userService";
import { ParamTypes, ScreenProps } from "../../types/appTypes";
import { initUser, UserType } from "../../types/userTypes";
import UserContent from "./user/userContent";

const User: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading, isError }, setUrl] = useApi<UserType>("", initUser);

  useEffect(() => {
    if (!isNew(id)) setUrl(userUrl(id));
  }, [id, setUrl]);

  return (
    <UserPageStore.Provider initialState={data}>
      <PageTitle title={`${title}/${id}`} fluid backBtn />
      <PageBody>
        {isError && <Result status="error" title={_t("msg.unknown_error")} />}
        <AnimateIn start={!isLoading} loadingBeforeStart>
          <UserContent />
        </AnimateIn>
      </PageBody>
    </UserPageStore.Provider>
  );
};

export default User;
