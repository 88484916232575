import { Button, Modal, Tooltip } from "antd";
import { FC, useState } from "react";
import Icon from "../../../assets/icons/icon";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import { DealerAdminProductType } from "../../../types/dealerTypes";
import DealerConfigValueForm from "./dealerConfigValueForm";

interface DealerConfigValuesProps {
  product: DealerAdminProductType;
}

const DealerConfigValues: FC<DealerConfigValuesProps> = ({ product }) => {
  const { dealer } = DealerStore.useContainer();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  return (
    <>
      <Tooltip title={_t("customize", "case_values")}>
        <Button type="text" className="hover-color-primary p-0 no-bg" onClick={openModal}>
          <Icon name="settings-outline" size="medium" />
        </Button>
      </Tooltip>
      <Modal width={800} visible={isVisible} onCancel={closeModal} title={null} footer={null}>
        <h2 className="modal-title">{_t("customize", "case_values")}</h2>
        <DealerConfigValueForm context={product.context} dealerId={dealer.id} onCloseModal={closeModal} />
      </Modal>
    </>
  );
};

export default DealerConfigValues;
