import React, { FC } from "react";
import { Form, Input } from "antd";
import DateSelect from "../../../components/form/dateSelect";
import EnumRadio from "../../../components/form/EnumRadio";
import EnumSelect from "../../../components/form/enumSelect";
import InputNum from "../../../components/form/inputNum";
import InputVin from "../../../components/form/inputVin";
import ServerSelectBox from "../../../components/serverSelectBox";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { brandsAutoComplete, modelsAutoComplete } from "../../../services/autocompleteService";
import ServerAutoFill from "../../../components/serverAutoFill";

const LeasingVehicleFormFields: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { validationStarted } = leasingCase.flags;
  const isRequiredForValidation = !!leasingCase.id && !validationStarted;

  return (
    <>
      <Form.Item name="typeId" label={_t("vehicle_type")}>
        <EnumRadio nameKey={["vehicle", "type"]} />
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ setFieldsValue, getFieldsValue }) => {
          const { typeId } = getFieldsValue();

          const url = brandsAutoComplete('', '', typeId);
          return (
            <Form.Item rules={[{ required: true }]} name="brandId" label={_t("brand")}>
              <ServerSelectBox
                onChange={(v, option) => "label" in option && setFieldsValue({ brand: option?.label })}
                apiUrl={url}
                disabled={!typeId}
              />
            </Form.Item>
          )
        }}
      </Form.Item>

      <Form.Item shouldUpdate={(curr, next) => curr.brandId !== next.brandId} noStyle>
        {({ getFieldsValue }) => {
          const { brandId, typeId } = getFieldsValue();

          const url = modelsAutoComplete('', brandId, '', typeId);
          return (
            <Form.Item rules={[{ required: true }]} name="model" label={_t("model")}>
              <ServerAutoFill
                apiUrl={url}
                disabled={!brandId || !typeId}
              />
            </Form.Item>
          );
        }}

      </Form.Item>

      <Form.Item name="firstRegDate" label={_t("firstRegDate")} rules={[{ required: true }]}>
        <DateSelect />
      </Form.Item>
      <Form.Item name="vin" label={_t("vin")} rules={[{ required: isRequiredForValidation }]}>
        <InputVin className="input-group-btn" maxLength={17} />
      </Form.Item>
      <Form.Item name="mileage" label={_t("mileage")} rules={[{ required: true }]}>
        <InputNum step={1000} min={0} suffix={<small>{_t("mileage_unit")}</small>} />
      </Form.Item>
      <Form.Item name="color" label={_t("color")} rules={[{ required: isRequiredForValidation }]}>
        <Input />
      </Form.Item>
      <Form.Item name="extraAccessories" label={_t("extra_equipments")} rules={[{ required: isRequiredForValidation }]}>
        <Input />
      </Form.Item>
      <Form.Item name="qualityId" label={_t("car_condition")} rules={[{ required: isRequiredForValidation }]}>
        <EnumSelect nameKey={["vehicle", "quality"]} />
      </Form.Item>
    </>
  );
};

export default LeasingVehicleFormFields;
