import React, { FC, useState } from "react";
import { Card, Col, Form, Row } from "antd";
import { useHistory } from "react-router-dom";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import PageTitle from "../../../layout/pageTitle";
import { saveTaxCase } from "../../../services/taxService";
import { ScreenProps } from "../../../types/appTypes";
import { isTaxDataSource, TaxCase, TaxCaseCreation, TaxDataSourceType } from "../../../types/taxTypes";
import { convertToApiFileList } from "../../../utilities/convertTypes";
import TaxFetchCarFC from "./TaxFetchCarFC";
import TaxNewCaseAdminPanel from "../taxCommon/admin/taxNewCaseAdminPanel";
import LoadingContainer from "../../../components/loadingContainer";
import PageBody from "../../../layout/pageBody";
import consts from "../../../utilities/consts";
import DividerLine from "../../../components/lineSplit";
import FancySubmitButton from "../../../components/fancySubmitButton";
import { getPath } from "../../../routes/appRoutes";
import { latestVersion } from "./calc/taxCalcService";
import { isAxiosError } from "../../../utilities/typeGuard";
import { getProductPrices } from "../../../services/authService";
import FakeFancySubmitButton from "../../../components/fakeFancySubmitButton";
import { renderErrorMessage } from "../../../components/messages/errorMessage";
import acceptTerms from "../../../components/modal/acceptTerms";

const TaxNewCase: FC<ScreenProps> = ({ title }) => {
  const { user, hasPermission, hasEnoughCredits } = UserStore.useContainer();
  const initialTaxNewCase = {
    id: 0,
    version: latestVersion,
    plan: 1,
    evaluationTypeId: 1,
    vehicle: {
      typeId: 1,
      link: "",
      vin: "",
    },
    note: null,
    files: null,
    calculationTypeId: 1,
    paymentMethodId: 1,
    client: {
      userId: user?.id,
      dealerId: user?.dealer?.id,
      typeId: 1,
    },
  };
  const isAdmin = hasPermission("tax.isAdmin");
  const [activeTab, setActiveTab] = useState<TaxDataSourceType>("vin");
  const [newTaxCaseForm] = Form.useForm<TaxCaseCreation>();
  const [loading, setLoading] = useState<boolean>(false);
  const [enforceIsInspected, setEnforceIsInspected] = useState(false);
  const [clipCost, setClipCost] = useState<number>(1);
  let history = useHistory();

  const handleTabChange = (key: string) => isTaxDataSource(key) && setActiveTab(key);

  const createNewTaxCase = async () => {
    const values = newTaxCaseForm.getFieldsValue(true);
    try {
      setLoading(true);
      await acceptTerms(user)

      let dataSourceId = 0;
      if (activeTab === "vin") {
        dataSourceId = 2;
      } else if (activeTab === "link") {
        dataSourceId = 1;
      } else if (activeTab === "manual") {
        dataSourceId = 3;
      }
      const { data: newTaxCase } = await saveTaxCase({
        ...values,
        dataSourceId,
        files: convertToApiFileList(values?.files || []),
      });
      redirectToCase(newTaxCase);
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data?.errors;
        if (errors) {
          newTaxCaseForm.setFields(errors);
          enforceRegistrationFee()
        }
      }
      renderErrorMessage(error)
      setLoading(false);
    }
  };
  const redirectToCase = (newTaxCase: TaxCase) => {
    if (hasPermission('tax.index')) {
      newTaxCase.id && history.replace(getPath("tax", newTaxCase.id));
    } else {
      newTaxCase.id && history.replace(getPath("tax", "caseCreated"));
    }
  }

  const calculateEvaluationCost = () => {
    const isYellowToWhite = newTaxCaseForm.getFieldValue('evaluationTypeId') === 3;
    setClipCost((isYellowToWhite ?
      getProductPrices()?.taxYellowToWhite :
      getProductPrices()?.tax
    ) || 2);
  }

  const canPurchase = hasEnoughCredits(clipCost);
  const usageText = _t("when_click_calculate_you_use", "credit_from_your_account", ` ${canPurchase.usage.total} `);

  const handleFormChange = (changedValues: Partial<TaxCaseCreation>, values: TaxCaseCreation) => {
    if (activeTab !== 'vin') {
      return;
    }
    if (changedValues.vehicle?.vin) {
      const isInspected = values.vehicle?.isInspected;
      deEnforceRegistrationFee(isInspected)
    }
  }

  const enforceRegistrationFee = () => {
    newTaxCaseForm.setFieldsValue({ vehicle: { isInspected: true } })
    setEnforceIsInspected(true)
  }

  const deEnforceRegistrationFee = (isInspected: boolean | undefined) => {
    newTaxCaseForm.setFieldsValue({ vehicle: { isInspected } })
    setEnforceIsInspected(false)
  }


  return (
    <>
      <PageTitle fluid backBtn title={title} />

      <PageBody>

        <Form
          {...consts.formItemProps}
          form={newTaxCaseForm}
          initialValues={initialTaxNewCase}
          onFieldsChange={calculateEvaluationCost}
          onValuesChange={handleFormChange}
          requiredMark={false}
          onFinish={createNewTaxCase}
        >
          <Row align="top" gutter={24}>
            {isAdmin && (
              <Col {...consts.newCaseAdminColumnProps}>
                <TaxNewCaseAdminPanel loading={loading} />
              </Col>
            )}
            <Col {...consts.newCaseContentColumnProps(isAdmin)}>
              <Card bordered={false} className="card-tabs" title={_t("new", "tax_case")}>
                <LoadingContainer loading={loading}>
                  <TaxFetchCarFC
                    isAdmin={isAdmin}
                    onTabChange={handleTabChange}
                    activeTab={activeTab}
                    enforceIsInspected={enforceIsInspected}
                  />
                  {!isAdmin && (
                    <div className="fancy-btn-wp text-center pt-1">
                      <DividerLine>
                        <small>
                          {!isAdmin && usageText}
                        </small>
                      </DividerLine>
                      {isAdmin || canPurchase.ok ? (
                        <FancySubmitButton loading={loading} />
                      ) : (
                        <FakeFancySubmitButton />
                      )}
                    </div>
                  )}
                </LoadingContainer>
              </Card>
            </Col>
          </Row>
        </Form>
      </PageBody>
    </>
  );
};

export default TaxNewCase;
