import React, {FC, useEffect} from "react";
import {getPath} from "../routes/appRoutes";
import {getRedirectParts} from "../utilities/urlFunctions";

const DMF_PRO_ORIGIN = process.env.REACT_APP_DMF_PRO_ORIGIN
const DMF_PRO_LOGIN_PATH = process.env.REACT_APP_DMF_PRO_LOGIN_PATH
const DMF_PRO_SIGNUP_PATH = process.env.REACT_APP_DMF_PRO_SIGNUP_PATH
const DMF_PRO_LOGIN_URL = DMF_PRO_ORIGIN + "" + DMF_PRO_LOGIN_PATH;
const DMF_PRO_SIGNUP_URL = DMF_PRO_ORIGIN + "" + DMF_PRO_SIGNUP_PATH;

interface RedirectProps {
  location?: { state: any }
}

const RedirectDealerLogin: FC<RedirectProps> = ({location}) => {
  const pathname = location?.state?.from?.pathname || '';

  useEffect(() => {
    const redirectParts = getRedirectParts(pathname);

    let href: string;
    if (pathname.startsWith(getPath("signUp"))) {
      href = DMF_PRO_SIGNUP_URL;
    } else if (redirectParts['context'] && redirectParts['value']) {
      href = `${DMF_PRO_ORIGIN}/${redirectParts['context']}/${redirectParts['value']}`;
    } else if (redirectParts['context']) {
      href = `${DMF_PRO_ORIGIN}/${redirectParts['context']}`;
    } else {
      href = DMF_PRO_LOGIN_URL;
    }

    window.location.href = href
  }, []);

  return (
    <></>
  )
};

export default RedirectDealerLogin;
