import { Typography } from "antd";
import React, { FC } from "react";
import Icon from "../assets/icons/icon";
import _t from "../lang/translate";

interface SavedStatusProps {
  status: boolean;
}

const SavedStatus: FC<SavedStatusProps> = ({ status }) => {
  const porps = {
    className: "mr-1 ml-1",
  };
  return status ? (
    <Typography.Text type="secondary" {...porps}>
      <Icon name="checkmark-outline" /> {_t("saved")}
    </Typography.Text>
  ) : (
    <Typography.Text type="warning" {...porps}>
      {_t("not_saved")}
    </Typography.Text>
  );
};

export default SavedStatus;
