import { LogObj } from "./appTypes";

type groupIdType = string;
type itemIdType = string;

export interface ChecklistEntryType {
  id: itemIdType;
  groupId: groupIdType;
  position: number;
  title: string | null;
  description: string | null;
  isPublic: boolean;
}

export interface ChecklistGroupType {
  id: groupIdType;
  position: number;
  title: string | null;
  isPublic: boolean;
  entries: ChecklistEntryViewType[];
}

export interface ChecklistEntryViewType extends ChecklistEntryType {
  completed: boolean;
  ignored: boolean;
  updateLog: LogObj | null;
}

export interface ExpandedObjType {
  groupId: groupIdType;
  id: itemIdType;
}

export const initChecklistEntry: ChecklistEntryType = {
  id: "0",
  groupId: "",
  title: null,
  description: null,
  isPublic: false,
  position: 1,
};

export const initChecklistGroup: ChecklistGroupType = {
  id: "0",
  position: 1,
  isPublic: false,
  title: null,
  entries: [],
};
