import React, { FC } from "react";
import CaseHeadClient from "../../../../components/caseHeadClient";
import openModal from "../../../../components/modal/openModal";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import { TaxCase } from "../../../../types/taxTypes";

const TaxCaseDealer: FC = () => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const { client, id } = taxCase;

  const handleClientChange = async () => {
    try {
      const newCase = await openModal<TaxCase>("changeClient", { id, client, context: "tax" });
      setTaxCase(newCase);
    } catch (ex) {
    }
  };

  return <CaseHeadClient onClientChange={handleClientChange} client={client} />;
};

export default TaxCaseDealer;
