import React, { ChangeEventHandler, FC, useRef, useState } from "react";
import { ChecklistGroupType } from "../../types/checklistTypes";
import { Button, Col, Input, message, Popconfirm, Row, Space } from "antd";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { deleteChecklistGroup, updateChecklistGroup } from "../../services/checklistService";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import { isAxiosError } from "../../utilities/typeGuard";

const { TextArea } = Input;

interface SortableItemProps {
  group: ChecklistGroupType;
  updateGroup: (group: ChecklistGroupType) => void;
}

const ChecklistSortableGroup: FC<SortableItemProps> = ({ group, updateGroup }) => {
  const { dispatchGroups } = ChecklistAdminStore.useContainer();
  const [value, setValue] = useState<string>(group.title || "");
  const [focus, setFocus] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const textRef = useRef<TextAreaRef>(null);

  const handleSubmit: React.KeyboardEventHandler<HTMLTextAreaElement> = async (e) => {
    e.preventDefault();
    const title = value;
    if (title === group.title) {
      textRef.current?.blur();
      return;
    }
    const originalGroup = { ...group };
    try {
      setLoading(true);
      const nextGroup = { ...group, title };
      updateGroup(nextGroup);
      // For better response time, the entries is set to [].
      await updateChecklistGroup({ ...nextGroup, entries: [] });
      message.success(_t("saved"));
      textRef.current?.blur();
      setFocus(false);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      updateGroup(originalGroup);
    } finally {
      setLoading(false);
    }
  };

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => setValue(e.target.value);

  const handleDelete = async () => {
    try {
      setLoading(true);
      // For better response time, the entries is set to [].
      await deleteChecklistGroup(group.id);
      message.success(_t("deleted"));
      setLoading(false);
      dispatchGroups({ type: "delete_group", payload: group });
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  const handlePublicClick = async () => {
    try {
      setLoading(true);
      await updateChecklistGroup({ ...group, isPublic: !group.isPublic });
      dispatchGroups({ type: "toggle_isPublic", payload: group });
      setLoading(false);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <div className={`dragable-item${false ? " is-dragging" : ""}`}>
      <Row className="flex-wrap" gutter={4}>
        <Col flex="36px">
          <Button tabIndex={-1} className="muted pl-05 pr-05" type="text">
            <Icon name="reorder-two-outline" size="large" className="drag-handle" />
          </Button>
        </Col>
        <Col flex="auto">
          <TextArea
            className={focus ? "" : "no-bg no-border"}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              setFocus(false);
              setValue(group.title || "");
            }}
            disabled={loading}
            onChange={handleChange}
            value={value}
            onPressEnter={handleSubmit}
            ref={textRef}
            autoSize={{ minRows: 1 }}
          />
        </Col>
        <Col flex="88px">
          <Space>
            <Button
              tabIndex={-1}
              className="muted pl-05 pr-05"
              type="text"
              disabled={loading}
              shape="circle"
              onClick={() => {
                if (focus) {
                  textRef.current?.blur();
                } else {
                  textRef.current?.focus();
                }
              }}
            >
              <Icon name={focus ? "close-outline" : "pencil-outline"} size="large" />
            </Button>
            <Button
              tabIndex={-1}
              className="muted pl-05 pr-05"
              type="text"
              disabled={loading}
              title={_t("public")}
              shape="circle"
              onClick={handlePublicClick}
            >
              <Icon name={group.isPublic ? "eye-outline" : "eye-off-outline"} size="large" />
            </Button>
            <Popconfirm
              placement="topLeft"
              disabled={loading}
              onConfirm={handleDelete}
              icon={<Icon fill="red" name="information-circle-outline" />}
              title={
                <div>
                  {_t("msg.confirm_delete")}&nbsp;
                  <strong>{group.title}</strong>
                </div>
              }
            >
              <Button
                tabIndex={-1}
                className="muted delete-btn"
                type="text"
                shape="circle"
                disabled={loading}
                icon={<Icon name="trash-bin-outline" size="large" />}
              />
            </Popconfirm>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default ChecklistSortableGroup;
