import React, { FC } from "react";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import ChecklistAdminGroup from "./checklistAdminGroup";
import { Spin } from "antd";

const ChecklistAdminGroups: FC = () => {
  const { groups, loading } = ChecklistAdminStore.useContainer();

  return (
    <Spin spinning={loading}>
      {groups?.map((group, i) => (
        <ChecklistAdminGroup key={group.id} group={group} className={i !== 0 ? "mt-4" : ""} />
      ))}
    </Spin>
  );
};

export default ChecklistAdminGroups;
