import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";
import { ChecklistEntryType, ChecklistEntryViewType, ChecklistGroupType } from "../types/checklistTypes";
import { IdType, ProductType } from "../types/appTypes";

const groupEndpoint = `${getApiEndpoint()}/checklist/group`;
const entryEndpoint = `${getApiEndpoint()}/checklist/entry`;

export const checklistUrl = (context: ProductType, id?: IdType) => {
  return `${groupEndpoint}/${context}${id ? "/" + id : ""}`;
};

export const checklistGroupUrl = (id: string) => {
  return `${groupEndpoint}${id ? "/" + id : ""}`;
};

export const checklistEntryUrl = (id: string) => {
  return `${entryEndpoint}${id ? "/" + id : ""}`;
};

export const createChecklistGroup = (context: ProductType, id: IdType | undefined, group: ChecklistGroupType) => {
  return http.post(checklistUrl(context, id), group);
};

export const loadDefaultChecklist = (context: ProductType, id: IdType | undefined) => {
  return http.post(`${checklistUrl(context, id)}/reset`);
};

export const updateChecklistGroup = (group: ChecklistGroupType) => {
  return http.put(checklistGroupUrl(group.id), group);
};

export const repositionChecklistGroup = (groups: ChecklistGroupType[]) => {
  return http.put(`${groupEndpoint}/reorder`, { items: groups });
};

export const deleteChecklistGroup = (id: string) => {
  return http.delete(`${groupEndpoint}/${id}`);
};

export const createChecklistEntry = (entry: ChecklistEntryType) => {
  return http.post(entryEndpoint, entry);
};

export const updateChecklistEntry = (entry: ChecklistEntryType) => {
  return http.put(checklistEntryUrl(entry.id), entry);
};

export const updateChecklistEntryHidden = (entry: ChecklistEntryViewType) => {
  return http.put(`${checklistEntryUrl(entry.id)}/ignored`, { status: entry.ignored });
};

export const updateChecklistEntryCompletion = (entry: ChecklistEntryViewType) => {
  return http.put(`${checklistEntryUrl(entry.id)}/completed`, { status: entry.completed });
};

export const repositionChecklistEntry = (group: ChecklistGroupType[]) => {
  return http.put(`${entryEndpoint}/reorder`, { items: group });
};

export const deleteChecklistEntry = (id: string) => {
  return http.delete(checklistEntryUrl(id));
};
