import { Form } from "antd";
import { FC } from "react";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import LeasingVehicleAgeField from "./leasingVehicleAgeField";

const LeasingVehicleAge: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { readonly, inputLocked } = leasingCase.flags;
  const disabled = readonly || inputLocked;

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue }) => {
        const leasingStartDate = getFieldValue(["input", "leasingDates"]);

        return (
          <Form.Item label={_t("vehicle_age")} name={["input", "vehicleAgeInMonths"]}>
            <LeasingVehicleAgeField
              disabled={disabled}
              firstRegDate={leasingCase.vehicle?.firstRegDate}
              leasingStartDate={Array.isArray(leasingStartDate) && leasingStartDate[0]}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default LeasingVehicleAge;
