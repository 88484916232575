import { AutoComplete, Col, Form, Input, Row } from "antd";
import { FC } from "react";
import InputNum from "../../../../../components/form/inputNum";
import _t from "../../../../../lang/translate";
import { TaxCalcEquipment } from "../../../../../types/taxCalcTableTypes";
import { VehicleEquipment } from "../../../../../types/appTypes";
import { OptionData, OptionGroupData } from "rc-select/lib/interface";
import appConfig from "../../../../../appConfig";

interface TaxCalcEquipmentNameProps {
  id: number;
  equipment: TaxCalcEquipment;
  potentialEquipments: VehicleEquipment[];
  euroFactor?: number;
}

const TaxCalcEquipmentName: FC<TaxCalcEquipmentNameProps> = ({ id, equipment, euroFactor, potentialEquipments }) => {
  const disabled = equipment.synced;
  const options = potentialEquipments.map(x => {
    let price = null;
    if (x.price) {
      price = x.price;
    } else if (x.priceEuro) {
      price = x.priceEuro * (euroFactor || 1) * appConfig("conversionRate")
    }

    const title = x.subEquipments?.map(y => y.name).join(', ');

    return {
      value: x.name || '',
      code: x.code || '',
      price: price || '',
      title: title ? `${x.name}: ${title}` : undefined
    }
  });

  const onAutoCompleteSelect = (value: string, option: OptionData | OptionGroupData, setFields: CallableFunction, index: number) => {
    setFields([
      {
        name: ['equipments', index, 'code'],
        value: option.code || '',
      },
      {
        name: ['equipments', index, 'price'],
        value: option.price || '',
      }
    ])
  }

  return (
    <Form.Item shouldUpdate={true} noStyle>
      {({ getFieldValue, setFields }) => {
        const equipments = (getFieldValue("equipments") || []) as any[];
        const index = equipments.findIndex((e) => e.id === id);

        return (
          <Row align="middle" gutter={8} className="flex-nowrap">
            <Col flex="80px">
              <Form.Item name={["equipments", index, "code"]} noStyle>
                <Input className="pr-0" placeholder={_t("code")} disabled={disabled} />
              </Form.Item>
            </Col>
            <Col flex="auto">
              <Form.Item name={["equipments", index, "name"]} noStyle>
                <AutoComplete
                  placeholder={_t("name")}
                  disabled={disabled}
                  options={options}
                  style={{ display: "block" }}
                  filterOption={(inputValue, option) =>
                    option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={(a, b) => onAutoCompleteSelect(a, b, setFields, index)}
                />
              </Form.Item>
            </Col>
            <Col flex="80px">
              <Form.Item name={["equipments", index, "price"]} noStyle>
                <InputNum className="pr-0" placeholder={_t("price")} disabled={disabled} />
              </Form.Item>
            </Col>
          </Row>
        );
      }}
    </Form.Item>
  );
};

export default TaxCalcEquipmentName;
