import React, { FC } from "react";
import { Col, Dropdown, Menu, Row } from "antd";
import moment from "moment";
import Icon from "../assets/icons/icon";
import _t from "../lang/translate";
import PaymentCardBrand from "./paymentCardBrand";
import { PaymentCardType } from "../types/paymentCardType";

interface PaymentCardContentProps {
  card: PaymentCardType;
  onRemove?: (id: string) => void;
}

const PaymentCardContent: FC<PaymentCardContentProps> = ({ card, onRemove }) => {
  const { id, brand, last4, ownerName, expirationDate } = card;

  const expDate = moment(expirationDate);
  const isExpired = !expDate.isAfter(moment());

  const cardMenu = (
    <Menu>
      <Menu.Item key="delete" danger onClick={() => onRemove && onRemove(id)}>
        <Icon name="trash-bin-outline" /> &nbsp;{_t("delete")}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Row justify="space-between">
        <Col flex="auto" className="">
          <p>
            <PaymentCardBrand brand={brand} />
          </p>
        </Col>
        {onRemove && (
          <Col flex="40px" className="text-right ">
            <Dropdown placement="bottomRight" overlay={cardMenu}>
              <Icon name="ellipsis-vertical-sharp" />
            </Dropdown>
          </Col>
        )}
      </Row>
      <h3 className="text-bold">**** **** **** {last4}</h3>
      <Row justify="space-between">
        <Col flex="auto" className="">
          {ownerName}
        </Col>
        <Col flex="40px" className={`text-right${isExpired ? " text-danger" : ""}`}>
          {expDate.format("MM/YY")}
        </Col>
      </Row>
    </>
  );
};

export default PaymentCardContent;
