import { Button, Col, Divider, Input, message, Modal, Popconfirm, Row, TableColumnsType } from "antd";
import React, { FC, useEffect, useState } from "react";
import _t from "../../lang/translate";
import { Table } from "antd/es";
import Icon from "../../assets/icons/icon";
import {
  TransportFeeBaseType,
  TransportFeeDataType,
  TransportFeeDataUpdateType,
  TransportFeePriceType,
  TransportFeePriceUpdateType,
  TransportFeeType
} from "../../types/systemTypes";
import { getTransportFees, saveTransportFees } from "../../services/systemService";
import { isAxiosError } from "../../utilities/typeGuard";
import DateSelect from "../../components/form/dateSelect";

interface TableRowType extends TransportFeeBaseType {
  [key: string]: any,
}

const SystemTransportFees: FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TransportFeeDataType[]>([]);
  const [dataDate, setDataDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data } = await getTransportFees() || {};
      setDataSource(data.items || []);
      setDataDate(data.date || '');
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setIsLoading(false);
    }
  }

  const saveData = async () => {
    let res = false;
    try {
      setIsLoading(true);

      const data: TransportFeeType = {
        date: dataDate,
        items: dataSource
      }

      await saveTransportFees(data);

      message.success(_t("saved"));

      res = true;
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setIsLoading(false);
    }

    return res;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const save = async () => {
    const success = await saveData();
    if (success) {
      setShowModal(false)
    }
  }

  const close = () => {
    setShowModal(false)
  }

  const addColumn = () => {
    const data = [...dataSource.map(set => {
      const tmp = set;
      const prices = tmp.prices || [];

      prices.push({
        toEnd: null,
        toStart: null,
        price: null,
        countryCode: "DK"
      })

      tmp.prices = prices;

      return tmp;
    })];

    setDataSource(data);
  }

  const removeColumn = (index: number) => {
    const data = [...dataSource.map(set => {
      return {
        ...set,
        prices: set.prices?.filter((_, i) => i !== index) || null
      }
    })];

    setDataSource(data);
  }

  const addRow = () => {
    const data = [...dataSource];
    const columnItem = dataSource[0];

    data.push({
      countryCode: null,
      fromEnd: null,
      fromStart: null,
      prices: columnItem?.prices?.map(set => {
        return {
          toEnd: set.toEnd,
          toStart: set.toStart,
          price: null,
        } as TransportFeePriceType
      }) || null
    });

    setDataSource(data);
  }

  const removeRow = (index: number) => {
    const data = [...dataSource.filter((_, i) => i !== index)];
    setDataSource(data);
  }

  const onDataChange = (index: number, key: TransportFeeDataUpdateType, value: string | null) => {
    const data = [...dataSource];
    data[index][key] = value;
    setDataSource(data);
  }

  const onToDataChange = (index: number, key: TransportFeePriceUpdateType, value: string | null) => {
    const data = [...dataSource.map(set => {
      return {
        ...set,
        prices: set.prices?.map((item, i) => {
          const tmp = { ...item };

          if (i === index) {
            tmp[key] = value;
          }

          return tmp;
        }) || null
      }
    })];

    setDataSource(data);
  }

  const onPriceDataChange = (rowIndex: number, colIndex: number, value: string | null) => {
    const data = [...dataSource];
    const tmp = data[rowIndex];

    tmp.prices = tmp.prices?.map((item, i) => {
      const tmp = { ...item };

      if (i === colIndex) {
        tmp.price = value;
      }

      return tmp;
    }) || null

    data[rowIndex] = tmp;
    setDataSource(data);
  }

  const columns: TableColumnsType<object> = [
    {
      key: "countryCode",
      dataIndex: "countryCode",
      title: _t('country'),
      width: 100,
      render: (value, _record, index) => {
        return <Input value={value} minLength={2} maxLength={2}
          onChange={(e) => onDataChange(index, "countryCode", e.target.value)} />
      }
    },
    {
      key: "from",
      dataIndex: "from",
      title: _t('from').charAt(0).toUpperCase() + _t('from').slice(1),
      width: 300,
      render: (_value, record, index) => {
        const set = record as TableRowType;
        const prefix = `${set.countryCode || ''}-`;

        return (
          <Input.Group compact>
            <Input style={{ width: '50%' }} placeholder="9999" suffix="-" prefix={prefix} value={set.fromStart || ''}
              onChange={(e) => onDataChange(index, "fromStart", e.target.value)} />
            <Input style={{ width: '50%' }} placeholder="9999" prefix={prefix} value={set.fromEnd || ''}
              onChange={(e) => onDataChange(index, "fromEnd", e.target.value)} />
          </Input.Group>
        );
      }
    }
  ];

  const columnItem = dataSource[0];
  columnItem?.prices?.forEach((set, index) => {
    const key = `${set.countryCode} ${set.toStart} - ${set.toEnd}`;
    const prefix = `${set.countryCode}-`;

    columns.push({
      key: 'p' + index,
      dataIndex: key,
      title: (_) => {
        return (
          <Input.Group compact>
            <div style={{ "display": "flex", "flexDirection": "row" }}>
              <Input placeholder="9999" className="flex-grow" suffix="-" prefix={prefix} value={set.toStart || ''}
                onChange={(e) => onToDataChange(index, "toStart", e.target.value)} />
              <Input placeholder="9999" className="flex-grow" prefix={prefix} value={set.toEnd || ''}
                onChange={(e) => onToDataChange(index, "toEnd", e.target.value)} />
              <Popconfirm
                placement="topLeft"
                onConfirm={() => removeColumn(index)}
                icon={<Icon fill="red" name="information-circle-outline" />}
                title={_t("msg.confirm_delete")}
              >
                <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
              </Popconfirm>
            </div>
          </Input.Group>
        );
      },
      render: (_value, record, rowIndex) => {
        const set = record as TableRowType;
        return <Input value={set[key]} suffix="kr"
          onChange={(e) => onPriceDataChange(rowIndex, index, e.target.value)} />
      }
    })
  });

  columns.push({
    key: "actions",
    dataIndex: "actions",
    render: (_value, _record, index) => {
      return (
        <Popconfirm
          placement="topLeft"
          onConfirm={() => removeRow(index)}
          icon={<Icon fill="red" name="information-circle-outline" />}
          title={_t("msg.confirm_delete")}
        >
          <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
        </Popconfirm>
      );
    }
  })

  const data: TableRowType[] = dataSource.map((item, index) => {
    const tmp = {
      key: 'd' + index,
      countryCode: item.countryCode,
      fromStart: item.fromStart,
      fromEnd: item.fromEnd,
    } as TableRowType

    item.prices?.forEach(set => {
      const key = `DK ${set.toStart} - ${set.toEnd}`;

      tmp[key] = set.price;
    });

    return tmp;
  });

  return (
    <>
      <Button onClick={() => setShowModal(true)} loading={isLoading}>
        {_t('customize', 'transport_fees')}
      </Button>

      <Modal width={"80%"} onCancel={close} onOk={save} visible={showModal} title={_t('transport_fees')}
        okText={_t('save')}>
        <Row align="middle" gutter={14}>
          <Col flex="none">
            <label>{_t("transport_fees_date")}:</label>
          </Col>

          <Col flex="auto">
            <DateSelect disabled={isLoading} value={dataDate} onChange={(val) => setDataDate(val)} />
          </Col>
        </Row>


        <Divider />

        <div className="text-right">
          <Button onClick={addColumn}>{_t('add', 'column')}</Button>
        </div>

        <Table
          pagination={false}
          bordered
          loading={isLoading}
          style={{ margin: "10px 0" }}
          columns={columns}
          dataSource={data}
        />

        <Button onClick={addRow}>{_t('add', 'row')}</Button>
      </Modal>
    </>
  );
}

export default SystemTransportFees;
