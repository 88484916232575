import React, { FC, useState } from "react";
import { Alert, Button, Menu, message, Space, Typography } from "antd";
import DropdownButton from "antd/lib/dropdown/dropdown-button";
import Icon from "../../../../assets/icons/icon";
import { LoadingOutlined } from "@ant-design/icons";
import ServerSelectBox from "../../../../components/serverSelectBox";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { billingDestinationsAutoComplete } from "../../../../services/autocompleteService";
import { openFileLink } from "../../../../services/mediaService";
import { saveTaxDraftInvoice, sentTaxInvoice, sentTaxInvoiceAgain } from "../../../../services/taxService";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import { wasPaidWithInvoice } from "../../../../utilities/paymentTypeHelpers";

interface TaxInvoiceActionsProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaxInvoiceActions: FC<TaxInvoiceActionsProps> = ({ setLoading }) => {
  const { taxCase, setTaxCase, canSendInvoice, isAdmin } = TaxCaseStore.useContainer();

  const initialBillingDestionation = taxCase.billingDestinationId || -1;
  const [billingDestinationId, setBillingDestinationId] = useState<number>(initialBillingDestionation);

  const sentInvoice = async () => {
    try {
      setLoading(true);
      const { data } = await sentTaxInvoice(taxCase.id, billingDestinationId);
      setTaxCase(data);
      message.success(_t("invoice", "send"));
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const sentInvoiceAgain = async () => {
    try {
      setLoading(true);
      await sentTaxInvoiceAgain(taxCase.id);
      message.success(_t("invoice", "send"));
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const sentInvoiceDraft = async () => {
    try {
      setLoading(true);
      await saveTaxDraftInvoice(taxCase.id, billingDestinationId);
      message.success(_t("invoice", "saved"));
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const invoiceIsSent = !!taxCase.links?.invoiceLink;
  const invoiceIsGenerating = !!taxCase.invoiceStartedAt && !invoiceIsSent;

  const getInvoiceBtnLabel = () => {
    if (invoiceIsGenerating)
      return (
        <>
          <LoadingOutlined />
          &nbsp; &nbsp;
          {_t("msg.generating_invoice")}...
        </>
      );

    if (invoiceIsSent) return _t("resend_invoice");

    return _t("send", "invoice");
  };

  const invoiceMenu = (
    <Menu>
      <Menu.Item key="1" onClick={sentInvoiceDraft}>
        {_t("create_invoice_draft")}
      </Menu.Item>
      <Menu.Item key="2" onClick={sentInvoice}>
        {_t("send", "invoice", " new ")}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Typography.Title level={5}>{_t("invoice")}</Typography.Title>
      {taxCase.client?.typeId !== 2 && (
        <div>
          <ServerSelectBox
            className="w-100 mb-1"
            value={billingDestinationId}
            onChange={(v) => setBillingDestinationId(Array.isArray(v) ? -1 : v)}
            addModalType="addBillingDestination"
            onItemAdded={(_, id) => typeof id === "number" && setBillingDestinationId(id)}
            addModalExtraValues={{ parentId: taxCase.client?.dealerId }}
            placeholder={_t("billing_destination")}
            filters={`filter[dealerId]=${taxCase.client?.dealerId}`}
            apiUrl={billingDestinationsAutoComplete()}
          />
        </div>
      )}
      <div className="text-right">
        <Space>
          <Button
            type="primary"
            disabled={!taxCase.links?.invoiceLink}
            ghost
            onClick={() => openFileLink(taxCase.links?.invoiceLink)}
          >
            <Icon name="download-outline" size="large" /> {_t("invoice")}
          </Button>
          <DropdownButton
            type="primary"
            onClick={invoiceIsSent ? sentInvoiceAgain : sentInvoice}
            disabled={invoiceIsGenerating || !canSendInvoice}
            overlay={invoiceMenu}
          >
            {getInvoiceBtnLabel()}
          </DropdownButton>
        </Space>
      </div>
      { wasPaidWithInvoice(taxCase.paymentDetails) && isAdmin && (
        <Alert className="mt-2" message={<>
          {taxCase.taxCaseValueConfig?.paymentTermCode.title}
          :
          &nbsp;
          {
            taxCase.taxCaseValueConfig?.paymentTermCode.options?.find((item) => {
              return item.id === taxCase.taxCaseValueConfig?.paymentTermCode?.value
            })?.title
          }
        </>}
        />
      )}
    </>
  );
};

export default TaxInvoiceActions;
