import React, { FC, useState } from "react";
import { Button, InputNumber, Popover, Space } from "antd";
import getCombinations from "../../../../../utilities/combinations";
import getBestCombination from "../../calc/getBestCombination";
import _t from "../../../../../lang/translate";
import { TradePriceCalcInputType } from "../../calc/tradePriceCalculatorTypes";

interface AdvCalcAutoPickBestAdsProps {
  getFieldsValue: (name?: any) => any;
  setFieldsValue: (value: any) => void;
  initCalcInput: Partial<TradePriceCalcInputType>;
}

const AdvCalcAutoPickBestAds: FC<AdvCalcAutoPickBestAdsProps> = ({ getFieldsValue, setFieldsValue, initCalcInput }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [wishedAdsCount, setWishedAdsCount] = useState<number>(2);
  const data = getFieldsValue(true);
  const adsList: any[] = data?.vehicles || [];
  const maxCount: number = adsList.length > 1 ? adsList.length - 1 : 0;
  const minCount: number = maxCount > 1 ? 2 : 0;

  const startCalc = () => {
    try {
      // Calculator validation goes here (exp. vehicle data are correct..)
      if (!Array.isArray(adsList) || adsList.length < 1 || wishedAdsCount - 1 >= adsList.length) return;

      // Get list of index and find the uniqe combinations.
      const indexList = adsList.map((e, i) => i);
      const combinations = getCombinations(indexList, wishedAdsCount);

      // Get best combination out of combinations array.
      if (!combinations?.length) throw new Error("Faild to load ads combinations");
      const { bestCombination, bestTradePrice } = getBestCombination(data, combinations, initCalcInput);
      if (Array.isArray(bestCombination)) {
        setFieldsValue({
          vehicles: adsList.map((v, i) => ({ ...v, enabled: bestCombination.includes(i) })),
          tradePrice: bestTradePrice,
        });
        setVisible(false);
      } else {
        throw new Error("Faild to calculate");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Popover
      content={
        <Space>
          <InputNumber value={wishedAdsCount} onChange={setWishedAdsCount} min={minCount} max={maxCount} />
          <Button type="primary" onClick={startCalc}>
            Start
          </Button>
        </Space>
      }
      title={_t("find_the_best_cars")}
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
    >
      <Button disabled={maxCount < 2} className="mr-05">
        {_t("auto_pick")}
      </Button>
    </Popover>
  );
};

export default AdvCalcAutoPickBestAds;
