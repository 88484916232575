import { Alert, Radio, Spin } from "antd";
import React, { FC, useEffect } from "react";
import { useEnums } from "../useEnum";
import _t from "../../lang/translate";
import { EnumKeys } from "../../types/appTypes";
import { RadioChangeEvent, RadioGroupProps } from "antd/lib/radio";

interface EnumRadioProps extends RadioGroupProps {
  nameKey: EnumKeys;
  defaultActiveFirstOption?: boolean;
}

const EnumRadio: FC<EnumRadioProps> = ({
  nameKey,
  defaultActiveFirstOption,
  value,
  buttonStyle = "solid",
  onChange,
  ...rest
}) => {
  const [enums, isLoading, isError] = useEnums(nameKey);

  useEffect(() => {
    if (defaultActiveFirstOption && enums && enums.length && !value) {
      if (onChange) {
        onChange({
          target: { checked: true, type: "radio", value: enums[0].id },
        } as RadioChangeEvent);
      }
    }
  }, [value, enums, defaultActiveFirstOption, onChange]);

  return (
    <>
      {isLoading && (
        <Spin spinning>
          <Alert
            message={_t("msg.fetching_options")}
            className="mt-1"
            type="info"
            closable
          />
        </Spin>
      )}
      {isError && (
        <Alert
          message={_t("msg.could_not_fetch_options")}
          className="mt-1"
          type="error"
          closable
        />
      )}
      {enums && (
        <Radio.Group
          optionType="button"
          buttonStyle={buttonStyle}
          value={value}
          onChange={onChange}
          {...rest}
        >
          {enums.map((e, i) => (
            <Radio.Button key={e.id} value={e.id}>
              {e.title}
            </Radio.Button>
          ))}
        </Radio.Group>
      )}
    </>
  );
};

export default EnumRadio;
