import { FC, useCallback, useEffect, useState } from "react";
import { Button, message, Popconfirm, Result, Space, Typography } from "antd";
import Icon from "../assets/icons/icon";
import { Link, useHistory, useLocation } from "react-router-dom";
import _t from "../lang/translate";
import PageTitle from "../layout/pageTitle";
import { IdType, LocationStateType, ScreenProps } from "../types/appTypes";
import { PaginationedData } from "../types/apiTypes";
import DataTable from "../components/table/dataTable";
import { CustomColumnsType, RenderFunc } from "../components/table/dataTableTypes";
import { useApi } from "../services/useApi";
import { DealerListItemType, DealerProductStatusesType } from "../types/dealerTypes";
import { dealersUrl, deleteDealer, getDealerStatuses } from "../services/dealerService";
import { getPath } from "../routes/appRoutes";
import { pageKey, pageSizeKey, searchKey, statusKey } from "../services/urlQueryService";
import UserStore from "../contexts/userStore";
import DealersExport from "./forms/dealer/dealersExport";
import debounce from "lodash/debounce";
import StatusTag from "../components/statusTag";
import useActiveStatus from "../hooks/useActiveStatus";
import StatusTabs from "../components/statusTabs";

function generateProductTags(prodStatus: DealerProductStatusesType | null) {
  const isAllActive = prodStatus && !Object.values(prodStatus).includes(false);
  if (isAllActive)
    return (
      <StatusTag size="small" color="blue">
        {_t("all", "products")}
      </StatusTag>
    );
  return (
    <Space>
      {prodStatus?.tax && (
        <StatusTag size="small" color="blue">
          {_t("tax")}
        </StatusTag>
      )}
      {prodStatus?.taxDoc && (
        <StatusTag size="small" color="blue">
          {_t("tax-doc")}
        </StatusTag>
      )}
      {prodStatus?.leasing && (
        <StatusTag size="small" color="blue">
          {_t("leasing")}
        </StatusTag>
      )}
      {prodStatus?.partnerLeasing && (
        <StatusTag size="small" color="blue">
          {_t("partner_leasing")}
        </StatusTag>
      )}
      {prodStatus?.import && (
        <StatusTag size="small" color="blue">
          {_t("import")}
        </StatusTag>
      )}
    </Space>
  );
}

const Dealers: FC<ScreenProps> = ({ title }) => {
  const location = useLocation<LocationStateType>();
  const { hasPermission } = UserStore.useContainer();
  const [refreshId, setRefreshId] = useState<string | undefined>();
  const statuses = getDealerStatuses();
  const [activeStatusId, setActiveStatusId] = useActiveStatus(Object.values(statuses));

  const [{ data, isLoading, isError }, setUrl, setData] = useApi<PaginationedData<DealerListItemType>>("", {
    data: [],
  });

  const history = useHistory();

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (activeStatusId !== -1) {
      query.set(statusKey, activeStatusId.toString());
    }
    if (typeof refreshId === "string") {
      query.set("refreshId", refreshId);
    }
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    const url = dealersUrl(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [delayedSetUrl, setUrl, location.search, activeStatusId, refreshId]);

  const handleDelete = async (id: IdType) => {
    const originalData = { ...data };
    try {
      setData((state) => ({
        ...state,
        data: originalData.data.filter((item) => item.id !== id),
      }));
      await deleteDealer(id);
      message.success(_t("deleted"));
    } catch (ex) {
      setData(originalData);
      message.error(ex.response?.data?.message || _t("msg.not_deleted"));
    }
  };

  const columns: CustomColumnsType<DealerListItemType> = [
    {
      title: "#",
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 90,
      render: (text, record) =>
        hasPermission("screen.dealer") ? (
          <Link to={getPath("dealer", record.id)}>
            <strong>{text}</strong>
          </Link>
        ) : (
          text
        ),
      fixed: "left",
    },
    {
      title: _t("name"),
      key: "name",
      dataIndex: "name",
      width: 150,
      render: (text) => (
        <Typography.Paragraph ellipsis={{ rows: 1 }} className="m-0">
          {text}
        </Typography.Paragraph>
      ),
    },
    {
      title: _t("address"),
      key: "address",
      dataIndex: "address",
      width: 150,
      ellipsis: true,
    },
    {
      title: _t("zip", "city", " & "),
      key: "zip",
      width: 110,
      ellipsis: true,
      dataIndex: "zip",
      render: (text, record) => `${text ? text : ""} ${record.city ? record.city : ""}`,
    },
    {
      title: _t("email"),
      key: "email",
      dataIndex: "email",
      width: 220,
    },
    {
      title: _t("cvr"),
      key: "cvr",
      dataIndex: "cvr",
      width: 90,
      hidden: true,
    },
    {
      title: _t("phone"),
      key: "phone",
      dataIndex: "phone",
      hidden: true,
    },
    {
      title: _t("payment_term"),
      key: "taxPaymentTerm",
      dataIndex: "taxPaymentTerm",
      hidden: true,
      sorter: true,
    },
    {
      title: _t("prospectId"),
      key: "prospectId",
      dataIndex: "prospectId",
      hidden: true,
    },
    {
      title: _t("contact_person"),
      key: "contactPerson",
      dataIndex: "contactPerson",
      hidden: true,
    },
    {
      title: _t("clip"),
      key: "clipAmount",
      dataIndex: "clipAmount",
      sorter: true,
      hidden: true,
      width: 60,
    },
    {
      title: _t("credits"),
      key: "creditAmount",
      dataIndex: "creditAmount",
      sorter: false,
      hidden: true,
      width: 60,
    },
    {
      title: _t("active_products"),
      key: "activeProduct",
      ellipsis: true,
      width: 190,
      render: (_, record) => {
        const prodStatus = record.productStatuses;
        return generateProductTags(prodStatus);
      },
    },
    {
      title: _t("subscription_package"),
      key: "subscriptionPackage",
      dataIndex: "subscriptionPackage",
      sorter: false,
      hidden: false,
      width: 140,
    },
    {
      title: _t("priorities"),
      key: "priority",
      dataIndex: "priorities",
      ellipsis: true,
      sorter: true,
      width: 140,
      render: (_, record) => {
        const prodStatus = record.productPriorities;
        return generateProductTags(prodStatus);
      },
    },
    {
      title: _t("status"),
      key: "status",
      sorter: true,
      width: 90,
      dataIndex: "status",
      render: (status) => <StatusTag status={status} size="small" />,
    },
  ];

  const tableActions: RenderFunc<DealerListItemType> = (_, record) => {
    return hasPermission("dealer.delete") ? (
      <Popconfirm
        placement="topLeft"
        onConfirm={() => handleDelete(record.id)}
        icon={<Icon fill="red" name="information-circle-outline" />}
        title={
          <div>
            {_t("msg.confirm_delete")}&nbsp;
            <strong>
              {record.id} : {record.name}
            </strong>
          </div>
        }
      >
        <Button className="muted delete-btn" type="text" shape="circle" icon={<Icon name="trash-outline" />} />
      </Popconfirm>
    ) : null;
  };

  const handleTabChange = (nextKey?: string) => {
    const query = new URLSearchParams(location.search);
    query.delete(pageKey);
    query.delete(statusKey);
    setActiveStatusId(nextKey || "-1");
    history.push({ ...history.location, search: query.toString() });
  };

  const handleRefresh = () => setRefreshId(new Date().getSeconds().toString());

  return (
    <>
      <PageTitle
        fluid
        headerMargin={false}
        title={title}
        extra={
          <>
            <DealersExport />
            {hasPermission("dealer.create") && (
              <Button type="primary">
                <Link to={getPath("dealer", "new")}>{_t("new_dealer")}</Link>
              </Button>
            )}
          </>
        }
      >
        <StatusTabs
          statuses={Object.values(statuses)}
          onTabChange={handleTabChange}
          activeTabId={`${activeStatusId}`}
        />
      </PageTitle>
      {isError ? (
        <Result status="error" title={_t("msg.unknown_error")} />
      ) : (
        <DataTable<DealerListItemType>
          loading={isLoading}
          onRefresh={handleRefresh}
          renderActions={tableActions}
          columnStorageKey="DEALER"
          columns={columns}
          dataSource={data.data}
          meta={data.meta}
        />
      )}
    </>
  );
};

export default Dealers;
