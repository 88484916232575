import React, { FC } from "react";
import { Typography } from "antd";
import Details from "../../../components/detailsList";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";
import { Vehicle } from "../../../types/appTypes";
import ResellerTaxCaseStore from "../../../contexts/resellerTaxCaseStore";

const { Link } = Typography;

const ResellerTaxVehicleInfo: FC<{ vehicle?: Vehicle }> = ({ vehicle }) => {
    const { resellerTaxCase } = ResellerTaxCaseStore.useContainer();
    const values = vehicle ? { ...vehicle } : { ...resellerTaxCase.vehicle };
    const {
        brand,
        model,
        typeId,
        type,
        equipmentVariant,
        firstRegDate,
        vin,
        mileage,
        quality,
        useCase,
        fuel,
        engineSize,
        horsePower,
        gearbox,
        body,
        emissionAmount,
        electricConsumption,
        electricRange,
        emissionUnit,
        isHighWeight,
        isNew,
        link,
    } = values;

    const formatValue = (v?: string | null) => (v && v?.trim() ? v : "-");
    return (
        <Details borderLess>
            {link && (
                <Details.Item
                    value={
                        <Link ellipsis className="max-width-240" href={link} target="_blank">
                            {link}
                        </Link>
                    }
                    className="mb-1"
                />
            )}
            <Details.Item label={_t("type")} value={formatValue(type)} />
            <Details.Item label={_t("new", "vehicle") + "?"} value={format.yesOrNo(isNew)} className="mb-1" />

            <Details.Item label={_t("brand")} value={formatValue(brand)} />
            <Details.Item label={_t("model")} value={formatValue(model)} />
            <Details.Item label={_t("equipment_variant")} value={formatValue(equipmentVariant)} />
            <Details.Item label={_t("firstRegDate")} value={format.date(firstRegDate)} />
            <Details.Item label={_t("vin")} value={formatValue(vin)} />
            <Details.Item label={_t("mileage")} value={format.milage(mileage)} className="mb-1" />

            <Details.Item label={_t("engine_size")} value={formatValue(engineSize)} />
            <Details.Item label={_t("engine_power")} value={formatValue(horsePower)} />
            <Details.Item label={_t("gearbox")} value={formatValue(gearbox)} />
            <Details.Item label={_t("trim")} value={formatValue(body)} />
            <Details.Item label={_t("car_condition")} value={formatValue(quality)} />
            <Details.Item label={_t("special_use")} value={formatValue(useCase)} className="mb-1" />

            <Details.Item label={_t("fuel_type")} value={formatValue(fuel)} />
            {typeId === 2 && <Details.Item label={_t("over_3000_kg")} value={format.yesOrNo(isHighWeight)} />}
            <Details.Item label={_t("co2_emission")} value={format.emission(emissionAmount)} />
            <Details.Item label={_t("co2_measurement")} value={formatValue(emissionUnit)} />
            <Details.Item label={_t("battery_usage")} value={format.batteryUsage(electricConsumption)} />
            <Details.Item label={_t("battery_range")} value={format.milage(electricRange)} />
        </Details>
    );
};

export default ResellerTaxVehicleInfo;
