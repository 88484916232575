import { FC } from "react";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { ChartDataType } from "../../types/statisticsType";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface LineChartPlainProps {
  data: ChartDataType;
}

const LineChartPlain: FC<LineChartPlainProps> = ({ data }) => {
  const options: any = {
    bezierCurve: true,
    responsive: true,
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      tooltip: false,
      legend: false,
    },
  };

  const nextData: any = {
    ...data,
    datasets: data.datasets?.map((line, index) => ({
      ...line,
      lineTension: 0.4,
      borderWidth: 2,
      borderColor: index === 0 ? "#0299FB" : "#BACAD3",
    })),
  };

  return <Line height={50} options={options} data={nextData} />;
};

export default LineChartPlain;
