import CollapseCard from "../../../../components/collapseCard";
import _t from "../../../../lang/translate";
import React, { FC, useState } from "react";
import { Button, Dropdown, Menu, message, Space, Tabs } from "antd";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import { ResellerTaxCase } from "../../../../types/resellerTaxTypes";
import openModal from "../../../../components/modal/openModal";
import Icon from "../../../../assets/icons/icon";
import { isAxiosError } from "../../../../utilities/typeGuard";
import { requestOptimization, requestRecalculation, updateFromTaxCase } from "../../../../services/resellerTaxService";
import ResellerTaxOfferEdit from "./resellerTaxOfferEdit";
import log from "../../../../services/logService";

interface Props {
  resellerTaxCase: ResellerTaxCase;
  setResellerTaxCase: (newCase: ResellerTaxCase) => void;
}

const TabActions: FC<Props> = ({ resellerTaxCase, setResellerTaxCase }) => {
  const [loading, setLoading] = useState<boolean>(false);

  async function storeTaxCase() {
    try {
      const data = await openModal<ResellerTaxCase>("resellerTaxToTaxCase", { id: resellerTaxCase.id });
      if (data?.taxCaseId) {
        message.success(_t("tax", "created"));
        setResellerTaxCase({ ...resellerTaxCase, taxCaseId: data.taxCaseId });
      } else {
        log("Exporting to tax case returned data without Id", { data });
      }
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    }
  }

  async function loadTaxCaseEvaluation() {
    setLoading(true);
    try {
      const { data } = await updateFromTaxCase(resellerTaxCase.id, { "offer": true, vehicle: true });
      message.success(_t("offer", "updated"));
      setResellerTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  async function requestTaxCaseOptimization() {
    setLoading(true);
    try {
      const { data } = await requestOptimization(resellerTaxCase.id);
      message.success(_t("optimization", "requested"));
      setResellerTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  async function loadTaxCaseOptimization() {
    setLoading(true);
    try {
      const { data } = await updateFromTaxCase(resellerTaxCase.id, { "optimization": true, vehicle: true });
      message.success(_t("optimization", "updated"));
      setResellerTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  async function requestTaxCaseRecalculation() {
    setLoading(true);
    try {
      const { data } = await requestRecalculation(resellerTaxCase.id);
      message.success(_t("calculation", "requested"));
      setResellerTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  const canRequestOptimization = !resellerTaxCase.internalChecklist.optimizationRequested?.status && resellerTaxCase.flags.paid;

  let menuItems = [];
  if (!resellerTaxCase.taxCaseId) {
    menuItems.push(<Menu.Item onClick={storeTaxCase} key="senddata">{_t('send', 'data')}</Menu.Item>);
  } else {
    menuItems = [
      // If there is a tax case
      !!resellerTaxCase.taxCaseId ?
        <Menu.Item disabled={true} key="datasent"><s>{_t('data', 'sent')}</s></Menu.Item> : null,

      // If there is a tax case, and has not been accepted
      !!resellerTaxCase.taxCaseId ? <Menu.Item disabled={resellerTaxCase.flags.accepted} key="senteval"
        onClick={loadTaxCaseEvaluation}>{_t('fetch', 'preassessment')}</Menu.Item> : null,

      // If there is a tax case, and it has been assessed
      !!resellerTaxCase.taxCaseId ?
        <Menu.Item disabled={!canRequestOptimization} key="optimized"
          onClick={requestTaxCaseOptimization}>{_t('request', 'optimization')}</Menu.Item> : null,


      // If there is a tax case, and it has been optimized
      !!resellerTaxCase.taxCaseId ?
        <Menu.Item disabled={!resellerTaxCase.internalChecklist.optimizedTaxCase || resellerTaxCase.flags.receipted} key="getopt"
          onClick={loadTaxCaseOptimization}>Hent
          fastsættelse</Menu.Item> : null,

      !!resellerTaxCase.taxCaseId ?
        <Menu.Item disabled={!resellerTaxCase.flags.expired} key="recalc" onClick={requestTaxCaseRecalculation}>Anmod
          genberegning</Menu.Item> : null,
    ].filter(Boolean);
  }
  const newMenu = (
    <Menu>
      {menuItems}
    </Menu>
  );

  return (
    <Dropdown
      className="p-0"
      overlay={newMenu}
      placement="bottomRight"
      arrow
    >
      <Button
        size="small"
        type="link"
        loading={loading}
        style={{ fontSize: "12px" }}
      >
        <Space>
          {_t('actions')}
          <Icon
            name="chevron-down-outline"
            size="small"
            style={{ fontSize: "12px" }}
          />
        </Space>
      </Button>
    </Dropdown>
  );
}

const ResellerTaxDetailsEdit = () => {
  const { resellerTaxCase, setResellerTaxCase } = ResellerTaxCaseStore.useContainer();

  return (
    <>
      <CollapseCard
        title={_t("evaluation")}
      >
        <Tabs
          defaultActiveKey={resellerTaxCase.flags.accepted ? "2" : "1"}
          tabBarExtraContent={<TabActions resellerTaxCase={resellerTaxCase} setResellerTaxCase={setResellerTaxCase} />}
        >
          <Tabs.TabPane tab={_t('offer')} key="1">
            <ResellerTaxOfferEdit optMode={false} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={_t('invoice', 'receipt', ` ${_t('and')} `)} key="2">
            <ResellerTaxOfferEdit optMode={true} />
          </Tabs.TabPane>
        </Tabs>
      </CollapseCard>
    </>
  );
}

export default ResellerTaxDetailsEdit;
