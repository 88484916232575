import http from "./httpService";
import { EnumItemType, EnumKeys, EnumType, ModulTypes } from "../types/appTypes";
import { useCallback, useEffect, useState } from "react";
import { getSavedEnums, saveEnums } from "./authService";
import getApiEndpoint from "./apiEndpoint";
import { extractDataFromObj } from "../utilities/objectUtil";

const apiEndpoint = `${getApiEndpoint()}/misc/enums`;

export function useEnums(): [EnumType | null, boolean, boolean] {
  const [enumsLoading, setEnumsLoading] = useState<boolean>(false);
  const [enumsError, setEnumsError] = useState<boolean>(false);
  const [enums, setEnums] = useState<EnumType | null>(getSavedEnums());

  const storageWatcher = useCallback(
    (e: StorageEvent) => {
      if (e.key === "enums") setEnums(e.newValue ? JSON.parse(e.newValue) : null);
    },
    [setEnums]
  );

  useEffect(() => {
    window.addEventListener("storage", storageWatcher);

    return () => {
      window.removeEventListener("storage", storageWatcher);
    };
  }, [storageWatcher]);

  useEffect(() => {
    const fetchEnums = async () => {
      try {
        setEnumsLoading(true);
        const { data } = await http.get(apiEndpoint);
        if (data === null) throw Error("Kunne ikke hent system enums!");
        saveEnums(data);
      } catch (x) {
        setEnumsError(true);
      } finally {
        setEnumsLoading(false);
      }
    };

    if (enums === null && !enumsLoading) fetchEnums();
  }, [enums, enumsLoading, setEnums, setEnumsError, setEnumsLoading]);

  return [enums, enumsLoading, enumsError];
}

export const geStatus = (id: number) => {
  const statuses = getSavedEnums()?.status;
  return statuses?.filter((item) => item.id === id)[0];
};

export const geStatusByContext = (type: ModulTypes) => {
  const statuses = getSavedEnums()?.status;
  return statuses?.filter((item) => item.contexts?.includes(type)) || [];
};

export const getEnumTitleById = (id: number, path: EnumKeys) => {
  const enums = getSavedEnums();
  const enumItems = extractDataFromObj<EnumItemType[]>(enums, path);
  return enumItems?.find((item) => item.id === id)?.title || null;
};
