import { Button, message, Typography } from "antd";
import React, { FC, useState } from "react";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { scrapeLink } from "../../services/miscService";
import { ScrapedVehicleType } from "../../types/appTypes";
import { isAxiosError } from "../../utilities/typeGuard";

interface LinkBoxProps {
  link: string;
  onFetch?: (data: ScrapedVehicleType) => void;
}

const LinkBox: FC<LinkBoxProps> = ({ link, onFetch }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const fetchVehicle = async () => {
    try {
      setLoading(true);
      const { data } = await scrapeLink(link);
      onFetch && onFetch(data.data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const showFetchBtn = onFetch && isLinkFetchable(link);

  return (
    <div className="link-box-wrapper">
      <div className="link-box-url">
        <Typography.Link
          href={link}
          target="_blank"
          ellipsis
          className={`link-box${showFetchBtn ? " with-suffix" : ""}`}
        >
          <Icon name="open-outline" /> {link}
        </Typography.Link>
      </div>
      {showFetchBtn && (
        <div>
          <Button className="link-box-btn" loading={loading} onClick={fetchVehicle}>
            {_t("download")}
          </Button>
        </div>
      )}
    </div>
  );
};

const isLinkFetchable = (link: string) => {
  try {
    const url = new URL(link);
    return url.host.includes("mobile.de") || url.host.includes("autoscout24.de") || url.host.includes("autoscout24.nl");
  } catch (error) {
    return false;
  }
};

export default LinkBox;
