import http from "./httpService";
import { GetStatusesType, IdType } from "../types/appTypes";
import { archivedKey, getApiQuery, urgentKey } from "./urlQueryService";
import getApiEndpoint from "./apiEndpoint";
import { PostsType } from "../types/postTypes";
import _t from "../lang/translate";

const apiEndpoint = `${getApiEndpoint()}/post`;

export function postUrl(id: string | number) {
  return `${apiEndpoint}/${id}`;
}

export function postsUrl(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function savePost(data: PostsType) {
  if (data.id !== 0) return http.put(postUrl(data.id), data);

  return http.post(apiEndpoint, data);
}

export function getPost(id: IdType) {
  return http.get(postUrl(id));
}

export function deletePost(id: IdType) {
  return http.delete(postUrl(id));
}

// Filter statuses on table page:

export const getPostStatuses: GetStatusesType = () => {
  return {
    all: { id: -1, title: _t("all") },
    [urgentKey]: { id: 1, title: _t("urgent") },
    [archivedKey]: { id: 2, title: _t("archived") },
  };
};
