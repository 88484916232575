import { Button, Modal, Popconfirm } from "antd";
import React, { FC, useState } from "react";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { LeasingPartialCaseType, LeasingValidationType } from "../../../types/leasingTypes";
import LeasingSendValidationForm from "./leasingSendValidationForm";

interface LeasingSendValidationProps {
  inputSavedStatus: boolean
}

const LeasingSendValidation: FC<LeasingSendValidationProps> = ({ inputSavedStatus }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { leasingCase, setLeasingCase, setAdminValues, adminValues } = LeasingStore.useContainer();
  const { flags, activeOffer } = leasingCase;
  const { readonly, inputLocked, validationStarted } = flags;
  const disabled = readonly || inputLocked || validationStarted || !activeOffer;

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  const handleSentValidation = (response: LeasingPartialCaseType, validation: LeasingValidationType) => {
    setLeasingCase({ ...leasingCase, ...response });
    adminValues && setAdminValues({ ...adminValues, validation });
    closeModal();
  };

  let modalTitle = `${_t("application_form")}`;
  if (leasingCase.partnerCase) {
    modalTitle += ` ${_t("to").toLocaleLowerCase()} ${_t("partner_leasing")}`;
  }

  return (
    <>
      {inputSavedStatus ? (
        <Button type="primary" disabled={disabled} onClick={openModal}>
          {_t("send", "application")}
        </Button>
      ) : (
        <Popconfirm title={_t('dirty_state_case')} onConfirm={openModal}>
          <Button type="primary" disabled={disabled}>
            {_t("send", "application")}
          </Button>
        </Popconfirm>
      )}
      <Modal width={800} visible={isVisible} onCancel={closeModal} title={modalTitle} footer={null}>
        <LeasingSendValidationForm onCancel={closeModal} leasingCase={leasingCase} onSent={handleSentValidation} />
      </Modal>
    </>
  );
};

export default LeasingSendValidation;
