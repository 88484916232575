import { Button, Col, Divider, Drawer, Result, Row, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import Icon from "../../../../assets/icons/icon";
import InfoList from "../../../../components/infoList";
import LayoutStore from "../../../../contexts/layoutStore";
import _t from "../../../../lang/translate";
import { useApi } from "../../../../services/useApi";
import { ApiResponseObject } from "../../../../types/apiTypes";
import consts from "../../../../utilities/consts";
import format from "../../../../utilities/formatNumbers";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import { OfferArchiveType } from "../../../../types/resellerTaxTypes";
import { getSavedOfferOptimizationUrl } from "../../../../services/resellerTaxService";

interface OfferHistoryDrawerProps {
  optMode?: boolean;
}

const initialData: OfferArchiveType = {
  id: 0,
  tradePrice: null,
  newPrice: null,
  total: null,
  registrationFee: null,
  licensePlateFee: null,
  caseFee: null,
  collateralFee: null,
  createdAt: null,
  person: null,
};

const ResellerTaxOfferArchived: FC<OfferHistoryDrawerProps> = ({ optMode = false }) => {
  const { screen } = LayoutStore.useContainer();
  const { resellerTaxCase } = ResellerTaxCaseStore.useContainer();
  const [visible, setVisible] = useState<boolean>(false);
  const [{ data, isError, isLoading }, setUrl] = useApi<ApiResponseObject<OfferArchiveType[]>>("", { data: [initialData] });
  const id = resellerTaxCase.id;

  useEffect(() => {
    if (visible) setUrl(getSavedOfferOptimizationUrl(id, optMode));
  }, [visible, setUrl, id, optMode]);

  const openDrawer = () => setVisible(true);
  const closeDrawer = () => setVisible(false);

  return (
    <>
      <Button tabIndex={-1} onClick={openDrawer}>
        <Icon name="file-tray-full-outline" size="medium" /> {_t("archive")}
      </Button>
      <Drawer
        footer={null}
        title={_t("saved_offers")}
        width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
        visible={visible}
        onClose={closeDrawer}
        className="calculation-table"
      >
        {isError && <Result status="error" />}
        {isLoading && <Spin size="large" />}
        {data.data &&
          data.data.map((offer, i) => (
            <Row gutter={[16, 16]} key={i}>
              <Col span={24}>
                {offer?.person && (
                  <p className="text-center w-100">
                    <strong>{offer.person?.fullName}</strong>
                    <a href={"mailto:" + offer.person.email} className="d-block">
                      {offer.person.email}
                    </a>
                  </p>
                )}
                <InfoList className="bg-white">
                  <InfoList.Title title={format.dateAndTime(offer?.createdAt)} />
                  <InfoList.Item value={format.price(offer.newPrice)} label={_t("new_price")} />
                  <InfoList.Item value={format.price(offer.tradePrice)} label={_t("trade_price")} />
                  <InfoList.Item
                    value={format.price(offer.registrationFee)}
                    label={_t("registration_fee")}
                  />
                  <InfoList.Item value={format.price(offer.licensePlateFee)} label={_t("plade_nr_fee")} />
                  <InfoList.Item value={format.price(offer.caseFee)} label={_t("offer_processingFee")} />
                  <InfoList.Item value={format.price(offer.collateralFee)} label={_t("collateralFee")} />
                  <Divider className="mb-1 mt-2" />
                </InfoList>
              </Col>
            </Row>
          ))}
      </Drawer>
    </>
  );
};

export default ResellerTaxOfferArchived;
