import React, { FC } from "react";
import { Col, Row } from "antd";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";

const TaxDocTotalPrice: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  const { registrationFee, productPriceWithVat } = taxDocCase.evaluation || {};

  return (
    <Row className="flex-wrap">
      <Col flex="auto">
        <h3 className="mb-0">{_t("total_price")}</h3>
      </Col>
      <Col flex="80px" className="text-right">
        <h3 className="mb-0 text-bold">{format.price((productPriceWithVat || 0) + (registrationFee || 0))}</h3>
      </Col>
    </Row>
  );
};

export default TaxDocTotalPrice;
