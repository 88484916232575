import { FC, useEffect, useState } from "react";
import Icon from "../../../assets/icons/icon";
import Details from "../../../components/detailsList";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";

import format from "../../../utilities/formatNumbers";
import { LeasingDealType } from "./calc/leasingCalcTypes";
import SwitchShowWithMoms from "./switchShowWithMoms";

const LeasingDealResults: FC = () => {
  const { calcCount, leasingForm, leasingCase, isPrivate } = LeasingStore.useContainer();
  const [showWithMoms, setShowWithMoms] = useState<boolean>(true);
  const [deal, setDeal] = useState<LeasingDealType | null>(null);

  useEffect(() => {
    const newDeal = leasingForm.getFieldsValue(true);
    setDeal(newDeal || leasingCase.deal);
  }, [calcCount]);

  const { insuranceOutput, insuranceInput, carWarrantyInput, gpsTrackerInput, output, input } = deal || {};
  const { monthlyPayment, restValue, payout, calculable, totalCost } = output || {};

  const payoutAmount = showWithMoms ? payout?.total : payout?.totalNoVat;
  const monthlyPaymentAmount = showWithMoms ? monthlyPayment?.total : monthlyPayment?.totalNoVat;
  const isActiveInsurance = insuranceInput?.active;
  const insuranceAmount = insuranceOutput?.monthlyPayment;
  const isGpsTracker = !!gpsTrackerInput?.active;
  const isCarWarranty = !!carWarrantyInput?.active;
  const isRoadAssistance = !!insuranceInput?.roadAssistance;
  const isGlassDamage = !!insuranceInput?.glassDamage;
  const restvalueAmount = showWithMoms ? restValue?.total : restValue?.totalNoVat;
  const taxationAmount = monthlyPayment?.taxation;
  const vatDeductionAmount = monthlyPayment?.vatDeduction;
  const deposit = (input?.wishedDeposit || 0) * (showWithMoms ? 1.25 : 1);

  const depositProcent = ((((input?.wishedDeposit || 0) / (totalCost || 0)) * 100) || 0).toFixed(2);
  const depositTitle = `${_t("deposit")} (${depositProcent}%)`;

  const payoutSelfFinancing = ((payout?.selfFinancing || 0) * 100).toFixed(2);
  const payoutTitle = `${_t("first_time_payment")} (${payoutSelfFinancing}%)`;

  const restValueDeducation = ((restValue?.deductionAnnually || 0) * 100).toFixed(2);
  const restValueTitle = `${_t("restvalue")} (${restValueDeducation}%)`;

  const monthlyPaymentTitle = (
    <>
      {_t("monthly_payment")}
      {isGpsTracker && <small>{` + GPS`}</small>}
      {isCarWarranty && <small>{` + CG`}</small>}
    </>
  );

  const insuranceTitle = (
    <>
      {_t("insurance")}
      {isRoadAssistance && <small>{` + ${_t("road_help")}`}</small>}
      {isGlassDamage && <small>{` + ${_t("glass_damage")}`}</small>}
    </>
  );

  if (!calculable) {
    return (
      <div className="leasing-nocalc-found">
        <Icon name="calculator_failed" />

        <strong>{_t("msg.cannot_calculate_check_values")}</strong>
      </div>
    );
  }

  return (
    <>
      <Details borderLess>
        <Details.Title
          className="pb-05 m-0"
          extra={<SwitchShowWithMoms showWithMoms={showWithMoms} setShowWithMoms={setShowWithMoms} />}
        >
          <span className="text-dark text-large">{_t("leasing_deal")}</span>
        </Details.Title>
        <Details.Item label={depositTitle} value={format.price(deposit, 2)} />
        <Details.Item label={payoutTitle} value={format.price(payoutAmount, 2)} />
        <Details.Item label={monthlyPaymentTitle} value={format.price(monthlyPaymentAmount, 2)} />
        {isActiveInsurance && <Details.Item label={insuranceTitle} value={format.price(insuranceAmount, 2)} />}
        <Details.Item label={restValueTitle} value={format.price(restvalueAmount, 2)} />
        {!isPrivate && (
          <>
            <Details.Divider className="m-0" />
            <Details.Item label={_t("taxation")} value={format.price(taxationAmount, 2)} />
            <Details.Item label={_t("vat_monthly_deduction")} value={format.price(vatDeductionAmount, 2)} />
          </>
        )}
      </Details>
    </>
  );
};

export default LeasingDealResults;
