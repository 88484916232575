import { Form, Input } from "antd";
import React, { FC } from "react";
import DividerLine from "../../../../components/lineSplit";
import _t from "../../../../lang/translate";
import QuickTradePrice from "./quickTradePrice";
import RegulationFields from "./regulationFields";
import DateSelect from "../../../../components/form/dateSelect";

const TradePriceEditFields: FC = () => {
  return (
    <>
      <DividerLine className="mt-0 mb-1" />
      <RegulationFields
        nameKey="otherRegulation"
        label={_t("other_adjustments")}
      />
      <RegulationFields
        nameKey="optimizedRegulation"
        label={_t("optimization_adjustment")}
      />
      <DividerLine className="mt-0 mb-1" />
      <QuickTradePrice />
      <Form.Item name={["tradePrice", "note"]} label={_t("note")}>
        <Input.TextArea autoSize={{ maxRows: 4, minRows: 1 }} />
      </Form.Item>
      <Form.Item label={_t("documentation", "date")} name="documentationDate">
        <DateSelect />
      </Form.Item>
    </>
  );
};

export default TradePriceEditFields;
