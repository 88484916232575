import React, { FC, useState } from "react";
import { Button, Col, message, Row, Typography } from "antd";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import { checkMotorAgencyStatus } from "../../../../services/taxDocService";
import format from "../../../../utilities/formatNumbers";
import { isAxiosError } from "../../../../utilities/typeGuard";

const TaxDocMotorAgencyStatus: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { taxDocCase, setTaxDocCase } = TaxDocCaseStore.useContainer();
  const { registrationNumber, latestUpdateInAgency } = taxDocCase.motorAgency || {};
  const handleCheck = async () => {
    try {
      setLoading(true);
      const { data } = await checkMotorAgencyStatus(taxDocCase.id);
      setTaxDocCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography.Title level={5}>{_t("vehicle", "status")}</Typography.Title>
      <Row justify="space-between" align="middle">
        <Col>
          {registrationNumber || "-"} / {_t("latest_dmr_update")}{" "}
          {latestUpdateInAgency ? format.date(latestUpdateInAgency) : "-"}
        </Col>
        <Col>
          <Button loading={loading} onClick={handleCheck}>
            {_t("check")} DMR
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default TaxDocMotorAgencyStatus;
