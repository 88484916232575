import React, { FC, useState } from "react";
import { Col, message, Row } from "antd";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import SwitchInput from "../../../../components/form/switchInput";
import LogText from "../../../../components/logText";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { updatePaid } from "../../../../services/importService";
import { isAxiosError } from "../../../../utilities/typeGuard";

const PaidSwitch: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>();
  const { id, log, flags } = importCase;

  const handlePaid: SwitchChangeEventHandler = async (v) => {
    try {
      setLoading(true);
      const { data } = await updatePaid(id, { status: v });
      setImportCase(data);
      message.success(_t("saved"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row gutter={[8, 8]} className="mb-1">
      <Col>
        <SwitchInput
          size="small"
          color="green"
          loading={loading}
          id="paid-switch"
          label={_t("payment", "received")}
          checked={flags.paid}
          onChange={handlePaid}
        />
      </Col>
      {log?.paid && (
        <Col>
          <LogText mdash inline small className={"p-0"} logData={log.paid} />
        </Col>
      )}
    </Row>
  );
};

export default PaidSwitch;
