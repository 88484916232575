import type {
    TaxEstimationDisplayOutput, TaxEstimationOutput
} from "../../../../../../types/taxEstimationTypes";

export const displayOutputFactory = (
    output: TaxEstimationOutput
): TaxEstimationDisplayOutput => {
    return {
        newPrice: output.newPrice,
        tradePrice: {
            equipmentRegulation: output.tradePrice.result?.equipmentRegulation ?? 0,
            mileageRegulation: output.tradePrice.result?.mileageRegulation ?? 0,
            priceLevel: output.tradePrice.result?.priceLevel ?? 0,
            tradePrice: output.tradePrice.result?.tradePrice ?? 0
        },
        registrationFee: {
            baseDeduction: output.registrationFee.config.baseDeduction,
            baseDeductionHybridElectricActualAmount:
                output.registrationFee.result.baseDeductionHybridElectricActualAmount,
            batteryDeduction: output.registrationFee.config.batteryDeduction,
            emissionTax: output.registrationFee.config.emissionTax,
            scaleBreakTax: output.registrationFee.result.scaleBreakTax,
            taxAfterAllDeductions: output.registrationFee.result.taxAfterAllDeductions,
            taxBeforeDeduction: output.registrationFee.result.taxBeforeDeduction,
            taxPhasingAmount: output.registrationFee.result.taxPhasingAmount,
            taxableValue: output.registrationFee.result.taxableValue
        },
        confidenceInterval: output.confidenceInterval
    }
}
