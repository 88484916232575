import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Space } from "antd";
import _t from "../../lang/translate";
import { updateDeliveryAddress } from "../../services/importService";
import { DeliveryAddress } from "../../types/importTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { renderErrorMessage } from "../messages/errorMessage";

export default function UpdateDeliveryAddress<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tempForm] = Form.useForm<DeliveryAddress>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id, initialValues } = payload;

  const handleSubmit = async (values: DeliveryAddress) => {
    setLoading(true);
    try {
      const { data } = await updateDeliveryAddress<T>(id, values);
      onSuccess(data);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={tempForm} initialValues={initialValues} requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <h2 className="modal-title">{_t("delivery_address")}</h2>
      <Form.Item rules={[{ required: true }]} name="name" label={_t("name")}>
        <Input />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="address" label={_t("address")}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("zip", "city", " & ")}>
        <Row gutter={12}>
          <Col flex="100px">
            <Form.Item rules={[{ required: true }]} name="zip" noStyle>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item rules={[{ required: true }]} name="city" noStyle>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="phone" label={_t("phone")}>
        <Input />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="contact" label={_t("contact_person")}>
        <Input />
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>

          <Button htmlType="submit" type="primary" loading={loading}>
            {_t("save")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(UpdateDeliveryAddress);
