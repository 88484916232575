import { FC, useEffect } from "react";
import { Equipment } from "../../../../../types/appTypes";
import format from "../../../../../utilities/formatNumbers";
import { isNum } from "../../../../../utilities/typeGuard";

interface TaxEquipmentsTotalCellProps {
  equipments?: any[] | null;
  vehicleEquipments?: number[] | null;
  used?: boolean;
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const TaxEquipmentsTotalCell: FC<TaxEquipmentsTotalCellProps> = ({
  vehicleEquipments,
  equipments,
  value,
  used,
  onChange,
}) => {
  useEffect(() => {
    function calcValue() {
      if (!vehicleEquipments || !equipments) return 0;

      // Get equipment prices from "equipments" and return total price
      return vehicleEquipments.reduce((acc, id) => {
        const equipment: Equipment = equipments.find((e) => e.id === id);
        const deduction: boolean = equipment?.deduction && !!used;
        let equipmentPrice: number = equipment?.price || 0;
        equipmentPrice = deduction ? equipmentPrice - 30000 : equipmentPrice;
        equipmentPrice = equipmentPrice < 0 ? 0 : equipmentPrice;
        return acc + equipmentPrice;
      }, 0);
    }

    onChange && onChange(calcValue());
  }, [equipments, vehicleEquipments]);

  return <span>{isNum(value) ? format.number(value) : 0}</span>;
};

export default TaxEquipmentsTotalCell;
