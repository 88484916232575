import React, { FC } from "react";
import CaseHeadClient from "../../../../components/caseHeadClient";
import openModal from "../../../../components/modal/openModal";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import { ImportCaseType } from "../../../../types/importTypes";

const ImportCaseDealer: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const { client, id } = importCase;

  const handleClientChange = async () => {
    try {
      const newCase = await openModal<ImportCaseType>("changeClient", { id, client, context: "import" });
      setImportCase(newCase);
    } catch (ex) {
    }
  };

  return <CaseHeadClient onClientChange={handleClientChange} client={client} />;
};

export default ImportCaseDealer;
