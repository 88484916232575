import React, { FC } from "react";
import ChecklistUserStore from "../../contexts/checklistUserStore";
import ChecklistUserGroup from "./checklistUserGroup";

const ChecklistUserGroups: FC = () => {
  const { groups } = ChecklistUserStore.useContainer();

  return (
    <>
      {groups?.map((group, i) => (
        <ChecklistUserGroup key={group.id} group={group} className={i !== 0 ? "mt-4" : ""} />
      ))}
    </>
  );
};

export default ChecklistUserGroups;
