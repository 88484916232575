import { FC } from "react";
import { Switch } from "antd";
import _t from "../../../lang/translate";

interface SwitchShowWithMomsProps {
  showWithMoms: boolean;
  setShowWithMoms: (checked: boolean) => void;
}

const SwitchShowWithMoms: FC<SwitchShowWithMomsProps> = ({ showWithMoms, setShowWithMoms }) => {
  return (
    <>
      <small>{_t(showWithMoms ? "include_short" : "exclude_short", "vat")}</small>
      <Switch size="small" className="ml-05" checked={showWithMoms} onChange={setShowWithMoms} />
    </>
  );
};

export default SwitchShowWithMoms;
