import { Button, Form, message, Result, Space, Spin } from "antd";
import { FC, useState } from "react";
import ConfigFieldsGenerator from "../../../components/form/ConfigFieldsGenerator";
import _t from "../../../lang/translate";
import { updateValueConfig, valueConfigUrl } from "../../../services/dealerService";
import { useApi } from "../../../services/useApi";
import { ProductType } from "../../../types/appTypes";
import { ConfigFieldsType } from "../../../types/systemTypes";
import consts from "../../../utilities/consts";
import { isAxiosError } from "../../../utilities/typeGuard";

interface DealerConfigValueFormProps {
  context: ProductType;
  dealerId: number;
  onCloseModal: () => void;
}

const DealerConfigValueForm: FC<DealerConfigValueFormProps> = ({ context, dealerId, onCloseModal }) => {
  const [{ data, isLoading, isError }] = useApi<ConfigFieldsType | null>(valueConfigUrl(dealerId, context), null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: ConfigFieldsType) => {
    try {
      setLoading(true);
      const data = { [context]: values };
      await updateValueConfig(dealerId, data);
      message.success(_t("saved"));
      setLoading(false);
      onCloseModal();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <>
      {isError && <Result status="error" title={_t("msg.unknown_error")} />}
      {isLoading && (
        <div className="text-center">
          <Spin spinning size="large" />
        </div>
      )}
      {!data && (
        <div className="text-right pt-1">
          <Button type="ghost" onClick={onCloseModal} disabled={loading}>
            {_t("cancel")}
          </Button>
        </div>
      )}
      {!!data && (
        <Form<ConfigFieldsType> onFinish={handleSubmit} {...consts.formItemProps} initialValues={data} className="mb-0">
          <ConfigFieldsGenerator forceSwitch list={data} disabled={loading} />
          <Form.Item shouldUpdate noStyle>
            {({ submit }) => (
              <div className="text-right pt-1">
                <Space>
                  <Button type="ghost" onClick={onCloseModal} disabled={loading}>
                    {_t("cancel")}
                  </Button>
                  <Button onClick={submit} loading={loading || isLoading} type="primary">
                    {_t("save")}
                  </Button>
                </Space>
              </div>
            )}
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default DealerConfigValueForm;
