import React, { FC } from "react";
import { Upload } from "antd";
import { saveFile } from "../services/mediaService";
import { DraggerProps } from "antd/lib/upload";

const DragUpload: FC<DraggerProps> = ({ children, ...rest }) => {
  const handleFileUpload = async (options: any) => {
    const { file, action, onProgress, onSuccess, onError } = options;
    const data = new FormData();
    data.append("file", file);
    try {
      const res = await saveFile(action, data, {
        onUploadProgress: (e) => {
          onProgress({ percent: (e.loaded / e.total) * 100 }, file);
        },
        headers: { "Content-Type": file.type },
      });
      onSuccess(res.data, file);
    } catch (ex) {
      onError(ex);
    }
  };

  return (
    <Upload.Dragger {...rest} customRequest={handleFileUpload} showUploadList={false}>
      {children}
    </Upload.Dragger>
  );
};

export default DragUpload;
