import { AxiosError } from "axios";

export const isNum = (value: any): value is number => typeof value === "number";

/** Checks that value is a valid number. isNum is referenced in many places, but doesn't check for NaN values.*/
export const isValidNum = (value: any): value is number =>
  typeof value === "number"
  && !isNaN(value);


export const isString = (value?: any): value is string => typeof value === "string";

export const isNotEmptyString = (value?: any): value is string => typeof value === "string" && !!value?.trim();

export function isAxiosError(err: any): err is AxiosError {
  return err.isAxiosError;
}

export function isObjKey<T>(key: any, obj: T): key is keyof T {
  return !!obj && typeof obj === 'object' && key in obj;
}

export function hasKey<K extends string, T extends object>(
  k: K, o: T
): o is T & Record<K, unknown> {
  return k in o;
}
