import { Col, Form, Input, Row } from "antd";
import { FC, ReactNode } from "react";
import InputCPR from "../../../components/form/inputCPR";
import InputCVR from "../../../components/form/inputCVR";
import _t from "../../../lang/translate";
import { CustomerKeyType } from "../../../types/customerTypes";

export type RequiredCustomerFeildsType = "name" | "email" | "phone" | "cvr" | "cpr" | "address" | "pbs";

interface CustomerFormFieldsProps {
  isPrivate: boolean;
  hideCPR?: boolean;
  hidePBS?: boolean;
  requiredFields?: RequiredCustomerFeildsType[];
  formKey?: string;
  formItemExtra?: ((name: CustomerKeyType) => ReactNode) | null;
}

const CustomerFormFields: FC<CustomerFormFieldsProps> = ({
  isPrivate,
  formItemExtra,
  formKey,
  requiredFields = ["name", "email", "phone"],
  hideCPR = false,
  hidePBS = false,
}) => {
  return (
    <>
      {isPrivate ? (
        <>
          {!hideCPR && (
            <Form.Item
              label={_t("cpr")}
              name={formKey ? [formKey, "cpr"] : "cpr"}
              rules={[{ required: requiredFields.includes("cpr") }]}
              extra={formItemExtra && formItemExtra("cpr")}
            >
              <InputCPR />
            </Form.Item>
          )}
          <Form.Item label={_t("name")} className="hide-error-message" required={requiredFields.includes("name")}>
            <Row gutter={[16, 16]}>
              <Col span={24} md={12}>
                <Form.Item
                  name={formKey ? [formKey, "nameFirst"] : "nameFirst"}
                  rules={[{ required: requiredFields.includes("name") }]}
                  noStyle
                  extra={formItemExtra && formItemExtra("nameFirst")}
                >
                  <Input placeholder={_t("firstname")} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name={formKey ? [formKey, "nameLast"] : "nameLast"}
                  rules={[{ required: requiredFields.includes("name") }]}
                  noStyle
                  extra={formItemExtra && formItemExtra("nameLast")}
                >
                  <Input placeholder={_t("lastname")} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </>
      ) : (
        <>
          <Form.Item shouldUpdate noStyle>
            {({ setFieldsValue }) => (
              <Form.Item
                label={_t("cvr")}
                name={formKey ? [formKey, "cvr"] : "cvr"}
                className="mb-3"
                rules={
                  requiredFields.includes("cvr") ? [{ required: true }, { whitespace: true }, { min: 8 }] : undefined
                }
                extra={formItemExtra && formItemExtra("cvr")}
              >
                <InputCVR onFetched={setFieldsValue} />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            label={_t("name")}
            name={formKey ? [formKey, "name"] : "name"}
            rules={[{ required: requiredFields.includes("name") }]}
            extra={formItemExtra && formItemExtra("name")}
          >
            <Input />
          </Form.Item>
        </>
      )}

      <Form.Item
        label={_t("email")}
        name={formKey ? [formKey, "email"] : "email"}
        rules={[{ required: requiredFields.includes("email"), type: "email" }]}
        extra={formItemExtra && formItemExtra("email")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={_t("phone")}
        name={formKey ? [formKey, "phone"] : "phone"}
        rules={[{ required: requiredFields.includes("phone") }]}
        extra={formItemExtra && formItemExtra("phone")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={_t("address")}
        rules={[{ required: requiredFields.includes("address") }]}
        name={formKey ? [formKey, "address"] : "address"}
        extra={formItemExtra && formItemExtra("address")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={_t("zip", "city", " & ")}
        className="mb-3 hide-error-message"
        required={requiredFields.includes("address")}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name={formKey ? [formKey, "zip"] : "zip"}
              noStyle
              extra={formItemExtra && formItemExtra("zip")}
              rules={[{ required: requiredFields.includes("address") }]}
            >
              <Input maxLength={10} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name={formKey ? [formKey, "city"] : "city"}
              noStyle
              rules={[{ required: requiredFields.includes("address") }]}
              extra={formItemExtra && formItemExtra("city")}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item
        hidden={hidePBS}
        label={_t("PBS_reg_nr")}
        rules={[{ required: requiredFields.includes("pbs") }]}
        name={formKey ? [formKey, "pbsRegNumber"] : "pbsRegNumber"}
        extra={formItemExtra && formItemExtra("pbsRegNumber")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        hidden={hidePBS}
        label={_t("PBS_account_nr")}
        rules={[{ required: requiredFields.includes("pbs") }]}
        name={formKey ? [formKey, "pbsAccountNumber"] : "pbsAccountNumber"}
        extra={formItemExtra && formItemExtra("pbsAccountNumber")}
      >
        <Input />
      </Form.Item>
    </>
  );
};

export default CustomerFormFields;
