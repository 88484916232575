import { Checkbox, CheckboxProps } from "antd";
import React, { FC } from "react";

interface CheckBoxRoundProps extends CheckboxProps {
}

const CheckBoxRound: FC<CheckBoxRoundProps> = ({ children, ...rest }) => {
  return (
    <Checkbox className="checkbox-round" {...rest}>
      {children}
    </Checkbox>
  );
};

export default CheckBoxRound;
