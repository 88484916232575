import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { initResellerTaxCase, ResellerTaxCase } from "../types/resellerTaxTypes";
import { convertToUploadFileList, isApiFiles } from "../utilities/convertTypes";
import UserStore from "./userStore";

interface ResellerTaxCaseStoreProps {
    isAdmin: boolean;
    resellerTaxCase: ResellerTaxCase;
    setResellerTaxCase: (newCase: ResellerTaxCase) => void;
    canSendInvoice: boolean;
    setCanSendInvoice: React.Dispatch<React.SetStateAction<boolean>>;
}

const useResellerTaxCaseStore = (initialState?: ResellerTaxCase): ResellerTaxCaseStoreProps => {
    const [resellerTaxCase, setResellerTaxCase] = useState<ResellerTaxCase>(initResellerTaxCase);
    const [canSendInvoice, setCanSendInvoice] = useState<boolean>(true);

    const { hasPermission } = UserStore.useContainer();
    const isAdmin = hasPermission("tax.isAdmin");

    const handleSetResellerTaxCase = useCallback(
        (newCase: ResellerTaxCase) => {
            const files = newCase.files && isApiFiles(newCase.files) ? convertToUploadFileList(newCase.files) : newCase.files;

            setResellerTaxCase({ ...newCase, files });
        },
        [setResellerTaxCase]
    );

    useEffect(() => {
        if (initialState) {
            handleSetResellerTaxCase(initialState);
            setCanSendInvoice(!!initialState.optimization?.newPrice);
        }
    }, [initialState, handleSetResellerTaxCase, setCanSendInvoice]);

    return {
        isAdmin,
        resellerTaxCase,
        setResellerTaxCase: handleSetResellerTaxCase,
        canSendInvoice,
        setCanSendInvoice,
    };
};

const ResellerTaxCaseStore = createContainer(useResellerTaxCaseStore);

export default ResellerTaxCaseStore;
