import { FC } from "react";
import { Form, Input } from "antd";
import EnumSelect from "../../../../components/form/enumSelect";
import _t from "../../../../lang/translate";

const { TextArea } = Input;

const ImportCondationForm: FC = () => {
  return (
    <>
      <Form.Item label={_t("condition_statusExterior")} name={["condition", "statusExterior"]}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("condition_statusInterior")} name={["condition", "statusInterior"]}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("condition_statusRims")} name={["condition", "statusRims"]}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("condition_statusTires")} name={["condition", "statusTires"]}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("condition_statusBrakes")} name={["condition", "statusBrakes"]}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("condition_statusChargingCables")} name={["condition", "statusChargingCables"]}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("condition_tireType")} name={["condition", "tireTypeId"]}>
        <EnumSelect nameKey={["importCase", "tireType"]} />
      </Form.Item>
      <Form.Item label={_t("condition_extraTiresStatus")} name={["condition", "extraTiresStatusId"]}>
        <EnumSelect nameKey={["importCase", "extraTiresStatus"]} />
      </Form.Item>

      <Form.Item label={_t("condition_otherComments")} name={["condition", "otherComments"]}>
        <TextArea autoSize={{ minRows: 1, maxRows: 3 }} />
      </Form.Item>
    </>
  );
};

export default ImportCondationForm;
