import React, { FC } from "react";
import { Button, Col, Row } from "antd";
import _t from "../../../../lang/translate";
import ImportVehicleInfo from "../importVehicleInfo";
import CardBox from "../../../../components/cardBox";
import ImportCaseStore from "../../../../contexts/importCaseStore";

interface ImportVehicleInfoEditiableProps {
  startEditing: () => void;
}

const ImportVehicleInfoEditiable: FC<ImportVehicleInfoEditiableProps> = ({ startEditing }) => {
  const { importCase } = ImportCaseStore.useContainer();

  return (
    <>
      <ImportVehicleInfo />
      <CardBox title={_t("note")}>{importCase.note || "-"}</CardBox>

      <Row justify="space-between" align="bottom">
        <Col />
        <Col>
          <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
            {_t("edit")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ImportVehicleInfoEditiable;
