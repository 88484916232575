import { FC, ReactNode, useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import StatisticDiffStatus from "../statisticDiffStatus";
import { DateSegmentType, StatisticDiffType } from "../../types/statisticsType";
import CardBox, { CardBoxProps } from "../cardBox";
import StatisticSegmentSelect from "./statisticSegmentSelect";
import { segmentKey } from "../../services/urlQueryService";
import { useApi } from "../../services/useApi";
import StatisticErrorResult from "./statisticErrorResult";
import LineChartPlain from "./LineChartPlain";

interface StatisticDiffProps extends CardBoxProps {
  title: ReactNode;
  fetchEndpoint: string;
  valueType?: null | 'percentage' | 'number';
}

const StatisticDiff: FC<StatisticDiffProps> = ({
  valueType,
  title,
  fetchEndpoint,
  ...rest
}) => {
  const [segment, setSegment] = useState<DateSegmentType>("month");
  const [{ data, isLoading, isError }, setUrl] =
    useApi<StatisticDiffType | null>("", null);
  const { labels, datasets, totals } = data || {};

  const suffix = valueType === 'percentage' ? '%' : '';

  useEffect(() => {
    const url = new URL(fetchEndpoint);
    url.searchParams.set(`filter[${segmentKey}]`, segment);
    if (segment === "year") {
      url.searchParams.set("filter[groupBy]", "month");
    }
    setUrl(url.toString());
  }, [fetchEndpoint, setUrl, segment]);

  let total: any = totals && totals[0] ? totals[0].value * (valueType === 'percentage' ? 100 : 1) : null;
  total = total && valueType === 'percentage' ? total.toFixed(2) : total;
  total = total ? <>{total}<span className="text-small">{suffix || ''}</span></> : '-';

  return (
    <CardBox {...rest}>
      <Spin spinning={isLoading}>
        <Row justify="space-between" align="middle">
          <Col>
            <label>{title}</label>
          </Col>
          <Col>
            <StatisticSegmentSelect segment={segment} onChange={setSegment} />
          </Col>
        </Row>
        {isError && <StatisticErrorResult />}
        <Row gutter={16}>
          <Col>
            <span className="text-40 text-dark">
              {total}
            </span>
          </Col>
          <Col className="pt-05">
            <StatisticDiffStatus totals={totals} valueType={valueType} />
          </Col>
        </Row>
        {labels && datasets && <LineChartPlain data={{ labels, datasets }} />}
      </Spin>
    </CardBox>
  );
};

export default StatisticDiff;
