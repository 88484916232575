/*
example of the input
input = {
  optimization: false,
  firstRegDate: "2011-10-05T14:48:00.000Z",
  mileage: 30000,
  qualityId: 1,
  specialUseId: 1,
  regulation: -1,
  optRegulation: 0,
  averagePrice: 540000,
  vehicleAdCount: 4,
  averageMileage: 35000,
};
*/

export default function calcTradePrice(input) {
  /////// Injecting consts in input object:
  const averageDiscountSurcharge = -0.05;
  const yearFactors = [0.31, 0.22, 0.2, 0.17];
  const specialUseDefaultSurcharge = -0.05;
  const specialUseQualityMaxPrice = -20000;
  const deliveryFee = -1180;
  const carQualities = [
    { value: 1, surcharge: -0.03 },
    { value: 2, surcharge: 0.05 },
    { value: 3, surcharge: -0.03 },
    { value: 4, surcharge: -0.05 },
  ];

  const consts = {
    averageDiscountSurcharge,
    deliveryFee,
    yearFactors,
    specialUseQualityMaxPrice,
    specialUseDefaultSurcharge,
    carQualities,
  };

  let output = {};
  output.consts = consts;
  output.regulation = input.regulation;
  output.equipmentRegulation = input.equipmentRegulation;
  output.averagePrice = input.averagePrice;
  output.carAgeInYears = carAgeCalc(input, output);
  output.yearFactor = calcYearFactor(input, output);
  output.averageDiscountAmount = averageDiscountAmountCalc(input, output);
  output.tradingPriceTotal = tradingPriceTotalCalc(input, output);
  output.qualitySurcharge = qualityIdSurchangeCalc(input, output);
  output.qualityAmount = qualityIdCalc(input, output);
  output.specialUseSurcharge = calcspecialUseSurcharge(input, output);
  output.specialUseValue = calcspecialUseValue(input, output);
  output.specialUseAmount = calcspecialUseAmount(input, output);
  output.kmDifference = kmDifferenceCalc(input, output);
  output.kmDeductionTotal = kmDeductionCalc(input, output);
  output.equipmentRegulationAmount = equipmentRegulationAmountCalc(input, output);
  output.regulationAmount = regulationAmountCalc(input, output);
  output.finalTradePriceTotal = finalTradePriceTotalCalc(input, output);

  return output;
}

///////// FUNCTIONS  ///////// FUNCTIONS  ///////// FUNCTIONS  ///////// FUNCTIONS

export function carAgeCalc({ firstRegDate, documentationDate }) {
  const now = documentationDate ? new Date(documentationDate) : new Date();

  // Calculate the age of the car in days (86400000 is a magic number) and later in years
  return Math.floor((now - new Date(firstRegDate)) / 86400000) / 365;
}

function averageDiscountAmountCalc({ averagePrice }, { consts }) {
  return averagePrice * consts?.averageDiscountSurcharge;
}

function tradingPriceTotalCalc({ averagePrice }, { averageDiscountAmount, consts }) {
  const result = averagePrice + averageDiscountAmount;
  return result + consts.deliveryFee;
}

function qualityIdSurchangeCalc({ qualityId }, { consts }) {
  // 2022-07-14 - DMR has decided that is not deductable anymore, thus always 0
  return 0;

  //return consts?.carQualities.find((q) => q.value == qualityId)?.surcharge;
}

function calcspecialUseSurcharge({ specialUseId }, { consts }) {
  return specialUseId === 1 ? 0 : consts.specialUseDefaultSurcharge;
}

function qualityIdCalc(_, { carAgeInYears, qualitySurcharge, tradingPriceTotal, consts }) {
  if (carAgeInYears < 0.5) return 0;
  let qualityAmount = tradingPriceTotal * qualitySurcharge;
  if (qualityAmount < consts.specialUseQualityMaxPrice) {
    qualityAmount = consts.specialUseQualityMaxPrice;
  } else if (qualityAmount < -8000) {
    qualityAmount = -8000;
  }

  return qualityAmount;
}

function calcspecialUseValue(_, { tradingPriceTotal, specialUseSurcharge }) {
  if (specialUseSurcharge === 0) return 0;
  return tradingPriceTotal * specialUseSurcharge;
}

function calcspecialUseAmount(_, output) {
  const { qualityAmount, specialUseSurcharge, specialUseValue, consts } = output;
  if (specialUseSurcharge === 0) return 0;

  if (specialUseValue + qualityAmount < consts.specialUseQualityMaxPrice) {
    return consts.specialUseQualityMaxPrice - qualityAmount;
  } else {
    return specialUseValue;
  }
}

function kmDifferenceCalc({ averageMileage, mileage }) {
  return averageMileage - mileage;
}

function calcYearFactor(_, { carAgeInYears, consts }) {
  const { yearFactors } = consts;
  const yearFactor = yearFactorCalc({ yearFactors, carAgeInYears });
  return yearFactor;
}

function kmDeductionCalc({ averageMileage, mileage }, { kmDifference, carAgeInYears, tradingPriceTotal, yearFactor }) {
  const kmDifferenceSurcharge = kmDifference / averageMileage;
  const priceLevel = (tradingPriceTotal / 100000) * kmDifference * yearFactor;
  const priceLevel10 = priceLevel10Calc({
    tradingPriceTotal,
    kmDifferenceSurcharge,
  });
  const priceLevel50 = Math.floor(priceLevel - priceLevel10) * 0.5;
  const kmDeductionValue = priceLevel10 + priceLevel50;
  const priceLevelValidation10Input = {
    averageMileage,
    mileage,
    priceLevel10,
    priceLevel,
    kmDeductionValue,
  };

  const priceLevelValidation10 = priceLevelValidation10Calc(priceLevelValidation10Input);

  const kmDeductionValue10YearInput = {
    carAgeInYears,
    kmDifferenceSurcharge,
    priceLevelValidation10,
  };

  const kmDeductionValue10Year = kmDeductionValue10YearCalc(kmDeductionValue10YearInput);

  return carAgeInYears >= 10 ? kmDeductionValue10Year : priceLevelValidation10;
}

function equipmentRegulationAmountCalc(_, { equipmentRegulation }) {
  return equipmentRegulation;
}

function regulationAmountCalc({ regulation }, { tradingPriceTotal }) {
  return tradingPriceTotal * (regulation / 100);
}

function finalTradePriceTotalCalc(
  { equipmentRegulation = 0 },
  { tradingPriceTotal = 0, qualityAmount = 0, specialUseAmount = 0, regulationAmount = 0, kmDeductionTotal = 0 }
) {
  const result =
    tradingPriceTotal + qualityAmount + specialUseAmount + regulationAmount + kmDeductionTotal + equipmentRegulation;
  return Math.round(result);
}

function kmDeductionValue10YearCalc({ carAgeInYears, kmDifferenceSurcharge, priceLevelValidation10 }) {
  let kmDeductionValue10Year;

  //Kilometer Deduction Value (Above 10 years)
  if (carAgeInYears > 10) {
    if (kmDifferenceSurcharge > 0.33 || kmDifferenceSurcharge < -0.33) {
      kmDeductionValue10Year = priceLevelValidation10;
    } else {
      kmDeductionValue10Year = 0;
    }
  }
  return kmDeductionValue10Year;
}

function yearFactorCalc({ yearFactors, carAgeInYears }) {
  let yearFactor;
  if (carAgeInYears >= 3) {
    yearFactor = yearFactors[3];
  } else if (carAgeInYears >= 2) {
    yearFactor = yearFactors[2];
  } else if (carAgeInYears >= 1) {
    yearFactor = yearFactors[1];
  } else {
    yearFactor = yearFactors[0];
  }

  return yearFactor;
}

//Pricelevel 10% (10% of tradingprice)
function priceLevel10Calc({ tradingPriceTotal, kmDifferenceSurcharge }) {
  let priceLevel10;
  if (kmDifferenceSurcharge > 0) {
    priceLevel10 = tradingPriceTotal * 0.1;
  } else if (kmDifferenceSurcharge < 0) {
    priceLevel10 = tradingPriceTotal * -0.1;
  } else {
    priceLevel10 = 0;
  }
  return priceLevel10;
}

function priceLevelValidation10Calc({ averageMileage, mileage, priceLevel10, priceLevel, kmDeductionValue }) {
  //Price level validation 10 (If deduction is more than 10% of the trading price, you need to engage pricelevel50 and pricelevel10)
  let priceLevelValidation10;

  if (averageMileage > mileage) {
    if (priceLevel10 < priceLevel) {
      priceLevelValidation10 = kmDeductionValue;
    } else {
      priceLevelValidation10 = priceLevel;
    }
  } else {
    if (priceLevel10 < priceLevel) {
      priceLevelValidation10 = priceLevel;
    } else {
      priceLevelValidation10 = kmDeductionValue;
    }
  }
  return priceLevelValidation10;
}
