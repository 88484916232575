import { Space } from "antd";
import _t from "../../lang/translate";
import { PaymentMethodDetailsType } from "../../types/subscriptionTypes";
import PaymentCardBrand from "../paymentCardBrand";

type Props = {
  paymentMethod: PaymentMethodDetailsType | null | undefined
}

export default function PaymentMethod({ paymentMethod }: Props) {
  const paymentMethodText = paymentMethod?.type
    ? (<span className="text-capitalize text-dark text-medium">
      {`${paymentMethod.type} **** ${paymentMethod?.lastFour}`}
    </span>)
    : _t('no_payment_methods');

  return (
    <Space size={"small"} align="center" className="space-items-lineheight-0">
      {paymentMethod?.type ? <PaymentCardBrand brand={paymentMethod.type} /> : null}
      {paymentMethodText}
    </Space>
  )
}
