import { Button, Checkbox, Form, Input, message, Space } from "antd";
import { FC, useState } from "react";
import DividerLine from "../../../../components/lineSplit";
import _t from "../../../../lang/translate";
import { updateLeasingCode } from "../../../../services/leasingService";
import { LeasingCaseType, LeasingCodeUpdateType } from "../../../../types/leasingTypes";
import consts from "../../../../utilities/consts";
import { isAxiosError } from "../../../../utilities/typeGuard";
import LeasingInsuranceCompanyView from "../leasingInsuranceCompanyView";

interface LeasingSendCodeFormProps {
  leasingCase: LeasingCaseType;
  onCancel: () => void;
  onSent: (leasingCode: string) => void;
}

const LeasingSendCodeForm: FC<LeasingSendCodeFormProps> = ({ leasingCase, onCancel, onSent }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id, contract, partnerCase } = leasingCase;
  const insuranceCompany = contract?.insuranceCompany;

  const handleSubmit = async (values: LeasingCodeUpdateType) => {
    try {
      setLoading(true);
      await updateLeasingCode(id, values);
      values.leasingCode && onSent(values.leasingCode);
      message.success(_t("saved"));
      onCancel();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <Form<LeasingCodeUpdateType> {...consts.formItemProps} className="mb-0" onFinish={handleSubmit}>
      <Form.Item name="leasingCode" label={_t("leasing_code")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item name="notify" label={_t("notify")} valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <DividerLine />
      {!partnerCase && <LeasingInsuranceCompanyView className="mb-2" insuranceCompany={insuranceCompany} />}

      <Form.Item noStyle shouldUpdate>
        {({ submit }) => {
          return (
            <div className="text-right">
              <Space>
                <Button type="ghost" onClick={onCancel} disabled={loading}>
                  {_t("cancel")}
                </Button>
                <Button onClick={submit} loading={loading} type="primary">
                  {_t("save")}
                </Button>
              </Space>
            </div>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default LeasingSendCodeForm;
