import React, { FC, useState } from "react";
import { Button, Form, Radio, Space, Tooltip, message } from "antd";
import _t from "../../../../../lang/translate";
import TaxCaseStore from "../../../../../contexts/taxCaseStore";
import { renderErrorMessage } from "../../../../../components/messages/errorMessage";
import { TaxCase } from "../../../../../types/taxTypes";
import LoadingContainer from "../../../../../components/loadingContainer";
import Icon from "../../../../../assets/icons/icon";
import DividerLine from "../../../../../components/lineSplit";
import format from "../../../../../utilities/formatNumbers";
import PopoverList from "../../../../../components/popups/popoverList";
import TaxAcceptOfferModal from "./taxAcceptOffer";
import { calculatePayment } from "../../calc/metaCalculators";
import { PaymentType } from "../../../../../types/paymentCardType";
import PaymentCardBrand from "../../../../../components/paymentCardBrand";

type FormOptions = {
    paymentType: PaymentType
}

const TaxAcceptAndPay: FC = () => {
    const { taxCase } = TaxCaseStore.useContainer();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState<boolean>(false)

    // const validatedCard = validateCardLimit({
    //     cardPayments: taxCase.cardPayments,
    //     taxCaseCalculation: taxCase.calculation
    // })
    // const validatedSavedCard = validateSavedCardLimit({
    //     cardPayments: taxCase.cardPayments,
    //     taxCaseCalculation: taxCase.calculation
    // })
    // const initialFormValues: FormOptions = {paymentType: validatedSavedCard.valid ? "stripe" : "invoice"};
    const initialFormValues: FormOptions = { paymentType: "invoice" };
    // const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentType>(initialFormValues.paymentType);
    const validation = isValidForPayment({
        taxCaseCalculation: taxCase.calculation,
        cardPayments: taxCase.cardPayments,
        paymentType: initialFormValues.paymentType
    })

    const handleSubmit = async (values: FormOptions) => {
        if (!values.paymentType) {
            renderErrorMessage("you need to choose a payment type");
            return;
        }
        setLoading(true)
        setShowModal(true)
    }

    return (<>
        <TaxAcceptOfferModal
            paymentCalculation={calculatePayment({
                taxCaseCalculation: taxCase.calculation,
                cardPayments: taxCase.cardPayments,
                paymentType: initialFormValues.paymentType
            })}
            paymentType={initialFormValues.paymentType}
            setVisible={setShowModal}
            visible={showModal}
            onSuccess={() => {
                setShowModal(false);
                setLoading(false);
                message.success(_t("case", "accepted"))
            }}
            onCancel={() => {
                setShowModal(false);
                setLoading(false);
            }}
        />
        <LoadingContainer showLogo loading={loading}>
            <Form
                initialValues={initialFormValues}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <h4>{_t("choose", "payment_method")}:</h4>
                <Form.Item name="paymentType" rules={[{ required: true }]}>
                    <Radio.Group className="box-radio-payment"
                    // onChange={(e) => {setSelectedPaymentType(e.target.value)}}
                    >
                        {/* STRIPE */}
                        {/* <Radio value={'stripe'}
                            disabled={!validatedSavedCard.valid}
                        >
                            <PopoverList
                                title={_t("card_payment")}
                                trigger={validatedSavedCard.valid ? "none" : "hover"}
                                list={[validatedSavedCard.rejectReason ?? '']}
                                className="full-width-btn-children"
                            >
                                <div className="radio-inner">
                                    <div className="">
                                            <h4 className="mb-0">
                                                <span className="mr-05">{_t("card_payment")}</span>
                                                <Tooltip trigger={validatedSavedCard.valid ? "hover" : "none"} title={_t('valuation.within.few')} overlayClassName="tax-payment-tooltip">
                                                    <Icon name="information-circle-outline" size="huge"/>
                                                </Tooltip>
                                            </h4>
                                        <small>{_t("immediate_valuation")}</small>
                                    </div>
                                    <Space size="small" className="payment-cards-wrapper">
                                        <PaymentCardBrand brand="visa" />
                                        <PaymentCardBrand brand="mastercard" />
                                        <PaymentCardBrand brand="american-express" />
                                        <PaymentCardBrand brand="discover" />
                                        <PaymentCardBrand brand="diners" />
                                        <PaymentCardBrand brand="maestro" />
                                        <PaymentCardBrand brand="cartes-bancaires" />
                                        <PaymentCardBrand brand="union-pay" />
                                    </Space>
                                </div>
                            </PopoverList>
                        </Radio> */}

                        {/* QUICKPAY */}

                        {/* <Radio value={'quickpay'}
                            disabled={!validatedCard.valid}
                        >
                            <PopoverList
                                title={_t("card_payment")}
                                trigger={validatedCard.valid ? "none" : "hover"}
                                list={[validatedCard.rejectReason ?? '']}
                                className="full-width-btn-children"
                            >
                                <div className="radio-inner">
                                    <div className="">
                                        <h4 className="mb-0">
                                            <span className="mr-05">Dankort, Visa, Mastercard</span>
                                            <Tooltip trigger={validatedCard.valid ? "hover" : "none"} title={_t('valuation.within.few')} overlayClassName="tax-payment-tooltip">
                                                <Icon name="information-circle-outline" size="huge"/>
                                            </Tooltip>
                                        </h4>
                                        <small>{_t("immediate_valuation")}</small>
                                    </div>
                                    <Space size="small" className="payment-cards-wrapper">
                                        <PaymentCardBrand brand="visa" />
                                        <PaymentCardBrand brand="mastercard" />
                                        <PaymentCardBrand brand="dankort" />
                                    </Space>
                                </div>

                            </PopoverList>
                        </Radio> */}

                        {/* FAKTURA - INVOICE */}
                        <Radio value={'invoice'}
                            disabled={!validateInvoice().valid}
                        >
                            <div className="radio-inner">
                                <div className="">
                                    <h4 className="mb-0">
                                        <span className="mr-05">{_t('payment_advice_2', "and") + " " + _t('invoice').toLowerCase()}</span>
                                        <Tooltip title={_t('valuation.within.few')} overlayClassName="tax-payment-tooltip">
                                            <Icon name="information-circle-outline" size="huge" />
                                        </Tooltip>
                                    </h4>
                                </div>
                                <Icon name="attach-outline" size="large" />
                            </div>
                        </Radio>
                    </Radio.Group>
                </Form.Item>

                <DividerLine />

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        const paymentType = getFieldValue(['paymentType']);
                        const text = getSubmitButtonText(paymentType)
                        return (<>
                            <PaymentCalculation cardPayments={taxCase.cardPayments}
                                paymentType={paymentType}
                                taxCaseCalculation={taxCase.calculation}
                            />
                            <PopoverList
                                title={_t("registration_fee_short", "msg.exceeds_limit")}
                                trigger={validation.valid ? "none" : "hover"}
                                list={[validation.rejectReason ?? '']}
                                className="full-width-btn-children"
                            >
                                <Button disabled={!validation.valid || taxCase.flags.expired} type="primary" size="large" className="w-100 mt-2" htmlType="submit">
                                    {text}
                                </Button>
                            </PopoverList>
                        </>)
                    }}
                </Form.Item>
            </Form>
        </LoadingContainer>
    </>);
};

export default TaxAcceptAndPay;


const getSubmitButtonText = (paymentType: FormOptions['paymentType']) => {
    switch (paymentType) {
        case "invoice": return _t("accept", "case");
        default: return _t("go", "to") + " " + _t("payment").toLowerCase();
    }
}


type PaymentCalculationProps = {
    paymentType: PaymentType;
    taxCaseCalculation: TaxCase['calculation'];
    cardPayments: TaxCase['cardPayments'];
}
const PaymentCalculation = ({ paymentType, cardPayments, taxCaseCalculation }: PaymentCalculationProps) => {
    const result = calculatePayment({ paymentType, cardPayments, taxCaseCalculation })
    const { total, cardFees, totalWithCardFees, transactionFeesFactor } = result
    const displayCardFees = (
        paymentType !== 'invoice' &&
        total !== totalWithCardFees
    )
    return (
        <div className="">
            <h4>{_t("payment_in_total")}</h4>
            {displayCardFees && (
                <div className="d-flex justify-content-between">
                    <span>{_t("total_price")}</span>
                    <span>{format.price(total)}</span>
                </div>
            )}
            {displayCardFees && (
                <div className="d-flex justify-content-between">
                    <i>Dankortgebyr +{format.percent(transactionFeesFactor, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</i>
                    <span>{format.price(cardFees)}</span>
                </div>
            )}
            <div className="d-flex justify-content-between">
                <strong>{_t("total_price")} {_t("offer_totalPrice").toLowerCase()}</strong>
                <strong>{format.price(totalWithCardFees)}</strong>
            </div>
        </div>
    )
}


type Validation = {
    valid: boolean;
    rejectReason: null | string;
}
function isValidForPayment({
    cardPayments,
    paymentType,
    taxCaseCalculation
}: PaymentCalculationProps): Validation {
    switch (paymentType) {
        case "invoice": return validateInvoice();
        case "quickpay": return validateCardLimit({ taxCaseCalculation, cardPayments });
        case "stripe": return validateSavedCardLimit({ taxCaseCalculation, cardPayments });
        default: return validateInvoice();
    }
}


type ValidateCardLimitsInput = {
    taxCaseCalculation: PaymentCalculationProps['taxCaseCalculation'],
    cardPayments: PaymentCalculationProps['cardPayments']
}
function validateCardLimit({ cardPayments, taxCaseCalculation }: ValidateCardLimitsInput): Validation {
    const registrationFee = taxCaseCalculation?.registrationFee?.registrationFee ?? 0;
    const cardPaymentLimit = cardPayments?.cardPaymentLimits ?? 0;
    if (registrationFee > cardPaymentLimit) {
        return {
            valid: false,
            rejectReason: `${format.price(registrationFee)} ${_t("is_greater_than_the_allowed_limit")}: ${format.price(cardPaymentLimit)}`,
        }
    }
    return {
        valid: true,
        rejectReason: null
    }
}


function validateSavedCardLimit({ cardPayments, taxCaseCalculation }: ValidateCardLimitsInput): Validation {
    const registrationFee = taxCaseCalculation?.registrationFee?.registrationFee ?? 0;
    const savedCardPaymentLimits = cardPayments?.savedCardPaymentLimits ?? 0;
    if (registrationFee > savedCardPaymentLimits) {
        return {
            valid: false,
            rejectReason: _t("msg.card_payment_only_on_zero_tax"),
        }
    }
    return {
        valid: true,
        rejectReason: null
    }
}

/**
 * Paying by invoice is always going to be valid and has no limit.
 * @returns {Validation} always valid
 */
function validateInvoice(): Validation {
    return {
        valid: true,
        rejectReason: null
    }
}
