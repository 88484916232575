import { Col, Divider, Row } from "antd";
import React, { FC } from "react";
import StatisticsStore from "../../../contexts/statisticsStore";

const StatisticsTotals: FC = () => {
  const { data } = StatisticsStore.useContainer();
  const { totals, columns } = data;
  const totalsKeys = totals && Object.keys(totals);

  if (!totals) return null;
  return (
    <>
      {totalsKeys?.map((key, i) => {
        const titleColumn = columns?.filter((c) => c.dataIndex === key);
        const title = titleColumn ? titleColumn[0].title : key;
        const count = totals[key as keyof typeof totals];
        return (
          <div key={i}>
            <Row justify="space-between" gutter={[16, 16]}>
              <Col>{title}</Col>
              <Col>{count}</Col>
            </Row>
            {totalsKeys.length - 1 > i && <Divider className="mt-1 mb-1" />}
          </div>
        );
      })}
    </>
  );
};

export default StatisticsTotals;
