import React, { FC, useState } from "react";
import { Alert, Button, Col, Divider, message, Row, Spin } from "antd";
import _t from "../lang/translate";
import { addPaymentCard, deletePaymentCard, paymentCardUrl } from "../services/paymentCardService";
import { useApi } from "../services/useApi";
import CardBox from "./cardBox";
import CheckableBox from "./checkableBox";
import PaymentOptions from "./paymentOptions";
import PaymentCardCheckBox from "./paymentCardCheckBox";
import { useHistory } from "react-router-dom";
import { isLinkType, LocationStateType } from "../types/appTypes";
import { openLink } from "../utilities/readLink";
import { PaymentCardType } from "../types/paymentCardType";
import { isAxiosError } from "../utilities/typeGuard";

interface PaymentSelectProps {
  value?: string | null;
  onChange?: (value: string | null) => void;
}

const PaymentSelect: FC<PaymentSelectProps> = ({ value, onChange }) => {
  const [loading, setLoading] = useState<boolean>(false);
  let history = useHistory<LocationStateType>();
  const [{ data, isLoading, isError }, setUrl, setData] = useApi<{ data: PaymentCardType[] }>(paymentCardUrl, {
    data: [],
  });
  const savedCards = data.data;

  const handleCheck = (id: string | null) => {
    onChange && onChange(id);
  };

  const handleAddNewCard = async () => {
    const { pathname, hash } = history.location;
    const returnPath = pathname + hash;

    try {
      setLoading(true);
      const { data } = await addPaymentCard(returnPath);
      const link = data?.data;
      isLinkType(link) && openLink(link, history.push, "_self");
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  const handleRemove = async (id: string) => {
    try {
      setLoading(true);
      const { data: isDeleted } = await deletePaymentCard(id);
      if (isDeleted) {
        setData({ data: savedCards?.filter((c) => c.id !== id) || [] });
      }
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={isLoading || loading}>
      <Row justify="space-between" align="middle" className="mb-05">
        <Col>
          <h4 className="mb-0">{_t("pay_with_saved_card")}</h4>
        </Col>

        <Col>
          <Button type="text" onClick={handleAddNewCard} className="no-bg pr-0">
            + {_t("add", "new")}
          </Button>
        </Col>
      </Row>
      {isError && <Alert type="error" message={_t("msg.unknown_error")} />}
      {!isError && !savedCards.length && <CardBox className="text-center">{_t("msg.you_have_no_saved_cards")}</CardBox>}
      {savedCards.map((card) => (
        <PaymentCardCheckBox
          key={card.id}
          card={card}
          onRemove={handleRemove}
          onCheck={handleCheck}
          checked={value === card.id}
        />
      ))}
      <Divider>
        <label>{_t("other_payment_methods")}</label>
      </Divider>
      <CheckableBox checked={value === "0"} onClick={() => handleCheck("0")}>
        <PaymentOptions />
      </CheckableBox>
    </Spin>
  );
};

export default PaymentSelect;
