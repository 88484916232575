import { Button, Modal, Tooltip } from "antd";
import { FC, useState } from "react";
import Icon from "../../../../assets/icons/icon";
import _t from "../../../../lang/translate";
import { TradePriceCalcTaxType } from "../../../../types/taxTypes";
import TradePriceOutput from "./tradePriceOutput";

interface TradePriceOutputFieldProps {
  output?: TradePriceCalcTaxType | null;
}

const TradePriceOutputField: FC<TradePriceOutputFieldProps> = ({ output }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return (
    <>
      <Tooltip title={_t("see_calculation")}>
        <Button
          size="small"
          type="text"
          shape="circle"
          tabIndex={-1}
          className="muted no-bg"
          onClick={openModal}
        >
          <Icon name="information-circle" />
        </Button>
      </Tooltip>
      <Modal
        visible={visible}
        onCancel={closeModal}
        footer={null}
        width={800}
        bodyStyle={{ padding: 0 }}
        title={_t("trade_price", "calculation")}
      >
        <TradePriceOutput calculation={output} />
      </Modal>
    </>
  );
};

export default TradePriceOutputField;
