import { useCallback, useEffect, useState } from "react";
import webSocket from "../services/webSocket";
import { SocketUser } from "../types/userTypes";

type UseAvtiveUsersType = [SocketUser[]];

export function useActiveUsers(roomId: string): UseAvtiveUsersType {
  const [activeUsers, setActiveUsers] = useState<SocketUser[]>([]);

  const handleHere = useCallback(
    (users: SocketUser[]) => {
      setActiveUsers([...users]);
    },
    [setActiveUsers]
  );

  const handleJoin = useCallback(
    (user: SocketUser) => {
      // Important to set state with the callback method, because the state variable "activeUsers" is always empty in the socket call-back
      setActiveUsers((ac) => {
        if (ac.filter((u) => u.id === user.id).length === 0) {
          return [...ac, user];
        }
        return ac;
      });
    },
    [setActiveUsers]
  );

  const handleLeave = useCallback(
    (user: SocketUser) => {
      setActiveUsers((ac) => {
        const nextActiveUsers = ac.filter((u) => u.id !== user.id);
        return [...nextActiveUsers];
      });
    },
    [setActiveUsers]
  );

  useEffect(() => {
    const socketEnabled = process.env.REACT_APP_GIT_BRANCH === "master" && process.env.NODE_ENV === "production";
    if (!socketEnabled) return;

    const socket = webSocket.create;
    socket.join(roomId).here(handleHere).joining(handleJoin).leaving(handleLeave);
    return () => {
      socket.leave(roomId);
    };
  }, [setActiveUsers]);

  return [activeUsers];
}
