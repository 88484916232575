import { Button } from "antd";
import { FC } from "react";
import Details from "../../../components/detailsList";
import LeasingStore from "../../../contexts/leasingStore";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";

interface LeasingDeliveryInfoProps {
  startEditing: () => void;
}

const LeasingDeliveryInfo: FC<LeasingDeliveryInfoProps> = ({ startEditing }) => {
  const { hasPermission } = UserStore.useContainer();
  const { leasingCase, isAdmin } = LeasingStore.useContainer();
  const { date, isReady } = leasingCase?.delivery || {};
  const isSale = hasPermission(
    leasingCase.partnerCase ? "partner_leasing.update_sales_values" : "leasing.update_sales_values"
  );
  const isPermitted = isSale || isAdmin;

  return (
    <>
      <Details borderLess>
        <Details.Item label={_t("delivery_date")} value={format.date(date)} />
        <Details.Item label={_t("ready_for_delivery")} value={format.yesOrNo(isReady)} />
      </Details>
      {isPermitted && !!leasingCase.id && (
        <div className="text-right">
          <Button type="link" className="pl-0 pr-0" onClick={startEditing}>
            {_t("edit")}
          </Button>
        </div>
      )}
    </>
  );
};

export default LeasingDeliveryInfo;
