import React, { useState } from "react";
import { Col, Modal as AntModal, Row, Typography } from "antd";
import posterImage from "../assets/images/video_cover.jpg";
import { getStartTrialModal, saveStartTrialModal } from "../services/authService";

const { Title } = Typography;

const AppExtra: React.FC = () => {
  const [showStartTrialModal, setShowStartTrialModal] = useState<boolean>(getStartTrialModal());
  const closeStartTrialModal = () => {
    setShowStartTrialModal(false);
    saveStartTrialModal(false);
  }

  return (
    <>
      <AntModal
        width={1060}
        destroyOnClose={true}
        visible={showStartTrialModal}
        onCancel={closeStartTrialModal}
        onOk={closeStartTrialModal}
        okText={"Luk vindue og gør brug af din gratis prøveperiode"}
        className="no-footer-border"
        cancelButtonProps={{ hidden: true }}
      >
        <Row justify="space-between" gutter={30 * 2} align="middle">
          <Col span={12}>
            <div className="visual-bar mt-2" />
            <Title level={3} className="mt-05 pb-1">
              Vi introducerer ny abonnementsløsning til dig, <br />og du har netop fået tildelt en prøveperiode!
            </Title>

            <p>
              Vi ønsker endnu engang at byde dig velkommen til danskmotorfinans.net og samtidig informere dig om en ny
              spændende abonnementsløsning, som vi netop har introduceret.
            </p>

            <p>
              Med vores nye abonnementsløsning får du mulighed for at nyde en lang række fordele i forhold til vores
              tidligere klippekortløsning. Du vil opleve en mere fleksibel og økonomisk løsning, hvor du kan tilpasse
              dit
              abonnement efter dine behov og betale en fast månedlig pris, som gør det nemt at planlægge din
              bilforretnings
              budgetter.
            </p>

            <p>
              Desuden vil du med vores abonnementsløsning løbende få adgang til flere funktioner og services, der vil
              forbedre din brugeroplevelse og gøre det lettere at håndtere dine kunder samt salgs- og
              administrationsprocesser.
            </p>

            <p>
              Vi håber, at du vil tage godt imod vores nye abonnementsløsning og ser frem til at hjælpe dig med at få
              mest
              muligt ud af vores CRM-system.
            </p>
          </Col>

          <Col span={12}>
            <video autoPlay={false} controls className="w-100 border-rounded" style={{ objectFit: "cover" }}
              poster={posterImage}>
              <source type="video/mp4" src={process.env.REACT_APP_SUBSCRIPTION_VIDEO} />
            </video>
          </Col>
        </Row>
      </AntModal>
    </>
  );
};

export default AppExtra;
