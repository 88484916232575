import React, { FC, useMemo, useState } from "react";

interface ExpandableProps extends React.HTMLAttributes<HTMLDivElement> {
  expanded?: boolean;
}

const Expandable: FC<ExpandableProps> = ({ children, expanded, style, className, ...rest }) => {
  const div = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | "auto">(0);
  const clientHeight = useMemo(() => (expanded ? div.current?.clientHeight || "auto" : 0), [expanded, div]);
  React.useEffect(() => {
    if (clientHeight !== height) {
      setHeight(clientHeight);
    }
  }, [clientHeight, setHeight]);

  return (
    <div
      {...rest}
      style={{ ...style, height, overflow: "hidden" }}
      className={`${className ? className : ""} transition`}
    >
      <div ref={div} style={{ overflow: "auto" }}>
        {children}
      </div>
    </div>
  );
};

export default Expandable;
