import React, { FC, useState } from "react";
import _t from "../../../../../../lang/translate";
import format from "../../../../../../utilities/formatNumbers";
import Details from "../../../../../../components/detailsList";
import { TaxEstimationDisplayOutput } from "../../../../../../types/taxEstimationTypes";

type Props = {
    output: TaxEstimationDisplayOutput['registrationFee']
}

const TaxEstimationRegistrationFeeOutput = ({ output }: Props) => {

    return (
        <Details borderLess>
            <Details.Item
                label={_t("taxable_value")}
                value={format.price(output.taxableValue)}
                color="secondary"
                bold
            />
            <Details.Item
                label={_t("battery_deduction")}
                value={format.price(output.batteryDeduction)}
                color="secondary"
            />
            <Details.Item
                label={_t("gross_tax")}
                value={format.price(output.scaleBreakTax.sum)}
                color="secondary"
            />
            <Details.Item
                label={_t("emission_tax")}
                value={format.price(output.emissionTax.sum)}
                color="secondary"
            />
            <Details.Item
                boldLabel
                label={_t("result")}
                color="secondary"
            />
            <Details.Item
                label={_t("registration_fee_before_deduction")}
                value={format.price(output.taxBeforeDeduction)}
                color="secondary"
            />
            <Details.Item
                label={_t("base_deduction")}
                value={format.price(output.baseDeduction)}
                color="secondary"
            />
            <Details.Item
                label={_t("phasing")}
                value={format.price(output.taxPhasingAmount)}
                color="secondary"
            />
            <Details.Item
                label={_t("base_deduction_low_emission")}
                value={format.price(output.baseDeductionHybridElectricActualAmount)}
                color="secondary"
            />
            <Details.Item
                label={_t("registration_fee")}
                value={format.price(output.taxAfterAllDeductions)}
                bold
            />
        </Details>
    );
};
export default TaxEstimationRegistrationFeeOutput
