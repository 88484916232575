import { Col, Divider, Row, Space, Tag, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React, { FC } from "react";
import Icon from "../../../assets/icons/icon";
import _t from "../../../lang/translate";
import { ProfileType } from "../../../types/userTypes";
import format from "../../../utilities/formatNumbers";
import { ProfileUpdateFunc } from "../../myAccount";
import UserResetPassword from "../user/userResetPassword";
import ProfileEdit from "./profileEdit";

const { Link, Text } = Typography;

interface UserProfileProps {
  user: ProfileType;
  onProfileUpdate: ProfileUpdateFunc;
}

const UserProfile: FC<UserProfileProps> = ({ user, onProfileUpdate }) => {
  const { initials, id, name, lastLogin, phone, email, departments } = user;
  return (
    <>
      <Text className="muted d-block mb-2">{_t("user", "information")}</Text>
      <Row gutter={16} className="flex-wrap-lg">
        {initials && (
          <Col flex="40px">
            <Avatar className="user-avatar">{initials}</Avatar>
          </Col>
        )}
        <Col flex="auto">
          <h3>
            {name}
            {lastLogin && (
              <small>
                {_t("last_login")}: {format.dateAndTime(lastLogin)}
              </small>
            )}
          </h3>
          <Divider className="mt-1 mb-1" />
          {phone && (
            <>
              <Link className="d-block mb-05" href={`tel:${phone}`}>
                <Icon name="call-outline" /> {phone}
              </Link>
            </>
          )}
          {email && (
            <>
              <Link className="d-block mb-05" href={`mailto:${email}`}>
                <Icon name="mail-outline" /> {email}
              </Link>
            </>
          )}
          {Array.isArray(departments) && !!departments.length && (
            <>
              <Divider className="mt-1 mb-1" />
              <small className="d-block mb-1">{_t("departments")}</small>
              {departments?.map((dep) => (
                <Tag className="tag-small mb-05" key={dep.id}>
                  <Text ellipsis>{dep.title}</Text>
                </Tag>
              ))}
            </>
          )}
          <Divider className="mt-1 mb-1" />
          <div className="text-right">
            <Space split={<Divider type="vertical" />}>
              <UserResetPassword userId={id} linkStyle />
              <ProfileEdit onProfileUpdate={onProfileUpdate} />
            </Space>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserProfile;
