import { Result } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";
import CollapseCard from "../../../components/collapseCard";
import DealerAdminStore from "../../../contexts/dealerAdminStore";
import _t from "../../../lang/translate";
import { dealerAdminUrl } from "../../../services/dealerService";
import { useApi } from "../../../services/useApi";
import { ParamTypes } from "../../../types/appTypes";
import { DealerAdminType } from "../../../types/dealerTypes";
import DealerAdminContent from "./dealerAdminContent";

const DealerAdmin: FC = () => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading, isError }] = useApi<DealerAdminType | null>(dealerAdminUrl(id), null);

  return (
    <CollapseCard
      title={_t("administration")}
      loading={isLoading}
      defaultOpen={false}
      className={data ? "collapse-card-tabs" : ""}
    >
      {isError && <Result status="error" title={_t("msg.unknown_error")} className="result-small" />}
      {data && (
        <DealerAdminStore.Provider initialState={data}>
          <DealerAdminContent />
        </DealerAdminStore.Provider>
      )}
    </CollapseCard>
  );
};

export default DealerAdmin;
