import React, { FC, useState } from "react";
import { Col, Divider, Form, message, Progress, Row, Space, Typography } from "antd";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import _t from "../../lang/translate";
import SwitchInput from "../form/switchInput";
import { ChecklistGroupType } from "../../types/checklistTypes";
import { compare } from "../../utilities/deepCompare";
import { getTitleByRoute } from "../../routes/appRoutes";
import { repositionChecklistEntry } from "../../services/checklistService";

const ChecklistAdminHeader: FC = () => {
  const {
    showIgnored,
    editing,
    dispatchGroups,
    setLoading,
    sorting,
    setSorting,
    context,
    id,
    setShowIgnored,
    showCompleted,
    setShowCompleted,
    setExpanded,
    total,
    completedTotal,
    groups,
    isAdmin,
  } = ChecklistAdminStore.useContainer();
  const [originalGroups, setOriginalGroups] = useState<ChecklistGroupType[] | null>(null);
  const [savedHiddenStatus, setSavedHiddenStatus] = useState<boolean>(false);

  const percent = (completedTotal * 100) / total;

  const handleSortingToggle = async (checked: boolean) => {
    if (checked) {
      setExpanded(null);
      setOriginalGroups(groups);
      setSavedHiddenStatus(showIgnored);
      setShowIgnored(true);
      setSorting(true);
    } else {
      const isMatched = originalGroups !== null && compare(originalGroups, groups);
      if (isMatched) {
        setSorting(false);
        setShowIgnored(savedHiddenStatus);
        setOriginalGroups(null);
      } else {
        try {
          setLoading(true);
          await repositionChecklistEntry(groups);
          setSorting(false);
          setShowIgnored(savedHiddenStatus);
          setOriginalGroups(null);
        } catch ({ response }) {
          message.error(response?.data?.message || _t("msg.unknown_error"));
          originalGroups && dispatchGroups({ type: "populate", payload: originalGroups });
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      <Form className="pt-2 pl-5 pr-2 pb-05">
        <Row gutter={20} justify="space-between">
          <Col>
            {!id ? (
              <h3>{getTitleByRoute(context)}</h3>
            ) : (
              <Typography.Title level={4} type={percent === 100 ? "success" : "secondary"} className="mb-0">
                {completedTotal}
                <small className="text-inherit"> / {total}</small>
              </Typography.Title>
            )}
          </Col>
          {isAdmin && (
            <Col>
              <Space direction="vertical">
                <Form.Item name="hideCompleted" noStyle>
                  <SwitchInput
                    disabled={!!editing || sorting}
                    checked={showCompleted}
                    onChange={(checked) => {
                      setExpanded(null);
                      setShowCompleted(checked);
                    }}
                    size="small"
                    label={_t("show", "completed")}
                  />
                </Form.Item>
                <Space split={<Divider type="vertical" />}>

                  <Form.Item name="showIgnored" noStyle>
                    <SwitchInput
                      disabled={!!editing || sorting}
                      checked={showIgnored}
                      onChange={(checked) => {
                        setExpanded(null);
                        setShowIgnored(checked);
                      }}
                      size="small"
                      label={_t("show", "hidden")}
                    />
                  </Form.Item>

                  <Form.Item name="sorting" noStyle>
                    <SwitchInput
                      disabled={!!editing}
                      checked={sorting}
                      onChange={handleSortingToggle}
                      size="small"
                      label={_t("sort")}
                    />
                  </Form.Item>
                </Space>
              </Space>
            </Col>
          )}
        </Row>
      </Form>
      <Progress percent={percent} size="small" showInfo={false} strokeWidth={3} status="success" />
    </>
  );
};

export default ChecklistAdminHeader;
