import http from "./httpService";
import {
  AcceptOfferFormType,
  CloneTaxCaseType,
  MakePurchaseType,
  PurchaseType,
  RejectCaseType,
  SendOfferFormType,
  TaxCase,
  TaxCaseCreation,
  TaxCreationRequestType,
  TaxListItem,
  TaxRecalculateRequestType,
  TradePriceCalcTaxType,
  UpdateDocumentationNoteType,
} from "../types/taxTypes";
import { ChangeClientType, IdType, LinkType, StatusUpdateType, Vehicle, } from "../types/appTypes";
import { getApiQuery } from "./urlQueryService";
import { mediaApiEndpoint } from "./mediaService";
import getApiEndpoint from "./apiEndpoint";
import { PaginationedData } from "../types/apiTypes";
import { ToLeasingFormType } from "../types/leasingTypes";
import { CaseStatusUpdate} from "../types/casesCommonTypes";
import { latestVersion } from "../screens/forms/tax/calc/taxCalcService";
import { StoreTaxEstimationRobot, TaxEstimationRobotData } from '../types/taxEstimationTypes'

const apiEndpoint = `${getApiEndpoint()}/taxCase`;

export function taxCaseUrl(id: string | number) {
  return `${apiEndpoint}/${id}`;
}

export function taxFileUrl(id?: IdType) {
  return id ? `${taxCaseUrl(id)}/file` : mediaApiEndpoint;
}

export function taxActivitiesUrl(id: IdType) {
  return `${taxCaseUrl(id)}/activities`;
}

export function getSavedOfferUrl(id: IdType, optimizationMode: boolean) {
  return `${taxCaseUrl(id)}/${optimizationMode ? "billingOptimizations" : "preAssessments"
    }/`;
}

export function getTaxCalculation(
  id: IdType,
  optimizationMode: boolean,
  data: any
) {
  return http.post<TradePriceCalcTaxType>(
    `${taxCaseUrl(id)}/${optimizationMode ? "billingOptimization" : "preAssessment"
    }/calculate`,
    data
  );
}

export function getTaxRegCalculation(
  id: IdType,
  optimizationMode: boolean,
  data: any
) {
  return http.post<TradePriceCalcTaxType>(
    `${taxCaseUrl(id)}/${optimizationMode ? "billingOptimization" : "preAssessment"
    }/calculateRegistrationFee`,
    data
  );
}

export function updateTaxActivity(id: IdType, data: any) {
  return http.put(`${taxActivitiesUrl(id)}/${data?.id}`, data);
}

export function saveTaxActivity(id: IdType, data: any) {
  return http.post(taxActivitiesUrl(id), data);
}

export function deleteTaxActivity(id: IdType, deletedId: string) {
  return http.delete(`${taxActivitiesUrl(id)}/${deletedId}`);
}

export function taxLegalFileUrl(id?: IdType) {
  return id ? `${taxCaseUrl(id)}/legalFile` : mediaApiEndpoint;
}

export function taxCasesURL(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function getTaxCases(query?: string) {
  return http.get<PaginationedData<TaxListItem>>(
    `${apiEndpoint}?${getApiQuery(query)}`
  );
}

export function getTaxCase(id: string | number) {
  return http.get(taxCaseUrl(id));
}

export function deleteTaxCase(id: IdType, doRefund: boolean) {
  const refundReason = doRefund ? 5 : -1;
  const url = `${taxCaseUrl(id)}?refundReason=${refundReason}`;
  return http.delete(url);
}

export function saveTaxCase(data: TaxCaseCreation) {
  return http.post<TaxCase>(apiEndpoint, data);
}

export function saveTaxCaseStatus(id: IdType, data: CaseStatusUpdate) {
  return http.put<TaxCase>(`${taxCaseUrl(id)}/status`, data);
}

export function saveTaxCaseStatusToInProgress(id: IdType) {
  return http.put<TaxCase>(`${taxCaseUrl(id)}/status/inProgress`, {});
}

export function copyAllFromPreAssessment(id: IdType) {
  return http.put(
    `${taxCaseUrl(id)}/billingOptimization/copyAllFromPreAssessment`
  );
}

export function downloadAllFiles(id: IdType) {
  return http.get(`${taxCaseUrl(id)}/downloadAllFiles`);
}

export function updateTaxCaseClient(id: IdType, data: ChangeClientType) {
  return http.put(`${taxCaseUrl(id)}/client`, data);
}

export function saveTaxVehicle(id: IdType, data: Vehicle) {
  return http.put(`${taxCaseUrl(id)}/vehicle`, data);
}

export function saveOffer(id: IdType, data: any, optimizationMode?: boolean) {
  const dataKey = optimizationMode ? "internalCalculation" : "calculation";
  return http.put(
    `${taxCaseUrl(id)}/${optimizationMode ? "billingOptimization" : "preAssessment"
    }`,
    {
      [dataKey]: data,
      version: latestVersion,
    }
  );
}

export function sentTaxOffer(id: IdType, data: SendOfferFormType) {
  return http.post(`${taxCaseUrl(id)}/sendOffer`, data);
}

export function publishTaxOffer(id: IdType, data: any) {
  return http.put(`${taxCaseUrl(id)}/published`, data);
}

export function followUpOnCase(id: IdType, data: any) {
  return http.put(`${taxCaseUrl(id)}/followup`, data);
}

export function payWithQuickpay(id: IdType) {
  return http.post<{ data: LinkType }>(`${taxCaseUrl(id)}/payByCard`);
}

export function payWithStripe(id: IdType, { userId }: { userId?: IdType | null }) {
  return http.post<TaxCase>(`${taxCaseUrl(id)}/payBySavedCard`, { userId });
}

export function acceptTaxOffer(id: IdType, data: AcceptOfferFormType) {
  return http.put<TaxCase>(`${taxCaseUrl(id)}/accepted`, data);
}

export function sentTaxReceipt(id: IdType) {
  return http.put(`${taxCaseUrl(id)}/receipted`, { status: true });
}

export function checkValidMileage(id: IdType) {
  return http.post<TaxCase>(`${taxCaseUrl(id)}/checkValidMileage`);
}

// Invoice functions :

export function sentTaxInvoice(id: IdType, billingDestinationId: IdType) {
  return http.post(`${taxCaseUrl(id)}/createInvoice`, {
    draftOnly: false,
    send: true,
    billingDestinationId,
  });
}

export function saveTaxDraftInvoice(id: IdType, billingDestinationId: IdType) {
  return http.post(`${taxCaseUrl(id)}/createInvoice`, {
    draftOnly: true,
    send: false,
    billingDestinationId,
  });
}

export function sentTaxInvoiceAgain(id: IdType) {
  return http.post(`${taxCaseUrl(id)}/sendInvoice`);
}

export function paidTaxOffer(id: IdType, data: any) {
  return http.put(`${taxCaseUrl(id)}/paid`, data);
}

export function uploadedTaxOffer(id: IdType, data: any) {
  return http.put(`${taxCaseUrl(id)}/documented`, data);
}

export function saveTaxEquipments(
  id: IdType,
  data: any,
  optimizationMode?: boolean
) {
  return http.put(
    `${taxCaseUrl(id)}/${optimizationMode ? "billingOptimization" : "preAssessment"
    }/equipments`,
    data
  );
}

export function saveTradePriceCalculation(
  id: IdType,
  data: any,
  optimizationMode?: boolean
) {
  return http.patch(
    `${taxCaseUrl(id)}/${optimizationMode ? "billingOptimization" : "preAssessment"
    }/calculation`,
    data
  );
}

export function getTradePrice(
  id: IdType,
  data: any,
  optimizationMode?: boolean
) {
  return http.get(
    `${taxCaseUrl(id)}/${optimizationMode ? "billingOptimization" : "preAssessment"
    }/calculationOutput`,
    data
  );
}

export function downloadDocumentation(id: IdType, optimizationMode?: boolean) {
  return http.post(
    `${taxCaseUrl(id)}/${optimizationMode ? "billingOptimization" : "preAssessment"
    }/downloadDocumentation`,
    { advanced: true }
  );
}

export function downloadTaxOffer(taxCaseId: IdType, documentId: IdType) {
  return http.get<{url: string}>(`${taxCaseUrl(taxCaseId)}}/downloadOffer/${documentId}`);
}

export function createLeasingofTaxCase(id: IdType, data: ToLeasingFormType) {
  return http.post(`${taxCaseUrl(id)}/toLeasingCase`, data);
}

export function createTaxDocofTaxCase(id: IdType) {
  return http.post(`${taxCaseUrl(id)}/toTaxDocCase`);
}

export function createImportofTaxCase(id: IdType) {
  return http.post(`${taxCaseUrl(id)}/toImportCase`);
}

export function cloneTaxCase(id: IdType, data: CloneTaxCaseType) {
  return http.post<TaxCase>(`${taxCaseUrl(id)}/toTaxCase`, data);
}

export function taxCaseCreationRequest(data: TaxCreationRequestType) {
  return http.post(`${getApiEndpoint()}/taxCaseCreationRequest`, data);
}

export function taxCaseRecalculateRequest(id: IdType, data: TaxRecalculateRequestType) {
  return http.post(`${taxCaseUrl(id)}/askRecalculate`, data);
}

/// Documentation Api endpoints :

export function adsDocumentationFileEndpoint(id?: IdType) {
  return id ? `${taxCaseUrl(id)}/adsDocumentationFile` : mediaApiEndpoint;
}

export function vehicleListDocumentationFileEndpoint(id?: IdType) {
  return id
    ? `${taxCaseUrl(id)}/vehicleListDocumentationFile`
    : mediaApiEndpoint;
}

export function newPriceDocumentationFileEndpoint(id?: IdType) {
  return id ? `${taxCaseUrl(id)}/newPriceDocumentationFile` : mediaApiEndpoint;
}

export function equipmentsDocumentationFileEndpoint(id?: IdType) {
  return id
    ? `${taxCaseUrl(id)}/equipmentsDocumentationFile`
    : mediaApiEndpoint;
}

export function fetchEquipmentsDocumentationFile(id: IdType) {
  return http.post(`${taxCaseUrl(id)}/fetchEquipmentsDocumentationFile`);
}

export function updateDocumentationNote(
  id: IdType,
  data: UpdateDocumentationNoteType
) {
  return http.put(`${taxCaseUrl(id)}/documentationNote`, data);
}

export function previewDocumentation(id: IdType) {
  return http.get(`${taxCaseUrl(id)}/previewDocumentation`);
}

export function sendDocumentation(id: IdType) {
  return http.post(`${taxCaseUrl(id)}/sendDocumentation`);
}

export function publishDocumentation(id: IdType, data: StatusUpdateType) {
  return http.put(`${taxCaseUrl(id)}/publishDocumentation`, data);
}

export function checkMotorAgencyStatus(id: IdType) {
  return http.post(`${taxCaseUrl(id)}/checkMotorAgencyStatus`);
}

export function makeDocumentationPurchase(id: IdType, data: MakePurchaseType) {
  return http.post(`${taxCaseUrl(id)}/makePurchase`, data);
}

export function rejectDocumentationCase(id: IdType, data: RejectCaseType) {
  return http.post(`${taxCaseUrl(id)}/close`, data);
}

export function purchaseByCard(id: IdType, data: PurchaseType) {
  return http.post<{ data: LinkType }>(
    `${taxCaseUrl(id)}/purchaseByCard`,
    data
  );
}

export function adsDataDocumentationFileEndpoint(id?: IdType) {
  return id
    ? `${taxCaseUrl(id)}/adsDataDocumentationFile`
    : mediaApiEndpoint;
}

export function purchaseBySavedCard(id: IdType, data: PurchaseType) {
  return http.post<TaxCase>(`${taxCaseUrl(id)}/purchaseBySavedCard`, data);
}

export function findRelevantVehicles(taxCaseId: IdType) {
  return http.post<TaxEstimationRobotData>(`${taxCaseUrl(taxCaseId)}/findRelevantVehicles`);
}

export function storeTaxRobotEstimation(taxCaseId: IdType, data: StoreTaxEstimationRobot) {
  return http.post(`${taxCaseUrl(taxCaseId)}/storeTaxRobotEstimation`, { data });
}

export function taxAutoFetchDatDe(taxCaseId: IdType) {
  return http.post<TaxCase>(`${taxCaseUrl(taxCaseId)}/attemptAutoReportFetch`);
}

/**
 * You can test with this vehicle data
 *    'vin' => 'WBY41DU090SB99488',
 *    'first_reg_date' => "2022-01-01 13:00",
 *
 * POST taxCase/{taxCase}/fetchPriceDocumentation \
 * it works in the same way as /attemptAutoReportFetch .
 */
export function taxFetchPriceDocumentation(taxCaseId: IdType) {
  return http.post<TaxCase>(`${taxCaseUrl(taxCaseId)}/fetchPriceDocumentation`);
}
