import { FC } from "react";
import { Col, Form, Row, Switch } from "antd";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";

const TechnicalNewPrice: FC = () => {
  return (
    <Form.Item label={_t("technical_new_price")}>
      <Row align="middle" className="flex-wrap">
        <Col flex="50px">
          <Form.Item
            name={["newPrice", "useTechnicalNewPrice"]}
            noStyle
            valuePropName="checked"
          >
            <Switch size="small" />
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Form.Item
            noStyle
            shouldUpdate={(curr, next) =>
              curr?.newPrice?.useTechnicalNewPrice !==
              next?.newPrice?.useTechnicalNewPrice
            }
          >
            {({ getFieldValue }) => (
              <Form.Item name={["newPrice", "technicalNewPrice"]} noStyle>
                <InputNum
                  disabled={
                    !getFieldValue(["newPrice", "useTechnicalNewPrice"])
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default TechnicalNewPrice;
