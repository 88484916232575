import { getCurrentUser } from "./authService";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";

const initialize = () => {
  const user = getCurrentUser();
  const isAdminUser = user && user.email && user.email.includes('@danskmotorfinans.dk')
  let hotjarEnabled = process.env.REACT_APP_GIT_BRANCH === "master" && process.env.NODE_ENV === "production";
  let gtmEnabled = !!process.env.REACT_APP_GTM_ID;

  // Disable GTM+hotjar for admins
  if (gtmEnabled && isAdminUser) {
    gtmEnabled = false;
  }

  if (hotjarEnabled && isAdminUser) {
    hotjarEnabled = false;
  }

  const userData = {
    id: user?.id,
    name: user?.name,
    email: user?.email,
    dealerName: user?.dealer.title,
    role: user?.role.title,
  };

  if (hotjarEnabled) {
    // init Hotjar:
    const hotjarId = parseInt(process.env.REACT_APP_HOTJAR_ID || "2647298");
    hotjar.initialize(hotjarId, 6);
    hotjar.identify(`${userData.id}`, userData);
  }

  if (gtmEnabled) {
    // init Google tag manager:
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID || "",
      dataLayer: userData,
    };
    TagManager.initialize(tagManagerArgs);
  }
};

export function register(eventName: "tax_case_accepted" | "import_orderd") {
  const user = getCurrentUser();
  const isAdminUser = user && user.email && user.email.includes('@danskmotorfinans.dk')
  const hotjarEnabled = !isAdminUser && process.env.REACT_APP_GIT_BRANCH === "master" && process.env.NODE_ENV === "production";
  const gtmEnabled = hotjarEnabled && user && user.role.id > 3;

  if (!hotjarEnabled) return;
  hotjar.event(eventName);

  if (!gtmEnabled) return;
  TagManager.dataLayer({
    dataLayer: {
      userId: user?.id,
      event: eventName,
    },
    dataLayerName: "ProductLayer",
  });
}

const eventTracker = {
  initialize,
  register,
};

export default eventTracker;
