import React, { FC } from "react";
import { Button, Col, Dropdown, Menu, Progress, Row, Space, Tooltip, Typography, } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import Icon from "../assets/icons/icon";
import _t from "../lang/translate";
import { openFileLink } from "../services/mediaService";
import format from "../utilities/formatNumbers";
import { capitalize } from "../utilities/stringExtraFunctions";
import LoadingContainer from "./loadingContainer";

interface UploadFileRenderProps extends React.HTMLAttributes<HTMLDivElement> {
  file: UploadFile;
  onRemove?: (file: UploadFile) => void;
  onAdminToggle?: (file: UploadFile) => void;
  hideDownload?: boolean;
  loading?: boolean;
}

const UploadFileRender: FC<UploadFileRenderProps> = ({
  file,
  onRemove,
  onAdminToggle,
  hideDownload,
  loading = false,
  ...rest
}) => {
  const getFileMenu = (file: UploadFile) => {
    return (
      <Menu>
        <Menu.Item
          key="delete"
          danger
          onClick={() => onRemove && onRemove(file)}
          icon={<Icon name="trash-bin-outline" size="small" />}
        >
          {_t("delete")}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <LoadingContainer
      loading={file.status === "uploading" || loading}
      {...rest}
    >
      <Row gutter={8} align="middle">
        <Col flex="auto">
          <Typography.Text
            type={
              file.status === "error" || file.status === "removed"
                ? "danger"
                : undefined
            }
            className="mx-width-180"
            title={
              file.status === "error"
                ? file?.error?.response?.data?.message
                : undefined
            }
            ellipsis
          >
            <Icon name="attach-outline" size="large" className="mr-05" />
            {capitalize(file.name)}
          </Typography.Text>
          <small> — {file.size && format.fileSize(file.size)}</small>
        </Col>
        {file.status === "uploading" && (
          <Col span={8}>
            <Progress type="line" showInfo={false} percent={file.percent} />
          </Col>
        )}

        <Col>
          <Space>
            {file.status === "done" && (
              <>
                {onAdminToggle && file.response?.id && (
                  <Tooltip
                    title={_t(
                      file.response?.isAdmin ? "make_it_visible" : "hide"
                    )}
                  >
                    <Button
                      shape="circle"
                      type="text"
                      onClick={() => onAdminToggle(file)}
                    >
                      <Icon
                        name={
                          file.response?.isAdmin
                            ? "eye-off-outline"
                            : "eye-outline"
                        }
                      />
                    </Button>
                  </Tooltip>
                )}
                {!hideDownload && (file.url || file.response?.url) && (
                  <Tooltip title={_t("download")}>
                    <Button
                      onClick={() =>
                        openFileLink(file.url || file.response?.url)
                      }
                      shape="circle"
                      type="text"
                    >
                      <Icon name="download-outline" />
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
            {onRemove && (
              <Dropdown placement="bottomRight" overlay={getFileMenu(file)}>
                <Button shape="circle" type="text">
                  <Icon name="ellipsis-vertical-sharp" />
                </Button>
              </Dropdown>
            )}
          </Space>
        </Col>
      </Row>
    </LoadingContainer>
  );
};

export default UploadFileRender;
