import http from "../services/httpService";
import { EnumItemType, EnumKeys, EnumType } from "../types/appTypes";
import { useEffect, useState } from "react";
import { getSavedEnums, saveEnums } from "../services/authService";
import getApiEndpoint from "../services/apiEndpoint";
import { extractDataFromObj } from "../utilities/objectUtil";

const apiEndpoint = `${getApiEndpoint()}/misc/enums`;

export function useEnums(nameKey: EnumKeys): [EnumItemType[] | null, boolean, boolean] {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [IsError, setIsError] = useState<boolean>(false);
  const enums: EnumType | null = getSavedEnums();

  useEffect(() => {
    const fetchEnums = async () => {
      try {
        setIsLoading(true);
        const { data } = await http.get(apiEndpoint);
        if (data === null) throw Error("Kunne ikke hent system enums!");
        saveEnums(data);
      } catch (x) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (!enums && !isLoading) fetchEnums();
  }, [enums, nameKey, isLoading, setIsError, setIsLoading]);

  return [extractDataFromObj<EnumItemType[]>(enums, nameKey), isLoading, IsError];
}
