import { Button, Form, Space } from "antd";
import React from "react";
import _t from "../../lang/translate";
import { cloneTaxCase } from "../../services/taxService";
import { OpenModalProps } from "../../types/modalTypes";
import { CloneTaxCaseType } from "../../types/taxTypes";
import assertFC from "../../utilities/assertFC";
import EnumSelect from "../form/enumSelect";
import { renderErrorMessage } from "../messages/errorMessage";

export default function CloneTaxCase<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<CloneTaxCaseType>();

  const handleAccept = async (values: CloneTaxCaseType) => {
    const { id } = payload;
    try {
      const { data } = await cloneTaxCase(id, values);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="vertical" onFinish={handleAccept}>
      <h2 className="modal-title">{_t("clone_case")}</h2>
      <Form.Item name="evaluationTypeId" label={_t("evaluating_type")}>
        <EnumSelect defaultActiveFirstOption nameKey={["taxCase", "evaluationType"]} />
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" type="primary">
            {_t("clone_case")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(CloneTaxCase);
