import React, { FC, useEffect, useState } from "react";
import { Button, Col, Form, Input, message, Row } from "antd";
import Icon from "../../../assets/icons/icon";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { useHistory, useLocation } from "react-router-dom";
import { authResetPassword } from "../../../services/authService";
import { ResetPasswordType } from "../../../types/loginTypes";
import { LocationStateType } from "../../../types/appTypes";
import { isAxiosError } from "../../../utilities/typeGuard";
import { trackKlaviyoLogin } from "../../../services/klaviyoTrackerService";
import {passwordRegex} from "../../../utilities/password";

const ResetPassword: FC = () => {
  const history = useHistory();
  const location = useLocation<LocationStateType>();
  const [loading, setLoading] = useState<boolean>(false);
  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    if (!token) history.push(getPath("loginAdmin"));
  }, []);

  const handleResetPassword = async (values: ResetPasswordType) => {
    try {
      setLoading(true);
      values.token = token;
      await authResetPassword(values);
      const oldState = location.state;
      window.history.replaceState(null, "", getPath("home"));
      window.location.href = oldState?.from ? oldState?.from?.pathname : "/";
      trackKlaviyoLogin(values.email)
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form<ResetPasswordType> onFinish={handleResetPassword} layout="vertical" name="resetPassword" requiredMark={false}>
      <h1>{_t("reset_password")}</h1>
      <Form.Item name="email" rules={[{ required: true, type: "email" }]} label={_t("email")}>
        <Input disabled={loading} prefix={<Icon name="person-outline" />} />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true },
          { whitespace: true },
          { min: 8, message: _t('password_min_8') },
          {
            pattern: passwordRegex,
            message: _t('password_complexity'),
          }
        ]}
        label={_t("new_password")}
      >
        <Input.Password disabled={loading} prefix={<Icon name="lock-closed-outline" />} />
      </Form.Item>
      <Row align="middle" justify="space-between">
        <Col>
          <Button
            disabled={loading}
            className="underline-style p-0"
            type="link"
            onClick={() => history.push(getPath("loginAdmin"))}
          >
            {_t("back_to_login")}
          </Button>
        </Col>
      </Row>
      <Button disabled={loading} className="fancy-btn mt-1" htmlType="submit" type="primary" block>
        {_t("reset_password", "log_in", " & ")}
      </Button>
    </Form>
  );
};

export default ResetPassword;
