import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Space } from "antd";
import _t from "../../lang/translate";
import { downloadCollectionAuthorization } from "../../services/importService";
import { TransportDocumentFormType } from "../../types/importTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import SwitchInput from "../form/switchInput";
import DividerLine from "../lineSplit";
import { renderErrorMessage } from "../messages/errorMessage";

export default function ImportCollectionAuthorization<T extends object>({
  onSuccess,
  onCancel,
  payload,
}: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<TransportDocumentFormType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id, collectionAuthorizationCode, pickupSelf, pickupInfo } = payload;

  const handleDownload = async (values: TransportDocumentFormType) => {
    try {
      setLoading(true);
      const { data } = await downloadCollectionAuthorization<T>(id, values);
      onSuccess({ ...data, collectionAuthorizationCode: values.collectionAuthorizationCode });
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  return (
    <Form
      form={tmpForm}
      requiredMark={false}
      layout="vertical"
      onFinish={handleDownload}
      initialValues={{ pickupInfo, collectionAuthorizationCode }}
    >
      <h2 className="modal-title">{_t("collection_authorization")}</h2>
      <Form.Item name="collectionAuthorizationCode" label={_t("auth_number")}>
        <Input />
      </Form.Item>
      {pickupSelf && (
        <>
          <DividerLine>{_t("authorized_person")}</DividerLine>
          <Form.Item name={["pickupInfo", "isCompany"]} valuePropName="checked">
            <SwitchInput size="small" label={_t("is_company")} />
          </Form.Item>
          <Form.Item name={["pickupInfo", "name"]} label={_t("name")}>
            <Input />
          </Form.Item>
          <Form.Item name={["pickupInfo", "address"]} label={_t("address")}>
            <Input />
          </Form.Item>
          <Form.Item label={_t("zip", "city", " & ")}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name={["pickupInfo", "zip"]} noStyle>
                  <Input maxLength={10} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item name={["pickupInfo", "city"]} noStyle>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name={["pickupInfo", "phone"]} label={_t("phone")}>
            <Input />
          </Form.Item>
          <Form.Item name={["pickupInfo", "identification"]} label={_t("identification_number")}>
            <Input />
          </Form.Item>
        </>
      )}
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            {_t("download")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ImportCollectionAuthorization);
