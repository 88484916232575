import React, { FC } from "react";
import { ParamTypes, ScreenProps } from "../../../../types/appTypes";
import { useParams } from "react-router-dom";
import { taxDocCaseUrl } from "../../../../services/taxDocService";
import { initTaxDocCase, TaxDocCase } from "../../../../types/taxDocTypes";
import { useApi } from "../../../../services/useApi";
import PageTitle from "../../../../layout/pageTitle";
import AnimateIn from "../../../../components/animateIn";
import ActiveUsers from "../../../../components/activeUsers";
import PageBody from "../../../../layout/pageBody";
import TaxDocCaseHeadExtra from "../taxDocCaseHeadExtra";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import TaxDocCaseEdit from "./taxDocCaseEdit";

const TaxDocCaseAdmin: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading }] = useApi<TaxDocCase>(taxDocCaseUrl(id), initTaxDocCase, true);
  const caseRecieved = data.id > 0;

  return (
    <TaxDocCaseStore.Provider initialState={data}>
      <PageTitle
        title={`${title}/${id}`}
        headerMargin={false}
        fluid
        backBtn
        extra={<ActiveUsers context="taxDoc" />}
        footer={caseRecieved && <TaxDocCaseHeadExtra />}
      />
      <PageBody>
        <AnimateIn start={!isLoading && caseRecieved} loadingBeforeStart>
          <TaxDocCaseEdit />
        </AnimateIn>
      </PageBody>
    </TaxDocCaseStore.Provider>
  );
};

export default TaxDocCaseAdmin;
