import { FC } from "react";
import { Checkbox, CheckboxProps } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface CheckboxCellProps extends Omit<CheckboxProps, "onChange"> {
  equipmentId: number;
  value?: number[] | null;
  onChange?: (v: number[] | null) => void;
}

const CheckboxCell: FC<CheckboxCellProps> = ({ equipmentId, value, onChange, ...rest }) => {
  const checked = Array.isArray(value) && value.includes(equipmentId);
  const handleChange = (e: CheckboxChangeEvent) => {
    let nextValue = [...(value || [])];
    if (e.target.checked) {
      nextValue = [...nextValue, equipmentId];
    } else {
      nextValue = nextValue.filter((id) => id !== equipmentId);
    }

    onChange && onChange(nextValue);
  };

  return <Checkbox checked={checked} onChange={handleChange} {...rest} />;
};

export default CheckboxCell;
