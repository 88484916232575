import { IdType } from "./appTypes";

const modalTypes = [
  "customizeInsurance",
  "customizeCarWarranty",
  "addBillingDestination",
  "leasingRejectCase",
  "leasingAcceptCase",
  "leasingSendToPartner",
  "addLeasingOffer",
  "addLeasingCutomer",
  "addDepartments",
  "review",
  "addUser",
  "addMerchant",
  "ImportRejectCase",
  "taxToLeasingCase",
  "addMerchantContact",
  "addCustomer",
  "exportDealers",
  "updateDeliveryAddress",
  "updateInvoiceAddress",
  "motorAgencyReport",
  "exportCustomers",
  "leasingInitClient",
  "sendCreditPortalLink",
  "exportDealersStats",
  "buyTaxDoc",
  "rejectTaxDoc",
  "changeClient",
  "ImportCollectionAuthorization",
  "ImportPickupAgreement",
  "ImportPickupOrder",
  "changeStatus",
  "updateResellerTaxCustomerInfo",
  "resellerTaxToTaxCase",
  "startDigitalSigning",
  "cloneTaxCase",
  "importToTaxCase",
  "acceptTerms"
] as const;

export const isValidModalType = (type: string) =>
  modalTypes.includes(type as ModalTypes);

export type ModalTypes = typeof modalTypes[number];

export type ModalPayloadType = { parentId?: IdType; initalValue?: any };

export interface OpenModalProps<T> {
  onSuccess: (values: T) => void;
  onCancel: (errors?: any) => void;
  payload: any;
}

export interface ModalContentProps<T> extends OpenModalProps<T> {
  type: ModalTypes;
}
