import { Checkbox, Col, Form, Row } from "antd";
import { FC } from "react";
import InputPrice from "../../../components/form/inputPrice";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";

const LeasingVehiclePrice: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { readonly, inputLocked } = leasingCase.flags;
  const disabled = readonly || inputLocked;

  return (
    <Form.Item label={<strong>{_t("purchase_price")}</strong>}>
      <Row align="middle" gutter={16}>
        <Col span={15}>
          <Form.Item
            shouldUpdate={(prev, next) => prev["input"]?.isVatDeadVehicle !== next["input"]?.isVatDeadVehicle}
            noStyle
          >
            {({ getFieldValue }) => {
              const isVatDeadVehicle = getFieldValue(["input", "isVatDeadVehicle"]);
              return (
                <Form.Item name={["input", "vehiclePrice"]} rules={[{ required: true }]} noStyle>
                  <InputPrice
                    disabled={disabled}
                    min={0}
                    suffix={<small>{_t(isVatDeadVehicle ? "include_short" : "exclude_short", "vat")}</small>}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item name={["input", "isVatDeadVehicle"]} valuePropName="checked" noStyle>
            <Checkbox disabled={disabled} tabIndex={20}>
              {_t("taxDead", "vehicle")}
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default LeasingVehiclePrice;
