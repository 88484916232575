import { FC } from "react";

interface PromoCardProps {
  bg?: boolean
}

const PromoCard: FC<PromoCardProps> = ({ bg = false, children }) => {
  return (
    <div className={`content-bg card p-4 height-100`}>
      {children}
    </div>
  );
};

export default PromoCard;
