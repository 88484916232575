import { Form } from "antd";
import { FC } from "react";
import SwitchInput from "../../../components/form/switchInput";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";
import { isNum } from "../../../utilities/typeGuard";
import { LeasingDealType } from "./calc/leasingCalcTypes";

const LeasingInsuranceActiveSwitch: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const shouldUpdate = (curr?: LeasingDealType, next?: LeasingDealType) =>
    curr?.insuranceOutput?.monthlyPayment !== next?.insuranceOutput?.monthlyPayment;

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate}>
      {({ getFieldsValue }) => {
        const deal: LeasingDealType | undefined = getFieldsValue(true);
        const monthlyPayment = deal?.insuranceOutput?.monthlyPayment;
        const renderLable = () => {
          if (isNum(monthlyPayment) && monthlyPayment > 0) {
            return (
              <span className="text-dark text-large">
                {format.number(monthlyPayment)} <small>{_t("krone_per_month")}</small>
              </span>
            );
          }
          return "";
        };

        return (
          <Form.Item
            label={<span className="text-dark text-large">{_t("kasko_insurance")}</span>}
            name={["insuranceInput", "active"]}
            valuePropName="checked"
          >
            <SwitchInput label={renderLable()} disabled={disabled} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default LeasingInsuranceActiveSwitch;
