import { Checkbox, Form, Popover, Typography } from "antd";
import React, { FC } from "react";
import InputNum from "../../../components/form/inputNum";
import InputPrice from "../../../components/form/inputPrice";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import LeasingDealExtraFields from "./leasingDealExtraFields";
import LeasingPeriodFields from "./leasingPeriodFields";
import LeasingVehicleAge from "./leasingVehicleAge";
import LeasingVehiclePrice from "./leasingVehiclePrice";
import Icon from "../../../assets/icons/icon";
import format from "../../../utilities/formatNumbers";
import ServerLink from "../../../components/serverLink";

const LeasingDealFormFields: FC = () => {
  const { leasingCase, isPrivate } = LeasingStore.useContainer();
  const { flags, expectedTaxPrice, sourceCase } = leasingCase;
  const { readonly, inputLocked } = flags;
  const disabled = readonly || inputLocked;

  const expectedTaxPriceContent = expectedTaxPrice ? [
    <span key={1}>{_t('expected_tax_price') + ` ${format.price(expectedTaxPrice)}`}</span>,
    (sourceCase ? <span key={2}>&nbsp;<ServerLink link={sourceCase} target="_blank" /></span> : null)
  ] : null;

  return (
    <>
      <LeasingVehiclePrice />
      <Form.Item
        name={["input", "taxPrice"]}
        label={(<>
          <strong>{_t("registration_fee")}</strong>
          {expectedTaxPrice && (
            <Popover placement="top" arrowPointAtCenter content={expectedTaxPriceContent}>
              <Typography.Text type="warning">
                <Icon name="information-outline" size="large" />
              </Typography.Text>
            </Popover>
          )}
        </>)}
        rules={[{ required: true }]}
      >
        <InputPrice min={0} disabled={disabled} />
      </Form.Item>
      <Form.Item label={_t("leasing_period")} className="mb-1">
        <LeasingPeriodFields disabled={disabled} parentKey={"input"} />
      </Form.Item>
      {!leasingCase.partnerCase && (
        <Form.Item valuePropName="checked" name={["input", "inactivePeriod"]} label=" ">
          <Checkbox disabled={disabled}>{_t("inactive_period")}</Checkbox>
        </Form.Item>
      )}
      <LeasingVehicleAge />
      <Form.Item
        name={["input", "annualKilometer"]}
        label={_t("mileage_per_year")}
        rules={[{ required: true }]}
        className="mb-2"
      >
        <InputNum min={0} disabled={disabled} maxLength={8} suffix={<small>{_t("mileage_unit")}</small>} />
      </Form.Item>
      <LeasingDealExtraFields />
    </>
  );
};

export default LeasingDealFormFields;
