import { useMemo, useReducer, useState } from "react";
import { createContainer } from "unstated-next";
import { ChecklistProps } from "../components/checklist/checklist";
import { ChecklistEntryViewType, ChecklistGroupType, ExpandedObjType } from "../types/checklistTypes";
import LayoutStore from "./layoutStore";
import { useLocalStorage } from "../hooks/useLocalStorage";

interface ChecklistAdminStoreProps extends ChecklistProps {
  groups: ChecklistGroupType[];
  dispatchGroups: React.Dispatch<ActionGroupsType>;
  drawerVisibility: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  openGroupSortDrawer: () => void;
  closeGroupSortDrawer: () => void;
  expanded: ExpandedObjType | null;
  setExpanded: React.Dispatch<React.SetStateAction<ExpandedObjType | null>>;
  editing: ExpandedObjType | null;
  setEditing: React.Dispatch<React.SetStateAction<ExpandedObjType | null>>;
  showIgnored: boolean;
  setShowIgnored: React.Dispatch<React.SetStateAction<boolean>>;
  showCompleted: boolean;
  setShowCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  sorting: boolean;
  setSorting: React.Dispatch<React.SetStateAction<boolean>>;
  groupSortDrawer: boolean;
  loading: boolean;
  total: number;
  completedTotal: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  asDrawer?: boolean;
}

type ActionTypes =
  | "populate"
  | "delete_group"
  | "toggle_isPublic"
  | "add_group"
  | "add_entry"
  | "update_entry"
  | "delete_entry";
type ActionGroupsType = {
  type: ActionTypes;
  payload: ChecklistEntryViewType | ChecklistGroupType | ChecklistGroupType[];
};

function groupsReducer(state: ChecklistGroupType[], action: ActionGroupsType) {
  const { type, payload } = action;
  switch (type) {
    case "populate": {
      return payload as ChecklistGroupType[];
    }

    case "toggle_isPublic": {
      const group = payload as ChecklistGroupType;
      return state.map((g) => (g.id === group.id ? { ...g, isPublic: !g.isPublic } : g));
    }

    case "add_entry": {
      const nextGroups = [...state];
      const newEntry = payload as ChecklistEntryViewType;
      const groupIndex = nextGroups.findIndex((g) => g.id === newEntry.groupId);
      let nextEntries: ChecklistEntryViewType[] = [];
      const prevEntries = nextGroups[groupIndex].entries;
      const prevlength = prevEntries.length;
      if (!prevlength || newEntry.position === prevlength + 1) {
        // If no entries exist in the group, or add at the end. Simply push the new entry:
        nextEntries = [...prevEntries, newEntry];
      } else {
        for (let i = 0; i < prevlength; i++) {
          const element = prevEntries[i];
          if (element.position === newEntry.position) {
            // add the new entry:
            nextEntries = [...nextEntries, newEntry, { ...element, position: element.position + 1 }];
          } else if (element.position > newEntry.position) {
            // Incrementing the position of every entry after the added entry:
            nextEntries.push({ ...element, position: element.position + 1 });
          } else {
            // Adding existing entries:
            nextEntries.push(element);
          }
        }
      }
      nextGroups[groupIndex].entries = nextEntries;
      return nextGroups;
    }

    case "add_group": {
      const group = payload as ChecklistGroupType;
      const nextGroups = [...state, group];

      return nextGroups;
    }

    case "update_entry": {
      const nextState = [...state];
      const item = payload as ChecklistEntryViewType;
      const groupIndex = nextState.findIndex((g) => g.id === item.groupId);
      const itemIndex = nextState[groupIndex]?.entries.findIndex((e) => e.id === item.id);
      nextState[groupIndex].entries[itemIndex] = item;
      return nextState;
    }

    case "delete_group": {
      const group = payload as ChecklistGroupType;
      return [...state].filter((g) => g.id !== group.id);
    }

    case "delete_entry": {
      const nextState = [...state];
      const item = payload as ChecklistEntryViewType;
      const groupIndex = nextState.findIndex((g) => g.id === item.groupId);
      const itemIndex = nextState[groupIndex]?.entries.findIndex((e) => e.id === item.id);
      nextState[groupIndex]?.entries.splice(itemIndex, 1);
      return nextState;
    }

    default:
      throw new Error("Unhandled reducer type: " + type);
  }
}

const useChecklistAdminStore = (initialState?: ChecklistProps): ChecklistAdminStoreProps => {
  const [drawerVisibility, setDrawerVisibility] = useLocalStorage<boolean>('dmf.admin.leasing.checklist.initial_open',!!initialState?.initialOpen);
  const [showIgnored, setShowIgnored] = useLocalStorage<boolean>('dmf.admin.leasing.checklist.show_ignored', false)
  const [showCompleted, setShowCompleted] = useLocalStorage<boolean>('dmf.admin.leasing.checklist.show_completed', true)
  const [loading, setLoading] = useState<boolean>(false);
  const [sorting, setSorting] = useState<boolean>(false);
  const [groups, dispatchGroups] = useReducer(groupsReducer, []);
  const [expanded, setExpanded] = useState<ExpandedObjType | null>(null);
  const [editing, setEditing] = useState<ExpandedObjType | null>(null);
  const [groupSortDrawer, setGroupSortDrawer] = useState<boolean>(false);
  const { screen } = LayoutStore.useContainer();
  const openDrawer = () => {
    if (initialState?.asDrawer || screen.isMobile) {
      document.body.style.overflow = "hidden";
    }
    setDrawerVisibility(true);
  }

  const closeDrawer = () => {
    document.body.style.overflow = "auto";
    setExpanded(null);
    setDrawerVisibility(false);
  };

  const openGroupSortDrawer = () => {
    setGroupSortDrawer(true);
  };

  const closeGroupSortDrawer = () => {
    setGroupSortDrawer(false);
  };

  const allEntries = useMemo(
    () =>
      Array.isArray(groups)
        ? groups?.reduce<ChecklistEntryViewType[]>((acc, item) => {
          const entries = item.entries || [];
          return (acc = [...acc, ...entries.filter((e) => !e.ignored)]);
        }, [])
        : [],
    [groups]
  );
  const total = allEntries.length;
  const completedTotal = allEntries?.filter((e) => e.completed).length;

  return {
    ...(initialState as ChecklistProps),
    groups,
    dispatchGroups,
    drawerVisibility,
    openDrawer,
    closeDrawer,
    expanded,
    setExpanded,
    editing,
    setEditing,
    showIgnored,
    setShowIgnored,
    showCompleted,
    setShowCompleted,
    groupSortDrawer,
    openGroupSortDrawer,
    loading,
    setLoading,
    sorting,
    total,
    completedTotal,
    setSorting,
    closeGroupSortDrawer,
  };
};

const ChecklistAdminStore = createContainer(useChecklistAdminStore);

export default ChecklistAdminStore;
