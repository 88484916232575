import React, { FC } from "react";
import { Space, SpaceProps } from "antd";
import { ReactComponent as MasterCard } from "../assets/images/mastercard.svg";
import { ReactComponent as MobilePay } from "../assets/images/mobilepay.svg";
import { ReactComponent as DKCard } from "../assets/images/dankort.svg";
import { ReactComponent as VisaCard } from "../assets/images/visa.svg";

const PaymentOptions: FC<SpaceProps> = ({ size = "large", align = "center", style = { marginTop: 4 }, ...rest }) => {
  return (
    <Space {...{ style, size, align, ...rest }}>
      <DKCard />
      <MobilePay />
      <VisaCard />
      <MasterCard />
    </Space>
  );
};

export default PaymentOptions;
