import { Badge, Tabs } from "antd";
import React, { FC, useState } from "react";
import UserStore from "../../contexts/userStore";
import _t from "../../lang/translate";
import { notifiTabKeys, NotifiTabKeys } from "../../types/notificationsType";
import NotificationList from "./notificationList";

const { TabPane } = Tabs;

interface NotificationsDrawerProps {
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationsDrawer: FC<NotificationsDrawerProps> = ({
  setVisibility,
}) => {
  const { notificationsCount } = UserStore.useContainer();
  const [activeTab, setActiveTab] = useState<NotifiTabKeys>(notifiTabKeys.all);

  /*   const menu = (
    <Menu>
      <Menu.Item>{_t("mark_all_read")}</Menu.Item>
    </Menu>
  ); */

  return (
    <>
      <Tabs
        activeKey={activeTab}
        onChange={(activeKey) => setActiveTab(activeKey as NotifiTabKeys)}
      /* tabBarExtraContent={
        <Dropdown overlay={menu}>
          <Button type="text" shape="circle" className="mr-1">
            <Icon name="ellipsis-vertical-sharp" />
          </Button>
        </Dropdown>
      } */
      >
        <TabPane tab={_t("all")} key={notifiTabKeys.all}>
          <NotificationList
            tapName={notifiTabKeys.all}
            setVisibility={setVisibility}
          />
        </TabPane>
        <TabPane
          tab={
            <Badge size="small" count={notificationsCount.notifications}>
              {_t("activities")}
            </Badge>
          }
          key={notifiTabKeys.notification}
        >
          <NotificationList
            tapName={notifiTabKeys.notification}
            setVisibility={setVisibility}
          />
        </TabPane>
        <TabPane
          tab={
            <Badge size="small" count={notificationsCount.posts}>
              {_t("news")}
            </Badge>
          }
          key={notifiTabKeys.post}
        >
          <NotificationList
            tapName={notifiTabKeys.post}
            setVisibility={setVisibility}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default NotificationsDrawer;
