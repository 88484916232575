import React, { FC, useState } from "react";
import { Button, message } from "antd";
import Icon from "../../../assets/icons/icon";
import ErrorMessage from "../../../components/messages/errorMessage";
import SavedMessage from "../../../components/messages/savedMessage";
import ImportCaseStore from "../../../contexts/importCaseStore";
import _t from "../../../lang/translate";
import { downloadTakeHomeAgreement } from "../../../services/importService";

const TakeHomeAgreementDownloadBtn: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { requested } = importCase.flags;
  const [loading, setLoading] = useState<boolean>();

  const handleTakeHomeAgreementDownload = async () => {
    try {
      setLoading(true);
      const { data } = await downloadTakeHomeAgreement(importCase.id);
      if (data.url) window.open(data.url, "_blank");
      message.success(<SavedMessage />);
    } catch ({ response }) {
      const { message: msg } = response?.data || {};
      message.error(<ErrorMessage message={msg} />);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button onClick={handleTakeHomeAgreementDownload} loading={loading} type="primary" ghost disabled={!requested}>
      <Icon name="download-outline" size="large" />
      {_t("take-home-agreement")}
    </Button>
  );
};

export default TakeHomeAgreementDownloadBtn;
