import moment from "moment";
import React from "react";
import _t from "../../lang/translate";

type PaymentCardDetailsProps = {
    paymentDate?: string | null;
    paymentPerson?: string | null;
    paymentId?: string | number | null;
    footer?: React.ReactNode;
}
export default function PaymentCardDetails({ paymentDate, paymentId, paymentPerson, footer }: PaymentCardDetailsProps) {
    const formattedDate = moment(paymentDate).format("DD-MM-YYYY") + " Kl. " + moment(paymentDate).format("HH.mm")
    return (
        <div className="mt-1 p-1 border-light border-radius">
            <div className="d-flex justify-content-between mb-05">
                <span className="muted">{_t("payment_date")}</span>
                <span>{formattedDate}</span>
            </div>
            <div className="d-flex justify-content-between mb-05">
                <span className="muted">{_t("payment_id")}</span>
                <span>{paymentId}</span>
            </div>
            <div className="d-flex justify-content-between">
                <span className="muted">{_t("payment_done_by")}</span>
                <span>{paymentPerson}</span>
            </div>
            {footer}
        </div>
    )
}
