import { FC } from "react";
import { Button, Col, Form, message, Row } from "antd";
import { useHistory } from "react-router-dom";
import SavedMessage from "../../../components/messages/savedMessage";
import CustomerStore from "../../../contexts/customerStore";
import _t from "../../../lang/translate";
import { updateCustomer } from "../../../services/customerService";
import { CustomerType } from "../../../types/customerTypes";
import consts from "../../../utilities/consts";
import CustomerFormFields from "./customerFormFields";
import DividerLine from "../../../components/lineSplit";
import ContactPersonsFieldList from "../../../components/form/contactPersonsFieldList";
import { getPath } from "../../../routes/appRoutes";

interface CustomerInfoFormProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  stopEditing: () => void;
}

const CustomerInfoForm: FC<CustomerInfoFormProps> = ({ setLoading, stopEditing }) => {
  let history = useHistory();
  const { customer, isPrivate, isNew, setCustomer } = CustomerStore.useContainer();
  const [customerForm] = Form.useForm();

  const handleSave = async (values: CustomerType) => {
    try {
      setLoading(true);
      const { data } = await updateCustomer<CustomerType>({ ...customer, ...values });
      message.success(<SavedMessage message={_t("saved")} />);
      if (isNew) {
        history.replace(getPath("customer", data.id));
      } else {
        stopEditing();
        setCustomer(data);
        setLoading(false);
      }
    } catch ({ response }) {
      const msg = response?.data?.message;
      const errors = response?.data?.errors;
      errors && customerForm.setFields(errors);
      message.error(msg || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <Form initialValues={customer} form={customerForm} {...consts.formItemProps} onFinish={handleSave} className="mb-0">
      <CustomerFormFields isPrivate={isPrivate} />
      {!isPrivate && (
        <>
          <DividerLine />
          <ContactPersonsFieldList />
        </>
      )}
      <Form.Item noStyle shouldUpdate>
        {({ submit }) =>
          isNew ? (
            <div className="text-right mt-2">
              <Button type="primary" onClick={submit}>
                {_t("create", "customer")}
              </Button>
            </div>
          ) : (
            <Row justify="space-between" className="mt-2">
              <Col>
                <Button onClick={stopEditing}>{_t("cancel")}</Button>
              </Col>
              <Col>
                <Button type="primary" onClick={submit}>
                  {_t("save")}
                </Button>
              </Col>
            </Row>
          )
        }
      </Form.Item>
    </Form>
  );
};

export default CustomerInfoForm;
