import React, { useState } from "react";
import { Button, Form, Input, message, Space } from "antd";
import _t from "../../lang/translate";
import { updateTaxCaseClient } from "../../services/taxService";
import { useEnums } from "../../services/enumService";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { customersAutoComplete, dealersAutoComplete, usersAutoComplete } from "../../services/autocompleteService";
import ServerSelectBox from "../serverSelectBox";
import { updateImportClient } from "../../services/importService";
import { updateTaxDocCaseClient } from "../../services/taxDocService";
import { updateLeasingClient } from "../../services/leasingService";
import { ChangeClientType } from "../../types/appTypes";
import { isAxiosError } from "../../utilities/typeGuard";
import { updateResellerTaxCaseClient } from "../../services/resellerTaxService";

export default function ChangeClient<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<ChangeClientType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id, client, context } = payload;
  const [enums] = useEnums();

  const handleSave = async (values: ChangeClientType) => {
    try {
      setLoading(true);
      let response: any;
      if (context == "tax") {
        response = await updateTaxCaseClient(id, values);
      } else if (context === "taxDoc") {
        response = await updateTaxDocCaseClient(id, values);
      } else if (context === "resellerTax") {
        response = await updateResellerTaxCaseClient(id, values);
      } else if (context === "import") {
        response = await updateImportClient(id, values);
      } else if (context === "leasing") {
        response = await updateLeasingClient(id, values);
      } else {
        throw new Error("unhandled context" + context);
      }
      onSuccess(response.data as T);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };
  const hasDealer = client?.typeId === enums?.client.type[0]?.id;

  return (
    <Form form={tmpForm} initialValues={client} requiredMark={false} layout="vertical" onFinish={handleSave}>
      <h2 className="modal-title">{_t("choose", "customer")}</h2>
      <Form.Item name="typeId" noStyle hidden>
        <Input disabled={loading} />
      </Form.Item>
      {hasDealer ? (
        <>
          <Form.Item name="dealerId" label={_t("dealer")} rules={[{ required: true }]}>
            <ServerSelectBox disabled={loading} apiUrl={dealersAutoComplete()} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.dealerId !== curValues.dealerId}>
            {({ getFieldValue }) => {
              const dealerId = getFieldValue("dealerId") || undefined;
              return (
                <Form.Item name="userId" label={_t("user")} rules={[{ required: true }]}>
                  <ServerSelectBox
                    disabled={loading}
                    apiUrl={usersAutoComplete()}
                    filters={dealerId ? `filter[dealerId]=${dealerId}` : undefined}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </>
      ) : (
        <Form.Item name="customerId" label={_t("customer")} rules={[{ required: true }]}>
          <ServerSelectBox disabled={loading} apiUrl={customersAutoComplete()} />
        </Form.Item>
      )}
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" loading={loading} type="primary">
            {_t("save")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ChangeClient);
