import { FC, useCallback, useEffect } from "react";
import moment from "moment";
import DateSelect, { DateSelectProps } from "./dateSelect";
import { Col, Row } from "antd";
import Icon from "../../assets/icons/icon";

type DateSelectSelectProps = Omit<DateSelectProps, "onChange" | "value"> & {
  value?: [string, string];
  onChange?: (date: [string, string] | null) => void;
  leasingPeriod: number | null;
};

const LeasingDateSelect: FC<DateSelectSelectProps> = ({ value, leasingPeriod, onChange, ...rest }) => {
  const calculateEndDate = useCallback(
    (startDate: string) => moment(startDate).add(leasingPeriod, "M").subtract(1, "d").toISOString(true)
    , [leasingPeriod]
  );

  useEffect(() => {
    if (leasingPeriod && value && value[0] && onChange) {
      const endDate = calculateEndDate(value[0]);
      // Set end date if the new value is different:
      if (moment(endDate).diff(moment(value[1] || ""), "days")) onChange([value[0], endDate]);
    }
  }, [calculateEndDate, leasingPeriod, onChange, value]);

  const handleChange = (newValue: string) => {
    if (onChange && moment(newValue).isValid()) {
      const newValues: [string, string] = [newValue, calculateEndDate(newValue)];
      onChange(newValues);
    }
  };

  return (
    <Row gutter={0} justify="space-between" className="flex-wrap">
      <Col flex="auto">
        <DateSelect value={value ? value[0] : undefined} onChange={handleChange} {...rest} />
      </Col>
      <Col>
        <Icon name="arrow-forward-outline" className="m-05" size="medium" />
      </Col>
      <Col flex="auto">
        <DateSelect value={value ? value[1] : undefined} disabled />
      </Col>
    </Row>
  );
};

export default LeasingDateSelect;
