import React, { FC } from "react";
import StatisticsStore from "../../../contexts/statisticsStore";
import { Line } from "@ant-design/charts";
import format from "../../../utilities/formatNumbers";

const StatisticsCVR: FC = () => {
  const { data: values } = StatisticsStore.useContainer();
  const { rows, columns } = values;
  const data = rows.map((record) => {
    const { date, taxCasesActiveAssessment: assessed, taxCasesAccepted: accepted } = record;
    return {
      title: format.date(date || null),
      value: assessed > 0 ? (accepted / assessed) * 100 : 0,
      type: "CVR",
    };
  });

  const config = {
    data,
    height: 400,
    xField: "title",
    yField: "value",
    slider: {
      start: 0.1,
      end: 0.5,
    },
    point: {
      size: 5,
      shape: "diamond",
    },
  };
  return <Line {...config} seriesField="type" />;
};

export default StatisticsCVR;
