import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { LoginType } from "../../types/loginTypes";
import { getCurrentUser, login, logout, storageKeys } from "../../services/authService";
import _t from "../../lang/translate";
import { getPath } from "../../routes/appRoutes";
import Icon from "../../assets/icons/icon";
import { setJwt } from "../../services/httpService";
import { trackKlaviyoLogin } from "../../services/klaviyoTrackerService";
import { renderErrorMessage } from "../messages/errorMessage";

interface LoginModalFormProps {
  title: string;
  onSuccess: (token: string | null) => void;
}

const LoginModalForm: FC<LoginModalFormProps> = ({ title, onSuccess }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const storageWatcher = useCallback((e: StorageEvent) => {
    if (e.key === storageKeys.token) {
      const nextUser = getCurrentUser();
      const isValid = nextUser?.expired && nextUser.expired > Date.now() / 1000;
      if (nextUser && isValid) {
        setJwt(e.newValue);
        onSuccess(e.newValue);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("storage", storageWatcher);
    return () => {
      window.removeEventListener("storage", storageWatcher);
    };
  }, [storageWatcher]);

  const handleLogin = async (values: LoginType) => {
    try {
      setLoading(true);
      const { data } = await login(values);
      trackKlaviyoLogin(values.email)
      onSuccess(data.accessToken);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const handleForgetPassword = () => {
    const path = getPath("forgotPassword");
    logout();
    window.location.href = path;
  };

  return (
    <Form<LoginType> layout="vertical" name="loginForm" requiredMark={false} onFinish={handleLogin}>
      <h1>{title}</h1>
      <Form.Item name="email" rules={[{ required: true, type: "email" }]} label={_t("email")}>
        <Input autoFocus disabled={loading} prefix={<Icon name="person-outline" />} />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true }]} label={_t("password")}>
        <Input.Password disabled={loading} prefix={<Icon name="lock-closed-outline" />} />
      </Form.Item>
      <Row align="middle" justify="space-between">
        <Col>
          <Button className="underline-style p-0" type="link" disabled={loading} onClick={handleForgetPassword}>
            {_t("forgot_password")}
          </Button>
        </Col>
      </Row>
      <Button disabled={loading} className="fancy-btn mt-1" htmlType="submit" type="primary" block>
        {_t("log_in")}
      </Button>
    </Form>
  );
};

export default LoginModalForm;
