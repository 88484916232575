import { Button, Col, message, Modal as AntModal, Row, Typography } from "antd";
import React, { FC, useState } from "react";
import _t from "../../lang/translate";
import { isAxiosError } from "../../utilities/typeGuard";
import { cancelSubscriptionPackage } from "../../services/subcriptionService";
import appConfig from "../../appConfig";
import moment from "moment/moment";
import LineSplit from "../lineSplit";
import { getSubscription } from "../../services/authService";
import format from "../../utilities/formatNumbers";
import type { ButtonType } from "antd/es/button";
import UserStore from "../../contexts/userStore";

const { Title } = Typography;

type CancelSubscriptionProps = {
  disabled?: boolean,
  children?: React.ReactNode;
  type?: ButtonType
}

const CancelSubscription: FC<CancelSubscriptionProps> = ({ children, disabled, type }) => {
  const { fetchUserData } = UserStore.useContainer();
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false);
  const currentSubscription = getSubscription();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await cancelSubscriptionPackage();
      message.success(_t("subscription", "cancelled"));
      setShowModal(false);
      fetchUserData();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  const subChangesAt = moment().add('1', 'month').startOf('month');

  return (
    <>
      <Button
        type={type || "link"} onClick={() => setShowModal(true)}
        className={type ? '' : "p-0 muted"}
        disabled={disabled}
      >
        {children || _t('cancel', 'subscription')}
      </Button>

      <AntModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        confirmLoading={loading}
        onOk={handleSubmit}
        okText={_t('cancel', 'subscription')}
        cancelText={_t('go_back')}
        className="no-footer-border"
      >
        <div>
          <Title level={3} className="text-center mt-2 pb-1">
            <span className="text-bold">{_t('cancel', 'subscription')}</span>
          </Title>

          <p className="muted mt-2 mb-0">
            {_t('yourCurrentSubscription')}
          </p>

          <LineSplit />

          <Row justify="space-between">
            <Col>
              <Title>
                {currentSubscription.package?.title}
              </Title>
            </Col>
            <Col>
              <Title className="text-normal">
                {currentSubscription.package?.priceNoVat ? (
                  `${format.price(currentSubscription.package.priceNoVat)} ${_t('per', 'month')}`
                ) : '-'}
              </Title>
            </Col>
          </Row>

          <p className="mt-2 muted">
            {_t('subscription_stops_at')}&nbsp;
            <strong>{subChangesAt.format(appConfig("dateFormat"))}</strong>
          </p>
          <p className="muted">{_t('you_can_restart_it_any_time')}</p>

          <LineSplit />
          <p className="muted">{_t('cancel_accept_terms')}</p>
        </div>
      </AntModal>
    </>
  )
}

export default CancelSubscription;
