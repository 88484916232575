import { Col, Row, Typography } from "antd";
import React, { FC } from "react";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { NotificationType } from "../../types/notificationsType";
import format from "../../utilities/formatNumbers";
import ServerLink from "../serverLink";

const { Text, Paragraph, Title, Link } = Typography;

interface NotificationItemProps {
  data: NotificationType;
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationItem: FC<NotificationItemProps> = ({
  data,
  setVisibility,
}) => {
  const { isPriority, message, description, date, isRead, link } = data;
  return (
    <div
      className={`notification-item${isRead ? " is-read" : ""}${isPriority ? " is-priority" : ""
        }`}
    >
      <Row justify="space-between" gutter={[16, 8]}>
        <Col span={24}>
          <Text type="secondary" className="notification-item-date">
            {format.dateAndTime(date)}
          </Text>
        </Col>
        {/* <Col span={12}>
            <Link
              onClick={() => handleIsRead(id, !isRead)}
              className="is-read-btn"
            >
              {_t(isRead ? "mark_as_unread" : "mark_as_read")}
            </Link>
          </Col> */}
        <Col span={24}>
          <Row className="flex-wrap" gutter={10} align="middle">
            <Col flex="auto">
              <Text ellipsis className="notification-item-title">
                {message}
              </Text>
            </Col>
            {isPriority && (
              <Col flex="26px">
                <Icon name="alert" size="large" className="is-priority-icon" />
              </Col>
            )}
          </Row>
        </Col>
        {description && (
          <Col span={24}>
            <Paragraph
              ellipsis={{
                rows: 2,
                expandable: true,
                symbol: `${_t("read", "more")}`,
              }}
              className="notification-item-message"
            >
              {description}
            </Paragraph>
          </Col>
        )}
        <Col span={24}>
          {link && (
            <ServerLink link={link} onClick={() => setVisibility(false)} />
          )}
        </Col>
      </Row>
    </div>
  );
};
/*
function getNotificationLink(link: LinkType) {
  if (typeof link.external?.trim() === "string") {
    let title = link.external;
    if (title.startsWith("tel:")) title = title.replace("tel:", "");
    if (title.startsWith("mailto:")) title = title.replace("mailto:", "");

    return { href: link.external, title, target: "_blank" };
  } else if (link.internalModule) {
    let result = getPath(link.internalModule);
    if (!result) return "";
    // pass link.params[0]
    result = link.search
      ? `${result}?${convertToAppQuery(link.search)}`
      : result;
    result =
      Array.isArray(link.params) && link.params.length
        ? `${result}/${link.params.join("/")}`
        : result;
    result = typeof link.hash === "string" ? result + link.hash : result;
    return {
      href: result,
      title: getTitleByRoute(link.internalModule),
      target: "_self",
    };
  } else {
    return null;
  }
}
 */
export default NotificationItem;
