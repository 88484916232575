import React from "react";
import assertFC from "../../utilities/assertFC";
import { Button, Col, Popover, Row } from "antd";
import { Container, Draggable } from "react-smooth-dnd";
import Icon from "../../assets/icons/icon";
import sortArrayAfterDrop from "../../utilities/sortArrayAfterDrop";
import { CustomColumnsType, OnHideChange, OnSortChange } from "./dataTableTypes";
import _t from "../../lang/translate";

interface SortColumnsProps<P> {
  columns: CustomColumnsType<P>;
  onSort: OnSortChange<P>;
  onHideClick: OnHideChange;
}

export default function SortColumns<P>({ columns, onHideClick, onSort }: SortColumnsProps<P>) {
  return (
    <Popover
      placement="bottomRight"
      title={<strong className="muted">{_t("resort_columns")}</strong>}
      trigger="click"
      overlayClassName="sort-cols-wp min-width-240"
      content={
        <Container
          onDrop={(e) => {
            const results = sortArrayAfterDrop(columns, e);
            onSort(results);
          }}
        >
          {columns.map((item) => (
            <Draggable key={item.key}>
              <Row align="middle" gutter={16} className={!item.hidden ? "col-visible" : "col-hidden"}>
                <Col flex="28px">
                  <Icon name="reorder-two" size="large" />
                </Col>
                <Col flex="auto">{item.title}</Col>
                <Col flex="54px">
                  <Button
                    className={!item.hidden ? "delete-btn" : "primary-text-btn"}
                    type="text"
                    shape="circle"
                    onClick={() => onHideClick(!!!item.hidden, item.key)}
                    icon={<Icon name={!item.hidden ? "eye-off-outline" : "eye-outline"} />}
                  />
                </Col>
              </Row>
            </Draggable>
          ))}
        </Container>
      }
    >
      <Button type="text" shape="circle" icon={<Icon name="ellipsis-vertical-sharp" />} />
    </Popover>
  );
}

assertFC(SortColumns);
