import { Card } from "antd";
import React, { FC } from "react";
import _t from "../../../../lang/translate";
import ImportDownloadBtns from "./importDownloadBtns";
import ImportProcessSteps from "./importProcessSteps";

const ImportProcess: FC = () => {
  return (
    <Card bordered={false} title={_t("process")}>
      <ImportProcessSteps />
      <ImportDownloadBtns />
    </Card>
  );
};

export default ImportProcess;
