import { Divider } from "antd";
import React, { FC } from "react";
import { ChecklistGroupType } from "../../types/checklistTypes";
import ChecklistGroupUserHeader from "./checklistGroupUserHeader";
import ChecklistUserItem from "./checklistUserItem";

interface ChecklistGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  group: ChecklistGroupType;
}

const ChecklistUserGroup: FC<ChecklistGroupProps> = ({ group, ...rest }) => {
  return (
    <div {...rest}>
      <ChecklistGroupUserHeader group={group} />
      <Divider className="mt-05 mb-05" />
      {group.entries?.map((item) => (
        <div key={item.id}>
          <ChecklistUserItem item={item} />
          <Divider className="mt-05 mb-05" />
        </div>
      ))}
    </div>
  );
};

export default ChecklistUserGroup;
