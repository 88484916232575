import { FC, useEffect } from "react";
import format from "../../../../../utilities/formatNumbers";
import { isNum } from "../../../../../utilities/typeGuard";
import { regulateAdvertMileage } from "../../calc/tradePriceSubCalculations";

interface TaxRecalculatedMileageCellProps {
  year?: number | null;
  mileage?: number | null;
  vehicleYear?: number | null;
  value?: number | null;
  calculationDate?: string | null;
  onChange?: (v: number | null) => void;
}

const TaxRecalculatedMileageCell: FC<TaxRecalculatedMileageCellProps> = ({
  year,
  mileage,
  vehicleYear,
  value,
  calculationDate,
  onChange,
}) => {
  useEffect(() => {
    onChange && onChange(regulateAdvertMileage(year, mileage, vehicleYear, calculationDate));
  }, [year, mileage, year, vehicleYear]);

  return <span>{isNum(value) ? format.number(value) : "-"}</span>;
};

export default TaxRecalculatedMileageCell;
