import { useEffect, useState } from "react";

type UseIsDraggingFilesType = [isDragging: boolean];

function useIsDraggingFiles(options?: { isModal: boolean }): UseIsDraggingFilesType {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  useEffect(() => {
    var lastEnter: any;

    const handleDragEnter = (ev: DragEvent) => {
      // Add class to prevent dragleave on children:
      document.body.classList.add("pointer-event-none");
      lastEnter = ev.target;
      return ev;
    };

    const handleDragOver = (ev: DragEvent) => {
      ev.preventDefault();
      const isModalOpen = document.body.classList.contains("ant-scrolling-effect") && !options?.isModal;

      // Check if dragged files is accepted, then switch isDragging on:
      if (!isModalOpen && containsFiles(ev)) setIsDragging(true);
      return ev;
    };

    const handleDragLeave = (ev: DragEvent) => {
      // Check if the target is window and clean up:
      if (lastEnter === ev.target) {
        document.body.classList.remove("pointer-event-none");
        setIsDragging(false);
      }
      return ev;
    };

    const handleDrop = (ev: DragEvent) => {
      document.body.classList.remove("pointer-event-none");
      setIsDragging(false);
      return ev;
    };

    document.addEventListener("dragenter", handleDragEnter);
    document.addEventListener("dragover", handleDragOver);
    document.addEventListener("dragleave", handleDragLeave);
    document.addEventListener("drop", handleDrop);
    return () => {
      document.removeEventListener("dragenter", handleDragEnter);
      document.removeEventListener("dragover", handleDragOver);
      document.removeEventListener("dragleave", handleDragLeave);
      document.removeEventListener("drop", handleDrop);
    };
  }, []);

  return [isDragging];
}

function containsFiles(event: DragEvent) {
  const types = event.dataTransfer?.types;
  if (types) {
    return types.some((t) => t === "Files");
  }

  return false;
}

export default useIsDraggingFiles;
