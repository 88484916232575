import { Form, FormInstance } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createContainer } from "unstated-next";
import { isLatestVersion, isSupportedVersion, latestVersion } from "../screens/forms/leasing/calc/leasingCalcService";
import { LeasingCalcVersionType, LeasingDealType } from "../screens/forms/leasing/calc/leasingCalcTypes";
import { isPrivateCustomer } from "../services/customerService";
import { initLeasingCase } from "../services/leasingService";
import { LeasingAdminType, LeasingCaseType, VersionStatusType } from "../types/leasingTypes";
import { convertToUploadFileList, isApiFiles } from "../utilities/convertTypes";
import UserStore from "./userStore";

interface LeasingStoreProps {
  isAdmin: boolean;
  isPrivate: boolean;
  leasingCase: LeasingCaseType;
  setLeasingCase: (newCase: LeasingCaseType) => void;
  adminValues: LeasingAdminType | null;
  setAdminValues: (newValues: LeasingAdminType) => void;
  leasingForm: FormInstance<LeasingDealType>;
  calcCount: number;
  setCalcCount: React.Dispatch<React.SetStateAction<number>>;
  vehicleLoading: boolean;
  setVehicleLoading: React.Dispatch<React.SetStateAction<boolean>>;
  customerLoading: boolean;
  setCustomerLoading: React.Dispatch<React.SetStateAction<boolean>>;
  versionStatus: VersionStatusType;
  setVersionStatus: React.Dispatch<React.SetStateAction<VersionStatusType>>;
}

const useLeasingStore = (initialState?: LeasingCaseType | null): LeasingStoreProps => {
  const { hasPermission } = UserStore.useContainer();
  const [leasingCase, setLeasingCase] = useState<LeasingCaseType>(initLeasingCase);
  const [vehicleLoading, setVehicleLoading] = useState<boolean>(false);
  const [versionStatus, setVersionStatus] = useState<VersionStatusType>("latest");
  const [customerLoading, setCustomerLoading] = useState<boolean>(false);
  const [calcCount, setCalcCount] = useState<number>(0);
  const [adminValues, setAdminValues] = useState<LeasingAdminType | null>(null);
  const [leasingForm] = Form.useForm<LeasingDealType>();
  const isAdmin = useMemo(
    () => hasPermission(leasingCase.partnerCase ? "partner_leasing.isAdmin" : "leasing.isAdmin"),
    [hasPermission, leasingCase]
  );

  const handleSetLeasingCase = useCallback(
    (newCase: LeasingCaseType) => {
      const initFiles = newCase.files;
      const files = initFiles && isApiFiles(initFiles) ? convertToUploadFileList(initFiles) : initFiles;
      setLeasingCase({ ...newCase, files });
    },
    [setLeasingCase]
  );

  const handleSetLeasingAdminValues = useCallback(
    (newValues: LeasingAdminType) => {
      setAdminValues(newValues);
    },
    [setAdminValues]
  );

  const handleVersionStatus = useCallback(
    (leasingCase: LeasingCaseType) => {
      if (!leasingCase.id || !leasingCase.deal.version) {
        return { ...leasingCase, deal: { ...leasingCase.deal, version: latestVersion } };
      }
      let nextLeasingCase = { ...leasingCase };
      const newVersionStatus = getVersionStatus(leasingCase.deal.version);
      const { active, expired, contractGenerated, validationAccepted } = nextLeasingCase.flags;
      const statusesNotRecalculable = active || expired || contractGenerated || validationAccepted;
      if (
        (nextLeasingCase.flags && newVersionStatus === "expired") ||
        (newVersionStatus === "supported" && statusesNotRecalculable)
      ) {
        setVersionStatus("expired");
        nextLeasingCase.flags.readonly = true;
      } else if (newVersionStatus !== "latest" && nextLeasingCase.deal) {
        setVersionStatus("supported");
        nextLeasingCase.deal.version = latestVersion;
      }
      return nextLeasingCase;
    },
    [setVersionStatus]
  );

  useEffect(() => {
    if (initialState) {
      const nextLeasingCase = handleVersionStatus(initialState);
      handleSetLeasingCase(nextLeasingCase);
    }
  }, [initialState, handleSetLeasingCase]);

  return {
    isAdmin,
    isPrivate: isPrivateCustomer(leasingCase.customer?.typeId),
    leasingCase,
    setLeasingCase: handleSetLeasingCase,
    adminValues,
    calcCount,
    setCalcCount,
    setAdminValues: handleSetLeasingAdminValues,
    leasingForm,
    vehicleLoading,
    setVehicleLoading,
    customerLoading,
    setCustomerLoading,
    setVersionStatus,
    versionStatus,
  };
};

const LeasingStore = createContainer(useLeasingStore);

export default LeasingStore;

const getVersionStatus = (version: LeasingCalcVersionType): VersionStatusType => {
  if (isLatestVersion(version)) {
    return "latest";
  }
  if (isSupportedVersion(version)) {
    return "supported";
  }
  return "expired";
};
