import React, { FC, useState } from "react";
import { Button, Col, message, Row, Typography } from "antd";
import { DraggerProps } from "antd/lib/upload";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import _t from "../lang/translate";
import { deleteFile, updateFile } from "../services/mediaService";
import { accepted } from "../utilities/acceptedFiles";
import DividerLine from "./lineSplit";
import UploadFileRender from "./uploadFileRender";
import UploadButton from "./uploadButton";
import useIsDraggingFiles from "../hooks/useIsDraggingFiles";
import DragUpload from "./dragUpload";
import { isAxiosError } from "../utilities/typeGuard";

interface DocumentsUploadProps extends DraggerProps {
  canLockFiles?: boolean;
  action: string;
  canDelete?: boolean;
  fileList?: UploadFile[];
  onChange?: (info: UploadChangeParam<UploadFile<any>>) => void;
  onDownloadAll?: () => void;
}

const DocumentsUpload: FC<DocumentsUploadProps> = ({
  action,
  fileList,
  onChange,
  onDownloadAll,
  canDelete = true,
  canLockFiles = false,
  accept = accepted.join(","),
  ...rest
}) => {
  const [isDragging] = useIsDraggingFiles();
  const [loadingIds, setLoadingIds] = useState<(number | string)[]>([]);

  const startLoading = (id: number | string) => {
    setLoadingIds((ids) => (!ids.includes(id) ? [...ids, id] : ids));
  };

  const endLoading = (id: number | string) => {
    setLoadingIds((ids) => (ids.includes(id) ? ids.filter((i) => i !== id) : ids));
  };

  const handleFileRemove = async (file: UploadFile) => {
    const serverId = file.response?.id;
    const localId = file.uid;
    let canRemove: boolean | undefined = true;
    if (serverId) {
      try {
        startLoading(localId);
        await deleteFile(action, serverId);
      } catch (error) {
        const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
        message.error(errorMessage || _t("msg.unknown_error"));
        canRemove = false;
      } finally {
        endLoading(localId);
      }
    }
    if (canRemove) {
      const updatedFileList = fileList?.filter((f) => f.uid !== localId) || [];
      onChange && onChange({ fileList: updatedFileList, file: file });
    }
  };

  const toggleIsAdmin = async (file: UploadFile) => {
    const serverId = file.response?.id;
    const localId = file.uid;
    const nextStatus = !file.response?.isAdmin;

    if (serverId && typeof serverId === "number") {
      try {
        startLoading(localId);
        await updateFile(action, serverId, { isAdmin: nextStatus });
        const fileToUpdate = fileList?.filter((f) => f.uid === localId)[0];
        const updatedList = fileList?.map((f) =>
          f.uid === localId ? { ...f, response: { ...f.response, isAdmin: nextStatus } } : f
        );
        if (onChange && fileToUpdate) onChange({ fileList: updatedList || [], file: fileToUpdate });
      } catch (ex) {
        message.error(_t("msg.isAdmin_not_switched"));
      } finally {
        endLoading(localId);
      }
    }
  };

  const total: number = fileList?.filter((f) => f.status === "done").length || 0;

  const props = {
    action,
    accept,
    multiple: true,
    fileList,
    onChange,
    ...rest,
  };

  return (
    <>
      {fileList?.map((file, i) => (
        <div key={file.uid}>
          <UploadFileRender
            onRemove={canDelete ? handleFileRemove : undefined}
            onAdminToggle={canLockFiles ? toggleIsAdmin : undefined}
            file={file}
            loading={loadingIds.includes(file.uid)}
          />
          {i + 1 < fileList.length ? (
            <DividerLine className="mt-05 mb-05" />
          ) : (
            <DividerLine weight={2} className="mt-05 mb-1" />
          )}
        </div>
      ))}
      {
        <DragUpload className={`${isDragging ? "is-dragging" : ""} file-drop-zone`} showUploadList={false} {...props}>
          <Typography.Title level={3} className="mb-0">
            {_t("msg.drop_her_to_attach")}
          </Typography.Title>
        </DragUpload>
      }
      <Row align="middle">
        <Col flex="auto" className="muted">
          <strong>{total}</strong> {_t("files_in_attached_to_case")}
          {!!total && (
            <>
              ,&nbsp;
              <Button type="link" onClick={onDownloadAll} className="p-0" size="small">
                {_t("download", "all")}
              </Button>
            </>
          )}
        </Col>
        <Col>
          <UploadButton {...props} />
        </Col>
      </Row>
    </>
  );
};

export default DocumentsUpload;
