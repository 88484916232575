import React, { FC, useCallback, useMemo, useState } from "react";
import {
  AutoComplete,
  Avatar,
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  Menu,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import Icon from "../assets/icons/icon";
import LayoutStore from "../contexts/layoutStore";
import { Link, useHistory } from "react-router-dom";
import UserStore from "../contexts/userStore";
import { getPath } from "../routes/appRoutes";
import _t from "../lang/translate";
import { PermissionTypes } from "../types/permissionTypes";
import Notifications from "../components/notifications/notifications";
import { getRoles } from "../services/authService";
import RoleSwitch from "./roleSwitch";
import { isModulTypes, SearchItemType } from "../types/appTypes";
import { doSearch, jumpToCase } from "../services/searchService";
import debounce from "lodash/debounce";
import { isAxiosError } from "../utilities/typeGuard";
import { getInternalLink } from "../components/serverLink";

const { Header } = Layout;

const Search: FC = () => {
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<SearchItemType[]>([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = useCallback(async (query: string | null) => {
    let items: SearchItemType[] = [];
    setLoading(true);

    if (query) {
      const { data } = await doSearch(query) || {};


      if (data && Array.isArray(data.data)) {
        items = data.data.map(item => {
          const context = item.type?.contexts && isModulTypes(item.type.contexts) ? item.type.contexts : null;
          const contextTitle = item.type?.contexts && isModulTypes(item.type.contexts) ? item.type.title : null;
          const link = context ? getPath(context, item.id) : null;

          const valueItems = [
            contextTitle,
            item.id ? `#${item.id}` : null,
            item.vehicle
          ]

          return {
            value: valueItems.filter(val => !!val).join(' - '),
            link: link
          }
        })
      }
    }

    setOptions(items);
    setLoading(false);
  }, []);

  const onSearch = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  const onSelect = (data: string) => {
    const item = options.find(option => option.value === data);

    if (item?.link) {
      setSearchValue(searchValue);
      history.replace(item.link);
    }
  }

  return (
    <Row align="middle">
      <Col className="flex-grow">
        <AutoComplete
          className="d-flex"
          onSearch={onSearch}
          onSelect={onSelect}
          value={searchValue}
          onChange={val => setSearchValue(val || '')}
          bordered={false}
          placeholder={_t("search_placeholder")}
          options={options}
        />
      </Col>

      <Col>
        <Spin spinning className={!loading ? "opacity-0" : ""} />
      </Col>
    </Row>
  );
}

const JumpTo: FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<{ caseId: string | null }>();

  const onSubmit = async () => {
    const { caseId } = form.getFieldsValue()

    if (!caseId) {
      return;
    }

    try {
      setLoading(true);
      const { data } = await jumpToCase(caseId || "") || {};
      const link = data.data;
      setLoading(false);
      const location = link && getInternalLink(link);
      history.push(location || getPath("notFound"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.not_found"));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form onFinish={onSubmit} form={form}>
      <Row align="middle">
        <Col>
          <Form.Item name="caseId" className="m-0 mx-width-180">
            <Input
              className="d-flex"
              placeholder={_t('find_case')}
              bordered={false}
            />
          </Form.Item>
        </Col>

        <Col>
          <Spin spinning className={!loading ? "opacity-0" : ""} />
        </Col>
      </Row>
    </Form>
  );
}

export const AppHeader: FC = () => {
  const { setSideMenuVisiblity, screen } = LayoutStore.useContainer();
  const { user, hasPermission, claimedRole } = UserStore.useContainer();
  const history = useHistory();
  const [currentRole, setCurrentRole] = useState<string>(
    user?.role.title || ""
  );
  const roles = getRoles();

  const menu = (
    <Menu forceSubMenuRender>
      {user && (
        <>
          {user.dealer && (
            <Menu.Item
              key="my_company"
              onClick={() => history.push(getPath("dealer", user?.dealer.id))}
              icon={<Icon name="albums-outline" />}
            >
              {_t("my_company")}
            </Menu.Item>
          )}
          <Menu.Item
            key="my_account"
            onClick={() => history.push(getPath("myAccount"))}
            icon={<Icon name="person-circle-outline" />}
          >
            {_t("my_account")}
          </Menu.Item>
          <Menu.Item
            key="invoices"
            onClick={() => history.push(getPath("purchase"))}
            icon={<Icon name="alert-circle-outline" />}
          >
            {_t("invoices")}
          </Menu.Item>
        </>
      )}
      <Menu.Item
        key="logout"
        onClick={() => history.push(getPath("logout"))}
        icon={<Icon name="log-out-outline" />}
      >
        {_t("logout")}
      </Menu.Item>
    </Menu>
  );

  interface AddNewList {
    title: string;
    path: string;
    permission: PermissionTypes;
  }

  const newList: AddNewList[] = [
    {
      title: _t("tax-doc"),
      path: getPath("taxDoc", "new"),
      permission: "taxDoc.create",
    },
    {
      title: _t("tax"),
      path: getPath("tax", "new"),
      permission: "tax.create",
    },
    {
      title: _t("import"),
      path: getPath("import", "new"),
      permission: "import.create",
    },
    {
      title: _t("leasing"),
      path: getPath("leasing", "new"),
      permission: "leasing.create",
    },
    {
      title: _t("partner_leasing"),
      path: getPath("partnerLeasing", "new"),
      permission: "partner_leasing.create",
    },
  ];

  const newMenu = (
    <Menu style={{ width: 180 }}>
      <Menu.ItemGroup title={_t("create", "new")}>
        {newList.map((item) =>
          hasPermission(item.permission) ? (
            <Menu.Item key={item.path}>
              <Link to={item.path}>{item.title}</Link>
            </Menu.Item>
          ) : null
        )}
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Header
      className={`site-layout-background${!!claimedRole ? " danger-border-bottom" : ""
        }`}
    >
      {screen.isTablet ? (
        <Row className="d-flex" justify="space-between" align="middle">
          <Col>
            <Button
              shape="circle"
              size="large"
              type="text"
              onClick={() => setSideMenuVisiblity(true)}
              icon={<Icon name="menu-outline" />}
            />
          </Col>
          <Col>
            <Dropdown
              className="p-0"
              overlay={newMenu}
              placement="bottomCenter"
              arrow
            >
              <Button
                shape="circle"
                size="large"
                type="text"
                icon={<Icon name="add-outline" />}
              />
            </Dropdown>
            {/* <Button
              shape="circle"
              size="large"
              type="text"
              onClick={() => setSideMenuVisiblity(true)}
              icon={<Icon name="search-outline" />}
            /> */}
          </Col>
          <Col className="flex-grow">
            <Search />
          </Col>
          <Col>
            <Notifications />
          </Col>
          <Col>
            <Dropdown overlay={menu} placement="bottomLeft" arrow>
              <div className="user-area">
                <Avatar>{user?.initials}</Avatar>
              </div>
            </Dropdown>
          </Col>
        </Row>
      ) : (
        <Row className="d-flex" align="middle">
          <Col>
            <Dropdown
              className="p-0"
              overlay={newMenu}
              placement="bottomLeft"
              arrow
            >
              <Button
                shape="circle"
                size="large"
                type="text"
                icon={<Icon name="add-outline" />}
              />
            </Dropdown>
          </Col>
          <Col>
            <JumpTo />
          </Col>
          <Col className="flex-grow">
            <Search />
          </Col>
          <Col className="header-actions">
            {(hasPermission("system.see_as") || !!claimedRole) && roles && (
              <RoleSwitch {...{ roles, currentRole, setCurrentRole }} />
            )}


            <Notifications />

            <Dropdown overlay={menu} placement="bottomLeft" arrow>
              <div className="user-area">
                <Avatar>{user?.initials}</Avatar>
                <Typography.Text ellipsis className="user-name">
                  {user?.name}
                </Typography.Text>
              </div>
            </Dropdown>
          </Col>
        </Row>
      )}
    </Header>
  );
};

export default AppHeader;
