import { Tag, TagProps } from "antd";
import React, { FC } from "react";
import Icon from "../assets/icons/icon";
import { getSavedEnums } from "../services/authService";
import { EnumItemType } from "../types/appTypes";
import hexToRGB from "../utilities/colorUtil";

export interface StatusTagProps extends TagProps {
  status?: EnumItemType | null;
  statusId?: number | null;
  solid?: boolean;
  size?: "small" | "large";
  locked?: boolean;
}

const StatusTag: FC<StatusTagProps> = ({ status, statusId, locked, solid, size, color, children, ...rest }) => {
  if (statusId) {
    status = getSavedEnums()?.status.find((st) => st.id === statusId);
  }
  const { icon, onClick } = rest;
  let styles: React.CSSProperties = {
    cursor: onClick ? "pointer" : "default",
    padding: size === "small" ? "2px 10px" : "5px 18px",
    marginRight: 0,
  };

  if (solid || status?.color) {
    styles.color = solid ? "#fff" : status?.color;
    styles.backgroundColor = solid ? status?.color || color : hexToRGB(status?.color || color || "", 0.2);
  }

  return (
    <Tag
      style={styles}
      color={!status ? color : undefined}
      icon={locked ? <Icon name="lock-closed-outline" size="medium" /> : icon}
      {...rest}
    >
      {status?.title ? status.title : children}
    </Tag>
  );
};

export default StatusTag;
