import { FC, useEffect } from "react";
import format from "../../../../../utilities/formatNumbers";
import { isNum } from "../../../../../utilities/typeGuard";
import { getDifferenceBetween } from "../../calc/tradePriceSubCalculations";

interface TaxEquipmentsDiffCellProps {
  vehiclesEquipmentsPrice?: number | null;
  vehicleEquipmentsPrice?: number | null;
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const TaxEquipmentsDiffCell: FC<TaxEquipmentsDiffCellProps> = ({
  vehiclesEquipmentsPrice,
  vehicleEquipmentsPrice,
  value,
  onChange,
}) => {
  useEffect(() => {
    onChange && onChange(getDifferenceBetween(vehicleEquipmentsPrice, vehiclesEquipmentsPrice));
  }, [vehiclesEquipmentsPrice, vehicleEquipmentsPrice]);

  return <>{isNum(value) ? <span className="text-dark">{format.number(value)}</span> : "-"}</>;
};

export default TaxEquipmentsDiffCell;
