import { Button, Col, Collapse, Menu, message, Row, Space, Typography, } from "antd";
import DropdownButton from "antd/lib/dropdown/dropdown-button";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import React, { FC, useState } from "react";
import CustomCollapse from "../../../../components/customCollapse";
import CustomCollapseHeader from "../../../../components/customCollapseHeader";
import Details from "../../../../components/detailsList";
import InputNum from "../../../../components/form/inputNum";
import SwitchInput from "../../../../components/form/switchInput";
import openModal from "../../../../components/modal/openModal";
import SavedStatus from "../../../../components/savedStatus";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import {
  previewDocumentation,
  publishEvaluation,
  sendEvaluation,
  updateSettings,
} from "../../../../services/taxDocService";
import { TaxDocCase, TaxDocSettingsType } from "../../../../types/taxDocTypes";
import format from "../../../../utilities/formatNumbers";
import { isAxiosError } from "../../../../utilities/typeGuard";

const { Panel } = Collapse;

interface TaxDocPrerequisitesProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaxDocPrerequisites: FC<TaxDocPrerequisitesProps> = ({ setLoading }) => {
  const { taxDocCase, setTaxDocCase } = TaxDocCaseStore.useContainer();
  const { settings, flags } = taxDocCase;
  const [collapseKeys, setCollapseKeys] = useState<string[]>([]);
  const [productPrice, setProductPrice] = useState<number | string>(
    settings?.productPrice || 0
  );

  const handleSave = async () => {
    const nextSettings: TaxDocSettingsType = {
      ...settings,
      productPrice: productPrice as number,
    };
    try {
      setLoading(true);
      const { data } = await updateSettings(taxDocCase.id, nextSettings);
      message.success(_t("saved"));
      setTaxDocCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const savedStatus = settings?.productPrice !== productPrice;

  const handlePublish: SwitchChangeEventHandler = async (checked) => {
    try {
      setLoading(true);
      const { data } = await publishEvaluation(taxDocCase.id, {
        status: checked,
      });
      message.success(_t("saved"));
      setTaxDocCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = async () => {
    try {
      setLoading(true);
      const { data } = await previewDocumentation(taxDocCase.id);
      if (data.url) window.open(data.url, '_blank');
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async () => {
    try {
      setLoading(true);
      const { data } = await sendEvaluation(taxDocCase.id);
      setTaxDocCase(data);
      message.success(_t("sent"));
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleBuy = async () => {
    try {
      const data = await openModal<TaxDocCase>("buyTaxDoc", {
        id: taxDocCase.id,
      });
      setTaxDocCase(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async () => {
    try {
      const data = await openModal<TaxDocCase>("rejectTaxDoc", {
        id: taxDocCase.id,
      });
      setTaxDocCase(data);
    } catch (error) {
      console.log(error);
    }
  };

  const caseTypeText = taxDocCase.isQuickCalculation
    ? _t("estimated", "evaluation")
    : _t("documented", "evaluation");

  const sendMenu = (
    <Menu className="min-width-125">
      <Menu.Item key="1" onClick={handleBuy}>
        {_t("request")}
      </Menu.Item>
      <Menu.Item key="2" danger onClick={handleReject}>
        {_t("reject")}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Typography.Title level={4} className="mb-1">
        {_t("publishing")}
      </Typography.Title>
      <CustomCollapse activeKey={collapseKeys} setActiveKey={setCollapseKeys}>
        <Panel
          key="1"
          header={
            <CustomCollapseHeader>
              {_t("settings")} <small>({caseTypeText})</small>
            </CustomCollapseHeader>
          }
          forceRender
        >
          <Details borderLess>
            <Details.Item label={_t("type")} value={caseTypeText} />
            <Details.Item
              label={_t("documentation", "end_date")}
              value={format.date(settings?.documentationExpirationDate)}
            />
            <Details.Item
              label={_t("guarantee_period")}
              value={settings?.guaranteeLengthInMonths}
            />
            <Details.Item
              align="middle"
              label={_t("guarantee_price")}
              value={
                <InputNum
                  className="mx-width-180"
                  value={productPrice}
                  onChange={setProductPrice}
                  min={0}
                  suffix="kr."
                />
              }
            />
          </Details>
          <div className="text-right">
            <SavedStatus status={!savedStatus} />
            <Button type="primary" onClick={handleSave}>
              {_t("save")}
            </Button>
          </div>
        </Panel>
      </CustomCollapse>
      <Row justify="space-between" align="middle">
        <Col>
          <SwitchInput
            disabled={flags.rejected || flags.generatingDocumentation}
            size="small"
            label={_t("publish")}
            id="publish-switch"
            checked={flags.documentationPublished}
            onChange={handlePublish}
          />
        </Col>
        <Col>
          <Space>
            <Button ghost type="primary" onClick={handlePreview}>
              {_t("preview")}
            </Button>
            <DropdownButton
              disabled={flags.rejected || flags.generatingDocumentation}
              type="primary"
              onClick={handleSend}
              overlay={sendMenu}
            >
              {_t("send")}
            </DropdownButton>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default TaxDocPrerequisites;
