import React, { FC } from "react";
import { Col, Row } from "antd";
import Icon from "../../../../assets/icons/icon";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";
import TaxCaseStore from "../../../../contexts/taxCaseStore";

const TaxDocPurchasedMessage: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();

  return (
    <Row className="flex-wrap mb-1">
      <Col flex="auto">
        <h3 className="">
          <Icon
            name="shield-checkmark-outline"
            size="huge"
            className="text-primary"
          />
          <span className="pl-05">{_t("documentation_purchased")}</span>
        </h3>
        <label className="d-block">
          {_t("documentation_ready_to_download")}
        </label>
        <label className="d-block">{_t("garanti_expiration__see_more")}</label>
      </Col>
      <Col flex="100px" className="text-right">
        <h3 className="mb-0">
          {format.price(taxCase.documentationSettings?.productPriceWithVat)}
        </h3>
        <small className="d-block text-right">
          {format.price(taxCase.documentationSettings?.productPrice)}
          <br /> {_t("exclude_short", "vat").toLowerCase()}
        </small>
      </Col>
    </Row>
  );
};

export default TaxDocPurchasedMessage;
