import React, { FC } from "react";
import { Alert, Col, Row } from "antd";
import ConflictAlert from "../../../../components/conflictAlert";
import TaxDocVehicleEdit from "./taxDocVehicleEdit";
import TaxDocAttachments from "../taxDocAttachments";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import TaxDocActions from "./taxDocActions";
import TaxDocCalculationCard from "./taxDocCalculationCard";

const TaxDocCaseEdit: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();

  return (
    <Row align="top" gutter={24}>
      <Col xxl={{ span: 8, order: 1 }} xl={{ span: 12, order: 3 }} xs={{ span: 24, order: 3 }}>
        <TaxDocVehicleEdit />
      </Col>
      <Col xxl={{ span: 8, order: 2 }} xl={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }}>
        <TaxDocCalculationCard />
      </Col>
      <Col xxl={{ span: 8, order: 3 }} xl={{ span: 12, order: 2 }} xs={{ span: 24, order: 2 }}>
        {taxDocCase.importantNote && (
          <Alert className="mb-2" message={taxDocCase.importantNote} type="warning" closable />
        )}
        {taxDocCase.relatedCases && <ConflictAlert conflictedCases={taxDocCase.relatedCases} />}
        <TaxDocActions />
        <TaxDocAttachments />
      </Col>
    </Row>
  );
};

export default TaxDocCaseEdit;
