import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { initUser, UserType } from "../types/userTypes";
import UserStore from "./userStore";

interface UserStoreProps {
  user: UserType;
  isNew: boolean;
  setUser: React.Dispatch<React.SetStateAction<UserType>>;
  loading: boolean;
  isAdmin: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useUserStore = (initialState?: UserType): UserStoreProps => {
  const [user, setUser] = useState<UserType>(initUser);
  const [loading, setLoading] = useState<boolean>(false);
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("import.isAdmin");

  useEffect(() => {
    if (initialState) {
      setUser(initialState);
    }
  }, [initialState]);

  return {
    user,
    setUser,
    loading,
    setLoading,
    isAdmin,
    isNew: user.id === 0,
  };
};

const UserPageStore = createContainer(useUserStore);

export default UserPageStore;
