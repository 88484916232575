import React, { FC } from "react";
import { Button, Checkbox, Col, Divider, Form, Input, message, Popconfirm, Radio, Row } from "antd";
import appConfig from "../../../../appConfig";
import ErrorMessage from "../../../../components/messages/errorMessage";
import SavedMessage from "../../../../components/messages/savedMessage";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { getApiBaseUrl } from "../../../../services/apiEndpoint";
import eventTracker from "../../../../services/eventsTrackerService";
import { acceptImportOffer } from "../../../../services/importService";
import { ImportOrderType } from "../../../../types/importTypes";
import consts from "../../../../utilities/consts";
import format from "../../../../utilities/formatNumbers";
import DividerLine from "../../../../components/lineSplit";

interface ImportOrderFormProps {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImportRequestForm: FC<ImportOrderFormProps> = ({ setLoading, loading }) => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const offer = importCase.vehicleOffer?.offer;

  const handleOrderVehicle = async (values: ImportOrderType) => {
    try {
      setLoading(true);
      const { data } = await acceptImportOffer(importCase.id, values);
      eventTracker.register("import_orderd");
      message.success(<SavedMessage escHint={false} />);
      setLoading(false);
      setImportCase(data);
    } catch ({ response }) {
      const { message: msg } = response?.data || {};
      message.error(<ErrorMessage message={msg} />);
      setLoading(false);
    }
  };

  return (
    <Form<ImportOrderType>
      {...consts.formItemProps}
      onFinish={handleOrderVehicle}
      initialValues={{
        deliveryAddress: importCase.order?.deliveryAddress || null,
        isCarStatusReport: false,
      }}
      requiredMark={false}
    >
      <h4 className="mb-1">{_t("delivery_address")}</h4>
      <Form.Item rules={[{ required: true }]} name={["deliveryAddress", "name"]} label={_t("name")}>
        <Input />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name={["deliveryAddress", "address"]} label={_t("address")}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("zip", "city", " & ")}>
        <Row gutter={12}>
          <Col flex="100px">
            <Form.Item rules={[{ required: true }]} name={["deliveryAddress", "zip"]} noStyle>
              <Input />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item rules={[{ required: true }]} name={["deliveryAddress", "city"]} noStyle>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name={["deliveryAddress", "phone"]} label={_t("phone")}>
        <Input />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name={["deliveryAddress", "contact"]} label={_t("contact_person")}>
        <Input />
      </Form.Item>
      <Divider />
      <h4 className="mb-1">{_t("addons")}</h4>
      <Form.Item name="isExpressDelivery" valuePropName="checked" label={_t('express_delivery')} hidden={true}>
        <Checkbox>
          {format.price(offer?.prices.expressDelivery, 0, "EUR")}
        </Checkbox>
      </Form.Item>
      <Form.Item name="isCarStatusReport" label={_t("dekra")} valuePropName="checked">
        <Checkbox>{format.price(appConfig("dekraPrice"), 2, "EUR")}</Checkbox>
      </Form.Item>
      <Divider />
      <h4 className="mb-05">{_t("payment")}</h4>

      <Form.Item noStyle shouldUpdate={(curr, next) => {
        return (
          curr.isCarStatusReport !== next.isCarStatusReport ||
          curr.isExpressDelivery !== next.isExpressDelivery
        );
      }}>
        {({ getFieldValue }) => {
          if (!offer?.totalPrice) return null;
          const dekra = getFieldValue("isCarStatusReport") ? appConfig("dekraPrice") : 0;
          const express = getFieldValue("isExpressDelivery") ? offer.prices.expressDelivery : 0;
          const price = offer.totalPrice + dekra + express;
          return (
            <Form.Item name="valutaId" label={_t("valuta")} initialValue={1}>
              <Radio.Group className="text-left">
                <Radio value={1}>
                  <h4>
                    DKK {format.price(price * appConfig("conversionRate"))}{" "}
                    <small>
                      ({_t("valuta_dkk_title")} {appConfig("conversionRate")})
                    </small>
                  </h4>
                </Radio>
                <Radio value={2}>
                  <h4>
                    EUR {format.price(price, 0, "EUR")} <small>({_t("late_delivery")})</small>
                  </h4>
                </Radio>
              </Radio.Group>
            </Form.Item>
          );
        }}
      </Form.Item>
      <DividerLine fluid weight={2} />
      <Form.Item name="termsAccepted" noStyle valuePropName="checked">
        <Checkbox>
          {_t("i_read_and_accept")}&nbsp;
          <a href={getApiBaseUrl(appConfig("static")?.ImportbusinessTerms)} target="_blank">
            {_t("business_terms")}
          </a>
        </Checkbox>
      </Form.Item>
      <Form.Item name="addressConfirmed" valuePropName="checked">
        <Checkbox>
          {_t("i_confirm_delivery_address")}
        </Checkbox>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ submit, getFieldValue }) => {
          const termsAccepted = getFieldValue("termsAccepted");
          const addressConfirmed = getFieldValue("addressConfirmed");
          const zip = getFieldValue(["deliveryAddress", "zip"]);
          const isSameZip = String(zip) === String(importCase.client?.zip);
          return (
            <div className="d-block text-right mt-1">
              {isSameZip ? (
                <Button disabled={!termsAccepted || loading} type="primary" onClick={submit}>
                  {_t("accept")}
                </Button>
              ) : (
                <Popconfirm title={_t('delivery_fee_different_zip')} onConfirm={submit}>
                  <Button disabled={!termsAccepted || !addressConfirmed || loading} type="primary">
                    {_t("accept")}
                  </Button>
                </Popconfirm>
              )}
            </div>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default ImportRequestForm;
