import React, { FC, useEffect, useState } from "react";
import UserStore from "../contexts/userStore";
import { Modal } from "antd";
import _t from "../lang/translate";

const CookieModal: FC = () => {
  const { isShowCookiePolicy, hideCookiePolicy } = UserStore.useContainer();
  const showCookies = !!process.env.REACT_APP_COOKIEBOT_ID;
  const [contentHtml, setContentHtml] = useState<string>('');

  useEffect(() => {
    let sourceEl: HTMLElement | null = document.getElementById('cookieBotContent');

    if (sourceEl) {
      sourceEl = sourceEl.cloneNode(true) as HTMLElement;
      sourceEl.querySelector('#CookieDeclaration')?.remove();
      setContentHtml(sourceEl.innerHTML);
    }

    return;
  }, [isShowCookiePolicy]);

  return showCookies ? (
    <Modal width={1000}
      title={_t('cookie_policy')}
      destroyOnClose={true}
      visible={isShowCookiePolicy} footer={null} onCancel={hideCookiePolicy}>
      <div dangerouslySetInnerHTML={{ __html: contentHtml }}></div>
    </Modal>
  ) : null;
};

export default CookieModal;
