import { Button, Empty, message } from "antd";
import { FC } from "react";
import Icon from "../../../assets/icons/icon";
import CollapseCard from "../../../components/collapseCard";
import DividerLine from "../../../components/lineSplit";
import openModal from "../../../components/modal/openModal";
import MerchantStore from "../../../contexts/merchantStore";
import _t from "../../../lang/translate";
import { deleteContactPerson } from "../../../services/merchantService";
import { ContactPersonType } from "../../../types/appTypes";
import { isAxiosError } from "../../../utilities/typeGuard";

const MerchantContactPersons: FC = () => {
  const { merchant, setMerchant, isNew, loading, setLoading } = MerchantStore.useContainer();

  const handleAdd = async () => {
    try {
      const data = await openModal<ContactPersonType>("addMerchantContact", {
        parentId: merchant.id,
        returnContact: true,
      });
      const contactPersons = [...(merchant.contactPersons || []), data];
      setMerchant({ ...merchant, contactPersons });
    } catch (e) {
    }
  };

  const handleDelete = async (id: number) => {
    try {
      setLoading(true);
      await deleteContactPerson(merchant.id, id);
      const contactPersons = merchant.contactPersons?.filter((cp) => cp.id !== id) || [];
      setMerchant({ ...merchant, contactPersons });
      message.success(_t("deleted"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <CollapseCard title={_t("contact_persons")} loading={loading} disable={isNew} defaultOpen={!isNew}>
      {!!merchant.contactPersons?.length ? (
        merchant.contactPersons.map((cp) => {
          const { name, email, phone, id } = cp;
          const seperator = email && phone ? ` • ` : "";
          return (
            <div className="mb-1 drawer-item position-relative" key={id}>
              <span className="d-block text-dark">{name}</span>
              <small>
                {phone} {seperator} {email}
              </small>
              <div className="drawer-item-actions position-absolute top-0 right-0 p-05">
                <Button
                  danger
                  className="muted"
                  type="link"
                  size="small"
                  shape="circle"
                  icon={<Icon name="trash-outline" size="small" />}
                  onClick={() => handleDelete(id)}
                />
              </div>
            </div>
          );
        })
      ) : (
        <Empty className="mb-1" description={_t("no_contact_persons")} />
      )}
      <DividerLine className="mt-05 mb-1" />
      <Button onClick={handleAdd}>
        <Icon name="add-outline" />
        {_t("add", "contact_person")}
      </Button>
    </CollapseCard>
  );
};

export default MerchantContactPersons;
