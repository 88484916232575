import React from "react";
import { Button, Modal as AntModal } from "antd";
import { saveStartTrialModal } from "../services/authService";

const Tools: React.FC = () => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const closeModal = () => {
    setShowModal(false);
  }

  const startTrialModal = () => {
    saveStartTrialModal(true);
    window.location.reload();
  }

  return (
    <>
      <Button className="muted pl-1 pr-1" type="link" onClick={() => setShowModal(true)}>
        TESTING MENU
      </Button>

      <AntModal
        destroyOnClose={true}
        visible={showModal}
        onCancel={closeModal}
        onOk={closeModal}
      >
        <div className="mb-1">
          <button onClick={startTrialModal}>Open "Start Trial" Modal</button>
        </div>
      </AntModal>
    </>
  );
};

export default Tools;
