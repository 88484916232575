import { createContainer } from "unstated-next";

import { StatisticsType } from "../types/statisticsType";

interface StatisticsStoreProps {
  data: StatisticsType;
}

const initData: StatisticsType = {
  columns: [],
  rows: [],
  totals: null,
  info: null,
};

const useStatisticsStore = (
  initialState?: StatisticsType | null
): StatisticsStoreProps => {
  return { data: initialState || initData };
};

const StatisticsStore = createContainer(useStatisticsStore);

export default StatisticsStore;
