import React, { useState } from "react";
import { Button, Form, Space } from "antd";
import _t from "../../lang/translate";
import { downloadPickupAgreement } from "../../services/importService";
import { PickupDocumentFormType } from "../../types/importTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import DateSelect from "../form/dateSelect";
import { renderErrorMessage } from "../messages/errorMessage";

export default function ImportPickupAgreement<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<PickupDocumentFormType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id, pickupDate } = payload;

  const handleDownload = async (values: PickupDocumentFormType) => {
    try {
      setLoading(true);
      const { data } = await downloadPickupAgreement<T>(id, values);
      onSuccess({ ...data, pickupDate: values.pickupDate });
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="vertical" onFinish={handleDownload}>
      <h2 className="modal-title">{_t("order_transport_document")}</h2>
      <Form.Item name="pickupDate" label={_t("ready_for_pickup_date")} initialValue={pickupDate}>
        <DateSelect disabled={loading} />
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            {_t("download")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ImportPickupAgreement);
