import React, { FC } from "react";
import { Button, ButtonProps, Col, Modal as AntModal, Row, Typography } from "antd";
import _t from "../lang/translate";
import { useHistory } from "react-router-dom";
import { getPath } from "../routes/appRoutes";

const { Title } = Typography;

type FakeFancySubmitButtonProps = ButtonProps & {
  text?: string
}

const FakeFancySubmitButton: FC<FakeFancySubmitButtonProps> = (props) => {
  let history = useHistory();
  const [showModal, setShowModal] = React.useState<boolean>(false)

  const hideModal = () => setShowModal(false);

  const goToSubs = () => {
    hideModal();
    history.push(getPath("subscription"));
  }

  return (
    <>
      <Button {...props} className="fancy-btn" type="primary" onClick={() => setShowModal(true)}>
        {props.text || _t("start_calculating")}
      </Button>

      <AntModal
        destroyOnClose={true}
        visible={showModal}
        onCancel={hideModal}
        onOk={goToSubs}
        okText={_t('show', 'subscription_packages')}
        cancelText={_t('close', 'window')}
        className="no-footer-border"
      >
        <Row justify="space-between" gutter={30 * 2} align="middle">
          <Col span={24}>
            <div className="visual-bar mt-2" />
            <Title level={3} className="mt-05 pb-1">
              Mangler klip på din konto, kan ikke oprette sag.
            </Title>

            <p>
              Opgrader dit abonnement for flere klip eller kontakt os for en særaftale. Besøg vores abonnementsoversigt for at opgradere. Vi beklager ulejligheden. Kontakt os for yderligere spørgsmål.
            </p>
          </Col>
        </Row>
      </AntModal>
    </>
  );
};

export default FakeFancySubmitButton;
