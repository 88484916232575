import { Col, Row, Space } from "antd";
import { FC } from "react";
import DealerAdminStore from "../../../contexts/dealerAdminStore";
import _t from "../../../lang/translate";
import DealerAdminClipOverview from "./dealerAdminClipOverview";
import DealerAdminClipUpdate from "./dealerAdminClipUpdate";

const DealerAdminClips: FC = () => {
  const { admin } = DealerAdminStore.useContainer();

  return (
    <Row justify="space-between">
      <Col>
        <div>
          <span className="text-larger text-dark">{admin.creditsLeft}</span> {_t("credits")}
        </div>
        <div>
          <span className="text-larger text-dark">{admin.clipAmount}</span> {_t("clips")}
        </div>
      </Col>
      <Col>
        <Space>
          <DealerAdminClipUpdate />
          <DealerAdminClipOverview />
        </Space>
      </Col>
    </Row>
  );
};

export default DealerAdminClips;
