import { SelectValue } from "antd/lib/select";
import React, { FC, ReactText } from "react";
import { useHistory } from "react-router-dom";
import EnumSelect from "../../components/form/enumSelect";
import UserStore from "../../contexts/userStore";
import _t from "../../lang/translate";
import { getPath } from "../../routes/appRoutes";
import { getLeasingStatuses } from "../../services/leasingService";
import { statusKey } from "../../services/urlQueryService";
import { EnumItemType } from "../../types/appTypes";

const LeasingTableFilter: FC = () => {
  const { hasPermission } = UserStore.useContainer();
  const history = useHistory();
  const partnerCase = !history.location.pathname.includes(getPath("leasing"));
  const isAdmin = hasPermission(partnerCase ? "partner_leasing.isAdmin" : "leasing.isAdmin");
  const statuses = getLeasingStatuses(isAdmin);
  const initStatusFilter = new URLSearchParams(history.location.search).get(statusKey) as ReactText;

  const filterByContext = (data: EnumItemType[]) =>
    data?.filter(
      (item) =>
        item?.contexts?.includes("leasing") && item?.id !== statuses?.active?.id && item?.id !== statuses?.expired?.id
    );

  const onStatusFilterChange = (id?: SelectValue) => {
    const query = new URLSearchParams(history.location.search);
    if (!id) query.delete(statusKey);
    else query.set(statusKey, id.toString());

    history.push({
      ...history.location,
      pathname: history.location.pathname,
      search: query.toString(),
    });
  };

  return (
    <EnumSelect
      dataFilter={filterByContext}
      nameKey={["status"]}
      value={initStatusFilter !== null ? Number(initStatusFilter) : undefined}
      className="width-180"
      showSearch={false}
      placeholder={_t("status", "filter")}
      allowClear
      onChange={onStatusFilterChange}
    />
  );
};

export default LeasingTableFilter;
