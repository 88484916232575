import React, { FC, useState } from "react";
import { message } from "antd";
import { downloadAllFiles, taxFileUrl } from "../../../services/taxService";
import DocumentsUpload from "../../../components/documentsUpload";
import TaxCaseStore from "../../../contexts/taxCaseStore";
import _t from "../../../lang/translate";
import CollapseCard from "../../../components/collapseCard";
import { isAxiosError } from "../../../utilities/typeGuard";

const TaxAttachments: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { taxCase, setTaxCase, isAdmin } = TaxCaseStore.useContainer();

  const handleDownloadAll = async () => {
    try {
      setLoading(true);
      const { data } = await downloadAllFiles(taxCase.id);
      if (data?.url) window.open(data.url, "_blank");
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <CollapseCard title={_t("attachments")} loading={loading}>
      <DocumentsUpload
        onDownloadAll={handleDownloadAll}
        fileList={taxCase.files || []}
        canLockFiles={isAdmin}
        onChange={({ fileList }) => {
          setTaxCase({ ...taxCase, files: fileList });
        }}
        action={taxFileUrl(taxCase?.id)}
      />
    </CollapseCard>
  );
};

export default TaxAttachments;
