import axios from "axios";
import { message } from "antd";
import {
  getCurrentUser,
  logout,
  saveClipCount,
  saveCreditCount,
  saveFreeDocBuysLeftCount,
  saveNotificatiosCount,
} from "./authService";
import log, { setLogContext } from "./logService";
import _t from "../lang/translate";
import showLoginModal from "../components/modal/showLoginModal";

let isRequestingLogin = false;
let requestsToRefresh: any[] = [];

export function setJwt(jwt: string | null) {
  if (jwt !== null) axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
}

axios.interceptors.response.use(
  (r) => {
    saveNotificatiosCount(r.headers["x-unreadnotifications"], r.headers["x-unreadposts"]);
    saveClipCount(r.headers["x-clipamount"]);
    saveCreditCount(r.headers["x-creditsleft"]);
    saveFreeDocBuysLeftCount(r.headers["x-freedocbuysleft"]);
    return r;
  },
  async (error) => {
    const { response, config } = error;
    const status = response && response.status;
    const user = getCurrentUser();
    if (status === 401 && user) {
      if (!isRequestingLogin) {
        isRequestingLogin = true;
        showLoginModal()
          .then((token) => {
            requestsToRefresh.forEach((cb) => cb(token));
          })
          .catch(() => {
            requestsToRefresh.forEach((cb) => cb(null));
            logout();
            window.location.href = "/";
          })
          .finally(() => {
            // Clear queue of failed requests
            requestsToRefresh = [];
            isRequestingLogin = false;
            return null;
          });
      } else {
        if (config.url.includes("login")) {
          setLogContext({ error_response: response });
          log(error.response);
          return Promise.reject(error);
        }
      }

      return new Promise((resolve, reject) => {
        requestsToRefresh.push((token: string) => {
          if (token) {
            config.headers.Authorization = "Bearer " + token;
            resolve(axios(config));
          }
          reject(error);
        });
      });
    } else if (status <= 400 && status > 500) {
      setLogContext({ error_response: error.response });
      log(error.response);
      message.error(_t("msg.unknown_error"));
      return Promise.reject(error.response);
    } else if (status === 503) {
      setLogContext({ error_response: error.response });
      message.error(_t("msg.maintenance"));
      //window.open("/maintenance.html", "_self");
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

const items = {
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  put: axios.put,
  patch: axios.patch,
  headers: axios.defaults.headers,
};

export default items;
