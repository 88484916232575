import React, { FC } from "react";
import { Form } from "antd";
import _t from "../../../../lang/translate";
import { initNewPriceInput, NewPriceCalcTaxDocType } from "../../../../types/taxDocTypes";
import TotalNewPriceField from "./totalNewPriceField";

const TotalNewPrice: FC = () => {
  return (
    <Form.Item shouldUpdate={true} noStyle>
      {({ getFieldsValue }) => {
        const newPriceInput: NewPriceCalcTaxDocType = getFieldsValue(true)?.newPrice;
        const useTechnicalNewPrice = newPriceInput?.useTechnicalNewPrice || false;
        return (
          <Form.Item name={["newPrice", "newPrice"]} label={<h4 className="mb-0">{_t("total")}</h4>} className="mb-0">
            <TotalNewPriceField
              newPriceInput={newPriceInput || initNewPriceInput}
              useTechnicalNewPrice={useTechnicalNewPrice}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default TotalNewPrice;
