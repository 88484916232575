import { IdType } from "../types/appTypes";
import { ExportDealersType } from "../types/dealerTypes";
import {
  ConfigFieldType,
  SystemValueConfigUpdateType,
  toggleProductAdminType,
  TransportFeeType,
} from "../types/systemTypes";
import getApiEndpoint from "./apiEndpoint";
import http from "./httpService";
import { getApiQuery } from "./urlQueryService";

const apiEndpoint = getApiEndpoint();
const systemEndpoint = `${apiEndpoint}/system`;
const userEndpoint = `${apiEndpoint}/user`;

export function userUrl(id: string | number) {
  return `${userEndpoint}/${id}`;
}

export const getAdminListUrl = `${apiEndpoint}/admin`;

export function systemValueConfigUrl() {
  return `${systemEndpoint}/valueConfig`;
}

export function systemMonthlySpecificationUrl(query: any): any {
  return `${systemEndpoint}/monthlySpecification?${getApiQuery(query)}`;
}

export function uploadMonthlySpecificationCsv() {
  return `${systemEndpoint}/monthlySpecification`;
}

export function saveSystemValueConfig(data?: SystemValueConfigUpdateType) {
  return http.put(`${systemEndpoint}/valueConfig`, data);
}

export function getTransportFees() {
  return http.get<TransportFeeType>(`${systemEndpoint}/transportFee`);
}

export function saveTransportFees(data?: TransportFeeType) {
  return http.put(`${systemEndpoint}/transportFee`, data);
}

export function exportDealers({ active, disabled, inactive }: ExportDealersType) {
  const query = `filter[active]=${active}&filter[disabled]=${disabled}&filter[inactive]=${inactive}`;
  return http.get(`${systemEndpoint}/exportDealers?${query}`);
}

export function toggleProductAdmin(userId: IdType, data: toggleProductAdminType) {
  return http.put(`${userUrl(userId)}/productAdmin`, data);
}

export function exportCustomers(ids?: number[]) {
  return http.get(`${systemEndpoint}/exportCustomers${ids ? "?filter[ids]=" + ids?.join("|") : ""}`);
}

export function getConfigValueById(config: ConfigFieldType[], id: string) {
  return config.find((i) => i.id === id)?.value;
}

export function downloadMonthlySpecificationList(query: string) {
  return http.get(`${systemEndpoint}/monthlySpecification/download?${query}`);
}
