import React, { FC, useEffect } from "react";
import InputNum, { InputPriceProps } from "../../../../components/form/inputNum";
import { TradePriceCalcTaxDocType } from "../../../../types/taxDocTypes";

interface TradingPriceLevelProps extends InputPriceProps {
  input?: TradePriceCalcTaxDocType | null;
}

const TradingPriceLevelField: FC<TradingPriceLevelProps> = ({ input, value, onChange, ...rest }) => {
  useEffect(() => {
    const { averagePrice, deliveryFee, deductionAverageDiscount } = input || {};

    const result = (averagePrice || 0) + (deliveryFee || 0) + (deductionAverageDiscount || 0);

    result !== value && onChange && onChange(result);
  }, [input]);

  return <InputNum {...{ onChange, value, ...rest }} />;
};

export default TradingPriceLevelField;
