import { FC } from "react";
import { Form } from "antd";
import SwitchInput from "../../../components/form/switchInput";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";
import { isNum } from "../../../utilities/typeGuard";

const LeasingCarWarrantyActiveSwitch: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const shouldUpdate = (curr: any, next: any) =>
    curr?.carWarrantyOutput?.monthlyPayment !== next?.carWarrantyOutput?.monthlyPayment;

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate}>
      {({ getFieldsValue }) => {
        const monthlyPayment = getFieldsValue(true)?.carWarrantyOutput?.monthlyPayment;
        const monthlyPaymentRender =
          isNum(monthlyPayment) && monthlyPayment > 0 ? (
            <span className="text-dark text-large">
              {format.number(monthlyPayment)}* <small>{_t("krone_per_month")}</small>
            </span>
          ) : (
            ""
          );

        return (
          <Form.Item
            label={<span className="text-dark text-large">{_t("car_warranty")}</span>}
            name={["carWarrantyInput", "active"]}
            valuePropName="checked"
          >
            <SwitchInput disabled={disabled} label={monthlyPaymentRender} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default LeasingCarWarrantyActiveSwitch;
