import { Button, Empty, Space } from "antd";
import React, { FC } from "react";
import _t from "../../lang/translate";
import ChecklistAddGroup from "./checklistAddGroup";

interface EmptyChecklistProps {
  onLoadDefaultChecklist?: () => void;
}

const EmptyChecklist: FC<EmptyChecklistProps> = ({ onLoadDefaultChecklist }) => {
  return (
    <Empty description={_t("empty_checklist_message")}>
      {!onLoadDefaultChecklist ? (
        <ChecklistAddGroup />
      ) : (
        <Space direction="vertical" className="w-100" size="large">
          <Button onClick={onLoadDefaultChecklist}>{_t("load_default_checklist")}</Button>
          <span>{_t("or")}</span>
          <ChecklistAddGroup />
        </Space>
      )}
    </Empty>
  );
};

export default EmptyChecklist;
