import { FC } from "react";
import DividerLine from "../../../components/lineSplit";
import _t from "../../../lang/translate";
import LeasingCarWarranty from "./leasingCarWarranty";
import LeasingGpsTracker from "./leasingGpsTracker";
import LeasingInsurance from "./leasingInsurance";

const LeasingAddonsFormFields: FC = () => {
  return (
    <>
      <LeasingInsurance />
      <DividerLine fluid />
      <LeasingGpsTracker />
      <DividerLine fluid />
      <LeasingCarWarranty />
      <DividerLine fluid />
      <label className="text-italic">* {_t("added_to_monthly_payment")}</label>
    </>
  );
};

export default LeasingAddonsFormFields;
