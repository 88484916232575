import { FC, useState } from "react";
import { Button, Col, Row, Space } from "antd";
import { useHistory } from "react-router";
import ActivityDrawer from "../../../components/activityDrawer";
import openModal from "../../../components/modal/openModal";
import StatusTag from "../../../components/statusTag";
import ResellerTaxCaseStore from "../../../contexts/resellerTaxCaseStore";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import { LocationStateType } from "../../../types/appTypes";
import ResellerTaxCaseCustomerInfo from "./admin/resellerTaxCaseCustomerInfo";
import { ResellerTaxCase } from "../../../types/resellerTaxTypes";

const ResellerTaxCaseHeadExtra: FC = () => {
  const history = useHistory<LocationStateType>();
  const { resellerTaxCase, setResellerTaxCase } = ResellerTaxCaseStore.useContainer();
  const { hasPermission } = UserStore.useContainer();
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const isAdmin = hasPermission("resellerTax.isAdmin");

  const handleStatusChange = async () => {
    try {
      const { id, status } = resellerTaxCase;
      const newCase = await openModal<ResellerTaxCase>("changeStatus", {
        id,
        context: "resellerTax",
        initialValues: {
          id: status?.id,
          forced: resellerTaxCase.flags.forcedStatus,
        },
      });
      setResellerTaxCase(newCase);
    } catch (error) {
    }
  };

  return (
    <Row align="middle">
      <Col flex="auto">{isAdmin && <ResellerTaxCaseCustomerInfo />}</Col>
      <Col>
        <Space>
          {isAdmin ? (
            <StatusTag locked={resellerTaxCase.flags.forcedStatus} status={resellerTaxCase.status}
              onClick={handleStatusChange} />
          ) : (
            <StatusTag status={resellerTaxCase.status} />
          )}

          {isAdmin && (
            <>
              <Button onClick={() => setDrawerVisibility(true)}>{_t("activities", "note", " & ")}</Button>
              <ActivityDrawer
                visible={drawerVisibility}
                isAdmin={isAdmin}
                setVisibility={setDrawerVisibility}
                type="resellerTax"
                id={resellerTaxCase.id}
              />
            </>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default ResellerTaxCaseHeadExtra;
