import { Button, Tooltip } from "antd";
import { FC } from "react";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";

interface AutoCalcSwitchProps {
  value?: boolean | null;
  onChange?: (v: boolean) => void;
}

const AutoCalcSwitch: FC<AutoCalcSwitchProps> = ({ value, onChange }) => {
  const toggleAutoCalculate = () => onChange && onChange(!value);

  return (
    <Tooltip title={_t(value ? "auto_calculate_off" : "auto_calculate_on")}>
      <Button tabIndex={-1} type="text" shape="circle" className="muted no-bg" onClick={toggleAutoCalculate}>
        <Icon name={value ? "flash-off" : "flash"} />
      </Button>
    </Tooltip>
  );
};

export default AutoCalcSwitch;
