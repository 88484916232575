import React, { FC } from "react";
import { Button, ButtonProps, Form } from "antd";
import _t from "../lang/translate";

type FancySubmitButtonProps = ButtonProps & {
  text?: string
}

const FancySubmitButton: FC<FancySubmitButtonProps> = (props) => {
  return (
    <Form.Item shouldUpdate noStyle>
      {({ submit }) => (
        <Button {...props} className="fancy-btn" type="primary" onClick={submit}>
          {props.text || _t("start_calculating")}
        </Button>
      )}
    </Form.Item>
  );
};

export default FancySubmitButton;
