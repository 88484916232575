import { FC } from "react";
import { Col, Row } from "antd";
import CustomerAttachments from "./customerAttachments";
import CustomerInfoCard from "./customerInfoCard";
import CustomerCasesCard from "./customerCasesCard";

const CustomerView: FC = () => {
  return (
    <Row align="top" gutter={24}>
      <Col xxl={{ span: 10, offset: 3 }} xl={{ span: 14 }} xs={{ span: 24 }}>
        <CustomerInfoCard />
        <CustomerCasesCard />
      </Col>
      <Col xxl={{ span: 8 }} xl={{ span: 10 }} xs={{ span: 24 }}>
        <CustomerAttachments />
      </Col>
    </Row>
  );
};

export default CustomerView;
