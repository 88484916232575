import React, { FC, useState } from "react";
import { Button, Col, message, Row, Switch } from "antd";
import ErrorMessage from "../../../../components/messages/errorMessage";
import SavedMessage from "../../../../components/messages/savedMessage";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { updateImportPublished } from "../../../../services/importService";
import ImportSendOfferModal from "./importSendOfferModal";

const ImportOfferActions: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const { offerPublished, offerSent } = importCase.flags;
  const [loading, setLoading] = useState<boolean>();
  const [visible, setVisible] = useState<boolean>(false);

  const disabled = loading || !importCase.vehicleOffer?.offer.salesPrice;

  const toggleOfferPublish = async (checked: boolean) => {
    try {
      setLoading(true);
      const { data } = await updateImportPublished(importCase.id, { status: checked });
      setImportCase(data);
      message.success(<SavedMessage />);
    } catch ({ response }) {
      const { message: msg } = response?.data || {};
      message.error(<ErrorMessage message={msg} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="space-between" gutter={[16, 16]} align="middle" className="mt-2">
      <Col>
        <Switch size="small" disabled={disabled} checked={offerPublished} onChange={toggleOfferPublish} />
        &nbsp; {_t("publish")}
      </Col>
      <Col>
        <Button type="primary" onClick={() => setVisible(true)} disabled={disabled}>
          {offerSent ? _t("send_new_offer") : _t("send", "offer")}
        </Button>
        <ImportSendOfferModal visible={visible} setVisible={setVisible} />
      </Col>
    </Row>
  );
};

export default ImportOfferActions;
