import React, { FC, useCallback, useEffect, useState } from "react";
import CollapseCard from "../../../components/collapseCard";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";
import moment from "moment";
import Details from "../../../components/detailsList";
import { Button, Col, Collapse, Pagination, PaginationProps, Popover, Row, Skeleton, Typography } from "antd";
import ChangePaymentMethod from "../../../components/subscription/changePaymentMethod";
import UserStore from "../../../contexts/userStore";
import RemovePaymentMethod from "../../../components/subscription/removePaymentMethod";
import CancelSubscription from "../../../components/subscription/cancelSubscription";
import CardBox from "../../../components/cardBox";
import PaymentCardBrand from "../../../components/paymentCardBrand";
import { PaymentCardBrandType } from "../../../types/paymentCardType";
import ChangeSubscription from "../../../components/subscription/changeSubscription";
import { useApi } from "../../../services/useApi";
import { PaginationedData } from "../../../types/apiTypes";
import { dealerKey, pageKey, pageSizeKey, searchKey } from "../../../services/urlQueryService";
import debounce from "lodash.debounce";
import { useLocation } from "react-router-dom";
import { LocationStateType } from "../../../types/appTypes";
import { DealerType } from "../../../types/dealerTypes";
import { SubscriptionInvoiceType, SubscriptionType } from "../../../types/subscriptionTypes";
import { subscriptionPaymentUrl } from "../../../services/subcriptionService";
import appConfig from "../../../appConfig";
import Icon from "../../../assets/icons/icon";
import StatusTag from "../../../components/statusTag";
import LoadingContainer from "../../../components/loadingContainer";
import RenewSubscription from "../../../components/subscription/renewSubscription";
import CustomCollapse from "../../../components/customCollapse";
import CustomCollapseHeader from "../../../components/customCollapseHeader";
import { SubscriptionStatusBadge } from "../../../components/CurrentSubscriptionInfo";
import ReactMarkdown from "react-markdown";
import PaymentMethod from "../../../components/paymentDetails/paymentMethod";

const { Panel } = Collapse;

interface InvoiceListProps {
  dealer: DealerType;
}

const InvoiceList: FC<InvoiceListProps> = ({ dealer }) => {
  const location = useLocation<LocationStateType>();
  const [refreshId, setRefreshId] = useState<string | undefined>();
  const [{ data, isLoading, isError }, setUrl] = useApi<PaginationedData<SubscriptionInvoiceType>>("", { data: [] });
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [collapseKeys, setCollapseKeys] = useState<string[]>([]);

  const delayedSetUrl = useCallback(
    debounce((url) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  useEffect(() => {
    if (data.data.length || !isLoading) {
      setInitialLoading(false);
    }
  }, [data.data]);

  const doLoad = useCallback((page: number = 1) => {
    const query = new URLSearchParams(location.search);
    if (typeof refreshId === "string") {
      query.set("refreshId", refreshId);
    }
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "5");
    query.set(dealerKey, String(dealer.id));
    query.set(pageKey, String(page));
    const url = subscriptionPaymentUrl(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [dealer.id, delayedSetUrl, location.search, refreshId, setUrl])

  useEffect(() => {
    doLoad(1)
  }, [doLoad]);

  const onChange: PaginationProps['onChange'] = page => {
    doLoad(page)
  };

  return (
    <Skeleton loading={initialLoading}>
      <CustomCollapse
        activeKey={collapseKeys}
        setActiveKey={setCollapseKeys}
      >
        <Panel key="1" header={<CustomCollapseHeader>{_t('billing_history2')}</CustomCollapseHeader>}>
          <LoadingContainer loading={isLoading}>

            {data.data.map((invoice, i) => (
              <Row key={invoice.url} justify="space-between" align="middle" className={i > 0 ? "mt-1" : ''}>
                <Col>
                  <span className="muted">
                    {moment(invoice.createdAt).format(appConfig("dateFormat"))}
                  </span>
                </Col>

                <Col flex={"80px"}>
                  <span className="muted">
                    {format.pricePrefixed(invoice.total, 2)}
                  </span>
                </Col>

                <Col>
                  <StatusTag size="small" color={invoice.paid ? "green" : "red"}>
                    {invoice.paid ? _t('paid') : _t('unpaid')}
                  </StatusTag>
                </Col>

                <Col>
                  <a href={invoice.url} target="_blank" rel="noreferrer">
                    <Icon name="download-outline" />
                  </a>
                </Col>
              </Row>
            ))}
            {data.meta && (<Pagination
              className="mt-1 text-center"
              size="small"
              total={data.meta.total}
              pageSize={data.meta.per_page}
              hideOnSinglePage={true}
              current={data.meta.current_page}
              onChange={onChange}
              disabled={isLoading}
            />)}

          </LoadingContainer>
        </Panel>
      </CustomCollapse>
    </Skeleton>
  )
}

const DealerSubscription: FC = () => {
  const { dealer, loading } = DealerStore.useContainer();
  const { user } = UserStore.useContainer();
  const isOwnDealer = user?.dealer.id === dealer.id;

  let subscription;
  let paymentMethod;
  let upcomingSubscription;
  if (isOwnDealer) {
    subscription = user?.subscription;
    upcomingSubscription = user?.upcomingSubscription;
    paymentMethod = user?.paymentMethod;
  } else {
    subscription = dealer.subscription;
    upcomingSubscription = dealer?.upcomingSubscription;
    paymentMethod = dealer.paymentMethod;
  }



  const endsAt = (!!subscription?.endsAt)
    ? format.date(subscription?.endsAt)
    : null;

  const hasUpcomingSubscriptionChanges = upcomingSubscription && endsAt;

  return (
    <CollapseCard title={_t("subscription")} loading={loading}>
      <Details borderLess>
        {listSubscriptionDetails(subscription, upcomingSubscription)}
        {hasUpcomingSubscriptionChanges && listUpcomingSubscriptionDetails(upcomingSubscription)}

      </Details>

      <CardBox className="mt-1 mb-1">
        <Row align="middle">
          <Col flex="1" className={`${paymentMethod?.type ? 'mr-1' : ''}`}>
            <PaymentMethod paymentMethod={paymentMethod} />
          </Col>
          {isOwnDealer && (
            <>
              {paymentMethod?.type ? (
                <Col flex="none">
                  <RemovePaymentMethod text={_t('delete')} />
                  &nbsp;/&nbsp;
                  <ChangePaymentMethod>{_t('change')}</ChangePaymentMethod>
                </Col>
              ) : (
                <Col flex="none">
                  <ChangePaymentMethod isNew={true}>{_t('add')}</ChangePaymentMethod>
                </Col>
              )}
            </>
          )}
        </Row>
      </CardBox>

      {isOwnDealer && (
        <Row>
          <Col flex={1}>
            {!!subscription?.endsAt && !subscription.isExpired ? (
              <RenewSubscription />
            ) : (
              !!subscription?.package ? (
                <CancelSubscription />
              ) : null
            )}
          </Col>

          <Col>
            <ChangeSubscription />
          </Col>
        </Row>
      )}

      <div className="mt-1">
        <InvoiceList dealer={dealer} />
      </div>
    </CollapseCard>
  );
};

export default DealerSubscription;



const listSubscriptionDetails = (
  subscription: SubscriptionType | null | undefined,
  upcomingSubscription: SubscriptionType | null | undefined
) => {
  const isActive = !!subscription?.isActive;
  const isTrial = !!subscription?.isTrial;
  const price = subscription?.package?.priceNoVat || 0;
  const priceText = price ? `${format.price(price)} / ${_t('month').toLowerCase()}` : '-';

  const trialEndsAt = (!!subscription?.trialEndsAt && isTrial)
    ? format.date(subscription?.trialEndsAt)
    : null;
  const endsAt = (!!subscription?.endsAt && !isTrial && !upcomingSubscription)
    ? format.date(subscription?.endsAt)
    : null;
  const nextRenewal = (!subscription?.endsAt && isActive) || (isActive && upcomingSubscription)
    ? format.date(moment().add(1, 'month').startOf('month').toISOString())
    : null;
  const subTitle = subscription?.package?.title || _t('starter');

  return (<>
    <Details.Item
      label={_t("yourSubscription")}
      value={subTitle || '-'}
    />
    <Details.Item
      label={_t("status")}
      value={subscription && <SubscriptionStatusBadge currentSubscription={subscription} upcomingSubscription={upcomingSubscription} />}
    />
    <Details.Item label={_t("subscription_price")} value={priceText} />
    {trialEndsAt && (
      <Details.Item label={_t("trial", "expires")} value={trialEndsAt} />
    )}
    {endsAt && (
      <Details.Item label={_t("expires")} value={endsAt} />
    )}
    {nextRenewal && (
      <Details.Item label={`${_t("next_renewal")} (${_t("automatic")})`} value={nextRenewal} />
    )}
  </>)
}



const listUpcomingSubscriptionDetails = (newSubscription: SubscriptionType | null | undefined) => {
  const nextRenewal = format.date(moment().add(1, 'month').startOf('month').toISOString())
  const newSubTitle = newSubscription?.package?.title || _t('starter');
  const newSubscriptionPrice = newSubscription?.package?.priceNoVat || 0;
  const newPriceText = newSubscriptionPrice
    ? `${format.price(newSubscriptionPrice)} / ${_t('month').toLowerCase()}`
    : '-';
  const features = newSubscription?.package?.features;
  const creditsPerPeriod = newSubscription?.package?.creditsPerPeriod;
  return (<>
    <Details.Item label={_t("your_next_package")} value={
      <Popover title={_t("subscription_change_date") + nextRenewal}
        placement="topRight"
        overlayStyle={{ maxWidth: 448, width: '100%' }}
        content={<>
          <div className="d-flex justify-content-between mb-05">
            <span className="text-dark">{_t("subscription_package")}</span>
            <span className="text-dark text-medium">{newSubTitle}</span>
          </div>
          <div className="d-flex justify-content-between mb-05">
            <span className="text-dark">{_t("subscription_price")}</span>
            <span className="text-dark text-medium">
              {newPriceText}
              <span className="text-lighter text-secondary "> + {_t("vat").toLowerCase()}</span>
            </span>

          </div>
          <div className="d-flex justify-content-between mb-05">
            <span className="text-dark">{_t("credits")}</span>
            <span className="text-dark text-medium">{creditsPerPeriod}</span>
          </div>
          <Typography.Paragraph className="mb-03 mt-1">
            {_t("description")}:
          </Typography.Paragraph>
          <div className="bg-light border-rounded p-03">
            {features?.map((item, i) => {
              return <Typography.Paragraph key={i} className="mb-03">
                <ReactMarkdown components={{ p: React.Fragment }}>
                  {item}
                </ReactMarkdown>
              </Typography.Paragraph>
            })}
          </div>
        </>}
      >
        <Button type="default" size="small" className="text-dark">
          {newSubTitle}
        </Button>
      </Popover>
    } />
  </>)
}
