import React, { FC, ReactNode } from "react";

interface CustomCollapseHeaderProps extends React.HTMLAttributes<HTMLHeadingElement> {
  extra?: ReactNode;
}

const CustomCollapseHeader: FC<CustomCollapseHeaderProps> = ({ children, extra, className, ...rest }) => {
  return (
    <h4 className={`pt-05 ${className}`} {...rest}>
      {children} <small>{extra}</small>
    </h4>
  );
};

export default CustomCollapseHeader;
