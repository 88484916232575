import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Collapse, Button, Row, Col, Spin, Result, Popconfirm } from "antd";
import CollapseCard from "../../../../../components/collapseCard";
import _t from "../../../../../lang/translate";
import TaxCaseStore from "../../../../../contexts/taxCaseStore";
import DividerLine from "../../../../../components/lineSplit";
import CustomCollapse from "../../../../../components/customCollapse";
import CustomCollapseHeader from "../../../../../components/customCollapseHeader";
import CopyableContent from "../../../../../components/copyableContent";
import format from "../../../../../utilities/formatNumbers";
import TaxEstimationTradePriceOutput from "./calculationOutput/taxEstimationTradePrice";
import TaxEstimationNewPriceOutput from "./calculationOutput/taxEstimationNewPrice";
import TaxEstimationRegistrationFeeOutput from "./calculationOutput/taxEstimationRegistrationFee";
import TaxEstimationConfidenceLevel from './robotDetailsOutput/confidenceLevel'
import calculate from "./calculateTaxEstimation";
import type {
  TaxEstimationDisplayOutput, TaxEstimationRobotData, TaxEstimationOutput
} from "../../../../../types/taxEstimationTypes";
import SimilarVehiclesTable from "./similarVehiclesTable";
import { TaxCase } from "../../../../../types/taxTypes";
import { renderErrorMessage } from "../../../../../components/messages/errorMessage";
import { isValidNum } from "../../../../../utilities/typeGuard";
import { isValidDateString } from "../../../../../utilities/date";
import { findRelevantVehicles, storeTaxRobotEstimation } from '../../../../../services/taxService'
import { displayOutputFactory } from "./calculationOutput/outputHelper";
import EquipmentTable from "./equipmentTable";
import PopoverList from "../../../../../components/popups/popoverList";
import Icon from "../../../../../assets/icons/icon";
import { isValidVin } from "../../../../../utilities/stringExtraFunctions";
import DraggableModal from "../../../../../components/draggableModal";
import { compare } from "../../../../../utilities/deepCompare";

const { Panel } = Collapse;

type ValidatedTaxCaseFields = {
  isValid: boolean;
  rejectReasons: string[];
}

const TaxEstimation: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [vehiclesModalIsVisible, setVehiclesModalIsVisible] = useState(false);
  const [equipmentModalIsVisible, setEquipmentModalIsVisible] = useState(false);
  const { taxCase } = TaxCaseStore.useContainer();
  const oldTaxCase = useRef<TaxCase>(taxCase)
  const [collapseKeys, setCollapseKeys] = useState<string[]>([]);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [taxEstimationRobotData, setTaxEstimationRobotData] = useState<TaxEstimationRobotData>()
  const [taxEstimationResult, setTaxEstimationResult] =
    useState<null | TaxEstimationDisplayOutput>(null);
  const [validatedTaxCaseFields, setValidatedTaxCaseFields] =
    useState<ValidatedTaxCaseFields>(hasRequiredTaxCaseFields(taxCase));

  useEffect(() => {
    if (isFirstRender && taxCase.latestTaxRobotResult) {
      displayData(taxCase.latestTaxRobotResult.output, taxCase.latestTaxRobotResult.robotData);
      setIsFirstRender(false);
    } else {
      setValidatedTaxCaseFields(hasRequiredTaxCaseFields(taxCase));
    }
  }, [taxCase, isFirstRender])


  const getSimilarVehicles = useCallback(async () => {
    if (validatedTaxCaseFields.isValid) {
      const similarVehicles = await findRelevantVehicles(taxCase.id)
      return similarVehicles.data
    } else {
      throw (_t('msg.tax_estimation_required_fields'))
    }
  },[taxCase.id, validatedTaxCaseFields.isValid])

  const saveTaxEstimation = useCallback(async (
    output: TaxEstimationOutput | null,
    taxEstimationRobotData: TaxEstimationRobotData
  ) => {
    await storeTaxRobotEstimation(taxCase.id, {
      output: output,
      robotData: taxEstimationRobotData,
    })
  },[taxCase.id])


  // TODO --> "Copy to estimation" button -- FUTURE
  // TODO --> Take a look at the math behind the confidence level interval.

  const doTaxEstimationFlow = useCallback(async () => {
    const taxEstimationRobotData = await getSimilarVehicles();
    const output = getCalculation(taxCase, taxEstimationRobotData);
    await saveTaxEstimation(output, taxEstimationRobotData)
    displayData(output, taxEstimationRobotData)
  },[getSimilarVehicles, saveTaxEstimation, taxCase])


  const triggerTaxEstimation = useCallback(async () => {
    try {
      setLoading(true);
      await doTaxEstimationFlow();
    } catch (error) {
      console.error(error)
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  },[doTaxEstimationFlow]);

  useEffect(() => {
    const vehicleHasChanged = !compare(taxCase.vehicle || {}, oldTaxCase.current.vehicle || {});
    let shouldUpdateTaxEstimation = !isFirstRender && validatedTaxCaseFields.isValid && vehicleHasChanged;
    if (shouldUpdateTaxEstimation) {
      triggerTaxEstimation()
    }
    oldTaxCase.current = taxCase;
  }, [validatedTaxCaseFields, isFirstRender, triggerTaxEstimation, taxCase])


  const displayData = (
    output: TaxEstimationOutput | null,
    similarVehicles: TaxEstimationRobotData
  ) => {
    let displayOutput = getDisplayOutput(output)
    setTaxEstimationResult(displayOutput);
    setTaxEstimationRobotData(similarVehicles);
  }

  const openVehiclesModal = () => {
    if (!!taxEstimationRobotData?.similarVehicles) {
      setVehiclesModalIsVisible(true)
    } else {
      renderErrorMessage(_t("msg.get_tax_estimation_first"));
    }
  }
  const openEquipmentModal = () => {
    if (!!taxEstimationRobotData?.similarVehicles) {
      setEquipmentModalIsVisible(true)
    } else {
      renderErrorMessage(_t("msg.get_tax_estimation_first"));
    }
  }

  return (<>
    <Spin spinning={loading}>
      <CollapseCard title={
        <CollapseHeader
          style={{
            marginRight: 24,
            marginBottom: 0,
            marginTop: '-0.5rem'
          }}
          title={_t("tax_estimation")}
          value={taxEstimationResult?.registrationFee.taxAfterAllDeductions || null}
        />}
      >
        {(taxEstimationResult && taxEstimationRobotData) && (
          <>
            <CustomCollapse
              activeKey={collapseKeys}
              setActiveKey={setCollapseKeys}
            >
              <Panel key="1" forceRender
                header={
                  <CollapseHeader
                    title={_t("new_price")}
                    value={taxEstimationResult.newPrice.totalSalesPrice}
                  />
                }
              >
                <TaxEstimationNewPriceOutput
                  output={taxEstimationResult.newPrice}
                  eurofactor={taxEstimationRobotData?.resolvedValues?.euroFactor}
                  openEquipmentModal={openEquipmentModal}
                />
              </Panel>
              <Panel key="2" forceRender
                header={
                  <CollapseHeader
                    title={_t("trade_price")}
                    value={taxEstimationResult.tradePrice.tradePrice}
                  />
                }
              >
                <TaxEstimationTradePriceOutput
                  output={taxEstimationResult.tradePrice}
                />
              </Panel>
              <Panel key="3" forceRender
                header={
                  <CollapseHeader
                    title={_t("registration_fee")}
                    value={taxEstimationResult.registrationFee.taxAfterAllDeductions}
                  />
                }
              >
                <TaxEstimationRegistrationFeeOutput
                  output={taxEstimationResult.registrationFee}
                />
              </Panel>
            </CustomCollapse>
            <DividerLine fluid />
          </>
        )}

        <div className="position-relative">
          {(taxEstimationRobotData) && (
            <span className="position-absolute right-0 top-minus-05">
              <PopoverList
                list={taxEstimationRobotData.robotDetails.notes}
                placement="bottomRight"
              >
                <Button className="p0" type="text"
                  icon={<Icon name="filter-outline" />}
                >
                  {_t("filters")}
                </Button>
              </PopoverList>
            </span>
          )}

          {(taxEstimationResult && taxEstimationRobotData) && (<>
            <TaxEstimationConfidenceLevel
              confidenceLevel={taxEstimationRobotData.robotDetails.confidenceLevel}
              confidenceInterval={taxEstimationResult.confidenceInterval}
            />
            <DividerLine fluid />
          </>)}

          {(!taxEstimationResult && taxEstimationRobotData) && (
            <Result
              title={_t('msg.not_found_similar_vehicles')}
              status="warning"
            />
          )}

        </div>

        <Row justify="space-between">
          <Col>
            <Button
              disabled={!!!taxEstimationRobotData?.similarVehicles.length}
              onClick={openVehiclesModal}
            >
              {_t("vehicles")}
            </Button>
          </Col>
          <Col>
            <PopoverList
              title={_t("msg.needs_input")}
              placement="topRight"
              trigger={validatedTaxCaseFields.isValid ? "none" : "hover"}
              list={validatedTaxCaseFields.rejectReasons}
            >
              <Popconfirm title={_t('msg.confirm_action')}
                disabled={!validatedTaxCaseFields.isValid}
                onConfirm={triggerTaxEstimation}
              >
                <Button type="primary" disabled={!validatedTaxCaseFields.isValid}>
                  {_t("get_estimation")}
                </Button>
              </Popconfirm>
            </PopoverList>
          </Col>
        </Row>
      </CollapseCard>
    </Spin>

    <DraggableModal
      visible={vehiclesModalIsVisible}
      onCancel={() => setVehiclesModalIsVisible(false)}
      footer={null}
      width={1200}
      bodyStyle={{ padding: 0 }}
      title={_t("similar_vehicles")}
    >
      <SimilarVehiclesTable
        taxEstimationResult={taxEstimationResult}
        taxEstimationRobotData={taxEstimationRobotData}
        taxCase={taxCase}
      />
    </DraggableModal>

    <DraggableModal
      visible={equipmentModalIsVisible}
      onCancel={() => setEquipmentModalIsVisible(false)}
      footer={null}
      width={1200}
      bodyStyle={{ padding: 0 }}
      title={_t("equipment_list")}
    >
      <EquipmentTable
        taxEstimationRobotData={taxEstimationRobotData}
      />
    </DraggableModal>
  </>
  );
};


export default TaxEstimation;

type CollapseHeaderProps = {
  title: string;
  value: number | null;
  style?: React.CSSProperties;
};

const CollapseHeader = ({ title, value, style }: CollapseHeaderProps) => {
  return (
    <CustomCollapseHeader style={style}>
      <Row justify="space-between">
        <Col>{title}</Col>
        <Col>
          {value && (
            <div onClick={e => e.stopPropagation()}>
              <CopyableContent contentToCopy={value.toFixed(0)}>
                {format.price(value)}
              </CopyableContent>
            </div>
          )}
        </Col>
      </Row>
    </CustomCollapseHeader>
  );
};

const hasRequiredTaxCaseFields = (taxCase: TaxCase) => {
  const rejectReasons: Array<string> = [];
  if (!isPrivateVehicle(taxCase)) {
    rejectReasons.push(_t("msg.private_vehicle_required"))
  }
  if (taxCase.vehicle?.isNew) {
    rejectReasons.push(_t("msg.used_vehicle_required"))
  }
  if (!isValidVin(taxCase.vehicle?.vin)) {
    // TODO --> Expand on reason
    // Example: Length was 16 instead of 17
    // Example: Vin contained invalid character ('.', '-', ' ')
    rejectReasons.push(_t("msg.vin_invalid"))
  }
  if (!isValidNum(taxCase.vehicle?.mileage)) {
    rejectReasons.push(_t("msg.mileage_required"))
  }
  if (!isValidDateString(taxCase.vehicle?.firstRegDate)) {
    rejectReasons.push(_t("msg.first_reg_date_invalid"))
  }
  if (!hasElectricOrGasolineInputs(taxCase)) {
    rejectReasons.push(_t("msg.electric_or_gasoline_inputs_required"))
  }
  return {
    isValid: rejectReasons.length === 0,
    rejectReasons
  }

}

const isPrivateVehicle = (taxCase: TaxCase) => {
  const privateVehicleTypeId = 1
  return taxCase.vehicle?.typeId === privateVehicleTypeId;
}

const hasElectricOrGasolineInputs = (taxCase: TaxCase) => {
  const hasGasolineInputs = isValidNum(taxCase.vehicle?.emissionAmount) &&
    isValidNum(taxCase.vehicle?.emissionUnitId)

  const hasElectricInputs = isValidNum(taxCase.vehicle?.electricConsumption) &&
    isValidNum(taxCase.vehicle?.electricRange)

  return hasElectricInputs || hasGasolineInputs;
}

const hasRequiredCalculationInput = (taxEstimationRobotData: TaxEstimationRobotData) => {
  const data = taxEstimationRobotData;
  return isValidNum(data.resolvedValues?.equipmentPrice) &&
    isValidNum(data.resolvedValues?.newPrice) &&
    isValidNum(data.resolvedValues?.euroFactor) &&
    isValidNum(data.robotDetails.confidenceLevel) &&
    isValidNum(data.similarVehicles?.[0]?.vehicle.equipmentPrice) &&
    isValidNum(data.similarVehicles?.[0]?.vehicle.mileage) &&
    isValidNum(data.similarVehicles?.[0]?.vehicle.salesPrice) &&
    isValidDateString(data.similarVehicles?.[0]?.vehicle.firstRegDate)
}

const getCalculation = (taxCase: TaxCase, taxEstimationRobotData: TaxEstimationRobotData) => {
  if (hasRequiredCalculationInput(taxEstimationRobotData)) {
    return calculate(taxCase, taxEstimationRobotData);
  }
  return null;
}

const getDisplayOutput = (output: TaxEstimationOutput | null) => {
  if (output === null) {
    return null;
  }
  return displayOutputFactory(output);
}

