import React, { ReactNode, useEffect, useState } from "react";
import { Card, Table } from "antd";
import { ColumnType, TablePaginationConfig, TableProps } from "antd/es/table";
import assertFC from "../../utilities/assertFC";
import { CustomColumnsType, OnColumnsChange, OnSortChange, RenderFunc } from "./dataTableTypes";
import { PaginationMeta } from "../../types/apiTypes";
import { Key, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { useHistory, useLocation } from "react-router-dom";
import { getSortValue } from "../../utilities/tableFuncs";
import { ColumnStorageKeyType, getColumns, saveColumns } from "../../components/table/cachColumns";
import { pageKey, pageSizeKey, searchKey, sortKey } from "../../services/urlQueryService";
import consts from "../../utilities/consts";
import _t from "../../lang/translate";
import LayoutStore from "../../contexts/layoutStore";
import TableActions from "./tableActions";
import TableSearchField from "./tableSearchField";

type TableChangeFunc<T> = (
  pagination: TablePaginationConfig,
  filters: Record<string, (Key | boolean)[] | null>,
  sorter: SorterResult<T> | SorterResult<T>[],
  extra: TableCurrentDataSource<T>
) => void;

interface DataTableProps<T> extends TableProps<T> {
  columns: CustomColumnsType<T>;
  loading?: boolean;
  filter?: ReactNode;
  onColumnsChange?: OnColumnsChange<T>;
  onRefresh?: () => void;
  onExport?: () => void;
  //TODO: remove next line after updating dataTable through the app.
  columnStorageKey?: ColumnStorageKeyType;
  renderActions?: RenderFunc<T>;
  meta?: PaginationMeta | null;
  dataSource: T[];
  disablePagination?: boolean;
  disableSearch?: boolean
}

export default function DataTable<T extends object>({
  columns: uncashedColumns,
  renderActions,
  filter,
  meta,
  columnStorageKey,
  onRefresh,
  onExport,
  loading,
  dataSource,
  scroll,
  size,
  disablePagination,
  disableSearch,
  ...rest
}: DataTableProps<T>) {

  let history = useHistory();
  let { search, pathname } = useLocation();
  const { screen } = LayoutStore.useContainer();
  const [editableCol, setEditableCol] = useState<CustomColumnsType<T>>([]);
  const [fixedColLeft, setFixedColLeft] = useState<CustomColumnsType<T>>([]);
  const [fixedColRight, setFixedColRight] = useState<CustomColumnsType<T>>([]);
  const isMobile = screen.isMobile;
  const sortedColumns = editableCol.filter((col) => !col.hidden);

  useEffect(() => {
    const columns = columnStorageKey ? getColumns(uncashedColumns, columnStorageKey) : uncashedColumns;

    if (isMobile) {
      setEditableCol(columns.map((col) => (col.fixed ? { ...col, fixed: false } : col)));
      setFixedColLeft([]);
      setFixedColRight([]);
    } else {
      setEditableCol(columns.filter((col) => !col.fixed));
      setFixedColLeft(columns.filter((col) => col.fixed === "left"));
      setFixedColRight(columns.filter((col) => col.fixed === "right"));
    }
  }, [columnStorageKey, uncashedColumns, setEditableCol, setFixedColLeft, setFixedColRight, isMobile]);

  const handleColumnsChange: OnColumnsChange<T> = (updatedColumns) => {
    columnStorageKey && saveColumns([...fixedColLeft, ...updatedColumns, ...fixedColRight], columnStorageKey);
  };

  const handleSortChange: OnSortChange<T> = (updatedColumns) => {
    setEditableCol(updatedColumns);
    handleColumnsChange(updatedColumns);
  };

  const handleTableChange: TableChangeFunc<T> = (pagination, _, sorter) => {
    const query = new URLSearchParams(search);

    const sortValue = getSortValue(sorter);
    const { current, pageSize } = pagination;

    if (sortValue.length > 0) query.set(sortKey, sortValue);
    else query.delete(sortKey);

    if (current && current > 1) query.set(pageKey, current.toString());
    else query.delete(pageKey);

    query.set(pageSizeKey, pageSize ? pageSize.toString() : getPageSize().toString());
    //else query.delete(pageSizeKey);

    history.push({
      ...history.location,
      pathname: pathname,
      search: query.toString(),
    });
  };

  const actionColumn: ColumnType<T> | null = renderActions
    ? {
      key: "actions",
      fixed: isMobile ? false : "right",
      width: 80,
      render: renderActions,
    }
    : null;

  const handlePageSize = (size: number) => {
    localStorage.setItem(pageSizeKey, size.toString());
  };

  const getPageSize = (): number => {
    if (meta?.per_page) return meta?.per_page;

    const urlPageSize = new URLSearchParams(search).get(pageSizeKey);
    if (urlPageSize !== null) return Number(urlPageSize);

    const savedPageSize = localStorage.getItem(pageSizeKey);
    if (savedPageSize !== null) return Number(savedPageSize);
    return 10;
  };

  const handleSearch = (value: string | undefined) => {
    const query = new URLSearchParams(search);
    if (!value || value === " ") query.delete(searchKey);
    else query.set(searchKey, value);

    history.push({
      ...history.location,
      pathname: pathname,
      search: query.toString(),
    });
  };

  const handleSearchClear = () => {
    const query = new URLSearchParams(search);
    query.delete(searchKey);

    history.push({
      ...history.location,
      pathname: pathname,
      search: query.toString(),
    });
  };

  return (
    <Card
      bordered={false}
      bodyStyle={{ padding: 0 }}
      className="table-card"
      title={disableSearch ? null : <TableSearchField onSearch={handleSearch} onClear={handleSearchClear} />}
      extra={
        <TableActions<T>
          columns={editableCol}
          filter={filter}
          onColumnsChange={handleSortChange}
          onExport={onExport}
          onRefresh={onRefresh}
        />
      }
    >
      <Table<T>
        {...rest}
        size={size || "small"}
        columns={
          actionColumn
            ? [...fixedColLeft, ...sortedColumns, ...fixedColRight, actionColumn]
            : [...fixedColLeft, ...sortedColumns, ...fixedColRight]
        }
        rowKey="id"
        loading={loading}
        scroll={scroll || { x: sortedColumns.length * 160 + 160 }}
        dataSource={dataSource}
        onChange={handleTableChange}
        pagination={disablePagination ? false : {
          current: meta?.current_page,
          total: meta?.total,
          showTotal: (total) => (
            <>
              {_t("total")} <strong>{total}</strong>
            </>
          ),
          pageSize: getPageSize(),
          pageSizeOptions: consts.tablePageSize,
          showSizeChanger: true,
          defaultCurrent: meta?.current_page,
          onShowSizeChange: (_, size) => handlePageSize(size),
        }}
      />
    </Card>
  );
}

assertFC(DataTable);
