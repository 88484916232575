import { FC } from "react";
import _t from "../../../../../lang/translate";

interface TaxCalcEquipmentTotalNameProps {
  equipments: any[];
  used?: boolean;
}

const TaxCalcEquipmentTotalName: FC<TaxCalcEquipmentTotalNameProps> = ({ equipments, used }) => {
  if (used) {
    const count = equipments?.filter((eq) => eq.price > 10000)?.length;
    return (
      <span>
        {_t("used")} - {count} {_t("piece_short")}
      </span>
    );
  }
  const count = equipments?.length;
  return (
    <span>
      {_t("new_price")} - {count} {_t("piece_short")}
    </span>
  );
};

export default TaxCalcEquipmentTotalName;
