import { Col, Row } from "antd";
import { FC } from "react";
import DividerLine from "../../../components/lineSplit";
import DealerAdminStore from "../../../contexts/dealerAdminStore";
import DealerAdminLevel from "./dealerAdminLevel";
import DealerConfigValues from "./dealerConfigValues";

const DealerAdminProducts: FC = () => {
  const { admin } = DealerAdminStore.useContainer();

  return (
    <>
      {admin.products.map((prod, index) => {
        return (
          <div key={prod.context}>
            <Row justify="space-between" align="middle" className="mb-1">
              <Col>
                {prod.title}
              </Col>
              <Col>
                <DealerConfigValues product={prod} />
              </Col>
            </Row>
            {prod.active && <DealerAdminLevel product={prod} />}
            {index + 1 !== admin.products.length && <DividerLine fluid />}
          </div>
        );
      })}
    </>
  );
};

export default DealerAdminProducts;
