import { FC } from "react";
import { Button } from "antd";
import CustomerStore from "../../../contexts/customerStore";
import _t from "../../../lang/translate";
import CustomerDetails from "./customerDetails";

interface CustomerInfoViewProps {
  startEditing: () => void;
}

const CustomerInfoView: FC<CustomerInfoViewProps> = ({ startEditing }) => {
  const { customer } = CustomerStore.useContainer();
  return (
    <>
      <CustomerDetails customer={customer} />
      <div className="text-right">
        <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
          {_t("edit")}
        </Button>
      </div>
    </>
  );
};

export default CustomerInfoView;
