import { Result, TablePaginationConfig } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import React, { FC, useCallback, useEffect, useState } from "react";
import _t from "../../../lang/translate";
import { bonusUsageUrl } from "../../../services/dealerService";
import { useApi } from "../../../services/useApi";
import { PaginationedData } from "../../../types/apiTypes";
import { BonusLogType } from "../../../types/bonusTypes";
import format from "../../../utilities/formatNumbers";
import { pageKey, pageSizeKey } from "../../../services/urlQueryService";
import debounce from "lodash/debounce";

interface DealerBonusLogProps {
  dealerId: number;
}

const DealerBonusLog: FC<DealerBonusLogProps> = ({ dealerId }) => {
  const [{ data, isLoading, isError }, setUrl] = useApi<PaginationedData<BonusLogType>>('', { data: [] });
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const [pageSize, setPageSize] = useState<number | undefined>(20);
  const [total, setTotal] = useState<number | undefined>(10);

  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  const handleChange = (pagination: TablePaginationConfig) => {
    let newCurrentPage = pagination.current;
    if (pagination.pageSize !== pageSize) {
      newCurrentPage = 1;
    }

    setCurrentPage(newCurrentPage);
    setPageSize(pagination.pageSize);
  }

  useEffect(() => {
    const query = new URLSearchParams();

    query.set(pageKey, `${currentPage}`);
    query.set(pageSizeKey, `${pageSize}`);

    const url = bonusUsageUrl(dealerId, query.toString())
    delayedSetUrl(url);
  }, [currentPage, dealerId, pageSize, delayedSetUrl])

  useEffect(() => {
    const { meta } = data;
    if (meta) {
      setTotal(meta.total);
    }
  }, [data]);

  if (isError) return <Result status="error" title={_t("msg.unknown_error")} />;

  const columns: ColumnProps<BonusLogType>[] = [
    {
      key: "amount",
      title: _t("amount"),
      dataIndex: "amount",
      render: (text) => <strong>{text}</strong>,
    },
    {
      key: "createdAt",
      title: _t("date"),
      dataIndex: "createdAt",
      render: (text) => format.dateAndTime(text),
    },
    {
      key: "caseId",
      title: _t("case"),
      dataIndex: "caseId",
      render: (text) => (text ? <span>#{text}</span> : null),
    },
    {
      key: "note",
      title: _t("description"),
      dataIndex: "note",
    },
  ];

  return (
    <>
      <Table
        rowKey="id"
        loading={isLoading}
        columns={columns}
        dataSource={data.data || []}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageSize,
          current: currentPage,
          total: total
        }}
        onChange={handleChange}
      />
    </>
  );
};

export default DealerBonusLog;
