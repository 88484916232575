import { Col, Form, Row } from "antd";
import React, { FC } from "react";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";

interface TaxablePriceInputProps {
}

const TaxablePriceInput: FC<TaxablePriceInputProps> = ({ }) => {
  return (
    <Row className="flex-nowrap">
      <Col flex="auto">
        <Form.Item name="taxablePrice" label={_t("taxable_value")}>
          <InputNum min={0} suffix="kr." className="bg-white no-border" />
        </Form.Item>
      </Col>
      <Col flex="49px" />
    </Row>
  );
};

export default TaxablePriceInput;
