import React, { FC } from "react";
import { Timeline, } from "antd";
import { Activity, ActivityEntryType } from "../../types/activityTypes";
import ActivityItemInfo from "./activityItemInfo";
import ActivityItemBody from "./activityItemBody";
import ActivityItemEdit from "./activityItemEdit";

interface ActivityTimeLineItemProps {
  item: Activity;
  isAdmin?: boolean;
  onUpdate: (values: ActivityEntryType) => void;
  onDelete: (id: string) => void;
  editingId: string | null;
  setEditingId: (id: string | null) => void;
  loading: boolean;
  editable: boolean;
}

const ActivityTimeLineItem: FC<ActivityTimeLineItemProps> = ({
  item,
  isAdmin,
  onUpdate,
  onDelete,
  editable,
  editingId,
  setEditingId,
  loading,
}) => {
  const editing = editingId === item.id;

  const onCancel = () => setEditingId(null);
  const onEdit = () => setEditingId(item.id);
  const handleDelete = () => onDelete(item.id);

  return (
    <Timeline.Item color={item.type?.color || ""}>
      {isAdmin && editable ? (
        <ActivityItemEdit
          {...{
            onUpdate,
            loading,
            item,
            onCancel,
            onEdit,
            isAdmin,
            editing,
            onDelete: handleDelete,
          }}
        />
      ) : (
        <>
          <ActivityItemInfo {...{ item, isAdmin }} />
          <ActivityItemBody item={item} />
        </>
      )}
    </Timeline.Item>
  );
};

export default ActivityTimeLineItem;
