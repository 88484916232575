import { Button } from "antd";
import { FC } from "react";
import Icon from "../../assets/icons/icon";
import { ReviewType } from "../../types/reviewType";
import LogText from "../logText";
import RateInput from "../rateInput";

interface ReviewItemViewProps {
  review: ReviewType;
  startEditing: () => void;
}

const ReviewItemView: FC<ReviewItemViewProps> = ({ startEditing, review }) => {
  const { score, log, note } = review;

  return (
    <div className="drawer-item position-relative">
      <div className="mb-05">
        <RateInput value={score} disabled allowHalf />
      </div>
      {note && <p className="mb-0">{note}</p>}
      <LogText logData={log} />
      <div className="drawer-item-actions position-absolute top-0 right-0 p-05 border-radius bg-muted">
        <Button
          className="muted"
          type="link"
          size="small"
          shape="circle"
          icon={<Icon name="pencil-outline" />}
          onClick={startEditing}
        />
      </div>
    </div>
  );
};

export default ReviewItemView;
