import { FC, useState } from "react";
import CollapseCard from "../../../components/collapseCard";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import LeasingVehicleForm from "./leasingVehicleForm";
import LeasingVehicleInfo from "./leasingVehicleInfo";

const LeasingVehicleCard: FC = () => {
  const { vehicleLoading } = LeasingStore.useContainer();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <CollapseCard title={_t("vehicle")} loading={vehicleLoading}>
      {isEditing ? (
        <LeasingVehicleForm stopEditing={stopEditing} />
      ) : (
        <LeasingVehicleInfo startEditing={startEditing} />
      )}
    </CollapseCard>
  );
};

export default LeasingVehicleCard;
