import React, { FC, ReactNode } from "react";
import { Affix, Col, Row } from "antd";
import consts from "../utilities/consts";

export interface PageCardProps {
  title?: ReactNode;
  extra?: ReactNode;
  className?: string;
  actions?: ReactNode;
  loading?: boolean;
  bodyClassName?: string;
  affix?: ReactNode;
  children?: ReactNode;
}

const PageCard: FC<PageCardProps> = ({
  title,
  extra,
  actions,
  affix = false,
  bodyClassName,
  children,
  loading,
  className,
}) => {
  return (
    <div className={`content-bg card ${className}${loading ? " loading" : ""}`}>
      {title && (
        <Row className="card-heading">
          <Col flex="auto">{title}</Col>
          {extra && <Col className="card-extra">{extra}</Col>}
        </Row>
      )}
      <Row className={`card-body ${bodyClassName ? bodyClassName : ""}`}>
        <Col flex={affix ? "auto" : "100%"}>{children}</Col>
        {affix && (
          <Col flex={consts.formSiderWidth} className="card-sider">
            <div className="card-affix">{affix}</div>
            <div className="card-actions">
              <Affix offsetBottom={15}>{actions}</Affix>
            </div>
          </Col>
        )}
      </Row>
      {!affix && actions && (
        <div className="card-footer text-right">{actions}</div>
      )}
    </div>
  );
};

export default PageCard;
