import calcTradePrice from "./tradePriceCalculator";

export interface TradePriceCalcInputType {
  firstRegDate?: string | null;
  documentationDate?: string | null;
  mileage?: number | null;
  qualityId?: number | null;
  specialUseId?: number | null;
  regulation?: number | null;
  equipmentRegulation?: number | null;
  averagePrice?: number | null;
  vehicleAdCount?: number | null;
  averageMileage?: number | null;
}

export interface TradePriceCalcOutputType {
  regulation: number | null;
  averagePrice: number | null;
  carAgeInYears: number | null;
  yearFactor: number | null;
  averageDiscountAmount: number | null;
  tradingPriceTotal: number | null;
  qualitySurcharge: number | null;
  qualityAmount: number | null;
  specialUseSurcharge: number | null;
  specialUseValue: number | null;
  specialUseAmount: number | null;
  kmDifference: number | null;
  kmDeductionTotal: number | null;
  regulationAmount: number | null;
  otherRegulationAmount: number | null;
  equipmentRegulation: number | null;
  equipmentRegulationAmount: number | null;
  finalTradePriceTotal: number | null;
  consts: TradePriceCalcConstsType; /// change that later..
}

export interface TradePriceCalcConstsType {
  averageDiscountSurcharge: number;
  yearFactors: number[];
  specialUseDefaultSurcharge: number;
  specialUseQualityMaxPrice: number;
  deliveryFee: number;
  carQualities: { value: number; surcharge: number }[];
}

export type TradePriceCalcFuncType = (input: TradePriceCalcInputType) => TradePriceCalcOutputType;

const calculateTradePrice: TradePriceCalcFuncType = (input) => calcTradePrice(input) as TradePriceCalcOutputType;

export default calculateTradePrice;
