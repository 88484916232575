import { FC } from "react";
import { useParams } from "react-router-dom";
import { ParamTypes, ScreenProps } from "../../../types/appTypes";
import ImportNewCase from "./importNewCase";
import { isNew } from "../../../routes/appRoutes";
import UserStore from "../../../contexts/userStore";
import ImportCaseAdmin from "./admin/importCaseAdmin";
import ImportCaseUser from "./user/importCaseUser";

const ImportCase: FC<ScreenProps> = (props) => {
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("import.isAdmin");
  const { id } = useParams<ParamTypes>();

  if (isNew(id)) return <ImportNewCase {...props} />;

  if (isAdmin) return <ImportCaseAdmin {...props} />;

  return <ImportCaseUser {...props} />;
};

export default ImportCase;
