import React, { FC } from "react";

interface AddDividerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}

const AddDivider: FC<AddDividerProps> = ({ className, ...rest }) => {
  return <button className={`add-divider-btn ${className ? className : ""}`} {...rest} />;
};

export default AddDivider;
