import { Button, Checkbox, Form, Space } from "antd";
import { useRef, useState } from "react";
import _t from "../../lang/translate";
import { createDigitalSigningCase } from "../../services/leasingService";
import { ApiFile } from "../../types/appTypes";
import { LeasingStartDigitalSigningType } from "../../types/leasingTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { renderErrorMessage } from "../messages/errorMessage";

export default function StartDigitalSigning<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [loading, setLoading] = useState<boolean>();
  const [startSigning, setStartSigning] = useState<boolean>(false);
  const id = payload.id;
  const files = payload.contractFiles as ApiFile[];
  const submitBtnRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (values: LeasingStartDigitalSigningType) => {
    const newValues = { ...values };
    newValues.start = startSigning;

    setLoading(true);
    try {
      const { data } = await createDigitalSigningCase(id, newValues);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitWithStart = (start: boolean) => {
    setStartSigning(start);
    submitBtnRef?.current?.click();
  }

  return (
    <Form<LeasingStartDigitalSigningType> requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <h2 className="modal-title">{_t("start_document_signing")}</h2>
      <Form.Item name="files" label={_t("documents")} initialValue={files.map((f) => f.id)}>
        <Checkbox.Group options={files.map((item) => item && { label: item?.name, value: item?.id })} />
      </Form.Item>

      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>

          <Button htmlType="submit" type="ghost" hidden={true} loading={loading} ref={submitBtnRef}>
            {_t('send')}
          </Button>

          <Button htmlType="button" type="ghost" loading={loading} onClick={() => handleSubmitWithStart(false)}>
            {_t("create_draft")}
          </Button>

          <Button htmlType="button" type="primary" loading={loading} onClick={() => handleSubmitWithStart(true)}>
            {_t("create", 'send', ' & ')}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(StartDigitalSigning);
