import { FC } from "react";
import { Button } from "antd";
import Icon from "../../../../assets/icons/icon";
import openModal from "../../../../components/modal/openModal";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";

const ImportPickupOrderBtn: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();

  const disabled = importCase.flags.pickupSelf;

  const handleAction = async () => {
    try {
      const { id, pickupDate } = importCase;
      const data = await openModal<any>("ImportPickupOrder", { id, pickupDate });
      if (data) setImportCase({ ...data });
    } catch (error) {
    }
  };

  const actionIcon = <Icon size="large" name="car-outline" />;

  return (
    <Button type="primary" icon={actionIcon} disabled={disabled} ghost onClick={handleAction}>
      {_t("order_pickup")}
    </Button>
  );
};

export default ImportPickupOrderBtn;
