import React, { FC, useState } from "react";
import { Progress, Space } from "antd";
import _t from "../../../../../../lang/translate";
import type {
    TaxEstimationRobotData, TaxEstimationOutput
} from "../../../../../../types/taxEstimationTypes";
import format from "../../../../../../utilities/formatNumbers";

type Props = {
    confidenceLevel: TaxEstimationRobotData['robotDetails']['confidenceLevel'];
    confidenceInterval: TaxEstimationOutput['confidenceInterval']
}

const TaxEstimationConfidenceLevel = ({ confidenceLevel, confidenceInterval }: Props) => {
    return (<>
        <h4 className="mb-1">{_t('confidence_level')}</h4>
        <div className="text-center">
            <Progress
                percent={confidenceLevel}
                status="active"
                strokeColor={getConfidenceColor(confidenceLevel)}
                width={128}
                className="text-larger text-dark"
            />
        </div>
        <div className="mt-05 d-flex justify-content-between">
            <Space>
                <span className="text-large">{_t('low')}:</span>
                <span className="text-large text-dark">{format.price(confidenceInterval.low)}</span>
            </Space>
            <Space>
                <span className="text-large">{_t('high')}:</span>
                <span className="text-large text-dark">{format.price(confidenceInterval.high)}</span>
            </Space>
        </div>
    </>)

};

export default TaxEstimationConfidenceLevel;

const getConfidenceColor = (confidenceLevel: number) => {
    if (confidenceLevel < 50) {
        return "#f5222d" // deep red
    } else if (confidenceLevel < 65) {
        return "#fa8c16" // orange
    } else if (confidenceLevel < 80) {
        return "#fadb14" // yellow
    } else {
        return "#52c41a" // green
    }
}
