import { FC } from "react";
import { Button } from "antd";
import Icon from "../../../../assets/icons/icon";
import openModal from "../../../../components/modal/openModal";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";

const ImportPickupAgreementBtn: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();

  const disabled = importCase.flags.pickupSelf;

  const handleDownload = async () => {
    try {
      const { id, pickupDate } = importCase;
      const data = await openModal<any>("ImportPickupAgreement", { id, pickupDate });
      if (data.pickupDate) setImportCase({ ...importCase, pickupDate });
      if (data.url) window.open(data.url, "_blank");
    } catch (error) {
    }
  };

  const downloadIcon = <Icon size="large" name="download-outline" />;

  return (
    <Button type="primary" icon={downloadIcon} disabled={disabled} ghost onClick={handleDownload}>
      {_t("pickup_agreement")}
    </Button>
  );
};

export default ImportPickupAgreementBtn;
