import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Popover, Result, DatePicker } from "antd";
import type { DatePickerProps } from 'antd';
import moment from "moment";
import _t from "../lang/translate";
import PageTitle from "../layout/pageTitle";
import { LocationStateType, ScreenProps } from "../types/appTypes";
import { Link, useLocation } from "react-router-dom";
import { useApi } from "../services/useApi";
import { PaginationedData } from "../types/apiTypes";
import { CustomColumnsType, RenderFunc } from "../components/table/dataTableTypes";
import Icon from "../assets/icons/icon";
import DataTable from "../components/table/dataTable";
import { billingHistoryUrl } from "../services/clipService";
import { BillingHistoryListType } from "../types/clipTypes";
import format from "../utilities/formatNumbers";
import { openFileLink } from "../services/mediaService";
import debounce from "lodash/debounce";
import { pageSizeKey, searchKey } from "../services/urlQueryService";
import StatusTag from "../components/statusTag";
import UserStore from "../contexts/userStore";
import { getPath } from "../routes/appRoutes";

const BillingHistory: FC<ScreenProps> = ({ title }) => {
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("tax.isAdmin");

  const now = moment();
  const yearMonthFormat = 'YYYY-MM';

  const location = useLocation<LocationStateType>();
  const [yearMonth, setYearMonth] = useState<string>(now.format(yearMonthFormat));
  const [refreshId, setRefreshId] = useState<string | undefined>();
  const [{ data, isLoading, isError }, setUrl] = useApi<PaginationedData<BillingHistoryListType>>("", { data: [] });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (typeof refreshId === "string") {
      query.set("refreshId", refreshId);
    }
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "20");
    query.set('yearMonth', yearMonth);
    const url = billingHistoryUrl(query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [setUrl, location.search, refreshId, yearMonth]);

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  let columns: CustomColumnsType<BillingHistoryListType> = [
    {
      title: "#",
      align: "center",
      key: "id",
      dataIndex: "id",
      width: 110,
      fixed: "left",
    },
    {
      title: _t("status"),
      key: "status",
      dataIndex: "status",
      width: 120,
      render: (status) => status && <StatusTag size="small" status={status} />,
    },
    {
      title: _t("date"),
      key: "createdAt",
      dataIndex: "createdAt",
      render: (date) => format.date(date) + "-" + format.time(date),
    },
    {
      title: _t("title"),
      key: "title",
      dataIndex: "title",
    },
    {
      title: _t("price"),
      key: "price",
      dataIndex: "price",
      render: (price) => format.price(price),
    },

    {
      title: _t("type"),
      key: "paymentGateway",
      dataIndex: "paymentGateway",
      render: (type) => type?.title,
    },
    {
      title: _t("dealer"),
      key: "dealerName",
      ellipsis: true,
      dataIndex: "dealerName",
    },
    {
      title: _t("case"),
      align: "left",
      key: "id",
      dataIndex: "id",
      render: (text, record) => {
        return record.taxCaseId ? (
          <>
            <Link to={getPath("tax", record.taxCaseId)} target="_blank" rel="noopener noreferrer" >
              <strong>{record.taxCaseId}</strong>
            </Link>
          </>
        ) : '-'

      },
    },
  ];

  if (isAdmin) {
    const columnToAdd = {
      title: _t("external-id"),
      key: "purchaseId",
      dataIndex: "purchaseId",
      ellipsis: true,
    };

    columns = [...columns, columnToAdd];
  }

  const downloadFile = (data: BillingHistoryListType) => {
    if (data.paymentGateway.id === 3) {
      window.open(data.url || '#', "_blank");
    } else {
      openFileLink(data.url);
    }
  }

  const tableActions: RenderFunc<BillingHistoryListType> = (_, record) => {
    return record.url ? (
      <Button
        className="muted"
        type="text"
        shape="circle"
        icon={<Icon name="download-outline" />}
        onClick={() => downloadFile(record)}
      />
    ) : (
      <Popover placement="topLeft" content={<p>{_t("contact_dmf")}</p>}>
        <Button className="muted" type="text" shape="circle" icon={<Icon name="information-circle-outline" />} />
      </Popover>
    );
  };

  const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    setYearMonth(dateString);
  };

  const datePicker = (
    <DatePicker
      onChange={onDateChange}
      disabledDate={(current) => {
        return now.add(0, 'month') < current;
      }}
      defaultValue={now}
      format={yearMonthFormat}
      picker="month"
      allowClear={false}
    />
  );

  const handleRefresh = () => setRefreshId(new Date().getSeconds().toString());

  return (
    <>
      <PageTitle
        fluid
        title={title}
      />
      {isError ? (
        <Result status="error" title={_t("msg.unknown_error")} />
      ) : (
        <DataTable<BillingHistoryListType>
          onRefresh={handleRefresh}
          loading={isLoading}
          renderActions={tableActions}
          columnStorageKey={"BILLING_HISTORY"}
          columns={columns}
          dataSource={data.data}
          meta={data.meta}
          disableSearch={true}
          filter={datePicker}
        />
      )}
    </>
  );
};

export default BillingHistory;
