import { RadioChangeEvent } from "antd";
import React, { FC, useEffect } from "react";
import RadioBox, { RadioBoxOption } from "../../../components/radioBox";
import _t from "../../../lang/translate";

interface QuickCalculationInputProps {
  activeTab: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
}

const QuickCalculationInput: FC<QuickCalculationInputProps> = ({
  activeTab,
  value,
  onChange,
}) => {
  useEffect(() => {
    if (activeTab === "link") {
      onChange && onChange(true);
    }
  }, [activeTab, onChange]);

  const planOptions: RadioBoxOption[] = [
    {
      key: 1,
      value: 1,
      title: _t("estimated", "tax-doc"),
      extra: "5 min",
      children: (
        <small>
          Overblik over din værdifastsættelse. <br />
          Documentation kan ikke tilkøbes.
        </small>
      ),
    },
    {
      key: 2,
      value: 2,
      title: _t("full", "documentation"),
      disabled: activeTab === "link",
      extra: "30 min",
      children: (
        <small>
          Overblik over din værdifastsættelse. <br />
          Documentation kan tilkøbes.
          <br />
          Evt. garanti mod reguleringer (tilkøbes).
        </small>
      ),
    },
  ];

  let nextValue: any = value ? 1 : 2;
  if (typeof value !== "boolean") nextValue = value;

  const handleChange = ({ target }: RadioChangeEvent) => {
    onChange && onChange(target.value === 1);
  };

  return (
    <RadioBox value={nextValue} onChange={handleChange} options={planOptions} />
  );
};

export default QuickCalculationInput;
