import React, { FC, useState } from "react";
import { Button, message } from "antd";
import Icon from "../../../../assets/icons/icon";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { getFileLink } from "../../../../services/mediaService";

const ImportDownloadReportBtn: FC = () => {
  const [loading, setLoading] = useState<boolean>();
  const { importCase } = ImportCaseStore.useContainer();
  const { flags, links } = importCase;
  const link = links?.vehicleReport;
  const downloadIcon = <Icon size="large" name="download-outline" />;

  const downloadOffer = async () => {
    if (!link) return;
    try {
      setLoading(true);
      const { data } = await getFileLink(link);
      if (data.url) window.open(data.url, "_blank");
    } catch (ex) {
      message.error(_t("msg.link_not_generated"));
    } finally {
      setLoading(false);
    }
  };

  const disabled = !link || !flags.offerPublished;

  return (
    <Button type="primary" ghost disabled={disabled} icon={downloadIcon} onClick={downloadOffer} loading={loading}>
      {_t("report")}
    </Button>
  );
};

export default ImportDownloadReportBtn;
