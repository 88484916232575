import { FC, ReactNode } from "react";
import { Button, Upload, UploadProps } from "antd";
import Icon from "../assets/icons/icon";
import _t from "../lang/translate";
import { mediaApiEndpoint, saveFile } from "../services/mediaService";
import { accepted } from "../utilities/acceptedFiles";

interface UploadButtonProps extends UploadProps {
  uploadIcon?: ReactNode;
  loading?: boolean;
  buttonText?: string;
}

const UploadButton: FC<UploadButtonProps> = ({
  uploadIcon = <Icon name="cloud-upload-outline" />,
  loading = false,
  buttonText = _t("upload"),
  action = mediaApiEndpoint,
  accept = accepted.join(","),
  ...rest
}) => {
  const handleFileUpload = async (options: any) => {
    const { file, action, onProgress, onSuccess, onError } = options;
    const data = new FormData();
    data.append("file", file);
    try {
      const res = await saveFile(action, data, {
        onUploadProgress: (e) => {
          onProgress({ percent: (e.loaded / e.total) * 100 }, file);
        },
        headers: { "Content-Type": file.type },
      });
      onSuccess(res.data, file);
    } catch (ex) {
      onError(ex);
    }
  };

  return (
    <Upload {...rest} accept={accept} action={action} customRequest={handleFileUpload} showUploadList={false}>
      <Button loading={loading} icon={uploadIcon} disabled={rest.disabled}>
        {buttonText}
      </Button>
    </Upload>
  );
};

export default UploadButton;
