import { InsuranceCalcMethodType, InsuranceOutputType } from "./insuranceCalcTypes";

const insuranceCalc: InsuranceCalcMethodType = ({
  insuranceInput: input,
  valueConfig: config,
}, isPrivate, isInactivePeriod, isVan) => {
  const { active, vehiclePrice, isUserOver26, glassDamage, roadAssistance } = input;
  //Insurance-addons & annually prices
  const insuranceAddOns = {
    roadHelp: 494,
    roadHelpVan: 1111,
    glassDamage: 1376,
    glassDamageSelfRisk: 1881,
  };
  const roadHelp = isVan ? insuranceAddOns.roadHelpVan : insuranceAddOns.roadHelp;

  //Init output
  let output: InsuranceOutputType = {
    insurable: true,
    monthlyPayment: null,
    ownRisk: null,
    roadAssistance: roadHelp ,
    glassDamage: insuranceAddOns.glassDamage,
    roadAssistanceMonthlyPayment: Math.ceil(roadHelp / 12 / 10) * 10,
    glassDamageMonthlyPayment: Math.ceil(insuranceAddOns.glassDamage / 12 / 10) * 10,
  };

  if (!active || !vehiclePrice) return output;

  // Check if insurable for under26:
  if (isPrivate) {
    const insuranceClassesUnder26 = insuranceClasses.filter((c) => !!c.priceUnder26);
    const maxPriceUnder26 = insuranceClassesUnder26[insuranceClassesUnder26.length - 1].amount;

    if (vehiclePrice > maxPriceUnder26 && !isUserOver26) {
      output.insurable = false;
      return output;
    }
  }

  //Calculation
  for (let insuranceClass of insuranceClasses) {
    if ((vehiclePrice || 0) <= insuranceClass.amount) {
      output.monthlyPayment = insuranceClass.price;
      output.ownRisk = insuranceClass.selfRiskPrice;

      if (!isUserOver26) {
        output.monthlyPayment = insuranceClass.priceUnder26 || 0;
        output.ownRisk = insuranceClass.selfRiskPriceUnder26;
        output.insurable = output.monthlyPayment !== null && output.ownRisk !== null;
      }
      break;
    }
  }



  /** Derudover skal der betales skadeforsikringsafgift til Staten af prisen for skadeforsikringer,
   *  dog med undtagelse af motoransvarsforsikringer og bådforsikringer.
   *  Afgiften udgør 1,1 % af prisen på dine forsikringer og skal betales,
   *  hver gang du betaler dine forsikringer.
   * @see https://tryg.dk/om-tryg/gebyrer-og-afgifter
   * @see https://www.codan.dk/erhverv/kundeservice/detmedsmaat#afgifter
   */
  // Skadeforsikringsafgift: Add 1,1% of prize to monthly payment
  // Miljøafgift: Add kr 84,- to monthly payment
  const INSURANCE_DAMAGE_TAX_RATE  = 0.011;
  const INSURANCE_ENVIRONMENT_TAX = 84;
  const insuranceCommission = config.insuranceCommission.value || 0;
  const monthlyInsuranceEnvironmentTax = INSURANCE_ENVIRONMENT_TAX / 12;
  const monthlyInsuranceCommission = insuranceCommission / 12;

  const totalPayment = (output.monthlyPayment || 0) // Bad naming >(
  let taxedMonthlyPayment = totalPayment / 12;
  const damageInsuranceTax = taxedMonthlyPayment * INSURANCE_DAMAGE_TAX_RATE;
  taxedMonthlyPayment += damageInsuranceTax;
  taxedMonthlyPayment += monthlyInsuranceEnvironmentTax;
  taxedMonthlyPayment += monthlyInsuranceCommission;

  output.monthlyPayment = Math.ceil(taxedMonthlyPayment / 10) * 10;


  if (isInactivePeriod) {
    output.monthlyPayment = Math.ceil(output.monthlyPayment / 2);
  }

  if (roadAssistance) output.monthlyPayment += output.roadAssistanceMonthlyPayment;
  if (glassDamage) {
    output.monthlyPayment += output.glassDamageMonthlyPayment;
  }

  return output;
};

export default insuranceCalc;


// Hvert år pr. forfald 1. januar forhøjes raterne og selvrisiko med indeks.
// Aftalen er pr. 1. september 2024 opdateret med indeks for 2024.
const insuranceClasses = [
  {
    amount: 100000,
    price: 6333,
    selfRiskPrice: 5660,
    priceUnder26: 12598,
    selfRiskPriceUnder26: 13892,
  },
  {
    amount: 200000,
    price: 7389,
    selfRiskPrice: 5660,
    priceUnder26: 14699,
    selfRiskPriceUnder26: 13892,
  },
  {
    amount: 300000,
    price: 8362,
    selfRiskPrice: 8232,
    priceUnder26: 15949,
    selfRiskPriceUnder26: 13892,
  },
  {
    amount: 400000,
    price: 11464,
    selfRiskPrice: 8232,
    priceUnder26: null,
    selfRiskPriceUnder26: null,
  },
  {
    amount: 500000,
    price: 11981,
    selfRiskPrice: 11834,
    priceUnder26: null,
    selfRiskPriceUnder26: null,
  },
  {
    amount: 600000,
    price: 13375,
    selfRiskPrice: 16979,
    priceUnder26: null,
    selfRiskPriceUnder26: null,
  },
  {
    amount: 750000,
    price: 16814,
    selfRiskPrice: 16979,
    priceUnder26: null,
    selfRiskPriceUnder26: null,
  },
];
