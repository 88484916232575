import { Button, Col, Row, Space, Typography } from "antd";
import { FC, useState } from "react";
import CardBox from "../../../../components/cardBox";
import LogText from "../../../../components/logText";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import TaxDocumentationBtn from "../taxDocumentationBtn";
import { taxAutoFetchDatDe } from "../../../../services/taxService";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";

const TaxDocDownloadBtns: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const { log } = taxCase;

  return (
    <CardBox
      className="pb-1"
      title={
        <Row justify="space-between" align="bottom">
          <Col>
            <Typography.Title
              level={4}
              type="secondary"
              className="mt-05 mb-05"
            >
              {_t("download")}
            </Typography.Title>
          </Col>
          <Col>
            {log?.documentationBought && (
              <LogText small logData={log?.documentationBought} />
            )}
          </Col>
        </Row>
      }
    >
      <Space direction="vertical">
        <TaxDocumentationBtn />
        <TaxFetchDatDeBtn />
      </Space>
    </CardBox>
  );
};

export default TaxDocDownloadBtns;

export const TaxFetchDatDeBtn: FC = () => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const handleAttemptAutoFetch = async () => {
    try {
      setLoading(true);
      const { data } = await taxAutoFetchDatDe(taxCase.id);
      setTaxCase(data);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button loading={loading} onClick={handleAttemptAutoFetch}>
      DAT.DE
    </Button>
  );
};

