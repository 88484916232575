import { Col, Row } from "antd";
import React, { FC } from "react";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import LeasingSendValidation from "./leasingSendValidation";

interface LeasingValidationProps {
  inputSavedStatus: boolean
}

const LeasingValidation: FC<LeasingValidationProps> = ({ inputSavedStatus }) => {
  const { leasingCase } = LeasingStore.useContainer();
  const code = leasingCase.leasingCode;

  const pendingContent = <label className="text-italic">{_t("pending")}</label>;

  return (
    <>
      <span className="text-dark d-block text-large mb-1">{_t("application")}</span>
      <Row justify="space-between" className="mb-1" align="middle">
        <Col></Col>
        <Col>
          <LeasingSendValidation inputSavedStatus={inputSavedStatus} />
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>{_t("leasing_code")}</Col>
        <Col>{code || pendingContent}</Col>
      </Row>
    </>
  );
};

export default LeasingValidation;
