import React, {FC} from "react";
import {ReactComponent as JcbCard} from "../assets/images/jcb.svg";
import {ReactComponent as DinersCard} from "../assets/images/diners.svg";
import {ReactComponent as MaestroCard} from "../assets/images/maestro.svg";
import {ReactComponent as MasterCard} from "../assets/images/mastercard.svg";
import {ReactComponent as MasterCardDebit} from "../assets/images/mastercard-debet.svg";
import {ReactComponent as DKCard} from "../assets/images/dankort.svg";
import {ReactComponent as VisaCard} from "../assets/images/visa.svg";
import {ReactComponent as VisaElectron} from "../assets/images/visa-electron.svg";
import {ReactComponent as AmericanExpress} from "../assets/images/american-express.svg";
import {ReactComponent as MobilePay} from "../assets/images/mobilepay.svg";
import {ReactComponent as Discover} from "../assets/images/discover-card-logo.svg";
import {ReactComponent as CartesBancaires} from "../assets/images/cartes-bancaires.svg";
import {ReactComponent as UnionPay} from "../assets/images/unionpay.svg";
import {PaymentCardBrandType} from "../types/paymentCardType";

interface PaymentCardBrandProps {
  brand: PaymentCardBrandType;
}

const PaymentCardBrand: FC<PaymentCardBrandProps> = ({brand}) => {
  switch (brand) {
    case "american-express":
      return <AmericanExpress className="mx-height-32"/>;
    case "dankort":
      return <DKCard className="mx-height-32"/>;
    case "diners":
      return <DinersCard className="mx-height-32"/>;
    case "jcb":
      return <JcbCard className="mx-height-32"/>;
    case "maestro":
      return <MaestroCard className="mx-height-32 width-38"/>;
    case "mastercard":
      return <MasterCard className="mx-height-32 width-38"/>;
    case "mastercard-debet":
      return <MasterCardDebit className="mx-height-32"/>;
    case "visa":
      return <VisaCard className="mx-height-32"/>;
    case "visa-electron":
      return <VisaElectron className="mx-height-32"/>;
    case "mobile-pay":
      return <MobilePay className="mx-height-32"/>;
    case "discover":
      return <Discover className="mx-height-32"/>;
    case "cartes-bancaires":
      return <CartesBancaires className="mx-height-32"/>;
    case "union-pay":
      return <UnionPay className="mx-height-32"/>;

    default:
      return <>{brand}</>;
  }
};

export default PaymentCardBrand;
