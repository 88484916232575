import React, { FC, ReactNode } from "react";
import { Alert, Col, Radio, RadioGroupProps, Row, Typography } from "antd";

interface RadioBoxProps extends Omit<RadioGroupProps, "options"> {
  options: RadioBoxOption[];
}

export interface RadioBoxOption {
  key: string | number;
  value: any;
  title: ReactNode;
  disabled?: boolean;
  promote?: boolean;
  extra?: ReactNode;
  children?: ReactNode;
}

const RadioBox: FC<RadioBoxProps> = ({ options, className, ...rest }) => {
  return (
    <Radio.Group className={`box-radio bg-dark ${className || ""}`} {...rest}>
      {options?.map((op) => !op.promote ? (
        <Radio
          value={op.value}
          key={op.key || op.value}
          disabled={op?.disabled}
        >
          <Row justify="space-between" align="middle" wrap={false}>
            <Col>
              <Typography.Title
                type={op?.disabled ? "secondary" : undefined}
                level={4}
              >
                {op.title}
              </Typography.Title>

              <div className="pr-05">
                {op.children}
              </div>
            </Col>
            <Col>{op.extra}</Col>
          </Row>
        </Radio>
      ) : (
        <Alert
          className="mt-1 mr-05"
          message={null}
          description={op.children}
          type="info"
          showIcon
        />
      ))}
    </Radio.Group>
  );
};

export default RadioBox;
