import { Button, Dropdown, Menu, message } from "antd";
import moment from "moment";
import { FC } from "react";
import Icon from "../../../assets/icons/icon";
import openModal from "../../../components/modal/openModal";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";

const DealersExport: FC = () => {
  const { user, hasPermission } = UserStore.useContainer();
  const canExport = hasPermission("dealer.export");
  const canExportStatistics = hasPermission("dealer.statistics");

  if (!canExport && !canExportStatistics) {
    return null;
  }

  const handleDealerStats = async () => {
    const from = moment().subtract(1, "day").toISOString();
    const to = moment().subtract(1, "months").toISOString();
    try {
      await openModal("exportDealersStats", {
        email: user?.email,
        date: [from, to],
      });
      message.success("sent");
    } catch (error) {
    }
  };

  const handleDealersExport = async () => {
    try {
      const { url } = await openModal("exportDealers", { active: true });
      window.open(url, "_blank");
    } catch (error) {
    }
  };

  const menu = (
    <Menu>
      {canExport && (
        <Menu.Item key={1} onClick={handleDealersExport} icon={<Icon name="person-outline" />}>
          {_t("dealers")}
        </Menu.Item>
      )}
      {canExportStatistics && (
        <Menu.Item key={2} onClick={handleDealerStats} icon={<Icon name="bar-chart-outline" />}>
          {_t("statistics")}
        </Menu.Item>
      )}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <Button className="muted-btn">
        {_t("export")} <Icon name="chevron-down-outline" size="small" />
      </Button>
    </Dropdown>
  );
};

export default DealersExport;
