import { Modal } from "antd";
import LoginModalForm from "./loginModalForm";

const { confirm } = Modal;

export default async function showLoginModal(): Promise<string | null> {
  const cancelBtnId = `login_modal_cancel_btn`;
  const closeModal = () => document.getElementById(cancelBtnId)?.click();

  return new Promise((resolve, reject) => {
    confirm({
      title: null,
      content: (
        <LoginModalForm
          title="Login"
          onSuccess={(token: string | null) => {
            resolve(token);
            closeModal();
          }}
        />
      ),
      width: 500,
      icon: null,
      className: "open-modal-wp",
      cancelButtonProps: {
        id: cancelBtnId,
        hidden: true,
      },
      okButtonProps: {
        hidden: true,
      },
      onCancel() {
        reject("Cancel");
      },
    });
  });
}
