/* import { getLocal } from "../services/login/authService";
const local = getLocal(); */

import danish from "./da_DK.json";
import React from "react";
import { Link } from "react-router-dom";

interface StringMap {
  [key: string]: string;
}

type TranslateFunction = (
  term: termType,
  subterm?: termType,
  linking?: string
) => string;

type LinkedTranslateFunction = (
  term: termType,
  subterm?: termType,
  link?: string
) => React.ReactNode;

type termType = keyof typeof danish;

const _t: TranslateFunction = (term, subterm, linking = " ") => {
  const da: StringMap = danish;
  const getTerm = da[term] || "";
  const getSubterm = subterm ? da[subterm].toLowerCase() || "" : "";
  return getSubterm.length > 0 ? `${getTerm}${linking}${getSubterm}` : getTerm;
};

export const _lt: LinkedTranslateFunction = (term, subterm, link) => {
  const da: StringMap = danish;
  const getTerm = da[term] || "";
  const getSubterm = subterm ? da[subterm].toLowerCase() || "" : "";
  let termString = getSubterm.length > 0 ? `${getTerm} ${getSubterm}` : getTerm;

  if (link) {
    const pattern = /__a__(.*?)__a__/g;
    const parts = termString.split(pattern);
    return parts.map((part, index) => {
      if (index % 2 === 0) {
        return part;
      }
      return <Link key={index} to={link}>{part}</Link>;
    });
  }

  return termString;
};

export default _t;
