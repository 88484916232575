import { Moment } from "moment";

import { IconType } from "antd/lib/notification";
import { UploadFile } from "antd/lib/upload/interface";
import { paths, urlExtraParams } from "../routes/appRoutes";

export type IdType = number;

export type ModulTypes = keyof typeof paths;
export const ProductKeys = [
  "tax",
  "taxDoc",
  "resellerTax",
  "import",
  "leasing",
  "partnerLeasing",
] as const;
export type ProductType = typeof ProductKeys[number];

export interface ContactFormType {
  type: "general" | "tax" | "import" | "leasing";
  message: string | null;
}
export const isModulTypes = (type: string): type is ModulTypes => {
  return Object.keys(paths).includes(type);
};

export type UrlExtraParamsType = keyof typeof urlExtraParams;
export const isUrlExtraParamsType = (
  type: string
): type is UrlExtraParamsType => Object.keys(urlExtraParams).includes(type);

export interface ScreenProps {
  title: string;
}

export interface ParamTypes {
  id: string;
}

export interface StatusUpdate extends EnumItemType {
  caseHandlerId: IdType | null;
  suggestedId?: IdType | null;
  forced?: boolean;
  expectedUpdateTime?: string | null;
}

export interface InternalLocation {
  internalModule: ModulTypes | null;
  params?: string[] | number[] | null;
  search?: string | null;
  hash?: string;
  state?: LocationStateType | null;
}

export interface LinkType extends InternalLocation {
  id?: string | number | null;
  external?: string | null;
  text?: string | null;
}

export const isLinkType = (link: any): link is LinkType =>
  typeof link?.external === "string" ||
  typeof link?.internalModule === "string";

export type LocationStateType = {
  pageSize?: number | string | null;
  errorStatusCode?: number;
  notification?: {
    message: string | null;
    description?: string;
    type?: IconType | null;
  };
  from?: {
    pathname: string;
    dealerId?: IdType;
  };
  data?: any;
};

export interface ImportVehicleType {
  link?: string | null;
  brandId?: IdType | null;
  modelId?: IdType | null;
  brand?: string | null;
  model?: string | null;
  firstRegDate?: string | null;
  vin?: string | null;
  mileage?: number | null;
  extraAccessories?: string | null;
  addModelAlias?: string | null;
  addVariantAlias?: string | null;
  meta?: null | {
    rawModel: string | null;
    rawVariant: string | null;
  };
}

export interface LeasingVehicleType extends ImportVehicleType {
  typeId?: IdType | null;
  type?: string | null;
  color?: string | null;
  qualityId?: IdType | null;
  quality?: string | null;
}

export interface BaseVehicle extends LeasingVehicleType {
  id?: number;
  engineSize?: string | null;
  isInspected?: boolean;
  horsePower?: string | null;
  fuelId?: IdType | null;
  fuel?: string | null;
  equipmentVariant?: string | null;
  modelDesignation?: string | null;
  modelPeriod?: string | null;
  gearboxId?: IdType | null;
  gearbox?: string | null;
  bodyId?: IdType | null;
  body?: string | null;
  useCaseId?: IdType | null;
  useCase?: string | null;
  isNew?: boolean | null;
  isHighWeight?: boolean | null;
  emissionAmount?: number | null;
  fuelEfficiency?: number | null;
  electricConsumption?: number | null;
  electricRange?: number | null;
  emissionUnitId?: IdType | null;
  emissionUnit?: string | null;
  batteryCapacity?: number | null;
  documentedMileage?: string | null;
  validDocumentedMileage?: boolean | null;
}
export interface Vehicle extends BaseVehicle {
  remoteData?: null | RemoteVehicleData;
}

export type RemoteVehicleData = {
  dmr: null | {
    raw: DMRVehicleData | null;
    mapped: BaseVehicle | null;
  },
  dat: null | {
    raw: DatVehicleData | null;
    mapped: BaseVehicle | null;
  }
}


export interface DatVehicleData {
  eCode: string | null,
  container: string | null,
  constructionTime: number | null,
  salesDescription: string | null,
  vehicleTypeName: string | null,
  baseModelName: string | null,
  subModelName: string | null,
  containerName: string | null,
  manufacturerName: string | null,
  capacity: string | number | null,
  horsePower: number | null,
  gearboxType: string | null,
  structureType: string | null,
  fuelMethodType: string | null,
  co2Emission: number | null,
  batteryCapacity: number | null,
  batteryConsumption: string | null,
  electricalRange: number | null,
  originalNetPrice: number | null,
  seatsCount: number | null,
  doorCount: number | null,
  gearCount: number | null,
  driveWheel: string | null,
  cylinders: number | null
}

export interface DMRVehicleData {
  data: null | {
    use?: {
      id: number | null,
      name: string | null
    },
    vin?: string | null,
    type?: string | null,
    axles?: string | null,
    brand?: string | null,
    color?: string | null,
    doors?: number | null,
    model?: string | null,
    wheels?: string | null,
    airbags?: number | null,
    permits?: unknown,
    version?: string | null,
    category?: string | null,
    coupling?: boolean | null,
    euronorm?: string | null,
    bodyType?: {
      id: number | null,
      name: string | null
    },
    equipment?: {
      id: number | null,
      name: string | null,
      quantity: number | null
    },
    fuelType?: string | null,
    ncapFive?: boolean | null,
    topSpeed?: number | null,
    modelYear?: string | null,
    vehicleId?: number | null,
    driveAxles?: string | null,
    engineCode?: number | string | null,
    expireDate?: string | null,
    enginePower?: number | null,
    registration?: string | null,
    totalWeight?: number | null,
    dispensations?: unknown[],
    maximumSeats?: number | null,
    minimumSeats?: number | null,
    towingWeight?: number | null,
    minimumWeight?: number | null,
    vehicleWeight?: number | null,
    extraEquipment?: string | null,
    fuelEfficiency?: number | null,
    engineCylinders?: number | null,
    seatBeltAlarms?: number | null,
    leasingPeriodEnd?: string | null,
    typeApprovalCode?: string | null,
    engineDisplacement?: number | null,
    registrationStatus?: string | null,
    statusUpdatedDate?: string | null,
    lastInspectionDate?: string | null,
    leasingPeriodStart?: string | null,
    towingWeightBrakes?: number | string | null,
    integratedChildSeats?: number | null,
    lastInspectionResult?: string | null,
    technicalTotalWeight?: number | null,
    firstRegistrationDate?: string | null,
    grossCombinationWeight?: number | null,
    lastInspectionOdometer?: number | null,
    registrationStatusUpdatedDate?: string | null
  },
  emissions: null | {
    co?: number | null,
    co2?: number | null,
    nox?: string | null,
    norm?: string | null,
    hcNox?: string | null,
    particles?: string | null,
    smokeDensity?: string | null,
    particleFilter?: string | null,
    smokeDensityRpm?: string | null
  },
  dmr: null | {
    typeApprovalCode: number | string | null,
    automatic: boolean,
    euronorm: string | null,
    insuranceCompany: string | null,
    insurancePolicyNumber: string | null,
    insuranceStatus: string | null,
    insuranceCreated: string | null,
    calculatedTax: {
      currentTotal: number | null,
      details: null | {
        2021: {
          fee: number | null
        },
        2022: {
          fee: number | null
        },
        2023: {
          fee: number | null
        },
        2024: {
          fee: number | null
        },
        2025: {
          fee: number | null
        },
        2026: {
          fee: number | null
        }
      }
    },
    taxes: {
      history: any[],
      info: any[]
    },
    nextInspectionDate: string | null,
    pluginHybrid: boolean | null,
    electricConsumption: string | null,
    electricRange: string | null,
    batteryCapacity: string | null,
    electricConsumptionMeasured: string | null,
    norm: string | null,
    totalCalculatedCo2: number | string | null,
    kilometers: number | null,
    kilometersDocumented: number | null,
    condition: string | null
  }
}




export interface VinVehicle {
  data: Vehicle;
}

export interface VehicleEquipment {
  code: null | string;
  id: null | string;
  name: null | string;
  price: null | number;
  priceEuro: null | number;
  subEquipments: null | VehicleEquipment[]
}

export interface Equipment {
  id: IdType;
  code: string | null; // read only
  name: string | null;
  price: number | null;
  priceEuro?: number | null;
  priceAutoCalc?: boolean;
  deduction: boolean;
  synced?: boolean;
  typeCategory?: number;
}

export const initEquipment: Equipment = {
  id: 0,
  code: null,
  name: null,
  price: null,
  deduction: false,
};

export interface Person {
  id?: IdType;
  fullName: string;
  phone?: string;
  email?: string;
  initials?: string;
}

export interface ContactPersonType {
  id: IdType;
  name: string | null;
  email: string | null;
  phone: string | null;
}

export const initContactPerson: ContactPersonType = {
  id: 0,
  name: null,
  email: null,
  phone: null,
};

export interface LogObj {
  date: string;
  person: Person;
}

export interface StatusLogObj extends LogObj {
  status: boolean;
}

export interface InitialClient {
  id: IdType | null;
  typeId: IdType;
  dealerId?: IdType | null;
}

export interface Client extends InitialClient {
  userId: IdType | null;
  customerId: IdType | null;
  name: string;
  dealerName: string | null;
  dealerId: IdType | null;
  dealerLevel: string | null;
  defaultPaymentCardId?: string | null;
  dealerCTR: number | null;
  dealerNote: string | null;
  dealerIsPriority: boolean;
  phone: string;
  email: string | null;
  zip: string | null;
  subscriptionPackage: string | null;
}

export interface ChangeClientType {
  typeId: IdType;
  userId?: IdType | null;
  customerId?: IdType | null;
  dealerId?: IdType | null;
}

export interface CVRData {
  address: string;
  city: string;
  cvr: string;
  email: string;
  name: string;
  phone: string;
  title: string;
  zip: string;
}

export interface EnumItemType {
  id: IdType;
  title: string;
  contexts?: ModulTypes;
  color?: string;
}

export interface EnumType {
  generic: {
    logEntryType: EnumItemType[];
  };
  client: {
    type: EnumItemType[];
  };
  customer: {
    type: EnumItemType[];
  };
  level: EnumItemType[];
  status: EnumItemType[];
  paymentMethod: EnumItemType[];
  paymentGateway: EnumItemType[];
  leasingCase: {
    type: EnumItemType[];
    carWarrantyHK: EnumItemType[];
    carWarrantyPackage: EnumItemType[];
    carWarrantyWeight: EnumItemType[];
    carWarrantyPeriod: EnumItemType[];
    carWarrantyFuel: EnumItemType[];
  };
  taxCase: {
    evaluationType: EnumItemType[];
    sourceType: EnumItemType[];
  };
  dealer: {
    category: EnumItemType[];
    logoPosition: EnumItemType[];
  };
  vehicle: {
    body: EnumItemType[];
    fuel: EnumItemType[];
    gearbox: EnumItemType[];
    quality: EnumItemType[];
    type: EnumItemType[];
    useCase: EnumItemType[];
    emissionUnit: EnumItemType[];
    energyType: EnumItemType[];
    equipmentCategory: EnumItemType[];
  };
}

export interface ApiFile extends AppDocumentType {
  mimeType: string;
  response?: any | UploadFile;
  previewUrl?: string | null;
  isAdmin?: boolean;
  size?: number;
  status?: "done" | "error" | "uploading";
}

export type CopyFuncType = (
  type:
    | "case"
    | "offer"
    | "regFeeCalc"
    | "tradePrice"
    | "vehicle"
    | "equipments"
) => void;

export interface AppDocumentType {
  id: IdType | string;
  name?: string | null;
  url?: string | null;
}

declare const initEnums: EnumType;

type EnumsMainKeys = keyof typeof initEnums | string;

export type EnumKeys = EnumsMainKeys[];

export interface StatusUpdateType {
  status: boolean;
}

export interface ScrapedVehicleType {
  price: {
    grossAmount: number;
    grossCurrency: string;
    netAmount: number;
    vat: number;
  };
  vehicle: Vehicle;
  merchantId: IdType;
}

/* type Enumlevel1Key = keyof typeof initEnums;
type Enumlevel1Key = keyof typeof initEnums[Enumlevel1Key]; */

export type StatusesType = { [x: string]: EnumItemType };
export type GetStatusesType = (isAdmin?: boolean) => StatusesType;

export interface RelatedCasesType {
  vin: LinkType[] | null;
  info: LinkType[] | null;
  link: LinkType[] | null;
}

export interface SearchItemType {
  value: string,
  link: string | null
}

export interface MonthlySpecificationFilterType {
  selectedStartDate: Moment;
  selectedEndDate: Moment;
  selectedStartingAmount: number;
  selectedEndingAmount: number;
  selectedStatus: number;
  selectedReconcileOption: number;
}

export interface MonthlySpecificationStatusListType {
  id: number;
  name: string | undefined;
}

export interface ReconcileOptionsListType {
  id: number;
  name: string | undefined;
}
