import { FC, useEffect } from "react";
import { Result } from "antd";
import { useParams } from "react-router-dom";
import _t from "../../lang/translate";
import PageTitle from "../../layout/pageTitle";
import { isNew } from "../../routes/appRoutes";
import { useApi } from "../../services/useApi";
import { ParamTypes, ScreenProps } from "../../types/appTypes";
import PageBody from "../../layout/pageBody";
import AnimateIn from "../../components/animateIn";
import { DealerType, initDealer } from "../../types/dealerTypes";
import DealerStore from "../../contexts/dealerStore";
import DealerContent from "./dealer/dealerContent";
import { dealerUrl } from "../../services/dealerService";
import DealerProspectId from "./dealer/dealerProspectId";

const Dealer: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading, isError }, setUrl] = useApi<DealerType>("", initDealer, true);

  useEffect(() => {
    if (!isNew(id)) setUrl(dealerUrl(id));
  }, [id, setUrl]);

  return (
    <DealerStore.Provider initialState={data}>
      <PageTitle title={`${title}/${id}`} fluid backBtn extra={<DealerProspectId />} />
      <PageBody>
        {isError ? (
          <Result status="error" title={_t("msg.unknown_error")} />
        ) : (
          <AnimateIn start={!isLoading} loadingBeforeStart>
            <DealerContent />
          </AnimateIn>
        )}
      </PageBody>
    </DealerStore.Provider>
  );
};

export default Dealer;
