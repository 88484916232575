import { Form } from "antd";
import moment from "moment";
import { FC } from "react";
import { Equipment } from "../../../../../types/appTypes";
import { TaxAdVehiclesType } from "../../../../../types/taxAdvancedCalcTypes";
import { TaxCalcEquipment } from "../../../../../types/taxCalcTableTypes";
import { TaxCase } from "../../../../../types/taxTypes";
import { dateDiff } from "../../../../../utilities/date";
import { mergeEquipments } from "../../calc/equipmentsCalculator";
import calculateTradePrice from '../../calc/tradePriceCalculatorIndex';
import {
  TradePriceCalcInputType,
  TradePriceCalcOutputType,
} from "../../calc/tradePriceCalculatorTypes";
import AdvCalcModalFooter from "./advCalcModalFooter";
import AdvCalcModalHeader from "./advCalcModalHeader";
import TaxAdvancedCalculator from "./taxAdvancedCalculator";

interface TaxAdvancedCalcFormProps {
  initValue: TaxAdVehiclesType | null;
  taxCase: TaxCase;
  vehicleEquipments: Equipment[];
  calculationDate?: string | null;
  initCalcInput: Partial<TradePriceCalcInputType>;
  stopDragging: () => void;
  startDragging: () => void;
  onApply?: (values: any, output: TradePriceCalcOutputType) => void;
}

const initAdvanceCalculator: TaxAdVehiclesType = {
  equipments: [],
  total: null,
  tradePrice: 0,
  euroFactor: 2,
  vehicle: {
    year: null,
    equipments: [],
    mileage: 0,
    qualityId: 1,
    useCaseId: 1,
    firstRegDate: null,
    equipmentsTotal: 0,
    equipmentsTotalUsed: 0,
  },
  vehicles: [],
};

const TaxAdvancedCalcForm: FC<TaxAdvancedCalcFormProps> = ({
  taxCase,
  initValue,
  vehicleEquipments = [],
  onApply,
  stopDragging,
  initCalcInput,
  startDragging,
}) => {
  const { mileage, qualityId, useCaseId, firstRegDate } = taxCase.vehicle || {};
  const [calcForm] = Form.useForm<any>();
  const TRADE_PRICE_EQUIPMENT_ID_BASE = 54321;

  const initVehicleYear = moment(firstRegDate).get("year");
  let init = initValue || initAdvanceCalculator;

  ///// Add a flag to synced equipments. And if vehicle is over a year old, remove all equipments with price under 10.000:
  const vehicleAgeInYears = firstRegDate ? Math.floor(dateDiff({ start: firstRegDate }) / 12) : 0;
  let newEquipments: TaxCalcEquipment[] = [...vehicleEquipments];
  if (newEquipments.length) {

    newEquipments = newEquipments.map((eq, i) => ({
      ...eq,
      id: eq?.id ? eq.id : i,
      synced: true,
    }));
    if (vehicleAgeInYears >= 1) {
      newEquipments = newEquipments.filter((eq) => !!eq.price && eq.price >= 10000);
    }
  }
  /////
  ///// Update synchronized equipments in initalValue:
  let initVehicleEquipments: Array<number | string> = init?.vehicle?.equipments || [];
  const hasEquipmentsSaved = !!init?.equipments?.length;
  if (!hasEquipmentsSaved && newEquipments.length) {
    // No equipments saved, check synced-equipments for vehicle:
    initVehicleEquipments = newEquipments.map((eq) => eq?.id) || [];
    init.equipments = [...newEquipments];
  } else {
    const initEquipments = mergeEquipments(initValue?.equipments, newEquipments);
    init.equipments = [...initEquipments];
  }

  ///// Update selected-equipment-ids-array on vehicles-array:
  const newEquipmentsIds = init.equipments?.map((e: any) => e.id);
  if (initVehicleEquipments.length) {
    initVehicleEquipments = initVehicleEquipments.filter((id) => newEquipmentsIds.includes(id));
  }
  if (init.vehicles?.length) {
    init.vehicles = init.vehicles.map((vh: any) => {
      if (Array.isArray(vh?.equipments) && vh?.equipments.length) {
        return {
          ...vh,
          equipments: vh.equipments.filter((e: any) => newEquipmentsIds.includes(e)),
        };
      }
      return vh;
    });
  }

  ///// Update vehicle values:
  init.vehicle = {
    ...init.vehicle,
    mileage: mileage || 0,
    qualityId: qualityId || 0,
    useCaseId: useCaseId || 1,
    firstRegDate: firstRegDate || "",
    year: initVehicleYear,
    equipments: initVehicleEquipments,
    equipmentsTotal: init.vehicle?.equipmentsTotal || 0,
    equipmentsTotalUsed: init.vehicle?.equipmentsTotalUsed || 0,
  };

  const handleApply = () => {
    const output = refreshTradePricePreview();
    onApply && onApply(calcForm.getFieldsValue(true), output);
  };

  const getVehiclesLength = (vehicles?: any[]) => vehicles?.filter((v: any) => v?.enabled).length || 0;

  const refreshTradePricePreview = () => {
    const values = calcForm.getFieldsValue(true);
    const input: TradePriceCalcInputType = {
      ...initCalcInput,
      mileage,
      qualityId,
      specialUseId: useCaseId,
      firstRegDate,

      equipmentRegulation: values?.total?.equipmentsPrice,
      vehicleAdCount: getVehiclesLength(values?.vehicles),
      averagePrice: values?.total?.recalculatedPrice,
      averageMileage: values?.total?.recalculatedMileage,
    };
    const output: TradePriceCalcOutputType = calculateTradePrice(input);
    calcForm.setFieldsValue({ tradePrice: output?.finalTradePriceTotal });
    return output;
  };

  const onVehicleAdd = () => {
    const { vehicles } = calcForm.getFieldsValue(true);
    let newId = 1; // id start number;
    let nextVehicles = [];
    if (Array.isArray(vehicles)) {
      const lastId = vehicles[vehicles.length - 1]?.id;
      if (typeof lastId === "number") {
        newId = lastId + 1;
      }
      nextVehicles = [...vehicles, { id: newId, enabled: true }];
    } else {
      nextVehicles = [{ id: newId, enabled: true }];
    }
    calcForm.setFieldsValue({ vehicles: nextVehicles });
  };

  const handleVehicleRemove = (id: number) => {
    const { vehicles } = calcForm.getFieldsValue(true);
    const newVehicles = Array.isArray(vehicles) ? vehicles.filter((veh) => veh.id !== id) : [];
    calcForm.setFieldsValue({ vehicles: [...newVehicles] });
  };

  const handleEquipmentAdd = () => {
    const { equipments } = calcForm.getFieldsValue(true);
    let newId: number = TRADE_PRICE_EQUIPMENT_ID_BASE; // id start number;
    let nextEquipments = [];
    if (Array.isArray(equipments)) {
      const lastId = equipments[equipments.length - 1]?.id;
      if (lastId < TRADE_PRICE_EQUIPMENT_ID_BASE) {
        newId = TRADE_PRICE_EQUIPMENT_ID_BASE + 1;
      } else {
        newId = lastId + 1;
      }
      nextEquipments = [...equipments, { id: newId }];
    } else {
      nextEquipments = [{ id: newId }];
    }
    calcForm.setFieldsValue({ equipments: nextEquipments });
  };

  const handleEquipmentRemove = (id: number) => {
    const { equipments, vehicles, vehicle } = calcForm.getFieldsValue(true);
    /// Remove equipment value from vehicle:
    let newVehiclesEquipments = [...(vehicle.equipments || [])]
      .filter((e_id) => e_id !== id)

    const newVehicle = { ...vehicle, equipments: newVehiclesEquipments };
    /// Remove equipment value from vehicles:
    const newVehicles = [...(vehicles || [])].map((veh) => {
      let equipments = [...(veh.equipments || [])].filter((e_id) => e_id !== id);
      return { ...veh, equipments };
    });

    /// Remove equipment from equipments:
    const newEquipments = [...(equipments || [])]
      .filter((eq) => eq.id !== id)

    calcForm.setFieldsValue({ equipments: newEquipments, vehicles: newVehicles, vehicle: newVehicle });
  };

  const handleNewVehicles = (vehicles: any) => {
    const oldVehicles: any[] = calcForm.getFieldsValue(true)?.vehicles;
    let nextVehicles = Array.isArray(vehicles) ? [...vehicles] : [];
    if (Array.isArray(oldVehicles) && oldVehicles.length) {
      nextVehicles = vehicles?.map((v: any) => {
        const oldEquipments: any[] = oldVehicles?.filter((o) => o.id === v.id)[0]?.equipments;
        return Array.isArray(oldEquipments) && oldEquipments.length ? { ...v, equipments: oldEquipments } : v;
      });
    }

    calcForm.setFieldsValue({ vehicles: nextVehicles });
  };
  return (
    <Form form={calcForm} initialValues={init} onFinish={handleApply}>
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldsValue }) => {
          const { vehicles, equipments } = getFieldsValue(true) || {};
          const headerProps = {
            stopDragging,
            startDragging,
            vehicleCount: getVehiclesLength(vehicles),
            onVehicleAdd,
          };
          return (
            <>
              <AdvCalcModalHeader {...headerProps} />
              <TaxAdvancedCalculator
                calculationDate={initCalcInput?.calculationDate}
                vehicles={vehicles}
                equipments={equipments}
                onEquipmentAdd={handleEquipmentAdd}
                onVehicleRemove={handleVehicleRemove}
                onEquipmentRemove={handleEquipmentRemove}
                calcForm={calcForm}
              />
              <AdvCalcModalFooter
                caseId={taxCase.id}
                onSubmit={calcForm.submit}
                onRefreshTradePrice={refreshTradePricePreview}
                onNewVehicles={handleNewVehicles}
                initCalcInput={initCalcInput}
              />
            </>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default TaxAdvancedCalcForm;
