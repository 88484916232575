import { useEffect, useState } from "react"

export function useLocalStorage<T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [storageValue, setStorageValue] = useState<T>(() => {
      const stored = window.localStorage.getItem(key)
      return stored ? JSON.parse(stored) : initialValue
  })

  useEffect(() => {
      window.localStorage.setItem(key, JSON.stringify(storageValue))
  }, [storageValue, key])

  return [storageValue, setStorageValue]
}
