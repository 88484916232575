import { FC, useEffect } from "react";
import CopyableContent from "../../../../components/copyableContent";
import { NewPriceCalcTaxDocType } from "../../../../types/taxDocTypes";
import format from "../../../../utilities/formatNumbers";
import { isNum } from "../../../../utilities/typeGuard";
import calculateNewPrice from '../calc/newPriceDecorator';

interface TotalNewPriceFieldProps {
  newPriceInput: NewPriceCalcTaxDocType;
  useTechnicalNewPrice: boolean;
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const TotalNewPriceField: FC<TotalNewPriceFieldProps> = ({ newPriceInput, value, onChange, useTechnicalNewPrice }) => {
  useEffect(() => {
    const newPrice = calculateNewPrice(newPriceInput);
    newPrice !== value && onChange && onChange(newPrice);
  }, [newPriceInput, useTechnicalNewPrice]);

  return (
    <CopyableContent contentToCopy={`${value || 0}`}>
      <h4 className="mb-0">{format.price(isNum(value) ? value : 0)}</h4>
    </CopyableContent>
  );
};

export default TotalNewPriceField;
