import { FC, useState } from "react";
import CollapseCard from "../../../components/collapseCard";
import MerchantStore from "../../../contexts/merchantStore";
import _t from "../../../lang/translate";
import MerchantInfoForm from "./merchantInfoForm";
import MerchantInfoView from "./merchantInfoView";

const MerchantInfoCard: FC = () => {
  const { merchant, loading, isNew } = MerchantStore.useContainer();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <CollapseCard title={merchant.name || _t("main_info")} loading={loading}>
      {isEditing || isNew ? (
        <MerchantInfoForm stopEditing={stopEditing} />
      ) : (
        <MerchantInfoView startEditing={startEditing} />
      )}
    </CollapseCard>
  );
};

export default MerchantInfoCard;
