type SetItemType = (key: string, value: string) => void;
type dispatchCustomEventType = (key: string, value?: string) => void;
type GetItemType = (key: string) => string | null;
type RemoveItemType = (key: string) => void;

type StorageType = {
  setItem: SetItemType;
  getItem: GetItemType;
  removeItem: RemoveItemType;
};

const dispatchCustomEvent: dispatchCustomEventType = (key, value) => {
  const eventInitDict = {
    key,
    oldValue: storage.getItem(key),
    newValue: value,
  };

  const event = new StorageEvent("storage", eventInitDict);
  dispatchEvent(event);
};

const storage: StorageType = {
  setItem: (key, value) => {
    localStorage.setItem(key, value);
    dispatchCustomEvent(key, value);
  },
  getItem: (key) => {
    return localStorage.getItem(key);
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
    dispatchCustomEvent(key);
  },
};

export default storage;
