import { Button, Modal } from "antd";
import { FC, useState } from "react";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import LeasingSendCodeForm from "./leasingSendCodeForm";

const SendLeasingCode: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();
  const { flags } = leasingCase;
  const { readonly } = flags;
  const disabled = readonly;

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  const handleSent = (leasingCode: string) => setLeasingCase({ ...leasingCase, leasingCode });

  return (
    <>
      <Button onClick={openModal} disabled={disabled}>
        {_t("send", "leasing_code")}
      </Button>
      <Modal width={500} visible={isVisible} onCancel={closeModal} title={_t("send", "leasing_code")} footer={null}>
        <LeasingSendCodeForm onCancel={closeModal} leasingCase={leasingCase} onSent={handleSent} />
      </Modal>
    </>
  );
};

export default SendLeasingCode;
