import { FC } from "react";
import { Result, ResultProps } from "antd";
import _t from "../../lang/translate";

const StatisticErrorResult: FC<ResultProps> = ({
  title = _t("msg.unknown_error"),
  status = "error",
  className,
  ...rest
}) => {
  return <Result status={status} title={title} className={"p-1 result-small " + className} {...rest} />;
};

export default StatisticErrorResult;
