import { FC, useState } from "react";
import CollapseCard from "../../../../components/collapseCard";
import _t from "../../../../lang/translate";
import ImportMerchantChooseForm from "./importMerchantChooseForm";
import ImportMerchantEditForm from "./importMerchantEditForm";
import ImportMerchantInfoEditable from "./importMerchantInfoEditable";

const ImportMerchantEditableCard: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isChoosing, setIsChoosing] = useState<boolean>(false);

  const startChoosing = () => setIsChoosing(true);
  const stopChoosing = () => setIsChoosing(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <>
      <CollapseCard title={_t("merchant")} loading={loading}>
        {isChoosing && <ImportMerchantChooseForm setLoading={setLoading} stopChoosing={stopChoosing} />}
        {isEditing && <ImportMerchantEditForm setLoading={setLoading} stopEditing={stopEditing} />}
        {!isEditing && !isChoosing && (
          <ImportMerchantInfoEditable startEditing={startEditing} startChoosing={startChoosing} />
        )}
      </CollapseCard>
    </>
  );
};

export default ImportMerchantEditableCard;
