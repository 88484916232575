import { FC } from "react";
import { useParams } from "react-router-dom";
import { ParamTypes, ScreenProps } from "../../../types/appTypes";
import { isNew } from "../../../routes/appRoutes";
import UserStore from "../../../contexts/userStore";
import ResellerTaxNewCase from "./resellerTaxNewCase";
import ResellerTaxCaseAdmin from "./admin/resellerTaxCaseAdmin";
import ResellerTaxCaseUser from "./user/resellerTaxCaseUser";

const ResellerTaxCase: FC<ScreenProps> = (props) => {
    const { hasPermission } = UserStore.useContainer();
    const isAdmin = hasPermission("resellerTax.isAdmin");
    const { id } = useParams<ParamTypes>();

    if (isNew(id)) {
        return <ResellerTaxNewCase {...props} />;
    }
    if (isAdmin) return <ResellerTaxCaseAdmin {...props} />;

    return <ResellerTaxCaseUser {...props} />;
};

export default ResellerTaxCase;
