import { Button, message } from "antd";
import { FC, useState } from "react";
import CardBox, { CardBoxProps } from "../../../../components/cardBox";
import Details from "../../../../components/detailsList";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { checkValidMileage } from "../../../../services/taxService";
import format from "../../../../utilities/formatNumbers";
import { isAxiosError } from "../../../../utilities/typeGuard";

const MileageDocumentation: FC<CardBoxProps> = (props) => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const [validating, setValidating] = useState<boolean>();
  const { documentedMileage, validDocumentedMileage } = taxCase.vehicle || {};

  const checkDocumentedMileage = async () => {
    try {
      setValidating(true);
      const { data } = await checkValidMileage(taxCase.id);
      setTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setValidating(false);
    }
  };

  return (
    <CardBox {...props}>
      <Details borderLess>
        <Details.Item
          label={_t("documentedMileage")}
          value={documentedMileage}
        />
        <Details.Item
          label={_t("validatedMileage")}
          value={format.yesOrNo(validDocumentedMileage)}
        />
      </Details>
      <div className="text-right">
        <Button
          type="link"
          className="pl-0 pr-0"
          onClick={checkDocumentedMileage}
          loading={validating}
        >
          {_t("check")}
        </Button>
      </div>
    </CardBox>
  );
};

export default MileageDocumentation;
