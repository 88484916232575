import appConfig from "../appConfig";

const consts = {
  contentMaxWidth: 1800,
  leasingSiderWidth: 400,
  leasingSiderWidthSm: 300,
  formGutter: 30,
  formSiderWidth: "260px",
  drawerWidth: 500,
  checklistDrawerWidth: 480,
  drawerWidthSm: 320,
  mobileSize: 576,
  tabletSize: 768,
  tablePageSize: ["10", "20", "50"],
  sideMenuWidth: 240,
  sideMenuWidthSm: 240,
  idColumnWidth: 80,
  newCaseAdminColumnProps: {
    xl: { span: 8 },
    lg: { order: 3 },
    xxl: 6,
    span: 24,
    order: 1,
  },
  newCaseContentColumnProps: (isAdmin: boolean) =>
    isAdmin
      ? {
        order: 2,
        span: 24,
        xl: { span: 16 },
        xxl: { span: 14, offset: 2 },
      }
      : {
        span: 24,
        xxl: { span: 16, offset: 4 },
      },
  formItemProps: {
    labelCol: { xs: 24, lg: 10, xl: 8, className: "text-left" },
    wrapperCol: { xs: 24, lg: 14, xl: 16 },
    colon: false,
    className: "mb-1",
  },
  formItemLabellessProps: {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
    colon: false,
    className: "mb-1",
  },
  formItemNoLabelProps: {
    labelCol: { span: 0 },
    wrapperCol: { xs: 24, lg: { span: 14, offset: 10 }, xl: { span: 16, offset: 8 } },
    colon: false,
    className: "mb-1",
  },
  zipFormLayout: {
    span: 24,
    lg: { span: 4 },
  },
  cityFormLayout: {
    span: 24,
    lg: { span: 8 },
  },
  formLayoutFull: {
    span: 24,
  },
  formLayout: {
    span: 24,
    lg: { span: 12 },
  },
  formLayoutHalfSize: {
    span: 24,
    lg: { span: 8 },
  },
  formTailLayout: {
    // used in config values
    label: { xs: 24, lg: 10, className: "text-lg-right tail-layout-label" },
    field: { xs: 24, lg: 14, className: "tail-layout-field" },
  },
  formInlineLayout: {
    labelCol: { xl: 10, xs: 24, className: "text-xl-right text-left" },
    wrapperCol: { xl: 14, xs: 24 },
    colon: false,
  },
  formInlineTailLayout: {
    wrapperCol: { xl: { offset: 10, span: 14 } },
  },
  timeFormat: "HH:mm",
  dateFormats: [
    appConfig("dateFormat"),
    "DDMMYYYY",
    "DDMMYY",
    "DD-MM-YY",
    "D-MM-YYYY",
    "DD-M-YYYY",
    "DD/MM/YYYY",
    "D/MM/YYYY",
    "DD/M/YYYY",
    "D/M/YYYY",
  ],
  notificationConfig: {
    placement: "bottomRight",
    bottom: 50,
    duration: 3,
  },
  paymentMethodId: {
    quickpay: 1,
    invoice: 2,
    stripe: 3,
  }
};
export default consts;
