import { FC } from "react";
import CollapseCard from "../../../../components/collapseCard";
import DividerLine from "../../../../components/lineSplit";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import LeasingUploadFile from "../leasingUploadFile";

const LeasingAfterRegisterCard: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();

  return (
    <CollapseCard title={_t("after_register")}>
      {leasingCase.separateFiles?.map((file) => (
        <LeasingUploadFile key={file.apiKey} separateFile={file} />
      ))}
      <DividerLine fluid />
      <label className="text-italic">{_t("will_recalc_after_send_files")}</label>
    </CollapseCard>
  );
};

export default LeasingAfterRegisterCard;
