import { Modal } from "antd";
import ModalContent from "./modalContent";
import { ModalTypes } from "../../types/modalTypes";

const { confirm } = Modal;

export default async function openModal<T extends object>(type: ModalTypes, payload: any): Promise<T> {
  const cancelBtnId = `${type}_modal_cancel_btn`;
  const closeModal = () => document.getElementById(cancelBtnId)?.click();

  return new Promise((resolve, reject) => {
    confirm({
      title: null,
      content: (
        <ModalContent<T>
          onSuccess={(data) => {
            resolve(data);
            closeModal();
          }}
          onCancel={closeModal}
          type={type}
          payload={payload}
        />
      ),
      width: 500,
      icon: null,
      className: "open-modal-wp",
      cancelButtonProps: {
        id: cancelBtnId,
        hidden: true,
      },
      okButtonProps: {
        hidden: true,
      },
      onCancel() {
        reject("Cancel");
      },
    });
  });
}
