import React, { FC } from "react";
import { Steps } from "antd";
import LogText from "../../../../components/logText";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import { LogObj } from "../../../../types/appTypes";

const { Step } = Steps;

const TaxDocProcessSteps: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  const { log, flags } = taxDocCase;

  const getLogDescription = (log?: LogObj | null) => {
    return log ? (
      <LogText className="p-0" logData={log} />
    ) : (
      <em>{_t("pending")}</em>
    );
  };

  const getStatus = (value?: boolean) => {
    return value ? "process" : "wait";
  };

  return (
    <Steps progressDot direction="vertical" className="mb-1">
      <Step
        title={_t("case_created")}
        description={getLogDescription(log?.creation)}
        status={getStatus(true)}
      />
      <Step
        title={_t("vehicle_evaluated")}
        description={getLogDescription(log?.documentationPublished)}
        status={getStatus(flags.documentationPublished)}
      />
      <Step
        title={_t("documentation_purchased")}
        description={getLogDescription(log?.documentationBought)}
        status={getStatus(flags.documentationBought)}
      />
      <Step
        title={_t("vehicle_is_registered")}
        description={getLogDescription(log?.registered)}
        status={getStatus(flags.registered)}
      />
    </Steps>
  );
};

export default TaxDocProcessSteps;
