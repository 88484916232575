import { getLocal } from "../services/authService";
import moment from "moment";
import appConfig from "../appConfig";
import _t from "../lang/translate";
import { isNotEmptyString, isNum, isString } from "./typeGuard";

const local = getLocal();

type NumFunc = (number?: any, props?: Intl.NumberFormatOptions) => string;

const number: NumFunc = (number, props) => (isNum(number) ? new Intl.NumberFormat(local, props).format(number) : "-");

const percent: NumFunc = (number, props) =>
  isNum(number)
    ? new Intl.NumberFormat(local, {
      style: "percent",
      maximumFractionDigits: 2,
      ...props,
    }).format(number)
    : "";

type PriceFunc = (number: number | null | undefined, maximumFractionDigits?: number, currency?: string) => string;

const price: PriceFunc = (number, maximumFractionDigits = 0, currency = "DKK") => {
  if (typeof number !== "number") return "-";
  let config: Intl.NumberFormatOptions = {
    style: "currency",
    currency,
  };
  if (maximumFractionDigits > 0) config = { ...config, maximumFractionDigits };
  else number = Math.round(number);
  return new Intl.NumberFormat(local, config).format(number).replace(",00", "");
};


const pricePrefixed = (number: unknown, decimals: number = 0): string => {
  let localNumber = 0;
  if (typeof number === 'string' && !isNaN(parseFloat(number))) {
    localNumber = parseFloat(number);
  } else if (typeof number !== "number") {
    return "-";
  } else {
    localNumber = number
  }

  let config: Intl.NumberFormatOptions = {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals
  };
  const num = new Intl.NumberFormat(local, config).format(localNumber);
  if (decimals) {
    return `kr. ${num},-`;
  } else {
    return `kr. ${num.replace(",00", "")},-`;
  }
};

const text = (v1?: string | number | null, v2?: string | number | null, separator?: string | null) => {
  let value = "-";
  if (isNotEmptyString(v1)) value = v1;
  if (isNotEmptyString(v2)) value += isString(separator) ? separator : " " + v2;
  return value;
};

const date = (value?: string | null) =>
  typeof value === "string" && moment(value).isValid() ? moment(value).format(appConfig("dateFormat")) : "-";

const year = (value?: string | null) =>
  typeof value === "string" && moment(value).isValid() ? moment(value).year() : "-";

const yesOrNo = (value?: boolean | null) => (typeof value === "boolean" ? _t(value ? "yes" : "No") : "-");

const milage = (value?: number | null) => (isNum(value) ? `${number(value)} ${_t("mileage_unit")}` : "-");

const emission = (value?: number | null) => (isNum(value) ? `${number(value)} ${_t("emission_unit")}` : "-");

const batteryUsage = (value?: number | null) => (isNum(value) ? `${number(value)} ${_t("battery_usage_unit")}` : "-");

const batteryCapacity = (value?: number | null) =>
  isNum(value) ? `${number(value, { maximumFractionDigits: 2 })} ${_t("battery_unit")}` : "-";

const time = (value?: string | null, prefix?: boolean) =>
  typeof value === "string" ? `${prefix ? "kl. " : ""}${moment(value).format("HH:mm")}` : "-";

const dateAndTime = (value?: string | null) =>
  typeof value === "string" ? `${date(value)} - ${time(value, true)}` : "-";

const fileSize = (bytes: number, decimals?: number) => {
  if (bytes === 0) return "0 Bytes";
  var k = 1024,
    dm = decimals || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const format = {
  price,
  pricePrefixed,
  number,
  percent,
  date,
  year,
  time,
  yesOrNo,
  dateAndTime,
  milage,
  emission,
  batteryUsage,
  batteryCapacity,
  fileSize,
  text,
};

export default format;
