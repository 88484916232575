import { Button, Empty, Space } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { customersAutoComplete } from "../../services/autocompleteService";
import { exportCustomers } from "../../services/systemService";
import { EnumItemType } from "../../types/appTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import ServerSelectBox from "../serverSelectBox";
import { renderErrorMessage } from "../messages/errorMessage";

export default function ExportCustomers<T extends object>({ onSuccess, onCancel }: OpenModalProps<T>) {
  const [selectedCustomers, setSelectedCustomers] = useState<EnumItemType[]>([]);
  const [selectValue, setSelectValue] = useState<any>([]);

  const handleExport = async () => {
    try {
      const { data } = await exportCustomers(selectedCustomers.map((e) => e.id));
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
    }
  };

  const handleSelect = (id: any, title: any) => {
    if (typeof id !== "number") return;
    if (!title) title = "";
    if (typeof title !== "string") title = title?.toString();

    const nextCustomer: EnumItemType = { id, title };
    setSelectedCustomers((list) => [nextCustomer, ...list]);
    setSelectValue(null);
  };

  const columns: ColumnsType<EnumItemType> = [
    {
      key: "title",
      dataIndex: "title",
      ellipsis: true,
    },
    {
      key: "id",
      dataIndex: "id",
      width: 60,
      render: (v) => {
        return (
          <Button
            className="muted delete-btn"
            type="text"
            shape="circle"
            icon={<Icon name="trash-outline" />}
            onClick={() => setSelectedCustomers((list) => list.filter((e) => e.id !== v))}
          />
        );
      },
    },
  ];

  return (
    <>
      <h2 className="modal-title">{_t("export", "customers")}</h2>
      <div className="mb-1">
        <ServerSelectBox
          value={selectValue}
          placeholder={_t("search_placeholder")}
          onChange={(value) => setSelectValue(value)}
          skipItems={selectedCustomers.map((e) => e.id)}
          className="w-100"
          apiUrl={customersAutoComplete()}
          onSelect={(_, { value, label }) => handleSelect(value, label)}
        />
      </div>
      {!!selectedCustomers.length ? (
        <Table
          columns={columns}
          dataSource={selectedCustomers}
          rowKey="id"
          showHeader={false}
          pagination={{ pageSize: 10, hideOnSinglePage: true, size: "small" }}
        />
      ) : (
        <Empty description={_t("choose_customers_for_export")} className="mt-2 mb-4" />
      )}
      <div className="text-right mt-1">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>
          <Button onClick={handleExport} type="primary">
            {_t("export")}
          </Button>
        </Space>
      </div>
    </>
  );
}

assertFC(ExportCustomers);
