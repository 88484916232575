import { SelectProps } from "antd/lib/select";
import React, { FC } from "react";
import { EnumItemType, ModulTypes } from "../../types/appTypes";
import EnumSelect from "./enumSelect";

interface StatusSelectProps extends SelectProps<any> {
  contexts: ModulTypes;
}

const StatusSelect: FC<StatusSelectProps> = ({ contexts, ...rest }) => {
  const filterByContext = (data: EnumItemType[]) =>
    data?.filter((item) => item?.contexts?.includes(contexts));
  return (
    <EnumSelect dataFilter={filterByContext} nameKey={["status"]} {...rest} />
  );
};

export default StatusSelect;
