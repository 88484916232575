import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { getInitCustomer, isPrivateCustomer } from "../services/customerService";
import { CustomerType } from "../types/customerTypes";
import { convertToUploadFileList, isApiFiles } from "../utilities/convertTypes";

interface CustomerStoreProps {
  customer: CustomerType;
  isPrivate: boolean;
  isNew: boolean;
  setCustomer: (newCustomer: CustomerType) => void;
}

const useCustomerStore = (initialState?: CustomerType): CustomerStoreProps => {
  const [customer, setCustomer] = useState<CustomerType>(getInitCustomer());

  const handleSetCustomer = useCallback(
    (newCustomer: CustomerType) => {
      const files =
        newCustomer.files && isApiFiles(newCustomer.files)
          ? convertToUploadFileList(newCustomer.files)
          : newCustomer.files;
      setCustomer({ ...newCustomer, files });
    },
    [setCustomer]
  );

  useEffect(() => {
    if (initialState) {
      handleSetCustomer(initialState);
    }
  }, [initialState, handleSetCustomer]);

  return {
    customer,
    isNew: customer.id === 0,
    isPrivate: isPrivateCustomer(customer.typeId),
    setCustomer: handleSetCustomer,
  };
};

const CustomerStore = createContainer(useCustomerStore);

export default CustomerStore;
