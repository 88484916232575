import { FC } from "react";
import { Form } from "antd";
import _t from "../../../../lang/translate";
import { TradePriceCalcOutputType } from "../calc/tradePriceCalculatorTypes";
import TradePriceAdvancedCalculator from "./advancedCalculator/tradePriceAdvancedCalculator";
import { OfferTax } from "../../../../types/taxTypes";

const TradePriceCalculator: FC = () => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ setFieldsValue, getFieldsValue, getFieldValue }) => {
        const caseCalcValues = getFieldsValue(true) as OfferTax;
        const initCalcInput = {
          // TODO: Add calculation start date from setting whenever we come there!
          optimization: !!caseCalcValues?.optimization,
          optimizedRegulation: 0,
          otherRegulation: 0,
        };
        const handleOutput = (output: TradePriceCalcOutputType) => {
          const tradePriceValues = getFieldValue("tradePrice");
          setFieldsValue({
            tradePrice: {
              ...tradePriceValues,
              tradePriceOutput: output,
            },
          });
        };

        return (
          <Form.Item
            name={["tradePrice", "adVehicles"]}
            label={_t("calculated", "trade_price")}
          >
            <TradePriceAdvancedCalculator
              onCalculated={handleOutput}
              calculationFormValues={getFieldsValue(true) as OfferTax}
              initCalcInput={initCalcInput}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default TradePriceCalculator;
