import { FC } from "react";
import { Card, Result, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import _t from "../../lang/translate";
import { getPath } from "../../routes/appRoutes";
import { getAdminListUrl } from "../../services/systemService";
import { useApi } from "../../services/useApi";
import { IdType } from "../../types/appTypes";
import { AdminProductsType, ProductsAdminType } from "../../types/systemTypes";
import ProductsAdminButton from "./productsAdminButton";

const SystemUserResponsibilities: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ data, isLoading, isError }, _, setData] = useApi<{ data: ProductsAdminType[] }>(getAdminListUrl, {
    data: [],
  });
  const dataSource = data.data;

  const handleChange = (userId: IdType, product: AdminProductsType) => {
    const originalData = [...dataSource];
    const nextData = originalData.map((user) => {
      if (user.id !== userId) return user;
      const products = user.products.map((p) => (p.id !== product.id ? p : product));
      return { ...user, products };
    });
    setData({ ...data, data: nextData });
  };

  const columns: ColumnsType<ProductsAdminType> = [
    /*     {
      title: "#",
      align: "center",
      key: "id",
      sorter: true,
      dataIndex: "id",
      width: 110,
      render: (text, record) => (
        <Link to={getPath("user", record.id)}>
          <strong>{text}</strong>
        </Link>
      ),
      fixed: "left",
    }, */
    {
      title: _t("name"),
      key: "name",
      sorter: true,
      ellipsis: true,
      dataIndex: "name",
      render: (text, record) => (
        <Link to={getPath("user", record.id)}>
          <strong>{text}</strong>
        </Link>
      ),
    },
    /*  {
      title: _t("email"),
      key: "email",
      sorter: true,
      dataIndex: "email",
    }, */
    {
      title: _t("products"),
      key: "products",
      dataIndex: "products",
      render: (_, { id, products }) => (
        <Space>
          {products?.map((product) => (
            <ProductsAdminButton key={product.id} userId={id} product={product} onChange={handleChange} />
          ))}
        </Space>
      ),
    },
  ];

  return (
    <Card title={_t("responsibilities")} loading={isLoading} bodyStyle={{ padding: 0 }}>
      {isError && <Result status="error" title={_t("msg.unknown_error")} />}
      <Table<ProductsAdminType>
        loading={isLoading}
        pagination={{ pageSize: 50, hideOnSinglePage: true }}
        rowKey="id"
        scroll={{ x: "auto" }}
        columns={columns}
        dataSource={dataSource}
      />
    </Card>
  );
};

export default SystemUserResponsibilities;
