import { Button, Card, Col, Form, message, Result, Row, Space, Tabs } from "antd";
import { FormProvider } from "antd/lib/form/context";
import { FormChangeInfo, FormFinishInfo } from "rc-field-form/es/FormContext";
import { FC, useState } from "react";
import ConfigFieldsGenerator from "../../components/form/ConfigFieldsGenerator";
import SavedStatus from "../../components/savedStatus";
import _t from "../../lang/translate";
import { saveSystemValueConfig, systemValueConfigUrl } from "../../services/systemService";
import { useApi } from "../../services/useApi";
import { ConfigFieldsType, SystemValueConfigType } from "../../types/systemTypes";
import SystemTransportFees from "./systemTransportFees";
import { renderErrorMessage } from "../../components/messages/errorMessage";
import DividerLine from "../../components/lineSplit";
import consts from "../../utilities/consts";

const { TabPane } = Tabs;

const SystemConfigValue: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [notMatchedKeys, setNotMatchedKeys] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ data, isLoading, isError }, _, setData] = useApi<SystemValueConfigType[]>(systemValueConfigUrl(), []);

  const handleValueChange = (key: string, { forms }: FormChangeInfo) => {
    if (key && forms[key]) {
      const formValues = forms[key].getFieldsValue()[key];
      const stateValues = data.filter((item) => item.key === key)[0]?.values;
      if (formValues && stateValues) {
        const isMatched = Object.keys(formValues).reduce(
          (res, item) => res && formValues[item]?.value === stateValues[item]?.value,
          true
        );
        const isNotSaved = notMatchedKeys.includes(key);
        if (!isMatched === isNotSaved) return;
        if (isMatched) setNotMatchedKeys((state) => state.filter((e) => e !== key));
        else setNotMatchedKeys((state) => [...state, key]);
      }
    }
  };

  const handleSave = async (key: string, info: FormFinishInfo) => {
    if (key && info.forms[key]) {
      try {
        setLoading(true);
        const { data } = await saveSystemValueConfig(info.values);
        message.success(_t("saved"));
        setNotMatchedKeys((state) => state.filter((e) => e !== key));
        setData(data);
      } catch (error) {
        renderErrorMessage(error)
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card loading={isLoading || loading} bodyStyle={{ paddingLeft: 0 }}>
      {isError && <Result status="error" title={_t("msg.unknown_error")} />}
      <FormProvider onFormChange={handleValueChange} onFormFinish={handleSave}>
        <Tabs tabBarGutter={4} size="middle" className="system-tabs" tabPosition="left">
          {data?.map((item) => (
            <TabPane tab={item.label} key={item.key} forceRender={true} disabled={loading}>
              <h2>{item.label}</h2>
              <DividerLine />
              <Form<ConfigFieldsType> name={item.key} initialValues={{ [item.key]: item.values }}>
                <ConfigFieldsGenerator
                  dividerBefore={["insuranceCommission", "interestLoanMarginal", "paymentTermCode"]}
                  nested={[item.key]}
                  list={item.values || null}
                  disabled={loading}
                />
                {item.key === "importCaseValueConfig" && (
                  <Row justify="space-between">
                    <Col {...consts.formItemProps.labelCol} className="text-secondary" >{_t("transport_fees")}</Col>
                    <Col {...consts.formItemProps.wrapperCol}>
                      <SystemTransportFees />
                    </Col>
                  </Row>
                )}
                <DividerLine fluid />
                <div className="text-right">
                  <Form.Item noStyle shouldUpdate>
                    {({ submit, resetFields }) => {
                      return (
                        <Space>
                          <SavedStatus status={!notMatchedKeys.includes(item.key)} />
                          <Button
                            disabled={loading}
                            onClick={() => {
                              resetFields([item.key]);
                              setNotMatchedKeys((state) => [...state, item.key]);
                            }}
                            type="ghost"
                          >
                            {_t("cancel")}
                          </Button>
                          <Button onClick={submit} type="primary" loading={loading}>
                            {_t("save")}
                          </Button>
                        </Space>
                      );
                    }}
                  </Form.Item>
                </div>
              </Form>
            </TabPane>
          ))}
        </Tabs>
      </FormProvider>
    </Card>
  );
};

export default SystemConfigValue;
