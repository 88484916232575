import { FC, ReactNode } from "react";
import { Col, Row, Switch, SwitchProps } from "antd";

interface SwitchInputProps extends SwitchProps {
  label: ReactNode;
  id?: string;
  color?: "green" | "red" | "default";
  extra?: ReactNode;
}

const SwitchInput: FC<SwitchInputProps> = ({ label, size, className, extra, checked, color = "default", ...rest }) => {
  const nextId = { ...(rest as any) }?.id;
  const isSmall = size === "small";

  return (
    <Row align="middle" className={className}>
      <Col>
        <Switch className={`switch-${color}`} checked={checked} {...rest} size={size} />
      </Col>
      <Col>
        <label
          style={nextId ? { cursor: "pointer" } : {}}
          htmlFor={nextId}
          className={`${isSmall ? "text-small pl-05" : "pl-1"}${checked ? " text-dark" : ""}`}
        >
          {label}
          {extra && " — " + extra}
        </label>
      </Col>
    </Row>
  );
};

export default SwitchInput;
