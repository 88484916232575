import { FC, useEffect } from "react";
import { InputNumber, InputNumberProps } from "antd";
import moment from "moment";
import _t from "../../../lang/translate";

interface LeasingVehicleAgeFieldsProps extends InputNumberProps {
  leasingStartDate?: string | null;
  firstRegDate?: string | null;
}

const LeasingVehicleAgeField: FC<LeasingVehicleAgeFieldsProps> = ({
  leasingStartDate,
  firstRegDate,
  disabled,
  value,
  onChange,
  ...rest
}) => {
  useEffect(() => {
    if (!leasingStartDate || !firstRegDate) return;
    const startDate = moment(leasingStartDate).endOf("day");
    const frd = moment(firstRegDate).endOf("day");
    if (!startDate.isValid() || !frd.isValid()) return;

    const vehicleAge = Math.abs(frd.diff(startDate, "months", false));

    vehicleAge !== value && onChange && onChange(vehicleAge);
  }, [leasingStartDate, firstRegDate]);

  return (
    <>
      <InputNumber
        disabled={disabled || !!firstRegDate}
        min={0}
        value={value}
        onChange={onChange}
        maxLength={3}
        {...rest}
      />
      <small className="pl-05">{_t("when_leasing_start_date")}</small>
    </>
  );
};

export default LeasingVehicleAgeField;
