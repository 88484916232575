import { isNum } from "./typeGuard";

export function roundNum(num: number, length: number) {
  var number = Math.round(num * Math.pow(10, length)) / Math.pow(10, length);
  return number;
}

export function isBetween(value: any, range: any[]) {
  if (!isNum(value) || !isNum(range[0]) || !isNum(range[1])) return false;
  return value > range[0] && value < range[1];
}

export function isBetweenOrEqual(value: any, range: any[any]) {
  if (!isNum(value) || !isNum(range[0]) || !isNum(range[1])) return false;
  return value >= range[0] && value <= range[1];
}
