export default function calcAverage<T extends Record<string, unknown>>(list: T[], key: keyof T): number {
    if (list.length) {
        let average = 0;
        list.forEach((item: T, i) => {
            let num = item[key];
            if (typeof num === "number" && !isNaN(num)) {
                average += num / list.length;
            } else {
                throw new Error(`property: '${key.toString()}' is of type '${typeof num}' at index: ${i}`)
            }
        })
        return average;
    } else {
        throw new Error('List is empty');
    }
}
