import React, { FC, useEffect, useState } from "react";
import InputNum, { InputPriceProps } from "../../../../components/form/inputNum";
import { compare } from "../../../../utilities/deepCompare";
import calculateRegFee, { RegFeeCalcInputType, RegFeeCalcOutputType } from "../calc/regFeeCalculatorIndex";

interface TaxDocRegestrationFeeInputProps extends InputPriceProps {
  onOutput?: (output: RegFeeCalcOutputType) => void;
  input: RegFeeCalcInputType;
  autoCalculate: boolean;
}

const TaxDocRegestrationFeeInput: FC<TaxDocRegestrationFeeInputProps> = ({
  input,
  onOutput,
  autoCalculate,
  onChange,
  ...rest
}) => {
  const [prevInput, setPrevInput] = useState<RegFeeCalcInputType>(input);

  useEffect(() => {
    if (!autoCalculate || compare(input, prevInput)) return;
    setPrevInput(input);

    async function calc() {
      try {
        const output = await calculateRegFee(input);
        if (onChange && typeof output.registrationFee === "number") {
          onChange(output.registrationFee);
        }
        onOutput && onOutput(output);
      } catch (error) {
        console.log(error);
      }
    }

    calc();
  }, [input, autoCalculate]);

  return <InputNum onChange={onChange} {...rest} />;
};

export default TaxDocRegestrationFeeInput;
