import { FC, useEffect } from "react";
import { Result } from "antd";
import { useParams } from "react-router-dom";
import _t from "../../lang/translate";
import PageTitle from "../../layout/pageTitle";
import { isNew } from "../../routes/appRoutes";
import { useApi } from "../../services/useApi";
import { ParamTypes, ScreenProps } from "../../types/appTypes";
import PageBody from "../../layout/pageBody";
import AnimateIn from "../../components/animateIn";

import { initMerchant, merchantUrl } from "../../services/merchantService";
import { MerchantType } from "../../types/merchantTypes";
import MerchantStore from "../../contexts/merchantStore";
import MerchantContent from "./merchant/merchantContent";

const Merchant: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading, isError }, setUrl] = useApi<MerchantType>("", initMerchant);

  useEffect(() => {
    if (!isNew(id)) setUrl(merchantUrl(id));
  }, [id, setUrl]);

  return (
    <MerchantStore.Provider initialState={data}>
      <PageTitle title={`${title}/${id}`} fluid backBtn />
      <PageBody>
        {isError && <Result status="error" title={_t("msg.unknown_error")} />}
        <AnimateIn start={!isLoading} loadingBeforeStart>
          <MerchantContent />
        </AnimateIn>
      </PageBody>
    </MerchantStore.Provider>
  );
};

export default Merchant;
