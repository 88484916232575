import React, { FC, ReactNode } from "react";

export interface CardBoxProps extends Omit<React.HTMLProps<HTMLDivElement>, "title"> {
  title?: ReactNode;
  bordered?: boolean;
  bg?: string
}

const CardBox: FC<CardBoxProps> = ({ title, bordered, children, bg, className, ...rest }) => {
  return (
    <div
      className={`pt-05 pb-05 pl-1 pr-1 border-rounded${bordered ? " border-light" : ""
        } ${className ? className : ""} ${bg ? bg : 'bg-light'}`}
      {...rest}
    >
      {title && <label className="d-block mb-05">{title}</label>}
      {children}
    </div>
  );
};

export default CardBox;
