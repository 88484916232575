import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";
import { RemoteVehicleData } from "../types/appTypes";

const apiEndpoint = `${getApiEndpoint()}/misc/vinDetails`;


export function getVinDetails(vin: string) {
  return http.get(`${apiEndpoint}/${vin}`);
}

// Route::post('{vin}/getRemoteData'
type getRemoteDataConfig = {
  items: ("dmr" | "dat")[];
  forceFresh?: boolean;
}
export function getRemoteData(vin: string, data: getRemoteDataConfig) {
  return http.post<{ data: RemoteVehicleData }>(`${getApiEndpoint()}/vin/${vin}/getRemoteData`, data);
}
