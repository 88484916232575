import React, { FC, useState } from "react";
import { Button, Col, Form, message, Modal, Row, Select, Space } from "antd";
import Icon from "../../../../assets/icons/icon";
import DividerLine from "../../../../components/lineSplit";
import _t from "../../../../lang/translate";
import { openFileLink } from "../../../../services/mediaService";
import type { SendOfferFormType } from "../../../../types/resellerTaxTypes";
import TextArea from "antd/lib/input/TextArea";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import { sentResellerTaxOffer } from "../../../../services/resellerTaxService";

interface TaxOfferActionsProps {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const reservationOptions = [
  {
    label: "Link til bil...",
    value: "Udstyret er baseret på en analyse af annonceteksten, og er derfor kun vejledende. Send stelnummer for at få en præcis vurdering. Hvis du ønsker at forsætte sagen skal du besvare denne e-mail med stel-nr. Såfremt det er en tysk sælger, foreslår vi du skriver en e-mail med følgende tekst til den tyske sælger:\n\nSehr geehrte Damen und Herren\n\nFür eine korrekte Abgaben Berechnung in Dänemark, Benötige ich die Fahrgestellnummer (VIN) des angebotenen Fahrzeuges"
  },
  {
    label: "Moms...",
    value: "Vær opmærksom på at køretøjet har kørt mindre end 6000 km og/eller er under 6 måneder gammel. Disse 2 krav skal være opfyldt hvis man vil nøjes med at betale den udlandske moms.",
  },
  {
    label: "CO2 tal...",
    value: "Vær opmærksom vi har regnet sagen med et co2 tal på under 50g. Hvis typedata viser sig at være anderledes end co2 tallet vi har beregnet efter kan afgiften blive højere. Derfor anbefaler vi, at du dobbelttjekker COC-dokumentet som følger med bilen, for at sikre dig, at typedata er korrekte.",
  },
  {
    label: "Beregnet bedst muligt...",
    value: "Køretøjet er beregnet bedst muligt ud fra det nuværende marked.",
  }
];

const ResellerTaxOfferActions: FC<TaxOfferActionsProps> = ({ loading, setLoading }) => {
  const { resellerTaxCase, setResellerTaxCase } = ResellerTaxCaseStore.useContainer();
  const [visibleOfferModal, setVisibleOfferModal] = useState<boolean>(false);

  const offerTitle = resellerTaxCase.flags.sent ? _t("send_new_offer") : _t("send", "offer");

  const sendOfferAction = async (values: SendOfferFormType) => {
    setLoading(true);
    try {
      const { data } = await sentResellerTaxOffer(resellerTaxCase.id, values);
      setResellerTaxCase(data);
      message.success(_t("offer", "sent"));
      setVisibleOfferModal(false);
    } catch ({ response }) {
      message.error(response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DividerLine weight={1.5} fluid />
      <Row align="middle" justify="space-between">
        <Col>&nbsp;</Col>
        <Col>
          <Space>
            <Button
              disabled={!resellerTaxCase.links?.offerPdfLink}
              type="primary"
              ghost
              onClick={() => openFileLink(resellerTaxCase.links?.offerPdfLink)}
            >
              <Icon name="download-outline" size="large" />
              {_t("offer")}
            </Button>
            <Button type="primary" onClick={() => setVisibleOfferModal(true)}>
              {offerTitle}
            </Button>
          </Space>
        </Col>
      </Row>

      <Modal
        title={null}
        visible={visibleOfferModal}
        destroyOnClose={true}
        footer={null}
        confirmLoading={loading}
        cancelButtonProps={{ disabled: loading }}
        onCancel={() => setVisibleOfferModal(false)}
      >
        <Form<SendOfferFormType>
          onFinish={sendOfferAction}
          initialValues={{ message: null, reservation: null }}
          layout="vertical"
        >

          <h2 className="modal-title">{offerTitle}</h2>
          <Form.Item
            name="message" label={_t("send_tax_offer_message_label")} rules={[{ required: false, whitespace: true }]}
          >
            <TextArea
              disabled={loading}
              autoSize={{ minRows: 1, maxRows: 3 }}
              placeholder={_t('send_tax_offer_placeholder')}
            />
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ setFieldsValue }) => (
              <Form.Item name="reservation" label={_t('send_tax_offer_extra_details_label')}>
                <Select
                  showSearch
                  placeholder={_t('choose')}
                  options={reservationOptions}
                  onChange={(val) => {
                    setFieldsValue({
                      message: val,
                      reservation: null,
                    });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {({ submit }) => (
              <div className="text-right">
                <Space>
                  <Button type="ghost" onClick={() => setVisibleOfferModal(false)} disabled={loading}>
                    {_t("cancel")}
                  </Button>
                  <Button onClick={submit} loading={loading} type="primary">
                    {_t("send")}
                  </Button>
                </Space>
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ResellerTaxOfferActions;
