import { FC } from "react";
import CollapseCard from "../../../components/collapseCard";
import MerchantStore from "../../../contexts/merchantStore";
import _t from "../../../lang/translate";
import MerchantCases from "./merchantCases";

const MerchantCasesCard: FC = () => {
  const { merchant, loading, isNew } = MerchantStore.useContainer();

  return (
    <CollapseCard title={_t("cases")} noPadding loading={loading} disable={isNew} defaultOpen={!isNew}>
      <MerchantCases cases={merchant?.cases} />
    </CollapseCard>
  );
};

export default MerchantCasesCard;
