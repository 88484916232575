import { UploadFile } from "antd/lib/upload/interface";
import { Client, EnumItemType, IdType, LogObj, RelatedCasesType, StatusUpdate, Vehicle, Person } from "./appTypes";
import { MerchantType } from "./merchantTypes";
import { Receipt } from "./taxTypes";
import { TransportFeeDataType } from "./systemTypes";

export interface ImportCaseListType {
  id: IdType;
  createdAt: string;
  problematic: boolean;
  dealerIsPriority: boolean;
  status: EnumItemType;
  clientName: string;
  importantNote: string | null;
  car: string | null;
  vin: string | null;
  mileage: number | null;
  firstRegDate: string | null;
  caseHandler: Person;
  expectedDeliveryDate: string | null;
  isFullService: boolean;
  flags: ImportCaseType['flags'];
  log: ImportCaseType['log'];

}

export interface DeliveryAddress {
  name: string | null;
  address: string | null;
  zip: string | null;
  city: string | null;
  phone: string | null;
  contact: string | null;
}

export interface ImportValueConfigItemType {
  value: number;
}

export interface ImportValueConfigType {
  carCheckupCompletePrice: ImportValueConfigItemType;
  expressDeliveryPrice: ImportValueConfigItemType;
  handlingFee: ImportValueConfigItemType;
  addonSuvPercentage: ImportValueConfigItemType;
  addonVanPercentage: ImportValueConfigItemType;
  invoiceFee: ImportValueConfigItemType;
}

export type ImportValueConfigKeyType = keyof ImportValueConfigType;

export interface ImportCaseCreationType {
  id: IdType;
  vehicle: Vehicle | null;
  note: string | null;
  client: Client | null;
  paymentMethodId?: IdType;
  reason?: string | null;
  files: UploadFile[] | null;
}

export interface ImportOfferType {
  isNetPriceVehicle: boolean;
  isSpecialTransportArrangement: boolean;
  vatPercentage: number | null;
  salesPrice: number | null;
  salesPriceGross: number | null;
  discount: number | null;
  discountGross: number | null;
  priceAfterDiscount: number | null;
  priceAfterDiscountGross: number | null;
  priceAfterDiscountHandling: number | null;
  priceAfterDiscountHandlingGross: number | null;
  processingFee: number | null;
  additionalTransportFee: number | null;
  transportFee: number | null;
  transportAddonPrice: number | null;
  invoiceFee: number | null;
  discountFee: number | null;
  totalFees: number | null;
  isExpressDelivery: boolean;
  isSuv: boolean;
  isVan: boolean;
  isCarStatusReport: boolean;
  totalPrice: number | null;
  autoCalculate: {
    processingFee: boolean;
    transportFee: boolean;
    transportAddonPrice: boolean;
    invoiceFee: boolean;
    discountFee: boolean;
  } | null;
  prices: {
    transportFees: TransportFeeDataType[] | null;
    carStatusReportPrice: number | null;
    expressDelivery: number | null;
    discountFeePercentage: number;
  };
}

declare const importOfferConst: ImportOfferType;
export type ImportOfferKeys = keyof typeof importOfferConst;

export interface ImportCaseType extends ImportCaseCreationType {
  relatedCases: RelatedCasesType;
  vehicle: Vehicle | null; // used in view and admin bar
  merchant: MerchantType | null; // used in admin bar
  merchantContactPersonId: IdType | null; // used in admin bar
  merchantValidationStatus?: EnumItemType | null;
  importantNote: string | null;
  order: ImportOrderType | null;
  status: StatusUpdate | null;
  caseHandlerId: IdType | null;
  collectionAuthorizationCode: string | null;
  vehicleOffer: ImportVehicleReport | null;
  receipt: Receipt | null;
  expectedDeliveryDate: string | null;
  pickupDate: string | null;
  pickupInfo: PickupInfoType | null;
  links?: {
    merchantReport: string | null;
    vehicleReport: string | null;
    offer: string | null;
    paymentAdvice: string | null;
    dealerView: string | null;
  };
  importCaseValueConfig?: ImportValueConfigType;
  flags: {
    purchaseContractSigned: boolean;
    entryCertificateSigned: boolean;
    takeHomeAgreementSigned: boolean;
    problematic: boolean;
    paid: boolean;
    vehiclePurchaseContractRequested: boolean;
    vehiclePurchaseContractSigned: boolean;
    vehiclePaid: boolean;
    pickupSelf: boolean;
    transportOrdered: boolean;
    forcedStatus: boolean;
    requested: boolean;
    offerPublished: boolean;
    offerSent: boolean;
    receipted: boolean;
    rejected: boolean;
    completed: boolean;
    fullService: boolean;
  };
  log: {
    purchaseContractSigned: LogObj | null;
    entryCertificateSigned: LogObj | null;
    takeHomeAgreementSigned: LogObj | null;
    problematic: LogObj | null;
    paid: LogObj | null;
    vehiclePurchaseContractRequested: LogObj | null;
    vehiclePurchaseContractSigned: LogObj | null;
    vehiclePaid: LogObj | null;
    transportOrdered: LogObj | null;
    pickupSelf: LogObj | null;
    rejected: LogObj | null;
    completed: LogObj | null;
    receipted: LogObj | null;
    requested: LogObj | null;
    merchantValidation: LogObj | null;
    creation: LogObj | null;
    offerSent: LogObj | null;
    offerPublished: LogObj | null;
    offerUpdated: LogObj | null;
    fullService: LogObj | null;
  };
}

export interface PickupInfoType {
  identification: string | null;
  isCompany: boolean;
  name: string | null;
  address: string | null;
  zip: string | null;
  city: string | null;
  phone: string | null;
}

export interface VehicleComingHome {
  status: boolean;
  date: string | null;
}

export interface ImportOrderType {
  deliveryAddress: DeliveryAddress | null;
  invoiceAddress: DeliveryAddress | null;
  valutaId?: IdType | null;
  valuta: string | null;
  isCarStatusReport: boolean;
  isExpressDelivery: boolean;
  bankInfo: string | null;
}

export interface ImportCaseHandlerType {
  caseHandlerId: IdType | null;
}

export interface ImportStatusUpdateType {
  forced: boolean;
  statusId: IdType;
}

export interface ImportVehicleReport {
  data: ImportVehicleData;
  service: ImportVehicleService;
  condition: ImportVehicleCondition;
  offer: ImportOfferType;
}

export interface ImportVehicleData {
  originalDocuments: string | null;
  keyAmount: string | number | null;
  ownerAmount: string | number | null;
  smokedInStatus: IdType | null;
  previousDamageStatus: IdType | null;
  availableDeliveryDate: string | null;
}

export interface ImportVehicleService {
  serviceBookStatus: IdType | null;
  folderStatus: IdType | null;
  serviceHistoryStatus: IdType | null;
  latestServiceDate: string | null;
  latestServiceMileage: number | null;
  nextServiceDate: string | null;
  nextServiceMileage: number | null;
  nextServiceContent: string | null; // optional
}

export interface ImportVehicleCondition {
  statusExterior: string | null;
  statusInterior: string | null;
  statusRims: string | null;
  statusTires: string | null;
  statusBrakes: string | null;
  statusChargingCables: string | null;
  tireType: IdType | null;
  extraTiresStatus: IdType | null;
  otherComments: string | null; // optional
}

export interface UpdateMerchantFormType {
  merchantId: IdType;
  merchantContactPersonId: IdType | null;
}

export interface UpdateImportClientType {
  typeId: IdType;
  userId?: IdType | null;
  customerId?: IdType | null;
  dealerId?: IdType | null;
}

export interface PickupDocumentFormType {
  pickupDate: string | null;
}

export interface TransportDocumentFormType extends Partial<PickupInfoType> {
  collectionAuthorizationCode: string | null;
}

export interface SendImportOfferFormType {
  withReport: boolean;
  files: IdType[];
  note: string|null;
}

export interface CompleteImportCaseType {
  expectedDeliveryDate: string | null;
}

export interface CloseImportCaseType {
  reason: string | null;
}

export const initImportCase: ImportCaseType = {
  id: 0,
  note: null,
  files: null,
  importantNote: null,
  caseHandlerId: null,
  vehicle: null,
  merchant: null,
  merchantContactPersonId: null,
  order: null,
  expectedDeliveryDate: null,
  status: null,
  pickupInfo: null,
  collectionAuthorizationCode: null,
  pickupDate: null,
  vehicleOffer: null,
  receipt: null,
  flags: {
    purchaseContractSigned: false,
    entryCertificateSigned: false,
    takeHomeAgreementSigned: false,
    problematic: false,
    paid: false,
    vehiclePurchaseContractRequested: false,
    vehiclePurchaseContractSigned: false,
    vehiclePaid: false,
    transportOrdered: false,
    pickupSelf: false,
    forcedStatus: false,
    requested: false,
    offerPublished: false,
    offerSent: false,
    receipted: false,
    rejected: false,
    completed: false,
    fullService: false,
  },
  log: {
    purchaseContractSigned: null,
    entryCertificateSigned: null,
    takeHomeAgreementSigned: null,
    problematic: null,
    paid: null,
    vehiclePurchaseContractRequested: null,
    vehiclePurchaseContractSigned: null,
    vehiclePaid: null,
    transportOrdered: null,
    pickupSelf: null,
    rejected: null,
    completed: null,
    receipted: null,
    requested: null,
    merchantValidation: null,
    creation: null,
    offerSent: null,
    offerPublished: null,
    offerUpdated: null,
    fullService: null,
  },
  client: null,
  relatedCases: {
    link: null,
    info: null,
    vin: null,
  },
};

export const importKeys = {
  vehicle: "vehicle",
  offer: "offer",
  order: "order",
  files: "files",
} as const;

export function isImportKey(key: string): key is ImportKeysType {
  return Object.values(importKeys).includes(key as ImportKeysType);
}

export type ImportKeysType = typeof importKeys[keyof typeof importKeys];

////// Admin Bar Types

export const settingsKeys = {
  closed: "",
  vehicle: "vehicle",
  merchant: "merchant",
  offer: "vehicleOffer",
  documents: "documents",
  order: "order",
} as const;

export type SettingsKeysType = typeof settingsKeys[keyof typeof settingsKeys];

export function isSettingsKey(key: string): key is SettingsKeysType {
  return key !== "" && Object.values(settingsKeys).includes(key as SettingsKeysType);
}

export const initSettingsSavedStatus = {
  vehicle: true,
  merchant: true,
  vehicleOffer: true,
};

export type ActionSaveStatusType = {
  key: SettingsKeysType;
  isMatched: boolean;
};
