import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { DealerType, initDealer } from "../types/dealerTypes";
import { convertToUploadFile, isApiFile } from "../utilities/convertTypes";
import UserStore from "./userStore";

interface DealerStoreProps {
  dealer: DealerType;
  isNew: boolean;
  setDealer: (newDealer: DealerType) => void;
  loading: boolean;
  isAdmin: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useDealerStore = (initialState?: DealerType): DealerStoreProps => {
  const [dealer, setDealer] = useState<DealerType>(initDealer);
  const [loading, setLoading] = useState<boolean>(false);
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("dealer.isAdmin");

  const handleSetDealer = useCallback(
    (newDealer: DealerType) => {
      const logoFile =
        newDealer.logoFile && isApiFile(newDealer.logoFile)
          ? convertToUploadFile(newDealer.logoFile)
          : newDealer.logoFile;
      setDealer({ ...newDealer, logoFile });
    },
    [setDealer]
  );

  useEffect(() => {
    if (initialState) {
      handleSetDealer(initialState);
    }
  }, [initialState]);

  return {
    dealer,
    setDealer: handleSetDealer,
    loading,
    setLoading,
    isAdmin,
    isNew: dealer.id === 0,
  };
};

const DealerStore = createContainer(useDealerStore);

export default DealerStore;
