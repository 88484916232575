import { Button, Timeline } from "antd";
import React, { FC } from "react";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { LoadingOutlined } from "@ant-design/icons";

interface ActivityReloadBtnProps {
  loading: boolean;
  onReload: () => void;
}

const ActivityReloadBtn: FC<ActivityReloadBtnProps> = ({
  loading,
  onReload,
}) => {
  return (
    <Timeline.Item
      dot={
        loading ? (
          <LoadingOutlined />
        ) : (
          <Icon name="refresh-circle-outline" size="huge" />
        )
      }
    >
      <Button
        type="link"
        onClick={onReload}
        className="reload-activities-btn pb-1 pt-0 pl-0"
      >
        {_t("refresh")}
      </Button>
    </Timeline.Item>
  );
};

export default ActivityReloadBtn;
