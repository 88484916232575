import React, { FC } from "react";
import { Space } from "antd";
import PurchaseContractSignedSwitch from "./purchaseContractSignedSwitch";
import EntryCertificateSignedSwitch from "./entryCertificateSignedSwitch";
import PaidSwitch from "./paidSwitch";
import PurchaseContractDownloadBtn from "../purchaseContractDownloadBtn";
import EntryCertificateDownloadBtn from "../entryCertificateDownloadBtn";
import PaymentAdviceDownloadBtn from "../paymentAdviceDownloadBtn";
import TakeHomeAgreementSignedSwitch from "./takeHomeAgreementSignedSwitch";
import TakeHomeAgreementDownloadBtn from "../takeHomeAgreementDownloadBtn";

const ImportBuyerRelation: FC = () => {
  return (
    <>
      <PurchaseContractSignedSwitch />
      <EntryCertificateSignedSwitch />
      <TakeHomeAgreementSignedSwitch />
      <PaidSwitch />
      <Space wrap>
        <PurchaseContractDownloadBtn />
        <EntryCertificateDownloadBtn />
        <TakeHomeAgreementDownloadBtn />
        <PaymentAdviceDownloadBtn />
      </Space>
    </>
  );
};

export default ImportBuyerRelation;
