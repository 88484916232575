import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";
import { IdType, ModulTypes } from "../types/appTypes";
import { ExportDealersStatsType } from "../types/dealerTypes";
import _t from "../lang/translate";
import { DateSegmentType, PersonalStatistic } from "../types/statisticsType";

const apiEndpoint = `${getApiEndpoint()}/statistics`;

export function detailStatisticsDealerUrl(id?: IdType) {
  const base = `${apiEndpoint}/detailStatistics`;
  if (id) return `${base}/${id}`;
  return base;
}

export function detailStatisticsUserUrl(id?: IdType) {
  const base = `${apiEndpoint}/userStatistics`;
  if (id) return `${base}/${id}`;
  return base;
}

export function exportDealersStats({ date, email }: ExportDealersStatsType) {
  const query = `filter[from]=${date[0]}&filter[to]=${date[1]}`;
  return http.post(`${apiEndpoint}/startDealerStatistics?${query}`, { email });
}

export function getLive(context: ModulTypes) {
  return `${apiEndpoint}/${context}/live`;
}

export function getTotalCreated(context: ModulTypes) {
  return `${apiEndpoint}/${context}/totalCreated`;
}

export function getTotalAccepted(context: ModulTypes) {
  return `${apiEndpoint}/${context}/totalAccepted`;
}

export function getActiveDealers(context: ModulTypes) {
  return `${apiEndpoint}/${context}/activeDealers`;
}

export function getTopDealers(context: ModulTypes) {
  return `${apiEndpoint}/${context}/topDealers`;
}

export function getTopSellers(context: ModulTypes) {
  return `${apiEndpoint}/${context}/topSellers`;
}

export function getRoiPoints(context: ModulTypes) {
  return `${apiEndpoint}/${context}/roiPoints`;
}

export function getLtvPercentages(context: ModulTypes) {
  return `${apiEndpoint}/${context}/ltvPercentages`;
}

export function getActiveCases(context: ModulTypes) {
  return `${apiEndpoint}/${context}/activeCases`;
}

// GET api/v1/statistics/personal
export function getStatiticsPersonal() {
  return http.get<{ data: PersonalStatistic }>(`${apiEndpoint}/personal`);
}


export const dateSegments: Array<{ label: string; key: DateSegmentType }> = [
  { label: _t("day"), key: "day" },
  { label: _t("week"), key: "week" },
  { label: _t("month"), key: "month" },
  { label: _t("year"), key: "year" },
];

export const getDateSegmentByKey = (key: DateSegmentType) => dateSegments.find((s) => s.key === key) || dateSegments[2];
