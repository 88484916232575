import { Button } from "antd";
import React, { FC } from "react";
import openModal from "../../../components/modal/openModal";
import _t from "../../../lang/translate";

const CustomersExport: FC = () => {
  const handleExport = async () => {
    try {
      const { url } = await openModal("exportCustomers", null);
      window.open(url, "_blank");
    } catch (error) {
    }
  };

  return (
    <Button className="muted-btn" onClick={handleExport}>
      {_t("export")}
    </Button>
  );
};

export default CustomersExport;
