import React, { FC } from "react";
import AppWrapper from "./appWrapper";
import "./assets/style/app.less";
import UserStore from "./contexts/userStore";
import Routes from "./routes/routes";
import CookieBot from "react-cookiebot";

const App: FC = () => {
  const domainGroupId = process.env.REACT_APP_COOKIEBOT_ID;

  return (
    <>
      <UserStore.Provider>
        <AppWrapper>
          <Routes />
        </AppWrapper>
      </UserStore.Provider>

      {domainGroupId && (
        <div hidden={true}>
          <CookieBot domainGroupId={domainGroupId} />
        </div>
      )}
    </>
  );
};

export default App;
