import { Divider, Form, Input, Switch } from "antd";
import React, { FC } from "react";
import DateSelect from "../../../../components/form/dateSelect";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";
import { brandsAutoComplete, modelsAutoComplete } from "../../../../services/autocompleteService";
import InputVin from "../../../../components/form/inputVin";
import EnumRadio from "../../../../components/form/EnumRadio";
import InputPrice from "../../../../components/form/inputPrice";
import ServerSelectBox from "../../../../components/serverSelectBox";
import ServerAutoFill from "../../../../components/serverAutoFill";
import UnmatchedValueWarning from "../../../../components/unmatchedValueWarning";


interface TaxCarFormProps {
  nested?: string[];
  disabled?: boolean;
  required?: boolean;
  hideVehicleType?: boolean;
  hideBottomDivider?: boolean;
}

const TaxVehicleForm: FC<TaxCarFormProps> = ({
  disabled,
  hideVehicleType,
  required = true,
  nested,
  hideBottomDivider
}) => {

  return (
    <>
      <Form.Item
        name={nested ? [...nested, "typeId"] : "typeId"}
        label={_t("vehicle_type")}
        rules={[{ required }]}
        hidden={!!hideVehicleType}
      >
        <EnumRadio size="small" nameKey={["vehicle", "type"]} />
      </Form.Item>
      <Form.Item
        name={nested ? [...nested, "isNew"] : "isNew"}
        label={_t("new", "vehicle") + "?"}
        valuePropName="checked"
      >
        <Switch disabled={disabled} size="small" />
      </Form.Item>
      <Form.Item shouldUpdate={(curr, next) => {
        const currData = nested ? curr.vehicle.typeId : curr.typeId;
        const nextData = nested ? next.vehicle.typeId : next.typeId;
        return currData !== nextData
      }
      } noStyle>
        {({ getFieldsValue }) => {
          const data = getFieldsValue();
          const { typeId } = nested ? data.vehicle : data;

          const url = brandsAutoComplete('', '', typeId);
          return (
            <Form.Item rules={[{ required: true }]} name={nested ? [...nested, "brandId"] : "brandId"}
              label={_t("brand")}>
              <ServerSelectBox
                apiUrl={url}
                disabled={!typeId || disabled}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate={(curr, next) => {
        const currDataBrand = nested ? curr.vehicle.brandId : curr.brandId;
        const currDataType = nested ? curr.vehicle.typeId : curr.typeId;
        const currAlias = nested ? curr.vehicle.addModelAlias : curr.addModelAlias;
        const nextDataBrand = nested ? next.vehicle.brandId : next.brandId;
        const nextDataType = nested ? next.vehicle.typeId : next.typeId;
        const nextAlias = nested ? next.vehicle.addModelAlias : next.addModelAlias;
        return (currDataBrand !== nextDataBrand ||
          currDataType !== nextDataType ||
          currAlias !== nextAlias)
      }} noStyle>
        {({ getFieldsValue }) => {
          const data = getFieldsValue();
          const { brandId, typeId, addModelAlias } = nested ? data.vehicle : data;

          const url = modelsAutoComplete('', brandId, '', typeId);
          return (
            <Form.Item
              help={addModelAlias && <UnmatchedValueWarning unMatchedValue={addModelAlias} />}
              rules={[{ required: true }]}
              name={nested ? [...nested, "model"] : "model"} label={_t("model")}>
              <ServerAutoFill
                apiUrl={url}
                disabled={!brandId || !typeId || disabled}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item name={nested ? [...nested, "addModelAlias"] : "addModelAlias"} hidden={true}>
        <Input />
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldsValue }) => {
          const data = getFieldsValue();
          const { addVariantAlias } = nested ? data.vehicle : data;

          return (
            <Form.Item
              name={nested ? [...nested, "equipmentVariant"] : "equipmentVariant"}
              label={_t("equipment_variant")}
              rules={[{ required }]}
              help={addVariantAlias && <UnmatchedValueWarning unMatchedValue={addVariantAlias} />}
            >
              <Input disabled={disabled} />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item name={nested ? [...nested, "addVariantAlias"] : "addVariantAlias"} hidden={true}>
        <Input />
      </Form.Item>

      <Form.Item
        name={nested ? [...nested, "modelDesignation"] : "modelDesignation"}
        label={_t("model_designation")}
      >
        <Input disabled={disabled} />
      </Form.Item>
      <Form.Item
        name={nested ? [...nested, "modelPeriod"] : "modelPeriod"}
        label={_t("model_period")}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        name={nested ? [...nested, "firstRegDate"] : "firstRegDate"}
        label={_t("firstRegDate")}
        rules={[{ required }]}
      >
        <DateSelect disabled={disabled} />
      </Form.Item>

      <Form.Item name={nested ? [...nested, "vin"] : "vin"} label={_t("vin")}>
        <InputVin disabled={disabled} />
      </Form.Item>

      <Form.Item name={nested ? [...nested, "mileage"] : "mileage"} label={_t("mileage")} rules={[{ required }]}>
        <InputNum disabled={disabled} step={1000} min={0} suffix={_t("mileage_unit")} />
      </Form.Item>

      <Divider className="mt-1 mb-1" />

      <Form.Item name={nested ? [...nested, "engineSize"] : "engineSize"} label={_t("engine_size")}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item name={nested ? [...nested, "horsePower"] : "horsePower"} label={_t("engine_power")}>
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item name={nested ? [...nested, "gearboxId"] : "gearboxId"} rules={[{ required }]} label={_t("gearbox")}>
        <EnumRadio size="small" disabled={disabled} nameKey={["vehicle", "gearbox"]} />
      </Form.Item>
      <Form.Item name={nested ? [...nested, "bodyId"] : "bodyId"} rules={[{ required }]} label={_t("trim")}>
        <EnumRadio size="small" disabled={disabled} nameKey={["vehicle", "body"]} />
      </Form.Item>

      <Form.Item
        name={nested ? [...nested, "qualityId"] : "qualityId"}
        label={_t("car_condition")}
        rules={[{ required }]}
      >
        <EnumRadio size="small" defaultActiveFirstOption disabled={disabled} nameKey={["vehicle", "quality"]} />
      </Form.Item>
      <Form.Item
        name={nested ? [...nested, "useCaseId"] : "useCaseId"}
        label={_t("special_use")}
        rules={[{ required }]}
      >
        <EnumRadio size="small" defaultActiveFirstOption disabled={disabled} nameKey={["vehicle", "useCase"]} />
      </Form.Item>

      <Divider className="mt-1 mb-1" />

      <Form.Item name={nested ? [...nested, "fuelId"] : "fuelId"} rules={[{ required }]} label={_t("fuel_type")}>
        <EnumRadio size="small" disabled={disabled} nameKey={["vehicle", "fuel"]} />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) =>
          getFieldValue(nested ? [...nested, "typeId"] : "typeId") !== 2 ? null : (
            <Form.Item
              name={nested ? [...nested, "isHighWeight"] : "isHighWeight"}
              label={_t("over_3000_kg")}
              valuePropName="checked"
            >
              <Switch disabled={disabled} size="small" />
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item name={nested ? [...nested, "emissionAmount"] : "emissionAmount"} label={_t("co2_emission")}>
        <InputPrice decimalSize={2} suffix={_t("emission_unit")} maxLength={6} />
      </Form.Item>
      <Form.Item
        rules={[{ required }]}
        name={nested ? [...nested, "emissionUnitId"] : "emissionUnitId"}
        label={_t("co2_measurement")}
      >
        <EnumRadio size="small" nameKey={["vehicle", "emissionUnit"]} />
      </Form.Item>
      <Form.Item
        name={nested ? [...nested, "batteryCapacity"] : "batteryCapacity"}
        label={_t("battery_capacity")}
      >
        <InputPrice suffix={_t("battery_unit")} max={1000} />
      </Form.Item>
      <Form.Item name={nested ? [...nested, "electricConsumption"] : "electricConsumption"} label={_t("battery_usage")}>
        <InputPrice suffix={_t("battery_usage_unit")} max={1000} />
      </Form.Item>
      <Form.Item name={nested ? [...nested, "electricRange"] : "electricRange"} label={_t("battery_range")}>
        <InputNum suffix={_t("mileage_unit")} maxLength={4} />
      </Form.Item>
      {!hideBottomDivider && (
        <Divider />
      )}
    </>
  );
};

export default TaxVehicleForm;
