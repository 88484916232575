import React, { FC, useEffect, useState } from "react";
import moment from "moment";
import _t from "../lang/translate";
import calculateDiff from "../utilities/calcDateDiff";
import StatusTag, { StatusTagProps } from "./statusTag";

interface StatusCountDownProps extends StatusTagProps {
  durationMinutes?: number;
  startDate: string;
  prefix?: string;
}

const StatusCountDown: FC<StatusCountDownProps> = ({
  startDate,
  status,
  durationMinutes = 30,
  prefix = _t("new"),
  ...rest
}) => {
  const [duration, setDuration] = useState<number>(calculateDiff(startDate, durationMinutes));
  const interval = 1000;

  useEffect(() => {
    let timerId: any;
    timerId = setInterval(() => {
      setDuration(calculateDiff(startDate, durationMinutes));
    }, interval);

    return () => clearInterval(timerId);
  }, [setDuration]);

  const isNeg = Math.sign(duration) < 0;

  const nextStatus: typeof status = {
    id: status?.id || 0,
    title: `${prefix} ${isNeg ? "-" : ""}${msToTime(duration)}`,
    color: isNeg ? "#f00" : status?.color,
  };

  return (
    <StatusTag solid={isNeg} status={nextStatus} {...rest}>
      {prefix}
    </StatusTag>
  );
};

export default StatusCountDown;

function msToTime(duration: number) {
  const momentDuration = moment.duration(Math.abs(duration));
  const hours = Math.floor(momentDuration.asHours());
  const minutes = Math.floor(momentDuration.minutes());
  const seconds = Math.floor(momentDuration.seconds());

  const hoursStr = hours < 10 ? "0" + hours : hours,
    minutesStr = minutes < 10 ? "0" + minutes : minutes,
    secondsStr = seconds < 10 ? "0" + seconds : seconds;

  return hoursStr + ":" + minutesStr + ":" + secondsStr;
}
