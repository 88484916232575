import { Button, Modal } from "antd";
import { FC, useState } from "react";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import DealerClipsLog from "./dealerClipsLog";
import DealerClipUsageFooter from "./dealerClipUsageFooter";

const DealerAdminClipOverview: FC = () => {
  const { dealer } = DealerStore.useContainer();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  return (
    <>
      <Button onClick={openModal}>{_t("clip", "usage")}</Button>
      <Modal
        width={800}
        visible={isVisible}
        onCancel={closeModal}
        destroyOnClose
        title={_t("clip", "usage")}
        bodyStyle={{ padding: 0 }}
        footer={<DealerClipUsageFooter dealerId={dealer.id} onCancel={closeModal} />}
      >
        <DealerClipsLog dealerId={dealer.id} />
      </Modal>
    </>
  );
};

export default DealerAdminClipOverview;
