import { Space } from "antd";
import { FC } from "react";
import Details from "../../../../components/detailsList";
import _t from "../../../../lang/translate";
import { MotorAgencyDataType, VehicleInsuraceType } from "../../../../types/leasingTypes";
import format from "../../../../utilities/formatNumbers";

interface MotorAgencyReportInfoProps {
  report?: MotorAgencyDataType | null;
}

const MotorAgencyReportInfo: FC<MotorAgencyReportInfoProps> = ({ report }) => {
  const { registrationNumber, leasingStartDate, leasingEndDate, insurance, nextCheckupDate, createdAt, id } =
    report || {};

  return (
    <Details borderLess>
      <Details.Item label={_t("registration_number_short")} value={format.text(registrationNumber)} />
      <Details.Item
        label={_t("leasing_contract_period")}
        value={`${format.date(leasingStartDate)} ${_t("to")} ${format.date(leasingEndDate)}`}
      />
      <Details.Item label={_t("insurance")} value={insurance?.companyName || "-"} />
      <Details.Item label={_t("insurance", "status")} value={insuranceStatus(insurance)} />
      <Details.Item label={_t("next_checkup_date")} value={format.date(nextCheckupDate)} />
      <Details.Item label={_t("latest_dmr_update")} value={getLatestUpdateValue(report)} />
      <Details.Divider />
      <Details.Item label={_t("updated_at")} value={format.date(createdAt)} />
    </Details>
  );
};

export default MotorAgencyReportInfo;

const insuranceStatus = (insurance?: VehicleInsuraceType | null) => {
  if (!insurance || insurance.active === null) return "-";
  return (
    <Space split=" - ">
      {_t(insurance?.active ? "active" : "inactive")}
      {!!insurance?.date && `${_t("created_at")} ${format.date(insurance.date)}`}
    </Space>
  );
};

const getLatestUpdateValue = (data?: MotorAgencyDataType | null) => {
  const { registered, latestUpdateInAgency } = data || {};
  let result = typeof registered === "boolean" ? `${_t(registered ? "registered_at" : "unsubscribed_at")} - ` : "";
  result += latestUpdateInAgency ? format.date(latestUpdateInAgency) : "";

  return result === "" ? "-" : result;
};
