import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";
import { MotorAgencyDataType } from "../types/leasingTypes";

const apiEndpoint = `${getApiEndpoint()}/motorAgencyReport`;

export const motorAgencyReportUrl = (vin: string) => `${getApiEndpoint()}/motorAgencyReport/${vin}`;

export const getMotorAgencyReport = (vin: string) => {
  return http.get<MotorAgencyDataType>(`${motorAgencyReportUrl(vin)}/fetch`);
};

export const saveMotorAgencyReport = (vin: string, data: MotorAgencyDataType) => {
  return http.post<MotorAgencyDataType>(`${motorAgencyReportUrl(vin)}`, data);
};

export const getMotorAgencyReports = (vin: string) => {
  return http.get<MotorAgencyDataType[]>(motorAgencyReportUrl(vin));
};

export const getMotorAgencyReportById = (id: string) => {
  return http.get(`${apiEndpoint}/${id}`);
};

export const updateMotorAgencyReport = (id: string, data: MotorAgencyDataType) => {
  return http.put(`${apiEndpoint}/${id}`, data);
};

export const deleteMotorAgencyReport = (id: string) => {
  return http.delete(`${apiEndpoint}/${id}`);
};
