import { LeasingCalcVersionType } from "./leasingCalcTypes";

// Whenever you have a new version just add it to array, if a version needs to be expired just remove it:
// version structure: YYYYMMDD(n)
export const leasingCalcVersions = [
  2021071301, 2022022801, 2022033001, 2022041201, 2023090101, 2024011001
] as const;

export const latestVersion = Math.max(
  ...leasingCalcVersions
) as LeasingCalcVersionType;
export const isLatestVersion = (version: LeasingCalcVersionType) =>
  version === latestVersion;
export const isSupportedVersion = (version: number) =>
  leasingCalcVersions.includes(version as LeasingCalcVersionType);
