import { FC } from "react";
import { Form } from "antd";
import _t from "../../../lang/translate";
import { ContactPersonType } from "../../../types/appTypes";
import { ContactPersonsKeyType } from "../../../types/leasingTypes";
import ContactPersonSelectField from "./contactPersonSelectField";

interface ContactPersonFormItemProps {
  formKey: ContactPersonsKeyType;
}

const ContactPersonSelect: FC<ContactPersonFormItemProps> = ({ formKey }) => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, setFieldsValue }) => {
        const customerId = getFieldValue(["customer", "id"]);
        if (!customerId) return null;

        const handleFetched = (cp: ContactPersonType) => {
          setFieldsValue({ [formKey]: cp });
        };
        return (
          <Form.Item name={[formKey, "id"]} noStyle>
            <ContactPersonSelectField
              customerId={customerId}
              onFetched={handleFetched}
              className="w-100 mb-1"
              placeholder={_t("choose", "contact_person")}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default ContactPersonSelect;
