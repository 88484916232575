import React, { FC } from "react";
import { Alert, Col, Row } from "antd";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import TaxDocAttachments from "../taxDocAttachments";
import TaxDocEvaluation from "./taxDocEvaluation";
import TaxDocProcess from "./taxDocProcess";
import TaxDocVehicleView from "./taxDocVehicleView";

const TaxDocCaseView: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();

  return (
    <Row align="top" gutter={24}>
      <Col xxl={16} xl={12} xs={24}>
        <Row gutter={24}>
          <Col xxl={{ span: 12, order: 1 }} xl={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }}>
            <TaxDocVehicleView />
          </Col>
          <Col xxl={{ span: 12, order: 2 }} xl={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}>
            <TaxDocEvaluation />
          </Col>
        </Row>
      </Col>
      <Col xxl={8} xl={12} xs={24}>
        {taxDocCase.importantNote && (
          <Alert className="mb-2" message={taxDocCase.importantNote} type="warning" closable />
        )}
        <TaxDocProcess />
        <TaxDocAttachments />
      </Col>
    </Row>
  );
};

export default TaxDocCaseView;
