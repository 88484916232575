import React, { FC, useState } from "react";
import { Button, message } from "antd";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { autoFetchMobileDe } from "../../../../services/importService";
import { isAxiosError } from "../../../../utilities/typeGuard";

const ImportAutoFetchMobileDe: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const handleAttemptAutoFetch = async () => {
    try {
      setLoading(true);
      const { data } = await autoFetchMobileDe(importCase.id);
      setImportCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button loading={loading} onClick={handleAttemptAutoFetch}>
      Læs fra link
    </Button>
  );
};

export default ImportAutoFetchMobileDe;
