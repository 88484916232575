import React, { FC, ReactNode, useRef } from "react";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib/input-number";

export interface InputPriceProps extends InputNumberProps {
  className?: string;
  min?: number;
  suffix?: ReactNode;
}

const locale = "da-DK";

const InputNum: FC<InputPriceProps> = ({ suffix, className, min, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const extraRef = useRef<HTMLSpanElement>(null);

  const hasMinus = typeof min === "undefined" || (min && min < 0);

  const numFormatter = (value?: number | string) => {
    if (!value) return "";
    if (hasMinus && value === "-") return "-";
    const res = new Intl.NumberFormat(locale).format(Number(value));
    return res.split(",")[0];
  };

  const numParser = (val?: string) => {
    if (!val || (typeof val === "string" && !val.length)) return "";
    let minus = "";

    if (hasMinus && (val.startsWith("-") || val === "0-")) {
      minus = "-";
      if (val.length === 1) return minus;
    }

    try {
      val = val.split(",")[0];
      val = val.replace(/[^0-9]/g, "");
      return `${minus}${val.length ? val : ""}`;
    } catch (error) {
      console.error(error);
      return "";
    }
  };

  const inputElement = () => {
    const paddingRight = extraRef.current?.offsetWidth ? extraRef.current?.offsetWidth + 8 : "1rem";
    return (
      <InputNumber
        {...rest}
        className={`no-steps-btns ${className ? className : ""}`}
        style={{
          width: 400,
          paddingRight,
        }}
        min={min}
        formatter={numFormatter}
        parser={numParser}
        ref={inputRef}
      />
    );
  };

  return !suffix ? (
    inputElement()
  ) : (
    <div className="input-number-wp">
      {inputElement()}
      <span ref={extraRef} onClick={() => inputRef.current?.focus()} className="input-number-extra">
        {suffix}
      </span>
    </div>
  );
  /*   return (
    <InputNumber
      {...rest}
      className={`${className} no-steps-btns`}
      style={{
        width: 400,
        marginRight: "1rem",
      }}
      min={min}
      formatter={numFormatter}
      parser={numParser}
    />
  ); */
};

export default InputNum;
