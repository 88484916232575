import { Button, message } from "antd";
import { FC } from "react";
import openModal from "../../../../components/modal/openModal";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";

const SendCreditPortalLink: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();

  const handleCreditPortalLink = async () => {
    try {
      await openModal("sendCreditPortalLink", { id: leasingCase.id });
      message.success(_t("sent"));
    } catch (error) {
    }
  };

  return <Button onClick={handleCreditPortalLink}>{_t("send_credit_portal_link")}</Button>;
};

export default SendCreditPortalLink;
