import React, { ReactNode } from "react";
import { Button, Space } from "antd";
import Icon from "../../assets/icons/icon";
import { CustomColumnsType, OnColumnsChange, OnHideChange, OnSortChange } from "./dataTableTypes";
import SortColumns from "./sortColumns";

interface TableActionsProps<T> {
  columns: CustomColumnsType<T>;
  onColumnsChange: OnColumnsChange<T>;
  filter?: ReactNode;
  onRefresh?: () => void;
  onExport?: () => void;
}

export default function TableActions<T extends Object>({
  columns,
  onColumnsChange,
  filter,
  onRefresh,
  onExport,
}: TableActionsProps<T>) {
  const handleHideChange: OnHideChange = (checked, key) => {
    const updatedColumns = columns.map((col) => (col.key === key ? { ...col, hidden: checked } : col));
    onColumnsChange(updatedColumns);
  };

  const handleSortChange: OnSortChange<T> = (updatedColumns) => {
    onColumnsChange(updatedColumns);
  };

  return (
    <div className="table-filter">
      <Space>
        <span className="table-filter-extra">{filter}</span>
        {onRefresh && (
          <Button type="text" onClick={onRefresh} shape="circle">
            <Icon name="refresh-outline" size="large" />
          </Button>
        )}
        {onExport && (
          <Button type="text" onClick={onRefresh} shape="circle">
            <Icon name="download-outline" size="large" />
          </Button>
        )}
        <SortColumns<T> onSort={handleSortChange} columns={columns} onHideClick={handleHideChange} />
      </Space>
    </div>
  );
}
