import { Button, Form, message, Space } from "antd";
import _t from "../../lang/translate";
import { useEnums } from "../../components/useEnum";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { customersAutoComplete, dealersAutoComplete, usersAutoComplete } from "../../services/autocompleteService";
import ServerSelectBox from "../serverSelectBox";
import EnumRadio from "../form/EnumRadio";
import consts from "../../utilities/consts";
import { LeasingInitCaseType } from "../../types/leasingTypes";
import { ChangeClientType } from "../../types/appTypes";
import { isAxiosError } from "../../utilities/typeGuard";

export default function LeasingInitClient<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<ChangeClientType>();
  const { client, typeId: leasingTypeId } = payload as LeasingInitCaseType;

  const handleSave = async (values: ChangeClientType) => {
    try {
      const results = { ...payload.client, ...values } as T;
      onSuccess(results);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    }
  };

  const [clientType] = useEnums(["client", "type"]);

  return (
    <Form form={tmpForm} initialValues={client} requiredMark={false} layout="vertical" onFinish={handleSave}>
      <h2 className="modal-title">{_t("choose", "customer")}</h2>
      <Form.Item noStyle shouldUpdate={(curr, next) => curr.typeId !== next.typeId}>
        {({ setFieldsValue }) => {
          return (
            <Form.Item {...consts.formItemLabellessProps} name={"typeId"}>
              <EnumRadio
                nameKey={["client", "type"]}
                onChange={(e) => setFieldsValue({ calculationTypeId: e.target.value })}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.typeId !== curr.typeId}>
        {({ getFieldValue }) => {
          const typeId = getFieldValue("typeId");
          return (
            clientType &&
            typeId === clientType[0]?.id && (
              <Form.Item {...consts.formItemLabellessProps} name={"dealerId"} rules={[{ required: true }]}>
                <ServerSelectBox placeholder={_t("choose", "dealer")} apiUrl={dealersAutoComplete()} />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.dealerId !== curr.dealerId || prev.typeId !== curr.typeId}>
        {({ getFieldValue }) => {
          const dealerId = getFieldValue("dealerId");
          const typeId = getFieldValue("typeId");

          return (
            clientType &&
            typeId === clientType[0]?.id && (
              <Form.Item {...consts.formItemLabellessProps} name={"userId"} rules={[{ required: true }]}>
                <ServerSelectBox
                  apiUrl={usersAutoComplete()}
                  disabled={!dealerId}
                  placeholder={_t("choose", "user")}
                  filters={dealerId ? `filter[dealerId]=${dealerId}` : undefined}
                />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prev, curr) => prev.typeId !== curr.typeId}>
        {({ getFieldValue }) => {
          const typeId = getFieldValue("typeId");
          return (
            clientType &&
            typeId === clientType[1]?.id && (
              <Form.Item {...consts.formItemLabellessProps} name={"customerId"} rules={[{ required: true }]}>
                <ServerSelectBox
                  apiUrl={customersAutoComplete()}
                  filters={`filter[typeId]=${leasingTypeId}`}
                  placeholder={_t("choose", "customer")}
                />
              </Form.Item>
            )
          );
        }}
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" type="primary">
            {_t("create")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(LeasingInitClient);
