import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import React, { FC } from "react";

interface InputCPRProps extends InputProps {
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputCPR: FC<InputCPRProps> = ({ value, onChange, ...rest }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value?.replace("-", "");
    event.target.value = value;
    onChange && onChange(event);
  };

  return (
    <Input
      value={formatCPR(value || "")}
      onChange={handleChange}
      maxLength={11}
      {...rest}
    />
  );
};

export default InputCPR;

const formatCPR = (value: string | number) => {
  const indexPosition = 6;
  const newValue = value.toString().replace(/[^0-9]/g, "");
  const results =
    newValue.length > indexPosition
      ? newValue.slice(0, indexPosition) + "-" + newValue.slice(indexPosition)
      : newValue;
  return results;
};
