import React, { FC, useState } from "react";
import _t from "../../../../../../lang/translate";
import format from "../../../../../../utilities/formatNumbers";
import Details from "../../../../../../components/detailsList";
import {
    TaxEstimationDisplayOutput, TaxEstimationRobotData
} from "../../../../../../types/taxEstimationTypes";
import Icon from "../../../../../../assets/icons/icon";
import { Button } from "antd";

type Props = {
    output: TaxEstimationDisplayOutput['newPrice'];
    eurofactor: TaxEstimationRobotData['resolvedValues']['euroFactor'];
    openEquipmentModal: () => void;
}

const TaxEstimationNewPriceOutput = ({ output, eurofactor, openEquipmentModal }: Props) => {

    return (
        <Details borderLess>
            <Details.Item
                label={_t("new_price", 'without_equipments')}
                value={format.price(output.newPriceWithoutEquipment)}
                color="secondary"
            />
            <Details.Item
                label={<>
                    {_t("equipment_new_price")}
                    <Button
                        size="small"
                        type="text"
                        shape="circle"
                        tabIndex={-1}
                        className="muted no-bg ml-05"
                        onClick={openEquipmentModal}
                    >
                        <Icon name="information-circle" className="v-align-top" />
                        <span className="v-align-middle ml-03">
                            {eurofactor} € {_t('factor')}
                        </span>
                    </Button>
                </>}
                value={format.price(output.equipmentPrice)}
                color="secondary"
            />
            <Details.Item
                label={_t("new_price")}
                value={format.price(output.totalSalesPrice)}
                bold
            />
        </Details>
    );
};
export default TaxEstimationNewPriceOutput
