import { PaymentDetails } from "../types/paymentCardType";
import consts from "./consts";

// enum
export function wasPaidWithInvoice(paymentDetails: PaymentDetails | null): boolean {
    return paymentDetails?.method.id === consts.paymentMethodId.invoice;
}
export function wasPaidWithQuickpay(paymentDetails: PaymentDetails | null): boolean {
    return paymentDetails?.method.id === consts.paymentMethodId.quickpay;
}
export function wasPaidWithStripe(paymentDetails: PaymentDetails | null): boolean {
    return paymentDetails?.method.id === consts.paymentMethodId.stripe;
}
