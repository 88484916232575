import { Alert } from "antd";
import React from "react";
import _t from "../lang/translate";
import { LinkType } from "../types/appTypes";
import ServerLink from "./serverLink";

interface Props {
  linkedCase: LinkType;
}

const RelatedCaseAlert = ({ linkedCase }: Props) => {

  const description = (
    <p className="mb-0">
      {_t('case_is_bound_to_mk_case')}:&nbsp;
      <ServerLink link={linkedCase} target="_blank" />
    </p>
  );

  return (
    <Alert
      message={_t("reseller-tax-case")}
      showIcon
      description={description}
      type="info"
      className="mb-2"
      closable
    />
  );

};

export default RelatedCaseAlert;
