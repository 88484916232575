import { UploadFile } from "antd/lib/upload/interface";
import { EnumItemType, IdType, ProductType } from "./appTypes";
import { PaymentCardType } from "./paymentCardType";
import { UserType } from "./userTypes";
import { PaymentMethodDetailsType, SubscriptionType } from "./subscriptionTypes";

export interface DealerLevelType {
  cvrRate: number | null;
  level: string | null;
  levelId: IdType | null;
  forced: boolean;
  isPriority: boolean;
  autoStatus?: boolean;
  note: string | null;
  noteExpiresAt: string | null;
}

export interface BillingDestinationType {
  id: IdType;
  cvr: number | null;
  title: string | null;
  email: string | null;
}

export interface DepartmentType {
  id: IdType;
  title: string | null;
  isPrimary?: boolean;

  invoiceEmail: string | null;
}

export interface DealerProductStatusesType {
  tax: boolean;
  taxDoc: boolean;
  leasing: boolean;
  partnerLeasing: boolean;
  import: boolean;
}

interface DealerCommonType {
  id: IdType;
  name: string | null;
  email: string | null;
  phone: string | null;
  address: string | null;
  zip: string | null;
  city: string | null;
  cvr: null | number;
  prospectId: string | null;
  contactPerson: string | null;
  contactPersonLeasing: string | null;
}

export interface DealerListItemType extends DealerCommonType {
  status?: EnumItemType | null;
  clipAmount: number;
  creditAmount: number;
  productStatuses: DealerProductStatusesType | null;
  productPriorities: DealerProductStatusesType | null;
  userCount?: number;
  taxPaymentTerm: string | null;
}

export interface DealerAdminType {
  settings: DealerSettingsType;
  bonusAmount: number;
  clipAmount: number;
  creditsLeft: number;
  economicSyncedAt: string | null;
  products: DealerAdminProductType[];
}

export interface DealerSettingsType {
  inactive: boolean;
  inactiveAt?: string | null;
  suspended: boolean;
  suspendedAt?: string | null;
  accountingItemsEnabled: boolean;
  leasingEnabled: boolean;
  categoryIds: IdType[] | null;
  categories: string[] | null;
}

export interface DealerAdminProductType {
  title: string;
  context: ProductType;
  active: boolean;
  level: DealerLevelType;
}

export interface DealerProfileType extends DealerCommonType {
  bookKeepingEmail: string | null;
  logoFile?: UploadFile | null;
  departments: DepartmentType[] | null;
  billingDestinations: BillingDestinationType[] | null;
}

export interface DealerType extends DealerCommonType {
  bookKeepingEmail: string | null;
  escalationEmail: string | null;
  logoFile?: UploadFile | null;
  logoPositionId: IdType | null;
  users: UserType[] | null;
  paymentCards: PaymentCardType[] | null;
  subscription: SubscriptionType | null;
  upcomingSubscription: SubscriptionType | null;
  paymentMethod: PaymentMethodDetailsType
}

export const initProductStatus: DealerProductStatusesType = {
  tax: false,
  taxDoc: false,
  leasing: false,
  partnerLeasing: false,
  import: false,
};

export const initDealer: DealerType = {
  id: 0,
  name: null,
  email: null,
  phone: null,
  address: null,
  cvr: null,
  prospectId: null,
  contactPerson: null,
  contactPersonLeasing: null,
  bookKeepingEmail: null,
  escalationEmail: null,
  zip: null,
  city: null,
  logoFile: null,
  logoPositionId: null,
  paymentCards: [],
  users: null,
  subscription: null,
  upcomingSubscription: null,
  paymentMethod: {
    type: null,
    lastFour: null,
  }
};

export const initDealerLevel: DealerLevelType = {
  cvrRate: 0,
  level: null,
  isPriority: false,
  levelId: null,
  forced: false,
  note: null,
  noteExpiresAt: null,
};

export const initDealerAdmin: DealerAdminType = {
  settings: {
    suspended: false,
    suspendedAt: null,
    inactive: false,
    inactiveAt: null,
    accountingItemsEnabled: false,
    leasingEnabled: false,
    categoryIds: null,
    categories: null,
  },
  products: [],
  economicSyncedAt: null,
  clipAmount: 0,
  creditsLeft: 0,
  bonusAmount: 0,
};

export interface ExportDealersType {
  active: boolean;
  inactive: boolean;
  disabled: boolean;
}

export interface ExportDealersStatsType {
  date: [string, string];
  email: string;
}
