import { UserSystemType } from "./authService";

/** Boot up / initialize Intercom Messenger Service
 * https://developers.intercom.com/installing-intercom/web/methods/#intercomboot-intercomsettings
 */
export const initializeIntercomService = (user: UserSystemType) => {
    if (typeof (window as any).Intercom === 'function') {
        (window as any).Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: process.env.REACT_APP_INTERCOM_APP_ID,
            name: user.name, // Full name
            email: user.email, // the email for your user
        })
    }
}

/**
 * Update and display new messages from Intercome Messenger Service
 * https://developers.intercom.com/installing-intercom/web/methods/#intercomupdate
 */
export const updateIntercomService = () => {
    if (typeof (window as any).Intercom === 'function') {
        (window as any).Intercom("update");
    }
}
