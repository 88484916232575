import { Col, Form, Row } from "antd";
import { FC } from "react";
import InputPrice from "../../../components/form/inputPrice";
import DividerLine from "../../../components/lineSplit";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";
import getLimits from "../../../utilities/getLimits";
import { isPayoutInLimit, isRestValueInLimit } from "./calc/leasingCalc";
import { LeasingDealType } from "./calc/leasingCalcTypes";

interface LeasingLimitsFieldsProps {
  inLimitStatus: boolean;
  onInLimitStatusChange: (s: boolean) => void;
}

const LeasingLimitsFields: FC<LeasingLimitsFieldsProps> = ({ onInLimitStatusChange, inLimitStatus }) => {
  const { leasingCase } = LeasingStore.useContainer();
  const { flags } = leasingCase;
  const { readonly, inputLocked } = flags;
  const disabled = readonly || inputLocked;

  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldsValue }) => {
        const deal: LeasingDealType = getFieldsValue(true);
        const { wishedPayoutMin, wishedPayoutMax, wishedRestValueMin, wishedRestValueMax } = deal?.output.limits || {};

        const totalPayout = (deal.input.wishedDeposit || 0) + (deal.input.wishedPayout || 0);
        const isUnlimitedValues = deal?.input.isUnlimitedValues;
        const payoutInLimit = isPayoutInLimit(deal);
        const restValueInLimit = isRestValueInLimit(deal);
        const inLimit = payoutInLimit && restValueInLimit;
        if (inLimit !== inLimitStatus) {
          onInLimitStatusChange(inLimit);
        }

        return (
          <>
            <Form.Item name={["input", "wishedDeposit"]} label={_t("deposit")}>
              <InputPrice disabled={disabled} />
            </Form.Item>

            <Form.Item name={["input", "wishedPayout"]} label={_t("wished", "first_time_payment")}>
              <InputPrice disabled={disabled} />
            </Form.Item>

            {!isUnlimitedValues && (
              <Form.Item label={_t("self_financing")}>
                <Row justify="space-between" className={!payoutInLimit ? "text-danger" : ""}>
                  <Col className="text-bold">{format.price(totalPayout)}</Col>
                  <Col>{getLimits(wishedPayoutMin, wishedPayoutMax)}</Col>
                </Row>
              </Form.Item>
            )}
            <DividerLine />
            <Form.Item name={["input", "wishedRestValue"]} label={_t("wished", "restvalue")}>
              <InputPrice
                disabled={disabled}
                min={0}
                suffix={getLimits(wishedRestValueMin, wishedRestValueMax)}
                style={!restValueInLimit ? { borderColor: "red", color: "red" } : {}}
              />
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );
};

export default LeasingLimitsFields;
