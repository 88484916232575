import {
  LimitsOutputType,
  MonthlyPaymentOutputType,
  PayoutOutputType,
  PeriodTaxOutputType,
  RestValueOutputType,
} from "./leasingCalcTypes";

export const initLimits: LimitsOutputType = {
  wishedRestValueMin: null,
  wishedRestValueMax: null,
  wishedPayoutMin: null,
  wishedPayoutMax: null,
};

export const initMonthlyPayment: MonthlyPaymentOutputType = {
  taxation: null,
  vatDeduction: null,
  total: 0,
  vat: 0,
  totalNoVat: 0,
};

export const initPayout: PayoutOutputType = {
  total: 0,
  recomended: 0,
  selfFinancing: 0,
  totalNoVat: 0,
  vat: 0,
};

export const initRestValue: RestValueOutputType = {
  total: 0,
  recomended: 0,
  deductionMonthly: 0,
  deductionAnnually: 0,
  vat: 0,
  totalNoVat: 0,
};

export const initPeriodTax: PeriodTaxOutputType = {
  valueLoss: 0,
  restTaxTotal: 0,
  interestOfRestTax: 0,
  total: 0,
  monthlyPayment: 0,
};
