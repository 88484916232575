import React, { FC, useState } from "react";
import { message } from "antd";
import { downloadAllFiles, resellerTaxFileUrl } from "../../../services/resellerTaxService";
import DocumentsUpload from "../../../components/documentsUpload";
import ResellerTaxCaseStore from "../../../contexts/resellerTaxCaseStore";
import _t from "../../../lang/translate";
import CollapseCard from "../../../components/collapseCard";
import { isAxiosError } from "../../../utilities/typeGuard";

const ResellerTaxAttachments: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { resellerTaxCase, setResellerTaxCase, isAdmin } = ResellerTaxCaseStore.useContainer();

    const handleDownloadAll = async () => {
        try {
            setLoading(true);
            const { data } = await downloadAllFiles(resellerTaxCase.id);
            if (data?.url) window.open(data.url, "_blank");
        } catch (error) {
            const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
            message.error(errorMessage || _t("msg.unknown_error"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <CollapseCard title={_t("attachments")} loading={loading}>
            <DocumentsUpload
                onDownloadAll={handleDownloadAll}
                fileList={resellerTaxCase.files || []}
                canLockFiles={isAdmin}
                onChange={({ fileList }) => {
                    setResellerTaxCase({ ...resellerTaxCase, files: fileList });
                }}
                action={resellerTaxFileUrl(resellerTaxCase?.id)}
            />
        </CollapseCard>
    );
};

export default ResellerTaxAttachments;
