import { Switch } from "antd";
import { SwitchProps } from "antd/lib/switch";
import { FC } from "react";

interface SwitchBoxProps extends SwitchProps {
  value?: boolean | null;
  onChange?: (value: boolean | null, e: MouseEvent) => void;
  size?: never;
}

const SwitchBox: FC<SwitchBoxProps> = ({ value = null, onChange, className, ...rest }) => {
  const handleChange = (checked: boolean | null, e: MouseEvent) => {
    if (value === false && checked) {
      checked = null;
    }
    onChange && onChange(checked, e);
  };
  return (
    <Switch
      checked={value || false}
      onChange={handleChange}
      className={`${value === null ? "ant-switch-null" : ""} ${className || ""}`}
      {...rest}
    />
  );
};

export default SwitchBox;
