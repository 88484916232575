import { Empty } from "antd";
import React, { FC } from "react";
import PageCard, { PageCardProps } from "../../../layout/pageCard";

interface StatisticsCardProps extends PageCardProps {
  isEmpty?: boolean;
}

const StatisticsCard: FC<StatisticsCardProps> = ({
  title,
  isEmpty,
  children,
  ...rest
}) => {
  return (
    <PageCard title={<h3 className="card-title mb-0">{title}</h3>} {...rest}>
      {isEmpty ? <Empty /> : children}
    </PageCard>
  );
};

export default StatisticsCard;
