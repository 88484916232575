import { Button, Typography } from "antd";
import { FC } from "react";
import Details from "../../../components/detailsList";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { getEnumTitleById } from "../../../services/enumService";
import format from "../../../utilities/formatNumbers";

const { Link } = Typography;

interface LeasingVehicleInfoProps {
  startEditing: () => void;
}

const LeasingVehicleInfo: FC<LeasingVehicleInfoProps> = ({ startEditing }) => {
  const { leasingCase } = LeasingStore.useContainer();
  const { flags } = leasingCase;
  const { readonly, inputLocked } = flags;
  const disabled = readonly || inputLocked;
  const { brand, model, type, typeId, firstRegDate, vin, mileage, link, color, extraAccessories, quality, qualityId } =
    leasingCase.vehicle || {};

  const linkValue = link ? (
    <Link ellipsis className="max-width-240" href={link} target="_blank">
      {link}
    </Link>
  ) : null;

  const formatedType = type || (typeId ? getEnumTitleById(typeId, ["vehicle", "type"]) : "-");
  const formatedQuality = quality || (qualityId ? getEnumTitleById(qualityId, ["vehicle", "quality"]) : "-");

  return (
    <>
      <Details borderLess className="m-0">
        {linkValue && <Details.Item label={_t("link")} value={linkValue} />}
        <Details.Item label={_t("type")} value={formatedType} className="mb-1" />

        <Details.Item label={_t("brand")} value={format.text(brand)} />
        <Details.Item label={_t("model")} value={format.text(model)} />
        <Details.Item label={_t("firstRegDate")} value={format.date(firstRegDate)} />
        <Details.Item label={_t("vin")} value={format.text(vin)} />
        <Details.Item label={_t("mileage")} value={format.milage(mileage)} className="mb-1" />

        <Details.Item label={_t("color")} value={format.text(color)} />
        <Details.Item label={_t("extra_equipments")} value={format.text(extraAccessories)} />
        <Details.Item label={_t("car_condition")} value={formatedQuality} />
      </Details>

      <div className="text-right">
        <Button type="link" disabled={disabled} className="pl-0 pr-0 mt-1" onClick={startEditing}>
          {_t("edit")}
        </Button>
      </div>
    </>
  );
};

export default LeasingVehicleInfo;
