import React, { FC } from "react";
import VehiclePurchaseContractRequestedSwitch from "./vehiclePurchaseContractRequestedSwitch";
import VehiclePurchaseContractSignedSwitch from "./vehiclePurchaseContractSignedSwitch";
import VehiclePaid from "./vehiclePaid";

const ImportMerchantRelation: FC = () => {
  return (
    <>
      <VehiclePurchaseContractRequestedSwitch />
      <VehiclePurchaseContractSignedSwitch />
      <VehiclePaid />
    </>
  );
};

export default ImportMerchantRelation;
