import React, { FC } from "react";
import { Form, Input } from "antd";
import _t from "../../../../lang/translate";
import InputNum from "../../../../components/form/inputNum";
import EnumRadio from "../../../../components/form/EnumRadio";
import DateSelect from "../../../../components/form/dateSelect";

const ImportCarDataForm: FC = () => {
  return (
    <>
      <Form.Item label={_t("data_originalDocuments")} name={["data", "originalDocuments"]}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("data_keyAmount")} name={["data", "keyAmount"]}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("data_ownerAmount")} name={["data", "ownerAmount"]}>
        <InputNum />
      </Form.Item>
      <Form.Item label={_t("data_smokedInStatus")} name={["data", "smokedInStatusId"]}>
        <EnumRadio nameKey={["importCase", "smokedInStatus"]} />
      </Form.Item>
      <Form.Item label={_t("data_previousDamageStatus")} name={["data", "previousDamageStatusId"]}>
        <EnumRadio nameKey={["importCase", "previousDamageStatus"]} />
      </Form.Item>
      <Form.Item label={_t("data_availableDeliveryDate")} name={["data", "availableDeliveryDate"]}>
        <DateSelect />
      </Form.Item>
    </>
  );
};

export default ImportCarDataForm;
