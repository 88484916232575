import { NewPriceCalcTaxDocType } from '../../../../types/taxDocTypes';
import { calculateNewPrice } from '@dmf/calculator-library';

const calculate = (
  newPriceInput: NewPriceCalcTaxDocType,
) => {
  const { equipmentsPrice, newPriceWithoutEquipments, technicalNewPrice, useTechnicalNewPrice } = newPriceInput || {};
  const newPrice = calculateNewPrice({
    equipmentPrice: equipmentsPrice || 0,
    newPrice: newPriceWithoutEquipments || 0,
    technicalNewPrice: technicalNewPrice || 0
  });

  return useTechnicalNewPrice
    ? newPrice.technicalNewPrice
    : newPrice.totalSalesPrice;
}

export default calculate;
