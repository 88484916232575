import { Form } from "antd";
import { FC } from "react";
import SwitchInput from "../../../components/form/switchInput";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import format from "../../../utilities/formatNumbers";
import { isNum } from "../../../utilities/typeGuard";
import { LeasingDealType } from "./calc/leasingCalcTypes";

const LeasingGpsTracker: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { inputLocked, readonly } = leasingCase.flags;
  const disabled = inputLocked || readonly;

  const shouldUpdate = (curr: any, next: any) =>
    curr?.gpsTrackerOutput?.monthlyPayment !== next?.gpsTrackerOutput?.monthlyPayment;

  return (
    <Form.Item noStyle shouldUpdate={shouldUpdate}>
      {({ getFieldsValue }) => {
        const deal: LeasingDealType = getFieldsValue(true);
        const monthlyPayment = deal?.gpsTrackerOutput?.monthlyPayment || 0;
        const monthlyPaymentRender =
          isNum(monthlyPayment) && monthlyPayment > 0 ? (
            <span className="text-dark text-large">
              {format.number(monthlyPayment)}* <small>{_t("krone_per_month")}</small>
            </span>
          ) : (
            ""
          );

        return (
          <Form.Item
            label={<span className="text-dark text-large">{_t("gps_tracker")}</span>}
            name={["gpsTrackerInput", "active"]}
            valuePropName="checked"
          >
            <SwitchInput disabled={disabled} label={monthlyPaymentRender} />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default LeasingGpsTracker;
