import React, { FC } from "react";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import TaxCaseEdit from "./taxCaseEdit";
import { ParamTypes, ScreenProps } from "../../../../types/appTypes";
import { useParams } from "react-router-dom";
import { taxCaseUrl } from "../../../../services/taxService";
import { initTaxCase, TaxCase } from "../../../../types/taxTypes";
import { useApi } from "../../../../services/useApi";
import PageTitle from "../../../../layout/pageTitle";
import TaxCaseHeadExtra from "../taxCaseHeadExtra";
import AnimateIn from "../../../../components/animateIn";
import ActiveUsers from "../../../../components/activeUsers";
import PageBody from "../../../../layout/pageBody";

const TaxCaseAdmin: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading }] = useApi<TaxCase>(taxCaseUrl(id), initTaxCase, true);
  const caseReceived = data.id > 0;

  return (
    <TaxCaseStore.Provider initialState={data}>
      <PageTitle
        title={`${title}/${id}`}
        fluid
        headerMargin={false}
        backBtn
        extra={<ActiveUsers context="tax" />}
        footer={caseReceived && <TaxCaseHeadExtra />}
      />
      <PageBody>
        <AnimateIn start={!isLoading && caseReceived} loadingBeforeStart>
          <TaxCaseEdit />
        </AnimateIn>
      </PageBody>
    </TaxCaseStore.Provider>
  );
};

export default TaxCaseAdmin;
