import React, { FC, useState } from "react";
import _t from "../../../../../../lang/translate";
import format from "../../../../../../utilities/formatNumbers";
import Details from "../../../../../../components/detailsList";
import { TaxEstimationDisplayOutput } from "../../../../../../types/taxEstimationTypes";

type Props = {
    output: TaxEstimationDisplayOutput['tradePrice']
}

const TaxEstimationTradePriceOutput = ({ output }: Props) => {

    return (
        <Details borderLess>
            <Details.Item
                label={_t("equipments_regulation")}
                value={format.price(output.equipmentRegulation)}
                color="secondary"
            />
            <Details.Item
                label={_t("mileage_regulation")}
                value={format.price(output.mileageRegulation)}
                color="secondary"
            />
            <Details.Item
                label={_t("trading_price_level")}
                value={format.price(output.priceLevel)}
                color="secondary"
            />
            <Details.Item
                label={_t("trade_price")}
                value={format.price(output.tradePrice)}
                bold
            />
        </Details>
    );
};
export default TaxEstimationTradePriceOutput
