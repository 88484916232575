import React, { FC, useEffect } from "react";
import { TradePriceCalcTaxDocType } from "../../../../types/taxDocTypes";
import format from "../../../../utilities/formatNumbers";
import { isNum } from "../../../../utilities/typeGuard";

interface TradePriceTotalFieldProps {
  onChange?: (value: number | null) => void;
  value?: number | null;
  input: TradePriceCalcTaxDocType | null;
}

const TradePriceTotalField: FC<TradePriceTotalFieldProps> = ({ input, value, onChange }) => {
  useEffect(() => {
    const {
      priceLevel,
      qualityAmount,
      specialUseAmount,
      otherRegulationAmount,
      mileageRegulation,
      equipmentRegulation,
    } = input || {};

    const result =
      (priceLevel || 0) +
      (qualityAmount || 0) +
      (specialUseAmount || 0) +
      (otherRegulationAmount || 0) +
      (mileageRegulation || 0) +
      (equipmentRegulation || 0);

    result !== value && onChange && onChange(result);
  }, [input]);

  return <h4 className="mb-0">{format.price(isNum(value) ? value : 0)}</h4>;
};

export default TradePriceTotalField;
