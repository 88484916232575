import appConfig from "../../../../appConfig";
import { Equipment } from "../../../../types/appTypes";
import { TaxCalcEquipment } from "../../../../types/taxCalcTableTypes";
import { isNum } from "../../../../utilities/typeGuard";

export function calcEquipmentsTotal(equipments?: Equipment[] | null) {
  return equipments?.reduce((acc, eq) => acc + (eq?.price || 0), 0) || 0;
}

export function cleanEquipments(equipments?: Equipment[] | null) {
  return equipments?.filter((eq) => isNum(eq?.price) || !!eq?.name) || [];
}

export function calcEquipmentsPrice(euroFactor?: number | null, equipments?: Equipment[] | null) {
  if (!Array.isArray(equipments)) return [];
  return equipments.map((eq) => ({
    ...eq,
    price:
      isNum(eq.priceEuro) && eq.priceEuro > 0
        ? Math.round(eq.priceEuro * (euroFactor || 1) * appConfig("conversionRate"))
        : eq.price,
  }));
}

export function mergeEquipments(
  prev?: TaxCalcEquipment[] | null,
  next?: TaxCalcEquipment[] | null
): TaxCalcEquipment[] {
  if (!prev) return next || [];

  if (!next) return prev || [];

  const prevSynced = prev.filter((eq) => eq.synced);
  const prevUnsynced = prev.filter((eq) => !eq.synced);
  const nextSynced = next.map((eq) => {
    const oldEquipment = prevSynced.find((e) => e.id === eq.id);
    return oldEquipment ? { ...oldEquipment, price: eq.price, name: eq.name } : eq;
  });
  return [...nextSynced, ...prevUnsynced];
}
