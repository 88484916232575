import { FC } from "react";
import { Button, Dropdown, Menu } from "antd";
import { useHistory } from "react-router-dom";
import { useEnums } from "../../components/useEnum";
import _t from "../../lang/translate";
import { getPath } from "../../routes/appRoutes";
import UserStore from "../../contexts/userStore";
import Icon from "../../assets/icons/icon";
import { LeasingInitCaseType, LeasingInitClientType } from "../../types/leasingTypes";
import openModal from "../../components/modal/openModal";

const CreateLeasingActions: FC = () => {
  const history = useHistory();
  const [customerTypes] = useEnums(["customer", "type"]);
  const partnerCase = !history.location.pathname.includes(getPath("leasing"));
  const { user, hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission(partnerCase ? "partner_leasing.isAdmin" : "leasing.isAdmin");

  const initReqestData: LeasingInitCaseType = {
    typeId: 1,
    partnerCase,
    client: {
      typeId: 1,
      dealerId: user?.dealer.id || null,
      userId: user?.id || null,
      customerId: null,
    },
  };

  const fetchInitLeasingCase = (values: LeasingInitCaseType) => {
    history.push({
      pathname: getPath(partnerCase ? "partnerLeasing" : "leasing", "new"),
      state: {
        from: { pathname: "newLeasing" },
        data: values,
      },
    });
  };

  const handleClick = (typeId: number) => {
    fetchInitLeasingCase({ ...initReqestData, typeId });
  };

  const handleMenuClick = async (typeId: number) => {
    initReqestData.typeId = typeId;
    try {
      const client = await openModal<LeasingInitClientType>("leasingInitClient", initReqestData);
      fetchInitLeasingCase({ ...initReqestData, client });
    } catch {
    }
  };

  const menu = (
    <Menu className="min-width-125">
      <Menu.ItemGroup title={_t("on_behalf")} />
      {customerTypes?.map((item) => (
        <Menu.Item key={item.id} onClick={() => handleMenuClick(item.id)}>
          {item.title}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <span key="newCustomer">{_t("create", "leasingCase")}: </span>

      <div className="ant-button-group">
        {customerTypes?.map((item) => (
          <Button type="primary" key={item.id} onClick={() => handleClick(item.id)}>
            {item.title}
          </Button>
        ))}

        {isAdmin && (
          <Dropdown overlay={menu}>
            <Button type="primary">
              <Icon name="ellipsis-vertical-outline" />
            </Button>
          </Dropdown>
        )}
      </div>
    </>
  );
};

export default CreateLeasingActions;
