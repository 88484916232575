import React, { FC, useState } from "react";
import { Button, Col, Dropdown, Menu, message, Row, Space, Tag, Tooltip } from "antd";
import { PlayCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router";
import Icon from "../../../assets/icons/icon";
import ActivityDrawer from "../../../components/activityDrawer";
import Checklist from "../../../components/checklist/checklist";
import openModal from "../../../components/modal/openModal";
import StatusTag from "../../../components/statusTag";
import TaxCaseStore from "../../../contexts/taxCaseStore";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import log from "../../../services/logService";
import {
  createImportofTaxCase,
  createTaxDocofTaxCase,
  saveTaxCaseStatusToInProgress
} from "../../../services/taxService";
import { LocationStateType } from "../../../types/appTypes";
import { TaxCase } from "../../../types/taxTypes";
import TaxCaseDealer from "./admin/taxCaseDealer";
import { isAxiosError } from "../../../utilities/typeGuard";

const TaxCaseHeadExtra: FC = () => {
  const history = useHistory<LocationStateType>();
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const { hasPermission } = UserStore.useContainer();
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const isAdmin = hasPermission("tax.isAdmin");
  const canCreateImport = hasPermission("import.create");
  const canCreateTaxDoc = hasPermission("taxDoc.create");
  const canCreateLeasing = hasPermission("leasing.create");
  const canCreatePartnerLeasing = hasPermission("partner_leasing.create");

  const handleCreateLeasing = async () => {
    try {
      const { data } = await openModal("taxToLeasingCase", {
        id: taxCase.id,
        from: "tax",
      });
      if (data)
        history.push({
          pathname: getPath("leasing", "new"),
          state: { from: { pathname: "tax" }, data },
        });
    } catch (e) {
    }
  };
  const handleCreatePartnerLeasing = async () => {
    try {
      const { data } = await openModal("taxToLeasingCase", {
        id: taxCase.id,
        from: "tax",
        partnerCase: true,
      });
      if (data)
        history.push({
          pathname: getPath("partnerLeasing", "new"),
          state: {
            from: { pathname: "tax" },
            data,
          },
        });
    } catch (e) {
    }
  };

  const handleCreateTaxDoc = async () => {
    try {
      const { data } = await createTaxDocofTaxCase(taxCase.id);
      if (data?.id) {
        history.push({
          pathname: getPath("taxDoc", `${data.id}`),
        });
      } else {
        log("Exporting tax to taxDoc case returned data without Id", { data });
      }
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    }
  };

  const handleCreateImport = async () => {
    try {
      const { data } = await createImportofTaxCase(taxCase.id);
      if (data?.id) {
        history.push({
          pathname: getPath("import", `${data.id}`),
        });
      } else {
        log("Exporting tax to Import case returned data without Id", { data });
      }
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    }
  };

  const exportMenu = (
    <Menu>
      {canCreateLeasing && (
        <Menu.Item key="toLeasing" onClick={handleCreateLeasing}>
          {_t("leasing")}
        </Menu.Item>
      )}
      {canCreatePartnerLeasing && (
        <Menu.Item key="toPartnerLeasing" onClick={handleCreatePartnerLeasing}>
          {_t("partner_leasing")}
        </Menu.Item>
      )}
      {canCreateTaxDoc && (
        <Menu.Item key="toTaxDoc" onClick={handleCreateTaxDoc}>
          {_t("tax-doc")}
        </Menu.Item>
      )}
      {canCreateImport && (
        <Menu.Item key="toImport" onClick={handleCreateImport}>
          {_t("importCase")}
        </Menu.Item>
      )}
    </Menu>
  );

  const handleStatusChange = async () => {
    try {
      const { id, status } = taxCase;
      const newCase = await openModal<TaxCase>("changeStatus", {
        id,
        context: "tax",
        initialValues: {
          id: status?.id,
          forcedStatus: taxCase.flags.forcedStatus,
        },
      });
      setTaxCase(newCase);
    } catch (error) {
    }
  };

  const handleCaseStatus = async () => {
    try {
      const data = await saveTaxCaseStatusToInProgress(taxCase.id);

      if (data) {
        setTaxCase(data.data);

        return true;
      }
    } catch(error) {
      message.error(error.response?.data?.message);

      return false;
    }
  };

  return (
    <Row align="middle" gutter={[8,16]}>
      <Col flex="auto">{isAdmin && <TaxCaseDealer />}</Col>
      <Col>
        <Space wrap>
          {taxCase.flags.isPriority && (
            <Tooltip title={_t("high_priority")}>
              <Tag color="error" className="icon-tag">
                <Icon name="alert-outline" />
              </Tag>
            </Tooltip>
          )}

          {
            isAdmin && (!taxCase.isInProgress) &&
            <Tooltip title={_t('changeToInProgress')}>
              <PlayCircleOutlined
                style={{ cursor: 'pointer', fontSize: 30, marginLeft: 5 }}
                onClick={handleCaseStatus}
              />
            </Tooltip>
          }

          {isAdmin ? (
            <StatusTag locked={taxCase.flags.forcedStatus} status={taxCase.status} onClick={handleStatusChange} />
          ) : (
            <StatusTag status={taxCase.status} />
          )}



          {(canCreateLeasing || canCreatePartnerLeasing) && (
            <Dropdown overlay={exportMenu}>
              <Button>{_t("move", "to")}</Button>
            </Dropdown>
          )}

          {isAdmin && (
            <>
              <Button onClick={() => setDrawerVisibility(true)}>{_t("activities", "note", " & ")}</Button>
              <ActivityDrawer
                visible={drawerVisibility}
                isAdmin={isAdmin}
                setVisibility={setDrawerVisibility}
                type="tax"
                id={taxCase.id}
                sendingDisabledReason={!taxCase.flags.partnerCase ? null : _t('externalCaseContactCustomerManually')}
              />
            </>
          )}
          {isAdmin && taxCase.links?.dealerView && (
            <Button href={taxCase.links.dealerView} target="_blank" icon={<Icon name="open-outline"/>}>DMF PRO</Button>
          )}

          {hasPermission("tax.checklist") && (
            <Checklist
              {...{
                isAdmin,
                id: taxCase.id,
                context: "tax",
                btnText: _t("checklist"),
                asDrawer: true
              }}
            />
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default TaxCaseHeadExtra;
