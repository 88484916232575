import { FC, useEffect } from "react";
import format from "../../../../../utilities/formatNumbers";
import { isNum } from "../../../../../utilities/typeGuard";

interface TaxRecalculatedMileageCellProps {
  year?: number | null;
  mileage?: number | null;
  vehicleYear?: number | null;
  value?: number | null;
  documentationDate: string | null;
  onChange?: (v: number | null) => void;
}

const TaxRecalculatedMileageCell: FC<TaxRecalculatedMileageCellProps> = ({
  year,
  mileage,
  vehicleYear,
  value,
  documentationDate,
  onChange,
}) => {
  useEffect(() => {
    let nextValue = 0;
    if (isNum(vehicleYear) && isNum(mileage) && isNum(year) && year > 999) {
      const currentYear = documentationDate ? new Date(documentationDate).getFullYear() : new Date().getFullYear();
      const diff = currentYear - year || 1;
      const vehicleDiff = currentYear - vehicleYear || 1;
      nextValue = Math.round((mileage / diff) * vehicleDiff || 0);
    }
    onChange && onChange(nextValue);
  }, [year, mileage, year, vehicleYear]);

  return <span>{isNum(value) ? format.number(value) : "-"}</span>;
};

export default TaxRecalculatedMileageCell;
