import jwt_decode from "jwt-decode";
import http, { setJwt } from "./httpService";
import { EnumItemType, EnumType, IdType, ProductType } from "../types/appTypes";
import log, { setLogContext } from "./logService";
import getApiEndpoint from "./apiEndpoint";
import { ForgotPasswordType, LoginType, ResetPasswordType, SignUpType } from "../types/loginTypes";
import { AppRoleType } from "../types/permissionTypes";
import { initNotificationCount, NotificationCountType } from "../types/notificationsType";
import { ProductPricesType, UserCommonType } from "../types/userTypes";
import { DepartmentType } from "../types/dealerTypes";
import storage from "../utilities/browserStorage";
import { PaymentMethodDetailsType, SubscriptionPackageProductCode, SubscriptionType } from "../types/subscriptionTypes";
import _t from "../lang/translate";

const apiEndpoint = `${getApiEndpoint()}/auth`;

export const storageKeys = {
  token: "accessToken",
  roles: "roles",
  claimedRoleId: "claimedRoleId",
  enums: "enums",
  productPrices: "clipPrices",
  //refreshToken: "refreshToken",
  locale: "local",
  notifications: "notificationsCount",
  notificationChannel: "notificationChannel",
  clip: "clipsCount",
  credit: "creditCount",
  freeDocBuys: "freeDocBuys",
  subscriptionSelected: "subscriptionSelected",
  subscription: "subscription",
  upcomingSubscription: "upcomingSubscription",
  startTrialModal: "startTrialModal",
  paymentMethodDetails: "paymentMethodDetails",
  fluidTable: "fluidTable",
  isFirstLogin: "isFirstLogin",
  userInfo: "userInfo",
  terms: "terms"
};

setJwt(getJwt());

export interface UserInfo {
  newsletterSubscription?: boolean;
}

export interface UserSystemType extends UserCommonType {
  expired?: number;
  dealer: EnumItemType;
  role: AppRoleType;
  token: string | null;
  lastLogin: string | null;
  subscriptionSelected: boolean;
  subscription: SubscriptionType | null;
  upcomingSubscription: SubscriptionType | null;
  paymentMethod: PaymentMethodDetailsType;
  terms: Terms | null
}

export interface AuthResponseData {
  accessToken: string;
  //refreshToken: string;
  local: string | undefined;
  expiresIn: string;
  lastLogin: string;
  newsletterSubscription: boolean;
  isFirstLogin: boolean;
  notificationChannel: string;
  trialStarted: boolean;
  productPrices: ProductPricesType;
  enums: EnumType;
  roles: AppRoleType[];
  subscriptionSelected: boolean;
  subscription: SubscriptionType | null;
  upcomingSubscription: SubscriptionType | null;
  paymentMethod: PaymentMethodDetailsType;
  terms: Terms | null
}

type Terms = {
  tax?: ProductTerm;
  leasing?: ProductTerm;
  import?: ProductTerm;
}

type ProductTerm = {
  uri: string | null,
  acceptedAt: null | string;
}

interface DecodedToken {
  iss: string | null;
  iat: number;
  exp: number;
  nbf: number | null;
  jti: string | null;
  sub: number;
  prv: string | null;
  name: string;
  initials: string;
  email: string;
  phone: string | null;
  dealer: EnumItemType;
  roleId: IdType;
  departments: DepartmentType[] | null;
  clmk: string | null;
}

export async function login(values: LoginType) {
  try {
    const url = `${apiEndpoint}/login`;
    const { data, headers } = await http.post<AuthResponseData>(url, values);
    handleNewLogin(data, headers);
    return { data, headers };
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function authResetPassword(values: ResetPasswordType) {
  try {
    const url = `${apiEndpoint}/reset/reset`;
    const { data, headers } = await http.post<AuthResponseData>(url, values);
    handleNewLogin(data, headers);
    return { data, headers };
  } catch (error) {
    return Promise.reject(error);
  }
}


/**
 * Route::post('register/initialize', [RegisterController::class, 'initialize'])->name('register.initialize');
 *
 * Takes "email" in the body, and will send an email containing a link the user can go to: /opret-konto?token=XXXXX
*/
export async function signUpInitialize(email: string) {
  const url = `${apiEndpoint}/register/initialize`;
  return http.post<{ data: boolean }>(url, { email });
}

/**
 * Route::get('register/{token}', [RegisterController::class, 'show'])->name('register.show');
 *
 * If the {token} is valid, then it will return the data about the token (email and validUntil), else it will return error 400
*/
export async function signUpValidateToken(token: string) {
  const url = `${apiEndpoint}/register/${token}`;
  return http.get<{ data: { email: string, validUntil: string } }>(url);
}

export async function signUp(values: SignUpType) {
  try {
    const url = `${apiEndpoint}/register`;
    const { data, headers } = await http.post<AuthResponseData>(url, values);
    handleNewLogin(data, headers);
    return { data, headers };
  } catch (error) {
    return Promise.reject(error);
  }
}

export function handleNewLogin(data: AuthResponseData, headers: any) {
  saveNotificatiosCount(headers["x-unreadnotifications"], headers["x-unreadposts"]);
  setJwt(data.accessToken);
  saveJwt(data.accessToken);
  saveProductPrices(data.productPrices);
  saveSubscription(data.subscription);
  saveSubscriptionSelected(data.subscriptionSelected);
  savePaymentMethodDetails(data.paymentMethod);
  saveEnums(data.enums);
  saveLocale(data.local);
  saveNotificationChannel(data.notificationChannel);
  saveRoles(data.roles);
  saveIsFirstLogin(data.isFirstLogin)
  saveTerms(data.terms);

  saveUserInfo({
    newsletterSubscription: data.newsletterSubscription
  });

  saveStartTrialModal(data.trialStarted);
  // const user = getCurrentUser();
  /*   const permissions = appRoles.filter((role) => role.key === user?.role.title);
  saveRoles(permissions.length > 0 ? permissions[0]?.permissions : [...permissions[4].permissions]); */
  //saveRefreshJwt(data.refreshToken);
}

export function logout() {
  for (const storageKey of Object.values(storageKeys)) {
    storage.removeItem(storageKey);
  }
}

export function sendResetPasswordEmail(data: ForgotPasswordType) {
  return http.post(`${apiEndpoint}/reset/sendEmail`, data);
}

export function getAboutUser() {
  return http.get<Partial<AuthResponseData>>(`${apiEndpoint}/whoami`);
}

export function getCurrentUser(): UserSystemType | null {
  try {
    const jwt = getJwt();
    if (jwt === null) return null;
    const newUser = jwt_decode<DecodedToken>(jwt);
    const role = getRole(newUser.roleId);
    const subscription = getSubscription();
    const upcomingSubscription = getUpcomingSubscription();
    const subscriptionSelected = getSubscriptionSelected();
    const paymentMethodDetails = getPaymentMethodDetails();
    const terms = getTerms();
    if (!role) return null;

    const user: UserSystemType = {
      id: newUser.sub,
      name: newUser.name,
      email: newUser.email,
      phone: newUser.phone,
      token: jwt,
      expired: newUser.exp,
      dealer: newUser.dealer,
      role,
      departments: newUser.departments,
      initials: newUser.initials,
      lastLogin: '',
      subscriptionSelected,
      subscription,
      upcomingSubscription,
      paymentMethod: paymentMethodDetails,
      terms: terms,
    };
    setLogContext({
      user_id: user.id,
      user_name: user.name,
      user_email: user.email,
      user_dealer: newUser.dealer,
      role: role.key,
    });
    return user;
  } catch (ex) {
    log("Error while getting user from local storage", ex);
    return null;
  }
}

/* export async function refreshJwt() {
  const tokenObj = {
    accessToken: getJwt(),
    refreshToken: getRefreshJwt(),
  };
  try {
    const refreshResponse = await http.post(`${apiEndpoint}/refresh`, tokenObj);
    //console.log("refreshResponse:", refreshResponse);
    setJwt(refreshResponse.data.accessToken);
    saveJwt(refreshResponse.data.accessToken);
    //saveRefreshJwt(refreshResponse.data.refreshToken);
    return refreshResponse;
  } catch (err) {
    //console.log("error:", err.response);
    return err;
  }
} */

export function saveJwt(token: string) {
  return storage.setItem(storageKeys.token, token);
}

/* export function saveRefreshJwt(refreshToken: string) {
  return storage.setItem(storageKeys.refreshToken, refreshToken);
} */

export function saveLocale(locale: string | undefined) {
  return storage.setItem(storageKeys.locale, locale ? locale : "da-DK");
}

export function saveNotificatiosCount(notificationsCount: number, postsCount: number) {
  return storage.setItem(
    storageKeys.notifications,
    JSON.stringify({
      notificationsCount: notificationsCount * 1,
      postsCount: postsCount * 1,
      total: notificationsCount * 1 + postsCount * 1,
    })
  );
}

export function saveNotificationChannel(channel: string) {
  return storage.setItem(storageKeys.notificationChannel, `${channel}`);
}

export function saveProductPrices(prices: ProductPricesType) {
  return storage.setItem(storageKeys.productPrices, JSON.stringify(prices));
}

export function saveUserInfo(data: UserInfo) {
  return storage.setItem(storageKeys.userInfo, JSON.stringify(data));
}

export function getUserInfo(): UserInfo {
  const state = storage.getItem(storageKeys.userInfo);
  return state ? JSON.parse(state) : {};
}

export function saveIsFirstLogin(state: boolean) {
  return storage.setItem(storageKeys.isFirstLogin, JSON.stringify(state ? 1 : 0));
}

export function getIsFirstLogin(): boolean {
  const state = storage.getItem(storageKeys.isFirstLogin);
  return state ? !!parseInt(state) : false;
}

export function saveClipCount(count: number) {
  return storage.setItem(storageKeys.clip, `${count}`);
}

export function saveCreditCount(count: number) {
  return storage.setItem(storageKeys.credit, `${count}`);
}

export function saveFreeDocBuysLeftCount(count: number) {
  return storage.setItem(storageKeys.freeDocBuys, `${count}`);
}

export function saveSubscriptionSelected(data: boolean) {
  return storage.setItem(storageKeys.subscriptionSelected, data ? '1' : '0');
}

export function saveSubscription(sub: SubscriptionType | null) {
  return storage.setItem(storageKeys.subscription, JSON.stringify(sub));
}

export function saveUpcomingSubscription(sub: SubscriptionType | null) {
  return storage.setItem(storageKeys.upcomingSubscription, JSON.stringify(sub));
}

export function savePaymentMethodDetails(data: PaymentMethodDetailsType | null) {
  return storage.setItem(storageKeys.paymentMethodDetails, JSON.stringify(data));
}

export function saveStartTrialModal(data: boolean) {
  return storage.setItem(storageKeys.startTrialModal, data ? '1' : '0');
}

export function getClaimedRoleId() {
  const claimedRoleId = storage.getItem(storageKeys.claimedRoleId);
  return claimedRoleId ? parseInt(claimedRoleId, 10) : null;
}

export function getRole(id: IdType) {
  const roles = getRoles();
  return roles ? roles.find((r) => r.id === id) : null;
}

export function getClaimedRole() {
  const claimedRoleId = getClaimedRoleId();
  return (claimedRoleId ? getRole(claimedRoleId) : null) as AppRoleType | null;
}

export function setClaimdRoleId(roleId: IdType | null) {
  if (roleId === null) return storage.removeItem(storageKeys.claimedRoleId);
  return storage.setItem(storageKeys.claimedRoleId, roleId.toString());
}

export function saveRoles(roles: AppRoleType[]) {
  return storage.setItem(storageKeys.roles, JSON.stringify(roles));
}

export function saveEnums(enums: EnumType | null) {
  return storage.setItem(storageKeys.enums, JSON.stringify(enums));
}

export function getSavedEnums(): EnumType | null {
  const enums = storage.getItem(storageKeys.enums);
  return !enums || enums === null ? null : (JSON.parse(enums) as EnumType);
}

export function saveTerms(terms: Terms | null) {
  return storage.setItem(storageKeys.terms, JSON.stringify(terms));
}

export function getTerms(): Terms | null {
  const terms = storage.getItem(storageKeys.terms);
  return (!terms || terms === null) ? null : (JSON.parse(terms) as Terms);
}

export function getStartTrialModal(): boolean {
  return storage.getItem(storageKeys.startTrialModal) === '1';
}

export function getSubscriptionSelected(): boolean {
  return storage.getItem(storageKeys.subscriptionSelected) === '1';
}

export function getProductPrices(): ProductPricesType | null {
  const prices = storage.getItem(storageKeys.productPrices);
  return !prices || prices === null ? null : (JSON.parse(prices) as ProductPricesType);
}

export function getLocal(): string | undefined {
  return storage.getItem(storageKeys.locale)?.toString();
}

export function getNotificationChannel(): string | null {
  return storage.getItem(storageKeys.notificationChannel);
}

export function getNotificatiosCount(): NotificationCountType {
  const notifications = storage.getItem(storageKeys.notifications);
  return notifications ? JSON.parse(notifications) : initNotificationCount;
}

export function getClipCount() {
  let clips = storage.getItem(storageKeys.clip);
  clips = typeof clips === "string" ? clips.replace(/\D/g, '') : clips;
  return !!clips ? JSON.parse(clips || "0") : 0;
}

export function getCreditCount() {
  let credits = storage.getItem(storageKeys.credit);
  credits = typeof credits === "string" ? credits.replace(/\D/g, '') : credits;
  return !!credits ? JSON.parse(credits || "0") : 0;
}

export function getFreeDocBuysLeftCount() {
  let credits = storage.getItem(storageKeys.freeDocBuys);
  credits = typeof credits === "string" ? credits.replace(/\D/g, '') : credits;
  return !!credits ? JSON.parse(credits || "0") : 0;
}

export function getSubscription() {
  const sub = storage.getItem(storageKeys.subscription);
  const parsed = sub ? JSON.parse(sub) : null;
  return parsed ? (parsed as SubscriptionType) : {
    id: -1,
    isActive: true,
    endsAt: null,
    isTrial: false,
    isPastDue: false,
    isExpired: false,
    trialEndsAt: null,
    package: {
      id: -1,
      priceNoVat: 0,
      title: _t('starter'),
      creditsPerPeriod: 3,
      description: _t('starterDescription'),
      level: 0,
      features: null,
      priceWithVat: 0,
      currency: 'dkk',
      productCode: SubscriptionPackageProductCode.FREE,
      selectable: false,
      recommended: false,
    }
  };
}
export function getUpcomingSubscription() {
  const sub = storage.getItem(storageKeys.upcomingSubscription);
  const parsed = sub ? JSON.parse(sub) : null;
  return parsed ? (parsed as SubscriptionType) : null;
}

export function getPaymentMethodDetails() {
  const data = storage.getItem(storageKeys.paymentMethodDetails);
  return !!data ? JSON.parse(data) : null;
}

export function getRoles() {
  const roles = storage.getItem(storageKeys.roles);
  return JSON.parse(roles || "null") as AppRoleType[] | null;
}

export function getPermissions() {
  const roles = getRoles();
  const userId = getCurrentUser()?.role.id;
  const claimedRoleId = getClaimedRoleId();
  const id = claimedRoleId || userId;
  return roles?.find((r) => r.id === id)?.permissions || [];
}

export function getJwt() {
  return storage.getItem(storageKeys.token);
}

/* export function getRefreshJwt() {
  return storage.getItem(storageKeys.refreshToken);
}
 */
const items = {
  login,
  signUp,
  logout,
  getCurrentUser,
  getJwt,
};


export default items;
