import { FC, useEffect } from "react";
import InputNum, { InputPriceProps, } from "../../../../components/form/inputNum";
import { isValidNum } from "../../../../utilities/typeGuard";
import { getTradePriceRegulation } from "../calc/tradePriceSubCalculations";

interface RegulationAmountInputProps extends InputPriceProps {
  priceLevel?: number | null;
  regulationRate?: number | null;
}

const RegulationAmountInput: FC<RegulationAmountInputProps> = ({
  priceLevel,
  regulationRate,
  value,
  onChange,
  ...rest
}) => {
  useEffect(() => {
    onChange && onChange(getTradePriceRegulation(
      isValidNum(priceLevel) ? priceLevel : 0,
      isValidNum(regulationRate) ? regulationRate : 0
    ));
  }, [priceLevel, regulationRate]);

  return (
    <InputNum
      disabled
      value={value}
      onChange={onChange}
      suffix="kr."
      {...rest}
    />
  );
};

export default RegulationAmountInput;
