import { FC } from "react";
import { Card, Alert } from "antd";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import CaseStatus from "../../../../components/caseStatus";
import TaxOfferForm from "./taxOfferForm";
import { isWorkingDay } from "../../../../utilities/date";

const TaxOfferView: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const { published } = taxCase.flags;

  /*
  Checks for the tax cases with status “Nyt Lead” and created outside normal
  opening hours (weekdays 8-16) and weekends.
  */
  const isNewAndOutOfNormalWorkingHours = (
    (taxCase?.status?.title === "Nyt lead") &&
    (!isWorkingDay(taxCase.log?.creation?.date, 8, 16))
  );
  const message = (
    <>
      {_t("outOfNormalWorkingHours")}
      <br />
      {_t("outOfNormalWorkingHoursContact")}
    </>
  )

  return (
    <>
      <Card bordered={false} title={_t("preassessment")}>
        {isNewAndOutOfNormalWorkingHours &&
          <Alert
            type="warning"
            message={message}
            className={"mb-1"}
          />
        }
        {published ? (
          <TaxOfferForm />
        ) : (
          <div className="text-center">
            <CaseStatus
              title={_t("msg.working_on_the_case")}
              status="calculating"
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default TaxOfferView;
