import { Button, message } from "antd";
import React, { FC, useState } from "react";
import _t from "../../lang/translate";
import { toggleProductAdmin } from "../../services/systemService";
import { IdType } from "../../types/appTypes";
import { AdminProductsType, toggleProductAdminType } from "../../types/systemTypes";
import { isAxiosError } from "../../utilities/typeGuard";

interface ProductsAdminButtonProps {
  userId: IdType;
  product: AdminProductsType;
  onChange: (userId: IdType, product: AdminProductsType) => void;
}

const ProductsAdminButton: FC<ProductsAdminButtonProps> = ({ userId, product, onChange }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id, value, title } = product;
  const ontoggleProduct = async (userId: IdType, data: toggleProductAdminType) => {
    try {
      setLoading(true);
      await toggleProductAdmin(userId, data);
      const nextProduct = { ...product, value: !value };
      onChange(userId, nextProduct);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      loading={loading}
      shape="round"
      size="small"
      className={`no-shadow${value ? "" : " muted-btn"}`}
      type={value ? "primary" : "default"}
      onClick={() => ontoggleProduct(userId, { product: id, status: !value })}
    >
      {title}
    </Button>
  );
};

export default ProductsAdminButton;
