import { PaymentType } from "../../../../types/paymentCardType";
import { TaxCase } from "../../../../types/taxTypes";
import { isValidNum } from "../../../../utilities/typeGuard";
import { calculateStandardDepreciation, calculateTradePrice } from "@dmf/calculator-library";

export function calculateBatteryCapacity(batteryUse: number, batteryRange: number) {
    try {
        const batteryCapacity = (batteryUse * batteryRange) / 1000;
        return isValidNum(batteryCapacity) ? batteryCapacity : 0;
    } catch (e: unknown) {
        return 0;
    }
}

const ACCEPTABLE_DEVIATION_LIMIT = {
    DEFAULT: 0.1,
    TRADE_PRICE: 0.1,
    NEW_PRICE: 0.1,
    STANDARD_DEPRECIATION: 0.1,
} as const;

export function calculateDeviation(realValue: number, tableValue: number) {
    const deviation = (realValue - tableValue) / tableValue;
    return !isNaN(deviation) ? deviation : 0;
}

export function getDeviation(
    realValue: number,
    tableValue: number,
    deviationType: keyof typeof ACCEPTABLE_DEVIATION_LIMIT = "DEFAULT",
) {
    const deviationLimit = ACCEPTABLE_DEVIATION_LIMIT[deviationType];
    const deviation = calculateDeviation(realValue, tableValue);
    return {
        deviation,
        isLargeDeviation: Math.abs(deviation) > deviationLimit,
    }
}

type DeprecitationInput = {
    newPrice: number,
    firstRegDate: string,
    tradePrice: number,
    mileage: number,
}
export type DeprecitationOutput = {
    standard: {
        factor: number;
        depreciatedNewPrice: number;
    },
    caseDeprecitationFactor: number,
    deviation: {
        deviation: number;
        isLargeDeviation: boolean;
    }
    mileageRegulation: number;
    deprecitationWithMileageRegulation: number,
}

export function getDeprecitation({ newPrice, firstRegDate, tradePrice, mileage }: DeprecitationInput
): DeprecitationOutput {
    const standardDepreciation = calculateStandardDepreciation({
        newPrice: newPrice,
        firstRegDate: firstRegDate
    });

    const mileageRegulation = getMileageRegulation({
        depreciatedNewPrice: standardDepreciation.depreciatedNewPrice,
        firstRegDate,
        mileage
    })
    const deprecitationWithMileageRegulation = standardDepreciation.depreciatedNewPrice + mileageRegulation.mileageRegulation;

    const caseDepreciation = 1 - (tradePrice / newPrice)
    return {
        standard: standardDepreciation,
        caseDeprecitationFactor: !isNaN(caseDepreciation) ? caseDepreciation : 0,
        deviation: getDeviation(tradePrice, deprecitationWithMileageRegulation, "STANDARD_DEPRECIATION"),
        mileageRegulation: mileageRegulation.mileageRegulation,
        deprecitationWithMileageRegulation
    }
}


const STANDARD_ANNUAL_MILEAGE = 20_000;
function getMileageRegulation({ depreciatedNewPrice, firstRegDate, mileage }: {
    depreciatedNewPrice: number,
    firstRegDate: string,
    mileage: number,
}) {
    const vehicleAgeInYears = new Date().getFullYear() - new Date(firstRegDate).getFullYear();
    const standardMileage = isNaN(vehicleAgeInYears)
        ? STANDARD_ANNUAL_MILEAGE
        : vehicleAgeInYears * STANDARD_ANNUAL_MILEAGE;

    const tradePriceOutput = calculateTradePrice({
        adverts: [{
            equipmentPrice: 0,
            firstRegDateYear: new Date(firstRegDate).getFullYear(),
            isSold: true,
            mileage: standardMileage,
            salesPrice: depreciatedNewPrice,
        }],
        equipmentPrice: 0,
        firstRegDate: firstRegDate,
        mileage: mileage,
        quality: "AVERAGE",
        specialUse: false,
        calcDate: new Date().toISOString(),
    })

    if (tradePriceOutput.result) {
        return {
            mileageRegulation: tradePriceOutput.result?.mileageRegulation
        }
    }
    return {
        mileageRegulation: 0,
    }
}

export type CalculatePaymentOutput = {
    total: number,
    cardFees: number,
    totalWithCardFees: number,
    transactionFeesFactor: number;
}
type CalculatePaymentInput = {
    paymentType: PaymentType;
    taxCaseCalculation: TaxCase['calculation'];
    cardPayments: TaxCase['cardPayments'];
}
export function calculatePayment({ paymentType, cardPayments, taxCaseCalculation }: CalculatePaymentInput): CalculatePaymentOutput {
    let transactionFeesFactor = 0;
    switch (paymentType) {
        case "quickpay": transactionFeesFactor = cardPayments?.transactionFees ?? 0; break;
        case "stripe": transactionFeesFactor = cardPayments?.transactionFees ?? 0; break;
        default: transactionFeesFactor = 0;
    }
    const total = taxCaseCalculation?.total ?? 0;
    const cardFees = total * transactionFeesFactor;
    const totalWithCardFees = total + cardFees;
    return {
        total,
        cardFees,
        totalWithCardFees,
        transactionFeesFactor
    }
}
