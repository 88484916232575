import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";
import { ProfileType, UserEditiblePropTypes } from "../types/userTypes";

const apiEndpoint = `${getApiEndpoint()}/profile`;

export const profileUrl = () => apiEndpoint;

export function getProfile() {
  return http.get(apiEndpoint);
}

export function saveProfile(data: UserEditiblePropTypes) {
  return http.put<ProfileType>(apiEndpoint, data);
}
