import { isBetweenOrEqual, roundNum } from "../../../../utilities/number";
import { isNum } from "../../../../utilities/typeGuard";
import gpsTrackerCalc from "./addons/gpsTrackerCalc";
import insuranceCalc from "./addons/insuranceCalc";
import { initLimits, initMonthlyPayment, initPayout, initPeriodTax, initRestValue, } from "./leasingCalcInit";
import {
  LeasingCalcMethodType,
  LeasingCalcOutputType,
  LimitsOutputType,
  MonthlyPaymentOutputType,
  PayoutOutputType,
  PeriodTaxOutputType,
  RestValueOutputType,
} from "./leasingCalcTypes";

const leasingCalc: LeasingCalcMethodType<LeasingCalcOutputType> = (deal) => {
  let output: LeasingCalcOutputType = {
    periodTax: initPeriodTax,
    limits: initLimits,
    monthlyPayment: initMonthlyPayment,
    payout: initPayout,
    restValue: initRestValue,
    totalCost: null,
    calculable: true,
  };

  try {
    output.periodTax = periodTaxCalc({ ...deal, output });
    output.totalCost = totalCostCalc({ ...deal, output });
    output.restValue = restValueCalc({ ...deal, output });
    output.payout = payoutCalc({ ...deal, output });
    output.monthlyPayment = monthlyPaymentCalc({ ...deal, output });
    output.limits = limitsCalc({ ...deal, output });
    output.calculable = isPayoutInLimit({ ...deal, output });
    output.calculable =
      output.calculable && isRestValueInLimit({ ...deal, output });
  } catch (error) {
    output.calculable = false;
  }
  return output;
};

export default {
  calc: leasingCalc,
  gpsTrackerCalc,
  insuranceCalc,
};

export const isPayoutInLimit: LeasingCalcMethodType<boolean> = ({
  input,
  output,
}) => {
  const { isUnlimitedValues, wishedPayout, wishedDeposit } = input;
  const { wishedPayoutMin, wishedPayoutMax } = output.limits || {};

  if (isUnlimitedValues || (!isNum(wishedPayout) && !isNum(wishedDeposit)))
    return true;
  const totalFirstPayment = (wishedPayout || 0) + (wishedDeposit || 0);
  return isBetweenOrEqual(totalFirstPayment, [
    wishedPayoutMin,
    wishedPayoutMax,
  ]);
};

export const isRestValueInLimit: LeasingCalcMethodType<boolean> = ({
  input,
  output,
}) => {
  const { isUnlimitedValues, wishedRestValue } = input;
  const { wishedRestValueMin, wishedRestValueMax } = output.limits || {};
  if (isUnlimitedValues || !isNum(wishedRestValue)) return true;
  return isBetweenOrEqual(wishedRestValue || 0, [
    wishedRestValueMin,
    wishedRestValueMax,
  ]);
};

const limitsCalc: LeasingCalcMethodType<LimitsOutputType> = ({
  input,
  output,
}) => {
  let limitsOutput = { ...initLimits };

  const { isUnlimitedValues } = input;
  const { restValue, totalCost } = output;

  if (!totalCost) throw new Error("Some values are missing!");

  if (!isUnlimitedValues) {
    limitsOutput.wishedRestValueMin = Math.round(restValue.recomended * 0.9);
    limitsOutput.wishedRestValueMax = Math.round(restValue.recomended * 1.1);
    limitsOutput.wishedPayoutMin = Math.floor((totalCost * 0.1) / 100) * 100;
    limitsOutput.wishedPayoutMax = Math.floor((totalCost * 0.3) / 100) * 100;
  }

  return limitsOutput;
};

const periodTaxCalc: LeasingCalcMethodType<PeriodTaxOutputType> = ({
  input,
  valueConfig: config,
}) => {
  let periodTaxOutput = { ...initPeriodTax };
  const interestOfTaxSurcharge = config?.interestOfTaxSurcharge.value;
  const {
    vehicleAgeInMonths,
    leasingPeriod,
    taxPrice,
    inactivePeriod,
    isPeriodTaxExtraMonth,
  } = input;

  if (
    !isNum(leasingPeriod) ||
    !isNum(vehicleAgeInMonths) ||
    !isNum(taxPrice) ||
    !isNum(interestOfTaxSurcharge)
  )
    throw new Error("Some values are missing!");

  const newLeasingPeriod = leasingPeriod + (isPeriodTaxExtraMonth ? 1 : 0);

  //Finding the months in each sections, by looping through each of the sections.
  var section1 = 0;
  var section2 = 0;
  var section3 = 0;

  for (
    let i = vehicleAgeInMonths;
    i < newLeasingPeriod + vehicleAgeInMonths;
    i++
  ) {
    i < 3 && section1++;
    i >= 3 && i < 36 && section2++;
    i >= 36 && section3++;
  }

  //Sums up
  // valueLoss is 3 decimals to match the old calc
  const valueLoss = roundNum(
    taxPrice * (section1 * 0.02 + section2 * 0.01 + section3 * 0.005),
    3
  );
  periodTaxOutput.valueLoss = roundNum(valueLoss, 2);
  periodTaxOutput.restTaxTotal = roundNum(taxPrice - valueLoss, 2);
  const interestOfRestTax = roundNum(
    periodTaxOutput.restTaxTotal *
    interestOfTaxSurcharge *
    (newLeasingPeriod / 12),
    4
  );
  periodTaxOutput.interestOfRestTax = roundNum(interestOfRestTax, 2);

  //End result (Math.ceil, rounds up to nearst "one")
  if (!inactivePeriod) {
    periodTaxOutput.total = Math.ceil((valueLoss + interestOfRestTax) / 1) * 1;
  }
  periodTaxOutput.monthlyPayment =
    Math.ceil(periodTaxOutput.total / newLeasingPeriod / 1) * 1;
  return periodTaxOutput;
};

const totalCostCalc: LeasingCalcMethodType<number> = ({
  input,
  output,
  carWarrantyInput,
  valueConfig: config,
}) => {
  const { dealerProvision, vehiclePrice, provisionIntern } = input;

  const { periodTax } = output;
  const financeDeclarationAmount = config.financeDeclarationAmount.value;
  const documentChargeInternal = config.documentChargeInternal.value;
  const administrationCost = config.administrationCost.value;
  const licensePlateFee = config.numberPlatesAndHealthCheck.value;
  if (!vehiclePrice) throw new Error("Some values are missing!");

  const carWarrantyTotalPrice = carWarrantyInput.active
    ? carWarrantyInput.price
    : 0;
  return (
    vehiclePrice +
    periodTax.total +
    (licensePlateFee ?? 0) +
    (dealerProvision ?? 0) +
    financeDeclarationAmount +
    documentChargeInternal +
    administrationCost +
    (provisionIntern ?? 0) +
    (carWarrantyTotalPrice ?? 0)
  );
};

const restValueCalc: LeasingCalcMethodType<RestValueOutputType> = ({
  input,
}) => {
  let restValueOutput = { ...initRestValue };

  const {
    vehicleAgeInMonths,
    leasingPeriod,
    annualKilometer,
    vehiclePrice,
    wishedRestValue,
    isVatDeadVehicle,
  } = input;
  if (
    !isNum(leasingPeriod) ||
    !isNum(vehicleAgeInMonths) ||
    !isNum(annualKilometer) ||
    !isNum(vehiclePrice)
  )
    throw new Error("Some values are missing!");

  //Slutafskrivning
  let endDepreciation = 0.12 + 0.005 * leasingPeriod;

  //Værditabsklasser, som indeholder både alders-værditab og kilometer-værdiab. See B15:E23 in excel-sheet.
  let valueLossClasses = [
    {
      min: 0,
      max: 5,
      months: 0,
      valueloss: 0.011,
      kilometerloss: annualKilometer * 0.000000195,
    },
    {
      min: 6,
      max: 17,
      months: 0,
      valueloss: 0.00085,
      kilometerloss: annualKilometer * 0.00000018,
    },
    {
      min: 18,
      max: 35,
      months: 0,
      valueloss: 0.007,
      kilometerloss: annualKilometer * 0.000000175,
    },
    {
      min: 36,
      max: 999999,
      months: 0,
      valueloss: 0.003,
      kilometerloss: annualKilometer * 0.00000015,
    },
  ];

  //Loops through the classes and modifies them and check which are in each of the carAgeClasses. See F15:F18 in excel-sheet
  for (
    let i = vehicleAgeInMonths;
    i < leasingPeriod + vehicleAgeInMonths;
    i++
  ) {
    valueLossClasses.forEach(
      (item) => i >= item.min && i <= item.max && item.months++
    );
  }

  // Finding the surcharge of the value loss.
  let valueLossSurcharge = 0;
  valueLossClasses.forEach(
    (item) =>
    (valueLossSurcharge +=
      item.months * item.valueloss + item.months * item.kilometerloss)
  );

  valueLossSurcharge += endDepreciation;

  let valueLoss = Math.floor((vehiclePrice * valueLossSurcharge) / 10) * 10;
  //let valueLossEachMonth = valueLoss / leasingPeriod;
  restValueOutput.recomended = vehiclePrice - valueLoss;

  restValueOutput.totalNoVat = wishedRestValue || restValueOutput.recomended;

  restValueOutput.vat = isVatDeadVehicle
    ? 0
    : restValueOutput.totalNoVat * 0.25;

  restValueOutput.total = restValueOutput.totalNoVat + restValueOutput.vat;

  let valueLossSectionized = 0;
  let valueLossSectionizedMonthly = 0;

  valueLossSectionized = vehiclePrice - restValueOutput.totalNoVat;
  valueLossSectionizedMonthly = valueLossSectionized / leasingPeriod;

  let valueLossYear1 =
    leasingPeriod > 24
      ? valueLossSectionizedMonthly * 3 * 0.26
      : leasingPeriod > 12
        ? valueLossSectionizedMonthly * 2 * 0.4
        : valueLossSectionizedMonthly * 1;

  let valueLossYear2 =
    leasingPeriod <= 12
      ? 0
      : leasingPeriod > 24
        ? valueLossSectionizedMonthly * 3 * 0.3
        : leasingPeriod > 12
          ? valueLossSectionizedMonthly * 2 * 0.6
          : 0;

  let valueLossYear3 =
    leasingPeriod <= 24 ? 0 : valueLossSectionizedMonthly * 3 * 0.44;

  let ultimos = [];
  let primo = vehiclePrice;
  ultimos.push(primo);

  for (let i = 0; i <= leasingPeriod; i++) {
    let something = 0;

    if (i >= 0 && i <= 11) {
      something = valueLossYear1;
    } else if (i >= 12 && i <= 23) {
      something = valueLossYear2;
    } else {
      something = valueLossYear3;
    }

    primo = primo - something;
    ultimos.push(primo);
  }

  restValueOutput.deductionAnnually = roundNum(
    (vehiclePrice - restValueOutput.totalNoVat) / vehiclePrice,
    8
  );

  if (leasingPeriod && restValueOutput.deductionAnnually) {
    restValueOutput.deductionMonthly = roundNum(
      restValueOutput.deductionAnnually / leasingPeriod,
      8
    );
  }

  return restValueOutput;
};

const payoutCalc: LeasingCalcMethodType<PayoutOutputType> = ({
  input,
  output,
}) => {
  let payoutOutput = { ...initPayout };

  const { leasingPeriod, wishedPayout } = input;

  const { totalCost } = output;

  if (!leasingPeriod || !totalCost) throw new Error("Some values are missing!");

  const getRecommendedPayout = () => {
    if (leasingPeriod <= 12) {
      return Math.ceil((totalCost * 0.2) / 100) * 100;
    } else if (leasingPeriod <= 24) {
      return Math.ceil((totalCost * 0.25) / 100) * 100;
    }
    return Math.ceil((totalCost * 0.3) / 100) * 100;
  };

  payoutOutput.recomended = getRecommendedPayout();
  payoutOutput.totalNoVat = wishedPayout || payoutOutput.recomended;
  payoutOutput.vat = payoutOutput.totalNoVat * 0.25;
  payoutOutput.total = payoutOutput.totalNoVat + payoutOutput.vat;
  payoutOutput.selfFinancing = roundNum(
    1 - (totalCost - payoutOutput.totalNoVat) / totalCost,
    2
  );

  return payoutOutput;
};

const monthlyPaymentCalc: LeasingCalcMethodType<MonthlyPaymentOutputType> = (
  deal
) => {
  let monthlyPaymentOutput = { ...initMonthlyPayment };

  const { input, output, valueConfig: config } = deal;

  const {
    leasingPeriod,
    isVipDeal,
    vehicleAgeInMonths,
    isVatDeadVehicle,
    newTaxation,
    taxPrice,
    vehiclePrice,
  } = input;
  const interestLoanInternal = config.interestLoanInternal.value;
  const interestLoanMarginal = config.interestLoanMarginal.value;
  const { totalCost } = output;

  if (
    !isNum(leasingPeriod) ||
    !isNum(totalCost) ||
    !isNum(vehicleAgeInMonths) ||
    !isNum(interestLoanInternal) ||
    !isNum(interestLoanMarginal) ||
    !isNum(taxPrice) ||
    !isNum(vehiclePrice)
  )
    throw new Error("Some values are missing!");

  const addonMonthlyPaymentCalc = (): number => {
    const { gpsTrackerInput, gpsTrackerOutput } = deal;
    let addonMonthlyPayment = 0;

    if (gpsTrackerInput.active && gpsTrackerOutput.monthlyPayment) {
      addonMonthlyPayment += gpsTrackerOutput.monthlyPayment;
    }

    return addonMonthlyPayment;
  };

  const pmt = (): number => {
    const fv = output.restValue.totalNoVat;
    const pv = output.payout.totalNoVat - totalCost;
    const nper = leasingPeriod;
    const type = 1;
    const rate =
      (interestLoanInternal + (isVipDeal ? 0 : interestLoanMarginal)) / 12;

    if (rate == 0) return -(pv + fv) / nper;

    const pvif = Math.pow(1 + rate, nper);
    let pmt = (rate / (pvif - 1)) * -(pv * pvif + fv);

    if (type == 1) {
      pmt /= 1 + rate;
    }

    return pmt;
  };

  const taxationCalc = (): number | null => {
    return newTaxation;
    // 20-01-2023 - the user has to input it manually from now on - toc@dmf

    if ((vehicleAgeInMonths || 0) >= 36) {
      const rate = isVatDeadVehicle ? 1 : 1.25;
      const beskatningsgrundlag = roundNum((vehiclePrice || 0) * rate + (taxPrice || 0), 2);
      return beskatningsgrundlag < 160000 ? 160000 : beskatningsgrundlag;
    } else {
      return newTaxation;
    }
  };

  const deductionCalc = (): number => {
    const rate = vehicleAgeInMonths > 36 ? 0.01 : 0.02;
    const taxPriceVat = taxPrice * 0.25;
    return roundNum(taxPriceVat * rate, 2);
  };

  monthlyPaymentOutput.totalNoVat = Math.ceil(
    pmt() + addonMonthlyPaymentCalc()
  );
  monthlyPaymentOutput.vat = monthlyPaymentOutput.totalNoVat * 0.25;
  monthlyPaymentOutput.total =
    monthlyPaymentOutput.totalNoVat + monthlyPaymentOutput.vat;
  monthlyPaymentOutput.vatDeduction = deductionCalc();
  monthlyPaymentOutput.taxation = taxationCalc();

  return monthlyPaymentOutput;
};
