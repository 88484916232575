import { Button, message, Modal, Space, Form, Radio, Input, Divider } from "antd";
import { FC, useState } from "react";
import DividerLine from "../../../../components/lineSplit";
import SavedMessage from "../../../../components/messages/savedMessage";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { activateLeasingCase, expireLeasingCase } from "../../../../services/leasingService";
import LeasingAdminDealActiveInfo from "./leasingAdminDealActiveInfo";
import LeasingAdminDealExpireInfo from "./leasingAdminDealExpireInfo";

type ActionType = "expired" | "activated";

type FormType = {
  note: string,
  registration: string,
  registrationHandler: string,
  numberPlateSource: string,
  expirationAction: string
}

const LeasingActivationActions: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();
  const [actionType, setActionType] = useState<ActionType | null>(null);
  const [form] = Form.useForm<FormType>();
  const { active, expired, readonly } = leasingCase.flags;
  const disabled = loading;

  const handleAction = async (type: ActionType) => {
    const { id } = leasingCase;
    try {
      const values = form.getFieldsValue();
      const note = values.note
      setLoading(true);
      const { data } = type === "activated"
        ? await activateLeasingCase(id, {note, inquiries: [
          {question: "Registrering", answer: values.registration},
          {question: "Hvem foretager registrering?", answer: values.registrationHandler},
          {question: "Hvor afhentes der nummerplader?", answer: values.numberPlateSource},
        ]})
        : await expireLeasingCase(id, {note, inquiries: [{question: 'Hvad sker der ved udløb?', answer: values.expirationAction}]});
      setLeasingCase({ ...leasingCase, ...data });
      message.success(<SavedMessage />);
      setActionType(null);
    } catch ({ response }) {
      const { message: msg } = response?.data;
      message.error(msg || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  let modalInfo = null;
  if (actionType === "activated") {
    modalInfo = <LeasingAdminDealActiveInfo />
  } else if (actionType === "expired") {
    modalInfo = <LeasingAdminDealExpireInfo />
  }

  return (
    <>
      <Space>
        <Button type="primary" disabled={disabled} onClick={() => setActionType("expired")} danger>
          {expired ? _t("expire", "again") : _t("expire")}
        </Button>
        <Button type="primary" disabled={disabled || readonly} onClick={() => setActionType('activated')} color="green">
          {active ? _t("activate", "again") : _t("activate")}
        </Button>
      </Space>

      <Modal
        visible={!!actionType}
        confirmLoading={loading}
        onOk={() => actionType && handleAction(actionType)}
        okText={actionType === "activated" ? _t("activate", "deal") : _t("expire", "deal")}
        onCancel={() => setActionType(null)}
        cancelButtonProps={{danger: true, type: "primary"}}
        title={_t('confirm_please')}
        width={800}
        className="modal-justify-between-footer"
      >
        {modalInfo}
        <Divider />
        <Form form={form}
          layout="horizontal"
          labelCol={{ xs: 24, lg: 10, xl: 8, className: "text-left label-h-auto", }}
          wrapperCol={{ xs: 24, lg: 14, xl: 16 }}
          initialValues={{
            registration: "Omregistrering",
            registrationHandler: "DMF",
            numberPlateSource: "DMF",
            expirationAction: "På lager"
          }}
        >
          {actionType === "activated" ? (
            <>
              <Form.Item label="Registrering" rules={[{ required: true }]} name={["registration"]}>
                <Radio.Group
                  optionType="button"
                  buttonStyle={"solid"}
                  disabled={loading}
                >
                  <Radio.Button value={'Omregistrering'}>Omregistrering</Radio.Button>
                  <Radio.Button value={'Nye plader'}>Nye plader</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item label={"Hvem foretager registrering?"} rules={[{ required: true }]} name={["registrationHandler"]}>
                <Radio.Group
                  optionType="button"
                  buttonStyle={"solid"}
                  disabled={loading}
                >
                  <Radio.Button value={'DMF'}>DMF</Radio.Button>
                  <Radio.Button value={'Forhandler'}>Forhandler</Radio.Button>
                  <Radio.Button value={'Synshal'}>Synshal</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item label={"Hvor afhentes der nummerplader?"} rules={[{ required: true }]} name={["numberPlateSource"]}>
                <Radio.Group
                  optionType="button"
                  buttonStyle={"solid"}
                  disabled={loading}
                >
                  <Radio.Button value={'DMF'}>DMF</Radio.Button>
                  <Radio.Button value={'Forhandler'}>Forhandler</Radio.Button>
                  <Radio.Button value={'Synshal'}>Synshal</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </>
          ) : (
            <Form.Item label="Hvad sker der ved udløb?" rules={[{ required: true }]} name={["expirationAction"]}>
              <Radio.Group
                optionType="button"
                buttonStyle={"solid"}
                disabled={loading}
              >
                <Radio.Button value={'På lager'}>På lager</Radio.Button>
                <Radio.Button value={'Solgt'}>Solgt</Radio.Button>
                <Radio.Button value={'Debitorskifte'}>Debitorskifte</Radio.Button>
                <Radio.Button value={'Ny aftale'}>Ny aftale</Radio.Button>
              </Radio.Group>
            </Form.Item>

          )}
          <DividerLine style={{marginBlock: 16}}/>
          <Form.Item label={_t("eventually", "note_to_bookkeeping")} name="note">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LeasingActivationActions;
