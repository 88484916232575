import { Button, Col, Form, message, Row } from "antd";
import debounce from "lodash/debounce";
import React, { FC, useCallback, useState } from "react";
import SavedStatus from "../../../../components/savedStatus";
import ServerSelectBox from "../../../../components/serverSelectBox";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { merchantAutoComplete, merchantContactPersonsAutoComplete } from "../../../../services/autocompleteService";
import { updateImportMerchant } from "../../../../services/importService";
import { UpdateMerchantFormType } from "../../../../types/importTypes";
import consts from "../../../../utilities/consts";
import { compare } from "../../../../utilities/deepCompare";

interface ImportMerchantFormProps {
  stopChoosing: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImportMerchantChooseForm: FC<ImportMerchantFormProps> = ({ stopChoosing, setLoading }) => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();

  const [savedStatus, setSavedStatus] = useState<boolean>(true);
  const initValues = {
    merchantId: importCase.merchant?.id,
    merchantContactPersonId: importCase.merchantContactPersonId,
  };
  const handleSave = async (values: UpdateMerchantFormType) => {
    try {
      setLoading(true);
      const { data } = await updateImportMerchant(importCase.id, values);
      setImportCase(data);
      stopChoosing();
    } catch ({ response }) {
      message.error(response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFormChange = (changedValues: any, values: UpdateMerchantFormType) => {
    delayedSetMatched(values, importCase.vehicle);
  };

  const delayedSetMatched = useCallback(
    debounce((values: UpdateMerchantFormType, savedValues: any) => {
      const isMatched = compare(values, savedValues);
      if (isMatched !== savedStatus) setSavedStatus(isMatched);
    }, 500),
    [setSavedStatus, savedStatus]
  );

  return (
    <Form<UpdateMerchantFormType>
      {...consts.formItemProps}
      className="m-0"
      onValuesChange={handleFormChange}
      initialValues={initValues}
      requiredMark={false}
      onFinish={handleSave}
    >
      <Form.Item noStyle shouldUpdate>
        {({ setFieldsValue }) => (
          <Form.Item name="merchantId" label={_t("merchant")} rules={[{ required: true }]}>
            <ServerSelectBox
              allowClear
              addModalType="addMerchant"
              onItemAdded={(option, id) => {
                setFieldsValue({ merchantId: id, merchantContactPersonId: null });
              }}
              apiUrl={merchantAutoComplete()}
              onChange={() => {
                setFieldsValue({ merchantContactPersonId: null });
              }}
            />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.merchantId !== curValues.merchantId}>
        {({ getFieldValue, setFieldsValue }) => {
          const merchantId = getFieldValue("merchantId") || undefined;
          return (
            <Form.Item name="merchantContactPersonId" label={_t("user")}>
              <ServerSelectBox
                allowClear
                disabled={!merchantId}
                addModalType="addMerchantContact"
                addModalExtraValues={{ parentId: merchantId }}
                onItemAdded={(option, id) => {
                  setFieldsValue({ merchantContactPersonId: id });
                }}
                apiUrl={merchantContactPersonsAutoComplete()}
                filters={merchantId ? `filter[merchantId]=${merchantId}` : undefined}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ submit }) => (
          <Row justify="space-between">
            <Col>
              <Button onClick={stopChoosing}>{_t("cancel")}</Button>
            </Col>
            <Col>
              <SavedStatus status={savedStatus} />
              <Button type="primary" onClick={submit}>
                {_t("save")}
              </Button>
            </Col>
          </Row>
        )}
      </Form.Item>
    </Form>
  );
};

export default ImportMerchantChooseForm;
