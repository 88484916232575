import React, { FC, useState } from "react";
import { Alert, Button, Form, message, Modal } from "antd";
import _t from "../../../../lang/translate";
import { TaxRecalculateRequestType } from "../../../../types/taxTypes";
import { taxCaseRecalculateRequest } from "../../../../services/taxService";
import { isAxiosError } from "../../../../utilities/typeGuard";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import InputNum from "../../../../components/form/inputNum";
import TextArea from "antd/lib/input/TextArea";

const TaxOfferExpiredBtn: FC = () => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<TaxRecalculateRequestType>();

  const hideModal = () => setIsVisible(false);
  const showModal = () => setIsVisible(true);

  const onFinish = async (values: TaxRecalculateRequestType) => {
    try {
      setLoading(true);
      const { data } = await taxCaseRecalculateRequest(taxCase.id, values);
      setTaxCase(data);
      setLoading(false);
      message.success(_t("sent"));
      hideModal();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <>
      <Button type="primary" onClick={showModal} disabled={!taxCase.flags?.allowRecalculateRequest}>
        {_t("request_recalculation")}
      </Button>
      <Modal
        visible={isVisible}
        onCancel={hideModal}
        title={_t("request_recalculation")}
        okText={_t('request')}
        onOk={form.submit}
        destroyOnClose
        confirmLoading={loading}
      >
        <Alert message={_t('expired_legal_time_limits')} type="warning" className="mb-2" />

        <Form onFinish={onFinish} form={form} initialValues={{ mileage: null, note: "" }} layout="vertical">
          <Form.Item name="mileage" label={_t("mileage")} rules={[{ required: true }]}>
            <InputNum disabled={loading} step={1000} min={0} suffix={_t("mileage_unit")} />
          </Form.Item>

          <Form.Item name="reason" label={_t("condition_otherComments")} rules={[{ required: true, whitespace: true }]}>
            <TextArea disabled={loading} autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TaxOfferExpiredBtn;
