import { FC, useEffect } from "react";
import InputNum, { InputPriceProps } from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";

interface LeasingPeriodTaxFieldProps extends InputPriceProps {
  valueLossReference: number | null;
  interruptionPeriodTax: number | null;
  periodTax: number | null;
}

const LeasingPeriodTaxField: FC<LeasingPeriodTaxFieldProps> = ({
  valueLossReference,
  interruptionPeriodTax,
  periodTax,
  onChange,
  value,
  ...rest
}) => {
  useEffect(() => {
    const nextValue = (periodTax || 0) - (valueLossReference || 0) - (interruptionPeriodTax || 0);
    if (value !== nextValue && onChange) onChange(nextValue);
  }, [valueLossReference, interruptionPeriodTax, periodTax]);

  return <InputNum suffix={_t("dkk_price_short")} onChange={onChange} value={value} {...rest} disabled />;
};

export default LeasingPeriodTaxField;
