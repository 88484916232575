import React, { FC, useState } from "react";
import { Button, message } from "antd";
import Icon from "../../../assets/icons/icon";
import TaxDocCaseStore from "../../../contexts/taxDocCaseStore";
import _t from "../../../lang/translate";
import { getFileLink } from "../../../services/mediaService";

const TaxDocGuaranteeBtn: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  const { flags, links } = taxDocCase;
  const link = links?.guarantee;

  const downloadIcon = <Icon size="large" name="download-outline" />;

  const handleDownload = async () => {
    if (!link) return;
    try {
      setLoading(true);
      const { data } = await getFileLink(link);
      if (data.url) window.open(data.url, "_blank");
    } catch (ex) {
      message.error(_t("msg.link_not_generated"));
    } finally {
      setLoading(false);
    }
  };

  const generating = flags.generatingDocumentation;

  return (
    <Button
      type="primary"
      ghost
      disabled={!flags.documentationBought && !generating}
      icon={downloadIcon}
      onClick={handleDownload}
      loading={loading || generating}
    >
      {generating ? _t("generating", "guarantee") : _t("guarantee")}
    </Button>
  );
};

export default TaxDocGuaranteeBtn;
