import { Button, Col, Form, FormItemProps, Input, Row } from "antd";
import { FormListFieldData } from "antd/lib/form/FormList";
import { FC } from "react";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import { initContactPerson } from "../../types/appTypes";

const ContactPersonsFieldList: FC = () => {
  const getProps = (field: FormListFieldData, name: string) => {
    let props: FormItemProps = {
      ...field,
      name: [field.name, name],
      fieldKey: [field.fieldKey, name],
      noStyle: true,
    };
    if (name === "id") {
      props.hidden = true;
    }
    if (name === "name") {
      props.rules = nameFieldRules;
    }

    return props;
  };

  const nameFieldRules = [{ required: true, whitespace: true }];

  return (
    <Form.List name="contactPersons">
      {(fields, { add, remove }) => (
        <>
          <label className="mb-1 d-block">{_t("contact_persons")}</label>
          {fields.map((field) => (
            <Row key={field.key} className="flex-wrap mb-1">
              <Col hidden>
                <Form.Item {...getProps(field, "id")}>
                  <Input hidden />
                </Form.Item>
              </Col>
              <Col flex="auto">
                <Row gutter={[8, 8]}>
                  <Col xl={10} span={24}>
                    <Form.Item {...getProps(field, "name")}>
                      <Input placeholder={_t("fullname")} className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col xl={7} md={12} span={24}>
                    <Form.Item {...getProps(field, "email")}>
                      <Input placeholder={_t("email")} />
                    </Form.Item>
                  </Col>
                  <Col xl={7} md={12} span={24}>
                    <Form.Item {...getProps(field, "phone")}>
                      <Input placeholder={_t("phone")} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col flex="50px" className="text-right">
                <Button
                  className="muted delete-btn"
                  type="text"
                  tabIndex={-1}
                  shape="circle"
                  onClick={() => remove(field.name)}
                  icon={<Icon name="trash-outline" />}
                />
              </Col>
            </Row>
          ))}
          <Form.Item noStyle>
            <Button type="dashed" className="add-btn mb-0" onClick={() => add(initContactPerson)} block>
              {_t("add", "contact_person")}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default ContactPersonsFieldList;
