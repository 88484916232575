import { Button, Space } from "antd";
import { FC } from "react";
import Icon from "../../../../../assets/icons/icon";
import DividerLine from "../../../../../components/lineSplit";
import format from "../../../../../utilities/formatNumbers";

interface TradePricePreviewProps {
  onRefresh: () => void;
  value?: number | null;
}

const TradePricePreview: FC<TradePricePreviewProps> = ({ onRefresh, value }) => {
  return (
    <Button className="no-shadow pl-1 pr-05" onClick={onRefresh}>
      <Space>
        <span className="tezt-dark">{format.price(value || 0)}</span>
        <DividerLine type="vertical" className="m-0" />
        <Icon name="refresh-outline" size="medium" />
      </Space>
    </Button>
  );
};

export default TradePricePreview;
