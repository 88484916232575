import React, { FC, ReactNode } from "react";
import { Steps, Tooltip } from "antd";
import _t from "../../../../lang/translate";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import LogText from "../../../../components/logText";
import { LogObj } from "../../../../types/appTypes";

const { Step } = Steps;

const TaxProcessSteps: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const { log, flags } = taxCase;

  const getLogDescription = (log?: LogObj | null) => {
    return log ? <LogText className="p-0" logData={log} /> : <em>{_t("pending")}</em>;
  };

  const getStatus = (value?: boolean) => {
    return value ? "process" : "wait";
  };

  return (
    <Steps progressDot direction="horizontal" className="mb-1">
      <Step
        title={getTitle(_t("case_created"), getLogDescription(log?.creation))}
        status={getStatus(true)}
      />
      <Step
        title={getTitle(_t("pre_assessed"), getLogDescription(log?.offerPublished))}
        status={getStatus(flags.published)}
      />
      <Step
        title={getTitle(_t("accepted"), getLogDescription(log?.accepted))}
        status={getStatus(flags.accepted)}
      />
      {/* <Step
        title={getTitle(_t("documents", "received"), getLogDescription(log?.uploaded))}
        status={getStatus(flags.uploaded)}
        style={{whiteSpace:"nowrap"}}
      /> */}
      <Step
        title={getTitle(_t("paid"), getLogDescription(log?.paid))}
        status={getStatus(flags.paid)}
      />
      <Step
        title={getTitle(_t("evaluated"), getLogDescription(log?.receipt))}
        status={getStatus(flags.receipted)}
      />
    </Steps>
  );
};

export default TaxProcessSteps;

const getTitle = (title: ReactNode, tooltip: ReactNode) => {
  title = <span className="text-small">{title}</span>;
  if (!tooltip) return title;

  return <Tooltip title={tooltip}>{title}</Tooltip>;
};
