import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";
import { IdType, ModulTypes } from "../types/appTypes";
import { ReviewType, ReviewUpsertType } from "../types/reviewType";
import { PaginationedData } from "../types/apiTypes";
import { getApiQuery, pageSizeKey } from "./urlQueryService";

const apiEndpoint = `${getApiEndpoint()}/review`;

export function reviewsUrl(targetId: IdType, targetContext: ModulTypes) {
  // Add Page controlles
  return `${apiEndpoint}/${targetContext}/${targetId}`;
}

export function getReviews(targetId: IdType, targetContext: ModulTypes, query?: string) {
  if (!query || (query && !query.includes(pageSizeKey))) {
    query += `&${pageSizeKey}=10`;
  }
  // Add Page controlles
  return http.get<PaginationedData<ReviewType>>(`${apiEndpoint}/${targetContext}/${targetId}?${getApiQuery(query)}`);
}

export function storeReview(targetId: IdType, targetContext: ModulTypes, data: ReviewUpsertType) {
  return http.post<ReviewType>(reviewsUrl(targetId, targetContext), data);
}

export function updateReview(id: number, data: ReviewUpsertType) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function deleteReview(id: number) {
  return http.delete(`${apiEndpoint}/${id}`);
}
