import { FC, useEffect } from "react";
import format from "../../../../../utilities/formatNumbers";
import { isNum } from "../../../../../utilities/typeGuard";

interface TaxEquipmentsDiffCellProps {
  vehiclesEquipmentsPrice?: number | null;
  vehicleEquipmentsPrice?: number | null;
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const TaxEquipmentsDiffCell: FC<TaxEquipmentsDiffCellProps> = ({
  vehiclesEquipmentsPrice,
  vehicleEquipmentsPrice,
  value,
  onChange,
}) => {
  useEffect(() => {
    let nextValue = 0;
    if (isNum(vehiclesEquipmentsPrice) && isNum(vehicleEquipmentsPrice)) {
      nextValue = vehicleEquipmentsPrice - vehiclesEquipmentsPrice;
    }
    onChange && onChange(nextValue);
  }, [vehiclesEquipmentsPrice, vehicleEquipmentsPrice]);

  return <>{isNum(value) ? <span className="text-dark">{format.number(value)}</span> : "-"}</>;
};

export default TaxEquipmentsDiffCell;
