import { FC, useEffect } from "react";
import { InputPriceProps } from "../../../components/form/inputNum";
import InputPrice from "../../../components/form/inputPrice";
import _t from "../../../lang/translate";

interface InsurancePriceFieldProps extends InputPriceProps {
  isActive: boolean;
  vehiclePrice?: number | null;
}

const InsurancePriceField: FC<InsurancePriceFieldProps> = ({ isActive, onChange, vehiclePrice, value, ...rest }) => {
  useEffect(() => {
    if (isActive && !value && vehiclePrice && onChange) {
      onChange(vehiclePrice);
    }
  }, [isActive]);

  return <InputPrice suffix={_t("dkk_price_short")} value={value} onChange={onChange} {...rest} />;
};

export default InsurancePriceField;
