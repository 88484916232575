import React, { FC, ReactNode, useState } from "react";
import { Card, Result } from "antd";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import ImportRequestForm from "../admin/importOrderForm";
import ImportOrderedView from "./importOrderedView";
import ImportReqestedView from "./importReqestedView";

const ImportOrderView: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { requested, offerPublished, paid, takeHomeAgreementSigned, entryCertificateSigned, purchaseContractSigned } = importCase.flags;
  const [loading, setLoading] = useState<boolean>(false);
  let children: ReactNode;
  const isOrderStepsDone = paid && entryCertificateSigned && purchaseContractSigned && takeHomeAgreementSigned;

  if (!offerPublished) {
    children = <Result status="info" title={_t("pending", "offer")} />;
  } else if (requested && !isOrderStepsDone) {
    children = <ImportReqestedView />;
  } else if (isOrderStepsDone) {
    children = <ImportOrderedView />;
  } else {
    children = <ImportRequestForm setLoading={setLoading} loading={loading} />;
  }

  return (
    <Card bordered={false} title={_t("ordering")}>
      {children}
    </Card>
  );
};

export default ImportOrderView;
