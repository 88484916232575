import { Button, Col, Form, InputNumber, Row } from "antd";
import React, { FC } from "react";
import InputNum from "../../../../../components/form/inputNum";
import InputPrice from "../../../../../components/form/inputPrice";
import _t from "../../../../../lang/translate";
import CheckboxCell from "./checkboxCell";
import TaxEquipmentsTotalCell from "./taxEquipmentsTotalCell";
import TaxRecalculatedMileageCell from "./taxRecalculatedMileageCell";
import TaxRecalculatedPriceCell from "./taxRecalculatedPriceCell";
import Icon from "../../../../../assets/icons/icon";

interface TaxVehicleCellProps {
  row: any;
  index: number;
  vehicle: any;
  calculationDate?: string | null;
  toggleVehicleEquipments: () => void;
}

const TaxVehicleCell: FC<TaxVehicleCellProps> = ({ row, index, toggleVehicleEquipments, calculationDate, vehicle }) => {
  const { type, editable, equipment, id } = row || {};

  if (id === "equipment_list_title") {
    return (
      <Row justify="center" align="middle">
        <Col>
          <Button size="small" onClick={() => toggleVehicleEquipments()}>
            <Icon name="checkbox-outline" />
          </Button>
        </Col>
      </Row>
    );
  }

  if (editable && type === "year") {
    return (
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldValue }) => {
          const enabled = getFieldValue(["vehicles", index, "enabled"]);

          return (
            <Form.Item name={["vehicles", index, type]} noStyle>
              <InputNumber maxLength={4} disabled={!enabled} className="w-100" />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  if (editable && type === "price") {
    return (
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldValue }) => {
          const enabled = getFieldValue(["vehicles", index, "enabled"]);

          return (
            <Form.Item name={["vehicles", index, type]} noStyle>
              <InputPrice suffix="Kr." disabled={!enabled} />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  if (type === "recalculatedPrice") {
    // add shouldUpdate later for "curr.vehicles[index].year" and "curr.vehicles[index].price"
    return (
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldsValue }) => {
          const values = getFieldsValue(true);
          const vehicleYear = values.vehicle?.year;
          const year = values.vehicles[index]?.year;
          const price = values.vehicles[index]?.price;

          return (
            <Form.Item name={["vehicles", index, type]} noStyle>
              <TaxRecalculatedPriceCell year={year} vehicleYear={vehicleYear} price={price} />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  if (editable && type === "mileage") {
    return (
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldValue }) => {
          const enabled = getFieldValue(["vehicles", index, "enabled"]);

          return (
            <Form.Item name={["vehicles", index, type]} noStyle>
              <InputNum suffix={_t("mileage_unit")} disabled={!enabled} />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  if (type === "recalculatedMileage") {
    // add shouldUpdate later for "curr.vehicles[index].year" and "curr.vehicles[index].mileage"
    return (
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldsValue }) => {
          const values = getFieldsValue(true);
          const vehicleYear = values.vehicle?.year;
          const year = values.vehicles[index]?.year;
          const mileage = values.vehicles[index]?.mileage;

          return (
            <Form.Item name={["vehicles", index, type]} noStyle>
              <TaxRecalculatedMileageCell
                calculationDate={calculationDate}
                year={year}
                mileage={mileage}
                vehicleYear={vehicleYear}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  if (editable && type === "equipments" && equipment) {
    return (
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldValue }) => {
          const enabled = getFieldValue(["vehicles", index, "enabled"]);

          return (
            <Form.Item name={["vehicles", index, type]} noStyle>
              <CheckboxCell disabled={!enabled} equipmentId={equipment.id} />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  if (type === "equipmentsTotal" || type === "equipmentsTotalUsed") {
    return (
      <Form.Item noStyle shouldUpdate={true}>
        {({ getFieldsValue }) => {
          const values = getFieldsValue(true);
          const equipments = values.equipments;
          const vehicleEquipments = values.vehicles[index]?.equipments;

          return (
            <Form.Item name={["vehicles", index, type]} noStyle>
              <TaxEquipmentsTotalCell
                used={type === "equipmentsTotalUsed"}
                equipments={equipments}
                vehicleEquipments={vehicleEquipments}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }
  return <span>{null}</span>;
};

export default TaxVehicleCell;
