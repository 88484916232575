import { Button, Dropdown, Menu, Typography } from "antd";
import React, { FC } from "react";
import Icon from "../assets/icons/icon";
import UserStore from "../contexts/userStore";
import { AppRoleType } from "../types/permissionTypes";

const { Text } = Typography;

interface RoleSwitchProps {
  roles: AppRoleType[];
}

const RoleSwitch: FC<RoleSwitchProps> = ({ roles }) => {
  const { user, switchRole, claimedRole } = UserStore.useContainer();

  const rolesMenu = (
    <Menu style={{ width: 180 }}>
      <Menu.ItemGroup title="se som">
        {roles.map((role) => {
          let type: undefined | "success" | "danger";
          if (!claimedRole && role.id === user?.role.id) type = "success";
          if (claimedRole?.id === role.id) type = "danger";

          return (
            <Menu.Item
              key={role.key}
              onClick={() => {
                switchRole(role.id);
              }}
            >
              {
                <Text strong={role.id === user?.role.id} type={type}>
                  {role.title}
                </Text>
              }
            </Menu.Item>
          );
        })}
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Dropdown className="p-0" overlay={rolesMenu} placement="bottomCenter" arrow>
      <Button
        shape="circle"
        size="large"
        type="text"
        danger={!!claimedRole && user?.role.id !== claimedRole?.id}
        icon={<Icon name="settings-outline" />}
      />
    </Dropdown>
  );
};

export default RoleSwitch;
