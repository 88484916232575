import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { getSocketAuthEndpoint, getSocketHost, getSocketHostPort } from "./apiEndpoint";
import http from "./httpService";
import { getNotificationChannel } from "./authService";
import { Transport } from "pusher-js/types/src/core/config";

const authEndpoint = getSocketAuthEndpoint(),
  key = process.env.REACT_APP_PUSHER_KEY,
  cluster = process.env.REACT_APP_PUSHER_CLUSTER,
  headers = http.headers.common,
  notificationChannel = getNotificationChannel();

const initOptions = {
  broadcaster: "pusher",
  key,
  cluster,
  forceTLS: false,
  authEndpoint,
  wsHost: getSocketHost(),
  wsPort: parseInt(getSocketHostPort() || "80"),
  auth: { headers },
  disableStats: true,
  enabledTransports: ["ws", "wss"] as Transport[],
};

const options = {
  ...initOptions,
  client: new Pusher(key || "", initOptions),
};

const create = new Echo(options);

const connectToNotification = (socket: Echo, notify: (data: any) => void) => {
  if (key && notificationChannel) {
    socket.private(notificationChannel).notification(notify);
  }
};

const disconnect = (socket: Echo) => {
  socket.disconnect();
};

const items = {
  create,
  connectToNotification,
  disconnect,
};

export default items;
