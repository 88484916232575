import { Card, Space, Tabs } from "antd";
import { FC } from "react";
import Checklist from "../../components/checklist/checklist";
import _t from "../../lang/translate";
import DividerLine from "../../components/lineSplit";

const { TabPane } = Tabs;

const SystemChecklistDefault: FC = () => {
  return (
    <Card bodyStyle={{ paddingLeft: 0 }}>
      <Tabs tabBarGutter={4} size="middle" className="system-tabs" tabPosition="left">
        <TabPane tab={_t("checklists")} key={"checklists"} forceRender={true}>
          <h2>{_t("customize", "checklist")}</h2>
          <DividerLine />
          <Space size="middle" direction="vertical">
            <Checklist isAdmin={true} asDrawer context="leasing" btnText={_t("leasing", "checklist")} hideTotal />
            <Checklist isAdmin={true} asDrawer context="partnerLeasing" btnText={_t("partner_leasing", "checklist")} hideTotal />
            <Checklist isAdmin={true} asDrawer context="import" btnText={_t("import", "checklist")} hideTotal />
            <Checklist isAdmin={true} asDrawer context="tax" btnText={_t("tax", "checklist")} hideTotal />
          </Space>

        </TabPane>
      </Tabs>
    </Card>
  );
};

export default SystemChecklistDefault;
