import { Button, message } from "antd";
import { FC, useState } from "react";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { leasingCaseToPartnerFile } from "../../../../services/leasingService";
import { isAxiosError } from "../../../../utilities/typeGuard";

const DownloadPartnerCase: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const handlePartnerDownload = async () => {
    try {
      setLoading(true);
      const { data } = await leasingCaseToPartnerFile(leasingCase.id);
      data.url && window.open(data.url, "_blank");
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button loading={loading} onClick={handlePartnerDownload}>
      {_t("download_case_as_file")}
    </Button>
  );
};

export default DownloadPartnerCase;
