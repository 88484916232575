import { Button, Checkbox, Col, Dropdown, Form, Menu, Row } from "antd";
import { FC } from "react";
import Icon from "../../../../../assets/icons/icon";
import _t from "../../../../../lang/translate";

interface TaxCalcAdTitleProps {
  index: number;
  vehicle: any;
  onRemove: (id: number) => void;
}

const TaxCalcAdTitle: FC<TaxCalcAdTitleProps> = ({ index, vehicle, onRemove }) => {
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => onRemove(vehicle.id)}
        danger
        icon={<Icon name="trash-bin-outline" size="small" />}
      >
        {_t("delete")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Row justify="space-between" align="middle" className="flex-nowrap">
      <Col>
        <Form.Item name={["vehicles", index, "enabled"]} valuePropName="checked" noStyle>
          <Checkbox> {_t("ad_short") + " " + vehicle.id}</Checkbox>
        </Form.Item>
      </Col>
      <Col>
        <Dropdown placement="bottomRight" overlay={menu}>
          <Button shape="circle" type="text">
            <Icon name="ellipsis-vertical-sharp" />
          </Button>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default TaxCalcAdTitle;
