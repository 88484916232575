import { Form, Input } from "antd";
import { FC } from "react";
import ServerSelectBox from "../../../components/serverSelectBox";
import _t from "../../../lang/translate";
import { dealersAutoComplete, departmentsAutoComplete, rolesAutoComplete } from "../../../services/autocompleteService";
import {passwordRegex} from "../../../utilities/password";

interface UserFormFieldsProps {
  isNew?: boolean;
  hideDealer?: boolean;
}

const UserFormFields: FC<UserFormFieldsProps> = ({ isNew, hideDealer }) => {
  return (
    <>
      <Form.Item label={_t("fullname")} name="name" rules={[{ required: true }, { whitespace: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label={_t("initials")} name="initials">
        <Input maxLength={4} />
      </Form.Item>
      <Form.Item label={_t("role")} name="roleId" rules={[{ required: true }]} className="mb-3">
        <ServerSelectBox apiUrl={rolesAutoComplete()} />
      </Form.Item>
      <Form.Item label={_t("email")} name="email" rules={[{ required: true, type: "email" }]}>
        <Input disabled={!isNew} />
      </Form.Item>
      {isNew && (
        <Form.Item
          label={_t("password")}
          name="password"
          rules={[
            { required: true },
            { whitespace: true },
            { min: 8, message: _t('password_min_8') },
            { pattern: passwordRegex, message: _t('password_complexity') }
          ]}
          className="mb-3"
        >
          <Input.Password />
        </Form.Item>
      )}
      <Form.Item label={_t("phone")} name="phone" rules={[{ required: true, whitespace: true }]} className="mb-3">
        <Input />
      </Form.Item>

      {!hideDealer && (
        <Form.Item label={_t("dealer")} name="dealerId" rules={[{ required: true }]}>
          <ServerSelectBox apiUrl={dealersAutoComplete()} disabled={!isNew} />
        </Form.Item>
      )}

      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue, setFieldsValue }) => {
          const values = getFieldsValue(true);
          const dealerId = values.dealerId;
          if (!dealerId) return null;
          return (
            <Form.Item label={_t("departments")} name="departmentIds">
              <ServerSelectBox
                mode="multiple"
                addModalType="addDepartments"
                disabled={!dealerId}
                addModalExtraValues={{ parentId: dealerId }}
                onItemAdded={(_, id) => {
                  setFieldsValue({
                    departmentIds: id,
                  });
                }}
                filters={dealerId ? `filter[dealerId]=${dealerId}` : undefined}
                apiUrl={departmentsAutoComplete()}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </>
  );
};

export default UserFormFields;
