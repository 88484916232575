import React, { FC } from "react";
import { Col, Row, Space, Typography } from "antd";
import CardBox from "../../../../components/cardBox";
import LogText from "../../../../components/logText";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import TaxDocDocumentationBtn from "../taxDocDocumentationBtn";
import TaxDocGuaranteeBtn from "../taxDocGuaranteeBtn";

const TaxDocDownloadBtns: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  const { log } = taxDocCase;

  return (
    <CardBox
      className="pb-1"
      title={
        <Row justify="space-between" align="bottom">
          <Col>
            <Typography.Title
              level={4}
              type="secondary"
              className="mt-05 mb-05"
            >
              {_t("download")}
            </Typography.Title>
          </Col>
          <Col>
            {log?.documentationBought && (
              <LogText small logData={log?.documentationBought} />
            )}
          </Col>
        </Row>
      }
    >
      <Space direction="vertical">
        <TaxDocDocumentationBtn />
        <TaxDocGuaranteeBtn />
      </Space>
    </CardBox>
  );
};

export default TaxDocDownloadBtns;
