import React, { FC, useState } from "react";
import { Button, Col, message, Row, Spin, Typography } from "antd";
import { ChecklistEntryViewType } from "../../types/checklistTypes";
import CheckBoxRound from "../form/checkBoxRound";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import Expandable from "../expandable";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { updateChecklistEntryCompletion } from "../../services/checklistService";
import ChecklistUserStore from "../../contexts/checklistUserStore";
import { isAxiosError } from "../../utilities/typeGuard";

interface ChecklistItemProps {
  item: ChecklistEntryViewType;
}

const ChecklistUserItem: FC<ChecklistItemProps> = ({ item }) => {
  const { completed, groupId, id, description, title } = item;
  const { expanded, setExpanded, dispatchGroups } = ChecklistUserStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const isExpanded = expanded?.id === id && expanded.groupId === groupId;

  const toggleExpanded = () => {
    const nextState = isExpanded ? null : { groupId, id };
    setExpanded(nextState);
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={12} align="middle" className="flex-wrap">
        <Col flex="auto" style={{ minWidth: 0 }}>
          <CheckBoxRound checked={completed}>
            <Typography.Paragraph
              ellipsis={isExpanded ? false : { rows: 2, expandable: true, symbol: " ", tooltip: title }}
              className="m-0"
            >
              {title}
            </Typography.Paragraph>
          </CheckBoxRound>
        </Col>
        {description && (
          <Col>
            <Button
              type="text"
              onClick={toggleExpanded}
              shape="circle"
              size="small"
              className={`expand-chevron ${isExpanded ? "rotate-180" : ""}`}
              icon={<Icon name="chevron-down-outline" />}
            />
          </Col>
        )}
      </Row>
      {description && (
        <Expandable expanded={isExpanded}>
          <div className="bg-muted mt-1 mb-05 p-1 border-rounded">{description}</div>
        </Expandable>
      )}
    </Spin>
  );
};

export default ChecklistUserItem;
