import { Tabs } from "antd";
import { FC } from "react";
import DealerAdminStore from "../../../contexts/dealerAdminStore";
import _t from "../../../lang/translate";
import DealerAdminBonus from "./dealerAdminBonus";
import DealerAdminClips from "./dealerAdminClips";
import DealerAdminProducts from "./dealerAdminProducts";
import DealerAdminSettings from "./dealerAdminSettings";

const { TabPane } = Tabs;

const DealerAdminContent: FC = () => {
  const { admin } = DealerAdminStore.useContainer();

  return (
    <Tabs>
      <TabPane tab={_t("settings")} key="1" className="pb-05">
        <DealerAdminSettings />
      </TabPane>
      <TabPane tab={_t("products")} key="2" className="pb-05">
        <DealerAdminProducts />
      </TabPane>
      <TabPane tab={_t("clips")} key="3" className="pb-05">
        <DealerAdminClips />
      </TabPane>
      {admin.settings.accountingItemsEnabled && (
        <TabPane tab={_t("bonus")} key="4" className="pb-05">
          <DealerAdminBonus />
        </TabPane>
      )}
    </Tabs>
  );
};

export default DealerAdminContent;
