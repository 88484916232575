import { Button, Form, Input, Space } from "antd";
import React from "react";
import _t from "../../lang/translate";
import { exportDealersStats } from "../../services/statisticsService";
import { ExportDealersStatsType } from "../../types/dealerTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import DateRange from "../form/dateRange";
import { renderErrorMessage } from "../messages/errorMessage";

export default function ExportDealersStats<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<ExportDealersStatsType>();

  const handleAccept = async (values: ExportDealersStatsType) => {
    try {
      const { data } = await exportDealersStats(values);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="vertical" onFinish={handleAccept} initialValues={payload}>
      <h2 className="modal-title">{_t("export", "dealers")}</h2>
      <Form.Item name="date" rules={[{ required: true }]}>
        <DateRange />
      </Form.Item>
      <Form.Item label={_t("email")} name="email" rules={[{ type: "email" }, { required: true }]}>
        <Input />
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" type="primary">
            {_t("accept")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ExportDealersStats);
