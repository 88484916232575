import React, { FC, ReactNode, useEffect } from "react";
import "./assets/style/app.less";
import LayoutStore from "./contexts/layoutStore";
import UserStore from "./contexts/userStore";
import UnauthorizedApp from "./screens/unauthorizedApp";
import AuthorizedApp from "./screens/authorizedApp";
import CookieModal from "./components/cookieModal";
import eventTracker from "./services/eventsTrackerService";

interface AppWrapperProps {
  children: ReactNode;
}

const AppWrapper: FC<AppWrapperProps> = ({ children }) => {
  const { isAuth } = UserStore.useContainer();

  useEffect(() => {
    eventTracker.initialize();
  }, []);

  return (
    <LayoutStore.Provider>
      {isAuth ? <AuthorizedApp>{children}</AuthorizedApp> : <UnauthorizedApp>{children}</UnauthorizedApp>}
      <CookieModal />
    </LayoutStore.Provider>
  );
};

export default AppWrapper;
