import React, { FC, useEffect } from "react";
import { ScreenProps } from "../types/appTypes";
import { AuthResponseData, handleNewLogin } from "../services/authService";

const DMF_PRO_ORIGIN = process.env.REACT_APP_DMF_PRO_ORIGIN

const LoginFromDMFPro: FC<ScreenProps> = () => {
  useEffect(() => {
    function listenToMessageFromDMFPro(e: MessageEvent<{data: AuthResponseData, headers: any}>) {
      if (e.origin !== DMF_PRO_ORIGIN) {
        return; // Wrong origin
      }
      try {
        handleNewLogin(e.data.data, e.data.headers)
      } catch(err) {
        console.error("line 16, login-from-dmf-pro.tsx", err)
      }
      finally {
        window.parent.postMessage({completed: true}, "*")
      }
    }

    window.addEventListener('message', listenToMessageFromDMFPro)
    return () => {
      window.removeEventListener('message', listenToMessageFromDMFPro)
    }
  }, []);

  return (
    <></>
  )
};

export default LoginFromDMFPro;
