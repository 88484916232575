import React, { FC } from "react";
import { Space, Typography } from "antd";
import _t from "../../../../lang/translate";
import ImportAutoFetchMobileDe from "./importAutoFetchMobileDe";
import ImportAutoFetchDatDe from "./importAutoFetchDatDe";

const ImportAutoFetch: FC = () => {
  return (
    <>
      <Typography.Title level={5}>{_t("auto", "download")}</Typography.Title>
      <Space className="mb-05" direction="vertical">
        <ImportAutoFetchMobileDe />
        <ImportAutoFetchDatDe />
      </Space>
    </>
  );
};

export default ImportAutoFetch;
