import { IdType } from "./appTypes";
import { PaymentCardBrandType } from "./paymentCardType";

export interface PaymentMethodIntentType {
  code: string
}

export interface PaymentMethodDetailsType {
  type: PaymentCardBrandType | null
  lastFour: string | null
}

export interface SubscriptionInvoiceType {
  total: number;
  url: string;
  paid: boolean;
  createdAt: string;
}

export interface SubscriptionPriceChangeType {
  lines: [
    {
      description: string,
      taxes: number,
      amountWithTax: number,
      amountExcludingTax: number
    }
  ],
  isUpgrade: boolean,
  dateToChange: string,
  paymentDate: string;
  taxes: number,
  totalWithTax: number,
  discountAmount: number,
  totalExcludingTax: number,
  validCoupon: boolean;
  trialDays: number;
}

export interface SubscriptionPackageType {
  id: IdType;
  title: string | null;
  description: string | null;
  creditsPerPeriod: number;
  selectable: boolean;
  recommended: boolean;
  productCode: string;
  features: string[] | null;
  level: number;
  currency: string;
  priceWithVat: number;
  priceNoVat: number;
}
export enum SubscriptionPackageProductCode {
  FREE = "free",
  LIGHT = "light",
  STANDARD = "standard",
  PROFESSIONAL = "professional",
  ENTERPRISE = "enterprise"
}

export interface SubscriptionType {
  id: IdType;
  package: null | SubscriptionPackageType;
  isExpired: boolean;
  isActive: boolean;
  isTrial: boolean;
  isPastDue: boolean;
  trialEndsAt: string | null;
  endsAt: string | null;
}

export interface SubscriptionSelectFormType {
  subscriptionPackageId: IdType;
  paymentMethodId?: string;
  coupon: string | null;
}
