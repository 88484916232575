import { Col, Row } from "antd";
import { FC } from "react";
import MerchantCasesCard from "./merchantCasesCard";
import MerchantContactPersons from "./merchantContactPersons";
import MerchantInfoCard from "./merchantInfoCard";

const MerchantContent: FC = () => {
  return (
    <Row align="top" gutter={24}>
      <Col xxl={{ span: 10, offset: 3 }} xl={{ span: 14 }} xs={{ span: 24 }}>
        <MerchantInfoCard />
        <MerchantCasesCard />
      </Col>
      <Col xxl={{ span: 8 }} xl={{ span: 10 }} xs={{ span: 24 }}>
        <MerchantContactPersons />
      </Col>
    </Row>
  );
};

export default MerchantContent;
