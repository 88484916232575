import { Button, List, Typography } from "antd";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import CollapseCard from "../../../components/collapseCard";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { postsUrl } from "../../../services/postService";
import { useApi } from "../../../services/useApi";
import { PostsType } from "../../../types/postTypes";
import format from "../../../utilities/formatNumbers";
import DividerLine from "../../../components/lineSplit";
import DashboardNewsletterToggle from "./dashboardNewsletterToggle";

const { Paragraph } = Typography;

const DashboardNews: FC = () => {
  const [{ data, isLoading }] = useApi<{ data: PostsType[] }>(postsUrl("pageSize=3&filter[isArchived]=0"), {
    data: [],
  });
  const dataSource = data.data;

  const loadMoreBtn = (
    <div className="text-center">
      <Button type="link">
        <Link to={getPath("post")}>{_t("read", "more")}</Link>
      </Button>
    </div>
  );

  const renderListItem = (item: PostsType) => (
    <List.Item>
      <h3>
        <Link to={getPath("post", item.id)}>{item.title}</Link>
      </h3>
      <small>{format.dateAndTime(item.date)}</small>
      <Paragraph ellipsis={{ rows: 3 }}>
        <span dangerouslySetInnerHTML={{ __html: item.content || "" }} />
      </Paragraph>
    </List.Item>
  );

  return (
    <CollapseCard className="mb-0" loading={isLoading} title={_t("news")}>
      <div className="card-body">
        <List<PostsType>
          itemLayout="vertical"
          size="large"
          dataSource={dataSource}
          loadMore={loadMoreBtn}
          renderItem={renderListItem}
        />
      </div>

      <DividerLine fluid={true} />
      <div className="text-right">
        <DashboardNewsletterToggle />
      </div>
    </CollapseCard>
  );
};

export default DashboardNews;
