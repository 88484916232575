import { notification } from "antd";
import React, { FC, ReactNode, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import appConfig from "../appConfig";
import _t from "../lang/translate";
import { routes } from "../routes/appRoutes";
import ErrorBoundary from "../screens/errorBoundary";
import NotFound from "../screens/notFound";
import Unauthorized from "../screens/unauthorized";
import { LocationStateType } from "../types/appTypes";
import { capitalize } from "../utilities/stringExtraFunctions";

interface ScreenProps {
  children: ReactNode;
}

const Notification = notification;

const Screen: FC<ScreenProps> = ({ children }) => {
  let { id } = useParams<{ id: string }>();
  let location = useLocation<LocationStateType>();

  useEffect(() => {
    const notification = location.state?.notification;
    const { message, description, type } = notification || {};
    if (notification && type) {
      Notification[type]({
        message,
        description,
      });
    }
    const pathname = location.pathname.split("/")[1];
    const currentRoute = routes.find((obj) => obj.path === `/${pathname}`);
    let title = [];
    if (id) title.push(capitalize(id));
    if (currentRoute) title.push(currentRoute.title);
    title.push(appConfig("companyName"));

    document.title = title.join(" - ");
  }, [location, id]);

  /*   useEffect(() => {
    if (!id) return;

    const handleEsc = (e: any) => {
      if (e.keyCode === 27) {
        history.goBack();
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [id, history]); */

  switch (location.state?.errorStatusCode) {
    case 404:
      return <NotFound title={_t("msg.not_found")} />;
    case 403:
      return <Unauthorized title={_t("msg.unauthorized")} />;

    // ... cases for other types of errors

    default:
      return <ErrorBoundary>{children}</ErrorBoundary>;
  }
};

export default Screen;
