import { Col, Row, Space } from "antd";
import { FC } from "react";
import DealerAdminStore from "../../../contexts/dealerAdminStore";
import _t from "../../../lang/translate";
import DealerAdminBonusOverview from "./dealerAdminBonusOverview";
import DealerAdminBonusUpdate from "./dealerAdminBonusUpdate";

const DealerAdminBonus: FC = () => {
  const { admin } = DealerAdminStore.useContainer();
  return (
    <Row justify="space-between">
      <Col>
        <span className="text-larger text-dark">{admin.bonusAmount}</span> {_t("dkk_price_short")}
      </Col>
      <Col>
        <Space>
          <DealerAdminBonusUpdate />
          <DealerAdminBonusOverview />
        </Space>
      </Col>
    </Row>
  );
};

export default DealerAdminBonus;
