import { Button, Checkbox, Form, Input, Space } from "antd";
import React, { useState } from "react";
import _t from "../../lang/translate";
import { rejectValidation } from "../../services/leasingService";
import { RejectValidationType } from "../../types/leasingTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { renderErrorMessage } from "../messages/errorMessage";

const { TextArea } = Input;

export default function RejectLeasingCase<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<RejectValidationType>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleReject = async (values: any) => {
    const { id } = payload;
    try {
      setLoading(true);
      const { data } = await rejectValidation(id, values);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
      setLoading(false);
    }
  };

  return (
    <Form form={tmpForm} requiredMark={false} layout="vertical" onFinish={handleReject}>
      <h2 className="modal-title">{_t("reject", "case")}</h2>
      <Form.Item rules={[{ required: true }]} name="note" label={_t("note")}>
        <TextArea />
      </Form.Item>
      <Form.Item name="lock" valuePropName="checked" initialValue={false}>
        <Checkbox>{_t("lock")}</Checkbox>
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel} disabled={loading}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            {_t("reject")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(RejectLeasingCase);
