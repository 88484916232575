import { FC, useState } from "react";
import { Button, Modal } from "antd";
import _t from "../../../../lang/translate";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import VehicleInfo from "../../../../components/vehicleInfoViews/vehicleInfo";

const TaxDocVehicleSourceModal: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();
  const { vehicleDataSource } = taxDocCase;
  const [visible, setVisible] = useState<boolean>(false);
  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return (
    <>
      <Button onClick={openModal}>{_t("dealers_data")}</Button>
      <Modal visible={visible} footer={null} onCancel={closeModal} title={_t("dealers_data")}>
        <VehicleInfo vehicle={vehicleDataSource} />
      </Modal>
    </>
  );
};

export default TaxDocVehicleSourceModal;
