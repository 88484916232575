import React, { FC, useState } from "react";
import CollapseCard from "../../../../components/collapseCard";
import _t from "../../../../lang/translate";
import ImportReportForm from "./importReportForm";
import ImportReportInfo from "./importReportInfo";

const ImportReportEditableCard: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <CollapseCard title={_t("report")} loading={loading}>
      {isEditing ? (
        <ImportReportForm stopEditing={stopEditing} setLoading={setLoading} />
      ) : (
        <ImportReportInfo startEditing={startEditing} />
      )}
    </CollapseCard>
  );
};

export default ImportReportEditableCard;
