export function extractDataFromObj<T>(obj: any, path: string[]) {
  if (!path || !obj) return null;

  let results: any = { ...obj };
  for (const key of path) {
    if (key in results) {
      results = results[key];
    } else {
      return null;
    }
  }
  if (results) return results as T;

  return null;
}
