import { Space } from "antd";
import { FC } from "react";
import LeasingStore from "../../../../contexts/leasingStore";
import { MotorAgencyDataType } from "../../../../types/leasingTypes";
import MotorAgencyHistory from "./motorAgencyHistory";
import MotorAgencyReportCheck from "./motorAgencyReportCheck";
import MotorAgencyReportInfo from "./motorAgencyReportInfo";

const MotorAgencyReportView: FC = () => {
  const { leasingCase, adminValues, setAdminValues } = LeasingStore.useContainer();
  const { motorAgency } = adminValues || {};
  const vin = leasingCase.vehicle?.vin;

  const handleNewReport = (motorAgency: MotorAgencyDataType) => {
    adminValues && setAdminValues({ ...adminValues, motorAgency });
  };

  return (
    <>
      <MotorAgencyReportInfo report={motorAgency} />
      <div className="text-right">
        <Space>
          <MotorAgencyHistory
            vin={vin}
            disabled={!leasingCase.id || !vin}
            activeMotorAgencyDataId={motorAgency?.id || null}
            leasingCaseId={leasingCase.id}
            onActiveChange={handleNewReport}
          />
          <MotorAgencyReportCheck disabled={!leasingCase.id || !vin} />
        </Space>
      </div>
    </>
  );
};

export default MotorAgencyReportView;
