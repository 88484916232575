import React, { FC } from "react";
import Loading from "./loading";

interface AnimateInProps {
  start?: boolean;
  forceRender?: boolean;
  loadingBeforeStart?: boolean;
}

const AnimateIn: FC<AnimateInProps> = ({
  children,
  forceRender,
  loadingBeforeStart,
  start = true,
}) => {
  return (
    <div className={`animate-in${start ? " start-animate-in" : ""}`}>
      {loadingBeforeStart ? <Loading /> : null}
      <div className="animate-content">
        {start || forceRender ? children : null}
      </div>
    </div>
  );
};

export default AnimateIn;
