import { FC } from "react";
import { ScreenProps } from "../../../types/appTypes";
import PageTitle from "../../../layout/pageTitle";
import PageBody from "../../../layout/pageBody";
import _t from "../../../lang/translate";
import LimitedAccessMessageBox from "../../../components/subscription/limitedAccessMsgBox";
import { Alert, Col, Row } from "antd";

const TaxCaseCreated: FC<ScreenProps> = (props) => {
  return (<>
    <PageTitle
      title={`${props.title}`}
      fluid
      headerMargin={false}
      backBtn
    />
    <PageBody>
      <Row justify="center">
        <Col span={24} xl={16}>
          <Alert
            banner
            type="success"
            message={_t("promo.case_received")}
            className="mb-2"
          />
          <LimitedAccessMessageBox
            customMsgProps={{
              theme: "light",
              className: "p-2 pt-0"
            }}
            productType="tax.view_case"
          />
        </Col>
      </Row>
    </PageBody>
  </>
  )
};

export default TaxCaseCreated;
