import { FC } from "react";
import { Rate, RateProps } from "antd";
import ReviewHistoryButton from "./review/reviewHistoryButton";
import { IdType, ModulTypes } from "../types/appTypes";

interface RateInputProps extends RateProps {
  size?: "small" | "default" | "large";
  showHistory?: boolean;
  targetId?: IdType;
  targetContext?: ModulTypes;
  scoreClassName?: string;
  onNewRating?: (score: number) => void;
}

const RateInput: FC<RateInputProps> = ({
  value,
  scoreClassName,
  className,
  size,
  showHistory,
  targetId,
  onNewRating,
  targetContext,
  disabled,
  ...rest
}) => {
  let nextClassName = className || "";
  if (size === "small") {
    nextClassName += " text-larger";
  }
  if (size === "large") {
    nextClassName += " text-30";
  }

  return (
    <>
      <Rate className={nextClassName} value={value} disabled={disabled} {...rest} />{" "}
      {showHistory && targetId && targetContext ? (
        <ReviewHistoryButton
          targetId={targetId}
          targetContext={targetContext}
          rating={value || 0}
          onNewRating={onNewRating}
          disabled={disabled}
        />
      ) : (
        <span className={`${size === "large" ? "text-huge" : "text-large"} pl-05 text-dark`}>{value || 0}</span>
      )}
    </>
  );
};

export default RateInput;
