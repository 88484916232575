import React, { FC, useState } from "react";
import { Button, Col, message, Modal, Row } from "antd";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { isAxiosError } from "../../../utilities/typeGuard";
import { generatePaymentAdviceFirstTimePreview, sendPaymentAdviceFirstTimeEmail } from "../../../services/leasingService";


const LeasingGeneratePaymentAdviceFirstTimeDocument: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const closeModal = () => setShowModal(false)

  const disabled = !leasingCase.flags.validationStarted;

  const handlePreview = async () => {
    try {
      setLoading(true);
      const { data } = await generatePaymentAdviceFirstTimePreview(leasingCase.id);
      if (data.url) {
        window.open(data.url, '_blank');
      }
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async () => {
    try {
      setLoading(true);
      await sendPaymentAdviceFirstTimeEmail(leasingCase.id);
      closeModal();
      message.success(_t("sent"));
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button loading={loading} disabled={disabled} onClick={() => setShowModal(true)}>
        {_t("paymentAdviceDocumentFirstTime")}
      </Button>

      <Modal
        confirmLoading={loading}
        width={800}
        visible={showModal}
        onCancel={closeModal}
        title={_t("paymentAdviceDocumentFirstTime")}
        okText={_t("send", 'paymentAdviceDocumentFirstTime')}
        onOk={handleSend}
      >
        <Row>
          <Col span={24} className="text-right pt-1">
            <Button type="link" size="small" onClick={handlePreview}>
              {_t('preview')}
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default LeasingGeneratePaymentAdviceFirstTimeDocument;
