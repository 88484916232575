import React, { FC } from "react";
import { ParamTypes, ScreenProps } from "../../../../types/appTypes";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../services/useApi";
import { initResellerTaxCase, ResellerTaxCase } from "../../../../types/resellerTaxTypes";
import { resellerTaxCaseUrl } from "../../../../services/resellerTaxService";
import PageTitle from "../../../../layout/pageTitle";
import ActiveUsers from "../../../../components/activeUsers";
import PageBody from "../../../../layout/pageBody";
import AnimateIn from "../../../../components/animateIn";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import ResellerTaxCaseHeadExtra from "../resellerTaxCaseHeadExtra";
import ResellerTaxCaseEdit from "./resellerTaxCaseEdit";

const ResellerTaxCaseAdmin: FC<ScreenProps> = ({ title }) => {
    const { id } = useParams<ParamTypes>();
    const [{ data, isLoading }] = useApi<ResellerTaxCase>(resellerTaxCaseUrl(id), initResellerTaxCase, true);
    const caseReceived = data.id > 0;

    return (
        <ResellerTaxCaseStore.Provider initialState={data}>
            <PageTitle
                title={`${title}/${id}`}
                fluid
                headerMargin={false}
                backBtn
                extra={<ActiveUsers context="resellerTax" />}
                footer={caseReceived && <ResellerTaxCaseHeadExtra />}
            />
            <PageBody>
                <AnimateIn start={!isLoading && caseReceived} loadingBeforeStart>
                    <ResellerTaxCaseEdit />
                </AnimateIn>
            </PageBody>
        </ResellerTaxCaseStore.Provider>
    );
};

export default ResellerTaxCaseAdmin;
