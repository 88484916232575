import getApiEndpoint from "./apiEndpoint";

export const dealersAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/dealers?${q}`;

export const billingDestinationsAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/billingDestinations?${q}`;

export const customersAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/customers?${q}`;

export const customerContactPersonsAutoComplete = (q = "") =>
  `${getApiEndpoint()}/autocomplete/customerContactPersons?${q}`;

export const merchantAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/merchants?${q}`;

export const merchantContactPersonsAutoComplete = (q = "") =>
  `${getApiEndpoint()}/autocomplete/merchantContactPersons?${q}`;

export const departmentsAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/departments?${q}`;

export const usersAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/users?${q}`;

export const brandsAutoComplete = (q = "", year: number | string = '', typeId: number | string = '') => {
  return `${getApiEndpoint()}/autocomplete/brands?filter[year]=${year}&filter[vehicleTypeId]=${typeId}&${q}`;
};

export const fuelsAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/vehicleFuels?${q}`;

export const typesAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/vehicleTypes?${q}`;

export const modelsAutoComplete = (q = "", makeId = 0, year: number | string = '', typeId: number | string = '') => {
  return `${getApiEndpoint()}/autocomplete/models?filter[year]=${year}&filter[vehicleTypeId]=${typeId}&filter[vehicleMakeId]=${makeId}&${q}`;
};

export const countriesAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/countries?${q}`;

export const rolesAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/roles?${q}`;

export const insuranceCompaniesAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/insuranceCompanies?${q}`;

export const dealerCategoriesAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/dealerCategories?${q}`;

export const checkListGroupsAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/checkListGroups?${q}`;

export const refundReasonsAutoComplete = (q = "") => `${getApiEndpoint()}/autocomplete/refundReasons?${q}`;

export const creditPortalLinkTemplates = (q = "") => `${getApiEndpoint()}/autocomplete/creditValidationTemplates?${q}`;

export const digitalSignatureTemplates = (q = "") => `${getApiEndpoint()}/autocomplete/digitalSignatureTemplates?${q}`;
