import React, { FC } from "react";
import { Vehicle } from "../../types/appTypes";
import _t from "../../lang/translate";
import format from "../../utilities/formatNumbers";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";

interface VehicleInfoProps {
  data: Vehicle
}

const VehicleCatalogVehicleInfo: FC<VehicleInfoProps> = ({ data }) => {
  const { brand, model, equipmentVariant, type, useCase, horsePower, fuel, firstRegDate, vin, fuelEfficiency } = data;

  return (
    <>
      <Title level={4}>{_t('info_from_dmr')}:</Title>
      <div className="mb-05">
        <Title level={5} className="mb-0">{`${_t('brand')}, ${_t('model')}, ${_t('variant')}`}</Title>
        <Text>{[brand, model, equipmentVariant].filter(x => !!x).join(', ')}</Text>
      </div>

      <div className="mb-05">
        <Title level={5} className="mb-0">{`${_t('type')}`}</Title>
        <Text>{[type, useCase].filter(x => !!x).join(', ')}</Text>
      </div>

      <div className="mb-05">
        <Title level={5} className="mb-0">{`${_t('horse_power')}`}</Title>
        <Text>{horsePower}</Text>
      </div>

      <div className="mb-05">
        <Title level={5} className="mb-0">{`${_t('fuel_efficiency')}`}</Title>
        <Text>{format.number(fuelEfficiency)}</Text>
      </div>

      <div className="mb-05">
        <Title level={5} className="mb-0">{`${_t('fuel')}`}</Title>
        <Text>{fuel}</Text>
      </div>

      <div className="mb-05">
        <Title level={5} className="mb-0">{`${_t('firstRegDate')}`}</Title>
        <Text>{format.date(firstRegDate)}</Text>
      </div>

      <div className="mb-05">
        <Title level={5} className="mb-0">{`${_t('vin')}`}</Title>
        <Text>{vin}</Text>
      </div>
    </>
  )
};

export default VehicleCatalogVehicleInfo;
