import React, { FC } from "react";
import { Card } from "antd";
import _t from "../../../../lang/translate";
import TaxDocDownloads from "./taxDocDownloads";
import TaxDocProcessSteps from "./taxDocProcessSteps";

const TaxDocProcess: FC = () => {
  return (
    <Card bordered={false} title={_t("process")}>
      <TaxDocProcessSteps />
      <TaxDocDownloads />
    </Card>
  );
};

export default TaxDocProcess;
