import { FC } from "react";
import { ScreenProps } from "../../../../types/appTypes";

const ResellerTaxCaseUser: FC<ScreenProps> = ({ title }) => {
  return (
    <>{title}</>
  );
};

export default ResellerTaxCaseUser;
