import { FC } from "react";
import { ReviewType } from "../../types/reviewType";
import ReviewItemEdit from "./reviewItemEdit";
import ReviewItemView from "./reviewItemView";

interface ReviewItemProps {
  review: ReviewType;
  isEditing: boolean;
  startEditing: () => void;
  stopEditing: () => void;
  onNewScore: (newReview: ReviewType, data: number) => void;
}

const ReviewItem: FC<ReviewItemProps> = ({ review, isEditing, startEditing, stopEditing, onNewScore }) => {
  return isEditing ? (
    <ReviewItemEdit stopEditing={stopEditing} onNewScore={onNewScore} review={review} />
  ) : (
    <ReviewItemView startEditing={startEditing} review={review} />
  );
};

export default ReviewItem;
