import React, { FC, MouseEventHandler, useEffect, useState } from "react";
import { Button, Divider, Menu, Popover } from "antd";
import _t from "../../lang/translate";
import PageTitle from "../../layout/pageTitle";
import { ScreenProps } from "../../types/appTypes";
import { useHistory } from "react-router-dom";
import VehicleCatalog from "./vehicleCatalog";

interface CreateButtonProps {
  title: string;
  popupContent: string;
  disabled: boolean;
  onClick: MouseEventHandler;
}

const CreateButton: FC<CreateButtonProps> = ({ title, onClick, popupContent, disabled }) => {
  const button = (
    <Button
      type="primary"
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </Button>
  )

  return disabled ? (
    <Popover placement="left" content={popupContent}>
      {button}
    </Popover>
  ) : button;
}

const VehicleCatalogPage: FC<ScreenProps> = ({ title }) => {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState<string>('variant');
  const [createEnabled, setCreateEnabled] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const currentUrl = window.location.toString();
    const url = new URL(currentUrl);
    const wantedTab = url.searchParams.get('tab');
    if (wantedTab) {
      setCurrentTab(wantedTab)
    }
  }, []);

  useEffect(() => {
    const currentUrl = window.location.toString();
    const url = new URL(currentUrl);
    url.searchParams.set('tab', currentTab)
    history.replace(url.toString().replace(url.origin, ''));
  }, [currentTab, history]);

  return (
    <>
      <PageTitle
        fluid
        title={title}
        className="fluid-page-title pb-0"
        extra={
          <CreateButton title={_t('add')} popupContent={_t('add')} disabled={!createEnabled}
            onClick={() => setShowModal(true)} />
        }
      >
        <Menu mode="horizontal" className={"border-0"}
          selectedKeys={[currentTab]}
          onSelect={({ item, key }) => setCurrentTab(key)}>
          <Menu.Item key="variant">
            {_t('variant', 'catalog')}
          </Menu.Item>
          {/* TODO: Disabled for now
          <Menu.Item key="equipment">
            {_t('equipment', 'catalog')}
          </Menu.Item>
          */}

          <Menu.Item key="equipment_divider" className={"p-0"}>
            <Divider type="vertical" className={"p-0"} />
          </Menu.Item>

          <Menu.Item key="type">
            {_t('type')}
          </Menu.Item>

          <Menu.Item key="make">
            {_t('brand')}
          </Menu.Item>

          <Menu.Item key="model">
            {_t('model')}
          </Menu.Item>

          <Menu.Item key="fuel">
            {_t('fuel')}
          </Menu.Item>
        </Menu>
      </PageTitle>

      <VehicleCatalog
        currentTab={currentTab}
        showCreateModal={showModal}
        hideCreateModal={() => setShowModal(false)}
        disableCreateBtn={() => setCreateEnabled(false)}
        enableCreateBtn={() => setCreateEnabled(true)}
        withActions={true}
      />
    </>
  );
};

export default VehicleCatalogPage;
