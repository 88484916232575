import { Button, Form, Input, message, Result, Space } from "antd";
import React, { useState } from "react";
import _t from "../../lang/translate";
import { saveDealerDepartment } from "../../services/dealerService";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";

export default function ModalDepartments<T extends object>({
  onSuccess,
  onCancel,
  payload,
}: OpenModalProps<T>) {
  const [departmentForm] = Form.useForm<T>();
  const [loading, setLoading] = useState<boolean>(false);
  const { parentId, initialValues } = payload;

  const handleSubmit = async (values: T) => {
    setLoading(true);
    try {
      const { data } = await saveDealerDepartment<T>(payload.parentId, values);
      onSuccess(data);
    } catch ({ response }) {
      const { message: msg, errors } = response?.data || {};
      message.error(msg);
      departmentForm.setFields(errors);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={departmentForm}
      requiredMark={false}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <h2 className="modal-title">{_t("add", "department")}</h2>
      {parentId ? (
        <>
          <Form.Item name="id" hidden initialValue={0}>
            <Input hidden />
          </Form.Item>
          <Form.Item
            name="title"
            label={_t("department")}
            rules={[{ required: true, whitespace: true }]}
            initialValue={initialValues}
          >
            <Input placeholder={_t("name")} />
          </Form.Item>
          <Form.Item
            name="invoiceEmail"
            label={_t("email")}
            rules={[{ whitespace: true }]}
            initialValue={initialValues}
            hidden={true}
          >
            <Input placeholder={_t("email")} />
          </Form.Item>
        </>
      ) : (
        <Result status="error" title={_t("choose", "dealer")} />
      )}
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            disabled={!parentId}
            loading={loading}
          >
            {_t("add")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(ModalDepartments);
