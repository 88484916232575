import http from "./httpService";
import { IdType, } from "../types/appTypes";
import { getApiQuery } from "./urlQueryService";
import getApiEndpoint from "./apiEndpoint";
import {
  VehicleEquipmentCopyType,
  VehicleEquipmentUpsertType,
  VehicleFuelUpsertType,
  VehicleMakeUpsertType,
  VehicleModelUpsertType,
  VehicleTypeUpsertType,
  VehicleVariantUpsertType
} from "../types/vehicleCatalogTypes";

const apiEndpoint = `${getApiEndpoint()}/system`;

const apiEndpointTypes = `${apiEndpoint}/vehicleTypes`;
const apiEndpointModels = `${apiEndpoint}/vehicleModels`;
const apiEndpointMakes = `${apiEndpoint}/vehicleMakes`;
const apiEndpointFuels = `${apiEndpoint}/vehicleFuels`;
const apiEndpointEquipment = `${apiEndpoint}/vehicleEquipment`;
const apiEndpointVariants = `${apiEndpoint}/vehicleVariants`;

export function vehicleTypeUrl(id: IdType) {
  return `${apiEndpointTypes}/${id}`;
}

export function vehicleTypesUrl(query?: string) {
  return `${apiEndpointTypes}?${getApiQuery(query)}`;
}

export function deleteVehicleType(id: IdType) {
  return http.delete(vehicleTypeUrl(id));
}

export function saveVehicleType(data: VehicleTypeUpsertType) {
  return http.post(apiEndpointTypes, data);
}

export function updateVehicleType(id: IdType, data: VehicleTypeUpsertType) {
  return http.put(vehicleTypeUrl(id), data);
}

export function vehicleFuelUrl(id: IdType) {
  return `${apiEndpointFuels}/${id}`;
}

export function vehicleFuelsUrl(query?: string) {
  return `${apiEndpointFuels}?${getApiQuery(query)}`;
}

export function deleteVehicleFuel(id: IdType) {
  return http.delete(vehicleFuelUrl(id));
}

export function saveVehicleFuel(data: VehicleFuelUpsertType) {
  return http.post(apiEndpointFuels, data);
}

export function updateVehicleFuel(id: IdType, data: VehicleFuelUpsertType) {
  return http.put(vehicleFuelUrl(id), data);
}

export function vehicleMakeUrl(id: IdType) {
  return `${apiEndpointMakes}/${id}`;
}

export function vehicleMakesUrl(query?: string) {
  return `${apiEndpointMakes}?${getApiQuery(query)}`;
}

export function deleteVehicleMake(id: IdType) {
  return http.delete(vehicleMakeUrl(id));
}

export function saveVehicleMake(data: VehicleMakeUpsertType) {
  return http.post(apiEndpointMakes, data);
}

export function updateVehicleMake(id: IdType, data: VehicleMakeUpsertType) {
  return http.put(vehicleMakeUrl(id), data);
}

export function vehicleModelUrl(id: IdType) {
  return `${apiEndpointModels}/${id}`;
}

export function vehicleModelsUrl(query?: string) {
  return `${apiEndpointModels}?${getApiQuery(query)}`;
}

export function deleteVehicleModel(id: IdType) {
  return http.delete(vehicleModelUrl(id));
}

export function saveVehicleModel(data: VehicleModelUpsertType) {
  return http.post(apiEndpointModels, data);
}

export function updateVehicleModel(id: IdType, data: VehicleModelUpsertType) {
  return http.put(vehicleModelUrl(id), data);
}

export function vehicleEquipmentUrl(id: string) {
  return `${apiEndpointEquipment}/${id}`;
}

export function vehicleEquipmentsUrl(
  query?: string,
  vehicleTypeId?: IdType,
  vehicleFuelId?: IdType,
  vehicleMakeId?: IdType,
  vehicleModelId?: IdType,
  year?: number,
  onlyPriced?: boolean
) {
  const url = new URL(`${apiEndpointEquipment}?${getApiQuery(query)}`)

  url.searchParams.set('filter[vehicleFuelId]', vehicleFuelId?.toString() || '');
  url.searchParams.set('filter[vehicleTypeId]', vehicleTypeId?.toString() || '');
  url.searchParams.set('filter[vehicleMakeId]', vehicleMakeId?.toString() || '');
  url.searchParams.set('filter[vehicleModelId]', vehicleModelId?.toString() || '');
  url.searchParams.set('filter[year]', year?.toString() || '');
  onlyPriced && url.searchParams.set('filter[onlyPriced]', '1');

  return url.href;
}

export function deleteVehicleEquipment(id: string) {
  return http.delete(vehicleEquipmentUrl(id));
}

export function saveVehicleEquipment(data: VehicleEquipmentUpsertType) {
  return http.post(apiEndpointEquipment, data);
}

export function updateVehicleEquipment(id: string, data: VehicleEquipmentUpsertType) {
  return http.put(vehicleEquipmentUrl(id), data);
}

export function copyVehicleEquipments(data: VehicleEquipmentCopyType) {
  return http.post(`${apiEndpointEquipment}/copyToNewYear`, data);
}

export function vehicleVariantUrl(id: IdType) {
  return `${apiEndpointVariants}/${id}`;
}

export function vehicleVariantsUrl(
  query?: string,
  vehicleTypeId?: IdType,
  vehicleFuelId?: IdType,
  vehicleMakeId?: IdType,
  vehicleModelId?: IdType,
  year?: number,
  onlyPriced?: boolean
) {
  const url = new URL(`${apiEndpointVariants}?${getApiQuery(query)}`)

  url.searchParams.set('filter[vehicleFuelId]', vehicleFuelId?.toString() || '');
  url.searchParams.set('filter[vehicleTypeId]', vehicleTypeId?.toString() || '');
  url.searchParams.set('filter[vehicleMakeId]', vehicleMakeId?.toString() || '');
  url.searchParams.set('filter[vehicleModelId]', vehicleModelId?.toString() || '');
  url.searchParams.set('filter[year]', year?.toString() || '');
  onlyPriced && url.searchParams.set('filter[onlyPriced]', '1');

  return url.href;
}

export function deleteVehicleVariant(id: IdType) {
  return http.delete(vehicleVariantUrl(id));
}

export function saveVehicleVariant(data: VehicleVariantUpsertType) {
  return http.post(apiEndpointVariants, data);
}

export function updateVehicleVariant(id: IdType, data: VehicleVariantUpsertType) {
  return http.put(vehicleVariantUrl(id), data);
}
