import { useCallback, useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { latestVersion } from "../screens/forms/tax/calc/taxCalcService";
import { initTaxCase, TaxCase } from "../types/taxTypes";
import { convertToUploadFileList, isApiFiles } from "../utilities/convertTypes";
import UserStore from "./userStore";

interface TaxCaseStoreProps {
  isAdmin: boolean;
  taxCase: TaxCase;
  setTaxCase: (newCase: TaxCase) => void;

  canSendInvoice: boolean;
  setCanSendInvoice: React.Dispatch<React.SetStateAction<boolean>>;
}

const useTaxCaseStore = (initialState?: TaxCase): TaxCaseStoreProps => {
  const [taxCase, setTaxCase] = useState<TaxCase>(initTaxCase);
  const [canSendInvoice, setCanSendInvoice] = useState<boolean>(true);

  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission("tax.isAdmin");

  const handleSetTaxCase = useCallback(
    (newCase: TaxCase) => {
      const files = newCase.files && isApiFiles(newCase.files) ? convertToUploadFileList(newCase.files) : newCase.files;
      const legalFiles =
        newCase.legalFiles && isApiFiles(newCase.legalFiles)
          ? convertToUploadFileList(newCase.legalFiles)
          : newCase.legalFiles;

      setTaxCase({ ...newCase, files, legalFiles });
    },
    [setTaxCase]
  );

  useEffect(() => {
    if (initialState) {
      if (!initialState.version) {
        initialState.version = latestVersion;
      }
      handleSetTaxCase(initialState);
      setCanSendInvoice(!!initialState.internalCalculation);
    }
  }, [initialState, handleSetTaxCase, setCanSendInvoice]);

  return {
    isAdmin,
    taxCase,
    setTaxCase: handleSetTaxCase,
    canSendInvoice,
    setCanSendInvoice,
  };
};

const TaxCaseStore = createContainer(useTaxCaseStore);

export default TaxCaseStore;
