import _t from "../lang/translate";
import { GetStatusesType, IdType, ProductType, StatusesType } from "../types/appTypes";
import { BonusLogType } from "../types/bonusTypes";
import { ClipLogType } from "../types/clipTypes";
import { DealerLevelType, DealerSettingsType, DealerType } from "../types/dealerTypes";
import { ConfigFieldsType } from "../types/systemTypes";
import getApiEndpoint from "./apiEndpoint";
import { geStatus } from "./enumService";
import http from "./httpService";
import { getApiQuery } from "./urlQueryService";

const apiEndpoint = `${getApiEndpoint()}/dealer`;

export function dealerUrl(id: number | string) {
  return `${apiEndpoint}/${id}`;
}

export function dealerAdminUrl(id: number | string) {
  return `${dealerUrl(id)}/administration`;
}

export function dealerClipsUrl(id: string | number) {
  return `${apiEndpoint}/${id}/clips`;
}

export function dealerBonusUrl(id: string | number) {
  return `${apiEndpoint}/${id}/accountingItems`;
}

export function clipUsageUrl(id: number) {
  return `${apiEndpoint}/${id}/clips`;
}

export function bonusUsageUrl(id: number, query?: string) {
  return `${apiEndpoint}/${id}/accountingItems?${getApiQuery(query)}`;
}

export function sendClips(id: IdType, data: ClipLogType) {
  return http.post<ClipLogType>(dealerClipsUrl(id), data);
}

export function downloadClipUsage(id: IdType) {
  return http.get(`${dealerUrl(id)}/clipHistory`);
}

export function updateBonus(id: IdType, data: BonusLogType) {
  return http.post<{ data: BonusLogType }>(dealerBonusUrl(id), data);
}

export function dealersUrl(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function saveDealer(data: DealerType) {
  if (data.id !== 0) return http.put(dealerUrl(data.id), data);

  return http.post(apiEndpoint, data);
}

export function updateDealerSettings(id: IdType, data: DealerSettingsType) {
  return http.put(`${dealerUrl(id)}/updateSettings`, data);
}

export function departmentUrl(dealerId: IdType) {
  return `${dealerUrl(dealerId)}/department`;
}

export function billingDestinationUrl(dealerId: IdType) {
  return `${dealerUrl(dealerId)}/billingDestination`;
}

export function deleteDepartment(dealerId: IdType, depId: number) {
  return http.delete(`${departmentUrl(dealerId)}/${depId}`);
}

export function deleteBillingDestination(dealerId: IdType, bdId: number) {
  return http.delete(`${billingDestinationUrl(dealerId)}/${bdId}`);
}

export function saveDealerDepartment<T>(id: IdType, data: T) {
  return http.post<T>(`${dealerUrl(id)}/addDepartmentQuick`, data);
}

export function saveBillingDestination<T>(id: IdType, data: T) {
  return http.post<T>(`${dealerUrl(id)}/addBillingDestinationQuick`, data);
}

export function valueConfigUrl(id: IdType, context: ProductType) {
  return `${dealerUrl(id)}/valueConfig/${context}`;
}

export function updateValueConfig(id: IdType, data: { [key: string]: ConfigFieldsType }) {
  return http.put(`${dealerUrl(id)}/updateValueConfig`, data);
}

export function updateDealerLevel(id: IdType, data: { [key: string]: DealerLevelType }) {
  return http.put<{ [key: string]: DealerLevelType }>(`${dealerUrl(id)}/updateProductLevel`, data);
}

export function getDealer(id: IdType) {
  return http.get(dealerUrl(id));
}

export function deleteDealer(id: IdType) {
  return http.delete(dealerUrl(id));
}

export function statisticsUrl(id: IdType) {
  return `${dealerUrl(id)}/overviewStatistics`;
}

export function dealerStatisticsUrl(id: IdType, query?: string) {
  return `${dealerUrl(id)}/statistics?${query ? query : ""}`;
}

export function caseStatisticsUrl(id: IdType, query?: string) {
  return `${dealerUrl(id)}/caseStatistics?${query ? query : ""}`;
}

// Filter statuses on table page:

export const getDealerStatuses: GetStatusesType = () => {
  let results: StatusesType = {
    all: { id: -1, title: _t("all") },
  };
  const active = geStatus(8);
  if (active) results = { ...results, active };
  const inactive = geStatus(9);
  if (inactive) results = { ...results, inactive };

  return results;
};
