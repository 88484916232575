import { FC, useEffect } from "react";
import CopyableContent from "../../../../components/copyableContent";
import format from "../../../../utilities/formatNumbers";
import { isNum } from "../../../../utilities/typeGuard";
import { getRegulatedTradePrice } from "../calc/tradePriceSubCalculations";

interface TradePriceTotalFieldProps {
  onChange?: (value: number | null) => void;
  value?: number | null;
  otherRegulationAmount?: number | null;
  optimizedRegulationAmount?: number | null;
  tradePrice?: number | null;
  estimatedTradePrice?: number | null;
  useEstimatedTradePrice?: boolean;
}

const TradePriceTotalField: FC<TradePriceTotalFieldProps> = ({
  otherRegulationAmount,
  optimizedRegulationAmount,
  estimatedTradePrice,
  useEstimatedTradePrice,
  tradePrice,
  value,
  onChange,
}) => {
  useEffect(() => {
    onChange && onChange(
      getRegulatedTradePrice(
        otherRegulationAmount,
        optimizedRegulationAmount,
        tradePrice,
        estimatedTradePrice,
        useEstimatedTradePrice
      )
    );
  }, [
    otherRegulationAmount,
    optimizedRegulationAmount,
    tradePrice,
    useEstimatedTradePrice,
    estimatedTradePrice,
  ]);

  return (
    <CopyableContent contentToCopy={`${value || 0}`}>
      <h4 className="mb-0">{format.price(isNum(value) ? value : 0)}</h4>
    </CopyableContent>
  );
};

export default TradePriceTotalField;
