import { FC } from "react";
import { Form } from "antd";
import _t from "../../../../lang/translate";
import { TaxDocCalculationsType } from "../../../../types/taxDocTypes";
import { TradePriceCalcOutputType } from "../calc/tradePriceCalculatorIndex";
import TradePriceAdvancedCalculator from "./advancedCalculator/tradePriceAdvancedCalculator";

const TradePriceCalculator: FC = () => {
  return (
    <Form.Item noStyle shouldUpdate={true}>
      {({ setFieldsValue, getFieldsValue }) => {
        const handleOutput = (output: TradePriceCalcOutputType) => {
          const caseCalcValues = getFieldsValue(true) as TaxDocCalculationsType;
          const tradePriceValues = caseCalcValues?.tradePrice;
          setFieldsValue({
            ...caseCalcValues,
            tradePrice: {
              ...tradePriceValues,
              tradePriceOutput: output,
              averagePrice: output.averagePrice,
              deliveryFee: output.consts.deliveryFee,
              deductionAverageDiscount: output.averageDiscountAmount,
              priceLevel: output.tradingPriceTotal,
              qualityAmount: output.qualityAmount,
              specialUseAmount: output.specialUseAmount,
              mileageRegulation: output.kmDeductionTotal,
              equipmentRegulation: output.equipmentRegulationAmount,
              otherRegulation: output.otherRegulationAmount,
              otherRegulationAmount: null,
              tradePrice: output.finalTradePriceTotal,
            },
          });
        };

        return (
          <Form.Item name={["tradePrice", "adVehicles"]} label={_t("ads")}>
            <TradePriceAdvancedCalculator
              onCalculated={handleOutput}
              calculationFormValues={getFieldsValue(true) as TaxDocCalculationsType}
            />
          </Form.Item>
        );
      }}
    </Form.Item>
  );
};

export default TradePriceCalculator;
