import React, { FC, useState } from "react";
import { Button, Col, Row } from "antd";
import CardBox from "../../../../../components/cardBox";
import LogText from "../../../../../components/logText";
import TaxCaseStore from "../../../../../contexts/taxCaseStore";
import _t from "../../../../../lang/translate";
import CollapseCard from "../../../../../components/collapseCard";
import TaxEquipmentReport from "../taxEquipmentReport";
import MileageDocumentation from "../mileageDocumentation";
import VehicleInfo from "../../../../../components/vehicleInfoViews/vehicleInfo";
import TaxVehicleEditModal from "./taxVehicleEditModal";
import { taxFetchPriceDocumentation } from "../../../../../services/taxService";
import { renderErrorMessage } from "../../../../../components/messages/errorMessage";
import Icon from "../../../../../assets/icons/icon";

const TaxVehicleEdit: FC = () => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoadingPriceData, setIsLoadingPriceData] = useState<boolean>(false);
  const [localDATVehicleData, setLocalDATVehicleData] = useState(taxCase.vehicle?.remoteData?.dat?.mapped);
  const [localDMRVehicleData, setLocalDMRVehicleData] = useState(taxCase.vehicle?.remoteData?.dmr?.mapped);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  const fetchPriceData = async () => {
    try {
      setIsLoadingPriceData(true)
      const {data} = await taxFetchPriceDocumentation(taxCase.id)
      setTaxCase(data)
    } catch (err) {
      renderErrorMessage(err)
    } finally {
      setIsLoadingPriceData(false)
    }
  }

  return (
    <>
      <CollapseCard title={_t("vehicle")}>
        <VehicleInfo vehicle={taxCase.vehicle} showAdditionalData showDMRAlerts />
        <CardBox className="mb-1" style={{whiteSpace: "pre-wrap"}} title={_t("note")}>
          {taxCase.note || "-"}
        </CardBox>
        <MileageDocumentation />
        <Row className="mt-1" gutter={[8, 16]} justify="space-between" align="bottom">
          <Col className="d-flex gap-05 flex-wrap">
            <TaxEquipmentReport />
            <Button loading={isLoadingPriceData} icon={<Icon size="large" name="download-outline"/>} onClick={fetchPriceData}>{_t("fetch", "prices")}</Button>
          </Col>
          <Col flex="1" className="text-right">
            <Button
              type="link"
              className="pl-0 pr-0"
              onClick={startEditing}
              loading={isSaving}
            >
              {_t("edit")}
            </Button>
          </Col>
        </Row>
      </CollapseCard>
      {taxCase.log?.creation && (
        <LogText
          className="p-0 text-right mt--1  mb-1"
          small
          logData={taxCase.log.creation}
        />
      )}
      <TaxVehicleEditModal
        isEditing={isEditing}
        stopEditing={stopEditing}
        startEditing={startEditing}
        setTaxCase={setTaxCase}
        taxCase={taxCase}
        DATVehicleData={localDATVehicleData}
        setDATVehicleData={setLocalDATVehicleData}
        DMRVehicleData={localDMRVehicleData}
        setDMRVehicleData={setLocalDMRVehicleData}
        setIsSaving={setIsSaving}
      />

    </>
  );
};

export default TaxVehicleEdit;
