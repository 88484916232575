import { Button, Form, Space } from "antd";
import _t from "../../lang/translate";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { rejectTaxDocCase } from "../../services/taxDocService";
import { MakePurchaseType } from "../../types/taxDocTypes";
import TextArea from "antd/lib/input/TextArea";
import { rejectDocumentationCase } from "../../services/taxService";
import { renderErrorMessage } from "../messages/errorMessage";

export default function RejectTaxDoc<T extends object>({
  onSuccess,
  onCancel,
  payload,
}: OpenModalProps<T>) {
  const [tmpForm] = Form.useForm<MakePurchaseType>();
  const { id, context } = payload;

  const handleConfirm = async (values: MakePurchaseType) => {
    try {
      const { data } =
        context === "tax"
          ? await rejectDocumentationCase(id, values)
          : await rejectTaxDocCase(id, values);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
    }
  };

  return (
    <Form
      form={tmpForm}
      requiredMark={false}
      layout="vertical"
      onFinish={handleConfirm}
    >
      <h2 className="modal-title">{_t("reject", "case")}</h2>
      <Form.Item
        name="reason"
        label={_t("reason")}
        rules={[{ required: true, message: _t("msg.field_required") }]}
      >
        <TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
      </Form.Item>
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>
          <Button htmlType="submit" type="primary" danger>
            {_t("reject")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(RejectTaxDoc);
