import { Tooltip } from "antd";
import { FC, useState } from "react";
import _t from "../lang/translate";

interface CopyableContentProps extends React.HTMLAttributes<HTMLDivElement> {
  contentToCopy: string;
}

const CopyableContent: FC<CopyableContentProps> = ({ contentToCopy, children }) => {
  const [tooltipText, setTooltipText] = useState<string>(_t("click_to_copy"));

  const handleCopy = () => {
    navigator.clipboard.writeText(`${contentToCopy || 0}`);
    setTooltipText(_t("copied"));
  };

  const handleVisibleChange = (isVisible: boolean) => !isVisible && setTooltipText(_t("click_to_copy"));

  return (
    <Tooltip title={tooltipText} afterVisibleChange={handleVisibleChange}>
      <div onClick={handleCopy}>{children}</div>
    </Tooltip>
  );
};

export default CopyableContent;
