import { FC } from "react";
import { Button, Col, Form, Row, Space } from "antd";
import Icon from "../../../../assets/icons/icon";
import InputPrice from "../../../../components/form/inputPrice";
import _t from "../../../../lang/translate";
import { Equipment } from "../../../../types/appTypes";
import format from "../../../../utilities/formatNumbers";
import { calcEquipmentsTotal } from "../calc/equipmentsCalculator";

interface EquipmentCalculatorFooterProps {
  onAdd: () => void;
  onCopyToEstimatic: () => void;
  onSubmit: () => void;
  onNewEuroFactor: () => void;
}

const EquipmentCalculatorFooter: FC<EquipmentCalculatorFooterProps> = ({ onSubmit, onAdd, onNewEuroFactor, onCopyToEstimatic }) => {
  return (
    <div className="ant-modal-footer">
      <Row justify="space-between">
        <Col>
          <Space size="large">
            <Button type="dashed" onClick={onAdd}>
              + {_t("add", "equipments")}
            </Button>
            <Button type="dashed" onClick={onCopyToEstimatic} icon={<Icon name="copy-outline"/>}>
              {_t("estimatic")}
            </Button>
            <Row gutter={4}>
              <Col flex="150px">
                <Form.Item className="mb-0 hide-error" name="euroFactor">
                  <InputPrice placeholder={_t("euro", "factor", "-")} />
                </Form.Item>
              </Col>
              <Col>
                <Button onClick={onNewEuroFactor} type="ghost" className="pl-05 pr-05">
                  <Icon name="refresh-outline" size="large" />
                </Button>
              </Col>
            </Row>

          </Space>
        </Col>
        <Col>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const equipments: Equipment[] | null = getFieldValue("equipments");
              return (
                <Space size="large">
                  <h4 className="mb-0">{format.price(calcEquipmentsTotal(equipments))}</h4>
                  <Button type="primary" onClick={onSubmit}>
                    {_t("apply")}
                  </Button>
                </Space>
              );
            }}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default EquipmentCalculatorFooter;
