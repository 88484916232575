import { Button, Col, Menu, message, Row, Space, Typography } from "antd";
import DropdownButton from "antd/lib/dropdown/dropdown-button";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import React, { FC } from "react";
import SwitchInput from "../../../../components/form/switchInput";
import openModal from "../../../../components/modal/openModal";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { previewDocumentation, publishDocumentation, sendDocumentation, } from "../../../../services/taxService";
import { TaxCase } from "../../../../types/taxTypes";
import { isAxiosError } from "../../../../utilities/typeGuard";

interface TaxDocPrerequisitesProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const TaxDocPrerequisites: FC<TaxDocPrerequisitesProps> = ({ setLoading }) => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const { documentationSettings: settings, flags } = taxCase;

  const handlePublish: SwitchChangeEventHandler = async (checked) => {
    try {
      setLoading(true);
      const { data } = await publishDocumentation(taxCase.id, {
        status: checked,
      });
      message.success(_t("saved"));
      setTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = async () => {
    try {
      setLoading(true);
      const { data } = await previewDocumentation(taxCase.id);
      if (data.url) window.open(data.url, '_blank');
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleSend = async () => {
    try {
      setLoading(true);
      const { data } = await sendDocumentation(taxCase.id);
      setTaxCase(data);
      message.success(_t("sent"));
    } catch (error) {
      const errorMessage = isAxiosError(error)
        ? error.response?.data?.message
        : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleBuy = async () => {
    try {
      const data = await openModal<TaxCase>("buyTaxDoc", {
        id: taxCase.id,
        context: "tax",
      });
      setTaxCase(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = async () => {
    try {
      const data = await openModal<TaxCase>("rejectTaxDoc", {
        id: taxCase.id,
        context: "tax",
      });
      setTaxCase(data);
    } catch (error) {
      console.log(error);
    }
  };

  const sendMenu = (
    <Menu className="min-width-125">
      <Menu.Item key="1" onClick={handleBuy}>
        {_t("buy")}
      </Menu.Item>
      <Menu.Item key="2" danger onClick={handleReject}>
        {_t("reject")}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Typography.Title level={4} className="mb-1">
        {_t("publishing")}
      </Typography.Title>
      <Row justify="space-between" align="middle">
        <Col>
          <SwitchInput
            disabled={flags.generatingDocumentation}
            size="small"
            label={_t("publish")}
            id="publish-doc-switch"
            checked={flags.documentationPublished}
            onChange={handlePublish}
          />
        </Col>
        <Col>
          <Space>
            <Button ghost type="primary" onClick={handlePreview}>
              {_t("preview")}
            </Button>
            <DropdownButton
              disabled={flags.generatingDocumentation}
              type="primary"
              onClick={handleSend}
              overlay={sendMenu}
            >
              {_t("send")}
            </DropdownButton>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default TaxDocPrerequisites;
