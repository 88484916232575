import React, { FC, ReactNode } from "react";

interface VerticalLabelValueProps extends React.HTMLAttributes<HTMLDivElement> {
  label: ReactNode;
  value: ReactNode;
  labelOnTop?: boolean;
  labelClassName?: string;
  valueClassName?: string;
}

const VerticalLabelValue: FC<VerticalLabelValueProps> = ({
  label,
  value,
  labelOnTop,
  labelClassName,
  valueClassName,
  className,
}) => {
  const labelElement = <label className={labelClassName}>{label}</label>;
  return (
    <div className={className}>
      {labelOnTop && labelElement}
      <span className={`text-24 d-block text-dark ${valueClassName}`}>{value}</span>
      {!labelOnTop && labelElement}
    </div>
  );
};

export default VerticalLabelValue;
