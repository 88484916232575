import moment from "moment";
import { roundNum } from "../../../../utilities/number";

import { getTradePriceInitCalcOutput } from './tradePriceCalculatorIndex';

import {
  KmDeductionValue10YearCalcType,
  PriceLevel10CalcType,
  PriceLevelValidation10CalcType,
  TradePriceCalcConstsType,
  TradePriceCalcFuncType,
  TradePriceCalcOutputType,
  TradePriceCalcSubFuncType,
  TradePriceYearFactorType,
} from "./tradePriceCalculatorTypes";

const calcTradePrice: TradePriceCalcFuncType = (input) => {
  /////// Injecting consts in input object:
  const averageDiscountSurcharge = -0.05;
  const yearFactors = [0.31, 0.22, 0.2, 0.17];
  const specialUseDefaultSurcharge = -0.05;
  const specialUseQualityMaxPrice = -20000;
  const deliveryFee = input.optimization ? -1180 : 0;
  const carQualities = [
    { value: 1, surcharge: 0 }, // Ikke vurderet endnu
    { value: 2, surcharge: 0.02 }, // Over Middel
    { value: 3, surcharge: 0 }, // Middel
    { value: 4, surcharge: -0.02 }, // Under middel
  ];

  const consts: TradePriceCalcConstsType = {
    averageDiscountSurcharge,
    deliveryFee,
    yearFactors,
    specialUseQualityMaxPrice,
    specialUseDefaultSurcharge,
    carQualities,
  };

  let output: TradePriceCalcOutputType = getTradePriceInitCalcOutput(consts);
  output.consts = consts;
  output.optimization = !!input.optimization;
  output.otherRegulation = input.otherRegulation || 0;
  output.equipmentRegulation = input.equipmentRegulation || 0;
  output.averagePrice = input.averagePrice || 0;
  output.optimizedRegulation = input.optimizedRegulation || 0;
  output.carAgeInYears = vehicleAgeCalc(input, output);
  output.yearFactor = calcYearFactor(input, output);
  output.averageDiscountAmount = averageDiscountAmountCalc(input, output);
  output.tradingPriceTotal = tradingPriceTotalCalc(input, output);
  output.qualitySurcharge = qualityIdSurchangeCalc(input, output);
  output.qualityAmount = qualityIdCalc(input, output);
  output.specialUseSurcharge = calcspecialUseSurcharge(input, output);
  output.specialUseValue = calcspecialUseValue(input, output);
  output.specialUseAmount = calcspecialUseAmount(input, output);
  output.kmDifference = kmDifferenceCalc(input, output);
  output.kmDeductionTotal = kmDeductionCalc(input, output);
  output.equipmentRegulationAmount = equipmentRegulationAmountCalc(input, output);
  output.optimizedRegulationAmount = optimizedRegulationAmountCalc(input, output);
  output.otherRegulationAmount = otherRegulationAmountCalc(input, output);
  output.finalTradePriceTotal = finalTradePriceTotalCalc(input, output);

  return output;
};

///////// FUNCTIONS  ///////// FUNCTIONS  ///////// FUNCTIONS  ///////// FUNCTIONS

export const vehicleAgeCalc: TradePriceCalcSubFuncType = ({ firstRegDate, calculationDate }) => {
  const startDate = moment(calculationDate || undefined).endOf("day");
  const frd = moment(firstRegDate).endOf("day");
  if (!startDate.isValid() || !frd.isValid()) return 0;
  const result = Math.abs(frd.diff(startDate, "years", true));
  return roundNum(result, 2);
};

const averageDiscountAmountCalc: TradePriceCalcSubFuncType = ({ averagePrice }, { consts }) => {
  return roundNum((averagePrice || 0) * consts?.averageDiscountSurcharge, 2);
};

const tradingPriceTotalCalc: TradePriceCalcSubFuncType = ({ averagePrice }, { averageDiscountAmount, consts }) => {
  const result = (averagePrice || 0) + averageDiscountAmount;
  return roundNum(result + consts.deliveryFee, 2);
};

const qualityIdSurchangeCalc: TradePriceCalcSubFuncType = ({ qualityId }, { consts }) => {
  return consts?.carQualities.find((q) => q.value == qualityId)?.surcharge || 0;
};

const calcspecialUseSurcharge: TradePriceCalcSubFuncType = ({ specialUseId }, { consts }) => {
  return specialUseId === 1 ? 0 : consts.specialUseDefaultSurcharge;
};

const qualityIdCalc: TradePriceCalcSubFuncType = (
  { specialUseId, qualityId },
  { carAgeInYears, qualitySurcharge, tradingPriceTotal, consts }
) => {
  // Max -8000 in case:
  // Vehicle quality is "Middel" | "Ikke vurderet"
  // Max -20000 in case:
  // Vehicle quality is "Under middel" OR Vehicle quality is "Middel" | "Ikke vurderet" and special use is not "ingen";
  // Max 0 in case:
  // Vehicle age is less than half a year.
  // Max +20000 in case:
  // Vehicle quality is "Over middel"

  if (carAgeInYears < 0.5) return 0;
  const qualityAmount = (tradingPriceTotal || 0) * qualitySurcharge;

  if (qualityId && qualityId === 2) {
    return roundNum(qualityAmount > 20000 ? 20000 : qualityAmount, 2);
  }

  if (specialUseId === 1 && (!qualityId || qualityId < 4)) {
    return roundNum(qualityAmount < -8000 ? -8000 : qualityAmount, 2);
  }

  return roundNum(
    qualityAmount < consts.specialUseQualityMaxPrice ? consts.specialUseQualityMaxPrice : qualityAmount,
    2
  );
};

const calcspecialUseValue: TradePriceCalcSubFuncType = (_, { tradingPriceTotal, specialUseSurcharge }) => {
  if (specialUseSurcharge === 0) return 0;
  return tradingPriceTotal * specialUseSurcharge;
};

const calcspecialUseAmount: TradePriceCalcSubFuncType = (_, output) => {
  const { qualityAmount, specialUseSurcharge, specialUseValue, consts } = output;
  if (specialUseSurcharge === 0) return 0;

  if (specialUseValue + qualityAmount < consts.specialUseQualityMaxPrice) {
    return roundNum(consts.specialUseQualityMaxPrice - qualityAmount, 2);
  } else {
    return roundNum(specialUseValue, 2);
  }
};

const kmDifferenceCalc: TradePriceCalcSubFuncType = ({ averageMileage, mileage }) => {
  return roundNum((averageMileage || 0) - (mileage || 0), 2);
};

const calcYearFactor: TradePriceCalcSubFuncType = (_, { carAgeInYears, consts }) => {
  const { yearFactors } = consts;
  return yearFactorCalc({ yearFactors, carAgeInYears });
};

const kmDeductionCalc: TradePriceCalcSubFuncType = (
  { averageMileage, mileage },
  { kmDifference, carAgeInYears, tradingPriceTotal, yearFactor }
) => {
  const kmDifferenceSurcharge = kmDifference / (averageMileage || 0);
  if (Math.abs(kmDifferenceSurcharge) < 0.1) {
    return 0; // if the difference is less than 10%, dont regulate.
  }

  const priceLevel = (tradingPriceTotal / 100000) * kmDifference * yearFactor;
  const priceLevel10 = priceLevel10Calc({
    tradingPriceTotal,
    kmDifferenceSurcharge,
  });
  const priceLevel50 = Math.floor(priceLevel - priceLevel10) * 0.5;
  const kmDeductionValue = priceLevel10 + priceLevel50;
  const priceLevelValidation10Input = {
    averageMileage: averageMileage || 0,
    mileage: mileage || 0,
    priceLevel10,
    priceLevel,
    kmDeductionValue,
  };

  const priceLevelValidation10 = priceLevelValidation10Calc(priceLevelValidation10Input);

  const kmDeductionValue10YearInput = {
    carAgeInYears,
    kmDifferenceSurcharge,
    priceLevelValidation10,
  };

  const kmDeductionValue10Year = kmDeductionValue10YearCalc(kmDeductionValue10YearInput);

  return roundNum(carAgeInYears >= 10 ? kmDeductionValue10Year : priceLevelValidation10, 2);
};

const equipmentRegulationAmountCalc: TradePriceCalcSubFuncType = (_, { equipmentRegulation }) => {
  return equipmentRegulation;
};

const otherRegulationAmountCalc: TradePriceCalcSubFuncType = ({ otherRegulation }, { tradingPriceTotal }) => {
  if (!otherRegulation) return 0;
  return Math.round(tradingPriceTotal * otherRegulation);
};

const optimizedRegulationAmountCalc: TradePriceCalcSubFuncType = (
  { optimizedRegulation: optimizedRegulation },
  { tradingPriceTotal }
) => {
  if (!optimizedRegulation) return 0;
  return Math.round(tradingPriceTotal * optimizedRegulation);
};

const finalTradePriceTotalCalc: TradePriceCalcSubFuncType = (
  { optimization, equipmentRegulation = 0 },
  {
    tradingPriceTotal = 0,
    qualityAmount = 0,
    specialUseAmount = 0,
    optimizedRegulationAmount = 0,
    otherRegulationAmount = 0,
    kmDeductionTotal = 0,
  }
) => {
  const result =
    tradingPriceTotal +
    qualityAmount +
    specialUseAmount +
    otherRegulationAmount +
    kmDeductionTotal +
    (equipmentRegulation || 0);
  if (optimization) return Math.round(result);
  return Math.round(result + optimizedRegulationAmount);
};

const kmDeductionValue10YearCalc: KmDeductionValue10YearCalcType = ({
  carAgeInYears,
  kmDifferenceSurcharge,
  priceLevelValidation10,
}) => {
  let kmDeductionValue10Year = 0;

  //Kilometer Deduction Value (Above 10 years)
  if (carAgeInYears > 10) {
    if (kmDifferenceSurcharge > 0.33 || kmDifferenceSurcharge < -0.33) {
      kmDeductionValue10Year = priceLevelValidation10;
    } else {
      kmDeductionValue10Year = 0;
    }
  }
  return kmDeductionValue10Year;
};

const yearFactorCalc: TradePriceYearFactorType = ({ yearFactors, carAgeInYears }) => {
  let yearFactor;
  if (carAgeInYears >= 3) {
    yearFactor = yearFactors[3];
  } else if (carAgeInYears >= 2) {
    yearFactor = yearFactors[2];
  } else if (carAgeInYears >= 1) {
    yearFactor = yearFactors[1];
  } else {
    yearFactor = yearFactors[0];
  }

  return yearFactor;
};

//Pricelevel 10% (10% of tradingprice)
const priceLevel10Calc: PriceLevel10CalcType = ({ tradingPriceTotal, kmDifferenceSurcharge }) => {
  let priceLevel10;
  if (kmDifferenceSurcharge > 0) {
    priceLevel10 = tradingPriceTotal * 0.1;
  } else if (kmDifferenceSurcharge < 0) {
    priceLevel10 = tradingPriceTotal * -0.1;
  } else {
    priceLevel10 = 0;
  }
  return priceLevel10;
};

const priceLevelValidation10Calc: PriceLevelValidation10CalcType = ({
  averageMileage,
  mileage,
  priceLevel10,
  priceLevel,
  kmDeductionValue,
}) => {
  //Price level validation 10 (If deduction is more than 10% of the trading price, you need to engage pricelevel50 and pricelevel10)
  let priceLevelValidation10;

  if (averageMileage > mileage) {
    if (priceLevel10 < priceLevel) {
      priceLevelValidation10 = kmDeductionValue;
    } else {
      priceLevelValidation10 = priceLevel;
    }
  } else {
    if (priceLevel10 < priceLevel) {
      priceLevelValidation10 = priceLevel;
    } else {
      priceLevelValidation10 = kmDeductionValue;
    }
  }
  return priceLevelValidation10;
};

export default calcTradePrice;
