import React, { FC, useState } from "react";
import { Button, Modal, Spin } from "antd";
import useHash from "../../../../hooks/useHash";
import _t from "../../../../lang/translate";
import TaxDocPurchaseForm from "./taxDocPurchaseForm";

const TaxDocPurchaseButton: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hash, setHash] = useHash();
  const hashKey = _t("buy", "documentation").replaceAll(" ", "_").toLowerCase();
  const isVisible = hash === hashKey;
  const openModal = () => setHash(hashKey);
  const closeModal = () => setHash();

  return (
    <>
      <Button onClick={openModal} type="primary" className="pl-4 pr-4">
        {_t("request", "documentation")}
      </Button>
      <Modal
        visible={isVisible}
        onCancel={closeModal}
        title={_t("request", "documentation")}
        footer={null}
      >
        <Spin spinning={loading} size="large">
          <TaxDocPurchaseForm setLoading={setLoading} />
        </Spin>
      </Modal>
    </>
  );
};

export default TaxDocPurchaseButton;
