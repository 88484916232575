import { Alert, Badge, Select } from "antd";
import { SelectProps } from "antd/lib/select";
import React, { FC, useEffect } from "react";
import _t from "../../lang/translate";
import { useEnums } from "../useEnum";
import { EnumItemType, EnumKeys } from "../../types/appTypes";
import Icon from "../../assets/icons/icon";

const { Option } = Select;

export interface EnumSelectProps extends SelectProps<any> {
  nameKey: EnumKeys;
  block?: boolean;
  dark?: boolean;
  dataFilter?: (data: EnumItemType[]) => EnumItemType[] | null;
}

const EnumSelect: FC<EnumSelectProps> = ({
  value,
  onChange,
  loading,
  dataFilter,
  nameKey,
  block,
  dark,
  dropdownClassName,
  defaultActiveFirstOption,
  ...rest
}) => {
  const [enums, isLoading, isError] = useEnums(nameKey);

  useEffect(() => {
    if (defaultActiveFirstOption && enums && enums.length && !value) {
      if (onChange) {
        onChange(enums[0].id, { label: enums[0].title, value: enums[0].id });
      }
    }
  }, [value, enums, defaultActiveFirstOption, onChange]);

  const data = dataFilter && enums ? dataFilter(enums) : enums;

  return (
    <>
      <Select
        showSearch
        onChange={onChange}
        clearIcon={<Icon name="close-circle-outline" size="large" />}
        value={value}
        className={block ? "w-100" : ""}
        loading={isLoading || loading}
        dropdownClassName={`${dark ? "dropdown-dark " : ""}${dropdownClassName}`}
        filterOption={(input, option) =>
          typeof option?.label === "string" && option.label.toLowerCase().startsWith(input.toLowerCase())
        }
        defaultActiveFirstOption
        {...rest}
      >
        {data?.map((e) => (
          <Option value={e.id} label={e.title} key={e.id}>
            {e.color && <Badge color={e.color} />}
            {e.title}
          </Option>
        ))}
      </Select>
      {isError && <Alert message={_t("msg.could_not_fetch_options")} className="mt-1" type="error" closable />}
    </>
  );
};

export default EnumSelect;
