import React, { FC } from "react";
import InputNum, { InputPriceProps } from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";

const EquipmentPriceEuro: FC<InputPriceProps> = ({ value, ...rest }) => {
  return value ? (
    <InputNum disabled={true} placeholder={_t("price", "euro")} value={value} suffix="€" {...rest} />
  ) : null;
};

export default EquipmentPriceEuro;
