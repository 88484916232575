import { FC, useState } from "react";
import { Badge, Button, Col, message, Row, Typography } from "antd";
import CardBox, { CardBoxProps } from "../../../../components/cardBox";
import Details from "../../../../components/detailsList";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { validateMerchant } from "../../../../services/importService";
import { openFileLink } from "../../../../services/mediaService";
import { isAxiosError } from "../../../../utilities/typeGuard";

const ImportMerchantVat: FC<CardBoxProps> = ({ ...rest }) => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const [validating, setValidating] = useState<boolean>();
  const { merchant, links } = importCase;
  const { vatNumber } = merchant || {};

  const handleValidateMerchant = async () => {
    try {
      setValidating(true);
      const { data } = await validateMerchant(importCase.id);
      setImportCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setValidating(false);
    }
  };

  return (
    <CardBox {...rest}>
      <Details borderLess>
        <Details.Item label={_t("vat_number")} value={vatNumber || "-"} />
      </Details>
      <Row justify="space-between" align="middle">
        <Col>
          {
            <Badge
              color={importCase.merchantValidationStatus?.color}
              text={importCase.merchantValidationStatus?.title}
            />
          }{" "}
          /{" "}
          {
            <Typography.Link disabled={!links?.merchantReport} onClick={() => openFileLink(links?.merchantReport)}>
              {_t("download", "pdf")}
            </Typography.Link>
          }
        </Col>
        <Col>
          <Button type="link" className="pl-0 pr-0" onClick={handleValidateMerchant} loading={validating}>
            {_t("validate_merchant")}
          </Button>
        </Col>
      </Row>
    </CardBox>
  );
};

export default ImportMerchantVat;
