import { Result } from "antd";
import _t from "../../../../../lang/translate";
import TaxCaseStore from "../../../../../contexts/taxCaseStore";
import TaxInvoicePaymentFlow from "./taxInvoicePaymentFlow";
import TaxCardPaymentFlow from "./taxCardPaymentFlow";
import TaxAcceptAndPay from "./taxAcceptAndPay";
import { TaxCase } from "../../../../../types/taxTypes";
import { wasPaidWithInvoice, wasPaidWithQuickpay, wasPaidWithStripe } from "../../../../../utilities/paymentTypeHelpers";


type CaseStatus = "created" | "published" | "accepted" | "paid" | "valuationDetermined" | "expired";

const TaxPaymentFlow = () => {
    const { taxCase } = TaxCaseStore.useContainer();
    const status = getCaseStatus(taxCase);

    if (status === 'created') {
        return <CaseCreated/>;
    } else if (status === 'published') {
        return <TaxAcceptAndPay/>;
    } else if (status === 'expired') {
      return <CaseExpired/>
    } else {
        return getPaymentResultComponent(taxCase);
    }
};

export default TaxPaymentFlow;

function getCaseStatus(taxCase:TaxCase):CaseStatus {
    const {flags} = taxCase;

    const expired = flags.expired
    const published = flags.published;
    const accepted = flags.accepted;
    const paid = flags.paid;
    const valuationDetermined = flags.receipted;
    if (expired) {
        return "expired";
    } else if (valuationDetermined) {
        return "valuationDetermined"
    } else if (paid) {
        return "paid";
    } else if (accepted){
        return "accepted"
    } else if (published) {
        return "published"
    } else {
        return "created"
    }
}

function getPaymentResultComponent(taxCase:TaxCase) {
    const paidByInvoice = wasPaidWithInvoice(taxCase.paymentDetails);
    const paidByQuickpay = wasPaidWithQuickpay(taxCase.paymentDetails);
    const paidByStripe = wasPaidWithStripe(taxCase.paymentDetails);

    if (paidByQuickpay || paidByStripe) {
        return <TaxCardPaymentFlow/>;
    } else if (paidByInvoice) {
        return <TaxInvoicePaymentFlow/>
    }

    if (taxCase.flags.accepted && taxCase.flags.paid) {
        return <CasePaid/>;
    }
    return <CaseAccepted/>;
}

// function wasAcceptedByInvoice(taxCase:TaxCase):boolean {
//     return !!(taxCase.flags.accepted && taxCase.cardPaymentDetails == null);
// }


// Generic component
function CaseCreated() {
    return (
        <Result
            status="info"
            title={_t("msg.working_on_the_case")}
            subTitle="Så snart sagen er forhåndsvurderet vil du have mulighed for at betale."
        />
    )
}

// Generic component
function CasePaid() {
    return (
        <Result
            status="success"
            title={_t("paid")+"!"}
            subTitle={_t("we_will_now_valuate_vehicle_in_dmr")}
        />
    )
}

// Generic component
function CaseAccepted() {
    return (
        <Result
            status="success"
            title={_t("accepted")+"!"}
            subTitle={"Sagen er nu accepteret. Afventer betaling."}
        />
    )
}

// Generic component
function CaseExpired() {
    return (
        <Result
            status="error"
            title={_t("case_expired")+"!"}
            subTitle={"Anmod om en genberegning, for at fortsætte med sagen."}
        />
    )
}
