import { FC } from "react";
import { Button, Col, Divider, Form, Row } from "antd";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";
import TradingPriceLevelField from "./tradingPriceLevelField";
import RegulationAmountInput from "./regulationAmountInput";
import InputPercentage from "../../../../components/form/inputPercentage";

interface TradePriceEditFieldsProps {
  stopEditing: () => void;
}

const TradePriceEditFields: FC<TradePriceEditFieldsProps> = ({ stopEditing }) => {
  return (
    <>
      <Form.Item name={["tradePrice", "averagePrice"]} label={_t("market_price")}>
        <InputNum />
      </Form.Item>
      <Form.Item name={["tradePrice", "deliveryFee"]} label={_t("delivery_fee")}>
        <InputNum />
      </Form.Item>
      <Form.Item name={["tradePrice", "deductionAverageDiscount"]} label={_t("average_discount_deduction")}>
        <InputNum />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldsValue }) => (
          <Form.Item name={["tradePrice", "priceLevel"]} label={_t("trading_price_level")}>
            <TradingPriceLevelField input={getFieldsValue(true)?.tradePrice} />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item name={["tradePrice", "qualityAmount"]} label={_t("vehicle_condition")}>
        <InputNum />
      </Form.Item>
      <Form.Item name={["tradePrice", "specialUseAmount"]} label={_t("special_use")}>
        <InputNum />
      </Form.Item>
      <Form.Item name={["tradePrice", "mileageRegulation"]} label={_t("mileage_regulation")}>
        <InputNum />
      </Form.Item>
      <Form.Item name={["tradePrice", "equipmentRegulation"]} label={_t("equipments_regulation")}>
        <InputNum />
      </Form.Item>
      <Form.Item label={_t("other_adjustments")}>
        <Row className="flex-wrap" gutter={8}>
          <Col flex="100px">
            <Form.Item name={["tradePrice", "otherRegulation"]} noStyle>
              <InputPercentage />
            </Form.Item>
          </Col>
          <Col flex="auto">
            <Form.Item
              shouldUpdate={(curr, next) => curr?.tradePrice?.otherRegulation !== next?.tradePrice?.otherRegulation}
              noStyle
            >
              {({ getFieldValue }) => (
                <Form.Item name={["tradePrice", "otherRegulationAmount"]} noStyle>
                  <RegulationAmountInput
                    priceLevel={getFieldValue(["tradePrice", "priceLevel"])}
                    otherRegulation={getFieldValue(["tradePrice", "otherRegulation"])}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Divider />
      <Form.Item name={["tradePrice", "estimatedTradePrice"]} label={_t("quick", "trade_price")}>
        <InputNum />
      </Form.Item>
      <div className="text-right">
        <Button type="primary" onClick={stopEditing}>
          {_t("apply")}
        </Button>
      </div>
    </>
  );
};

export default TradePriceEditFields;
