import { FC, useState } from "react";
import LeasingAdminDealExpireEdit from "./leasingAdminDealExpireEdit";
import LeasingAdminDealExpireInfo from "./leasingAdminDealExpireInfo";

const LeasingDealExpireSettings: FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return isEditing ? (
    <LeasingAdminDealExpireEdit stopEditing={stopEditing} />
  ) : (
    <LeasingAdminDealExpireInfo startEditing={startEditing} />
  );
};

export default LeasingDealExpireSettings;
