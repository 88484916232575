import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "moment/locale/da";
import moment from "moment";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ConfigProvider } from "antd";
import DaDK from "antd/es/locale/da_DK";
import { BrowserRouter } from "react-router-dom";
import { validateMessages } from "./screens/forms/formMessages";
import ErrorBoundary from "./screens/errorBoundary";

moment.locale("da");

ReactDOM.render(
  //<React.StrictMode>
  <ErrorBoundary>
    <ConfigProvider locale={DaDK} form={{ validateMessages }}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </ErrorBoundary>,
  //</React.StrictMode>
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
