import calcTradePrice from './tradePriceCalculator';

import { TradePriceCalcConstsType, TradePriceCalcInputType, TradePriceCalcOutputType } from './tradePriceCalculatorTypes';

export const getTradePriceInitCalcOutput = (consts: TradePriceCalcConstsType): TradePriceCalcOutputType => ({
  optimization: false,
  otherRegulation: 0,
  optimizedRegulation: 0,
  averagePrice: 0,
  yearFactor: 0,
  carAgeInYears: 0,
  averageDiscountAmount: 0,
  tradingPriceTotal: 0,
  qualitySurcharge: 0,
  qualityAmount: 0,
  specialUseSurcharge: 0,
  specialUseValue: 0,
  specialUseAmount: 0,
  kmDifference: 0,
  kmDeductionTotal: 0,
  otherRegulationAmount: 0,
  optimizedRegulationAmount: 0,
  equipmentRegulation: 0,
  equipmentRegulationAmount: 0,
  finalTradePriceTotal: 0,
  consts,
});

const calculateTradePrice = (input: TradePriceCalcInputType) => calcTradePrice(input);

export default calculateTradePrice
