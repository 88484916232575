import { Button, message } from "antd";
import { FC, useState } from "react";
import Icon from "../../../../assets/icons/icon";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { downloadValuationReportUrl } from "../../../../services/leasingService";
import { getFileLink } from "../../../../services/mediaService";

const LeasingEquipmentReport: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { leasingCase } = LeasingStore.useContainer();
  const link = downloadValuationReportUrl(leasingCase.id);

  const downloadIcon = <Icon size="large" name="download-outline" />;

  const handleDownload = async () => {
    if (!link) return;
    try {
      setLoading(true);
      const { data } = await getFileLink(link);
      if (data.url) window.open(data.url, "_blank");
    } catch (ex) {
      message.error(_t("msg.link_not_generated"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button disabled={!link} onClick={handleDownload} icon={downloadIcon} loading={loading}>
      {_t("car_report")}
    </Button>
  );
};

export default LeasingEquipmentReport;
