import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { createContainer } from "unstated-next";
import { getPath } from "../routes/appRoutes";
import {
  getAboutUser,
  getClaimedRole,
  getClipCount,
  getCreditCount,
  getCurrentUser,
  getNotificatiosCount,
  getPermissions,
  getSubscription,
  saveEnums,
  savePaymentMethodDetails,
  saveProductPrices,
  saveRoles,
  saveSubscription,
  saveSubscriptionSelected,
  saveTerms,
  saveUpcomingSubscription,
  setClaimdRoleId,
  storageKeys,
  UserSystemType,
} from "../services/authService";
import { IdType } from "../types/appTypes";
import { NotificationCountType } from "../types/notificationsType";
import { AppRoleType, PermissionTypes } from "../types/permissionTypes";
import { UserEditiblePropTypes } from "../types/userTypes";
import { SubscriptionType } from "../types/subscriptionTypes";

type HasPermissionType = (permission?: PermissionTypes) => boolean;

interface UserStoreProps {
  user: UserSystemType | null;
  onProfileUpdated: (values: UserEditiblePropTypes) => void;
  notificationsCount: NotificationCountType;
  isAuth: boolean;
  isShowCookiePolicy: boolean;
  hasEnoughCredits: (amount: number) => { ok: boolean, usage: { total: number, clip: number, credit: number } };
  showCookiePolicy: () => void;
  hideCookiePolicy: () => void;
  clipCount: number;
  creditCount: number;
  claimedRole: AppRoleType | null;
  switchRole: (roleId: IdType) => void;
  permissions: string[];
  hasPermission: HasPermissionType;
  subscription: SubscriptionType | null;

  fetchUserData: () => void;
  setPartialUser: (newUser: Partial<UserSystemType>) => void;
}

const useUserStore = (): UserStoreProps => {
  const [user, setUser] = useState<UserSystemType | null>(getCurrentUser());
  const history = useHistory();
  const [creditCount, setCreditCount] = useState<number>(getCreditCount());
  const [clipCount, setClipCount] = useState<number>(getClipCount());
  const [subscription, setSubscription] = useState<SubscriptionType | null>(getSubscription());
  const [notificationsCount, setNotificationsCount] = useState<NotificationCountType>(getNotificatiosCount());
  const [claimedRole, setClaimedRole] = useState<AppRoleType | null>(getClaimedRole());
  const [permissions, setPermissions] = useState<string[]>(getPermissions());
  const [isShowCookiePolicy, setIsShowCookiePolicy] = useState<boolean>(false);

  const storageWatcher = useCallback(
    (e: StorageEvent) => {
      if (e.key === storageKeys.token) {
        const nextUser = getCurrentUser();
        if (e.newValue === null) {
          setUser(null);
          window.location.href = getPath("loginAdmin");
        }
        const isModal = history.location.pathname !== getPath("loginAdmin");
        if (isModal) {
          const isValid = nextUser?.expired && nextUser.expired > Date.now() / 1000;
          if (nextUser && isValid) {
            setUser(nextUser);
          }
        }
      }
      if (e.key === storageKeys.notifications) setNotificationsCount(getNotificatiosCount());
      if (e.key === storageKeys.clip) {
        setClipCount(getClipCount());
      }
      if (e.key === storageKeys.credit) {
        setCreditCount(getCreditCount());
      }
      if (e.key === storageKeys.subscription) {
        setSubscription(getSubscription());
      }
      if (e.key === storageKeys.claimedRoleId) {
        setClaimedRole(getClaimedRole());
        setPermissions([...getPermissions(), "system.see_as"]);
      }
      if (e.key === storageKeys.roles) {
        setPermissions(getPermissions());
      }
    },
    [setUser, setNotificationsCount, setPermissions, setClipCount, setCreditCount, setClaimedRole, setSubscription]
  );

  useEffect(() => {
    window.addEventListener("storage", storageWatcher);
    return () => {
      window.removeEventListener("storage", storageWatcher);
    };
  }, [storageWatcher]);

  const hasPermission: HasPermissionType = (permission) => !!permission && permissions.includes(permission);

  const hasEnoughCredits = (amount: number) => {
    if (amount <= 0) {
      return {
        ok: true,
        usage: { clip: 0, credit: 0, total: 0 }
      }
    }

    const available = clipCount + creditCount;

    if (amount <= available) {
      return {
        ok: true,
        usage: { clip: amount, credit: 0, total: amount }
      }
    }

    return {
      ok: false,
      usage: { clip: 0, credit: 0, total: amount }
    }
  }

  const onProfileUpdated = (values: UserEditiblePropTypes) => {
    user && setUser({ ...user, ...values });
  };

  const switchRole = (id: IdType) => {
    setClaimdRoleId(id === user?.role.id ? null : id);
  };

  const showCookiePolicy = () => {
    setIsShowCookiePolicy(true);
  }

  const hideCookiePolicy = () => {
    setIsShowCookiePolicy(false);
  }

  const handleSetPartialUser = useCallback(
    (newUser: Partial<UserSystemType>) => {
      setUser(currentUser => Object.assign({}, currentUser, newUser));
    },
    [setUser]
  );

  const fetchUserData = useCallback(async () => {
    try {
      const { data } = await getAboutUser();
      handleSetPartialUser(data);
      saveSubscription(data.subscription || null);
      saveUpcomingSubscription(data.upcomingSubscription || null);
      saveSubscriptionSelected(data.subscriptionSelected || true);
      savePaymentMethodDetails(data.paymentMethod || null);
      saveTerms(data.terms || null)
      data.productPrices && saveProductPrices(data.productPrices);
      data.enums && saveEnums(data.enums);
      data.roles && saveRoles(data.roles);
      data.roles && setPermissions(getPermissions);
    } catch (e) {
    }
  }, [handleSetPartialUser])

  return {
    user,
    onProfileUpdated,
    clipCount,
    creditCount,
    subscription,
    notificationsCount,
    isAuth: user !== null,
    permissions,
    claimedRole,
    switchRole,
    hasPermission,
    isShowCookiePolicy,
    hasEnoughCredits,
    hideCookiePolicy,
    showCookiePolicy,
    fetchUserData,
    setPartialUser: handleSetPartialUser,
  };
};

const UserStore = createContainer(useUserStore);

export default UserStore;
