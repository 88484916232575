import React, { FC, useState } from "react";
import { Button, message } from "antd";
import Icon from "../../../../assets/icons/icon";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import { getFileLink } from "../../../../services/mediaService";
import { wasPaidWithInvoice, wasPaidWithStripe } from "../../../../utilities/paymentTypeHelpers";
import { isValidLink } from "../../../../utilities/readLink";

const TaxDownloadInvoiceBtn: FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>();
  const { taxCase } = TaxCaseStore.useContainer();
  const { links, paymentDetails } = taxCase;

  let link = taxCase.paymentDetails?.invoiceLink.external;
  let isGeneratingInvoice = false;

  if (wasPaidWithInvoice(paymentDetails)) {
    link = links?.invoiceLink;
    isGeneratingInvoice = !!taxCase.invoiceStartedAt && !link;
  }

  const downloadIcon = <Icon size="large" name="download-outline" />;

  const downloadInvoice = async () => {
    if (!link) return;
    try {
      setLoading(true);
      if (wasPaidWithStripe(paymentDetails)) {
        if (isValidLink(link)) {
          window.open(link, "_blank")
        } else {
          throw new Error("Invalid stripe invoice link")
        }
      } else {
        const { data } = await getFileLink(link);
        window.open(data.url, "_blank")
      }
    } catch (ex) {
      message.error(_t("msg.link_not_generated"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      type="primary"
      ghost
      disabled={!link}
      loading={isGeneratingInvoice || loading}
      icon={downloadIcon}
      onClick={downloadInvoice}
    >
      {children
        ? children
        : _t(isGeneratingInvoice ? "msg.generating_invoice" : "invoice")
      }
    </Button>
  );
};

export default TaxDownloadInvoiceBtn;
