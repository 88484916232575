import React, { FC } from "react";
import Details from "../../../../components/detailsList";
import { Equipment } from "../../../../types/appTypes";
import format from "../../../../utilities/formatNumbers";

interface NewPriceEquipmentsViewProps {
  equipments?: Equipment[] | null;
}

const NewPriceEquipmentsView: FC<NewPriceEquipmentsViewProps> = ({ equipments = [] }) => {
  return (
    <Details>
      {equipments?.map((eq, i) => (
        <Details.Item
          key={i}
          label={
            <span>
              {eq.code} - {eq.name}
            </span>
          }
          value={format.price(eq.price)}
        />
      ))}
    </Details>
  );
};

export default NewPriceEquipmentsView;
