import { Col, Popover, Row } from "antd";
import { FC } from "react";
import CustomCollapseHeader from "../../../../components/customCollapseHeader";
import _t from "../../../../lang/translate";
import TotalNewPrice from "./totalNewPrice";
import Icon from "../../../../assets/icons/icon";
import format from "../../../../utilities/formatNumbers";

type Props = {
  isLargeDeviation: boolean;
  deviationPercentage: number;
}

const NewPricePanelHeader: FC<Props> = ({ isLargeDeviation, deviationPercentage }) => {
  return (
    <CustomCollapseHeader>
      <Row justify="space-between">
        <Col className="d-flex align-middle">
          {_t("new_price")}
          {isLargeDeviation && (
            <Popover
              title={_t("deviation_percentage") + ": " + format.percent(deviationPercentage)}
              content={_t("new_price", "without_equipments") + " " + _t("deviates").toLowerCase() + " " + _t("from", "the_robot")}
            >
              <Icon size="huge" className="ml-05 text-warning" name="alert" />
            </Popover>
          )}
        </Col>
        <Col>
          <TotalNewPrice />
        </Col>
      </Row>
    </CustomCollapseHeader>
  );
};

export default NewPricePanelHeader;
