import React, { FC } from "react";
import { Form, Input } from "antd";
import DateSelect from "../../../../components/form/dateSelect";
import EnumRadio from "../../../../components/form/EnumRadio";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";

const { TextArea } = Input;

const ImportServiceForm: FC = () => {
  return (
    <>
      <Form.Item label={_t("service_serviceBookStatus")} name={["service", "serviceBookStatusId"]}>
        <EnumRadio nameKey={["importCase", "serviceBookStatus"]} />
      </Form.Item>
      <Form.Item label={_t("service_folderStatus")} name={["service", "folderStatusId"]}>
        <EnumRadio nameKey={["importCase", "folderStatus"]} />
      </Form.Item>
      <Form.Item label={_t("service_serviceHistoryStatus")} name={["service", "serviceHistoryStatusId"]}>
        <EnumRadio nameKey={["importCase", "serviceHistoryStatus"]} />
      </Form.Item>
      <Form.Item label={_t("service_latestServiceDate")} name={["service", "latestServiceDate"]}>
        <DateSelect />
      </Form.Item>
      <Form.Item label={_t("service_latestServiceMileage")} name={["service", "latestServiceMileage"]}>
        <InputNum suffix={_t("mileage_unit")} />
      </Form.Item>
      <Form.Item label={_t("service_nextServiceDate")} name={["service", "nextServiceDate"]}>
        <DateSelect />
      </Form.Item>
      <Form.Item label={_t("service_nextServiceMileage")} name={["service", "nextServiceMileage"]}>
        <InputNum suffix={_t("mileage_unit")} />
      </Form.Item>
      <Form.Item label={_t("service_nextServiceContent")} name={["service", "nextServiceContent"]}>
        <TextArea autoSize={{ minRows: 1, maxRows: 3 }} />
      </Form.Item>
    </>
  );
};

export default ImportServiceForm;
