import { Button, Col, message, Row } from "antd";
import { FC, useState } from "react";
import Icon from "../../../assets/icons/icon";
import _t from "../../../lang/translate";
import { downloadClipUsage } from "../../../services/dealerService";
import { isAxiosError } from "../../../utilities/typeGuard";

interface DealerClipUsageFooterProps {
  dealerId: number;
  onCancel: () => void;
}

const DealerClipUsageFooter: FC<DealerClipUsageFooterProps> = ({ dealerId, onCancel }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownloadHistory = async () => {
    try {
      setLoading(true);
      const { data } = await downloadClipUsage(dealerId);
      data.url && window.open(data.url, "_blank");
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="space-between">
      <Col>
        <Button onClick={handleDownloadHistory} loading={loading} icon={<Icon name="download-outline" size="large" />}>
          {_t("full", "report")}
        </Button>
      </Col>
      <Col>
        <Button onClick={onCancel}>{_t("cancel")}</Button>
      </Col>
    </Row>
  );
};

export default DealerClipUsageFooter;
