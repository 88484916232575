import React, { FC } from "react";
import { Button, Form } from "antd";
import Details from "../../../../components/detailsList";
import _t from "../../../../lang/translate";

interface TradePriceViewFieldsProps {
  startEditing: () => void;
}

const TradePriceViewFields: FC<TradePriceViewFieldsProps> = ({ startEditing }) => {
  return (
    <>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const tradePrice = getFieldValue("tradePrice");

          const {
            averagePrice,
            deliveryFee,
            deductionAverageDiscount,
            qualityAmount,
            specialUseAmount,
            mileageRegulation,
            equipmentRegulation,
            otherRegulation,
            otherRegulationAmount,
            estimatedTradePrice,
          } = tradePrice || {};

          const tradingPriceLevel = tradePrice?.tradePriceOutput?.tradingPriceTotal;

          return (
            <Details borderLess>
              <Details.Item label={_t("market_price")} value={averagePrice} />
              <Details.Item value={deliveryFee} label={_t("delivery_fee")} />
              <Details.Item value={deductionAverageDiscount} label={_t("average_discount_deduction")} />
              <Details.Item value={tradingPriceLevel} label={_t("trading_price_level")} />
              <Details.Item value={qualityAmount} label={_t("vehicle_condition")} />
              <Details.Item value={specialUseAmount} label={_t("special_use")} />
              <Details.Item value={mileageRegulation} label={_t("mileage_regulation")} />
              <Details.Item value={equipmentRegulation} label={_t("equipments_regulation")} />
              <Details.Item
                value={otherRegulation ? parseFloat((otherRegulation * 100).toFixed(4)) : otherRegulation}
                label={_t("other_adjustments")}
              />
              <Details.Item value={otherRegulationAmount} label={_t("other_adjustments_amount")} />
              <Details.Divider />
              <Details.Item value={estimatedTradePrice} label={_t("quick", "trade_price")} />
            </Details>
          );
        }}
      </Form.Item>
      <div className="text-right">
        <Button type="link" className="pl-0 pr-0 m-0" onClick={startEditing}>
          {_t("edit")}
        </Button>
      </div>
    </>
  );
};

export default TradePriceViewFields;
