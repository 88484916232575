import { FC } from "react";
import { Badge, Col, Row, Select } from "antd";
import _t from "../../lang/translate";
import { EnumItemType, IdType } from "../../types/appTypes";

const { Option } = Select;

interface ActivitiesTitleProps {
  filters: EnumItemType[] | null;
  loading: boolean;
  onFilter: (id: IdType) => void;
}

const ActivitiesHeader: FC<ActivitiesTitleProps> = ({ filters, loading, onFilter }) => {
  return (
    <Row align="middle">
      <Col flex="auto">{_t("activities", "note", " & ")}</Col>
      {filters && (
        <Col flex="130px">
          <Select
            showSearch
            defaultValue={-1}
            loading={loading}
            onChange={onFilter}
            filterOption={(input, option) =>
              typeof option?.label === "string" && option.label.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {filters?.map((q) => (
              <Option value={q.id} label={q.title} key={q.id}>
                {q.color && <Badge color={q.color} />}
                {q.title}
              </Option>
            ))}
          </Select>
        </Col>
      )}
    </Row>
  );
};

export default ActivitiesHeader;
