import { FC, useState } from "react";
import _t from "../../../lang/translate";
import LeasingDeliveryForm from "./leasingDeliveryForm";
import LeasingDeliveryInfo from "./leasingDeliveryInfo";

const LeasingDelivery: FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <>
      <span className="text-dark d-block text-large mb-1">{_t("delivery")}</span>
      {isEditing ? (
        <LeasingDeliveryForm stopEditing={stopEditing} />
      ) : (
        <LeasingDeliveryInfo startEditing={startEditing} />
      )}
    </>
  );
};

export default LeasingDelivery;
