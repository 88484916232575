import { Button, Col, Form, Input, message, Modal, Row } from "antd";
import React, { FC, useState } from "react";
import Icon from "../../../assets/icons/icon";
import _t from "../../../lang/translate";
import { saveProfile } from "../../../services/profileService";
import { UserEditiblePropTypes } from "../../../types/userTypes";
import { ProfileUpdateFunc } from "../../myAccount";

interface ProfileEditProps {
  onProfileUpdate: ProfileUpdateFunc;
}

const ProfileEdit: FC<ProfileEditProps> = ({ onProfileUpdate }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [profileEditForm] = Form.useForm<UserEditiblePropTypes>();

  const handleOk = () => {
    profileEditForm.validateFields().then(async (values) => {
      try {
        setLoading(true);
        const { data } = await saveProfile(values as UserEditiblePropTypes);
        onProfileUpdate(data);
        profileEditForm.resetFields();
        setVisible(false);
      } catch (error) {
        message.error(error.response?.data?.message || _t("not_saved"));
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <>
      <Button onClick={() => setVisible(true)} className="p-0" type="link">
        <Icon name="create-outline" /> {_t("edit")}
      </Button>
      <Modal
        title={_t("profile_edit")}
        visible={visible}
        okText={_t("save")}
        cancelText={_t("cancel")}
        onOk={handleOk}
        confirmLoading={loading}
        onCancel={() => {
          setVisible(false);
          profileEditForm.resetFields();
        }}
      >
        <Form form={profileEditForm} name="profilePasswordForm" layout="vertical" requiredMark={false}>
          <Row gutter={30}>
            <Col xs={24} lg={16}>
              <Form.Item label={_t("fullname")} name="name" rules={[{ required: true }, { whitespace: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item label={_t("initials")} name="initials">
                <Input maxLength={4} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={_t("phone")} name="phone" rules={[{ required: true }, { whitespace: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ProfileEdit;
