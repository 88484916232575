import { Button, Form, Input, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { FC, useState } from "react";
import _t from "../../../../lang/translate";
import { taxCaseCreationRequest } from "../../../../services/taxService";
import { TaxCreationRequestType } from "../../../../types/taxTypes";
import { isAxiosError } from "../../../../utilities/typeGuard";

const TaxCreateLink: FC = () => {
  const [visiable, setVisiable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<TaxCreationRequestType>();
  const showModal = () => setVisiable(true);
  const hideModal = () => !loading && setVisiable(false);
  const onFinish = async (values: TaxCreationRequestType) => {
    try {
      setLoading(true);
      await taxCaseCreationRequest(values);
      setLoading(false);
      message.success(_t("send"));
      hideModal();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
    hideModal();
  };
  return (
    <>
      <Button className="muted-btn" onClick={showModal}>
        {_t("share", "link")}
      </Button>
      <Modal
        title={_t("share", "link")}
        visible={visiable}
        onOk={form.submit}
        onCancel={hideModal}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ loading }}
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item name="email" label={_t("email")} rules={[{ required: true }, { type: "email" }]}>
            <Input disabled={loading} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TaxCreateLink;
