import { Result } from "antd";
import Icon from "../../../../../assets/icons/icon";
import TaxCaseStore from "../../../../../contexts/taxCaseStore";
import TaxDownloadInvoiceBtn from "../taxDownloadInvoiceBtn";

export default function TaxInvoicePaymentFlow() {
  const { taxCase } = TaxCaseStore.useContainer();
  const { flags, links } = taxCase;
  const link = links?.invoiceLink;
  const awaitingInvoice = !taxCase.invoiceStartedAt && !link
  const isGeneratingInvoice = !!taxCase.invoiceStartedAt && !link;
  const invoiceGenerated = !isGeneratingInvoice && !!link;
  const invoicePaid = flags.paid;

  if (awaitingInvoice) {
    return (
      <Result
        title="Tak for din accept af sagen."
        subTitle={<div>
          <p className="mb-05">Vi har sendt dig en betalingsadvisering på mail. Så snart du har betalt kan vi fastsætte køretøjet og du vil modtage din kvittering til indregistrering.</p>
          <p>Du vil modtage en specificeret faktura på mail efter ca. 20 minutter.</p>
        </div>}
        status={"info"}
        icon={
          <span className="bg-primary border-round text-white width-64 height-64 d-inline-flex justify-content-center align-items-center">
            <Icon className="text-40" name="hourglass-outline" />
          </span>
        }
      />
    )
  }

  if (isGeneratingInvoice) {
    return (
      <Result
        title="Fakturaen bliver genereret!"
        subTitle="Generering af faktura er nu begyndt, og vil ankomme til din e-mail. Venligst vent er par minutter."
        status={"info"}
        icon={
          <span className="bg-primary border-round text-white width-64 height-64 d-inline-flex justify-content-center align-items-center">
            <Icon className="text-40" name="hourglass-outline" />
          </span>
        }
      />
    )
  }
  if (invoiceGenerated && !invoicePaid) {
    return (
      <Result
        title="Faktura er klar til dig!"
        extra={<TaxDownloadInvoiceBtn />}
        status={"info"}
      />
    )
  }

  if (invoicePaid) {
    return (
      <Result
        title="Betalt!"
        subTitle="Registreringsafgiften er nu betalt med faktura. Vi sørger nu for værdifastsættelse af køretøjet i DMR, og du vil hurtigst muligt modtage en kvittering herpå."
        status={"success"}
      />
    )
  }

  return (
    <Result
      title="Noget er gået galt"
      subTitle="Kontakt venligts en administrator."
      status={"warning"}
    />
  )
}
