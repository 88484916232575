import React, { FC, useState } from "react";
import { Divider, message, Spin } from "antd";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import _t from "../../lang/translate";
import { repositionChecklistGroup } from "../../services/checklistService";
import { ChecklistGroupType } from "../../types/checklistTypes";
import ChecklistSortableGroup from "./checklistSortableGroup";
import ChecklistAddGroup from "./checklistAddGroup";
import { isAxiosError } from "../../utilities/typeGuard";

const ChecklistGroupSort: FC = () => {
  const { groups, dispatchGroups } = ChecklistAdminStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const handleGroupUpdate = (group: ChecklistGroupType) => {
    const payload = groups.map((g) => (g.id === group.id ? group : g));
    dispatchGroups({ type: "populate", payload });
  };

  const handleDrop = async ({ removedIndex, addedIndex, payload: movedGroup }: DropResult) => {
    if (removedIndex === null && addedIndex === null) return;

    const originalGroups = [...groups];
    const newGroups = [...groups];
    let newGroup = { ...movedGroup };

    if (removedIndex !== null) {
      newGroup = newGroups.splice(removedIndex, 1)[0];
    }

    if (addedIndex !== null) {
      newGroups.splice(addedIndex, 0, newGroup);
    }
    const payload = newGroups.map((g, i) => ({ ...g, position: i + 1 }));
    dispatchGroups({ type: "populate", payload });
    if (addedIndex !== null) {
      try {
        setLoading(true);
        await repositionChecklistGroup(payload);
      } catch (error) {
        const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
        message.error(errorMessage || _t("msg.unknown_error"));
        dispatchGroups({ type: "populate", payload: originalGroups });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <Container
        groupName="groups"
        onDrop={handleDrop}
        dragHandleSelector=".drag-handle"
        getChildPayload={(index) => groups[index]}
      >
        {groups.map((group) => (
          <Draggable key={group.id}>
            <ChecklistSortableGroup key={group.id} group={group} updateGroup={handleGroupUpdate} />
          </Draggable>
        ))}
      </Container>
      <Divider />
      <ChecklistAddGroup />
    </Spin>
  );
};

export default ChecklistGroupSort;
