import { FC, useState } from "react";
import { Alert, Button, Checkbox, Form, Input, message, Radio, Space } from "antd";
import _t from "../../../lang/translate";
import { initializeValidation } from "../../../services/leasingService";
import { LeasingCaseType, LeasingPartialCaseType, LeasingValidationType } from "../../../types/leasingTypes";
import consts from "../../../utilities/consts";
import CustomerCard from "../../../components/customerCard";
import SwitchInput from "../../../components/form/switchInput";
import { isAxiosError } from "../../../utilities/typeGuard";

interface LeasingSendValidationFormProps {
  leasingCase: LeasingCaseType;
  onCancel: () => void;
  onSent: (response: LeasingPartialCaseType, values: LeasingValidationType) => void;
}

const LeasingSendValidationForm: FC<LeasingSendValidationFormProps> = ({ leasingCase, onSent, onCancel }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id, customer } = leasingCase;

  const handleSubmit = async (values: LeasingValidationType) => {
    try {
      setLoading(true);
      const { data } = await initializeValidation(id, values);
      setLoading(false);
      onSent(data, values);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  const dealInfoOptions = [
    { label: _t("yes"), value: true },
    { label: _t("no_customer_pay_directly_to_partner"), value: false },
  ];

  return (
    <Form<LeasingValidationType> {...consts.formItemProps} className="mb-0" onFinish={handleSubmit}>
      <Form.Item label={_t("customer")} className="mb-3">
        <CustomerCard customer={customer} />
      </Form.Item>
      <Form.Item
        label={_t("confirm_please")}
        className="mb-3"
        name="customerInfoCorrect"
        valuePropName="checked"
        initialValue={false}
      >
        <SwitchInput size="small" className="mb-05" label={_t("customer_info_correct")} />
      </Form.Item>
      <Form.Item label={_t("customer_info")} className="mb-3">
        <span className="text-dark">{_t("known_customer")}</span>
        <Form.Item rules={[{ required: true }]} name={["customerVehicle", "knownCustomer"]}>
          <Input />
        </Form.Item>
        <span className="text-dark">{_t("customer_contact")}</span>
        <Form.Item rules={[{ required: true }]} name={["customerVehicle", "customerContact"]}>
          <Input />
        </Form.Item>
        <span className="text-dark">{_t("vehicle_source")}</span>
        <Form.Item rules={[{ required: true }]} name={["customerVehicle", "vehicleSource"]}>
          <Input />
        </Form.Item>
        {!leasingCase.partnerCase && (
          <Form.Item name="withGuide" valuePropName="checked">
            <Checkbox>{_t("send_guide_to_customer")}</Checkbox>
          </Form.Item>
        )}
      </Form.Item>
      {leasingCase.partnerCase && (
        <Form.Item label={_t("deal_info")} className="mb-3">
          <span className="mb-05 d-block text-dark">{_t("set_off_against_car_price")}</span>
          <Form.Item name="recalculateFirstMonth">
            <Radio.Group options={dealInfoOptions} />
          </Form.Item>
        </Form.Item>
      )}
      <Form.Item className="mb-3" name="note" label={_t("other_information")}>
        <Input.TextArea autoSize={{ maxRows: 4, minRows: 1 }} />
      </Form.Item>

      <Alert
        type="warning"
        closable
        className="mb-2"
        message={
          <>
            <h4>{_t("be_aware")}</h4>- {leasingCase.client?.dealerName} {_t("ht_stands_for_restvalue")}
            <br />- {leasingCase.client?.dealerName} {_t("ht_garanti_after_terms")}
          </>
        }
      />

      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue, submit }) => {
          const confirmed = getFieldValue("customerInfoCorrect");

          return (
            <div className="text-right">
              <Space>
                <Button type="ghost" onClick={onCancel} disabled={loading}>
                  {_t("cancel")}
                </Button>
                <Button onClick={submit} loading={loading} type="primary" disabled={!confirmed}>
                  {_t("send", "application")}
                </Button>
              </Space>
            </div>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default LeasingSendValidationForm;
