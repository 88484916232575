import { Col, Row, Spin, Tooltip, Typography } from "antd";
import { FC, useEffect, useState } from "react";
import _t from "../../lang/translate";
import { segmentKey } from "../../services/urlQueryService";
import { useApi } from "../../services/useApi";
import { DateSegmentType, TopEntityType } from "../../types/statisticsType";
import CardBox, { CardBoxProps } from "../cardBox";
import DividerLine from "../lineSplit";
import StatusTag from "../statusTag";
import StatisticErrorResult from "./statisticErrorResult";
import StatisticSegmentSelect from "./statisticSegmentSelect";

interface StatisticTopEntitiesProps extends CardBoxProps {
  fetchEndpoint: string;
}

const StatisticTopEntities: FC<StatisticTopEntitiesProps> = ({
  fetchEndpoint,
  ...rest
}) => {
  const [segment, setSegment] = useState<DateSegmentType>("month");
  const [{ data, isLoading, isError }, setUrl] = useApi<TopEntityType[]>(
    "",
    []
  );

  useEffect(() => {
    const url = new URL(fetchEndpoint);
    url.searchParams.set(`filter[${segmentKey}]`, segment);
    setUrl(url.toString());
  }, [fetchEndpoint, setUrl, segment]);

  return (
    <CardBox {...rest}>
      <Spin spinning={isLoading}>
        <Row justify="space-between" align="middle" className="mb-05">
          <Col>
            <label>{_t("top", "sellers")}</label>
          </Col>
          <Col>
            <StatisticSegmentSelect segment={segment} onChange={setSegment} />
          </Col>
        </Row>
        {isError && <StatisticErrorResult />}
        {data &&
          data?.map((item, index) => {
            const { created, handled, accepted, dealed, expired } = item || {};

            const dataSet = [
              { label: _t("created"), value: created },
              { label: _t("handled"), value: handled },
              { label: _t("accepted"), value: accepted },
              { label: _t("dealed"), value: dealed },
              { label: _t("expired"), value: expired },
            ];

            const isFirst = index === 0;
            const status = {
              id: 1,
              title: dataSet.map(x => x.value).filter(x => typeof x == 'number').join('/'),
              color: isFirst ? "#2bb509" : "#B9C7CE",
            };
            return (
              <span key={item.name}>
                <Row align="middle">
                  <Col span={18}>
                    <Typography.Text
                      ellipsis
                      className={
                        index === 0 ? "text-success text-large" : "text-dark"
                      }
                    >
                      {item.name}
                    </Typography.Text>
                  </Col>
                  <Col span={6} className="text-right">
                    <Tooltip
                      placement="topLeft"
                      title={dataSet.filter(x => typeof x.value == 'number').map(x => x.label).join('/')}
                    >
                      <StatusTag status={status} size="small" />
                    </Tooltip>
                  </Col>
                </Row>
                {index < data.length - 1 && (
                  <DividerLine className="mt-05 mb-05" />
                )}
              </span>
            );
          })}
      </Spin>
    </CardBox>
  );
};

export default StatisticTopEntities;
