import { FC } from "react";
import { Space } from "antd";
import ImportCollectionAuthorizationBtn from "./importCollectionAuthorizationBtn";
import ImportExpectedDeliveryDate from "./importExpectedDeliveryDate";
import ImportPickupAgreementBtn from "./importPickupAgreementBtn";
import ImportTransportOrderdSwitch from "./importTransportOrderdSwitch";
import DividerLine from "../../../../components/lineSplit";
import ImportPickupSelfSwitch from "./importPickupSelfSwitch";
import ImportPickupOrderBtn from "./importPickupOrderBtn";

const ImportTransportActions: FC = () => {
  return (
    <>
      <ImportPickupSelfSwitch />
      <DividerLine className="mt-05 mb-1" />
      <ImportExpectedDeliveryDate />
      <ImportTransportOrderdSwitch />
      <Space>
        <ImportCollectionAuthorizationBtn />
        <ImportPickupAgreementBtn />
        <ImportPickupOrderBtn />
      </Space>
    </>
  );
};

export default ImportTransportActions;
