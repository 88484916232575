import { FC, useEffect } from "react";
import { logout } from "../services/authService";

const Logout: FC = () => {
  useEffect(() => {
    logout();

    window.location.href = "/";
  }, []);
  return null;
};

export default Logout;
