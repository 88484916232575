import { Button, message } from "antd";
import { FC } from "react";
import openModal from "../../../../components/modal/openModal";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { LeasingPartialCaseType } from "../../../../types/leasingTypes";

const SendToPartnerValidation: FC = () => {
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();

  const handleSend = async () => {
    try {
      const payload = await openModal<LeasingPartialCaseType>("leasingSendToPartner", {
        id: leasingCase.id,
        files: leasingCase.files,
      });
      message.success(_t("saved"));
      setLeasingCase({ ...leasingCase, ...payload });
    } catch (error) {
    }
  };

  return <Button onClick={handleSend}>{_t("send_to_krone_kapital")}</Button>;
};

export default SendToPartnerValidation;
