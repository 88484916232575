import React, { FC } from "react";
import Icon from "../../assets/icons/icon";
import { Activity } from "../../types/activityTypes";
import format from "../../utilities/formatNumbers";

interface ActivityItemInfoProps {
  item: Activity;
  isAdmin?: boolean;
}

const ActivityItemInfo: FC<ActivityItemInfoProps> = ({ item, isAdmin }) => {
  const { date, isPublic, isImportant } = item;
  return (
    <span className="small muted mb-05 d-block">
      {`${format.dateAndTime(date)}`}&nbsp;
      {isAdmin && isPublic ? (
        <>
          &nbsp;•&nbsp; <Icon name="earth-outline" />
        </>
      ) : null}
      {isAdmin && isImportant ? (
        <>
          &nbsp;•&nbsp; <Icon name="warning" size="large" color="#FF9524" />
        </>
      ) : null}
    </span>
  );
};

export default ActivityItemInfo;
