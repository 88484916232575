import React, { FC, ReactNode } from "react";
import { message } from "antd";
import _t from "../../lang/translate";
import { isAxiosError, hasKey } from "../../utilities/typeGuard";

interface ErrorMessageProps {
  message?: ReactNode;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  return <>{message || _t("msg.unknown_error")}</>;
};

export const renderErrorMessage = (error: unknown) => {
  if (isAntFormError(error)) {
    renderAntFormErrorMessages(error)
  } else {
    let errorMessage: string | null = null;
    if (typeof error === "string") {
      errorMessage = error;
    } else if (isAxiosError(error)) {
      errorMessage = error.response?.data?.message;
    }
    else if (error instanceof Error) {
      errorMessage = error.message;
    }
    message.error(<ErrorMessage message={errorMessage} />);
  }
}

export default ErrorMessage;

const isAntFormError = (error: unknown) => {
  return typeof error === 'object'
    && !!error
    && hasKey('errorFields', error)
    && Array.isArray(error.errorFields)
    && error.errorFields.length
}
const renderAntFormErrorMessages = (error: unknown) => {
  // This if-check is necessary for typescript. isAntFormError(error) doesn't do type-narrowing.
  if (typeof error === 'object'
    && !!error
    && hasKey('errorFields', error)
    && Array.isArray(error.errorFields)
    && error.errorFields.length
  ) {
    let errorMessage = '';
    error.errorFields.forEach(errorField => {
      errorMessage = getErrorField_Name(errorField);
      errorMessage += ": ";
      errorMessage += getErrorField_errors(errorField);
      message.error(<ErrorMessage message={errorMessage} />);
    })
  } else {
    message.error(<ErrorMessage message={null} />);
  }
}

function getErrorField_Name(errorField: unknown) {
  let errorMessage = '';
  if (
    typeof errorField === 'object' &&
    errorField &&
    hasKey('name', errorField) &&
    Array.isArray(errorField.name)
  ) {
    for (const namePath of errorField.name) {
      errorMessage += String(namePath) + " ";
    }
  }
  return errorMessage
}
function getErrorField_errors(errorField: unknown) {
  let errorMessage = '';
  if (typeof errorField === 'object' && errorField &&
    hasKey('errors', errorField) &&
    Array.isArray(errorField.errors)
  ) {
    for (const errorMessages of errorField.errors) {
      errorMessage += String(errorMessages) + " ";
    }
  }
  return errorMessage
}
