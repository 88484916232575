import React, { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ScreenProps } from "../types/appTypes";
import LoginForm from "./forms/login/loginForm";
import { getLoginMode, getPath } from "../routes/appRoutes";
import ForgotPassword from "./forms/login/forgotPassword";
import ResetPassword from "./forms/login/resetPassword";
import { getCurrentUser } from "../services/authService";
import { ParamLoginTypes } from "../types/loginTypes";
import { ReactComponent as Logo } from "../assets/images/dmf_logo.svg";
import { ReactComponent as LogoTagLine } from "../assets/images/dmf_tagLine.svg";
import { Button, Col, Dropdown, Row } from "antd";
import appConfig from "../appConfig";
import ContactMenuDropdown from "../components/contactMenu";
import UserStore from "../contexts/userStore";
import LayoutStore from "../contexts/layoutStore";
import _t from "../lang/translate";

const Login: FC<ScreenProps> = ({ title }) => {
  const history = useHistory();
  const { screen } = LayoutStore.useContainer();
  const isMobile = screen.isMobile;
  const { showCookiePolicy } = UserStore.useContainer();
  const showCookies = !!process.env.REACT_APP_COOKIEBOT_ID;

  const { mode } = useParams<ParamLoginTypes>();

  useEffect(() => {
    const user = getCurrentUser();
    if (user !== null) history.push(getPath("home"));
  }, [history]);

  const getForm = (currentMode?: string) => {
    if (currentMode === "forgotPassword") {
      return <ForgotPassword />;
    } else if (currentMode === "resetPassword") {
      return <ResetPassword />;
    } else {
      return <LoginForm title={title} />;
    }
  };

  return (
    <Row className="login">
      <Col flex={'500px'} className="transition">
        <div className="login-form-wp">
          <div className="login-mobile-logo">
            <Logo />
          </div>
          <div>{getForm(getLoginMode(mode))}</div>
          <div className="login-footer">
            <Row gutter={16} justify={isMobile ? "start" : "space-between"} align="middle">
              <Col>
                <span className="muted">{appConfig("companyName")}</span>
              </Col>
              <Col>
                <ContactMenuDropdown />
              </Col>

              {showCookies && (
                <Col>
                  <Button className="underline-style p-0" type="link" onClick={showCookiePolicy}>
                    {_t("cookie_policy")}
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </Col>
      <Col flex="auto" className="login-bg">
        <LogoTagLine className="position-relative z-4" />
      </Col>
    </Row>
  )
};

export default Login;
