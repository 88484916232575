import { Steps } from "antd";
import React, { FC } from "react";
import LogText from "../../../../components/logText";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { LogObj } from "../../../../types/appTypes";

const { Step } = Steps;

const ImportProcessSteps: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { log, flags } = importCase;

  const getLogDescription = (log?: LogObj | null) => {
    return log ? <LogText className="p-0" logData={log} /> : <em>{_t("pending")}</em>;
  };

  const getStatus = (value?: boolean) => {
    return value ? "process" : "wait";
  };

  return (
    <Steps progressDot direction="vertical" className="mb-1">
      <Step title={_t("case_created")} description={getLogDescription(log?.creation)} status={getStatus(true)} />
      <Step
        title={_t("vehicle_handled")}
        description={getLogDescription(log?.offerPublished)}
        status={getStatus(flags.offerPublished)}
      />
      <Step
        title={_t("vehicle", "ordered")}
        description={getLogDescription(log?.requested)}
        status={getStatus(flags.requested)}
      />
      <Step
        title={_t("purchase-contract-GR", "received")}
        description={getLogDescription(log?.purchaseContractSigned)}
        status={getStatus(flags.purchaseContractSigned)}
      />
      <Step
        title={_t("entry-certificate-GR", "received")}
        description={getLogDescription(log?.entryCertificateSigned)}
        status={getStatus(flags.entryCertificateSigned)}
      />
      <Step
        title={_t("take-home-agreement", "received")}
        description={getLogDescription(log?.takeHomeAgreementSigned)}
        status={getStatus(flags.takeHomeAgreementSigned)}
      />
      <Step
        title={_t("payment", "registered")}
        description={getLogDescription(log?.paid)}
        status={getStatus(flags.paid)}
      />
      <Step
        title={_t("vehicle_coming_home")}
        description={getLogDescription(log?.transportOrdered)}
        status={getStatus(flags.transportOrdered)}
      />
      <Step
        title={_t("vehicle", "delivered")}
        description={getLogDescription(log?.completed)}
        status={getStatus(flags.completed)}
      />
    </Steps>
  );
};

export default ImportProcessSteps;
