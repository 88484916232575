import { FC, useState } from "react";
import LeasingAdminDealActiveEdit from "./leasingAdminDealActiveEdit";
import LeasingAdminDealActiveInfo from "./leasingAdminDealActiveInfo";

const LeasingDealActiveSettings: FC = () => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return isEditing ? (
    <LeasingAdminDealActiveEdit stopEditing={stopEditing} />
  ) : (
    <LeasingAdminDealActiveInfo startEditing={startEditing} />
  );
};

export default LeasingDealActiveSettings;
