import { Button, Modal } from "antd";
import { FC, useState } from "react";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import DealerStatistics from "./dealerStatistics";

const DealerAdminStatistics: FC = () => {
  const { dealer } = DealerStore.useContainer();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  return (
    <>
      <Button type="link" className="pl-0 pr-0 mt-1" onClick={openModal}>
        {_t("show", "statistics")}
      </Button>
      <Modal
        width={800}
        visible={isVisible}
        onCancel={closeModal}
        title={_t("statistics")}
        okButtonProps={{ className: "d-none" }}
        bodyStyle={{ padding: 0 }}
      >
        <DealerStatistics dealerId={dealer.id} />
      </Modal>
    </>
  );
};

export default DealerAdminStatistics;
