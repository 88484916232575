import React, { FC, ReactElement, useCallback } from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import _t from "../../../../../lang/translate";
import { Vehicle } from "../../../../../types/appTypes";
import LoadingContainer from "../../../../../components/loadingContainer";
import { isString, isValidNum } from "../../../../../utilities/typeGuard";
import format from "../../../../../utilities/formatNumbers";
import Icon from "../../../../../assets/icons/icon";
import { FormInstance } from "antd/es";
import DividerLine from "../../../../../components/lineSplit";
import { formatVin, isValidVin } from "../../../../../utilities/stringExtraFunctions";
import PopoverList from "../../../../../components/popups/popoverList";

const { Link } = Typography;


type VehicleDataSourceListProps = {
    localVehicle: Vehicle | undefined | null;
    getRemoteVehicleData?: () => Promise<void>;
    form: FormInstance<Vehicle>;
    sourceType: "dealer" | "dmr" | "dat";
    loading: boolean;
    dealerDataHasLink: boolean;
    formVinValue?: string;
    warningFields: Map<keyof Vehicle, string[]>;
};

const VehicleDataSourceList = ({ localVehicle, form, sourceType, dealerDataHasLink, formVinValue, getRemoteVehicleData, loading, warningFields }: VehicleDataSourceListProps) => {
    const {
        brand,
        brandId,
        model,
        type,
        typeId,
        modelDesignation,
        modelPeriod,
        equipmentVariant,
        firstRegDate,
        vin,
        mileage,
        quality,
        qualityId,
        useCase,
        useCaseId,
        fuel,
        fuelId,
        engineSize,
        horsePower,
        gearbox,
        gearboxId,
        body,
        bodyId,
        emissionAmount,
        batteryCapacity,
        electricConsumption,
        electricRange,
        emissionUnit,
        emissionUnitId,
        isHighWeight,
        isNew,
        link,
    } = (localVehicle || {}); // if <--  || {}  --> is hit, then all values extracted will be undefined.
    const title = getSourceTypeTitle(sourceType);
    const vinIsNotMatching = formatVin(vin) !== formatVin(formVinValue);

    const copyToForm = useCallback((key: keyof Vehicle, value: Vehicle[keyof Vehicle]) => {
        if (key in vehicleItemsTransformConfig) {
            const tranformedValue = vehicleItemsTransformConfig[key](value);
            value = tranformedValue;
        }
        form.setFieldsValue({
            [key]: value,
        })
    }, [form])

    const copyEntireVehicle = () => {
        const prevFormState = form.getFieldsValue();
        const newFormState: Record<string, any> = {};
        if (!localVehicle) { return; }
        for (const [key, value] of Object.entries(localVehicle)) {
            if (key in prevFormState) {
                if (key in vehicleItemsTransformConfig) {
                    const tranformedValue = vehicleItemsTransformConfig[key](value);
                    newFormState[key] = tranformedValue;
                } else {
                    newFormState[key] = value;
                }
            }
        }
        form.setFieldsValue(newFormState)
    }

    const copyEmissionData = () => {
        const newFormValues: Partial<Vehicle> = {
            fuelId: localVehicle?.fuelId,
            emissionAmount: localVehicle?.emissionAmount,
            emissionUnitId: localVehicle?.emissionUnitId,
            batteryCapacity: localVehicle?.batteryCapacity,
            electricConsumption: localVehicle?.electricConsumption,
            electricRange: localVehicle?.electricRange,
        }
        form.setFieldsValue(newFormValues)
    }

    return (
        <LoadingContainer loading={loading} showLogo>
            <div className={`p-1 border-rounded ${(vinIsNotMatching || warningFields.size) ? 'bg-warning' : 'bg-light'}`}>
                <Space style={{ paddingBottom: 16, justifyContent: "space-between", width: "100%" }} align="center">
                    <Typography.Title className="" level={3}>
                        {title}
                    </Typography.Title>
                    {sourceType !== "dealer" && (
                        <Button
                            icon={<Icon name="refresh-outline" />}
                            loading={loading}
                            disabled={!isValidVin(formVinValue)}
                            onClick={getRemoteVehicleData}
                            style={{ marginRight: '-8px' }}
                        />
                    )}
                </Space>

                {link ? (
                    <VehicleItem displayValue={
                        <Link href={link} target="_blank">
                            {link}
                        </Link>
                    }
                        value={link} name="link"
                        handleCopyClick={copyToForm}
                    />
                ) : dealerDataHasLink
                    ? (
                        // The remote vehicle data sources will probably never contain a link, while the dealerData can
                        // So they need to be aligned properly in that use case
                        // Hence this div, which simulates the size and margin of the link field for the dealer data.
                        <div style={{ height: 31, marginBottom: 8 }}></div>
                    ) : null}
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(type)} value={typeId} name="typeId" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={format.yesOrNo(isNew)} value={isNew} name="isNew" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(brand)} value={brandId} name="brandId" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(model)} value={model} name="model" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(equipmentVariant)} value={equipmentVariant} name="equipmentVariant" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(modelDesignation)} value={modelDesignation} name="modelDesignation" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(modelPeriod)} value={modelPeriod} name="modelPeriod" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={format.date(firstRegDate)} value={firstRegDate} name="firstRegDate" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(vin).toUpperCase()} value={vin} name="vin"
                    warning={vinIsNotMatching}
                    warningMessage={vinIsNotMatching
                        ? [_t("msg.vin_not_equal"), `${vin ?? '-'} ${_t('is_not')} ${formVinValue}`]
                        : undefined}
                />
                <VehicleItem handleCopyClick={copyToForm} displayValue={format.milage(mileage)} value={mileage} name="mileage" />
                <DividerLine className="mt-1 mb-1" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(engineSize)} value={engineSize} name="engineSize" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(horsePower)} value={horsePower} name="horsePower" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(gearbox)} value={gearboxId} name="gearboxId" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(body)} value={bodyId} name="bodyId" className="mb-2" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(quality)} value={qualityId} name="qualityId" className="mb-2" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(useCase)} value={useCaseId} name="useCaseId" />
                <div className="mb-05" style={{ marginTop: 36, marginInline: "-8px" }}>
                    <Button onClick={copyEmissionData}
                        type="primary"
                        className="w-100"
                        icon={<Icon name="copy-outline" />}
                    >
                        {_t("copy", "emission_data")}
                    </Button>
                </div>
                <VehicleItem handleCopyClick={copyToForm} displayValue={formatValue(fuel)} value={fuelId} name="fuelId" />
                {form.getFieldValue('typeId') === 2 && (
                    <VehicleItem handleCopyClick={copyToForm} displayValue={format.yesOrNo(isHighWeight)} value={isHighWeight} name="isHighWeight" />
                )}
                <VehicleItem handleCopyClick={copyToForm} displayValue={format.emission(emissionAmount)} value={emissionAmount} name="emissionAmount" />
                <VehicleItem handleCopyClick={copyToForm}
                    displayValue={formatValue(emissionUnit)}
                    value={emissionUnitId}
                    name="emissionUnitId"
                    warning={!!warningFields.get("emissionUnit")}
                    warningMessage={warningFields.get("emissionUnit")}
                />
                <VehicleItem handleCopyClick={copyToForm} displayValue={format.batteryCapacity(batteryCapacity)} value={batteryCapacity} name="batteryCapacity" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={format.batteryUsage(electricConsumption)} value={electricConsumption} name="electricConsumption" />
                <VehicleItem handleCopyClick={copyToForm} displayValue={format.milage(electricRange)} value={electricRange} name="electricRange" />
                <Button onClick={copyEntireVehicle}
                    type="primary"
                    className="w-100 mt-1"
                    icon={<Icon name="copy-outline" />}
                >
                    {_t("copy", "all")}
                </Button>
            </div>
        </LoadingContainer>
    );
};
export default VehicleDataSourceList;

const VehicleItem = ({ displayValue, value, name, className, handleCopyClick, warning, warningMessage }: {
    displayValue: number | string | undefined | null | ReactElement;
    value: Vehicle[keyof Vehicle];
    name: keyof Vehicle;
    className?: string;
    handleCopyClick: (key: keyof Vehicle, value: Vehicle[keyof Vehicle]) => void;
    warning?: boolean;
    warningMessage?: string[];
}) => {

    return (
        <VehicleItemWrapper list={warningMessage}>
            <Row className={`mb-05 ${warning ? 'bg-warning-darker' : 'bg-muted'} border-rounded ` + (className ? className : '')} wrap={false} gutter={16} align="middle" justify="space-between">
                <Col className="max-width-full-with-offset">
                    <Typography.Text ellipsis title={value?.toString()}>
                        {displayValue}
                    </Typography.Text>
                </Col>
                <Col className="pr-0">
                    <Button
                        type="primary"
                        onClick={() => handleCopyClick(name, value)}
                        icon={<Icon name="copy-outline" />}
                    />
                </Col>
            </Row>

        </VehicleItemWrapper>
    )
}

const VehicleItemWrapper: FC<{ list?: string[] }> = ({ list, children }) => {
    return list
        ? (
            <PopoverList list={list}>
                {children}
            </PopoverList>
        ) : (<>
            {children}
        </>)

}


const vehicleItemsTransformConfig: Record<string, any> = {
    engineSize: (value: any) => String(value != null ? value : ""),
    horsePower: (value: any) => String(value != null ? value : ""),
    mileage: (value: any) => String(value != null ? value : 0),
    emissionAmount: (value: any) => String(value != null ? value : 0),
}

function getSourceTypeTitle(sourceType: VehicleDataSourceListProps['sourceType']) {
    switch (sourceType) {
        case "dealer": return _t("dealer");
        case "dmr": return _t("DMR");
        case "dat": return _t("DAT");
        default: return "";
    }
}


const formatValue = (v?: unknown | null) => {
    if (v && isString(v)) {
        return v.trim()
    } else if (isValidNum(v) || typeof v === 'boolean') {
        return v + "";
    } else {
        return "-";
    }
};
