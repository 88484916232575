import React, { useState } from "react";
import { Result, Space, Table } from "antd";
import _t from "../../../../../lang/translate";
import {
  EquipmentTaxEstimationRobot,
  TaxEstimationRobotData,
} from "../../../../../types/taxEstimationTypes";
import { ColumnsType } from "antd/lib/table";
import format from "../../../../../utilities/formatNumbers";
import { sortAlphabetically } from "./similarVehiclesTable";
import { isValidNum } from "../../../../../utilities/typeGuard";
import { getRandomString } from "../../../../../utilities/stringExtraFunctions";

type Props = {
  taxEstimationRobotData: TaxEstimationRobotData | undefined | null;
  filteredEquipment?: any[]; // TODO
};

// TODO --> Show filtered Equipment from DAT on the filteredEquipment prop.

const EquipmentTable = ({ taxEstimationRobotData }: Props) => {
  const [totalValueInDKK, setTotalValueInDKK] =
    useState(taxEstimationRobotData?.resolvedValues.equipmentPrice);
  const [numberOfEquipmentShown, setNumberOfEquipmentShown] =
    useState(taxEstimationRobotData?.resolvedValues.equipments.length);

  if (taxEstimationRobotData === null || taxEstimationRobotData === undefined) {
    return <Result status="error" title={_t("msg.get_tax_estimation_first")} />;
  }

  const tableRows: TableData[] = taxEstimationRobotData.resolvedValues.equipments.map(equipment => {
    return getTableRowData(equipment);
  })

  const tableColumns: ColumnsType<TableData> = getTableColumns()

  const renderTitle = () => {
    return (
      <Space size="large" className="pt-03">
        <h4>
          <span className="text-gray mr-03">{_t("equipment")}:</span>
          {taxEstimationRobotData.resolvedValues.equipments.length}
        </h4>
        <h4>
          <span className="text-gray mr-03">{_t("equipment", 'chosen')}:</span>
          {numberOfEquipmentShown}
        </h4>
        <h4>
          <span className="text-gray mr-03">{_t("total", 'equipment_price')}:</span>
          {format.price(taxEstimationRobotData.resolvedValues.equipmentPrice)}
        </h4>
        <h4>
          <span className="text-gray mr-03">{_t("filtered", 'equipment_price')}:</span>
          {format.price(totalValueInDKK)}
        </h4>
        <h4>
          <span className="text-gray mr-03">{_t("euro", 'factor', '')}:</span>
          {taxEstimationRobotData.resolvedValues.euroFactor}
        </h4>
        <h4>
          <span className="text-gray mr-03">{_t("euro_exchange_rate")}:</span>
          {taxEstimationRobotData.resolvedValues.euroExchangeRate}
        </h4>
      </Space>
    )
  }

  return (
    <Table<TableData>
      className="border-radius no-white-space similar-vehicles-table"
      bordered
      tableLayout="auto"
      title={() => renderTitle()}
      columns={tableColumns}
      dataSource={tableRows}
      onChange={(_pagination, _filters, _sorter, extra) => {
        setTotalValueInDKK(sumOfEquipment(extra.currentDataSource, 'price'))
        setNumberOfEquipmentShown(extra.currentDataSource.length)
      }}
      pagination={false}
      scroll={{ y: 600 }}
      size={"small"}
    />
  );
};

export default EquipmentTable;


const getTableColumns = (): ColumnsType<TableData> => {
  return [
    {
      key: "code",
      dataIndex: "code",
      title: _t("code"),
      sorter: (a, b) => sortAlphabetically(a, b, 'code'),
    },
    {
      key: "name",
      dataIndex: "name",
      title: _t("name"),
      sorter: (a, b) => sortAlphabetically(a, b, 'name'),
    },
    {
      key: "priceEuro",
      dataIndex: "priceEuro",
      title: _t("price_external_euro"),
      sorter: (a, b) => (a.priceEuro || 0) - (b.priceEuro || 1),
      render: (price, row) => format.price(price, 0, "EUR"),
    },
    {
      key: "price",
      dataIndex: "price",
      title: _t("price_external_dkk"),
      sorter: (a, b) => (a.price || 0) - (b.price || 1),
      render: (price, row) => format.price(price),
      onFilter: (filter, record) => {
        if (filter === 'BELOW_TEN_THOUSAND') {
          return 10000 > (record.price || 0);
        } else if (filter === 'ABOVE_TEN_THOUSAND') {
          return 10000 < (record.price || 0);
        }
        return true;
      },
      filters: [
        {
          text: "Under 10.000 kr.",
          value: 'BELOW_TEN_THOUSAND',
        },
        {
          text: "Over 10.000 kr.",
          value: 'ABOVE_TEN_THOUSAND',
        }
      ],
    },
  ];
}


function getTableRowData(equipment: EquipmentTaxEstimationRobot): TableData {
  return {
    key: equipment.id || getRandomString(5),
    name: equipment.name,
    code: equipment.code,
    priceEuro: equipment.priceEuro,
    price: equipment.price,
  }
}


function sumOfEquipment<T extends TableData>(
  list: T[],
  key: keyof T
): number {
  if (list.length) {
    return list.reduce((acc: number, curr: T): number => {
      const num = curr[key];
      if (isValidNum(num)) {
        return acc + num;
      } else {
        return acc + 0;
      }
    }, 0)
  } else {
    return 0;
  }
}


type TableData = {
  key: string | number;
} & Pick<EquipmentTaxEstimationRobot,
  | "code"
  | "name"
  | "priceEuro"
  | "price"
>

