import { Space, Typography } from "antd";
import { FC } from "react";
import Icon from "../../../../assets/icons/icon";
import DividerLine from "../../../../components/lineSplit";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import TaxDocPurchase from "./taxDocPurchase";
import TaxDocPurchaseButton from "./taxDocPurchaseButton";
import TaxDocPurchasedMessage from "./taxDocPurchasedMessage";
import { LoadingOutlined } from "@ant-design/icons";

const TaxDocumentationInfo: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const { documentationBought, documentationPublished } = taxCase.flags || {};

  const docStatus = documentationPublished ? (
    <Typography.Text type="secondary">
      <Icon name="checkmark-outline" /> {_t("documented")}
    </Typography.Text>
  ) : (
    <Space>
      <LoadingOutlined /> {_t("generating", "documentation")}
    </Space>
  );
  return (
    <>
      {documentationBought ? <TaxDocPurchasedMessage /> : <TaxDocPurchase />}

      <DividerLine fluid />
      <div className="text-right">
        {taxCase.flags.documentationBought ? (
          docStatus
        ) : <TaxDocPurchaseButton expired={!!taxCase.flags.expired} />}
      </div>
    </>
  );
};

export default TaxDocumentationInfo;
