import { Collapse } from "antd";
import { FC } from "react";
import CustomCollapse from "../../../../components/customCollapse";
import CustomCollapseHeader from "../../../../components/customCollapseHeader";
import DividerLine from "../../../../components/lineSplit";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";

const LeasingValidationAdminInfo: FC = () => {
  const { adminValues, leasingCase } = LeasingStore.useContainer();
  const { customerVehicle, note, recalculateFirstMonth } = adminValues?.validation || {};
  const { knownCustomer, customerContact, vehicleSource } = customerVehicle || {};
  const disabled = !leasingCase.flags.validationStarted;

  return (
    <CustomCollapse>
      <Collapse.Panel
        collapsible={disabled ? "disabled" : undefined}
        key="dealer_merchant_relation"
        header={
          <CustomCollapseHeader className={disabled ? "muted" : ""}>{_t("validation", "info")}</CustomCollapseHeader>
        }
      >
        <label>{_t("known_customer")}</label>
        <p className="mb-05">{knownCustomer || "-"}</p>

        <label>{_t("customer_contact")}</label>
        <p className="mb-05">{customerContact || "-"}</p>

        <label>{_t("vehicle_source")}</label>
        <p className="mb-0">{vehicleSource || "-"}</p>

        <DividerLine className="mb-05 mt-05" />

        <label>{_t("set_off_against_car_price")}</label>
        <p className="mb-05">{format.yesOrNo(recalculateFirstMonth)}</p>

        <label>{_t("note")}</label>
        <p className="mb-0">{note || "-"}</p>
      </Collapse.Panel>
    </CustomCollapse>
  );
};

export default LeasingValidationAdminInfo;
