import React, { FC, useState } from "react";
import { Button, Col, Dropdown, Menu, message, Row, Space } from "antd";
import ActivityDrawer from "../../../components/activityDrawer";
import openModal from "../../../components/modal/openModal";
import StatusTag from "../../../components/statusTag";
import ImportCaseStore from "../../../contexts/importCaseStore";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import { ImportCaseType } from "../../../types/importTypes";
import ImportCaseDealer from "./admin/importCaseDealer";
import { getPath } from "../../../routes/appRoutes";
import log from "../../../services/logService";
import { isAxiosError } from "../../../utilities/typeGuard";
import { useHistory } from "react-router";
import { LocationStateType } from "../../../types/appTypes";
import Icon from "../../../assets/icons/icon";

const ImportCaseHeadExtra: FC = () => {
  const history = useHistory<LocationStateType>();
  const { hasPermission } = UserStore.useContainer();
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const canCreateTax = hasPermission("tax.create");
  const { id } = importCase;
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const isAdmin = hasPermission("import.isAdmin");

  const handleStatusChange = async () => {
    try {
      const { id, status, flags } = importCase;
      const newCase = await openModal<ImportCaseType>("changeStatus", {
        id,
        context: "import",
        initialValues: {
          id: status?.id,
          forced: flags?.forcedStatus,
        },
      });
      setImportCase(newCase);
    } catch (error) {
    }
  };

  const handleCreateTax = async () => {
    try {
      const data = await openModal<ImportCaseType>("importToTaxCase", { id: importCase.id });
      if (data?.id) {
        history.push({
          pathname: getPath("tax", `${data.id}`),
        });
      } else {
        log("Exporting tax to tax case returned data without Id", { data });
      }
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    }
  };

  const exportMenu = (
    <Menu>
      {canCreateTax && (
        <Menu.Item key="toTax" onClick={handleCreateTax}>
          {_t("tax")}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Row align="middle" gutter={[8,16]}>
      <Col flex="auto">{isAdmin && <ImportCaseDealer />}</Col>
      <Col>
        <Space wrap>
          {isAdmin ? (
            <StatusTag locked={importCase.status?.forced} status={importCase.status} onClick={handleStatusChange} />
          ) : (
            <StatusTag status={importCase.status} />
          )}

          {id && canCreateTax && (
            <Dropdown overlay={exportMenu}>
              <Button>{_t("move", "to")}</Button>
            </Dropdown>
          )}

          {isAdmin && importCase.links?.dealerView && (
            <Button href={importCase.links.dealerView} target="_blank" icon={<Icon name="open-outline"/>}>DMF PRO</Button>
          )}

          <Button onClick={() => setDrawerVisibility(true)}>{_t("activities", "note", " & ")}</Button>
        </Space>
        <ActivityDrawer
          visible={drawerVisibility}
          isAdmin={isAdmin}
          setVisibility={setDrawerVisibility}
          type="import"
          id={importCase.id}
        />
      </Col>
    </Row>
  );
};

export default ImportCaseHeadExtra;
