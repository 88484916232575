import React, { FC } from "react";
import CardBox from "../../../../components/cardBox";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { Col, Row } from "antd";

const ImportMerchantCompletedCases: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { createdCasesAmount, completedCasesAmount, acceptedCasesAmount, problematicCasesAmount } = importCase.merchant || {};

  const sets = [
    {
      label: _t("total"),
      value: (createdCasesAmount || "-"),
    },
    {
      label: _t("accepted"),
      value: (acceptedCasesAmount || "-"),
    },
    {
      label: _t("completed"),
      value: (completedCasesAmount || "-"),
    },
    {
      label: _t("problematic"),
      value: (problematicCasesAmount || "-"),
    },
  ];

  return (
    <CardBox className="pt-1 pb-1">
      {sets.map((set, i) => (
        <Row justify="space-between" key={i}>
          <Col>
            <label>{set.label}</label>
          </Col>
          <Col>
            <span className="text-dark">{set.value}</span>
          </Col>
        </Row>
      ))}
    </CardBox>
  );
};

export default ImportMerchantCompletedCases;
