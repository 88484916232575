import { Button } from "antd";
import { FC } from "react";
import Details from "../../../../components/detailsList";
import LogText from "../../../../components/logText";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";

interface LeasingAdminDealActiveInfoProps {
  startEditing?: () => void;
}

const LeasingAdminDealActiveInfo: FC<LeasingAdminDealActiveInfoProps> = ({ startEditing }) => {
  const { adminValues } = LeasingStore.useContainer();
  const {
    vehiclePriceInvoice,
    dealerProvisionInvoice,
    periodTax,
    startDateDmr,
    restValueNotUpdated,
    vipVehicle,
    customCaseId,
    log
  } =
    adminValues?.deal?.activeSettings || {};

  const logExtraProps = {
    small: true,
    className: "p-0",
  };

  return (
    <>
      <Details borderLess>
        <Details.Item
          label={_t("vehicle_price_invoice")}
          value={format.price(vehiclePriceInvoice)}
          extra={<LogText logData={log?.vehiclePriceInvoice} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("dealer_provision_invoice")}
          value={format.price(dealerProvisionInvoice)}
          extra={<LogText logData={log?.dealerProvisionInvoice} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("period_tax")}
          value={format.price(periodTax)}
          className="mb-1"
          extra={<LogText logData={log?.periodTax} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("start_date")}
          value={format.date(startDateDmr)}
          extra={<LogText logData={log?.startDateDmr} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("custom_case_id")}
          value={format.text(customCaseId)}
          extra={<LogText logData={log?.customCaseId} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("rest_value_not_updated")}
          value={format.yesOrNo(restValueNotUpdated)}
          extra={<LogText logData={log?.restValueNotUpdated} {...logExtraProps} />}
        />
        <Details.Item
          label={_t("vip_vehicle")}
          value={format.yesOrNo(vipVehicle)}
          extra={<LogText logData={log?.vipVehicle} {...logExtraProps} />}
        />
      </Details>
      {startEditing && (
        <div className="text-right">
          <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
            {_t("edit")}
          </Button>
        </div>
      )}

    </>
  );
};

export default LeasingAdminDealActiveInfo;
