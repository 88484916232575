import { Button, Form, Result, Space } from "antd";
import { useState } from "react";
import _t from "../../lang/translate";
import UserFormFields from "../../screens/forms/user/userFormFields";
import { saveUser } from "../../services/userService";
import { OpenModalProps } from "../../types/modalTypes";
import { initUser, UserType } from "../../types/userTypes";
import assertFC from "../../utilities/assertFC";
import consts from "../../utilities/consts";
import { renderErrorMessage } from "../messages/errorMessage";

export default function AddUserModal<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [userForm] = Form.useForm<UserType>();
  const [loading, setLoading] = useState<boolean>(false);

  const { initialValues, parentId } = payload;

  const handleSubmit = async () => {
    const values = userForm.getFieldsValue(true);

    try {
      setLoading(true);
      const { data } = await saveUser(values);
      onSuccess(data as T);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  if (!parentId) {
    return <Result status="error" title={_t("msg.no_dealer_selected")} />;
  }

  return (
    <Form
      {...consts.formItemProps}
      form={userForm}
      requiredMark
      initialValues={{ ...initUser, name: initialValues || null, dealerId: parentId }}
      onFinish={handleSubmit}
    >
      <h2 className="modal-title">{_t("add", "user")}</h2>
      <UserFormFields isNew hideDealer />
      <div className="text-right pt-1">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>

          <Button htmlType="submit" type="primary" loading={loading}>
            {_t("create")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(AddUserModal);
