import React, { FC, useState } from "react";
import { DefaultEditor } from "react-simple-wysiwyg";

interface EditorProps {
  value?: string;
  onChange?: (event: React.FormEvent<HTMLElement>) => void;
}

const Editor: FC<EditorProps> = ({ value, onChange, ...rest }) => {
  const [active, setActive] = useState<boolean>(false);
  return (
    <div className="editor-wp">
      <DefaultEditor
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        className={`editor${active ? " active" : ""}`}
        value={value || ""}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

export default Editor;
