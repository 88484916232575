import { FC } from "react";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";

const DealerProspectId: FC = () => {
  const { dealer } = DealerStore.useContainer();
  const { prospectId } = dealer;

  return (
    <label className="pt-05 d-block">
      {_t("prospectId")}: {prospectId || "-"}
    </label>
  );
};

export default DealerProspectId;
