import { FC } from "react";
import { Result, Table } from "antd";
import { ColumnProps } from "antd/es/table";
import _t from "../../../lang/translate";
import { clipUsageUrl } from "../../../services/dealerService";
import { useApi } from "../../../services/useApi";

import { ClipLogType } from "../../../types/clipTypes";
import format from "../../../utilities/formatNumbers";
import { PaginationedData } from "../../../types/apiTypes";
import ServerLink from "../../../components/serverLink";

interface DealerBonusLogProps {
  dealerId: number;
}

const DealerClipsLog: FC<DealerBonusLogProps> = ({ dealerId }) => {
  const [{ data, isLoading, isError }] = useApi<PaginationedData<ClipLogType>>(clipUsageUrl(dealerId), { data: [] });

  if (isError) return <Result status="error" title={_t("msg.unknown_error")} />;

  const columns: ColumnProps<ClipLogType>[] = [
    {
      key: "amount",
      title: _t("clip_amount"),
      dataIndex: "amount",
      render: (text) => <strong>{text}</strong>,
    },
    {
      key: "type",
      title: _t("type"),
      dataIndex: "type",
    },
    {
      key: "createdAt",
      title: _t("date"),
      dataIndex: "createdAt",
      render: (text) => format.date(text),
    },
    {
      key: "description",
      title: _t("description"),
      dataIndex: "description",
      render: (text, item) => item.link ? (
        <ServerLink link={item.link} target="_blank">{text}</ServerLink>
      ) : text,
    },
  ];

  return <Table rowKey="id" loading={isLoading} columns={columns} dataSource={data.data || []} pagination={false} />;
};

export default DealerClipsLog;
