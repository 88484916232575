export function hasNumber(myString: string) {
  return /\d/.test(myString);
}

export function capitalize(s: string) {
  const firstLetter = s.charAt(0);
  return hasNumber(firstLetter) ? s : firstLetter.toUpperCase() + s.slice(1);
}

export const getRandomString = (length: number = 5) => (Math.random() + 1).toString(36).substring(length);

export const isValidVin = (vin: unknown): boolean => {
  if (typeof vin === 'string' && vin.length === 17) {
    const vinPattern = /^[a-zA-Z0-9]+$/
    return vinPattern.test(vin)
  }
  return false;
}

export const formatVin = (vin: unknown) => {
  return String(vin).toUpperCase()
}
