import http from "./httpService";
import getApiEndpoint from "./apiEndpoint";
import { SimpleCasesType } from "../types/miscTypes";
import { PaginationedData } from "../types/apiTypes";
import { LinkType } from "../types/appTypes";

const searchEndpoint = (q = "") => `${getApiEndpoint()}/search/?filter[q]=${encodeURIComponent(q)}`;
const jumpToEndpoint = (caseId: string | number) => `${getApiEndpoint()}/jumpToCase/${caseId}`;

export function doSearch(q: string) {
  return http.get<PaginationedData<SimpleCasesType>>(searchEndpoint(q));
}

export function jumpToCase(caseId: string | number) {
  return http.get<{ data: LinkType }>(jumpToEndpoint(caseId));
}
