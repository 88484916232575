import { FC, Fragment } from "react";
import { Checkbox, Col, Divider, Form, Input, Row, Select, Switch } from "antd";
import { Gutter } from "antd/lib/grid/row";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { ConfigFieldsType, ConfigFieldType } from "../../types/systemTypes";
import consts from "../../utilities/consts";
import InputNum from "./inputNum";
import InputPercentage from "./inputPercentage";
import InputPrice from "./inputPrice";

const { Option } = Select;

interface ConfigFieldsGeneratorProps {
  list?: ConfigFieldsType | null;
  nested?: string[];
  dividerBefore?: string[];
  rowGutter?: number | Partial<Record<Breakpoint, number>> | [Gutter, Gutter] | undefined;
  disabled?: boolean;
  darkTheme?: boolean;
  forceSwitch?: boolean;
}

const ConfigFieldsGenerator: FC<ConfigFieldsGeneratorProps> = ({
  list,
  nested,
  dividerBefore,
  rowGutter = consts.formGutter, // TODO remove value.
  disabled = false,
  darkTheme = false,
  forceSwitch = false,
}) => {
  const getConfigField = (item: ConfigFieldType, disabled: boolean) => {
    const props = {
      name: nested ? [...nested, item.id, "value"] : [item.id, "value"],
      noStyle: true,
    };
    switch (item.type) {
      case "boolean":
        return (
          <Form.Item {...props} valuePropName="checked">
            <Checkbox disabled={disabled} />
          </Form.Item>
        );
      case "decimal":
        return (
          <Form.Item {...props}>
            <InputPrice disabled={disabled} suffix={item?.unit} />
          </Form.Item>
        );
      case "integer":
        return (
          <Form.Item {...props}>
            <InputNum disabled={disabled} suffix={item?.unit} />
          </Form.Item>
        );
      case "string":
        return (
          <Form.Item {...props}>
            <Input disabled={disabled} suffix={item?.unit} />
          </Form.Item>
        );
      case "select":
        return (
          <Form.Item {...props}>
            <Select disabled={disabled} dropdownClassName={darkTheme ? "dropdown-dark" : ""}>
              {item.options?.map((opt) => (
                <Option value={opt.id} key={opt.id} label={opt.title}>
                  {opt.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      case "percent":
        return (
          <Form.Item {...props}>
            <InputPercentage disabled={disabled} />
          </Form.Item>
        );
      default:
        throw Error("New type added " + item.type);
    }
  };

  return (
    <>
      {list &&
        Object.keys(list).map((key) => {
          const item = list[key];
          return (
            <Fragment key={key}>
              {!item.hidden && dividerBefore && dividerBefore.includes(key) && <Divider className="mt-0 mb-1" />}
              <Form.Item
                {...consts.formItemProps}
                label={`${item.title}${item.unit ? ` (${item.unit})` : ""}`}
                hidden={!!item.hidden}
              >
                {forceSwitch ? (
                  <Row align="middle">
                    <Col flex="70px" className="text-right pr-05">
                      <Form.Item
                        name={nested ? [...nested, item.id, "forced"] : [item.id, "forced"]}
                        noStyle
                        valuePropName="checked"
                      >
                        <Switch disabled={disabled} size="small" />
                      </Form.Item>
                    </Col>
                    <Col flex="auto">
                      <Form.Item noStyle shouldUpdate={(prev, curr) => prev[item.id]?.forced !== curr[item.id]?.forced}>
                        {({ getFieldValue }) => getConfigField(item, !getFieldValue([item.id, "forced"]) || disabled)}
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  getConfigField(item, disabled)
                )}
              </Form.Item>
            </Fragment>
          );
        })}
    </>
  );
};

export default ConfigFieldsGenerator;
