import { Badge, Button, Drawer } from "antd";
import React, { FC, useState } from "react";
import Icon from "../../assets/icons/icon";
import LayoutStore from "../../contexts/layoutStore";
import UserStore from "../../contexts/userStore";
import _t from "../../lang/translate";
import consts from "../../utilities/consts";
import NotificationsDrawer from "./NotificationsDrawer";

const Notifications: FC = () => {
  const { notificationsCount } = UserStore.useContainer();
  const { screen } = LayoutStore.useContainer();
  const [visibility, setVisibility] = useState<boolean>(false);

  return (
    <>
      <div className="d-flex align-items-center">
        <Button
          shape="circle"
          size="large"
          type="text"
          icon={<Icon name="notifications-outline" />}
          onClick={() => setVisibility(true)}
        />
        <Badge count={notificationsCount.total} className="header-badge ml--1">
          &nbsp;
        </Badge>
      </div>
      <Drawer
        visible={visibility}
        title={_t("notifications")}
        className="notifications-drawer"
        destroyOnClose
        closable={false}
        onClose={() => setVisibility(false)}
        width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
      >
        <NotificationsDrawer setVisibility={setVisibility} />
      </Drawer>
    </>
  );
};

export default Notifications;
