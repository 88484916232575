import { Button, Collapse } from "antd";
import { FC } from "react";
import Icon from "../../../assets/icons/icon";
import CustomCollapse from "../../../components/customCollapse";
import CustomCollapseHeader from "../../../components/customCollapseHeader";
import DividerLine from "../../../components/lineSplit";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { openFileLink } from "../../../services/mediaService";
import LeasingInsuranceCompanyView from "./leasingInsuranceCompanyView";

const LeasingContractsInfo: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { contractGenerated } = leasingCase.flags;

  if (!contractGenerated) {
    return null;
  }

  const { insuranceCompany, documents } = leasingCase.contract || {};

  return (
    <CustomCollapse>
      <Collapse.Panel
        key="dealer_merchant_relation"
        header={<CustomCollapseHeader>{_t("info", "documents", " & ")}</CustomCollapseHeader>}
      >
        <LeasingInsuranceCompanyView insuranceCompany={insuranceCompany} />
        <DividerLine className="mt-1 mb-05" />
        {documents?.map((doc) => (
          <Button
            key={doc.id}
            type="link"
            className="pl-0 pr-0 text-left"
            block
            icon={<Icon name="document-outline" />}
            onClick={() => openFileLink(doc.url)}
          >
            {doc.name}
          </Button>
        ))}
      </Collapse.Panel>
    </CustomCollapse>
  );
};

export default LeasingContractsInfo;
