import { FC, useEffect } from "react";
import format from "../../../../../utilities/formatNumbers";
import { isNum } from "../../../../../utilities/typeGuard";
import { regulateAdvertSalesPrice } from "../../calc/tradePriceSubCalculations";

interface TaxRecalculatedPriceCellProps {
  year?: number | null;
  price?: number | null;
  vehicleYear?: number | null;
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const TaxRecalculatedPriceCell: FC<TaxRecalculatedPriceCellProps> = ({ year, price, vehicleYear, value, onChange }) => {
  useEffect(() => {
    onChange && onChange(regulateAdvertSalesPrice(year, price, vehicleYear));
  }, [year, price, vehicleYear]);

  return <span>{isNum(value) ? format.number(value) : "-"}</span>;
};

export default TaxRecalculatedPriceCell;
