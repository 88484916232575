import { getPath } from "../routes/appRoutes";
import { convertToAppQuery } from "../services/urlQueryService";
import { LinkType, LocationStateType } from "../types/appTypes";

export function openLink(
  link: LinkType,
  push?: (path: string, state?: LocationStateType) => void,
  target?: "_blank" | "_self"
) {
  if (typeof link.external?.trim() === "string") {
    let title = link.external;
    if (title.startsWith("tel:")) title = title.replace("tel:", "");
    if (title.startsWith("mailto:")) title = title.replace("mailto:", "");
    window.open(link.external, target);
  } else if (link.internalModule) {
    let result = getPath(link.internalModule);
    // pass link.params[0]
    result = result && link.search ? `${result}?${convertToAppQuery(link.search)}` : result;
    /*      result =
        result && typeof link.hash === "string" ? result + link.hash : result; */
    result && push && push(result, link.state as LocationStateType);
  }
}

export function isValidLink(link: unknown): link is string {
  if (typeof link !== 'string') { return false };
  try {
    new URL(link);
    return true;
  } catch (err) {
    return false;
  }
}
