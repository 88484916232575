import React, { FC, useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import _t from "../../../../../lang/translate";
import Draggable from "react-draggable";
import TaxAdvancedCalcForm from "./taxAdvancedCalcForm";
import { TradePriceCalcInputType, TradePriceCalcOutputType, } from "../../calc/tradePriceCalculatorTypes";
import { Equipment } from "../../../../../types/appTypes";
import TaxCaseStore from "../../../../../contexts/taxCaseStore";
import { OfferTax } from "../../../../../types/taxTypes";
import { TaxAdVehiclesType } from "../../../../../types/taxAdvancedCalcTypes";
import format from "../../../../../utilities/formatNumbers";

interface TradePriceAdvancedCalculatorProps {
  onChange?: (values: TaxAdVehiclesType) => void;
  onCalculated?: (values: TradePriceCalcOutputType) => void;
  calculationFormValues: OfferTax;
  value?: TaxAdVehiclesType;
  initCalcInput: Partial<TradePriceCalcInputType>;
}

const TradePriceAdvancedCalculator: FC<TradePriceAdvancedCalculatorProps> = ({
  onCalculated,
  onChange,
  value,
  calculationFormValues,
  initCalcInput,
}) => {
  const [dragging, setDragging] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const { taxCase } = TaxCaseStore.useContainer();

  const vehicleEquipments: Equipment[] =
    calculationFormValues?.newPrice?.equipmentCalculator?.equipments || [];

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  const stopDragging = () => dragging && setDragging(false);
  const startDragging = () => !dragging && setDragging(true);

  const handleApply = (
    v: TaxAdVehiclesType,
    output: TradePriceCalcOutputType
  ) => {
    onCalculated && onCalculated(output);
    onChange && onChange(v);
    closeModal();
  };

  const activeAdCount = (value?.vehicles || []).filter(
    (ad: any) => ad.enabled
  ).length;

  const finalTradePriceTotal =
    calculationFormValues.tradePrice?.tradePriceOutput?.finalTradePriceTotal;

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>{format.price(finalTradePriceTotal)}</Col>
        <Col>
          <Button tabIndex={-1} onClick={openModal}>
            ({activeAdCount}) {_t("ads")}
          </Button>
        </Col>
      </Row>
      <Modal
        width={1161}
        title={null}
        visible={visible}
        destroyOnClose
        closable
        modalRender={(modal) => (
          <Draggable disabled={dragging}>{modal}</Draggable>
        )}
        onCancel={closeModal}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        <TaxAdvancedCalcForm
          onApply={handleApply}
          initValue={value || null}
          initCalcInput={initCalcInput}
          vehicleEquipments={vehicleEquipments}
          taxCase={taxCase}
          stopDragging={stopDragging}
          startDragging={startDragging}
        />
      </Modal>
    </>
  );
};

export default TradePriceAdvancedCalculator;
