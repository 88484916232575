import React, { FC } from "react";
import { Col, Form, Row } from "antd";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";

const TaxLicensePlateFee: FC = () => {
  return (
    <Row className="flex-nowrap">
      <Col flex="auto">
        <Form.Item label={_t("plade_nr_fee")} name="licensePlateFee">
          <InputNum min={0} className="bg-white no-border" suffix="Kr." />
        </Form.Item>
      </Col>
      <Col flex="49px" />
    </Row>
  );
};

export default TaxLicensePlateFee;
