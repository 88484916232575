import http from "./httpService";
import { ChangeClientType, IdType, LinkType, StatusUpdateType, Vehicle, } from "../types/appTypes";
import { getApiQuery } from "./urlQueryService";
import { mediaApiEndpoint } from "./mediaService";
import getApiEndpoint from "./apiEndpoint";
import { PaginationedData } from "../types/apiTypes";
import { ToLeasingFormType } from "../types/leasingTypes";
import {
  MakePurchaseType,
  PurchaseType,
  RejectCaseType,
  TaxDocCalculationsType,
  TaxDocCase,
  TaxDocCaseCreation,
  TaxDocListItem,
  TaxDocSettingsType,
  UpdateDocumentationNoteType,
} from "../types/taxDocTypes";

const apiEndpoint = `${getApiEndpoint()}/taxDocCase`;

export function taxDocCaseUrl(id: string | number) {
  return `${apiEndpoint}/${id}`;
}

export function taxDocFileUrl(id?: IdType) {
  return id ? `${taxDocCaseUrl(id)}/file` : mediaApiEndpoint;
}

export function taxDocCasesURL(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function getTaxDocCases(query?: string) {
  return http.get<PaginationedData<TaxDocListItem>>(
    `${apiEndpoint}?${getApiQuery(query)}`
  );
}

export function getTaxDocCase(id: string | number) {
  return http.get(taxDocCaseUrl(id));
}

export function deleteTaxDocCase(id: IdType) {
  return http.delete(taxDocCaseUrl(id));
}

export function createTaxDocCase(data: TaxDocCaseCreation) {
  return http.post(apiEndpoint, data);
}

export function saveTaxDocCaseStatus(id: IdType, data: any) {
  return http.put(`${taxDocCaseUrl(id)}/status`, data);
}

export function taxDocActivitiesUrl(id: IdType) {
  return `${taxDocCaseUrl(id)}/activities`;
}

export function updateTaxDocActivity(id: IdType, data: any) {
  return http.put(`${taxDocActivitiesUrl(id)}/${data?.id}`, data);
}

export function saveTaxDocActivity(id: IdType, data: any) {
  return http.post(taxDocActivitiesUrl(id), data);
}

export function deleteTaxDocActivity(id: IdType, deletedId: string) {
  return http.delete(`${taxDocActivitiesUrl(id)}/${deletedId}`);
}

export function calculationArchiveEndpoint(id: IdType) {
  return `${taxDocCaseUrl(id)}/calculationArchive`;
}

export function documentationFileEndpoint(id?: IdType) {
  return id ? `${taxDocCaseUrl(id)}/documentationFile` : mediaApiEndpoint;
}

export function adsDataDocumentationFileEndpoint(id?: IdType) {
  return id
    ? `${taxDocCaseUrl(id)}/adsDataDocumentationFile`
    : mediaApiEndpoint;
}

export function adsDocumentationFileEndpoint(id?: IdType) {
  return id ? `${taxDocCaseUrl(id)}/adsDocumentationFile` : mediaApiEndpoint;
}

export function vehicleListDocumentationFileEndpoint(id?: IdType) {
  return id
    ? `${taxDocCaseUrl(id)}/vehicleListDocumentationFile`
    : mediaApiEndpoint;
}

export function newPriceDocumentationFileEndpoint(id?: IdType) {
  return id
    ? `${taxDocCaseUrl(id)}/newPriceDocumentationFile`
    : mediaApiEndpoint;
}

export function equipmentsDocumentationFileEndpoint(id?: IdType) {
  return id
    ? `${taxDocCaseUrl(id)}/equipmentsDocumentationFile`
    : mediaApiEndpoint;
}

export function downloadAllFiles(id: IdType) {
  return http.get(`${taxDocCaseUrl(id)}/downloadAllFiles`);
}

export function updateTaxDocCaseClient(id: IdType, data: ChangeClientType) {
  return http.put(`${taxDocCaseUrl(id)}/client`, data);
}

export function updateTaxDocVehicle(id: IdType, data: Vehicle) {
  return http.put(`${taxDocCaseUrl(id)}/vehicle`, data);
}

export function updateCalculations(id: IdType, data: TaxDocCalculationsType) {
  return http.put(`${taxDocCaseUrl(id)}/calculations`, data);
}

export function updateSettings(id: IdType, data: TaxDocSettingsType) {
  return http.put(`${taxDocCaseUrl(id)}/settings`, data);
}

export function updateDocumentationNote(
  id: IdType,
  data: UpdateDocumentationNoteType
) {
  return http.put(`${taxDocCaseUrl(id)}/documentationNote`, data);
}

export function fetchEquipmentsDocumentationFile(id: IdType) {
  return http.post(`${taxDocCaseUrl(id)}/fetchEquipmentsDocumentationFile`);
}

export function sendEvaluation(id: IdType) {
  return http.post(`${taxDocCaseUrl(id)}/sendEvaluation`);
}

export function publishEvaluation(id: IdType, data: StatusUpdateType) {
  return http.put(`${taxDocCaseUrl(id)}/published`, data);
}

export function createLeasingofTaxDocCase(id: IdType, data: ToLeasingFormType) {
  return http.post(`${taxDocCaseUrl(id)}/toLeasingCase`, data);
}

export function purchaseByCard(id: IdType, data: PurchaseType) {
  return http.post<{ data: LinkType }>(
    `${taxDocCaseUrl(id)}/purchaseByCard`,
    data
  );
}

export function purchaseBySavedCard(id: IdType, data: PurchaseType) {
  return http.post<TaxDocCase>(
    `${taxDocCaseUrl(id)}/purchaseBySavedCard`,
    data
  );
}

export function makePurchase(id: IdType, data: MakePurchaseType) {
  return http.post(`${taxDocCaseUrl(id)}/makePurchase`, data);
}

export function rejectTaxDocCase(id: IdType, data: RejectCaseType) {
  return http.post(`${taxDocCaseUrl(id)}/close`, data);
}

export function previewDocumentation(id: IdType) {
  return http.get(`${taxDocCaseUrl(id)}/previewDocumentation`);
}

export function checkMotorAgencyStatus(id: IdType) {
  return http.post(`${taxDocCaseUrl(id)}/checkMotorAgencyStatus`);
}
