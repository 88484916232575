import { ArgsProps } from "antd/lib/notification";
import { LinkType } from "./appTypes";

export interface NotificationCountType {
  notifications: number;
  posts: number;
  total: number;
}

export const initNotificationCount = {
  notifications: 0,
  posts: 0,
  total: 0,
};

export interface NotificationType {
  id: string;
  message: string;
  description: string | null;
  date: string;
  isPriority: boolean;
  isRead: boolean;
  link: LinkType;
  level: IconType;
}

export const notifiTabKeys = {
  all: "all",
  notification: "notification",
  post: "post",
} as const;
type TabsKyes = keyof typeof notifiTabKeys;
export type NotifiTabKeys = typeof notifiTabKeys[TabsKyes];

const notificationLevels = [
  "success",
  "info",
  "error",
  "warning",
  "default",
] as const;

export declare type IconType = typeof notificationLevels[number];
export type NotifiFuncs = { [x in IconType]: (args: ArgsProps) => void };

export function isNotificationLevel(level: string): level is IconType {
  return notificationLevels.includes(level as IconType);
}
