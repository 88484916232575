import React, { FC, useState } from "react";
import { Button, message } from "antd";
import Icon from "../../../assets/icons/icon";
import ErrorMessage from "../../../components/messages/errorMessage";
import ImportCaseStore from "../../../contexts/importCaseStore";
import _t from "../../../lang/translate";
import { downloadPaymentAdvice } from "../../../services/importService";

const PaymentAdviceDownloadBtn: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>();

  const handleDownload = async () => {
    try {
      setLoading(true);
      const { data } = await downloadPaymentAdvice(importCase.id);
      if (data.url) window.open(data.url, "_blank");
    } catch ({ response }) {
      const { message: msg } = response?.data || {};
      message.error(<ErrorMessage message={msg} />);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button onClick={handleDownload} loading={loading} type="primary" ghost disabled={!importCase.flags.requested}>
      <Icon name="download-outline" size="large" />
      {_t("payment_advice")}
    </Button>
  );
};

export default PaymentAdviceDownloadBtn;
