import { SorterResult } from "antd/lib/table/interface";

export const getSortValue = <T extends {}>(
  sorter: SorterResult<T> | SorterResult<T>[]
) => {
  let sortValues: string[] = [];
  if (Array.isArray(sorter)) {
    sortValues = sorter.map((col) =>
      col.column ? sortDirection(col.order) + col.field : ""
    );
  } else if (typeof sorter === "object" && sorter.column !== undefined)
    sortValues.push(sortDirection(sorter.order) + sorter.field);
  else sortValues = [];
  return sortValues.length > 0 ? `${sortValues.join("|")}` : "";
};

export const sortDirection = (order: "descend" | "ascend" | null | undefined) =>
  order === "descend" ? "-" : "";
