import { Button, Form, Space } from "antd";
import { useState } from "react";
import _t from "../../lang/translate";
import MerchantFormFields from "../../screens/forms/merchant/merchantFormFields";
import { initMerchant, saveMerchant } from "../../services/merchantService";
import { MerchantType } from "../../types/merchantTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import consts from "../../utilities/consts";
import { renderErrorMessage } from "../messages/errorMessage";

export default function AddMerchantModal<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [merchantForm] = Form.useForm<T>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    const values = merchantForm.getFieldsValue(true);

    try {
      setLoading(true);
      const { data } = await saveMerchant(values as MerchantType);
      onSuccess({ id: data.id, title: data.name } as T);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={merchantForm}
      {...consts.formItemProps}
      requiredMark={true}
      initialValues={{ ...initMerchant, name: payload.initialValues || null }}
      onFinish={handleSubmit}
    >
      <h2 className="modal-title">{_t("add", "merchant")}</h2>
      <MerchantFormFields />
      <div className="text-right pt-1">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>

          <Button htmlType="submit" type="primary" loading={loading}>
            {_t("save")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(AddMerchantModal);
