import { ColProps } from "antd/lib/col";
import { useEffect, useMemo, useState } from "react";
import { createContainer } from "unstated-next";
import consts from "../utilities/consts";

type windowSize = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

interface Screen {
  width: number;
  height: number;
  size: windowSize | undefined;
  isMobile: boolean;
  isTablet: boolean;
  greaterThan: GreaterThan | undefined;
}

interface GreaterThan {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
}

interface LayoutStoreProps {
  sideMenuVisiblity: boolean;
  adminColProps: ColProps;
  setSideMenuVisiblity: React.Dispatch<React.SetStateAction<boolean>>;
  screen: Screen;
}

const useLayoutStore = (): LayoutStoreProps => {
  const [sideMenuVisiblity, setSideMenuVisiblity] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [greaterThan, setGreaterThan] = useState<GreaterThan | undefined>(getGreaterThanValue(window.innerWidth));
  const [size, setSize] = useState<windowSize | undefined>(getBreakPoint(window.innerWidth));

  const isMobile = useMemo<boolean>(() => {
    return width < consts.mobileSize;
  }, [width]);

  const isTablet = useMemo<boolean>(() => {
    return width < consts.tabletSize;
  }, [width]);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    setSize(getBreakPoint(window.innerWidth));
    setGreaterThan(getGreaterThanValue(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const adminColProps = {
    flex: greaterThan?.xl ? "288px" : "auto",
    order: 1,
    xl: { order: 3 },
  };

  return {
    sideMenuVisiblity,
    setSideMenuVisiblity,
    adminColProps,
    screen: { size, width, height, isMobile, isTablet, greaterThan },
  };
};

const LayoutStore = createContainer(useLayoutStore);

export default LayoutStore;

/////////////////////////////

function getGreaterThanValue(windowWidth: number | undefined): GreaterThan | undefined {
  if (typeof windowWidth === "number") {
    return {
      xs: windowWidth >= 480,
      sm: windowWidth >= 576,
      md: windowWidth >= 768,
      lg: windowWidth >= 992,
      xl: windowWidth >= 1200,
      xxl: windowWidth >= 1600,
    };
  } else {
    return undefined;
  }
}

function getBreakPoint(windowWidth: number | undefined): windowSize | undefined {
  if (typeof windowWidth === "number") {
    if (windowWidth < 480) {
      return "xs";
    } else if (windowWidth < 576) {
      return "sm";
    } else if (windowWidth < 768) {
      return "md";
    } else if (windowWidth < 992) {
      return "lg";
    } else if (windowWidth < 1200) {
      return "xl";
    } else {
      return "xxl";
    }
  } else {
    return undefined;
  }
}
