import { FC, useState } from "react";
import CollapseCard from "../../../components/collapseCard";
import UserPageStore from "../../../contexts/userPageStore";
import _t from "../../../lang/translate";
import UserPageInfoForm from "./userPageInfoForm";
import UserPageInfoView from "./userPageInfoView";

const UserInfoCard: FC = () => {
  const { user, loading, isNew } = UserPageStore.useContainer();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <CollapseCard title={user.name || _t("main_info")} loading={loading}>
      {isEditing || isNew ? (
        <UserPageInfoForm stopEditing={stopEditing} />
      ) : (
        <UserPageInfoView startEditing={startEditing} />
      )}
    </CollapseCard>
  );
};

export default UserInfoCard;
