import React, { FC } from "react";
import { Button, Empty } from "antd";
import _t from "../lang/translate";

interface LoadMoreProps {
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  message?: string;
  canLoadMore?: boolean;
}

const LoadMore: FC<LoadMoreProps> = ({ onClick, canLoadMore, message = _t("no_more_notifications") }) => {
  if (!canLoadMore) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={message} />;

  return (
    <div className="text-center p-1 mb-2">
      <Button onClick={onClick}>{_t("read", "more")}</Button>
    </div>
  );
};

export default LoadMore;
