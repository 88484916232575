import React, { FC, useState } from "react";
import { Button, Col, message, Row, Space, Spin, Typography } from "antd";
import { ChecklistEntryType, ChecklistEntryViewType } from "../../types/checklistTypes";
import CheckBoxRound from "../form/checkBoxRound";
import Icon from "../../assets/icons/icon";
import _t from "../../lang/translate";
import Expandable from "../expandable";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ChecklistActions from "./checklistActions";
import ChecklistForm from "./checklistForm";
import LogText from "../logText";
import {
  deleteChecklistEntry,
  updateChecklistEntry,
  updateChecklistEntryCompletion,
  updateChecklistEntryHidden,
} from "../../services/checklistService";
import { isAxiosError } from "../../utilities/typeGuard";

interface ChecklistItemProps {
  item: ChecklistEntryViewType;
}

const ChecklistAdminItem: FC<ChecklistItemProps> = ({ item }) => {
  const { completed, groupId, id, ignored, isPublic, description, title, updateLog } = item;
  const {
    editing,
    setEditing,
    id: caseId,
    expanded,
    setExpanded,
    dispatchGroups,
    isAdmin,
    sorting,
  } = ChecklistAdminStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const isEditingEntry = editing?.id === id && editing.groupId === groupId;
  const isExpanded = expanded?.id === id && expanded.groupId === groupId;

  const toggleExpanded = () => {
    const nextState = isExpanded ? null : { groupId, id };
    setExpanded(nextState);
  };

  const setCompleted = async (e: CheckboxChangeEvent) => {
    onStatusChange({ ...item, completed: e.target.checked }, "completed");
  };

  const onDelete = async () => {
    try {
      setLoading(true);
      await deleteChecklistEntry(item.id);
      message.success(_t("deleted"));
      setLoading(false);
      dispatchGroups({ type: "delete_entry", payload: item });
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  const onStatusChange = async (item: ChecklistEntryViewType, status: "ignored" | "completed") => {
    try {
      setLoading(true);
      const { data } =
        status === "ignored" ? await updateChecklistEntryHidden(item) : await updateChecklistEntryCompletion(item);
      setLoading(false);
      dispatchGroups({ type: "update_entry", payload: data });
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  const handleEntryUpdate = async (entry: ChecklistEntryType) => {
    try {
      setLoading(true);
      const { data: payload } = await updateChecklistEntry(entry);
      dispatchGroups({ type: "update_entry", payload });
      message.success(_t("saved"));
      setEditing(null);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={12} align="middle" className="flex-wrap">
        <Col flex="auto" style={{ minWidth: 0 }}>
          {!caseId ? (
            <Typography.Paragraph
              ellipsis={isExpanded ? false : { rows: 2, expandable: true, symbol: " ", tooltip: title }}
              className={`m-0 ${ignored ? "text-strike" : ""}`}
            >
              {title}
            </Typography.Paragraph>
          ) : (
            <CheckBoxRound onChange={setCompleted} disabled={!!editing || sorting} checked={completed}>
              <Typography.Paragraph
                ellipsis={isExpanded ? false : { rows: 2, expandable: true, symbol: " ", tooltip: title }}
                className={`m-0 ${ignored ? "text-strike" : ""}`}
              >
                {title}
              </Typography.Paragraph>
            </CheckBoxRound>
          )}
          {(updateLog || isPublic || ignored) && (
            <div>
              <small className={`${caseId ? "ml-3" : ""} d-block"`}>
                <Space split=" • ">
                  {updateLog && <LogText logData={updateLog} className="p-0" />}
                  {isPublic && _t("public")}
                  {ignored && _t("hidden")}
                </Space>
              </small>
            </div>
          )}
        </Col>
        <Col>
          {sorting ? (
            <Icon name="reorder-three-outline" size="huge" className="drag-handle" />
          ) : (
            <Button
              type="text"
              disabled={!!editing}
              onClick={toggleExpanded}
              shape="circle"
              size="small"
              className={`expand-chevron ${isExpanded ? "rotate-180" : ""}`}
              icon={<Icon name="chevron-down-outline" />}
            />
          )}
        </Col>
      </Row>
      <Expandable expanded={isExpanded && !isEditingEntry}>
        {description && <div className="bg-muted mt-1 mb-05 p-1 border-rounded">{description}</div>}
        {isAdmin && (
          <ChecklistActions entry={item} onDelete={onDelete} onHiddenChange={(e) => onStatusChange(e, "ignored")} />
        )}
      </Expandable>
      {isAdmin && (
        <Expandable expanded={isEditingEntry}>
          <ChecklistForm initData={item} onSubmit={handleEntryUpdate} loading={loading} />
        </Expandable>
      )}
    </Spin>
  );
};

export default ChecklistAdminItem;
