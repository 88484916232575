import React, { FC } from "react";
import _t from "../lang/translate";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import ResellerTaxCaseStore from "../contexts/resellerTaxCaseStore";
import SwitchInput from "./form/switchInput";
import { Col, message, Row } from "antd";
import { acceptResellerTaxOffer, paidTaxOffer, uploadedTaxOffer } from "../services/resellerTaxService";
import LogText from "./logText";
import { isAxiosError } from "../utilities/typeGuard";

interface ResellerTaxFlagsSwitchesProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResellerTaxFlagsSwitches: FC<ResellerTaxFlagsSwitchesProps> = ({ setLoading }) => {
  const { resellerTaxCase, setResellerTaxCase } = ResellerTaxCaseStore.useContainer();
  const handleOfferAccept: SwitchChangeEventHandler = async (v) => {
    try {
      setLoading(true);
      const { data } = await acceptResellerTaxOffer(resellerTaxCase.id, { status: v });
      message.success(_t(v ? "accepted" : "not_accepted"));
      setResellerTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentsUploaded: SwitchChangeEventHandler = async (v) => {
    try {
      setLoading(true);
      const { data } = await uploadedTaxOffer(resellerTaxCase.id, { status: v });
      message.success(_t(v ? "documents_uploaded" : "documents_not_uploaded"));
      setResellerTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleInvoicePaid: SwitchChangeEventHandler = async (v) => {
    setLoading(true);
    try {
      const { data } = await paidTaxOffer(resellerTaxCase.id, { status: v });
      message.success(_t(v ? "invoice_paid" : "invoice_not_paid"));
      setResellerTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row gutter={[8, 8]} className="mb-1">
        <Col>
          <SwitchInput
            size="small"
            id="case-accepted-switch"
            label={_t("case", "accepted")}
            checked={resellerTaxCase.flags.accepted}
            disabled={!resellerTaxCase.offer?.newPrice}
            onChange={handleOfferAccept}
          />
        </Col>
        {resellerTaxCase?.log?.accepted && (
          <Col>
            <LogText mdash inline small className={"p-0"} logData={resellerTaxCase.log.accepted} />
          </Col>
        )}
      </Row>
      <Row gutter={[8, 8]} className="mb-1">
        <Col>
          <SwitchInput
            checked={resellerTaxCase.flags.uploaded}
            id="documents-received-switch"
            onChange={handleDocumentsUploaded}
            size="small"
            label={_t("documents", "received")}
          />
        </Col>
        {resellerTaxCase?.log?.uploaded && (
          <Col>
            <LogText mdash inline small className={"p-0"} logData={resellerTaxCase.log.uploaded} />
          </Col>
        )}
      </Row>
      <Row gutter={[8, 8]} className="mb-1">
        <Col>
          <SwitchInput
            checked={resellerTaxCase.flags.paid}
            id="payment-received-switch"
            onChange={handleInvoicePaid}
            size="small"
            label={_t("payment", "received")}
          />
        </Col>
        {resellerTaxCase?.log?.paid && (
          <Col>
            <LogText mdash inline small className={"p-0"} logData={resellerTaxCase.log.paid} />
          </Col>
        )}
      </Row>
    </>
  );
};

export default ResellerTaxFlagsSwitches;
