import { FC } from "react";
import { Form, Switch } from "antd";
import InputPrice from "../../../components/form/inputPrice";
import _t from "../../../lang/translate";
import LeasingStore from "../../../contexts/leasingStore";

const LeasingDealDmf: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { readonly, inputLocked } = leasingCase.flags;
  const disabled = readonly || inputLocked;

  return (
    <>
      <Form.Item name={["input", "provisionIntern"]} label={_t("extra_provision")}>
        <InputPrice min={0} disabled={disabled} />
      </Form.Item>
      {leasingCase.partnerCase ? (
        <Form.Item name={["input", "optimizedTaxPrice"]} label={_t("final_registration_fee")}>
          <InputPrice min={0} disabled={disabled} />
        </Form.Item>
      ) : (
        <Form.Item name={["input", "isVipDeal"]} valuePropName="checked" label={_t("vip_deal")}>
          <Switch size="small" disabled={disabled} />
        </Form.Item>
      )}

      <Form.Item name={["input", "isUnlimitedValues"]} valuePropName="checked" label={_t("remove_limits")}>
        <Switch size="small" disabled={disabled} />
      </Form.Item>

      <Form.Item
        name={["input", "isPeriodTaxExtraMonth"]}
        valuePropName="checked"
        label={_t("add_extra_month_leasing")}
      >
        <Switch size="small" disabled={disabled} />
      </Form.Item>
    </>
  );
};

export default LeasingDealDmf;
