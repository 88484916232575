import React, { FC } from "react";
import _t from "../lang/translate";
import ResellerTaxCaseStore from "../contexts/resellerTaxCaseStore";
import { Col, message, Row } from "antd";
import { isAxiosError } from "../utilities/typeGuard";
import { ResellerTaxCase } from "../types/resellerTaxTypes";
import SwitchInput from "./form/switchInput";
import LogText from "./logText";
import { toggleChecklistFlag } from "../services/resellerTaxService";

interface ResellerTaxChecklistSwitchesProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResellerTaxChecklistSwitches: FC<ResellerTaxChecklistSwitchesProps> = ({ setLoading }) => {
  const { resellerTaxCase, setResellerTaxCase } = ResellerTaxCaseStore.useContainer();

  const checklistKeys = Object.keys(resellerTaxCase.internalChecklist) as Array<keyof ResellerTaxCase["internalChecklist"]>;

  async function handleToggle(key: keyof ResellerTaxCase["internalChecklist"], newStatus: boolean) {
    try {
      setLoading(true);
      const { data } = await toggleChecklistFlag(resellerTaxCase.id, { key, status: newStatus });
      message.success(_t("updated"));
      setResellerTaxCase(data);
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {checklistKeys.map((key) => {
        const flag = resellerTaxCase.internalChecklist?.[key];
        const translate = `flag_${key}` as const;

        let disabled = false;
        if (key === "optimizationRequested" || key === "optimizedTaxCase") {
          disabled = true;
        }

        return (
          <Row gutter={[8, 8]} className="mb-1" key={key}>
            <Col>
              <SwitchInput
                size="small"
                id={`${key}-switch`}
                label={_t(translate)}
                checked={!!flag?.status}
                disabled={disabled}
                onChange={() => handleToggle(key, !flag?.status)}
              />
            </Col>
            {flag?.status && (
              <Col>
                <LogText mdash inline small className={"p-0"} logData={flag} />
              </Col>
            )}
          </Row>
        )
      })}
    </>
  );
};

export default ResellerTaxChecklistSwitches;
