import React from "react";
import { Result, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import _t from "../../../../../lang/translate";
import { getPath } from "../../../../../routes/appRoutes";
import format from "../../../../../utilities/formatNumbers";
import { date } from "../../../../../utilities/date";
import { SimilarVehicle, TaxEstimationDisplayOutput, TaxEstimationRobotData } from "../../../../../types/taxEstimationTypes";
import { TaxCase } from "../../../../../types/taxTypes";
import { getRandomString } from "../../../../../utilities/stringExtraFunctions";

type Props = {
  taxEstimationResult: TaxEstimationDisplayOutput | null;
  taxEstimationRobotData: TaxEstimationRobotData | undefined | null;
  taxCase: TaxCase;
}

const empty = null
// TODO --> Handle null-values better inside table-row-data

const SimilarVehiclesTable = ({ taxEstimationRobotData, taxCase, taxEstimationResult }: Props) => {

  if (taxEstimationRobotData === null ||
    taxEstimationRobotData === undefined ||
    taxEstimationResult === null
  ) {
    return <Result status="error" title={_t("msg.get_tax_estimation_first")} />
  }
  const getTableData = (): TableData[] => {
    const robotTableData: TableData = robotTableRowData(
      taxEstimationResult, taxCase, taxEstimationRobotData);
    const taxCaseTableData: TableData = ourVehicleTableRowData(taxCase)
    const similarVehiclesTableData: TableData[] = taxEstimationRobotData.similarVehicles
      .map(similarVehicle => {
        return similarVehiclesTableRowData(similarVehicle);
      })
    return [robotTableData, taxCaseTableData, ...similarVehiclesTableData];
  }

  const tableRows: TableData[] = getTableData();
  const tableColumns: ColumnsType<TableData> = getTableColumns();

  return (
    <Table<TableData>
      className="border-radius no-white-space similar-vehicles-table"
      bordered
      tableLayout="auto"
      columns={tableColumns}
      dataSource={tableRows}
      pagination={false}
      scroll={{ x: 3650, y: 600 }}
      onRow={(record, index) => {
        let className = "";
        if (record.isRobot) className = "highlight-tablerow"
        else if (record.isOurVehicle) className = "highlight-tablerow secondary";
        return {
          className: className,
        }
      }}
      size={"small"}
    />
  );
};

export default SimilarVehiclesTable;

function robotTableRowData(
  taxEstimationResult: TaxEstimationDisplayOutput,
  taxCase: TaxCase,
  taxEstimationRobotData: TaxEstimationRobotData
): TableData {
  const pricingData = robotPricingData(taxEstimationResult, taxEstimationRobotData)
  const commonData = commonTableRowData(taxCase);
  return {
    ...pricingData,
    ...commonData
  }
}

function robotPricingData(
  taxEstimationResult: TaxEstimationDisplayOutput,
  taxEstimationRobotData: TaxEstimationRobotData
): DifferentlySourcedPricingData {
  return {
    isOurVehicle: false,
    isRobot: true,
    newPrice: taxEstimationResult.newPrice.totalSalesPrice,
    newPriceWithoutEquipment: null,
    equipmentPrice: null,
    tradePrice: taxEstimationResult.tradePrice.tradePrice,
    registrationFee: taxEstimationResult.registrationFee.taxAfterAllDeductions,
    inferredEquipmentPrice: taxEstimationRobotData.resolvedValues.equipmentPrice,
    inferredNewPriceWithoutEquipment: taxEstimationRobotData.resolvedValues.newPrice
  }
}

function ourVehicleTableRowData(
  taxCase: TaxCase,
): TableData {
  const pricingData = ourVehiclePricingData(taxCase)
  const commonData = commonTableRowData(taxCase);
  return {
    ...pricingData,
    ...commonData
  }
}

function ourVehiclePricingData(
  taxCase: TaxCase,
): DifferentlySourcedPricingData {
  return {
    isOurVehicle: true,
    isRobot: false,
    newPrice: taxCase.calculation?.newPrice?.newPrice ?? null,
    newPriceWithoutEquipment: taxCase.calculation?.newPrice?.newPriceWithoutEquipments ?? null,
    equipmentPrice: taxCase.calculation?.newPrice?.equipmentsPrice ?? null,
    tradePrice: taxCase.calculation?.tradePrice?.tradePrice ?? null,
    registrationFee: taxCase.calculation?.registrationFee?.registrationFee ?? null,
    inferredEquipmentPrice: null,
    inferredNewPriceWithoutEquipment: null
  }
}

function similarVehiclesTableRowData(
  similarVehicle: SimilarVehicle,
): TableData {

  const pricingData = similarVehiclesPricingData(similarVehicle)

  const commonData = commonTableRowData(similarVehicle.source);

  return {
    ...pricingData,
    ...commonData
  }
}


function similarVehiclesPricingData(
  vehicle: SimilarVehicle,
): DifferentlySourcedPricingData {
  return {
    isOurVehicle: false,
    isRobot: false,
    newPrice: vehicle.source.calculation?.newPrice?.newPrice ?? null,
    newPriceWithoutEquipment: vehicle.source.calculation?.newPrice?.newPriceWithoutEquipments ?? null,
    equipmentPrice: vehicle.source.calculation?.newPrice?.equipmentsPrice ?? null,
    tradePrice: vehicle.source.calculation?.tradePrice?.tradePrice ?? null,
    registrationFee: vehicle.source.calculation?.registrationFee?.registrationFee ?? null,
    inferredEquipmentPrice: vehicle.inferredEquipmentPrice ?? null,
    inferredNewPriceWithoutEquipment: vehicle.inferredNewPriceWithoutEquipment ?? null
  }
}

function commonTableRowData(taxCase: TaxCase): CommonDataData {
  return {
    firstRegDate: taxCase.vehicle?.firstRegDate ?? empty,
    key: taxCase.id + getRandomString(5),
    caseId: taxCase.id,
    mileage: taxCase.vehicle?.mileage ?? null,
    vin: taxCase.vehicle?.vin ?? empty,
    vehicleMake: taxCase.vehicle?.brand ?? empty,
    vehicleModel: taxCase.vehicle?.model ?? empty,
    vehicleVariant: taxCase.vehicle?.equipmentVariant ?? empty,
    emission: taxCase.vehicle?.emissionAmount ?? null,
    emissionUnit: taxCase.vehicle?.emissionUnit ?? empty,
    electricConsumption: taxCase.vehicle?.electricConsumption ?? null,
    electricRange: taxCase.vehicle?.electricRange ?? null,
    batteryCapacity: taxCase.vehicle?.batteryCapacity ?? null,
    engineSize: taxCase.vehicle?.engineSize ?? empty,
    fuel: taxCase.vehicle?.fuel ?? empty,
    transmission: taxCase.vehicle?.gearbox ?? empty,
    horsepower: taxCase.vehicle?.horsePower ?? empty,
    body: taxCase.vehicle?.body ?? empty,
    useCase: taxCase.vehicle?.useCase ?? empty,
  }
}

function getTableColumns(): ColumnsType<TableData> {
  return [
    {
      dataIndex: "caseId",
      key: 'caseId',
      title: _t('case'),
      fixed: "left",
      render: (id, row) => {
        if (row.isOurVehicle) {
          return <span className="text-medium">{_t('the_vehicle')}</span>;
        } else if (row.isRobot) {
          return <span className="text-medium">{_t('robot')}</span>;
        } else {
          return <Link target="_blank" to={getPath("tax", id)}>{id}</Link>
        }
      },
      sorter: (a, b) => (a.caseId || 0) - (b.caseId || 1),
      width: 64,
    },
    {
      dataIndex: "firstRegDate",
      key: "firstRegDate",
      title: _t("firstRegDate"),
      render: (dateString, _row) => {
        return date(dateString)
      },
      sorter: (a, b) => {
        if (a.firstRegDate && b.firstRegDate) {
          return new Date(a.firstRegDate).getTime() - new Date(b.firstRegDate).getTime()
        }
        return 0
      },
      width: 108,
    },
    {
      dataIndex: "mileage",
      key: "mileage",
      title: _t("mileage_unit"),
      render: (value) => format.milage(value),
      sorter: (a, b) => (a.mileage || 0) - (b.mileage || 1),
      width: 68,
    },
    {
      dataIndex: "newPrice",
      key: "newPrice",
      align: "right",
      title: _t("new_price"),
      render: (value) => format.price(value),
      sorter: (a, b) => (a.newPrice || 0) - (b.newPrice || 1),
      width: 124,
    },
    {
      dataIndex: "tradePrice",
      key: "tradePrice",
      align: "right",
      title: _t("trade_price"),
      render: (value) => format.price(value),
      sorter: (a, b) => (a.tradePrice || 0) - (b.tradePrice || 1),
      width: 124,
    },
    {
      dataIndex: "registrationFee",
      key: "registrationFee",
      align: "right",
      title: _t("registration_fee"),
      render: (value) => format.price(value),
      sorter: (a, b) => (a.registrationFee || 0) - (b.registrationFee || 1),
      width: 160,
    },
    {
      dataIndex: "equipmentPrice",
      key: "equipmentPrice",
      align: "right",
      title: _t("extra_equipments"),
      render: (value) => format.price(value),
      sorter: (a, b) => (a.equipmentPrice || 0) - (b.equipmentPrice || 1),
      width: 124,
    },
    {
      dataIndex: "newPriceWithoutEquipment",
      key: "newPriceWithoutEquipment",
      align: "right",
      title: _t("new_price", "wo_equipment"),
      render: (value) => format.price(value),
      sorter: (a, b) => (a.newPriceWithoutEquipment || 0) - (b.newPriceWithoutEquipment || 1),
      width: 142,
    },
    {
      dataIndex: "inferredNewPriceWithoutEquipment",
      key: "inferredNewPriceWithoutEquipment",
      align: "right",
      title: _t("inferred_new_price_without_equipment"),
      render: (value) => format.price(value),
      sorter: (a, b) => (a.inferredNewPriceWithoutEquipment || 0) -
        (b.inferredNewPriceWithoutEquipment || 1),
      width: 202,
    },
    {
      dataIndex: "inferredEquipmentPrice",
      key: "inferredEquipmentPrice",
      align: "right",
      title: _t("equipment_price_from_robot"),
      render: (value) => format.price(value),
      sorter: (a, b) => (a.inferredEquipmentPrice || 0) - (b.inferredEquipmentPrice || 1),
      width: 162,
    },
    {
      dataIndex: "vin",
      key: "vin",
      title: _t("vin_short"),
      width: 80,
    },
    {
      dataIndex: "vehicleMake",
      key: "vehicleMake",
      title: _t("brand"),
      className: "mx-width-180 text-ellipsis",
      render: (brand, _row) => <span title={brand}>{brand}</span>,
      sorter: (a, b) => sortAlphabetically(a, b, 'vehicleMake'),
      width: 88,
    },
    {
      dataIndex: "vehicleModel",
      key: "vehicleModel",
      title: _t("model"),
      className: "max-width-240 text-ellipsis",
      render: (model, _row) => <span title={model}>{model}</span>,
      sorter: (a, b) => sortAlphabetically(a, b, 'vehicleModel'),
      width: 88,
    },
    {
      dataIndex: "vehicleVariant",
      key: "vehicleVariant",
      title: _t("variant"),
      className: "max-width-240 text-ellipsis",
      render: (variant, _row) => <span title={variant}>{variant}</span>,
      sorter: (a, b) => sortAlphabetically(a, b, 'vehicleVariant'),
      width: 88,
    },
    {
      dataIndex: "emission",
      key: "emission",
      align: "right",
      title: _t("co2_emission"),
      render: (value) => format.emission(value),
      sorter: (a, b) => (a.emission || 0) - (b.emission || 1),
      width: 140,
    },
    {
      dataIndex: "emissionUnit",
      key: "emissionUnit",
      title: _t("co2_measurement"),
      sorter: (a, b) => sortAlphabetically(a, b, 'emissionUnit'),
      width: 148,
    },
    {
      dataIndex: "electricConsumption",
      key: "electricConsumption",
      title: _t("battery_usage"),
      render: (value) => format.batteryUsage(value),
      sorter: (a, b) => (a.electricConsumption || 0) - (b.electricConsumption || 1),
      width: 140,
    },
    {
      dataIndex: "electricRange",
      key: "electricRange",
      align: "right",
      title: _t("battery_range"),
      render: (value) => format.milage(value),
      sorter: (a, b) => (a.electricRange || 0) - (b.electricRange || 1),
      width: 168,
    },
    {
      dataIndex: "batteryCapacity",
      key: "batteryCapacity",
      align: "right",
      title: _t("battery_capacity"),
      render: (value) => format.batteryCapacity(value),
      sorter: (a, b) => (a.batteryCapacity || 0) - (b.batteryCapacity || 1),
      width: 168,
    },
    {
      dataIndex: "engineSize",
      key: "engineSize",
      align: "right",
      title: _t("engine_size"),
      sorter: (a, b) => (a.electricRange || 0) - (b.electricRange || 1),
      width: 140,
    },
    {
      dataIndex: "fuel",
      key: "fuel",
      title: _t("fuel"),
      sorter: (a, b) => sortAlphabetically(a, b, 'fuel'),
      width: 116,
    },
    {
      dataIndex: "transmission",
      key: "transmission",
      title: _t("gearbox"),
      sorter: (a, b) => sortAlphabetically(a, b, 'transmission'),
      width: 128,
    },
    {
      dataIndex: "horsepower",
      key: "horsepower",
      align: "right",
      title: _t("horse_power"),
      sorter: (a, b) => sortAlphabetically(a, b, 'emissionUnit'),
      width: 74,
    },
    {
      dataIndex: "body",
      key: "body",
      title: _t("trim"),
      sorter: (a, b) => sortAlphabetically(a, b, 'body'),
      width: 140,
    },
    {
      dataIndex: "useCase",
      key: "useCase",
      title: _t("special_use"),
      sorter: (a, b) => sortAlphabetically(a, b, 'useCase'),
    },
  ];
}

export function sortAlphabetically<T extends object>(a: T, b: T, key: keyof T) {
  if (a[key] != null && b[key] != null) {
    if (a[key]! < b[key]!) {
      return -1;
    } else {
      return 1;
    }
  }
  return 0;
}

type TableData = DifferentlySourcedPricingData & CommonDataData;

type DifferentlySourcedPricingData = {
  isOurVehicle: boolean;
  isRobot: boolean;
  newPrice: number | null;
  newPriceWithoutEquipment: number | null;
  equipmentPrice: number | null;
  tradePrice: number | null;
  registrationFee: number | null;
  inferredNewPriceWithoutEquipment: number | null;
  inferredEquipmentPrice: number | null;
}

type CommonDataData = {
  key: string | number;
  caseId: number | null;
  vin: string | null;
  firstRegDate: string | null;
  mileage: number | null;
  vehicleMake: string | null;
  vehicleModel: string | null;
  vehicleVariant: string | null;
  electricConsumption: number | null;
  electricRange: number | null;
  batteryCapacity: number | null;
  emission: number | null;
  emissionUnit: string | null;
  engineSize: string | null;
  fuel: string | null;
  transmission: string | null;
  horsepower: string | null;
  body: string | null;
  useCase: string | null;
}
