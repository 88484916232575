import { Checkbox, Form } from "antd";
import { FC } from "react";
import ServerSelectBox from "../../../components/serverSelectBox";
import _t from "../../../lang/translate";
import { dealerCategoriesAutoComplete } from "../../../services/autocompleteService";

const DealerSettingsFormFields: FC = () => {
  return (
    <>
      <Form.Item label={_t("inactive")} name="inactive" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item label={_t("suspended")} name="suspended" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item label={_t("leasing_enabled")} name="leasingEnabled" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item label={_t("accounting_items_enabled")} name="accountingItemsEnabled" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      <Form.Item label={_t("categories")} name="categoryIds">
        <ServerSelectBox mode="multiple" apiUrl={dealerCategoriesAutoComplete()} />
      </Form.Item>
    </>
  );
};

export default DealerSettingsFormFields;
