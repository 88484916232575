import { FC } from "react";
import { Col, Form, Row } from "antd";
import InputNum from "../../../components/form/inputNum";
import LeasingDateSelect from "../../../components/form/LeasingDateSelect";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";

interface LeasingPeriodFieldsProps {
  parentKey: string;
  disabled?: boolean;
}

const LeasingPeriodFields: FC<LeasingPeriodFieldsProps> = ({ parentKey, disabled }) => {
  const { leasingCase } = LeasingStore.useContainer();
  const leasingPeriodMax = leasingCase.partnerCase ? 12 : 36;

  return (
    <Row gutter={[12, 12]} className="flex-wrap">
      <Col flex="100px">
        <Form.Item name={["input", "leasingPeriod"]} rules={[{ required: true, message: "" }]} noStyle>
          <InputNum
            disabled={disabled}
            min={1}
            max={leasingPeriodMax}
            maxLength={2}
            suffix={<small>{_t("months_short")}</small>}
          />
        </Form.Item>
      </Col>
      <Col flex="auto">
        <Form.Item noStyle shouldUpdate={(curr, prev) => curr.input?.leasingPeriod !== prev.input?.leasingPeriod}>
          {({ getFieldValue }) => {
            const leasingPeriod = getFieldValue(["input", "leasingPeriod"]);
            return (
              <Form.Item name={[parentKey, "leasingDates"]} rules={[{ required: true }]} noStyle>
                <LeasingDateSelect disabled={disabled} leasingPeriod={leasingPeriod} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default LeasingPeriodFields;
