import React, { FC, useState } from "react";
import { Form, message } from "antd";
import CollapseCard from "../../../../components/collapseCard";
import DividerLine from "../../../../components/lineSplit";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import consts from "../../../../utilities/consts";
import CalculationFormActions from "./calculationFormActions";
import NewPriceFormItems from "./newPriceFormItems";
import TradePriceFormItems from "./tradePriceFormItems";
import TaxDocRegestrationFee from "./taxDocRegestrationFee";
import { TaxDocCalculationsType } from "../../../../types/taxDocTypes";
import { updateCalculations } from "../../../../services/taxDocService";
import { isAxiosError } from "../../../../utilities/typeGuard";

const TaxDocCalculationCard: FC = () => {
  const { taxDocCase, setTaxDocCase } = TaxDocCaseStore.useContainer();
  const [calcForm] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    const values = calcForm.getFieldsValue(true) as TaxDocCalculationsType;
    try {
      setLoading(true);
      const { data } = await updateCalculations(taxDocCase.id, values);
      setTaxDocCase(data);
      message.success(_t("saved"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <CollapseCard title={_t("calculation")} loading={loading}>
      <Form
        {...consts.formItemProps}
        form={calcForm}
        className="m-0"
        initialValues={taxDocCase.calculation || {}}
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <NewPriceFormItems />
        <DividerLine fluid />
        <TradePriceFormItems />
        <DividerLine fluid />
        <TaxDocRegestrationFee />
        <DividerLine fluid />
        <CalculationFormActions />
      </Form>
    </CollapseCard>
  );
};

export default TaxDocCalculationCard;
