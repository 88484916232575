import React, { FC, ReactNode } from "react";

interface LoadingProps {
  loading?: boolean;
  hideLogo?: boolean;
  message?: ReactNode;
  style?: React.CSSProperties;
}

const Loading: FC<LoadingProps> = ({ loading = true, hideLogo = false, message, style, children }) => {
  return (
    <div className={`loading-wp ${loading ? " is-loading" : ""} ${hideLogo ? " hide-loading-logo" : ""}`}
      style={style}
    >
      {children}
      {message && <div className="loading-message">{message}</div>}
    </div>
  );
};

export default Loading;
