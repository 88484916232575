import React, { FC } from "react";
import { Col, Row } from "antd";
import Icon from "../../../../assets/icons/icon";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";
import TaxDocPurchaseButton from "./taxDocPurchaseButton";

const TaxDocPurchase: FC = () => {
  const { taxDocCase } = TaxDocCaseStore.useContainer();

  return (
    <>
      <Row className="flex-wrap mb-1">
        <Col flex="auto">
          <h3 className="">
            <Icon name="shield-checkmark-outline" size="huge" className="text-primary" />
            <span className="pl-05">{_t("documentation_with_guarantee")}</span>
          </h3>
          <label className="d-block">{_t("save_administration_and_risk")}</label>
          <label className="d-block">{_t("clear_documentation_over_evaluation")}</label>
          <label className="d-block">{_t("garanti_with_24_months")}</label>
        </Col>
        <Col flex="100px" className="text-right">
          <h3 className="mb-0">{format.price(taxDocCase.settings?.productPrice)}</h3>
          <small className="d-block text-right">
            {format.price(taxDocCase.evaluation?.productPriceWithVat)}
            <br /> {_t("include_short", "vat").toLowerCase()}
          </small>
        </Col>
      </Row>
      {!taxDocCase.flags.documentationBought && <TaxDocPurchaseButton />}
    </>
  );
};

export default TaxDocPurchase;
