import React, { FC } from "react";
import { ReactComponent as LogoImg } from "../assets/images/logo.svg";

const Logo: FC = () => {
  return (
    <div className="logo-wp">
      <LogoImg />
    </div>
  );
};

export default Logo;
