import { Button, Col, Form, Input, message, Row, Spin } from "antd";
import { FC, useState } from "react";
import InputNum from "../../../../components/form/inputNum";
import SwitchBox from "../../../../components/form/switchBox";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { updateDealActiveSettings } from "../../../../services/leasingService";
import { LeasingAdminDealActiveType } from "../../../../types/leasingTypes";
import consts from "../../../../utilities/consts";
import { isAxiosError } from "../../../../utilities/typeGuard";
import DateSelect from "../../../../components/form/dateSelect";

interface LeasingAdminDealActiveEditProps {
  stopEditing: () => void;
}

const LeasingAdminDealActiveEdit: FC<LeasingAdminDealActiveEditProps> = ({ stopEditing }) => {
  const { leasingCase, adminValues, setAdminValues } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSave = async (values: LeasingAdminDealActiveType) => {
    try {
      setLoading(true);
      const { data } = await updateDealActiveSettings(leasingCase.id, values);
      if (adminValues && data) {
        setAdminValues({ ...adminValues, ...data });
      }
      stopEditing();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <Form<LeasingAdminDealActiveType>
        {...consts.formItemProps}
        className="m-0"
        initialValues={{ startDateDmr: adminValues?.motorAgency?.leasingStartDate || null, ...adminValues?.deal?.activeSettings }}
        requiredMark={false}
        onFinish={handleSave}
      >
        <Form.Item name="vehiclePriceInvoice" label={_t("vehicle_price_invoice")}>
          <InputNum suffix={_t("dkk_price_short")} />
        </Form.Item>
        <Form.Item name="dealerProvisionInvoice" label={_t("dealer_provision_invoice")}>
          <InputNum suffix={_t("dkk_price_short")} />
        </Form.Item>
        <Form.Item name="periodTax" label={_t("period_tax")}>
          <InputNum suffix={_t("dkk_price_short")} />
        </Form.Item>
        <Form.Item name="startDateDmr" label={_t("start_date")}>
          <DateSelect />
        </Form.Item>
        <Form.Item name="customCaseId" label={_t("custom_case_id")}>
          <Input />
        </Form.Item>
        <Form.Item name="restValueNotUpdated" label={_t("rest_value_not_updated")}>
          <SwitchBox />
        </Form.Item>
        <Form.Item name="vipVehicle" label={_t("vip_vehicle")}>
          <SwitchBox />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ submit }) => (
            <Row justify="space-between">
              <Col>
                <Button disabled={loading} onClick={stopEditing}>
                  {_t("cancel")}
                </Button>
              </Col>
              <Col>
                <Button loading={loading} type="primary" onClick={submit}>
                  {_t("save")}
                </Button>
              </Col>
            </Row>
          )}
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default LeasingAdminDealActiveEdit;
