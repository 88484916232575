import { FC } from "react";
import { Form } from "antd";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";
import TaxCollateralAmountInput from "./taxCollateralAmountInput";

const TaxCollateralAmount: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const preRegFee = taxCase.calculation?.registrationFee?.registrationFee;
  const estimatedCollateral = taxCase.calculation?.estimatedCollateral?.estimatedCollateral;
  const finalPreRegFee = (preRegFee || 0) + (estimatedCollateral || 0);

  return (
    <Form.Item shouldUpdate noStyle>
      {({ getFieldValue }) => (
        <Form.Item label={_t("collateral")} name="collateral">
          <TaxCollateralAmountInput
            optRegFee={getFieldValue(["registrationFee", "registrationFee"])}
            preRegFee={finalPreRegFee}
          />
        </Form.Item>
      )}
    </Form.Item>
  );
};

export default TaxCollateralAmount;
