import { Col, Row } from "antd";
import { FC } from "react";
import DividerLine from "../../../../components/lineSplit";
import MotorAgencyReportView from "./motorAgencyView";
import LeasingActivationActions from "./leasingActivationActions";
import LockCaseButton from "./lockCaseButton";

const LeasingAdminMotorAgency: FC = () => {
  return (
    <>
      <MotorAgencyReportView />
      <DividerLine fluid className="mb-1" />
      <Row justify="space-between">
        <Col>
          <LockCaseButton />
        </Col>
        <Col>
          <LeasingActivationActions />
        </Col>
      </Row>
    </>
  );
};

export default LeasingAdminMotorAgency;
