import { Form, message } from "antd";
import { FC, useState } from "react";
import ServerSelectBox from "../../../../components/serverSelectBox";
import _t from "../../../../lang/translate";
import { customersAutoComplete } from "../../../../services/autocompleteService";
import { getCustomer } from "../../../../services/customerService";
import { CustomerType } from "../../../../types/customerTypes";

interface LeasingCustomerAutoFillProps {
  typeId: number;
  onFetched: (customer: CustomerType) => void;
}

const LeasingCustomerAutoFill: FC<LeasingCustomerAutoFillProps> = ({ typeId, onFetched }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelect = async (v: number) => {
    try {
      setLoading(true);
      const { data } = await getCustomer(v);
      const customer: CustomerType = { ...data, contactPersons: null };

      onFetched(customer);
    } catch ({ response }) {
      message.error(_t(response?.data?.message || "msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form.Item labelCol={{ hidden: true }} wrapperCol={{ span: 24 }}>
      <ServerSelectBox
        placeholder={_t("choose", "customer")}
        loading={loading}
        disabled={loading}
        onSelect={handleSelect}
        apiUrl={customersAutoComplete()}
        filters={`filter[typeId]=${typeId}`}
      />
    </Form.Item>
  );
};

export default LeasingCustomerAutoFill;
