import { Result, Spin } from "antd";
import React, { FC } from "react";
import Details from "../../../../components/detailsList";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { keyNumbersUrl } from "../../../../services/leasingService";
import { useApi } from "../../../../services/useApi";
import { KeyNumbersType } from "../../../../types/leasingTypes";
import DividerLine from "../../../../components/lineSplit";

interface SeparatorTitleProps {
  title: string;
}

const SeparatorTitle: FC<SeparatorTitleProps> = ({ title }) => {
  let separator;

  switch (title) {
    case "Resterende måneder":
      separator = (
        <>
          <Details.Title
            className="m-0"
          >
            <DividerLine fluid />
            <h3>{_t("actual_status")}</h3>
          </Details.Title>
        </>
      )
      break;
    case "Kunden egenfinansiering i %":
      separator = (
        <>
          <Details.Title
            className="m-0"
          >
            <DividerLine fluid />
            <h3>{_t("capital_requirements")}</h3>
          </Details.Title>
        </>
      )
      break;
    case "Endelig indtægt":
      separator = (
        <>
          <Details.Title
            className="m-0"
          >
            <DividerLine fluid />
            <h3>{_t("final_key_values")}</h3>
          </Details.Title>
        </>
      )
      break;
    case "Indtægt (Uden moms)":
      separator = (
        <>
          <Details.Title
            className="m-0"
          >
            <h3>{_t("core_earnings")}</h3>
          </Details.Title>
        </>
      )
      break;
    default:
      separator = null;
  }

  return separator;
}

const LeasingKeyNumbers: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const [{ data, isLoading, isError }] = useApi<KeyNumbersType[]>(keyNumbersUrl(leasingCase.id), []);

  return (
    <Spin spinning={isLoading} wrapperClassName="min-height-100">
      {isError && <Result status="error" title={_t("msg.unknown_error")} className="result-small" />}
      {data.length > 0 && (
        <Details borderLess>
          {data.map((item) => {
            return (
              <>
                <SeparatorTitle title={item.label} />
                <Details.Item {...item} />
              </>
            )
          })}
        </Details>
      )}
    </Spin>
  );
};

export default LeasingKeyNumbers;
