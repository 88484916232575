import { ActivityEntryType, SuggestionsType } from "../types/activityTypes";
import { IdType, ProductType } from "../types/appTypes";
import { deleteImportActivity, importActivitiesUrl, saveImportActivity, updateImportActivity } from "./importService";
import {
  deleteLeasingActivity,
  leasingActivitiesUrl,
  saveLeasingActivity,
  updateLeasingActivity,
} from "./leasingService";
import { deleteTaxDocActivity, saveTaxDocActivity, taxDocActivitiesUrl, updateTaxDocActivity } from "./taxDocService";
import { deleteTaxActivity, saveTaxActivity, taxActivitiesUrl, updateTaxActivity } from "./taxService";
import {
  deleteResellerTaxActivity,
  resellerTaxActivitiesUrl,
  saveResellerTaxActivity,
  updateResellerTaxActivity
} from "./resellerTaxService";

export function getUrl(type: ProductType, id: IdType) {
  if (type === "tax") return taxActivitiesUrl(id);
  if (type === "resellerTax") return resellerTaxActivitiesUrl(id);
  if (type === "taxDoc") return taxDocActivitiesUrl(id);
  if (type === "leasing") return leasingActivitiesUrl(id);
  if (type === "import") return importActivitiesUrl(id);
  else throw Error("Unhadeld Activity Type:" + type);
}

export function saveActivity(type: ProductType, id: IdType, data: ActivityEntryType) {
  if (type === "tax") return saveTaxActivity(id, data);
  if (type === "resellerTax") return saveResellerTaxActivity(id, data);
  if (type === "taxDoc") return saveTaxDocActivity(id, data);
  if (type === "leasing") return saveLeasingActivity(id, data);
  if (type === "import") return saveImportActivity(id, data);
  else throw Error("Unhadeld Activity Type:" + type);
}

export function updateActivity(type: ProductType, id: IdType, data: ActivityEntryType) {
  // Create and import updateActivity funcs before uncomment:
  if (type === "tax") return updateTaxActivity(id, data);
  if (type === "resellerTax") return updateResellerTaxActivity(id, data);
  if (type === "taxDoc") return updateTaxDocActivity(id, data);
  if (type === "leasing") return updateLeasingActivity(id, data);
  if (type === "import") return updateImportActivity(id, data);
  else throw Error("Unhadeld Activity Type:" + type);
}

export function deleteActivity(type: ProductType, id: IdType, deletedId: string) {
  if (type === "tax") return deleteTaxActivity(id, deletedId);
  if (type === "resellerTax") return deleteResellerTaxActivity(id, deletedId);
  if (type === "taxDoc") return deleteTaxDocActivity(id, deletedId);
  if (type === "leasing") return deleteLeasingActivity(id, deletedId);
  if (type === "import") return deleteImportActivity(id, deletedId);
  else throw Error("Unhadeld Activity Type:" + type);
}

export function getSuggestions(type: ProductType) {
  if (type === "import") return importSuggestions;
  if (type === "tax") return null;
  if (type === "resellerTax") return null;
  if (type === "taxDoc") return null;
  if (type === "leasing") return null;
  else throw Error("Unhadeld Activity Type:" + type);
}

const importSuggestions: SuggestionsType[] = [
  {
    label: "Indtast aktivitet",
    text: "",
  },
  {
    label: "Har forsøgt at ringe til sælger",
    text: "Har forsøgt at kontakte sælger uden held. Forsøger igen senere.",
  },
  {
    label: "Afventer svar fra sælger",
    text: "Afventer oplysninger fra sælger.",
  },
  {
    label: "Forhandlet ny pris",
    text: "Forhandlet ny pris.",
  },
  {
    label: "Rapport uploaded",
    text: "Rapport uploaded",
  },
  {
    label: "Bilen er reserveret",
    text: "Bilen er reserveret til anden side. Følger op senere.",
  },
  {
    label: "Biler er handlet",
    text: "Bilen er købt og transport er bestilt.",
  },
  {
    label: "Forventer levering af bilen",
    text: "Forventer levering af bilen d. ",
  },
  {
    label: "Bilen var solgt til anden side",
    text: "Bilen var solgt til anden side",
  },
];
