import React, { FC } from "react";
import { Activity } from "../../types/activityTypes";

interface ActivityItemBodyProps {
  item: Activity;
}

const ActivityItemBody: FC<ActivityItemBodyProps> = ({ item }) => {
  const { text, person, note } = item;
  return (
    <>
      {text && <span className="d-block mb-05">{text}</span>}
      {(person || note) && (
        <span className="d-block mb-1">
          {person && <strong>{person.fullName}</strong>}
          {note && (
            <span className="spacing-pre-line">
              : <br />
              {note}
            </span>
          )}
        </span>
      )}
    </>
  );
};

export default ActivityItemBody;
