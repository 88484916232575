import { FC, useEffect } from "react";
import format from "../../../../../utilities/formatNumbers";
import { isNum } from "../../../../../utilities/typeGuard";

interface TaxEquipmentsPriceCellProps {
  vehicleYear?: number | null;
  diff?: number | null;
  equipmentYearFactorDeduction?: number | null;
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const TaxEquipmentsPriceCell: FC<TaxEquipmentsPriceCellProps> = ({
  vehicleYear,
  diff,
  value,
  equipmentYearFactorDeduction,
  onChange,
}) => {
  useEffect(() => {
    let nextValue = 0;

    if (isNum(diff) && isNum(vehicleYear) && isNum(equipmentYearFactorDeduction) && vehicleYear < 8) {
      nextValue = diff * equipmentYearFactorDeduction;
    }
    onChange && onChange(Math.round(nextValue || 0));
  }, [vehicleYear, diff, equipmentYearFactorDeduction]);

  return <>{isNum(value) ? <span className="text-dark text-bold">{format.number(value)}</span> : "-"}</>;
};

export default TaxEquipmentsPriceCell;
