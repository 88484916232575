import { Button, Col, message, Popconfirm, Row } from "antd";
import React, { FC, useState } from "react";
import Icon from "../../../assets/icons/icon";
import openModal from "../../../components/modal/openModal";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { activateOffer, deleteOffer, sendOffer } from "../../../services/leasingService";
import { LeasingPartialCaseType } from "../../../types/leasingTypes";
import { isAxiosError } from "../../../utilities/typeGuard";
import LeasingOfferArchive from "./leasingOfferArchive";
import LeasingOfferView from "./leasingOfferView";

interface LeasingOffersProps {
  inputSavedStatus: boolean
}

const LeasingOffers: FC<LeasingOffersProps> = ({ inputSavedStatus }) => {
  const { leasingCase, setLeasingCase, leasingForm, setCalcCount } = LeasingStore.useContainer();
  const { activeOffer, flags } = leasingCase;
  const disabled = flags.readonly || flags.inputLocked || !leasingCase.id;
  const [loading, setLoading] = useState<boolean>(false);

  const handleOfferDeletion = async (offerId: number) => {
    try {
      setLoading(true);
      await deleteOffer(leasingCase.id, offerId);
      if (offerId === activeOffer?.id) {
        setLeasingCase({ ...leasingCase, activeOffer: null });
        /* leasingForm.setFieldsValue(payload); */
      }
      message.success(_t("offer", "deleted"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleOfferActivate = async (offerId: number) => {
    try {
      setLoading(true);
      const { data } = await activateOffer(leasingCase.id, offerId);
      if (data.deal) {
        leasingForm.setFieldsValue({ ...data.deal });
        setLeasingCase({ ...leasingCase, ...data });
        // Let the ui know we have new output:
        setCalcCount((v) => ++v);
      }
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleOfferSend = async (offerId: number) => {
    try {
      setLoading(true);
      await sendOffer(leasingCase.id, offerId);
      message.success(_t("offer", "send"));
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleNewOffer = async () => {
    try {
      const payload = await openModal<LeasingPartialCaseType>("addLeasingOffer", {
        leasingCaseId: leasingCase.id,
      });
      setLeasingCase({ ...leasingCase, ...payload });
    } catch (error) {
    }
  };

  return (
    <>
      <span className="text-dark d-block text-large mb-05">{_t("active", "offer")}</span>
      <LeasingOfferView
        className="mb-1"
        isActiveOffer
        offer={activeOffer}
        loading={loading}
        onDelete={handleOfferDeletion}
        onSend={handleOfferSend}
        disabled={disabled}
      />
      <Row justify="space-between">
        <Col>
          <LeasingOfferArchive onDelete={handleOfferDeletion} onActivate={handleOfferActivate} />
        </Col>
        <Col>
          {inputSavedStatus ? (
            <Button icon={<Icon name="add-outline" />} disabled={disabled} onClick={handleNewOffer}>
              {_t("create", "offer")}
            </Button>
          ) : (
            <Popconfirm title={_t('dirty_state_case')} onConfirm={handleNewOffer}>
              <Button icon={<Icon name="add-outline" />} disabled={disabled}>
                {_t("create", "offer")}
              </Button>
            </Popconfirm>
          )}
        </Col>
      </Row>
    </>
  );
};

export default LeasingOffers;
