import React, { FC, useState } from "react";
import { Button, Modal } from "antd";
import _t from "../../../../../lang/translate";
import Draggable from "react-draggable";
import TaxAdvancedCalcForm from "./taxAdvancedCalcForm";
import TaxDocCaseStore from "../../../../../contexts/taxDocCaseStore";
import { TradePriceCalcOutputType } from "../../calc/tradePriceCalculatorIndex";
import { Equipment } from "../../../../../types/appTypes";
import { TaxDocCalculationsType } from "../../../../../types/taxDocTypes";

interface TradePriceAdvancedCalculatorProps {
  onChange?: (values: any) => void;
  onCalculated?: (values: TradePriceCalcOutputType) => void;
  calculationFormValues: TaxDocCalculationsType;
  value?: any;
}

const TradePriceAdvancedCalculator: FC<TradePriceAdvancedCalculatorProps> = ({
  onCalculated,
  onChange,
  value,
  calculationFormValues,
}) => {
  const [dragging, setDragging] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const { taxDocCase } = TaxDocCaseStore.useContainer();

  const vehicleEquipments: Equipment[] = calculationFormValues?.newPrice?.equipmentCalculator?.equipments || [];
  const documentationDate = calculationFormValues?.documentationDate || null;

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  const stopDragging = () => dragging && setDragging(false);
  const startDragging = () => !dragging && setDragging(true);

  const handleApply = (v: any, output: TradePriceCalcOutputType) => {
    onCalculated && onCalculated(output);
    onChange && onChange(v);
    closeModal();
  };

  return (
    <>
      <Button tabIndex={-1} onClick={openModal}>
        {_t("advanced")}
      </Button>
      <Modal
        width={1161}
        title={null}
        visible={visible}
        destroyOnClose
        closable
        modalRender={(modal) => <Draggable disabled={dragging}>{modal}</Draggable>}
        onCancel={closeModal}
        bodyStyle={{ padding: 0 }}
        footer={null}
      >
        <TaxAdvancedCalcForm
          onApply={handleApply}
          initValue={value || {}}
          documentationDate={documentationDate}
          vehicleEquipments={vehicleEquipments}
          taxDocCase={taxDocCase}
          stopDragging={stopDragging}
          startDragging={startDragging}
        />
      </Modal>
    </>
  );
};

export default TradePriceAdvancedCalculator;
