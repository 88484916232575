import { Col, Row, RowProps, Skeleton, Space } from "antd";
import React, { FC, ReactNode } from "react";
import DividerLine, { DividerLineProps } from "./lineSplit";

interface DetailsProps {
  children: ReactNode;
  borderLess?: boolean;
  skeletonLoading?: boolean;
  className?: string;
}

interface TitleProps {
  children: ReactNode;
  bold?: boolean;
  extra?: ReactNode;
  className?: string;
}

interface ItemProps extends RowProps {
  label?: ReactNode;
  value?: ReactNode;
  extra?: ReactNode;
  boldValue?: boolean;
  boldLabel?: boolean;
  bold?: boolean;
  color?: string;
}

interface ListSubComponents {
  Title: FC<TitleProps>;
  Item: FC<ItemProps>;
  Divider: FC<DividerLineProps>;
}

const Title: FC<TitleProps> = ({ children, extra, bold, className }) => {
  const nextClassName = `details-list-title mt-2 ${className}`;
  if (extra) {
    return (
      <Row justify="space-between" gutter={0} className={nextClassName}>
        <Col>{bold ? <strong>{children}</strong> : children}</Col>
        <Col>{extra}</Col>
      </Row>
    );
  }
  return <div className={nextClassName}>{bold ? <strong>{children}</strong> : children}</div>;
};
const Divider: FC<DividerLineProps> = ({ children, className, ...rest }) => (
  <DividerLine className={`mt-05 mb-05 ${className ?? ""}`} {...rest}>
    {children}
  </DividerLine>
);

const Item: FC<ItemProps> = ({ label, value, boldValue, boldLabel, extra, bold, color, ...rest }) => (
  <Row justify="space-between" gutter={14} {...rest}>
    <Col>
      <label>{boldLabel || bold ? <strong>{label}</strong> : label}</label>
    </Col>
    {(value !== null || value !== undefined) && (
      <Col className={`details-list-value text-${color}-important`}>
        {boldValue || bold ? <strong>{value}</strong> : value}
      </Col>
    )}
    {extra && (
      <Col span={24} className="text-right">
        {extra}
      </Col>
    )}
  </Row>
);

const Details: FC<DetailsProps> & ListSubComponents = ({
  children,
  borderLess,
  skeletonLoading,
  className,
}) => (
  <div className={`details-list-wp mb-1 ${className || ""}`}>
    <Space
      className="w-100"
      split={borderLess ? undefined : <Divider className="m-0" />}
      direction="vertical"
    >
      {skeletonLoading ? (
        <Skeleton
          title={false}
          className="calculation-skeleton"
          paragraph={{ rows: React.Children.toArray(children)?.length || 3 }}
        />
      ) : (
        children
      )}
    </Space>
  </div>
);

Details.Item = Item;
Details.Title = Title;
Details.Divider = Divider;

export default Details;
