import React, { FC } from "react";
import { Button, Row, Space, Typography } from "antd";
import Col from "antd/es/grid/col";
import Icon from "../../assets/icons/icon";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import { ChecklistGroupType } from "../../types/checklistTypes";

interface ChecklistGroupHeaderProps {
  group: ChecklistGroupType;
}

const ChecklistGroupAdminHeader: FC<ChecklistGroupHeaderProps> = ({ group }) => {
  const { openGroupSortDrawer, editing, sorting } = ChecklistAdminStore.useContainer();
  return (
    <Row justify="space-between">
      <Col>
        <Typography.Title level={5} className="mb-0">
          {group.title}
        </Typography.Title>
      </Col>
      <Col>
        <Space>
          <Icon name={group.isPublic ? "eye-outline" : "eye-off-outline"} size="large" className="mr-05" />
          <Button
            size="small"
            disabled={!!editing || sorting}
            className="muted no-bg"
            onClick={openGroupSortDrawer}
            type="text"
            shape="circle"
            icon={<Icon name="settings-outline" />}
          />
        </Space>
      </Col>
    </Row>
  );
};

export default ChecklistGroupAdminHeader;
