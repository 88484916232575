import React from "react";
import CustomMsg from "../customMsg";
import { Button, Col, Row } from "antd";
import _t from "../../lang/translate";
import { Link } from "react-router-dom";
import { getPath } from "../../routes/appRoutes";
import appConfig from "../../appConfig";
import subImage from "../../assets/images/sub-image.png";

type ProductType = "tax.view_case" | "tax.view_archive";

type Props = {
  customMsgProps: React.ComponentProps<typeof CustomMsg>;
  productType: ProductType;
}

function getAccessText(productType: ProductType) {
  switch (productType) {
    case "tax.view_archive": return _t("promo.no_access_to_tax_archive");
    case "tax.view_case": return _t("promo.no_access_to_tax_case");
    default: return _t("promo.no_access");
  }
}

const LimitedAccessMessageBox = ({ customMsgProps, productType }: Props) => {
  return (
    <CustomMsg
      {...customMsgProps}
    >
      <Row gutter={[16, 16]} align="middle" className="mt-2 pr-2">
        <Col sm={24} xxl={19}>
          <h2>{_t("promo.no_acess_with_current_subscription")}</h2>
          <p className="text-large mb-0">
            {getAccessText(productType)}
          </p>
          <p className="text-large">
            {_t("promo.limited_access_reason")}
          </p>

          <Row gutter={16} align="middle">
            <Col className="mt-2 mb-2">
              <Button type="primary">
                <Link to={getPath("subscription")}>
                  {_t('choose', 'subscription_package')}
                </Link>
              </Button>
            </Col>

            <Col className="text-large">
              {_t('or')}
            </Col>

            <Col className="mt-2 mb-2">
              <Button type="default">
                <Link to={getPath("tax", "new")}>{_t("create_tax_case")}</Link>
              </Button>
            </Col>
          </Row>

          <span style={{ opacity: 0.7 }}>
            {_t("if_you_have_questiongs_you_are_welcome_to_contact_us_at")}.&nbsp;
            <a href={`tel:${appConfig("companyPhone")}`}>
              {appConfig("companyPhone")}
            </a>
            &nbsp;{_t("or", "on").toLowerCase()}&nbsp;
            <a href={`mailto:${appConfig("companyEmail")}`}>
              {appConfig('companyEmail')}
            </a>.
            &nbsp;{_t("we_look_forward_to_working_with_you")}.
            </span>
        </Col>

        <Col xs={0} xxl={5}>
          <img src={subImage} alt="" width="202" />
        </Col>
      </Row>
    </CustomMsg>
  )
}
export default LimitedAccessMessageBox;
