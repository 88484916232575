import React, { FC } from "react";
import { Button, Form, Input, Space } from "antd";
import ChecklistAdminStore from "../../contexts/checklistAdminStore";
import _t from "../../lang/translate";
import { ChecklistEntryType } from "../../types/checklistTypes";
import SwitchInput from "../form/switchInput";

const { TextArea } = Input;

interface ChecklistFormProps {
  initData?: ChecklistEntryType;
  loading: boolean;
  onCancel?: () => void;
  onSubmit: (value: ChecklistEntryType) => void;
}

const ChecklistForm: FC<ChecklistFormProps> = ({ initData, onSubmit, loading, onCancel }) => {
  const { setEditing } = ChecklistAdminStore.useContainer();
  const [form] = Form.useForm<ChecklistEntryType>();

  const handleCancel = () => {
    onCancel && onCancel();
    if (initData?.id === "0") return;

    setEditing(null);
  };
  const handleSubmit = () => {
    onSubmit(form.getFieldsValue(true));
  };

  return (
    <Form form={form} onFinish={handleSubmit} initialValues={initData} className="mt-1 mb-05">
      <Form.Item name="title" rules={[{ required: true, whitespace: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="description" rules={[{ required: true, whitespace: true }]}>
        <TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
      </Form.Item>
      <Form.Item name="isPublic" valuePropName="checked">
        <SwitchInput label={_t("publish")} />
      </Form.Item>
      <Space className="justify-content-end w-100">
        <Button disabled={loading} onClick={handleCancel} tabIndex={-1}>
          {_t("cancel")}
        </Button>
        <Button loading={loading} onClick={form.submit} type="primary">
          {_t("save")}
        </Button>
      </Space>
    </Form>
  );
};

export default ChecklistForm;
