import { Button, Drawer, Result, Spin } from "antd";
import { FC, useEffect, useState } from "react";
import Icon from "../../../assets/icons/icon";
import LayoutStore from "../../../contexts/layoutStore";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { leasingOffersUrl } from "../../../services/leasingService";
import { useApi } from "../../../services/useApi";
import { ApiResponseObject } from "../../../types/apiTypes";
import { LeasingOfferType } from "../../../types/leasingTypes";
import consts from "../../../utilities/consts";
import LeasingOfferView from "./leasingOfferView";

interface LeasingOfferArchiveProps {
  onDelete: (Id: number) => void;
  onActivate: (id: number) => void;
}

const LeasingOfferArchive: FC<LeasingOfferArchiveProps> = ({ onActivate, onDelete }) => {
  const { screen } = LayoutStore.useContainer();
  const { leasingCase } = LeasingStore.useContainer();
  const [visible, setVisible] = useState<boolean>(false);
  const [{ data, isError, isLoading }, setUrl] = useApi<ApiResponseObject<LeasingOfferType[]>>("", { data: [] });
  const { id, activeOffer, flags } = leasingCase;
  const activeOfferId = activeOffer?.id;
  const { inputLocked, readonly } = flags;
  const disabled = inputLocked || readonly;

  useEffect(() => {
    visible ? setUrl(leasingOffersUrl(id)) : setUrl("");
  }, [visible, setUrl, id]);

  const openDrawer = () => setVisible(true);
  const closeDrawer = () => setVisible(false);

  return (
    <>
      <Button tabIndex={-1} onClick={openDrawer} disabled={!leasingCase.id}>
        <Icon name="file-tray-full-outline" size="medium" /> {_t("saved_offers")}
      </Button>
      <Drawer
        footer={null}
        title={_t("recent_created_offer")}
        width={screen.isMobile ? consts.drawerWidthSm : consts.drawerWidth}
        visible={visible}
        onClose={closeDrawer}
        className="calculation-table"
      >
        {isError && <Result status="error" />}
        {isLoading && (
          <div className="text-center mb-2">
            <Spin size="large" />
          </div>
        )}
        {data.data &&
          data.data.map((offer) => (
            <LeasingOfferView
              className={`mb-1${activeOfferId === offer.id ? " border-light border-primary" : ""}`}
              disabled={activeOfferId === offer.id || disabled}
              offer={offer}
              onDelete={onDelete}
              onActivate={offer.canActivate ? onActivate : undefined}
            />
          ))}
      </Drawer>
    </>
  );
};

export default LeasingOfferArchive;
