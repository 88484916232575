import React, { FC } from "react";
import { PaymentCardType } from "../types/paymentCardType";
import CheckableBox, { CheckableBoxProps } from "./checkableBox";
import PaymentCardContent from "./paymentCardContent";

interface PaymentCardProps extends CheckableBoxProps {
  card: PaymentCardType;
  onCheck: (id: string) => void;
  onRemove?: (id: string) => void;
  checked?: boolean;
}

const PaymentCardCheckBox: FC<PaymentCardProps> = ({
  card,
  className,
  onClick,
  checked,
  onRemove,
  onCheck,
  ...rest
}) => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    onCheck(card.id);
    onClick && onClick(e);
  };

  return (
    <CheckableBox
      checked={checked}
      className="mb-05 p-1 text-larger text-left font-courier"
      onClick={handleClick}
      {...rest}
    >
      <PaymentCardContent card={card} onRemove={onRemove} />
    </CheckableBox>
  );
};

export default PaymentCardCheckBox;
