import { Button, Form, message, Modal, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FC, useState } from "react";
import InputNum from "../../../components/form/inputNum";
import DealerAdminStore from "../../../contexts/dealerAdminStore";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import { updateBonus } from "../../../services/dealerService";
import { BonusLogType } from "../../../types/bonusTypes";
import format from "../../../utilities/formatNumbers";
import { isAxiosError } from "../../../utilities/typeGuard";

const DealerAdminBonusUpdate: FC = () => {
  const { dealer } = DealerStore.useContainer();
  const { admin, setAdmin } = DealerAdminStore.useContainer();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const openModal = () => setIsVisible(true);
  const closeModal = () => setIsVisible(false);

  const handleSubmit = async (values: BonusLogType) => {
    try {
      setLoading(true);
      await updateBonus(dealer.id, values);
      const nextAmount = admin.bonusAmount + values.amount;
      setAdmin({ ...admin, bonusAmount: nextAmount });
      closeModal();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button onClick={openModal}>{_t("add", "bonus")}</Button>
      <Modal width={500} visible={isVisible} onCancel={closeModal} title={null} footer={null}>
        <Form<BonusLogType>
          onFinish={handleSubmit}
          initialValues={{ amount: 1, note: "Manuel bonus" }}
          layout="vertical"
        >
          <h2 className="modal-title">{_t("add", "bonus")}</h2>
          <Form.Item
            name="amount"
            label={_t("amount")}
            rules={[{ required: true }]}
            extra={
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <small className="d-block pt-05">
                    {_t("total", "bonus")}: {format.price((getFieldValue("amount") || 0) + admin.bonusAmount)}
                  </small>
                )}
              </Form.Item>
            }
          >
            <InputNum disabled={loading} />
          </Form.Item>

          <Form.Item name="note" label={_t("note")} rules={[{ required: true, whitespace: true }]}>
            <TextArea disabled={loading} autoSize={{ minRows: 1, maxRows: 3 }} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ submit }) => (
              <div className="text-right">
                <Space>
                  <Button type="ghost" onClick={closeModal} disabled={loading}>
                    {_t("cancel")}
                  </Button>
                  <Button onClick={submit} loading={loading} type="primary">
                    {_t("save")}
                  </Button>
                </Space>
              </div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default DealerAdminBonusUpdate;
