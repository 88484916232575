import { FC } from "react";
import { Button, Form } from "antd";
import DividerLine from "../../../components/lineSplit";
import SavedStatus from "../../../components/savedStatus";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";

const LeasingEntriesActions: FC<{ savedStatus: boolean }> = ({ savedStatus }) => {
  const { leasingCase } = LeasingStore.useContainer();
  const { flags } = leasingCase;
  const { readonly, inputLocked } = flags;
  const disabled = readonly || inputLocked;

  return (
    <Form.Item shouldUpdate noStyle>
      {({ submit }) => (
        <>
          <DividerLine className="mb-1" />
          <div className="text-right pt-0 p-1">
            <SavedStatus status={!!leasingCase.id && savedStatus} />
            <Button onClick={submit} type="primary" disabled={disabled}>
              {!leasingCase.id ? _t("create", "case") : _t("save")}
            </Button>
          </div>
        </>
      )}
    </Form.Item>
  );
};

export default LeasingEntriesActions;
