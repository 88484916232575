import { Col, message, Result, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CollapseCard from "../../components/collapseCard";
import UserStore from "../../contexts/userStore";
import _t from "../../lang/translate";
import PageBody from "../../layout/pageBody";
import PageTitle from "../../layout/pageTitle";
import { getPath, isNew } from "../../routes/appRoutes";
import { postUrl, savePost } from "../../services/postService";
import { useApi } from "../../services/useApi";
import { ParamTypes, ScreenProps } from "../../types/appTypes";
import { initPost, PostType } from "../../types/postTypes";
import { isAxiosError } from "../../utilities/typeGuard";
import PostEdit from "./news/postEdit";
import PostView from "./news/postView";

const Post: FC<ScreenProps> = ({ title }) => {
  const history = useHistory();

  const { id } = useParams<ParamTypes>();
  const isNewPost = isNew(id);

  const { hasPermission } = UserStore.useContainer();
  const canUpsert = hasPermission("post.create");

  const [{ data, isLoading, isError }, setUrl, setData] = useApi<PostType>("", initPost);
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    if (!isNewPost) setUrl(postUrl(id));
    else if (canUpsert) setEditing(true);
    else history.replace(getPath("post"));
  }, [id, setUrl]);

  const startEditing = () => setEditing(true);
  const stopEditing = () => setEditing(false);

  const handleSave = async (newPost: PostType) => {
    try {
      setLoading(true);
      const { data: nextPost } = await savePost({ ...newPost, id: data.id });
      message.success(_t("saved"));
      setData(nextPost);
      stopEditing();
    } catch (error) {
      const errorMessage = isAxiosError(error) ? error.response?.data?.message : null;
      message.error(errorMessage || _t("msg.unknown_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle backBtn={true} title={`${title}/${data.title || _t("new_post")}`} fluid />
      <PageBody>
        <Row gutter={24}>
          <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 14, offset: 5 }} span={24}>
            <CollapseCard loading={isLoading || loading} title={isNew(id) ? _t("new_post") : data.title}>
              {isError && <Result status="error" title={_t("msg.unknown_error")} />}

              {editing ? (
                <PostEdit onSave={handleSave} onCancel={stopEditing} post={data} />
              ) : (
                <PostView startEditing={startEditing} post={data} canEdit={canUpsert} />
              )}
            </CollapseCard>
          </Col>
        </Row>
      </PageBody>
    </>
  );
};

export default Post;
