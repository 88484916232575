import http from "./httpService";
import { GetStatusesType, IdType, StatusesType } from "../types/appTypes";
import { getApiQuery } from "./urlQueryService";
import getApiEndpoint from "./apiEndpoint";
import { UserType } from "../types/userTypes";
import _t from "../lang/translate";
import { geStatus } from "./enumService";

const apiEndpoint = `${getApiEndpoint()}/user`;

export function userUrl(id: string | number) {
  return `${apiEndpoint}/${id}`;
}

export function usersUrl(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function saveUser(data: UserType) {
  if (data.id !== 0) return http.put(userUrl(data.id), data);

  return http.post(apiEndpoint, data);
}

export function getUser(id: IdType) {
  return http.get(userUrl(id));
}

export function deleteUser(id: IdType) {
  return http.delete(userUrl(id));
}

export function resetPassword(id: IdType, data: any) {
  return http.put(`${userUrl(id)}/updatePassword`, data);
}

export function banUser(id: IdType, status: boolean) {
  return http.put(`${userUrl(id)}/ban`, { status });
}

// Route::put('/api/v1/user/{user}/termsAccepted
export function termsAccepted(id: IdType, data: ("tax" | "import" | "leasing")[]) {
  return http.put(`${userUrl(id)}/termsAccepted`, { items: data });
}


// Filter statuses on table page:
export const getUserStatuses: GetStatusesType = () => {
  let results: StatusesType = {
    all: { id: -1, title: _t("all") },
  };
  const active = geStatus(8);
  if (active) results = { ...results, active };
  const inactive = geStatus(17);
  if (inactive) results = { ...results, inactive };
  return results;
};
