import { FC, useEffect, useState } from "react";
import { Badge, Col, Divider, Row, Space, Spin } from "antd";
import _t from "../../lang/translate";
import { DateSegmentType, StatisticItemType } from "../../types/statisticsType";
import CardBox, { CardBoxProps } from "../cardBox";
import VerticalLabelValue from "../verticalLabelValue";
import StatisticSegmentSelect from "./statisticSegmentSelect";
import { segmentKey } from "../../services/urlQueryService";
import { useApi } from "../../services/useApi";
import StatisticErrorResult from "./statisticErrorResult";
import Icon from "../../assets/icons/icon";
import DividerLine from "../lineSplit";

interface StatisticLiveDataProps extends CardBoxProps {
  fetchEndpoint: string;
}

type DataType = {
  global: StatisticItemType[];
  user: StatisticItemType[];
};

const initData: DataType = {
  global: [],
  user: [],
};

const StatisticLiveData: FC<StatisticLiveDataProps> = ({ fetchEndpoint, ...rest }) => {
  const [segment, setSegment] = useState<DateSegmentType>("month");
  const [{ data, isLoading, isError }, setUrl] = useApi<DataType>("", initData);

  useEffect(() => {
    const url = new URL(fetchEndpoint);
    url.searchParams.set(`filter[${segmentKey}]`, segment);
    setUrl(url.toString());
  }, [fetchEndpoint, setUrl, segment]);

  return (
    <CardBox bg="bg-light-primary" {...rest}>
      <Spin spinning={isLoading}>
        <Row justify="space-between" className="mb-1">
          <Col>
            <Badge status="processing" text={<span className="text-primary text-large">{_t("live")}</span>} />
          </Col>
          <Col>
            <StatisticSegmentSelect segment={segment} onChange={setSegment} />
          </Col>
        </Row>
        {isError && <StatisticErrorResult />}
        <div className="text-center">
          <Space split={<Divider type="vertical" className="min-height-40 ml-05 mr-05" />}>
            {data.global?.map((item) => (
              <VerticalLabelValue
                key={item.label}
                label={item.label}
                value={item.value}
                labelClassName="text-smaller"
                className="text-center"
              />
            ))}
          </Space>
        </div>
        {data.user && (
          <>
            <DividerLine className="mt-05 mb-05" />
            <div className="text-right">
              <Space className="text-right">
                <Icon name="person-outline" />
                {data.user.map((item) => (
                  <div key={item.label}>
                    <span className="pr-05">{item.value}</span>
                    <label>{item.label}</label>
                  </div>
                ))}
              </Space>
            </div>
          </>
        )}
      </Spin>
    </CardBox>
  );
};

export default StatisticLiveData;
