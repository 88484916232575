import React, { FC, useState } from "react";
import { Button, Col, Form, message, Row, Typography } from "antd";
import ErrorMessage from "../../../../components/messages/errorMessage";
import SavedMessage from "../../../../components/messages/savedMessage";
import ServerSelectBox from "../../../../components/serverSelectBox";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { usersAutoComplete } from "../../../../services/autocompleteService";
import { updateCaseHandler } from "../../../../services/importService";
import { ImportCaseHandlerType } from "../../../../types/importTypes";

const ImportCaseHandler: FC = () => {
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const [loadingCaseHandler, setLoadingCaseHandler] = useState<boolean>();

  const handleUpdateCaseHandler = async (values: ImportCaseHandlerType) => {
    try {
      setLoadingCaseHandler(true);
      const { data } = await updateCaseHandler(importCase.id, values);
      message.success(<SavedMessage />);
      setImportCase(data);
    } catch ({ response }) {
      const { message: msg } = response?.data || {};
      message.error(<ErrorMessage message={msg} />);
    } finally {
      setLoadingCaseHandler(false);
    }
  };

  return (
    <Form<ImportCaseHandlerType>
      onFinish={handleUpdateCaseHandler}
      initialValues={{ caseHandlerId: importCase.caseHandlerId }}
      layout="vertical"
    >
      <Typography.Title level={5}>{_t("case_handler")}</Typography.Title>
      <Row align="bottom" gutter={12} className="flex-wrap">
        <Col flex="auto">
          <Form.Item name="caseHandlerId" className="mb-0">
            <ServerSelectBox
              placeholder={_t("case_handler")}
              apiUrl={usersAutoComplete("filter[product]=import&")}
              disabled={loadingCaseHandler}
            />
          </Form.Item>
        </Col>
        <Col>
          <Button loading={loadingCaseHandler} htmlType="submit" type="primary">
            {_t("save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ImportCaseHandler;
