import { Result } from "antd";
import debounce from "lodash/debounce";
import { FC, useCallback, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import _t from "../lang/translate";
import { getPath, getTitleByRoute } from "../routes/appRoutes";
import { caseListUrl } from "../services/miscService";
import { pageSizeKey, searchKey } from "../services/urlQueryService";
import { useApi } from "../services/useApi";
import { PaginationedData } from "../types/apiTypes";
import { IdType, LocationStateType, ModulTypes } from "../types/appTypes";
import { CaseListType } from "../types/miscTypes";
import consts from "../utilities/consts";
import StatusTag from "./statusTag";
import DataTable from "./table/dataTable";
import { CustomColumnsType } from "./table/dataTableTypes";

interface CaseListProps {
  context: ModulTypes;
  id: IdType;
}

const CaseList: FC<CaseListProps> = ({ context, id }) => {
  const location = useLocation<LocationStateType>();
  const [{ data, isLoading, isError }, setUrl] = useApi<PaginationedData<CaseListType>>("", { data: [] });

  // If doing as it recommends, table search stops working
  const delayedSetUrl = useCallback(
    debounce((url: string) => {
      setUrl(url);
    }, 200), [setUrl]
  );

  useEffect(() => {
    if (!context || !id) return;
    const query = new URLSearchParams(location.search);
    query.set(pageSizeKey, localStorage.getItem(pageSizeKey) || "10");
    const url = caseListUrl(context, id, query.toString());
    query.has(searchKey) ? delayedSetUrl(url) : setUrl(url);
  }, [delayedSetUrl, setUrl, location.search, context, id]);

  let columns: CustomColumnsType<CaseListType> = [
    {
      key: "id",
      dataIndex: "id",
      title: "#",
      width: consts.idColumnWidth,
      render: (id, record) => (record.context ? <Link to={getPath(record.context, record.id)}>{id}</Link> : id),
    },
    {
      key: "title",
      dataIndex: "title",
      width: 200,
      title: _t("title"),
      ellipsis: true,
    },
    {
      key: "context",
      dataIndex: "context",
      width: 120,
      title: _t("product"),
      render: (context, record) => getTitleByRoute(record.context) || context,
    },
    {
      key: "statusId",
      dataIndex: "statusId",
      title: _t("status"),
      width: 150,
      hidden: true,
      render: (statusId) => <StatusTag statusId={statusId} />,
    },
    {
      key: "handler",
      dataIndex: "handler",
      width: 180,
      title: _t("case_handler"),
    },
  ];

  if (context !== "customer") {
    columns.splice(3, 0, {
      key: "client",
      dataIndex: "client",
      width: 120,
      title: _t("customer"),
    });
  }

  return isError ? (
    <Result status="error" title={_t("msg.unknown_error")} className="p-1 result-small" />
  ) : (
    <DataTable
      scroll={{ x: 700 }}
      loading={isLoading}
      rowKey="id"
      columns={columns}
      dataSource={data.data}
      meta={data?.meta}
    />
  );
};

export default CaseList;
