import { Button, Image } from "antd";
import { FC } from "react";
import CardBox from "../../../components/cardBox";
import Details from "../../../components/detailsList";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import { getEnumTitleById } from "../../../services/enumService";
import format from "../../../utilities/formatNumbers";

interface DealerInfoViewProps {
  startEditing: () => void;
}

const DealerInfoView: FC<DealerInfoViewProps> = ({ startEditing }) => {
  const { dealer } = DealerStore.useContainer();
  const { cvr, name, contactPerson, contactPersonLeasing, address, bookKeepingEmail, escalationEmail, city, email, logoPositionId, phone, zip, logoFile } =
    dealer;
  const logoPosition = logoPositionId ? getEnumTitleById(logoPositionId, ["dealer", "logoPosition"]) : "-";
  const logo = logoFile?.thumbUrl ? <Image src={logoFile.thumbUrl} width={50} /> : "-";
  return (
    <>
      <Details borderLess>
        <Details.Item label={_t("cvr")} value={cvr || "-"} className="mb-1" />
        <Details.Item label={_t("name")} value={name || "-"} />
        <Details.Item label={_t("contact_person")} value={contactPerson || "-"} className="mb-1" />
        <Details.Item label={_t("contact_person_leasing")} value={contactPersonLeasing || "-"} className="mb-1" />
        <Details.Item label={_t("address")} value={address || "-"} />
        <Details.Item label={_t("zip", "city", " & ")} value={format.text(zip, city)} className="mb-1" />
        <Details.Item label={_t("phone")} value={phone || "-"} />
        <Details.Item label={_t("email")} value={email || "-"} />
        <Details.Item label={_t("bookKeeper", "email")} value={bookKeepingEmail || "-"} />
        <Details.Item label={_t("reminder_email")} value={escalationEmail || "-"} />
      </Details>
      <CardBox>
        <Details borderLess>
          <Details.Item label={_t("logo")} value={logo} />
          <Details.Item label={_t("logo_position")} value={logoPosition} />
        </Details>
      </CardBox>
      <div className="text-right">
        <Button type="link" className="pl-0 pr-0 mt-1" onClick={startEditing}>
          {_t("edit")}
        </Button>
      </div>
    </>
  );
};

export default DealerInfoView;
