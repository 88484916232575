import { FC, useEffect } from "react";
import format from "../../../../utilities/formatNumbers";

interface TaxCalculationTotalFieldProps {
  input: number[];
  value?: number;
  onChange?: (value: number) => void;
}

const TaxCalculationTotalField: FC<TaxCalculationTotalFieldProps> = ({ input, value = 0, onChange }) => {
  useEffect(() => {
    const total = input.reduce((acc, n) => acc + n, 0);
    onChange && onChange(total);
  }, [input, onChange]);

  return <strong className="text-dark ml-05">{format.price(value || 0)}</strong>;
};

export default TaxCalculationTotalField;
