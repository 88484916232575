import { UploadFile, UploadProps } from "antd/es/upload/interface";
import { UploadChangeParam } from "antd/lib/upload";
import { FC } from "react";
import { isUploadFile } from "../../utilities/convertTypes";
import UploadButton from "../uploadButton";
import UploadFileRender from "../uploadFileRender";

interface UploadButtonFormProps extends Omit<UploadProps, "onChange" | "multiple"> {
  value?: UploadFile;
  onChange?: (newValue: UploadFile | null) => void;
}

const UploadButtonForm: FC<UploadButtonFormProps> = ({ value, onChange, ...rest }) => {
  const handleRemove = () => onChange && onChange(null);
  const handleFileChange = ({ fileList }: UploadChangeParam<any>) => {
    onChange && onChange(fileList[0]);
  };

  return !!value && isUploadFile(value) && value.status === "done" ? (
    <UploadFileRender file={value} onRemove={handleRemove} />
  ) : (
    <UploadButton {...rest} onChange={handleFileChange} fileList={value ? [value] : []} />
  );
};

export default UploadButtonForm;
