import http from "./httpService";
import { IdType } from "../types/appTypes";
import { getApiQuery } from "./urlQueryService";
import getApiEndpoint from "./apiEndpoint";
import { MerchantType } from "../types/merchantTypes";

const apiEndpoint = `${getApiEndpoint()}/merchant`;

export function merchantUrl(id: string | number) {
    return `${apiEndpoint}/${id}`;
}

export function merchantsUrl(query?: string) {
    return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function saveMerchant(data: MerchantType) {
    if (data.id !== 0) return http.put(merchantUrl(data.id), data);

    return http.post(apiEndpoint, data);
}

export function addMerchantContact<T>(id: IdType, data: T) {
    return http.post<T>(`${merchantUrl(id)}/addContactPerson`, data);
}

export function getMerchant(id: IdType) {
    return http.get<MerchantType>(merchantUrl(id));
}

export function deleteMerchant(id: IdType) {
    return http.delete(merchantUrl(id));
}

export function deleteContactPerson(id: IdType, contactPersonId: number) {
    return http.delete(`${merchantUrl(id)}/contactPerson/${contactPersonId}`);
}

export function downloadDocumentationUrl(id: IdType | string) {
    return http.get<{ url: string }>(`${merchantUrl(id)}/downloadDocumentation`);
}

export const initMerchant: MerchantType = {
    id: 0,
    name: null,
    address: null,
    zip: null,
    city: null,
    countryId: 276,
    vatNumber: null,
    country: null,
    email: null,
    phone: null,
    bank: null,
    iban: null,
    bic: null,
    createdCasesAmount: null,
    completedCasesAmount: null,
    acceptedCasesAmount: null,
    problematicCasesAmount: null,
    rating: null,
    note: null,
    contactPersons: null,
    cases: null,
};
