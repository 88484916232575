import { Button, Col, Dropdown, Menu, Row, Space, Tag, Tooltip } from "antd";
import React, { FC, useState } from "react";
import { useHistory } from "react-router";
import Icon from "../../../assets/icons/icon";
import ActivityDrawer from "../../../components/activityDrawer";
import openModal from "../../../components/modal/openModal";
import StatusTag from "../../../components/statusTag";
import TaxDocCaseStore from "../../../contexts/taxDocCaseStore";
import UserStore from "../../../contexts/userStore";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { LocationStateType } from "../../../types/appTypes";
import { TaxDocCase } from "../../../types/taxDocTypes";
import TaxDocCaseDealer from "./admin/taxDocCaseDealer";

const TaxDocCaseHeadExtra: FC = () => {
  const history = useHistory<LocationStateType>();
  const { taxDocCase, setTaxDocCase } = TaxDocCaseStore.useContainer();
  const { hasPermission } = UserStore.useContainer();
  const [drawerVisibility, setDrawerVisibility] = useState<boolean>(false);
  const isAdmin = hasPermission("taxDoc.isAdmin");
  const canCreateLeasing = hasPermission("leasing.create");
  const canCreatePartnerLeasing = hasPermission("partner_leasing.create");

  const handleCreateLeasing = async () => {
    try {
      const { data } = await openModal("taxToLeasingCase", {
        id: taxDocCase.id,
        from: "tax",
      });
      if (data)
        history.push({
          pathname: getPath("leasing", "new"),
          state: { from: { pathname: "tax" }, data },
        });
    } catch (e) {
    }
  };
  const handleCreatePartnerLeasing = async () => {
    try {
      const { data } = await openModal("taxToLeasingCase", {
        id: taxDocCase.id,
        from: "tax",
      });
      if (data)
        history.push({
          pathname: getPath("partnerLeasing", "new"),
          state: {
            from: { pathname: "tax" },
            data: { ...data, partnerCase: true },
          },
        });
    } catch (e) {
    }
  };
  const exportMenu = (
    <Menu>
      {/* <Menu.Item onClick={handleCloneCase}>
        {_t("clone_case")}
      </Menu.Item> */}

      {canCreateLeasing && (
        <Menu.Item key="1" onClick={handleCreateLeasing}>
          {_t("leasing")}
        </Menu.Item>
      )}
      {canCreatePartnerLeasing && (
        <Menu.Item key="2" onClick={handleCreatePartnerLeasing}>
          {_t("partner_leasing")}
        </Menu.Item>
      )}
    </Menu>
  );

  const handleStatusChange = async () => {
    try {
      const { id, status } = taxDocCase;
      const newCase = await openModal<TaxDocCase>("changeStatus", {
        id,
        context: "taxDoc",
        initialValues: {
          id: status?.id,
          forcedStatus: taxDocCase.flags.forcedStatus,
        },
      });
      setTaxDocCase(newCase);
    } catch (error) {
    }
  };

  return (
    <Row align="middle">
      <Col flex="auto">{isAdmin && <TaxDocCaseDealer />}</Col>
      <Col>
        <Space>
          {taxDocCase.flags.isPriority && (
            <Tooltip title={_t("high_priority")}>
              <Tag color="error" className="icon-tag">
                <Icon name="alert-outline" />
              </Tag>
            </Tooltip>
          )}
          {isAdmin ? (
            <StatusTag locked={taxDocCase.flags.forcedStatus} status={taxDocCase.status} onClick={handleStatusChange} />
          ) : (
            <StatusTag status={taxDocCase.status} />
          )}

          {(canCreateLeasing || canCreatePartnerLeasing) && (
            <Dropdown overlay={exportMenu}>
              <Button>{_t("move", "to")}</Button>
            </Dropdown>
          )}

          {isAdmin && (
            <>
              <Button onClick={() => setDrawerVisibility(true)}>{_t("activities", "note", " & ")}</Button>
              <ActivityDrawer
                visible={drawerVisibility}
                isAdmin={isAdmin}
                setVisibility={setDrawerVisibility}
                type="taxDoc"
                id={taxDocCase.id}
              />
            </>
          )}

          {/* {hasPermission("taxDoc.checklist") && (
            <Checklist
              {...{
                isAdmin,
                id: taxDocCase.id,
                context: "tax",
                btnText: _t("checklist"),
              }}
            />
          )} */}
        </Space>
      </Col>
    </Row>
  );
};

export default TaxDocCaseHeadExtra;
