import { FC } from "react";
import Details from "../../../components/detailsList";
import LeasingStore from "../../../contexts/leasingStore";
import _t from "../../../lang/translate";
import { ContactPersonsKeyType } from "../../../types/leasingTypes";

interface ContactPersonFormFieldsProps {
  contactPersonKey: ContactPersonsKeyType;
}

const LeasingContactPersonsInfo: FC<ContactPersonFormFieldsProps> = ({ contactPersonKey: key }) => {
  const { leasingCase } = LeasingStore.useContainer();
  const contactPerson = leasingCase[key];

  return (
    <Details borderLess>
      <Details.Divider>{_t(key === "legalOwner" ? "legal_owner" : "contact_person")}</Details.Divider>
      <Details.Item label={_t("name")} value={contactPerson?.name || "-"} />
      <Details.Item label={_t("email")} value={contactPerson?.email || "-"} />
      <Details.Item label={_t("phone")} value={contactPerson?.phone || "-"} />
    </Details>
  );
};

export default LeasingContactPersonsInfo;
