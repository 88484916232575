import { FC, useEffect } from "react";
import { Result } from "antd";
import { useParams } from "react-router-dom";
import _t from "../../lang/translate";
import PageTitle from "../../layout/pageTitle";
import { isNew } from "../../routes/appRoutes";
import { customerUrl, getInitCustomer } from "../../services/customerService";
import { useApi } from "../../services/useApi";
import { ParamTypes, ScreenProps } from "../../types/appTypes";
import { CustomerType } from "../../types/customerTypes";
import CustomerProspectId from "./customer/customerProspectId";
import CustomerStore from "../../contexts/customerStore";
import PageBody from "../../layout/pageBody";
import AnimateIn from "../../components/animateIn";
import CustomerView from "./customer/customerView";

const Customer: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading, isError }, setUrl] = useApi<CustomerType>("", getInitCustomer());

  useEffect(() => {
    if (!isNew(id)) setUrl(customerUrl(id));
  }, [id, setUrl]);

  return (
    <CustomerStore.Provider initialState={data}>
      <PageTitle title={`${title}/${id}`} fluid backBtn extra={<CustomerProspectId />} />
      <PageBody>
        {isError && <Result status="error" title={_t("msg.unknown_error")} />}
        <AnimateIn start={!isLoading} loadingBeforeStart>
          <CustomerView />
        </AnimateIn>
      </PageBody>
    </CustomerStore.Provider>
  );
};

export default Customer;
