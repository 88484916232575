import { Tabs } from "antd";
import { FC } from "react";
import PageBody from "../layout/pageBody";
import PageTitle from "../layout/pageTitle";
import { ScreenProps } from "../types/appTypes";
import SystemChecklistDefault from "./system/systemChecklistDefault";
import SystemConfigValue from "./system/systemConfigValue";
import SystemUserResponsibilities from "./system/systemUserResponsibilities";
import _t from "../lang/translate";
import MonthlySpecification from "./system/monthlySpecification";

const { TabPane } = Tabs;

const System: FC<ScreenProps> = ({ title }) => {
  return (
    <>
      <PageTitle title={title} fluid className="mb-0 pb-3" />
      <PageBody>
        <Tabs className="system-tabs-top">
          <TabPane tab={_t("products")} key={'products'} forceRender={true} disabled={false}>
            <SystemConfigValue />
          </TabPane>
          <TabPane tab={_t("checklists")} key={'checklists'} forceRender={true} disabled={false}>
            <SystemChecklistDefault />
          </TabPane>
          <TabPane tab={_t("monthlySpecification")} key={'monthlySpecification'} forceRender={true} disabled={false}>
            <MonthlySpecification />
          </TabPane>
          <TabPane tab={_t("responsibilities")} key={'responsibilities'} forceRender={true} disabled={false}>
            <SystemUserResponsibilities />
          </TabPane>
        </Tabs>
      </PageBody>
    </>
  );
};

export default System;
