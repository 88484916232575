import _values from "lodash/values";

type RecurFunc = (array: any[], n: number) => Array<any>;

const getCombinations: RecurFunc = (collection, n) => {
  let array: Array<any> = _values(collection);
  if (array.length < n) {
    return [];
  }
  let recur: RecurFunc = (array, n) => {
    if (--n < 0) {
      return [[]];
    }
    let combinations: Array<any> = [];
    array = array.slice();
    while (array.length - n) {
      let value = array.shift();
      recur(array, n).forEach((combination) => {
        combination.unshift(value);
        combinations.push(combination);
      });
    }
    return combinations;
  };
  return recur(array, n);
};

export default getCombinations;
