export const compare = (data1: { [key: string]: any }, data2: { [key: string]: any }, exactMatch?: boolean) => {
  // clean objects form undefiend values:
  const obj1 = cleanObject(data1);
  const obj2 = cleanObject(data2);

  //Loop through properties in object 1
  for (var p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    // Make sure that empty objects and null are equal {} === null:
    if (obj1[p] === null && obj2[p] !== null && !isEmptyObject(obj2[p])) return false;
    if (obj2[p] === null && obj1[p] !== null && !isEmptyObject(obj1[p])) return false;

    switch (typeof obj1[p]) {
      //Deep compare objects
      case "object":
        if (Array.isArray(obj1[p]) && Array.isArray(obj2[p]) && obj1[p].length !== obj2[p].length) return false;
        if (!compare(obj1[p], obj2[p], exactMatch)) return false;
        break;
      //Compare function code
      case "function":
        if (typeof obj2[p] == "undefined" || (p !== "compare" && obj1[p].toString() !== obj2[p].toString()))
          return false;
        break;

      case "string":
        if (obj1[p] === "" && obj2[p] !== "") return false;
        if (obj2[p] === "" && obj1[p] !== "") return false;
        if (obj1[p].localeCompare(obj2[p])) return false;
        break;
      //Compare values
      default:
        if (obj1[p] !== obj2[p]) return false;
    }
  }

  //Check object 2 for any extra properties
  if (exactMatch)
    for (var q in obj2) {
      if (typeof obj1[q] === "undefined") return false;
    }
  return true;
};

const isEmptyObject = (obj: any) => obj && typeof obj === "object" && Object.keys(obj).length === 0;

const cleanObject = (obj: { [key: string]: any }) => {
  // stringify removes the undefiend values in an object:
  const strObj = JSON.stringify(obj);
  return JSON.parse(strObj) as { [key: string]: any };
};
