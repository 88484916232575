import { FC } from "react";
import { InputPriceProps } from "../../../components/form/inputNum";
import InputPrice from "../../../components/form/inputPrice";

interface TaxationFieldProps extends InputPriceProps {
  vehicleAge?: number | null;
}

const TaxationField: FC<TaxationFieldProps> = ({ vehicleAge, value, onChange, disabled, ...rest }) => {
  /* useEffect + disabled attr is now OFF, as we want the dealer to put a number in all cases - toc@dmf - 20-01-2023
  useEffect(() => {
    if (vehicleAge && vehicleAge >= 36 && !!value && onChange) onChange(0);
  }, [onChange, value, vehicleAge]);
   */

  return (
    <>
      <InputPrice
        onChange={onChange}
        value={value || undefined}
        //disabled={(vehicleAge && vehicleAge >= 36) || disabled}
        {...rest}
      />
    </>
  );
};

export default TaxationField;
