import { RadioChangeEvent } from "antd";
import React, { FC, useState } from "react";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t, { _lt } from "../../../../lang/translate";
import TaxOfferBaseInfo from "./taxOfferBaseInfo";
import TaxOfferInfo from "./taxOfferInfo";
import TaxDocumentationInfo from "./taxDocumentationInfo";
import DividerLine from "../../../../components/lineSplit";
import TaxRecalculateInfo from "./TaxRecalculateInfo";

const TaxOfferForm: FC = () => {
  const { taxCase } = TaxCaseStore.useContainer();
  const { documentationBought, expired } = taxCase.flags;

  const selectedProduct = expired
    ? 3
    : documentationBought
      ? 2
      : 1;


  return (
    <>
      <TaxOfferBaseInfo />

      <DividerLine fluid />

      <h4 className="text-lighter mb-1">
        {_t('your', 'price')}
      </h4>

      {selectedProduct === 1 ? <TaxOfferInfo /> : null}
      {selectedProduct === 2 ? <TaxDocumentationInfo /> : null}
      {selectedProduct === 3 ? <TaxRecalculateInfo /> : null}
    </>
  );
};

export default TaxOfferForm;
