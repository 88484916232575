import { FC, useState } from "react";
import { Button, ButtonProps, message } from "antd";
import LeasingStore from "../../../../contexts/leasingStore";
import _t from "../../../../lang/translate";
import { unGenerateContracts } from "../../../../services/leasingService";
import SavedMessage from "../../../../components/messages/savedMessage";
import ErrorMessage from "../../../../components/messages/errorMessage";

const OpenContractsButton: FC<ButtonProps> = (props) => {
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const { contractGenerated, readonly } = leasingCase.flags;
  const disabled = !contractGenerated || readonly;

  const handleOpenContract = async () => {
    try {
      setLoading(true);
      const { data } = await unGenerateContracts(leasingCase.id);
      message.success(<SavedMessage />);
      setLeasingCase({ ...leasingCase, ...data });
    } catch ({ response }) {
      message.error(<ErrorMessage message={response?.data?.message} />);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Button {...props} loading={loading} disabled={disabled} onClick={handleOpenContract}>
      {_t("open_contract")}
    </Button>
  );
};

export default OpenContractsButton;
