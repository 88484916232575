import React, { FC, useState } from "react";
import CollapseCard from "../../../../components/collapseCard";
import LogText from "../../../../components/logText";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import ImportVehicleInfo from "../importVehicleInfo";
import ImportVehicleForm from "./importVehicleForm";
import ImportVehicleInfoEditiable from "./importVehicleInfoEditiable";

const ImportVehicleEditableCard: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  return (
    <>
      <CollapseCard title={_t("vehicle")} loading={loading}>
        {isEditing ? (
          <ImportVehicleForm setLoading={setLoading} stopEditing={stopEditing} />
        ) : (
          <ImportVehicleInfoEditiable startEditing={startEditing} />
        )}
      </CollapseCard>
      {importCase.log?.creation && (
        <LogText className="p-0 text-right mt--1  mb-1" small logData={importCase.log.creation} />
      )}
    </>
  );
};

export default ImportVehicleEditableCard;
<ImportVehicleInfo />;
