import { FC } from "react";
import CollapseCard from "../../../components/collapseCard";
import DividerLine from "../../../components/lineSplit";
import UserPageStore from "../../../contexts/userPageStore";
import _t from "../../../lang/translate";
import UserAdminTools from "./userAdminTools";
import UserResetPassword from "./userResetPassword";

const UserPageActions: FC = () => {
  const { user, loading, isAdmin, isNew } = UserPageStore.useContainer();

  return (
    <CollapseCard title={_t("actions")} loading={loading} disable={isNew} defaultOpen={!isNew}>
      <UserResetPassword userId={user.id} />
      {isAdmin && (
        <>
          <DividerLine />
          <UserAdminTools />
        </>
      )}
    </CollapseCard>
  );
};

export default UserPageActions;
