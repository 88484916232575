import React, { FC, ReactText, useState } from "react";
import { Typography, Tooltip, Space } from "antd";
import { SelectValue } from "antd/lib/select";
import { useHistory } from "react-router-dom";
import Icon from "../../assets/icons/icon";
import StatusSelect from "../../components/form/statusSelect";
import _t from "../../lang/translate";
import { statusKey, paid, uploaded } from "../../services/urlQueryService";

const TaxTableFilter: FC = () => {
  const history = useHistory();
  const location = history.location;
  const queryParams = new URLSearchParams(location.search);
  const initStatusFilter = new URLSearchParams(location.search).get(statusKey) as ReactText;
  const [isMissingCardFilter, setIsMissingCardFilter] = useState(queryParams.get(paid) || false);
  const [isMissingDocumentsFilter, setIsMissingDocumentsFilter] = useState(queryParams.get(uploaded) || false);

  const onStatusFilterChange = (id: SelectValue) => {
    const query = new URLSearchParams(location.search);
    if (!id) query.delete(statusKey);
    else query.set(statusKey, id.toString());

    history.push({
      ...history.location,
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const setHistory = (flag: boolean, key: string, value: string) => {
    const query = new URLSearchParams(location.search);

    if (!flag) {
      query.delete(key);
    } else {
      query.set(key, value);
    }

    history.push({
      ...history.location,
      pathname: location.pathname,
      search: query.toString(),
    });
  }

  const onCardIconClick = () => {
    const flag = !isMissingCardFilter;
    const key = paid;
    const value = 0;

    setIsMissingCardFilter(flag);
    setHistory(flag, key, value.toString());
  }

  const onDocIconClick = () => {
    const flag = !isMissingDocumentsFilter;
    const key = uploaded;
    const value = 0;

    setIsMissingDocumentsFilter(flag);
    setHistory(flag, key, value.toString());
  }

  return (
    <>
      <Space align="center">
        <Typography.Text type={isMissingCardFilter ? "warning" : "secondary"}>
          <Tooltip title={_t("missingPaymentsFilter")}>
            <Icon name="card-outline" size="large" onClick={() => onCardIconClick()} style={{ display: 'block' }} />
          </Tooltip>
        </Typography.Text>

        <Typography.Text type={isMissingDocumentsFilter ? "warning" : "secondary"}>
          <Tooltip title={_t("missingDocumentsFilter")}>
            <Icon name="documents-outline" size="large" onClick={() => onDocIconClick()} style={{ display: 'block' }} />
          </Tooltip>
        </Typography.Text>

        <StatusSelect
          defaultValue={initStatusFilter !== null ? Number(initStatusFilter) : undefined}
          showSearch={false}
          className="width-180"
          placeholder={_t("status", "filter")}
          allowClear
          onChange={(value) => onStatusFilterChange(value)}
          contexts={"tax"}
        />
      </Space>
    </>
  );
};

export default TaxTableFilter;
