import React, { FC, useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Divider, Form, Row, Select } from "antd";
import _t from "../../lang/translate";
import { Vehicle, VinVehicle } from "../../types/appTypes";
import Type from "./type";
import Fuel from "./fuel";
import Make from "./make";
import Model from "./model";
import Variant from "./variant";
import { SearchConfig } from "../../types/vehicleCatalogTypes";
import CollapseCard from "../../components/collapseCard";
import {
  brandsAutoComplete,
  fuelsAutoComplete,
  modelsAutoComplete,
  typesAutoComplete
} from "../../services/autocompleteService";
import ServerSelectBox from "../../components/serverSelectBox";
import InputVin from "../../components/form/inputVin";
import VehicleCatalogVehicleInfo from "../../components/vehicleInfoViews/vehicleCatalogVehicleInfo";
import AnimateIn from "../../components/animateIn";

interface SidebarProps {
  doSearch: (config: SearchConfig) => void;
  initialValues: SearchConfig;
  initialVin?: string;
}

interface CatalogProps {
  withActions: boolean;
  enableCreateBtn?: () => void;
  disableCreateBtn?: () => void;
  showCreateModal?: boolean;
  hideCreateModal?: () => void;
  priceSelectAction?: (price: number | null) => void;
  initialVin?: string;
  currentTab: string; //"variant" | "equipment" | "type" | "make" | "model" | "fuel";
}

const Sidebar: FC<SidebarProps> = ({ doSearch, initialValues, initialVin }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [vehicleInfo, setVehicleInfo] = useState<Vehicle | null>(null);
  const [itemForm] = Form.useForm<SearchConfig>()

  const handleSearch = async () => {
    setLoading(true);
    doSearch(itemForm.getFieldsValue());
    setLoading(false);
  }

  const handleReset = async () => {
    setLoading(true);
    itemForm.resetFields();
    setVehicleInfo(null);
    setLoading(false);

    await handleSearch();
  }

  const handleVinFetch = async (baseData: VinVehicle) => {
    setLoading(true);

    const { data } = baseData;
    const date = data.firstRegDate ? new Date(data.firstRegDate) : new Date();

    itemForm.setFieldsValue({
      vehicleModelId: data.modelId,
      vehicleFuelId: data.fuelId,
      vehicleMakeId: data.brandId,
      vehicleTypeId: data.typeId,
      year: date.getUTCFullYear(),
      onlyPriced: true,
    });
    setLoading(false);
    setVehicleInfo(data);

    await handleSearch();
  }

  const years = Array.from(Array(new Date().getFullYear() - 1969), (_, i) => (i + 1970).toString()).reverse()

  return (
    <CollapseCard
      title={_t("search_placeholder")}
      loading={loading}
    >
      <Form form={itemForm} onFinish={handleSearch} initialValues={{ ...initialValues, vin: initialVin }}>
        <Form.Item name={"vin"} label={_t("vin")}>
          <InputVin
            onFetched={handleVinFetch}
          />
        </Form.Item>

        <AnimateIn start={!!vehicleInfo}>
          {vehicleInfo && (
            <Card className="no-shadow">
              <VehicleCatalogVehicleInfo data={vehicleInfo} />
            </Card>
          )}
        </AnimateIn>

        <Divider />

        <Form.Item label={_t("type")} name="vehicleTypeId">
          <ServerSelectBox
            apiUrl={typesAutoComplete()}
            onSelect={() => itemForm.setFieldsValue({
              vehicleModelId: null,
              vehicleMakeId: null,
            })}
          />
        </Form.Item>

        <Form.Item shouldUpdate={(curr, next) => (
          curr.vehicleTypeId !== next.vehicleTypeId
        )} noStyle>
          {({ getFieldsValue }) => {
            const { vehicleTypeId } = getFieldsValue();
            return (
              <Form.Item name="year" label={_t('year')}>
                <Select
                  showSearch
                  placeholder={_t('choose')}
                  options={years.map(year => ({ "value": year, "label": year }))}
                  disabled={!vehicleTypeId}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item shouldUpdate={(curr, next) => (
          curr.vehicleTypeId !== next.vehicleTypeId || curr.year !== next.year
        )} noStyle>
          {({ getFieldsValue, setFieldsValue }) => {
            const { vehicleTypeId, year } = getFieldsValue();
            const brandApiUrl = brandsAutoComplete('', year, vehicleTypeId);
            return (
              <Form.Item label={_t("brand")} name="vehicleMakeId">
                <ServerSelectBox
                  urlSetDelay={1000}
                  apiUrl={brandApiUrl}
                  onSelect={() => setFieldsValue({
                    vehicleModelId: null,
                  })}
                  disabled={!year}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item shouldUpdate={(curr, next) => (
          curr.vehicleMakeId !== next.vehicleMakeId ||
          curr.vehicleTypeId !== next.vehicleTypeId ||
          curr.year !== next.year
        )} noStyle>
          {({ getFieldsValue }) => {
            const { vehicleMakeId, vehicleTypeId, year } = getFieldsValue();
            const modelApiUrl = modelsAutoComplete('', vehicleMakeId, year, vehicleTypeId);
            return (
              <Form.Item name="vehicleModelId" label={_t("model")}>
                <ServerSelectBox
                  apiUrl={modelApiUrl}
                  urlSetDelay={1000}
                  disabled={!vehicleMakeId}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item shouldUpdate={(curr, next) => (
          curr.vehicleModelId !== next.vehicleModelId
        )} noStyle>
          {({ getFieldsValue }) => {
            const { vehicleModelId } = getFieldsValue();
            return (
              <Form.Item label={_t("fuel")} name="vehicleFuelId">
                <ServerSelectBox
                  apiUrl={fuelsAutoComplete()}
                  disabled={!vehicleModelId}
                />
              </Form.Item>
            );
          }}
        </Form.Item>

        <Form.Item label={_t("only_priced")} name="onlyPriced" valuePropName="checked">
          <Checkbox />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ submit }) => (
            <Row justify="space-between">
              <Col>
                <Button onClick={handleReset}>{_t("reset")}</Button>
              </Col>
              <Col>
                <Button type="primary" onClick={submit}>
                  {_t("search")}
                </Button>
              </Col>
            </Row>
          )}
        </Form.Item>
      </Form>
    </CollapseCard>
  )
}

const VehicleCatalog: FC<CatalogProps> = ({
  currentTab,
  enableCreateBtn,
  disableCreateBtn,
  showCreateModal,
  hideCreateModal,
  initialVin,
  withActions,
  priceSelectAction
}) => {
  const [searchConfig, setSearchConfig] = useState<SearchConfig>({
    vehicleFuelId: null,
    vehicleModelId: null,
    vehicleTypeId: null,
    vehicleMakeId: null,
    year: null,
    vin: null,
    onlyPriced: true,
  });

  const doSearch = (config: SearchConfig) => {
    setSearchConfig(config);
  }

  useEffect(() => {
    const createEnabled = !!(searchConfig.vehicleTypeId && searchConfig.vehicleFuelId && searchConfig.vehicleMakeId && searchConfig.vehicleModelId && searchConfig.year);
    if (createEnabled) {
      enableCreateBtn && enableCreateBtn();
    } else {
      disableCreateBtn && disableCreateBtn();
    }
  }, [disableCreateBtn, enableCreateBtn, searchConfig])

  let tabItem;
  let withSidebar = false;
  switch (currentTab) {
    case 'variant':
      withSidebar = true;
      tabItem = <Variant showModal={!!showCreateModal}
        hideModal={() => hideCreateModal && hideCreateModal()}
        searchConfig={searchConfig}
        withActions={withActions}
        priceSelectAction={priceSelectAction}
      />;
      break;
    /* TODO: Disabled for now
  case 'equipment':
    withSidebar = true;
    tabItem = <Equipment  withActions={withActions} showModal={!!showCreateModal} hideModal={() => hideCreateModal && hideCreateModal()} searchConfig={searchConfig}/>;
    buttonTitle = _t("create", "equipment");
    break;
     */
    case 'type':
      tabItem = <Type
        withActions={withActions}
        showModal={!!showCreateModal}
        hideModal={() => hideCreateModal && hideCreateModal()} />;
      break;
    case 'make':
      tabItem = <Make
        withActions={withActions}
        showModal={!!showCreateModal}
        hideModal={() => hideCreateModal && hideCreateModal()} />;
      break;
    case 'model':
      tabItem = <Model
        withActions={withActions}
        showModal={!!showCreateModal}
        hideModal={() => hideCreateModal && hideCreateModal()} />;
      break;
    case 'fuel':
      tabItem = <Fuel
        withActions={withActions}
        showModal={!!showCreateModal}
        hideModal={() => hideCreateModal && hideCreateModal()} />;
      break;
    default:
      tabItem = null;
  }

  return (
    <Row gutter={16}>
      {withSidebar && (
        <Col span={7}>
          <Sidebar doSearch={doSearch} initialValues={searchConfig} initialVin={initialVin} />
        </Col>
      )}

      <Col span={withSidebar ? 17 : 24}>
        {tabItem}
      </Col>
    </Row>
  );
};

export default VehicleCatalog;
