import { FC, useEffect } from "react";
import { TaxCalcValueTypes } from "../../../../../types/taxCalcTableTypes";
import format from "../../../../../utilities/formatNumbers";
import { roundNum } from "../../../../../utilities/number";
import { isNum } from "../../../../../utilities/typeGuard";
import { getAverageOfEnabledVehicles } from "../../calc/tradePriceSubCalculations";

interface TaxaveragePriceCellProps {
  vehicles?: any[] | null;
  type: TaxCalcValueTypes;
  bold?: boolean;
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const TaxaveragePriceCell: FC<TaxaveragePriceCellProps> = ({ vehicles, type, bold, value, onChange }) => {
  useEffect(() => {
    try {
      const averageValue = getAverageOfEnabledVehicles(vehicles || [], type);
      onChange && onChange(roundNum(averageValue, 0));
    } catch (err: unknown) {
      onChange && onChange(0);
    }
  }, [vehicles, type]);

  return (
    <>{isNum(value) ? <span className={`text-dark${bold ? " text-bold" : ""}`}>{format.number(value)}</span> : "-"}</>
  );
};

export default TaxaveragePriceCell;
