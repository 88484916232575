import { Divider, DividerProps } from "antd";
import React, { FC } from "react";

export interface DividerLineProps extends DividerProps {
  fluid?: boolean;
  weight?: number;
  largeFluid?: boolean;
}

const DividerLine: FC<DividerLineProps> = ({ fluid, weight, style, largeFluid, type, ...rest }) => {
  let newStyle: React.CSSProperties = style || {};
  if (typeof weight === "number" && weight > 0 && weight < 10) {
    newStyle = {
      ...newStyle,
      borderWidth: weight,
    };
  }
  const containerStyle: React.CSSProperties =
    fluid && type !== "vertical" ? {
      marginLeft: largeFluid ? '-32px' : "-24px",
      marginRight: largeFluid ? '-32px' : "-24px"
    } : {};
  return (
    <div style={containerStyle}>
      <Divider style={newStyle} type={type} {...rest} />
    </div>
  );
};

export default DividerLine;
