export const accepted = [
  "application/x-abiword",
  "application/x-freearc",
  "application/vnd.amazon.ebook",
  "image/bmp",
  "application/x-bzip",
  "application/x-bzip2",
  "text/csv",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/epub+zip",
  "application/gzip",
  "image/gif",
  "image/vnd.microsoft.icon",
  "image/jpeg",
  "application/json",
  "application/ld+json",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "image/png",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.rar",
  "application/rtf",
  "image/svg+xml",
  "application/x-tar",
  "image/tiff",
  "text/plain",
  "application/vnd.visio",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/xml ",
  "text/xml",
  "application/vnd.mozilla.xul+xml",
  "application/zip",
  "application/x-7z-compressed",
];
