import React, { FC, useState } from "react";
import { Button, message } from "antd";
import Icon from "../../../../assets/icons/icon";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import { validateDealer } from "../../../../services/importService";

const ImportVATCheckBtn: FC = () => {
  const [loading, setLoading] = useState<boolean>();
  const { importCase, setImportCase } = ImportCaseStore.useContainer();
  const downloadIcon = <Icon size="large" name="download-outline" />;

  const downloadDealerValidation = async () => {
    try {
      setLoading(true);
      const { data } = await validateDealer(importCase.id);
      setImportCase(data)
    } catch (ex) {
      message.error(_t("msg.link_not_generated"));
    } finally {
      setLoading(false);
    }
  };


  return (
    <Button type="primary" ghost
      icon={downloadIcon}
      onClick={downloadDealerValidation} loading={loading}
    >
      {_t("vat_check")}
    </Button>
  );
};

export default ImportVATCheckBtn;
