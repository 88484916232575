import { Button, Col, Form, Row } from "antd";
import React, { FC } from "react";
import _t from "../../../../lang/translate";
import TaxDocArchive from "./taxDocArchive";

interface CalculationFormActionsProps {
}

const CalculationFormActions: FC<CalculationFormActionsProps> = ({ }) => {
  return (
    <Row justify="space-between">
      <Col>
        <TaxDocArchive />
      </Col>
      <Col>
        <Form.Item shouldUpdate noStyle>
          {({ submit }) => (
            <Button type="primary" onClick={submit}>
              {_t("save")}
            </Button>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CalculationFormActions;
