import { FC } from "react";
import { Form } from "antd";
import InputPrice from "../../../components/form/inputPrice";
import _t from "../../../lang/translate";
import LeasingStore from "../../../contexts/leasingStore";

const LeasingDealAdvanced: FC = () => {
  const { leasingCase } = LeasingStore.useContainer();
  const { flags } = leasingCase;
  const { readonly, inputLocked } = flags;
  const disabled = readonly || inputLocked;

  return (
    <Form.Item label={_t("costs")} name={["input", "dealerProvision"]}>
      <InputPrice disabled={disabled} />
    </Form.Item>
  );
};

export default LeasingDealAdvanced;
