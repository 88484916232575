import { FC } from "react";
import CaseHeadClient from "../../../../components/caseHeadClient";
import openModal from "../../../../components/modal/openModal";
import LeasingStore from "../../../../contexts/leasingStore";
import { Client } from "../../../../types/appTypes";

const LeasingCaseDealer: FC = () => {
  const { leasingCase, setLeasingCase } = LeasingStore.useContainer();
  const { client, id } = leasingCase;

  const handleClientChange = async () => {
    try {
      const newClient = await openModal<Client>("changeClient", {
        id,
        client,
        context: "leasing",
      });
      setLeasingCase({ ...leasingCase, client: newClient });
    } catch (ex) {
    }
  };

  return <CaseHeadClient onClientChange={handleClientChange} client={client} hideEditButton={!leasingCase.id} />;
};

export default LeasingCaseDealer;
