import React, { FC, useState } from "react";
import { Collapse, Form } from "antd";
import CustomCollapse from "../../../../components/customCollapse";
import NewPriceEquipmentsTotal from "./newPriceEquipmentsTotal";
import NewPriceEquipmentsList from "./newPriceEquipmentsList";

const { Panel } = Collapse;

const NewPriceEquipments: FC = () => {
  const [collapseKeys, setCollapseKeys] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  return (
    <CustomCollapse activeKey={collapseKeys} setActiveKey={setCollapseKeys}>
      <Panel key="1" header={<NewPriceEquipmentsTotal openModal={openModal} />} forceRender>
        <Form.Item name={["newPrice", "equipmentCalculator"]} noStyle>
          <NewPriceEquipmentsList isModalVisible={isModalVisible} closeModal={closeModal} />
        </Form.Item>
      </Panel>
    </CustomCollapse>
  );
};

export default NewPriceEquipments;
