import { FC } from "react";
import { Alert, Col, Row } from "antd";
import ResellerTaxVehicleEdit from "./resellerTaxVehicleEdit";
import ResellerTaxAttachments from "./../resellerTaxAttachments";
import ResellerTaxActions from "./resellerTaxActions";
import ResellerTaxInternalChecklist from "./resellerTaxInternalChecklist";
import ResellerTaxDetailsEdit from "./resellerTaxDetailsEdit";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";

const ResellerTaxCaseEdit: FC = () => {
  const { resellerTaxCase } = ResellerTaxCaseStore.useContainer();

  return (
    <Row align="top" gutter={24}>
      <Col span={24}>
      </Col>
      <Col
        xxl={{ span: 8, order: 1 }}
        xl={{ span: 12, order: 3 }}
        xs={{ span: 24, order: 3 }}
      >
        <ResellerTaxVehicleEdit />
      </Col>
      <Col
        xxl={{ span: 8, order: 2 }}
        xl={{ span: 12, order: 1 }}
        xs={{ span: 24, order: 1 }}
      >
        <ResellerTaxDetailsEdit />
      </Col>
      <Col
        xxl={{ span: 8, order: 3 }}
        xl={{ span: 12, order: 2 }}
        xs={{ span: 24, order: 2 }}
      >
        {resellerTaxCase.importantNote && (
          <Alert
            className="mb-2"
            message={resellerTaxCase.importantNote}
            type="warning"
            closable
          />
        )}
        <ResellerTaxActions />
        <ResellerTaxInternalChecklist />
        <ResellerTaxAttachments />
      </Col>
    </Row>
  );
};

export default ResellerTaxCaseEdit;
