import { FC } from "react";
import Details from "../../../components/detailsList";
import DividerLine from "../../../components/lineSplit";
import _t from "../../../lang/translate";
import { getCustomerName, getInitCustomer, isPrivateCustomer } from "../../../services/customerService";
import { getEnumTitleById } from "../../../services/enumService";
import { CustomerType } from "../../../types/customerTypes";
import format from "../../../utilities/formatNumbers";

interface CustomerDetailsProps {
  customer?: CustomerType | null;
  hideContactPersons?: boolean;
  hideCpr?: boolean;
  hidePBS?: boolean;
}

const CustomerDetails: FC<CustomerDetailsProps> = ({
  customer = getInitCustomer(),
  hideContactPersons = false,
  hideCpr = false,
  hidePBS = false,
}) => {
  const { cvr, cpr, email, phone, address, zip, city, pbsRegNumber, pbsAccountNumber, typeId, type, contactPersons } =
    customer || {};
  const isPrivate = isPrivateCustomer(typeId);
  const formatedType = type || (typeId ? getEnumTitleById(typeId, ["customer", "type"]) : "-");

  return (
    <>
      <Details borderLess className="m-0">
        <Details.Item label={_t("type")} value={formatedType} className="mb-1" />
        <Details.Item label={_t("name")} value={customer ? getCustomerName(customer) : "-"} />
        {isPrivate ? (
          !hideCpr && <Details.Item label={_t("cpr")} value={format.text(cpr)} />
        ) : (
          <Details.Item label={_t("cvr")} value={format.text(cvr)} />
        )}
        <Details.Item label={_t("address")} value={format.text(address)} />
        <Details.Item label={_t("zip", "city", " & ")} value={format.text(zip, city)} className="mb-1" />

        <Details.Item label={_t("email")} value={format.text(email)} />
        <Details.Item label={_t("phone")} value={format.text(phone)} className="mb-1" />

        <Details.Item hidden={hidePBS} label={_t("PBS_reg_nr")} value={format.text(pbsRegNumber)} />
        <Details.Item hidden={hidePBS} label={_t("PBS_account_nr")} value={format.text(pbsAccountNumber)} />
      </Details>
      {!hideContactPersons && !isPrivate && (
        <>
          <DividerLine orientation="left">
            <label className="text-normal text-small">{_t("contact_persons")}</label>
          </DividerLine>
          {!contactPersons?.length && <small>{_t("msg.no_contact_persons_added")}</small>}
          {contactPersons?.map((cp, i) => (
            <Details borderLess key={cp.id}>
              <Details.Item label={_t("name")} value={cp.name || "-"} />
              <Details.Item label={_t("email")} value={cp.email || "-"} />
              <Details.Item label={_t("phone")} value={cp.phone || "-"} />
              {i + 1 < contactPersons.length && <Details.Divider className="mb-1" />}
            </Details>
          ))}
        </>
      )}
    </>
  );
};

export default CustomerDetails;
