import React, { FC } from "react";
import { ParamTypes, ScreenProps } from "../../../../types/appTypes";
import { useParams } from "react-router-dom";
import { initTaxDocCase, TaxDocCase } from "../../../../types/taxDocTypes";
import { useApi } from "../../../../services/useApi";
import PageTitle from "../../../../layout/pageTitle";
import AnimateIn from "../../../../components/animateIn";
import PageBody from "../../../../layout/pageBody";
import TaxDocCaseStore from "../../../../contexts/taxDocCaseStore";
import TaxDocCaseHeadExtra from "../taxDocCaseHeadExtra";
import TaxDocCaseView from "./taxDocCaseView";
import { taxDocCaseUrl } from "../../../../services/taxDocService";

const TaxDocCaseUser: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const [{ data, isLoading }] = useApi<TaxDocCase>(taxDocCaseUrl(id), initTaxDocCase, true);
  const caseRecieved = data.id > 0;

  return (
    <TaxDocCaseStore.Provider initialState={data}>
      <PageTitle title={`${title}/${id}`} fluid backBtn extra={caseRecieved && <TaxDocCaseHeadExtra />} />
      <PageBody>
        <AnimateIn start={!isLoading && caseRecieved} loadingBeforeStart>
          <TaxDocCaseView />
        </AnimateIn>
      </PageBody>
    </TaxDocCaseStore.Provider>
  );
};

export default TaxDocCaseUser;
