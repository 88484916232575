import { FC } from "react";
import { Result } from "antd";
import Details from "../../../../components/detailsList";
import _t from "../../../../lang/translate";
import { RegFeeCalcOutputType } from "../../tax/calc/regFeeCalculatorIndex";
import format from "../../../../utilities/formatNumbers";

interface RegFeeOutputProps {
  output: RegFeeCalcOutputType | null;
  regFee: number | null;
}

export const isNotEmpty = (value: any) => {
  return value !== null && value !== undefined;
};

const RegFeeOutput: FC<RegFeeOutputProps> = ({ output, regFee }) => {
  if (!output) return <Result status="404" title={_t("msg.not_found")} />;

  const {
    energyType,
    emission,
    batteryCapacity,
    valueLoss,
    batteryDeductionAmount,
    scales,
    grossTax,
    emissions,
    emissionAddon,
    registrationFeeBeforeDeduction,
    bottomDeductionStandardAmount,
    taxDeductionAmount,
    lowDeductionAmount,
    taxablePrice,
    registrationFee,
  } = output || {};
  return (
    <Details>
      {isNotEmpty(energyType?.id) && <Details.Item label="Køretøjskategori" value={energyType?.title} boldValue />}
      {isNotEmpty(emission) && (
        <Details.Item
          label={`Co2-emission (${energyType?.id === 2 ? "WLTP" : "Omregnet til WLTP"})`}
          boldValue
          value={`${emission} g/km`}
        />
      )}
      {isNotEmpty(batteryCapacity) && (
        <Details.Item label="Batterikapacitet" boldValue value={`${format.number(batteryCapacity)} kWh`} />
      )}
      {valueLoss && isNotEmpty(valueLoss) && (
        <Details.Item boldValue label="Værditab" value={`${format.number(100 - valueLoss * 100)} %`} />
      )}
      <Details.Title>
        <h3>Tillæg/fradrag til værdien</h3>
      </Details.Title>
      {isNotEmpty(batteryDeductionAmount) && (
        <Details.Item
          boldValue
          label="Sum af tillæg/fradrag til værdien før afgift"
          value={`${format.price(batteryDeductionAmount)}`}
        />
      )}
      <Details.Title>
        <h3>Registreringsafgift før tillæg/fradrag</h3>
      </Details.Title>
      {isNotEmpty(scales[0]) && (
        <Details.Item label="Lav skalaknæk (25% op til 67.800,-)" value={`${format.price(scales[0])}`} />
      )}
      {isNotEmpty(scales[1]) && (
        <Details.Item label="Mellem skalaknæk (85% op til 210.600,-)" value={`${format.price(scales[1])}`} />
      )}
      {isNotEmpty(scales[2]) && (
        <Details.Item label="Høj skalaknæk (150% af beløb over 210.600,-)" value={`${format.price(scales[2])}`} />
      )}
      {isNotEmpty(grossTax) && <Details.Item label="Bruttoafgift" value={`${format.price(grossTax)}`} boldValue />}

      <Details.Title>
        <h3>CO2-tillæg i afgift</h3>
      </Details.Title>
      {emissions && isNotEmpty(emissions[0]) && (
        <Details.Item label="1. CO2-grænse (op til 117 g CO2 pr. km)" value={`${format.price(emissions[0])}`} />
      )}
      {emissions && isNotEmpty(emissions[1]) && (
        <Details.Item label="2. CO2-grænse (mellem 117 og 150 g CO2 pr. km)" value={`${format.price(emissions[1])}`} />
      )}
      {emissions && isNotEmpty(emissions[2]) && (
        <Details.Item label="3. CO2-grænse (over 150 g CO2 pr. km):" value={`${format.price(emissions[2])}`} />
      )}
      {isNotEmpty(emissionAddon) && (
        <Details.Item boldValue label="Sum af CO2-tillæg til afgiften" value={`${format.price(emissionAddon)}`} />
      )}

      <Details.Title>
        <h3>Resultat</h3>
      </Details.Title>
      {isNotEmpty(registrationFeeBeforeDeduction) && (
        <Details.Item
          label="Registreringsafgift før fradrag og indfasning"
          value={`${format.price(registrationFeeBeforeDeduction)}`}
        />
      )}
      {isNotEmpty(bottomDeductionStandardAmount) && (
        <Details.Item label="Bundfradrag til alle biler" value={`-${format.price(bottomDeductionStandardAmount)}`} />
      )}
      {isNotEmpty(taxDeductionAmount) && (
        <Details.Item
          label="Fradrag i afgift (indfasning hvis under 50 g CO2 pr. km)"
          value={`-${format.price(taxDeductionAmount)}`}
        />
      )}
      {isNotEmpty(lowDeductionAmount) && (
        <Details.Item label="Bundfradrag (Nul- og lav emisionsbiler)" value={`-${format.price(lowDeductionAmount)}`} />
      )}
      {isNotEmpty(taxablePrice) && (
        <Details.Item bold label={_t("taxable_value")} value={`${format.price(taxablePrice)}`} />
      )}
      {isNotEmpty(registrationFee) && (
        <Details.Item bold label={_t("registration_fee")} value={`${format.price(registrationFee)}`} />
      )}
      {!!regFee && regFee !== registrationFee && (
        <Details.Item label={_t("manual_registration_fee")} value={`${format.price(regFee)}`} />
      )}
    </Details>
  );
};

export default RegFeeOutput;
