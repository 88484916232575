import React, { FC, useEffect } from "react";
import { Equipment } from "../../../../types/appTypes";
import format from "../../../../utilities/formatNumbers";
import { calcEquipmentsTotal } from "../calc/equipmentsCalculator";

interface NewPriceEquipmentsTotalFieldProps {
  equipments: Equipment[];
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const NewPriceEquipmentsTotalField: FC<NewPriceEquipmentsTotalFieldProps> = ({ equipments, onChange, value }) => {
  useEffect(() => {
    const nextValue = calcEquipmentsTotal(equipments);
    nextValue !== value && onChange && onChange(nextValue);
  }, [equipments]);

  return <>{format.price(value || 0)}</>;
};

export default NewPriceEquipmentsTotalField;
