// @ts-nocheck
import { Button, Col, Divider, Row, Space, Tag, Typography } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { Link as RouterLink } from "react-router-dom";
import React, { FC } from "react";
import Icon from "../../../assets/icons/icon";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { ApiFile } from "../../../types/appTypes";
import { DealerProfileType } from "../../../types/dealerTypes";
import UserStore from "../../../contexts/userStore";

const { Link, Text } = Typography;

interface DealerProfileProps {
  dealer: DealerProfileType;
}

const DealerProfile: FC<DealerProfileProps> = ({ dealer }) => {
  const { hasPermission } = UserStore.useContainer();
  return (
    <>
      <Text className="muted d-block mb-2">{_t("dealer", "information")}</Text>
      <Row gutter={16} className="flex-wrap-lg">
        {dealer.logoFile?.length && (
          <Col flex="40px">
            <Avatar className="dealer-avatar" src={{ ...(dealer.logoFile[0] as ApiFile) }.previewUrl || ""} />
          </Col>
        )}
        <Col flex="auto">
          <h3>{dealer.name}</h3>
          <p>
            <Tag className="tag-small">
              <Text ellipsis>
                {_t("cvr")} {dealer.cvr}
              </Text>
            </Tag>
          </p>
          <Divider className="mt-1 mb-1" />

          {dealer.contactPerson && (
            <>
              <small className="d-block">{_t("contact_person")}</small>
              {dealer.contactPerson}
            </>
          )}
          {dealer.phone || dealer.email || dealer.address ? (
            <>
              <Divider className="mt-1 mb-1" />
              <small className="d-block mb-05">{_t("contact_info")}</small>
            </>
          ) : null}
          {dealer.phone && (
            <Link className="d-block mb-05" href={`tel:${dealer.phone}`}>
              <Icon name="call-outline" /> {dealer.phone}
            </Link>
          )}
          {dealer.email && (
            <Link className="d-block mb-05" href={`mailto:${dealer.email}`}>
              <Icon name="mail-outline" /> {dealer.email}
            </Link>
          )}
          {dealer.address && (
            <Link
              className="d-block mb-05"
              target="_blank"
              href={`https://www.google.com/maps/search/${dealer.address}, ${dealer.city}`}
            >
              <Space size={3} align="start">
                <Icon name="location-outline" />{" "}
                <span>
                  {dealer.address}
                  <br />
                  {dealer.zip} {dealer.city}
                </span>
              </Space>
            </Link>
          )}

          {dealer.bookKeepingEmail && (
            <>
              <Divider className="mt-1 mb-1" />
              <small className="d-block mb-1">{_t("bookKeeper")}</small>
              <Link href={`mailto:${dealer.bookKeepingEmail}`}>
                <Icon name="mail-outline" /> {dealer.bookKeepingEmail}
              </Link>
            </>
          )}
          {dealer.departments && dealer.departments.length ? (
            <>
              <Divider className="mt-1 mb-1" />
              <small className="d-block mb-1">{_t("departments")}</small>
              {dealer.departments?.map((dep) => (
                <Tag className="tag-small mb-05" key={dep.id}>
                  <Text ellipsis>{dep.title}</Text>
                </Tag>
              ))}
            </>
          ) : null}
          {dealer.billingDestinations && dealer.billingDestinations.length ? (
            <>
              <Divider className="mt-1 mb-1" />
              <small className="d-block mb-1">{_t("billing_destinations")}</small>
              {dealer.billingDestinations?.map((des) => (
                <div key={des.id}>
                  <Text ellipsis>{des.title}</Text>
                  <small className="ml-1">
                    {_t("cvr")} {des.cvr}
                  </small>
                </div>
              ))}
            </>
          ) : null}
          {hasPermission("screen.dealer") && (
            <>
              <Divider className="mt-1 mb-1" />
              <div className="text-right">
                <Space split={<Divider type="vertical" />}>
                  <Button className="p-0" type="link">
                    <RouterLink to={getPath("user")}>
                      <Icon name="people-outline" className="mr-05" />
                      {_t("users")}
                      <Tag className="tag-small mr-0 ml-05">{dealer.userCount || 1}</Tag>
                    </RouterLink>
                  </Button>
                  <Button className="p-0" type="link">
                    <RouterLink to={getPath("dealer", dealer.id)}>
                      <Icon name="create-outline" /> {_t("edit")}
                    </RouterLink>
                  </Button>
                </Space>
              </div>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DealerProfile;
