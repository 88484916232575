import { useState } from "react";
import { Button, Form, Input, Result, Space } from "antd";
import _t from "../../lang/translate";
import { addMerchantContact } from "../../services/merchantService";
import { ContactPersonType } from "../../types/appTypes";
import { OpenModalProps } from "../../types/modalTypes";
import assertFC from "../../utilities/assertFC";
import { renderErrorMessage } from "../messages/errorMessage";

export default function AddContactPerson<T extends object>({ onSuccess, onCancel, payload }: OpenModalProps<T>) {
  const [contactPersonForm] = Form.useForm<T>();
  const [loading, setLoading] = useState<boolean>(false);
  const { parentId, returnContact } = payload;

  const handleSubmit = async (values: T) => {
    setLoading(true);
    try {
      const { data } = await addMerchantContact<T>(payload.parentId, values);
      const contactPerson = { ...(data as ContactPersonType) };
      const result = returnContact ? contactPerson : { id: contactPerson.id, title: contactPerson.name };
      onSuccess(result as T);
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={contactPersonForm} requiredMark={false} layout="vertical" onFinish={handleSubmit}>
      <h2 className="modal-title">{_t("add", "department")}</h2>
      {parentId ? (
        <>
          <Form.Item name="id" hidden initialValue={0}>
            <Input hidden />
          </Form.Item>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input placeholder={_t("fullname")} />
          </Form.Item>
          <Form.Item name="email">
            <Input placeholder={_t("email")} />
          </Form.Item>
          <Form.Item name="phone">
            <Input placeholder={_t("phone")} />
          </Form.Item>
        </>
      ) : (
        <Result status="error" title={_t("choose", "merchant")} />
      )}
      <div className="text-right">
        <Space>
          <Button type="ghost" onClick={onCancel}>
            {_t("cancel")}
          </Button>

          <Button htmlType="submit" type="primary" disabled={!parentId} loading={loading}>
            {_t("add")}
          </Button>
        </Space>
      </div>
    </Form>
  );
}

assertFC(AddContactPerson);
