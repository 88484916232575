import React, { FC, useEffect } from "react";
import format from "../../../../utilities/formatNumbers";

interface TaxCollateralAmountInputProps {
  value?: number | null;
  onChange?: (v: number | null) => void;
  optRegFee?: number | null;
  offerRegFee?: number | null;
}

const ResellerTaxCollateralAmountInput: FC<TaxCollateralAmountInputProps> = ({
  value,
  onChange,
  optRegFee,
  offerRegFee
}) => {
  useEffect(() => {
    if (typeof optRegFee === "number") {
      const newCollateral = typeof offerRegFee === "number" ? offerRegFee - optRegFee : 0;
      onChange && onChange(newCollateral);
    }
  }, [optRegFee, offerRegFee]);

  return <span>{format.price(value || 0)}</span>;
};

export default ResellerTaxCollateralAmountInput;
