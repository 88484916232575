import { FC, ReactNode } from "react";
import { Collapse, CollapseProps } from "antd";
import Icon from "../assets/icons/icon";

interface CollapseCardProps extends CollapseProps {
  title: ReactNode;
  defaultOpen?: boolean;
  loading?: boolean | null;
  disable?: boolean | null;
  noPadding?: boolean;
}

const CollapseCard: FC<CollapseCardProps> = ({
  children,
  className,
  title,
  noPadding = false,
  defaultOpen = true,
  loading,
  disable,
}) => {
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={defaultOpen ? ["1"] : []}
      expandIconPosition="right"
      expandIcon={(panelProps: any) => {
        const { panelKey, onItemClick, className, isActive } = panelProps || {};
        return (
          <span
            className={`${className ?? ""} card-icon`}
            onClick={() => onItemClick && onItemClick(panelKey)}
            tabIndex={-1}
          >
            <Icon name={isActive ? "minimize" : "maximize"} size="medium" />
          </span>
        );
      }}
      className={`${className ?? ""} collapse-card ant-card ${loading ? " loading" : ""}`}
    >
      <Collapse.Panel
        className={`card-collapse-item${noPadding ? " card-body-padding-0" : ""}`}
        key={"1"}
        collapsible={disable ? "disabled" : undefined}
        header={title}
        forceRender
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};

export default CollapseCard;
