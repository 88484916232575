import { Col, Result, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC } from "react";
import _t from "../../../../lang/translate";
import { TradePriceCalcTaxType } from "../../../../types/taxTypes";
import format from "../../../../utilities/formatNumbers";

interface TradePriceOutputProps {
  calculation?: TradePriceCalcTaxType | null;
}

const TradePriceOutput: FC<TradePriceOutputProps> = ({ calculation }) => {
  const {
    tradePriceOutput: output,
    optimizedRegulation,
    otherRegulation,
    tradePrice,
    estimatedTradePrice,
    optimizedRegulationAmount,
    otherRegulationAmount,
  } = calculation || {};
  if (!output) return <Result status="404" title={_t("msg.not_found")} />;

  const columns: ColumnsType<any> = [
    {
      dataIndex: "title",
      title: "",
    },
    {
      dataIndex: "surcharge",
      title: _t("deduction"),
      align: "right",
    },
    {
      dataIndex: "amount",
      title: _t("amount"),
      align: "right",
    },
    {
      dataIndex: "total",
      align: "right",
      title: _t("offer_totalPrice"),
    },
  ];

  const dataSource = [
    {
      title: _t("market_price"),
      surcharge: "",
      amount: "",
      total: format.price(output.averagePrice),
    },
    {
      title: _t("delivery_fee"),
      surcharge: "",
      amount: output.optimization ? output.consts?.deliveryFee : 0,
      total: "",
    },
    {
      title: _t("average_discount_deduction"),
      surcharge: format.percent(output.consts?.averageDiscountSurcharge),
      amount: output.averageDiscountAmount,
      total: "",
    },
    {
      title: _t("trading_price_level"),
      surcharge: "",
      amount: "",
      total: format.price(output.tradingPriceTotal),
    },
    {
      title: _t("vehicle_condition"),
      surcharge: format.percent(output.qualitySurcharge),
      amount: format.number(output.qualityAmount),
      total: "",
    },
    {
      title: _t("special_use"),
      surcharge: format.percent(output.specialUseSurcharge),
      amount: output.specialUseAmount,
      total: "",
    },
    {
      title: _t("mileage_regulation"),
      surcharge: "Diff. " + format.milage(output.kmDifference),
      amount: format.number(output.kmDeductionTotal),
      total: "",
    },
    {
      title: _t("equipments_regulation"),
      surcharge: "",
      amount: format.number(output.equipmentRegulation),
      total: "",
    },
    {
      title: _t("calculated", "trade_price"),
      surcharge: "",
      amount: "",
      total: <strong>{format.price(output.finalTradePriceTotal)}</strong>,
    },
    {
      title: _t("other_adjustments"),
      surcharge: format.percent(otherRegulation || 0),
      amount: otherRegulationAmount,
      total: "",
    },
    {
      title: _t("optimization_regulation"),
      surcharge: output.optimization
        ? 0
        : format.percent(optimizedRegulation || 0),
      amount: output.optimization ? 0 : optimizedRegulationAmount,
      total: "",
    },
  ];

  const footer = () => (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <strong>{_t("trade_price")}</strong>
      </Col>
      <Col span={12} className="text-right">
        <strong>{format.price(tradePrice)}</strong>
      </Col>
      <Col span={12}>{_t("quick", "trade_price")}</Col>
      <Col span={12} className="text-right">
        {format.price(estimatedTradePrice)}
      </Col>
    </Row>
  );

  return (
    <Table
      className="border-radius"
      bordered
      columns={columns}
      onRow={(row) =>
        row?.title === _t("calculated", "trade_price")
          ? { className: "bg-light" }
          : {}
      }
      dataSource={dataSource}
      pagination={false}
      rowKey={"title"}
      footer={footer}
    />
  );
};

export default TradePriceOutput;
