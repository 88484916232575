import { Col, Form, Row, Switch } from "antd";
import { FC } from "react";
import InputNum from "../../../../components/form/inputNum";
import _t from "../../../../lang/translate";

const QuickTradePrice: FC = () => {
  return (
    <Form.Item label={_t("quick", "trade_price")}>
      <Row align="middle" className="flex-wrap">
        <Col flex="50px">
          <Form.Item
            name={["tradePrice", "useEstimatedTradePrice"]}
            noStyle
            valuePropName="checked"
          >
            <Switch size="small" />
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Form.Item
            noStyle
            shouldUpdate={(curr, next) =>
              curr?.tradePrice?.useEstimatedTradePrice !==
              next?.tradePrice?.useEstimatedTradePrice
            }
          >
            {({ getFieldValue }) => (
              <Form.Item name={["tradePrice", "estimatedTradePrice"]} noStyle>
                <InputNum
                  disabled={
                    !getFieldValue(["tradePrice", "useEstimatedTradePrice"])
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default QuickTradePrice;
