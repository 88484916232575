import http from "./httpService";
import { ContactPersonType, IdType } from "../types/appTypes";
import { getApiQuery, typeKey } from "./urlQueryService";
import getApiEndpoint from "./apiEndpoint";
import { getSavedEnums } from "./authService";
import { CustomerType, ValidationStatusesType } from "../types/customerTypes";
import { isNotEmptyString } from "../utilities/typeGuard";

const apiEndpoint = `${getApiEndpoint()}/customer`;

export function customerUrl(id: string | number, query?: string) {
  return `${apiEndpoint}/${id}${query ? "?" + getApiQuery(query) : ""}`;
}

export function customersUrl(query?: string) {
  return `${apiEndpoint}?${getApiQuery(query)}`;
}

export function updateValidation(id: IdType, data: ValidationStatusesType) {
  return http.put<CustomerType>(`${customerUrl(id)}/updateValidation`, data);
}

export function updateCustomer<T>(data: CustomerType) {
  if (data.id !== 0) return http.put<T>(customerUrl(data.id), data);

  return http.post<T>(apiEndpoint, data);
}

export function addContactPerson<T>(id: IdType, data: T) {
  return http.post<T>(`${customerUrl(id)}/addContactPerson`, data);
}

export function getContactPerson(id: IdType, contactPersonId: IdType) {
  return http.get<ContactPersonType>(`${customerUrl(id)}/contactPerson/${contactPersonId}`);
}

export function addCustomer<T>(data: CustomerType) {
  return http.post<T>(`${apiEndpoint}/storeQuick`, data);
}

export function getCustomer(id: IdType) {
  return http.get<CustomerType>(customerUrl(id));
}

export function deleteCustomer(id: IdType) {
  return http.delete(customerUrl(id));
}

export function customerFileUrl(id: IdType) {
  return `${apiEndpoint}/${id}/file`;
}

export function isPrivateCustomer(typeId?: number | null) {
  const id = typeId || getInitCustomer().typeId;
  return id === getSavedEnums()?.customer?.type[0]?.id;
}

export function getCustomerName({ typeId, name, nameFirst, nameLast }: CustomerType) {
  const customerName = isPrivateCustomer(typeId) ? `${nameFirst || ""} ${nameLast || ""}` : name;
  return isNotEmptyString(customerName) ? customerName : null;
}

export const getCustomerTypeId = () => {
  const typeIdFromUrl = new URLSearchParams(window.location.search).get(typeKey);
  return typeIdFromUrl ? Number(typeIdFromUrl) : getSavedEnums()?.customer?.type[0]?.id || 1;
};

export const getInitCustomer: (typeId?: number) => CustomerType = (typeId = getCustomerTypeId()) => {
  return {
    id: 0,
    name: null,
    email: null,
    prospectId: null,
    address: null,
    zip: null,
    city: null,
    phone: null,
    typeId,
    type: null,
    cvr: null,
    cpr: null,
    contactPersons: null,
    leasingProspectId: null,
    pbsRegNumber: null,
    pbsAccountNumber: null,
    validationStatuses: null,
    files: null,
  };
};
