import { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ActiveUsers from "../../components/activeUsers";
import LeasingStore from "../../contexts/leasingStore";
import UserStore from "../../contexts/userStore";
import PageTitle from "../../layout/pageTitle";
import { getPath, isNew } from "../../routes/appRoutes";
import { getInitLeasingCase, leasingUrl } from "../../services/leasingService";
import { useApi } from "../../services/useApi";
import { LocationStateType, ParamTypes, ScreenProps } from "../../types/appTypes";
import { LeasingCaseType, LeasingInitCaseType } from "../../types/leasingTypes";
import LeasingAdminHeadFooter from "./leasing/admin/leasingAdminHeadFooter";
import LeasingCaseHeadExtra from "./leasing/leasingCaseHeadExtra";
import LeasingCaseBody from "./leasingCaseBody";

const LeasingCase: FC<ScreenProps> = ({ title }) => {
  const { id } = useParams<ParamTypes>();
  const history = useHistory<LocationStateType>();
  const { location } = history;
  const [{ data }, setUrl, setData] = useApi<LeasingCaseType | null>("", null, true);
  const partnerCase = !location.pathname.includes(getPath("leasing"));
  const { hasPermission } = UserStore.useContainer();
  const isAdmin = hasPermission(partnerCase ? "partner_leasing.isAdmin" : "leasing.isAdmin");
  const { user } = UserStore.useContainer();
  useEffect(() => {
    const fetchInitCase = async (init: LeasingInitCaseType | null) => {
      const initReqestData: LeasingInitCaseType = {
        typeId: init?.typeId || 1,
        partnerCase,
        client: {
          typeId: init?.client?.typeId || 1,
          dealerId: init?.client?.dealerId || user?.dealer?.id || null,
          userId: init?.client?.userId || user?.id || null,
          customerId: init?.client?.customerId || null,
        },
      };
      try {
        const { data: initCase } = await getInitLeasingCase(initReqestData);
        setData(initCase);
      } catch (error) {
        history?.replace(location.pathname, {
          errorStatusCode: 404,
        });
      }
    };
    if (!isNew(id)) {
      setUrl(leasingUrl(id));
    } else {
      const fromPath = location.state?.from?.pathname;
      if (fromPath === "tax" || fromPath === "import" || fromPath === "leasing") {
        setData(location.state.data);
      } else {
        fetchInitCase(location.state?.data || null);
      }
    }
  }, [id, setUrl]);

  return (
    <LeasingStore.Provider initialState={data}>
      <PageTitle
        title={`${title}/${id}`}
        headerMargin={!isAdmin}
        fluid
        backBtn
        extra={isAdmin ? <ActiveUsers context="leasing" /> : <LeasingCaseHeadExtra />}
        footer={isAdmin && <LeasingAdminHeadFooter />}
      />
      <LeasingCaseBody />
    </LeasingStore.Provider>
  );
};

export default LeasingCase;
