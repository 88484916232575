import React, { FC, useState } from "react";
import { Collapse, Divider } from "antd";
import appConfig from "../../../../appConfig";
import Details from "../../../../components/detailsList";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import _t from "../../../../lang/translate";
import format from "../../../../utilities/formatNumbers";
import CustomCollapseHeader from "../../../../components/customCollapseHeader";
import CustomCollapse from "../../../../components/customCollapse";

const { Panel } = Collapse;

const ImportOrderedInfo: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();
  const { expressDelivery } = importCase.vehicleOffer?.offer?.prices || {};
  const { deliveryAddress, isCarStatusReport, valutaId, bankInfo, isExpressDelivery } = importCase.order || {};
  const [activeKey, setActiveKey] = useState<string[]>([]);

  const dekra = isCarStatusReport ? appConfig("dekraPrice") : 0;
  const express = isExpressDelivery ? expressDelivery : 0;
  const price = importCase.vehicleOffer?.offer?.totalPrice + dekra + express;
  const valutaText =
    valutaId === 1 ? (
      <>DKK ({format.price(price * appConfig("conversionRate"))})</>
    ) : (
      <>
        EUR <small>({format.number(price)})</small>
      </>
    );

  return (
    <CustomCollapse className="bg-none ml-0 mr-0" setActiveKey={setActiveKey} activeKey={activeKey}>
      <Panel header={<CustomCollapseHeader>{_t("see_your_request_details")}</CustomCollapseHeader>} key="2">
        <Details borderLess>
          <Details.Title className="m-0">
            <h3>{_t("delivery_address")}</h3>
          </Details.Title>
          <Details.Item label={_t("name")} value={deliveryAddress?.name || "-"} />
          <Details.Item label={_t("address")} value={deliveryAddress?.address || "-"} />
          <Details.Item
            label={_t("zip", "city", " & ")}
            value={`${deliveryAddress?.zip || "-"} ${deliveryAddress?.city || "-"}`}
          />
          <Details.Item label={_t("phone")} value={deliveryAddress?.phone || "-"} />
          <Details.Item label={_t("contact_person")} value={deliveryAddress?.contact || "-"} />
        </Details>
        <Divider />
        <Details borderLess>
          <Details.Title className="m-0">
            <h3>{_t("addons")}</h3>
          </Details.Title>
          <Details.Item label={_t("express_delivery")} value={format.yesOrNo(isExpressDelivery)} hidden={true} />
          <Details.Item label={_t("dekra")} value={format.yesOrNo(isCarStatusReport)} />
        </Details>
        <Divider />
        <Details borderLess>
          <Details.Title className="m-0">
            <h3>{_t("payment")}</h3>
          </Details.Title>
          <Details.Item label={_t("valuta")} value={valutaText} />
          <Details.Item label={_t("bank_info")} value={bankInfo} />
        </Details>
      </Panel>
    </CustomCollapse>
  );
};

export default ImportOrderedInfo;
