import { Col, Form, Row } from "antd";
import React, { FC } from "react";
import AutoCalcSwitch from "../../../../components/form/autoCalcSwitch";
import _t from "../../../../lang/translate";
import TaxDynamicFeeInput from "./taxDynamicFeeInput";

const TaxDynamicFee: FC = () => {
  const shouldUpdate = (curr: any, next: any) =>
    curr.registrationFee?.registrationFee !== next.registrationFee?.registrationFee ||
    curr.fee?.autoCalculate !== next.fee?.autoCalculate;
  return (
    <Row className="flex-nowrap">
      <Col flex="auto">
        <Form.Item shouldUpdate={shouldUpdate} noStyle>
          {({ getFieldValue }) => {
            const autoCalculate = getFieldValue(["fee", "autoCalculate"]);
            return (
              <Form.Item name={["fee", "fee"]} label={_t("fee")}>
                <TaxDynamicFeeInput
                  suffix={<small>{_t("include_short", "vat")}</small>}
                  className="bg-white no-border"
                  regFee={getFieldValue(["registrationFee", "registrationFee"])}
                  newPrice={getFieldValue(["newPrice", "newPrice"])}
                  tradePrice={getFieldValue(["tradePrice", "tradePrice"])}
                  autoCalculate={autoCalculate}
                  disabled={autoCalculate}
                  min={0}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col flex="49px" className="text-right">
        <Form.Item noStyle name={["fee", "autoCalculate"]}>
          <AutoCalcSwitch />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default TaxDynamicFee;
