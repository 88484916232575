import { VersionStatusType } from "../../../../types/leasingTypes";

export const taxCalcVersions = [2024020201, 2024013101, 2022071401, 2022033001, 2018010101] as const;
export const taxCalcForcedExpiredVersions = [2024013101, 2022071401, 2022033001, 2018010101] as const;
export type TaxCalcVersionType = typeof taxCalcVersions[number];
export type TaxCalcForcedExpiredVersionType = typeof taxCalcForcedExpiredVersions[number];

export const latestVersion = Math.max(...taxCalcVersions) as TaxCalcVersionType;
export const isLatestVersion = (version: TaxCalcVersionType) => version === latestVersion;
export const isSupportedVersion = (version: number) => taxCalcVersions.includes(version as TaxCalcVersionType);
export const isForcedExpiredVersion = (version: number) => taxCalcForcedExpiredVersions.includes(version as TaxCalcForcedExpiredVersionType);

export const getVersionStatus = (version: TaxCalcVersionType, createdAtYear: number): VersionStatusType => {
  if (createdAtYear < (new Date()).getFullYear()) {
    return "expired";
  }
  if (isForcedExpiredVersion(version)) {
    return "expired";
  }
  if (isLatestVersion(version)) {
    return "latest";
  }
  if (isSupportedVersion(version)) {
    return "supported";
  }
  return "expired";
};
