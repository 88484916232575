import { Button, Form, message, Space } from "antd";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import SavedMessage from "../../../components/messages/savedMessage";
import DealerStore from "../../../contexts/dealerStore";
import _t from "../../../lang/translate";
import { getPath } from "../../../routes/appRoutes";
import { saveDealer } from "../../../services/dealerService";
import { DealerType } from "../../../types/dealerTypes";
import consts from "../../../utilities/consts";
import { isUploadFile } from "../../../utilities/convertTypes";
import DealerFormFields from "./dealerFormFields";

interface DealerInfoFormProps {
  stopEditing: () => void;
}

const DealerInfoForm: FC<DealerInfoFormProps> = ({ stopEditing }) => {
  let history = useHistory();
  const { dealer, setDealer, setLoading, isNew } = DealerStore.useContainer();
  const [dealerForm] = Form.useForm<DealerType>();

  const handleSave = async (values: DealerType) => {
    try {
      setLoading(true);
      let logoFile: any = values.logoFile;
      if (logoFile && logoFile.status === "done" && isUploadFile(logoFile)) {
        logoFile = logoFile.response;
      }
      const { data } = await saveDealer({ ...values, id: dealer.id, logoFile });
      message.success(<SavedMessage message={_t("saved")} />);
      if (isNew) {
        history.replace(getPath("dealer", data.id));
      } else {
        setDealer(data);
        setLoading(false);
        stopEditing();
      }
    } catch ({ response }) {
      const msg = response?.data?.message;
      const errors = response?.data?.errors;
      errors && dealerForm.setFields(errors);
      message.error(msg || _t("msg.unknown_error"));
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={dealer}
      form={dealerForm}
      {...consts.formItemProps}
      requiredMark
      onFinish={handleSave}
      className="mb-0"
    >
      <DealerFormFields />
      <Form.Item noStyle shouldUpdate>
        {({ submit }) => (
          <div className="text-right pt-2">
            {isNew ? (
              <Button type="primary" onClick={submit}>
                {_t("create", "dealer")}
              </Button>
            ) : (
              <Space>
                <Button onClick={stopEditing}>{_t("cancel")}</Button>
                <Button type="primary" onClick={submit}>
                  {_t("save")}
                </Button>
              </Space>
            )}
          </div>
        )}
      </Form.Item>
    </Form>
  );
};

export default DealerInfoForm;
