import React, { FC } from "react";
import { Button, Col, message, Modal, Row, Space, Typography } from "antd";
import ResellerTaxCaseStore from "../../../../contexts/resellerTaxCaseStore";
import _t from "../../../../lang/translate";
import { sentResellerTaxReceipt } from "../../../../services/resellerTaxService";
import LogText from "../../../../components/logText";
import { renderErrorMessage } from "../../../../components/messages/errorMessage";
import { ResellerTaxCase } from "../../../../types/resellerTaxTypes";
import PopoverList from "../../../../components/popups/popoverList";

const { confirm } = Modal;

interface TaxReceiptActionsProps {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResellerTaxReceiptActions: FC<TaxReceiptActionsProps> = ({ setLoading }) => {
  const { resellerTaxCase, setResellerTaxCase } = ResellerTaxCaseStore.useContainer();
  const { cannotSendReceipt, rejectReasons } = checkCanSendReceipt(resellerTaxCase)

  const handleSendReceipt = async () => {
    try {
      setLoading(true);
      const { data } = await sentResellerTaxReceipt(resellerTaxCase.id);
      setResellerTaxCase(data);
      message.success("Kvittering Sent");
    } catch (error) {
      renderErrorMessage(error)
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="position-relative">
      <Typography.Title className="mb-05" level={5}>
        {_t("customer", "receipt")}
      </Typography.Title>

      <Row justify="space-between" gutter={8} align="middle" className="mt-1">
        <Col>&nbsp;</Col>
        <Col>
          <Space>
            {resellerTaxCase.log?.receipted ? (
              <LogText inline small className={"p-0"} logData={resellerTaxCase.log.receipted} />
            ) : null}

            <PopoverList
              title={_t("msg.needs_input")}
              placement="topRight"
              trigger={cannotSendReceipt ? "hover" : "none"}
              list={rejectReasons}
            >
              <Button
                disabled={cannotSendReceipt}
                type="primary"
                onClick={() => {
                  confirm({ content: _t("msg.confirm_send_receipt"), onOk: handleSendReceipt });
                }}
              >
                {resellerTaxCase.flags.receipted ? _t("send_receipt", "again") : _t("send_receipt")}
              </Button>
            </PopoverList>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default ResellerTaxReceiptActions;

function checkCanSendReceipt(resellerTaxCase: ResellerTaxCase) {

  const cannotSendReceipt = !(resellerTaxCase.links?.invoiceLink || resellerTaxCase.invoiceStartedAt)

  const rejectReasons = [];
  cannotSendReceipt && rejectReasons.push(_t("msg.needs_invoice_sent"));

  return {
    rejectReasons, cannotSendReceipt
  }
}
