import moment from "moment";
import momentTz from 'moment-timezone';

import appConfig from "../appConfig";

export const date = (date: string) => moment(date).format(appConfig("dateFormat"));
export const time = (date: string) => moment(date).format("HH:mm");

export const dateDiff = ({
  start,
  end,
  unitOfTime = "months",
}: {
  start: string | null;
  end?: string | null;
  unitOfTime?: "years" | "months";
}) => {
  const endDate = moment(end || undefined).endOf("day");
  const startDate = moment(start || undefined).endOf("day");
  if (!startDate.isValid() || !endDate.isValid()) return 0;
  const result = Math.abs(endDate.diff(start, unitOfTime, false));
  return result;
};

export const isValidDateString = (dateString: unknown) => {
  if (typeof dateString === 'string') {
    return !isNaN(new Date(dateString).getTime())
  }
  return false;
}

/*
* Checks if the date falls on weekdays (Monday - Friday) and weekends (Saturday - Sunday).
* Also, checks if the time falls within a minimum and maximum time window on one of the
* the weekdays. Return true if both the specified conditions meet. Otherwise, it returns
* false.
*
*  Params:
*  -------
*  dateTime - The date and time string to check for
*  minTime - The minimum time for the time window
*  maxTime - The maximum time for the time window
* */
export const isWorkingDay = (dateTime: string | undefined, minTime: number, maxTime: number): boolean => {
  // Get the timezone is hardcoded to the below since, if the online system is viewed from f.rx USA, then using the system timezone deosn't make sense
  const systemTimeZone = 'Europe/Copenhagen';

  // Get the current datetime in system timezone
  const systemDateTime = momentTz(dateTime).tz(systemTimeZone);

  /*
  Extracts the day of the week. Note: 0 = Sunday, 1 = Monday, 2 = Tuesday, 3 = Wednesday, 4 = Thursday,
  5 = Friday and 6 = Saturday
  */
  const dayOfWeek = systemDateTime.day();
  // Extract the time of the day
  const hourOfDay = systemDateTime.hour();

  // Checks for Weekends
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return false;
  }

  // Checks for weekdays and the specified minimum and maximum time window
  if ((dayOfWeek >= 1 && dayOfWeek <= 5) && (hourOfDay >= minTime && hourOfDay <= maxTime)) {
    return true;
  }

  // Default value
  return false;
}

export function millisToMinutes(millis: number) {
  const minutes: number = Math.floor(millis / 60000);
  const seconds: string = ((millis % 60000) / 1000).toFixed(0);
  // @ts-ignore
  return minutes;
}
