import { FC, useEffect } from "react";
import format from "../../../../../utilities/formatNumbers";
import { isNum } from "../../../../../utilities/typeGuard";
import { getEquipmentDepreciation } from "../../calc/tradePriceSubCalculations";

interface TaxEquipmentsPriceCellProps {
  vehicleYear?: number | null;
  diff?: number | null;
  equipmentYearFactorDeduction?: number | null;
  value?: number | null;
  onChange?: (v: number | null) => void;
}

const TaxEquipmentsPriceCell: FC<TaxEquipmentsPriceCellProps> = ({
  vehicleYear,
  diff,
  value,
  equipmentYearFactorDeduction,
  onChange,
}) => {
  useEffect(() => {
    onChange && onChange(getEquipmentDepreciation(vehicleYear, diff, equipmentYearFactorDeduction));
  }, [vehicleYear, diff, equipmentYearFactorDeduction]);

  return <>{isNum(value) ? <span className="text-dark text-bold">{format.number(value)}</span> : "-"}</>;
};

export default TaxEquipmentsPriceCell;
