import React, { FC } from "react";
import { Space } from "antd";
import TaxDownloadInvoiceBtn from "./taxDownloadInvoiceBtn";
import TaxDownloadReceiptBtn from "./taxDownloadReceiptBtn";

const TaxDownloads: FC = () => {
  return (
    <Space className="mb-05" direction="vertical">
      <TaxDownloadInvoiceBtn />
      <TaxDownloadReceiptBtn />
    </Space>
  );
};

export default TaxDownloads;
