import { FC, useState } from "react";
import { Button, Form, message, Space, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import TaxCaseStore from "../../../../contexts/taxCaseStore";
import _t from "../../../../lang/translate";

import { UpdateDocumentationNoteType } from "../../../../types/taxTypes";
import { updateDocumentationNote } from "../../../../services/taxService";

const TaxDocNote: FC = () => {
  const { taxCase, setTaxCase } = TaxCaseStore.useContainer();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { documentationNote } = taxCase;
  const startEditing = () => setIsEditing(true);
  const stopEditing = () => setIsEditing(false);

  const handleUpdate = async (values: UpdateDocumentationNoteType) => {
    try {
      setLoading(true);
      const { data } = await updateDocumentationNote(taxCase.id, values);
      setTaxCase(data);
      stopEditing();
    } catch ({ response }) {
      message.error(response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography.Title level={5}>{_t("note_to_case")}</Typography.Title>
      {isEditing ? (
        <Form initialValues={{ documentationNote }} onFinish={handleUpdate}>
          <Form.Item name="documentationNote">
            <TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ submit }) => (
              <div className="text-right">
                <Space>
                  <Button disabled={loading} onClick={stopEditing}>
                    {_t("cancel")}
                  </Button>
                  <Button type="primary" loading={loading} onClick={submit}>
                    {_t("save")}
                  </Button>
                </Space>
              </div>
            )}
          </Form.Item>
        </Form>
      ) : (
        <>
          <Typography.Paragraph ellipsis={{ rows: 2 }} className="mb-0">
            {taxCase.documentationNote || "-"}
          </Typography.Paragraph>
          <div className="text-right">
            <Button
              type="link"
              className="pl-0 pr-0 mt-1"
              onClick={startEditing}
            >
              {_t("edit")}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default TaxDocNote;
