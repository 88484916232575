import { Checkbox, Form, Input } from "antd";
import React, { FC } from "react";
import Icon from "../../../assets/icons/icon";
import _t from "../../../lang/translate";
import EnumSelect from "../../../components/form/enumSelect";
import DocumentsUpload from "../../../components/documentsUpload";
import { taxFileUrl } from "../../../services/taxService";
import InputNum from "../../../components/form/inputNum";
import UserStore from "../../../contexts/userStore";
import InputVin from "../../../components/form/inputVin";
import { TaxCase } from "../../../types/taxTypes";
import EnumRadio from "../../../components/form/EnumRadio";
import QuickCalculationInput from "./quickCalculationInput";

const { TextArea } = Input;

interface TaxDocNewCaseFormProps {
  uploadRef?: React.RefObject<HTMLDivElement>;
  onTabChange: (key: string) => void;
  activeTab: string;
  taxCase?: TaxCase;
  isAdmin: boolean;
}

const TaxDocNewCaseForm: FC<TaxDocNewCaseFormProps> = ({ uploadRef, taxCase, onTabChange, isAdmin, activeTab }) => {
  const { hasPermission } = UserStore.useContainer();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div>
      {/* <Tabs activeKey={activeTab} centered destroyInactiveTabPane onTabClick={onTabChange}>
        <TabPane tab={_t("vin")} key="vin">

        </TabPane>
        <TabPane tab={_t("link")} key="link">
          <Form.Item name="isQuickCalculation" label={_t("plan")} className="mb-1">
            <QuickCalculationInput activeTab={activeTab} />
          </Form.Item>
          <Form.Item
            className="mb-1"
            label={_t("link")}
            name={["vehicle", "link"]}
            rules={[{ required: activeTab === "link" }]}
          >
            <Input size="large" placeholder={_t("insert_link")} prefix={<Icon name="link-outline" />} />
          </Form.Item>
          <Form.Item
            name={["vehicle", "typeId"]}
            label={_t("vehicle_type")}
            rules={[{ required: activeTab === "link" }]}
          >
            <EnumRadio nameKey={["vehicle", "type"]} />
          </Form.Item>
        </TabPane>
      </Tabs> */}
      <Form.Item hidden name="isQuickCalculation" label={_t("plan")} className="mb-1">
        <QuickCalculationInput activeTab={activeTab} />
      </Form.Item>

      <Form.Item
        name={["vehicle", "vin"]}
        label={_t("vin")}
        className="mb-1"
        rules={[{ required: activeTab === "vin" }]}
      >
        <InputVin size="large" placeholder={_t("insert_vin_here")} prefix={<Icon name="barcode-outline" />} />
      </Form.Item>

      <Form.Item label=" " name={["vehicle", "isInspected"]} valuePropName="checked">
        <Checkbox>{_t("car_is_inspected")}</Checkbox>
      </Form.Item>

      <Form.Item label=" " name={["flags", "isPriority"]} valuePropName="checked">
        <Checkbox>{_t("high_priority_with_example")}</Checkbox>
      </Form.Item>

      <Form.Item name={["vehicle", "typeId"]} label={_t("vehicle_type")} rules={[{ required: activeTab === "vin" }]}>
        <EnumRadio nameKey={["vehicle", "type"]} />
      </Form.Item>

      <Form.Item name={["vehicle", "mileage"]} label={_t("mileage")} rules={[{ required: activeTab === "vin" }]}>
        <InputNum step={1000} min={0} suffix={_t("mileage_unit")} />
      </Form.Item>

      <Form.Item
        className="mb-05"
        name={["vehicle", "useCaseId"]}
        label={_t("special_use")}
        rules={[{ required: activeTab === "vin" }]}
      >
        <EnumSelect nameKey={["vehicle", "useCase"]} defaultActiveFirstOption />
      </Form.Item>

      <Form.Item name="note" label={_t("note")}>
        <TextArea placeholder={_t("note_placeholder")} autoSize={{ minRows: 1, maxRows: 3 }} />
      </Form.Item>

      <div className="uploadRef" ref={uploadRef}>
        <Form.Item name="files" label={_t("documents")} valuePropName="fileList" getValueFromEvent={normFile}>
          <DocumentsUpload
            canLockFiles={hasPermission("tax.isAdmin")}
            action={taxFileUrl(taxCase?.id)}
            canDelete={!isAdmin}
            multiple={true}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default TaxDocNewCaseForm;
