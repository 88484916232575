import React, { FC } from "react";
import { Alert, Col, Row } from "antd";
import ImportCaseStore from "../../../../contexts/importCaseStore";
import ImportAttachmentsCard from "../admin/importAttachmentsCard";
import ImportOfferView from "./importOfferView";
import ImportOrderView from "./importOrderView";
import ImportProcess from "./importProcess";
import ImportVehicleView from "./importVehicleView";

const ImportCaseView: FC = () => {
  const { importCase } = ImportCaseStore.useContainer();

  return (
    <Row align="top" gutter={24}>
      <Col xxl={16} xl={12} xs={24}>
        <Row gutter={24}>
          <Col xxl={{ span: 12, order: 1 }} xl={{ span: 24, order: 2 }} xs={{ span: 24, order: 2 }}>
            <ImportVehicleView />
          </Col>
          <Col xxl={{ span: 12, order: 2 }} xl={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}>
            <ImportOfferView />
            <ImportOrderView />
          </Col>
        </Row>
      </Col>
      <Col xxl={8} xl={12} xs={24}>
        {importCase.importantNote && (
          <Alert className="mb-2" message={importCase.importantNote} type="warning" closable />
        )}
        <ImportProcess />
        <ImportAttachmentsCard />
      </Col>
    </Row>
  );
};

export default ImportCaseView;
